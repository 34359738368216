import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Box, Divider, Typography } from '@mui/material';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../../assets/commonStyled';
import { validateAddress } from '../../../constants/common';
import { Validation } from '../../../constants/FieldValidationMsg';
import { EMAIL_REGEX } from '../../../constants/Regex';
import { SUPER_ADMIN } from '../../../constants/Roles';
import getCityByStateHook from '../../../hooks/getCityByStateHook';
import getStatesHook from '../../../hooks/getStatesHook';
import useAddressDetail from '../../../hooks/useAddressDetail';
import useAutocompleteAddress from '../../../hooks/useAutcompleteAddress';
import { getOfficeStaffById } from '../../../store/officeStaff/api';
import { formattedDate, loggedInUserRole } from '../../../utils';
import Autocomplete from '../../CommonComponents/AutoComplete';
import CreateCityModal from '../../CommonComponents/CreateCityModal';
import CustomButton from '../../CommonComponents/CustomButton';
import CommonCheckbox from '../../CommonComponents/CustomCheckbox';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import CustomTextField from '../../CommonComponents/CustomTextField';

const OfficeStaffForm = ({
  id,
  control,
  reset,
  setValue,
  watch,
  trigger,
  clearErrors,
  setServerErrors,
  sethasFormValues,
}) => {
  const dispatch = useDispatch();
  const { isLoading, editOfficeStaff: officeStaff } = useSelector(
    (state) => state.officeStaff.edit
  );
  const selectedState = watch('state');
  const stateList = getStatesHook();
  const cityList = getCityByStateHook(selectedState?.value);

  const { t } = useTranslation();

  const labels = (label) => t(`label.superAdmin.${label}`);

  useEffect(() => {
    if (id) {
      dispatch(getOfficeStaffById(id));
    }
  }, []);

  sethasFormValues(Object.values(watch()).some((value) => value));

  useEffect(() => {
    if (!isLoading && officeStaff && id) {
      reset({
        uuid: officeStaff.uuid,
        name: officeStaff.name,
        email: officeStaff.email,
        cell_phone: officeStaff.cell_phone,
        address: officeStaff.address,
        address_line_2: officeStaff.address_line_2 || '',
        city: {
          label: officeStaff?.city || '',
          value: officeStaff?.city || '',
        },
        state: { label: officeStaff.state, value: officeStaff.state },
        active: officeStaff.is_active,
        created_at: formattedDate(officeStaff.created_at),
        postcode: officeStaff.postcode,
      });
    }
  }, [isLoading, officeStaff]);

  const clearHandler = (name) => setValue(name, '');

  const postCodeLength = 4;

  const [addressBlur, setAddressBlur] = useState(false);
  const [address2Blur, setAddress2Blur] = useState(false);
  const [stateBlur, setStateBlur] = useState(false);
  const [cityBlur, setCityBlur] = useState(false);
  const [postcodeBlur, setPostcodeBlur] = useState(false);
  const [addressRecheck, setAddressRecheck] = useState(false);
  const address = watch('address');
  const addressLine2 = watch('address_line_2');
  const state = watch('state');
  const city = watch('city');
  const postcode = watch('postcode');
  const {
    isLoading: isAutocompleteAddressLoading,
    data: autocompleteAddressData,
  } = useSelector((state) => state.googleAPIs.autocompleteAddress);
  const typedValue = watch('searchAddress');
  const [searchAddress, setSearchAddress] = useState('');
  const [open, setOpen] = useState(false);

  useAutocompleteAddress(searchAddress);
  useAddressDetail({
    selectedAddress: typedValue,
    setValue: setValue,
    fieldName: {
      stateKey: 'state',
      cityKey: 'city',
      postCodeKey: 'postcode',
      address1: 'address',
      address2: 'address_line_2',
    },
  });

  useEffect(() => {
    if (
      state &&
      (city || true) &&
      address &&
      postcode &&
      (addressLine2 || true)
    ) {
      setAddressBlur(true);
      setStateBlur(true);
      setCityBlur(true);
      setPostcodeBlur(true);
      setAddress2Blur(true);
      setAddressRecheck(true);
    }
  }, [state, city, address, postcode, addressLine2]);

  useEffect(() => {
    validateAddress({
      clearErrors,
      address1: address,
      address2: addressLine2,
      city: city,
      state: state,
      postcode: postcode,
      addressRecheck,
      fieldName: {
        addressKey: 'address',
        addressValue: t('attributes.address'),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'postcode',
        postCodeValue: t('attributes.postCode'),
        address1Key: 'address',
        address1Value: 'Address Line 1',
        address2Key: 'address_line_2',
        address2Value: 'Address Line 2',
      },
      setServerErrors: setServerErrors,
    });
  }, [addressBlur, address2Blur, stateBlur, cityBlur, postcodeBlur]);

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.office_staff.contact_information')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="name"
            control={control}
            rules={{
              required: `${t('attributes.office_staff.office_staff')} ${t('attributes.name')} ${Validation.general.required}`,
              minLength: {
                value: 2,
                message: Validation.officeStaff.name,
              },
              maxLength: {
                value: 255,
                message: Validation.officeStaff.name,
              },
            }}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={`${t('attributes.name')}`}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('name');
                }}
                onBlur={() => {
                  onChange(value.trim());
                  onBlur();
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Controller
            name="cell_phone"
            control={control}
            rules={{
              // required: `${t('attributes.office_staff.cell_phone')} ${Validation.general.required}`,
              pattern: {
                value: /^\d{0,50}$/,
                message: Validation.officeStaff.maxPhone,
              },
            }}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={`${t('attributes.office_staff.cell_phone')}`}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('cell_phone');
                }}
                onBlur={() => {
                  onChange(value.trim());
                  onBlur();
                  trigger('cell_phone');
                }}
                isRequired={false}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{
              required: `${t('attributes.email')} ${Validation.general.required}`,
              maxLength: {
                value: 254,
                message: Validation.general.email,
              },
              pattern: {
                value: EMAIL_REGEX,
                message: Validation.general.validEmail,
              },
            }}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={`${t('attributes.email')}`}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('email');
                }}
                onBlur={() => {
                  onChange(value.trim());
                  onBlur();
                  trigger('email');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
                disabled={officeStaff && id}
              />
            )}
          />
          <Controller
            name="searchAddress"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                label={t('common.searchAddress')}
                options={autocompleteAddressData || []}
                value={value}
                isRequired={false}
                isLoadingData={isAutocompleteAddressLoading}
                onChange={(e, newValue) => {
                  if (newValue || newValue === null) {
                    onChange(newValue);
                  }
                  setSearchAddress(e.target?.value);
                }}
                helperText={error ? error.message : ''}
                error={error}
              />
            )}
          />
          <Controller
            name="address"
            control={control}
            rules={{
              required: `${t('attributes.addressLine1')} ${Validation.general.required}`,
              maxLength: {
                value: 300,
                message: Validation.general.address,
              },
            }}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.addressLine1')}
                fullWidth
                value={value}
                setBlur={setAddressBlur}
                onChange={(e) => {
                  onChange(e);
                  trigger('address');
                }}
                multiline
                rows={2}
                helperText={error ? error.message : ''}
                error={error}
                onBlur={() => {
                  onChange(value.trim());
                  onBlur();
                }}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Controller
            name={'address_line_2'}
            control={control}
            rules={{
              maxLength: {
                value: 300,
                message: Validation.general.address2,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.addressLine2')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('address_line_2');
                }}
                isRequired={false}
                multiline
                rows={2}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
                setBlur={setAddress2Blur}
              />
            )}
          />

          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="state"
              control={control}
              rules={{
                required: `${t('common.state')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  options={stateList}
                  label={t('common.state')}
                  setBlur={setStateBlur}
                  onChange={(e, newValue) => {
                    if (newValue || newValue === null) {
                      onChange(newValue);
                    }
                    setValue('city', null);
                  }}
                  value={value}
                  error={error}
                  helperText={error ? error.message : ''}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  options={cityList}
                  label={t('common.city')}
                  setBlur={setCityBlur}
                  onChange={(e, newValue) => {
                    if (newValue || newValue === null) {
                      onChange(newValue);
                    }
                  }}
                  value={value}
                  error={error}
                  helperText={error ? error.message : ''}
                  onClear={() => clearHandler(name)}
                  disabledDropdown={!selectedState}
                  isRequired={false}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '-16px 0px',
            }}
          >
            <CustomButton
              startIcon={<AddCircleOutlineOutlinedIcon />}
              text={t('attributes.add')}
              style={{ textDecoration: 'underline' }}
              variant="text"
              onClick={() => setOpen(true)}
            />
          </Box>
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="postcode"
              control={control}
              rules={{
                required: `${t('attributes.postCode')} ${Validation.general.required}`,
                validate: (value) => {
                  if (value && !/^\d+$/.test(value)) {
                    return Validation.general.numericValue;
                  }

                  return true;
                },
                minLength: {
                  value: postCodeLength,
                  message: `${Validation.officeStaff.postCodeCharacter} ${postCodeLength} ${Validation.officeStaff.characters}`,
                },
                maxLength: {
                  value: postCodeLength,
                  message: `${Validation.officeStaff.postCodeCharacter} ${postCodeLength} ${Validation.officeStaff.characters}`,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.postCode')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '');

                    onChange(numericValue);
                    trigger('postcode');
                  }}
                  setBlur={setPostcodeBlur}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
          </Box>
        </FormFieldWrapper>
      </CustomCard>
      {id && (
        <CustomCard>
          <Box sx={{ padding: '0 16px 0 16px' }}>
            <CommonCheckbox
              control={control}
              name="active"
              label={t('common.active')}
              isRequired={false}
              showMessage={true} // Enable message display
              messageOnChecked={labels('officeStaffActiveCheckbox')}
              messageOnUnchecked={labels('officeStaffInactiveCheckbox')}
            />
          </Box>
        </CustomCard>
      )}
      {loggedInUserRole() === SUPER_ADMIN && (
        <CreateCityModal
          open={open}
          setOpen={setOpen}
          state={watch('state')}
          setServerErrors={setServerErrors}
        />
      )}
    </FormWrapper>
  );
};

export default OfficeStaffForm;
