import React from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { LIGHT_RED, PRIMARY, TEXT_COLOR } from '../../constants/Colors';
import CustomButton from './CustomButton';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ErrorMessage = styled(Typography)({
  color: LIGHT_RED,
  fontSize: '12px',
  marginTop: '4px',
  fontWeight: 400,
});

const CustomFileUploadButton = ({
  style = {},
  onFileChange,
  multiple = false,
  buttonText = 'Click to upload or drag and drop the PDF file',
  accept = 'application/pdf', // Default to PDF
  error = false,
  errorMessage = '',
  name,
}) => {
  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;

    // Filter only PDF files
    const pdfFiles = Array.from(files).filter(
      (file) =>
        file.type === 'application/pdf' ||
        file.type === 'text/csv' ||
        file.type === accept
    );

    if (pdfFiles.length) {
      onFileChange({ target: { files: pdfFiles } });
    } else {
      onFileChange({ target: { files: [] } }); // Clear invalid input
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div style={style}>
      <CustomButton
        component="label"
        variant="contained"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        sx={{
          borderStyle: 'dotted',
          borderWidth: '2px',
          borderColor: '#d1d1d1',
          borderRadius: '4px',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          padding: '11px',
          height: '48px',
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '13px',
            color: TEXT_COLOR,
          }}
        >
          <span style={{ color: PRIMARY, textDecoration: 'underline' }}>
            {buttonText.split(' or ')[0]}{' '}
          </span>
          {'No File Selected' === buttonText.split(' or ')[1] ? (
            ' '
          ) : (
            <span> or </span>
          )}
          {buttonText.split(' or ')[1]}
        </Typography>
        <VisuallyHiddenInput
          type="file"
          name={name}
          onChange={(event) => {
            const files = event.target.files;
            const pdfFiles = Array.from(files).filter(
              (file) =>
                file.type === 'application/pdf' ||
                file.type === 'text/csv' ||
                file.type === accept
            );

            if (pdfFiles.length) {
              onFileChange(event);
            } else {
              onFileChange({ target: { files: [] } }); // Clear invalid input
            }
          }}
          multiple={multiple}
          accept={accept}
        />
      </CustomButton>
      {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};

export default CustomFileUploadButton;
