import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { TimelineDot } from '@mui/lab';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box, Typography } from '@mui/material';

import { DARK_GRAY, DISABLE, SECONDARY } from '../../constants/Colors';
import {
  ASSET_FAILED,
  ASSET_HISTORY,
  ASSET_MODIFIED,
  ASSET_NO_CHECK,
  ASSET_PASSED,
  DATE_TIME_RANGE_FORMAT_DEFAULT,
  DEFECT_RAISED,
  DEFECT_RESOLVED,
  ROUTE_NAVIGATE_CONST,
  redirectURL,
} from '../../constants/Constants';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import {
  getAssetHistory,
  getAssetHistoryMessages,
} from '../../store/property/api';
import {
  resetAssetHistory,
  resetAssetHistoryMessage,
} from '../../store/property/reducer';
import { getTechnicianNamesList } from '../../store/technician/api';
import {
  findLabelByValue,
  formattedDate,
  getRedirectURL,
  loggedInUserRole,
} from '../../utils';
import CustomCircularLoader from '../CommonComponents/CustomLoader';

const AssetHistory = ({ assetUUID, propertyId }) => {
  const dispatch = useDispatch();

  const {
    loading: assetHistoryLoading,
    data: assetHistory,
    error,
  } = useSelector((state) => state.property.assetHistory);
  const { loading: historyMessageLoading, data: historyMessage } = useSelector(
    (state) => state.property.assetHistoryMessage
  );
  const { technicianDropdownLoading, technicianDropdownData } =
    getDropdownListHook({
      reducerName: 'technician',
      dropdownListName: 'technicianNamesDropdownList',
      labelName: 'display_name',
      valueName: 'uuid',
    });

  useEffect(() => {
    dispatch(getAssetHistory({ uuid: assetUUID }));
    dispatch(getAssetHistoryMessages());
    dispatch(getTechnicianNamesList());

    return () => {
      dispatch(resetAssetHistoryMessage());
      dispatch(resetAssetHistory());
    };
  }, []);

  const getEventDetails = useCallback(
    (message, event, technicianList, uuid, wid, wuid, item) => {
      const description = message?.filter((evt) => evt?.event === event)[0]
        ?.display_string;

      const technician_name =
        findLabelByValue(technicianList, uuid)?.label || '-';
      const workOrderLink =
        (wid || wuid) &&
        redirectURL[loggedInUserRole()][
          ROUTE_NAVIGATE_CONST.WORK_ORDER_DETAIL
        ] ? (
          <NavLink
            style={{ textDecoration: 'underline' }}
            to={getRedirectURL()[loggedInUserRole()][
              ROUTE_NAVIGATE_CONST.WORK_ORDER_DETAIL
            ]?.replace(':uuid', wuid)}
          >
            {wid || '-'}
          </NavLink>
        ) : (
          wid
        );

      switch (event) {
        case ASSET_PASSED:
        case ASSET_FAILED:
        case DEFECT_RESOLVED:
        case ASSET_MODIFIED:
        case ASSET_NO_CHECK:
          return (
            <>
              {
                description
                  ?.replace('{name}', technician_name)
                  ?.split('{work_order_id}')[0]
              }
              {workOrderLink}
            </>
          );
        case DEFECT_RAISED: {
          const defectTechnicianName =
            findLabelByValue(
              technicianList,
              item?.defect_details?.technician_uuid
            )?.label || '-';

          return (
            <>
              {
                description
                  ?.replace('{name}', defectTechnicianName)
                  ?.split('{work_order_id}')[0]
              }
              {workOrderLink}
            </>
          );
        }
        default:
          return description;
      }
    },
    []
  );

  return assetHistoryLoading ||
    historyMessageLoading ||
    technicianDropdownLoading ? (
    <CustomCircularLoader />
  ) : error ? (
    <Box mt="16px" textAlign="center">
      <Typography variant="body1">
        There are not event associated with the particular asset.
      </Typography>
    </Box>
  ) : (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.14,
        },
        mt: '24px',
      }}
    >
      {assetHistory?.map((item, idx) => (
        <TimelineItem key={idx}>
          <TimelineOppositeContent color={DISABLE} fontStyle="italic" mt="6px">
            <Typography
              variant="body2"
              sx={{
                color: `var(--text-grey)`,
              }}
            >
              {formattedDate(item?.created_at, DATE_TIME_RANGE_FORMAT_DEFAULT)}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                color: SECONDARY,
                bgcolor: ASSET_HISTORY[item?.event]?.bgColor,
                margin: '6px 0',
              }}
            >
              {ASSET_HISTORY[item?.event]?.icon}
            </TimelineDot>

            {idx !== assetHistory?.length - 1 && (
              <TimelineConnector
                sx={{
                  bgcolor: DARK_GRAY,
                }}
              />
            )}
          </TimelineSeparator>
          <TimelineContent sx={{ pt: 0, mt: '-4px' }} gap="4px">
            <Typography variant="body1" sx={{ mb: '4px' }}>
              {item?.event}
            </Typography>
            <Typography variant="body2">
              {getEventDetails(
                historyMessage,
                item?.event,
                technicianDropdownData,
                item?.technician_uuid,
                item?.work_order_id,
                item?.work_order_uuid,
                item
              )}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default AssetHistory;
