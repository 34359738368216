import { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Box, Checkbox, Divider, TextField, Typography } from '@mui/material';
import { debounce } from 'lodash';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../assets/commonStyled';
import { PRODUCT_TYPES } from '../../constants/Constants';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import { getEquipmentTypesDropdown } from '../../store/equipmentTypes/api';
import { getproductCatalogueList } from '../../store/productCatalogue/api';
import { Delete } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomTextField from '../CommonComponents/CustomTextField';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';

const AddProduct = ({
  control,
  watch,
  setValue,
  trigger,
  defectTitleList,
  selectedProductsError,
  setProductError = () => {},
  isFromServiceQuote = false,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const clearHandler = (name) => setValue(name, '');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lineItems, setLineItems] = useState([]);
  const [allLineItems, setAllLineItems] = useState([]);

  const { productList, isLoading } = useSelector(
    (state) => state.productCatalogue.get
  );

  const { equipmentTypesDropdownLoading, equipmentTypesDropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'equipmentTypesDropdownList',
      labelName: 'display_name',
      valueName: 'code',
    });

  useEffect(() => {
    setProducts(productList);
  }, [productList]);

  const selectedtype = watch('type');
  const searchProduct = watch('search');

  const selectedproductType = watch('product_type');
  const equipmentType = watch('equipment_type');

  const debouncedFetchProductList = useCallback(
    debounce((type, search, equipmentType) => {
      setLoading(true);
      dispatch(
        getproductCatalogueList({
          type,
          search: search,
          limit: '-1',
          equipment_type: equipmentType,
        })
      );
    }, 500),
    [dispatch]
  );

  // Trigger the debounced function when selected type or search changes
  useEffect(() => {
    debouncedFetchProductList(
      selectedtype?.value || selectedproductType?.value,
      searchProduct,
      equipmentType?.value
    );
    setSelectedProducts([]);
    setIsAllSelected(false);
  }, [
    selectedtype?.value,
    searchProduct,
    equipmentType?.value,
    debouncedFetchProductList,
    selectedproductType?.value,
  ]);

  const handleCheckboxChange = (product) => {
    setSelectedProducts((prevSelected) => {
      const existingProduct = prevSelected.find((p) => p.uuid === product.uuid);

      if (existingProduct) {
        // Remove if already selected
        setAllLineItems((prev) => prev.filter((p) => p.uuid !== product.uuid));

        return prevSelected.filter((p) => p.uuid !== product.uuid);
      } else {
        // Add if not selected
        return [...prevSelected, { ...product, qty: 1, id: product.uuid }];
      }
    });
  };

  useEffect(() => {
    // Check if all individual checkboxes are selected
    if (
      selectedProducts.length === productList.length &&
      productList.length > 0
    ) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }

    if (selectedProducts.length !== 0) {
      setProductError(false);
    }
  }, [selectedProducts, productList]);

  useEffect(() => {
    isFromServiceQuote && dispatch(getEquipmentTypesDropdown());
  }, []);

  const handleQuantityChange = (product, qty) => {
    setSelectedProducts((prevSelected) => {
      const existingProduct = prevSelected.find((p) => p.uuid === product.uuid);

      if (!existingProduct) {
        return [
          ...prevSelected,
          { ...product, qty: Number(qty), id: product.uuid },
        ];
      }

      return prevSelected.map((p) =>
        p.uuid === product.uuid
          ? { ...p, qty: Number(qty), id: product.uuid }
          : p
      );
    });
  };

  const handleSelectAllChange = () => {
    setIsAllSelected((prevIsAllSelected) => {
      const newIsAllSelected = !prevIsAllSelected;

      if (newIsAllSelected) {
        setSelectedProducts((prevSelected) => {
          // Find products that are not already in the selectedProducts list
          const newProductsToAdd = productList.filter(
            (product) => !prevSelected.some((p) => p.uuid === product.uuid)
          );

          // Merge already selected products with new products
          return [
            ...prevSelected,
            ...newProductsToAdd.map((product) => ({
              ...product,
              qty: 1,
              id: product.uuid,
            })),
          ];
        });
      } else {
        // Clear selectedProducts when "Select All" is unchecked
        setSelectedProducts([]);
      }

      return newIsAllSelected;
    });
  };

  useEffect(() => {
    setValue('selectedProducts', lineItems);
  }, [lineItems, setValue]);

  useEffect(() => {
    if (lineItems.length > 0) {
      setProductError(null);
    }
  }, [lineItems]);

  const columns = [
    {
      field: 'product_name',
      headerName: t('attributes.productCatalogue.sell_price'),
      flex: 4, // 40% width
      sortable: false,
      renderHeader: () => (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            color="primary"
            checked={isAllSelected}
            onChange={handleSelectAllChange}
          />
          <Typography variant="body1">Product Name</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            color="primary"
            checked={selectedProducts.some((p) => p.uuid === params.row.uuid)}
            onChange={() => handleCheckboxChange(params.row)}
          />
          <Typography variant="body2">{params.row.product_name}</Typography>
        </Box>
      ),
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 1, // 10% width
      sortable: false,
      renderCell: (params) => (
        <TextField
          name="qty"
          type="number"
          sx={{
            '& .MuiInputBase-input': {
              padding: '0 10px',
              height: '30px',
            },
          }}
          value={
            selectedProducts.find((p) => p.uuid === params.row.uuid)?.qty || 1
          }
          onChange={(e) => handleQuantityChange(params.row, e.target.value)}
        />
      ),
    },
    {
      field: 'cost',
      headerName: t('attributes.productCatalogue.cost_price'),
      flex: 1.5, // 10% width
      sortable: false,
      headerAlign: 'right',
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{ display: 'flex', justifyContent: 'right' }}
        >
          {params.row.cost.toFixed(2)}
        </Typography>
      ),
    },
    {
      field: 'sell_price',
      headerName: t('attributes.productCatalogue.sell_price'),
      flex: 1.5, // 10% width
      sortable: false,
      headerAlign: 'right',
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{ display: 'flex', justifyContent: 'right' }}
        >
          {params.row.sell_price.toFixed(2)}
        </Typography>
      ),
    },
  ];

  const lineItemsColumns = [
    {
      field: 'product_name',
      headerName: t('attributes.productCatalogue.product_name'),
      flex: 4, // 40% width
      sortable: false,
      renderHeader: () => (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1">
            {t('attributes.productCatalogue.product_name')}
          </Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2">{params.row.product_name}</Typography>
        </Box>
      ),
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 1, // 10% width
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          {lineItems.find((p) => p.uuid === params.row.uuid)?.qty || 1}
        </Typography>
      ),
    },
    {
      field: 'sell_price',
      headerName: t('attributes.productCatalogue.sell_price'),
      flex: 1, // 10% width
      sortable: false,
      headerAlign: 'right',
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{ display: 'flex', justifyContent: 'right' }}
        >
          {params.row.sell_price.toFixed(2)}
        </Typography>
      ),
    },
    {
      field: 'action',
      headerName: t('attributes.action'),
      flex: 1, // 10% width
      sortable: false,
      headerAlign: 'center',
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Delete
            onClick={() => {
              setLineItems((prevLineItems) =>
                prevLineItems.filter((item) => item.uuid !== row.uuid)
              );
              setSelectedProducts((prevLineItems) =>
                prevLineItems.filter((item) => item.uuid !== row.uuid)
              );
              setAllLineItems((prevLineItems) =>
                prevLineItems.filter((item) => item.uuid !== row.uuid)
              );
            }}
          />
        </Box>
      ),
    },
  ];

  const rows = products?.map((item, index) => ({
    id: index,
    uuid: item.uuid,
    product_name: item.product_name,
    quantity: selectedProducts.find((p) => p.uuid === item.uuid)?.qty || 1,
    cost: item.cost,
    sell_price: item.sell_price,
  }));

  const onAddProductsToList = () => {
    const updatedData = [...allLineItems, ...selectedProducts];

    const uniqueData = updatedData.reduce((acc, item) => {
      const existingItemIndex = acc.findIndex(
        (existingItem) => existingItem.uuid === item.uuid
      );

      if (existingItemIndex !== -1) {
        acc[existingItemIndex] = {
          ...acc[existingItemIndex],
          qty: (acc[existingItemIndex].qty || 1) + (item.qty || 1), // Combine the qty of both items
        };
      } else {
        acc.push(item);
      }

      return acc;
    }, []);

    setLineItems(uniqueData);
    setAllLineItems(uniqueData);
    setSelectedProducts([]);
  };

  return (
    <FormWrapper>
      <CustomCard>
        <FormFieldWrapper component="form">
          {isFromServiceQuote ? (
            <>
              <Controller
                name="product_type"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('attributes.productCatalogue.product_type')}
                    options={PRODUCT_TYPES}
                    value={value}
                    onChange={(e, newValue) => {
                      if (newValue || newValue === null) {
                        onChange(newValue);
                      }
                      trigger('product_type');
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    isLoadingData={false}
                    isRequired={false}
                  />
                )}
              />
              <Controller
                name="equipment_type"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('attributes.property.assetGroup')}
                    options={equipmentTypesDropdownData}
                    value={value}
                    onChange={(e, newValue) => {
                      if (newValue || newValue === null) {
                        onChange(newValue);
                      }
                      trigger('equipment_type');
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    isLoadingData={equipmentTypesDropdownLoading}
                    isRequired={false}
                  />
                )}
              />
            </>
          ) : (
            <>
              <Controller
                name="defect_title"
                control={control}
                rules={{
                  required: 'Defect title field is required',
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('attributes.defectsQuotes.defectTitle')}
                    options={defectTitleList}
                    value={value}
                    onChange={(e, newValue) => {
                      if (newValue || newValue === null) {
                        onChange(newValue);
                      }
                      trigger('defect_title');
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    isLoadingData={false}
                    isRequired={true}
                  />
                )}
              />
              <Controller
                name="type"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('attributes.productCatalogue.type')}
                    options={PRODUCT_TYPES}
                    value={value}
                    onChange={(e, newValue) => {
                      if (newValue || newValue === null) {
                        onChange(newValue);
                      }
                      trigger('type');
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    isLoadingData={false}
                    isRequired={false}
                  />
                )}
              />
            </>
          )}
        </FormFieldWrapper>
      </CustomCard>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Controller
            name="search"
            control={control}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.search')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('search');
                }}
                onBlur={() => {
                  onChange(value?.trim());
                  onBlur();
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
                isRequired={false}
              />
            )}
          />
        </Box>
        <Divider />
        {selectedProductsError && selectedProducts?.length === 0 && (
          <Typography sx={{ padding: '16px' }} color="error">
            {selectedProductsError}
          </Typography>
        )}
        <CustomGridTable
          columns={columns}
          rows={rows}
          total={rows.length}
          checkboxSelection={false}
          paginationRequired={false}
          filterHeight={705}
          disableColumnMenu={true}
          isLoading={isLoading}
          noData={<NoRecordFound />}
          isTableView={true}
        />
        <Box sx={{ padding: '16px', display: 'flex', justifyContent: 'end' }}>
          <CustomButton
            text={t('attributes.add_to_list')}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={onAddProductsToList}
            disabled={false}
          />
        </Box>
      </CustomCard>

      <CustomCard>
        <Box sx={{ padding: '8px 16px 8px 16px' }}>
          {isFromServiceQuote
            ? t('attributes.service_quotes.added_serviceQuote_line_items')
            : t('attributes.defectsQuotes.added_defectQuote_line_items')}
        </Box>
        <Divider />
        <CustomGridTable
          columns={lineItemsColumns}
          rows={lineItems}
          total={lineItems.length}
          checkboxSelection={false}
          paginationRequired={false}
          filterHeight={705}
          disableColumnMenu={true}
          sx={{ paddingLeft: '5px' }}
          isLoading={false}
          isTableView={true}
          noData={
            <NoRecordFound
              isShowIcon={false}
              text={t('common.no_products_selected')}
            />
          }
        />
      </CustomCard>
    </FormWrapper>
  );
};

export default AddProduct;
