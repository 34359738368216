import { createSlice } from '@reduxjs/toolkit';

import {
  createZone,
  getZone,
  getZonesDropdownList,
  getZonesList,
  updateZone,
} from './api';

const zonesDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const initialState = {
  isLoading: false,
  error: false,
  data: false,
};

const listInitialState = {
  isLoading: false,
  error: false,
  data: [],
  total: 0,
  totalCount: 0,
};

const zonesSlice = createSlice({
  name: 'zones',
  initialState: {
    zonesDropdownList,
    createNewZoneLGA: initialState,
    updateZoneLGA: initialState,
    getZonesLGAList: listInitialState,
    getZoneDetails: initialState,
  },
  reducers: {
    resetZonesDropdownList: (state) => {
      state.zonesDropdownList = zonesDropdownList;
    },
    resetCreateNewZoneLGA: (state) => {
      state.createNewZoneLGA = initialState;
    },
    resetUpdateZoneLGA: (state) => {
      state.updateZoneLGA = initialState;
    },
    resetGetZonesLGAList: (state) => {
      state.getZonesLGAList = listInitialState;
    },
    resetGetZoneDetails: (state) => {
      state.getZoneDetails = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Zones Dropdown List
      .addCase(getZonesDropdownList.pending, (state) => {
        state.zonesDropdownList.isLoading = true;
      })
      .addCase(getZonesDropdownList.fulfilled, (state, action) => {
        state.zonesDropdownList.isLoading = false;
        state.zonesDropdownList.data = action.payload;
      })
      .addCase(getZonesDropdownList.rejected, (state, action) => {
        state.zonesDropdownList.isLoading = false;
        state.zonesDropdownList.error = action.error.message;
      })
      .addCase(createZone.pending, (state) => {
        state.createNewZoneLGA.isLoading = true;
      })
      .addCase(createZone.fulfilled, (state, action) => {
        state.createNewZoneLGA.isLoading = false;
        state.createNewZoneLGA.data = action.payload;
      })
      .addCase(createZone.rejected, (state, action) => {
        state.createNewZoneLGA.isLoading = false;
        state.createNewZoneLGA.error = action.error.message;
      })
      .addCase(updateZone.pending, (state) => {
        state.updateZoneLGA.isLoading = true;
      })
      .addCase(updateZone.fulfilled, (state, action) => {
        state.updateZoneLGA.isLoading = false;
        state.updateZoneLGA.data = action.payload;
      })
      .addCase(updateZone.rejected, (state, action) => {
        state.updateZoneLGA.isLoading = false;
        state.updateZoneLGA.error = action.error.message;
      })
      .addCase(getZonesList.pending, (state) => {
        state.getZonesLGAList.isLoading = true;
      })
      .addCase(getZonesList.fulfilled, (state, action) => {
        state.getZonesLGAList.isLoading = false;
        state.getZonesLGAList.data = action.payload;
        state.getZonesLGAList.totalCount = action.payload.total_records;
        state.getZonesLGAList.total = action.payload.total;
      })
      .addCase(getZonesList.rejected, (state, action) => {
        state.getZonesLGAList.isLoading = false;
        state.getZonesLGAList.error = action.error.message;
      })
      .addCase(getZone.pending, (state) => {
        state.getZoneDetails.isLoading = true;
      })
      .addCase(getZone.fulfilled, (state, action) => {
        state.getZoneDetails.isLoading = false;
        state.getZoneDetails.data = action.payload;
      })
      .addCase(getZone.rejected, (state, action) => {
        state.getZoneDetails.isLoading = false;
        state.getZoneDetails.error = action.error.message;
      });
  },
});

export const {
  resetZonesDropdownList,
  resetCreateNewZoneLGA,
  resetUpdateZoneLGA,
  resetGetZonesLGAList,
  resetGetZoneDetails,
} = zonesSlice.actions;

export default zonesSlice.reducer;
