import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Box, Typography, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { uniqueId } from 'lodash';
import moment from 'moment';

import { FlexEnd, SpaceBetween } from '../../assets/commonStyled';
import { DIVIDER_COLOR, PRIMARY, WARNING } from '../../constants/Colors';
import {
  ASSETS,
  CHART_COLOR,
  CRITICAL_STATUS,
  CUSTOMER_DASHBOARD_DEFECT_HORIZONTAL_CHART,
  CUSTOMER_DASHBOARD_DEFECT_VERTICAL_CHART,
  DASHBOARD,
  MONTHS_DROPDOWN,
  NON_CONFORMANCE_STATUS,
  NON_CRITICAL_STATUS,
  OPEN_STATUS,
  PROPERTIES,
  RECOMMENDATION_STATUS,
  RESOLVED,
  ROUTE_NAVIGATE_CONST,
  SEVERITY,
  STATUS,
} from '../../constants/Constants';
import { ROUTENAME } from '../../constants/RoutesConstants';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import {
  getDefectByEquipementType,
  getDefectByProperty,
  getMonthlyDefectChartData,
  getMonthlyDefectStatusChartData,
} from '../../store/defects/api';
import { getEquipmentTypesDropdown } from '../../store/equipmentTypes/api';
import { getCustomerDefectCount } from '../../store/users/customer/api';
import {
  getMomentDateTime,
  getRedirectURL,
  getXAxisLabel,
  loggedInUserRole,
} from '../../utils';
import StackedBarChart from '../Chart/StackedBarChart/StackedBarChart';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import DashboardCard from '../CommonComponents/DashboardCard';

const ChartWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  background: 'white',
  borderRadius: '4px',
  marginTop: '16px',
});

const CustomAutocomplete = styled(Autocomplete)({
  paddingRight: 0,
  height: '26px',
  '.MuiAutocomplete-inputRoot': {
    paddingRight: '0 !important',
    height: '26px',
    width: '120px',
  },
  '.MuiOutlinedInput-root': { padding: '0 !important' },
  '& .MuiAutocomplete-endAdornment': {
    right: '0 !important',
  },
});

const defaultRangeData = {
  defectTypeByStatus: CUSTOMER_DASHBOARD_DEFECT_VERTICAL_CHART[0],
  defectType: CUSTOMER_DASHBOARD_DEFECT_HORIZONTAL_CHART[0],
  defectFrequency: MONTHS_DROPDOWN[0],
  defectFrequencyForHorizontalChart: MONTHS_DROPDOWN[0],
};

const DefectDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const role = loggedInUserRole();

  const { propertyDropdownData, refresh } = useOutletContext();

  const { critical, non_conformance, non_critical, recommendation } =
    CHART_COLOR;

  const colors = [critical, non_critical, non_conformance, recommendation];
  const statusColor = [WARNING, PRIMARY];

  const [ranges, setRanges] = useState(defaultRangeData);
  const [verticalChartData, setVerticalChartData] = useState([]);
  const [horizontalChartData, setHorizontalChartData] = useState([]);
  const [xAxisLabel, setXAxisLabel] = useState([]);
  const [horizontalChartXAxisLabels, setHorizontalChartXAxisLabels] = useState(
    []
  );

  const [chartLoading, setChartLoading] = useState({
    status: false,
    severity: false,
  });

  const { isLoading, data } = useSelector(
    (state) => state.customer.defectStatusCount
  );

  const { equipmentTypesDropdownData } = getDropdownListHook({
    reducerName: 'equipmentTypes',
    dropdownListName: 'equipmentTypesDropdownList',
    labelName: 'display_name',
    valueName: 'id',
  });

  let defectCard = [
    {
      title: 'Critical',
      status: CRITICAL_STATUS,
      value: 0,
      click: true,
    },
    {
      title: 'Non-Critical',
      status: NON_CRITICAL_STATUS,
      value: 0,
      click: true,
    },
    {
      title: 'Non-Conformance',
      status: NON_CONFORMANCE_STATUS,
      value: 0,
      click: true,
    },
    {
      title: 'Recommendation',
      status: RECOMMENDATION_STATUS,
      value: 0,
      click: true,
    },
  ];

  data &&
    data?.forEach((item) => {
      let card = defectCard.find((card) => card.status === item.severity);

      if (card) {
        card.value = item.count;
      }
    });

  useEffect(() => {
    const req = getMomentDateTime();

    dispatch(getCustomerDefectCount({ ...req }));
    dispatch(getEquipmentTypesDropdown());
  }, [dispatch, refresh]);

  // Vertical Chart APIs
  useEffect(() => {
    setXAxisLabel(getXAxisLabel(ranges?.defectFrequency?.value, true));

    setChartLoading((pre) => ({ ...pre, status: true }));
    // Filter by STATUS
    if (ranges?.defectTypeByStatus?.value === STATUS) {
      dispatch(
        getMonthlyDefectStatusChartData({
          start_date: moment().subtract(
            ranges.defectFrequency?.value,
            'months'
          ),
          end_date: moment(),
        })
      )
        .then((res) => {
          const currentMonth = moment().month() + 1; // Convert to 1-based month
          const currentYear = moment().year();

          const lastMonths = [...Array(ranges?.defectFrequency?.value)].map(
            (_, index) => {
              const month = ((currentMonth - index - 1 + 12) % 12) + 1;
              const year =
                currentYear + Math.floor((currentMonth - index - 1) / 12);

              return { month, year };
            }
          );

          const openStatusData = lastMonths.map(
            ({ month, year }) =>
              res?.payload?.data?.find(
                (d) =>
                  d.month === month &&
                  d.year === year &&
                  d.status === OPEN_STATUS
              )?.count || 0
          );

          const closedStatusData = lastMonths.map(
            ({ month, year }) =>
              res?.payload?.data.find(
                (d) =>
                  d.month === month && d.year === year && d.status === RESOLVED
              )?.count || 0
          );

          const finalData = [
            {
              data: openStatusData.reverse(),
              label: t('common.status.open'),
              id: OPEN_STATUS,
              stack: 'total',
            },
            {
              data: closedStatusData.reverse(),
              label: t('common.status.closed'),
              id: RESOLVED,
              stack: 'total',
            },
          ];

          setVerticalChartData(finalData);
        })
        .finally(() => setChartLoading((pre) => ({ ...pre, status: false })));
    }

    // Filter by SEVERITY
    if (ranges?.defectTypeByStatus?.value === SEVERITY) {
      dispatch(
        getMonthlyDefectChartData(
          getMomentDateTime({
            months: ranges.defectFrequency?.value,
          })
        )
      )
        .then((res) => {
          const currentMonth = moment().month() + 1; // Convert to 1-based month
          const currentYear = moment().year();

          const lastMonths = [...Array(ranges?.defectFrequency?.value)].map(
            (_, index) => {
              const month = ((currentMonth - index - 1 + 12) % 12) + 1;
              const year =
                currentYear + Math.floor((currentMonth - index - 1) / 12);

              return { month, year };
            }
          );

          const criticalStatusData = lastMonths.map(
            ({ month, year }) =>
              res?.payload?.data?.find(
                (d) =>
                  d.month === month &&
                  d.year === year &&
                  d?.severity === CRITICAL_STATUS
              )?.count || 0
          );
          const nonCriticalStatusData = lastMonths.map(
            ({ month, year }) =>
              res?.payload?.data?.find(
                (d) =>
                  d.month === month &&
                  d.year === year &&
                  d?.severity === NON_CRITICAL_STATUS
              )?.count || 0
          );
          const nonConformanceStatusData = lastMonths.map(
            ({ month, year }) =>
              res?.payload?.data?.find(
                (d) =>
                  d.month === month &&
                  d.year === year &&
                  d?.severity === NON_CONFORMANCE_STATUS
              )?.count || 0
          );
          const recommendationStatusData = lastMonths.map(
            ({ month, year }) =>
              res?.payload?.data?.find(
                (d) =>
                  d.month === month &&
                  d.year === year &&
                  d?.severity === RECOMMENDATION_STATUS
              )?.count || 0
          );

          const finalData = [
            {
              data: criticalStatusData.reverse(),
              label: t('common.status.critical'),
              id: CRITICAL_STATUS,
              stack: 'total',
            },
            {
              data: nonCriticalStatusData.reverse(),
              label: t('common.status.non_critical'),
              id: NON_CRITICAL_STATUS,
              stack: 'total',
            },
            {
              data: nonConformanceStatusData.reverse(),
              label: t('common.status.non_conformance'),
              id: NON_CONFORMANCE_STATUS,
              stack: 'total',
            },
            {
              data: recommendationStatusData.reverse(),
              label: t('common.status.recommendation'),
              id: RECOMMENDATION_STATUS,
              stack: 'total',
            },
          ];

          setVerticalChartData(finalData);
        })
        .finally(() => setChartLoading((pre) => ({ ...pre, status: false })));
    }
  }, [ranges?.defectTypeByStatus, ranges?.defectFrequency, refresh]);

  // Horizontal Chart API
  useEffect(() => {
    setChartLoading((pre) => ({ ...pre, severity: true }));
    if (ranges?.defectType?.value === ASSETS) {
      dispatch(
        getDefectByEquipementType(
          getMomentDateTime({
            months: ranges.defectFrequencyForHorizontalChart?.value,
          })
        )
      )
        .then((res) => {
          let criticalStatusData = [];
          let nonCriticalStatusData = [];
          let nonConformanceStatusData = [];
          let recommendationStatusData = [];

          const dataShouldBeDispalyed = equipmentTypesDropdownData?.filter(
            (data) =>
              res?.payload?.data
                ?.map((d) => d.equipment_type_id)
                ?.includes(data?.value)
          );

          setHorizontalChartXAxisLabels(
            dataShouldBeDispalyed?.map((d) => d.label)
          );

          for (let index = 0; index < dataShouldBeDispalyed?.length; index++) {
            // Critical Data
            criticalStatusData.push(
              res?.payload?.data?.find(
                (d) =>
                  d.equipment_type_id === dataShouldBeDispalyed[index]?.value &&
                  d?.severity === CRITICAL_STATUS
              )?.count || 0
            );
            // Non Critical Data
            nonCriticalStatusData.push(
              res?.payload?.data?.find(
                (d) =>
                  d.equipment_type_id === dataShouldBeDispalyed[index]?.value &&
                  d?.severity === NON_CRITICAL_STATUS
              )?.count || 0
            );

            // Non Critical Data
            nonConformanceStatusData.push(
              res?.payload?.data?.find(
                (d) =>
                  d.equipment_type_id === dataShouldBeDispalyed[index]?.value &&
                  d?.severity === NON_CONFORMANCE_STATUS
              )?.count || 0
            );

            // Recommendation Data
            recommendationStatusData.push(
              res?.payload?.data?.find(
                (d) =>
                  d.equipment_type_id === dataShouldBeDispalyed[index]?.value &&
                  d?.severity === RECOMMENDATION_STATUS
              )?.count || 0
            );
          }
          const finalData = [
            {
              data: criticalStatusData,
              label: t('common.status.critical'),
              id: CRITICAL_STATUS,
              stack: 'total',
            },
            {
              data: nonCriticalStatusData,
              label: t('common.status.non_critical'),
              id: NON_CRITICAL_STATUS,
              stack: 'total',
            },
            {
              data: nonConformanceStatusData,
              label: t('common.status.non_conformance'),
              id: NON_CONFORMANCE_STATUS,
              stack: 'total',
            },
            {
              data: recommendationStatusData,
              label: t('common.status.recommendation'),
              id: RECOMMENDATION_STATUS,
              stack: 'total',
            },
          ];

          setHorizontalChartData(finalData);
        })
        .finally(() =>
          setChartLoading((prev) => ({ ...prev, severity: false }))
        );
    }

    if (ranges?.defectType?.value === PROPERTIES) {
      dispatch(
        getDefectByProperty({
          start_date: moment().subtract(
            ranges.defectFrequencyForHorizontalChart?.value,
            'months'
          ),
          end_date: moment(),
        })
      )
        .then((res) => {
          let criticalStatusData = [];
          let nonCriticalStatusData = [];
          let nonConformanceStatusData = [];
          let recommendationStatusData = [];

          const dataShouldBeDispalyed = propertyDropdownData?.filter((d) =>
            res?.payload?.data?.map((d) => d.property_uuid).includes(d?.value)
          );

          setHorizontalChartXAxisLabels(
            dataShouldBeDispalyed?.map((d) => d.label)
          );

          for (let index = 0; index < dataShouldBeDispalyed.length; index++) {
            // Critical Data
            criticalStatusData.push(
              res?.payload?.data?.find(
                (d) =>
                  d.property_uuid === dataShouldBeDispalyed[index]?.value &&
                  d?.severity === CRITICAL_STATUS
              )?.count || 0
            );
            // Non Critical Data
            nonCriticalStatusData.push(
              res?.payload?.data?.find(
                (d) =>
                  d.property_uuid === dataShouldBeDispalyed[index]?.value &&
                  d?.severity === NON_CRITICAL_STATUS
              )?.count || 0
            );

            // Non Critical Data
            nonConformanceStatusData.push(
              res?.payload?.data?.find(
                (d) =>
                  d.property_uuid === dataShouldBeDispalyed[index]?.value &&
                  d?.severity === NON_CONFORMANCE_STATUS
              )?.count || 0
            );

            // Recommendation Data
            recommendationStatusData.push(
              res?.payload?.data?.find(
                (d) =>
                  d.property_uuid === dataShouldBeDispalyed[index]?.value &&
                  d?.severity === RECOMMENDATION_STATUS
              )?.count || 0
            );
          }

          const finalData = [
            {
              data: criticalStatusData,
              label: t('common.status.critical'),
              id: CRITICAL_STATUS,
              stack: 'total',
            },
            {
              data: nonCriticalStatusData,
              label: t('common.status.non_critical'),
              id: NON_CRITICAL_STATUS,
              stack: 'total',
            },
            {
              data: nonConformanceStatusData,
              label: t('common.status.non_conformance'),
              id: NON_CONFORMANCE_STATUS,
              stack: 'total',
            },
            {
              data: recommendationStatusData,
              label: t('common.status.recommendation'),
              id: RECOMMENDATION_STATUS,
              stack: 'total',
            },
          ];

          setHorizontalChartData(finalData);
        })
        .finally(() =>
          setChartLoading((prev) => ({ ...prev, severity: false }))
        );
    }
  }, [
    ranges?.defectType,
    ranges?.defectFrequencyForHorizontalChart,
    equipmentTypesDropdownData,
    refresh,
  ]);

  const handleCardClick = (status) => {
    if (status !== DASHBOARD) {
      navigate(
        getRedirectURL()[role][ROUTE_NAVIGATE_CONST.DEFECTS_STATUS]?.replace(
          ROUTENAME.DEFECT_STATUS,
          status
        )
      );
    } else {
      navigate(getRedirectURL()[role][ROUTE_NAVIGATE_CONST.DEFECTS]);
    }
  };

  const isHorizontalData = horizontalChartData
    ?.map((c) => c?.data?.reduce((c, t) => c + t, 0))
    ?.reduce((c, t) => c + t, 0);

  return (
    <Box sx={{ bgcolor: DIVIDER_COLOR, width: '100%' }}>
      <Box sx={{ width: '100%', padding: '16px 0 30px 0px' }}>
        <Grid container spacing={2}>
          {defectCard?.map((item) => (
            <Grid xs={12} sm={6} md={4} key={uniqueId('dashboardCard')}>
              <DashboardCard
                title={item.title}
                value={item.value}
                onClick={() => handleCardClick(item.status)}
                isLoading={isLoading}
              />
            </Grid>
          ))}
        </Grid>
        <ChartWrapper>
          <SpaceBetween
            sx={{
              width: '100%',
              padding: '8px',
              borderBottom: `1px solid ${DIVIDER_COLOR}`,
            }}
          >
            <Typography variant="body1">{'Defects'}</Typography>
            <FlexEnd style={{ columnGap: '8px' }}>
              <CustomAutocomplete
                options={CUSTOMER_DASHBOARD_DEFECT_VERTICAL_CHART}
                value={ranges?.defectTypeByStatus}
                onChange={(e, newVal) =>
                  setRanges((prev) => ({ ...prev, defectTypeByStatus: newVal }))
                }
                disableClearable
              />
              <CustomAutocomplete
                options={MONTHS_DROPDOWN}
                value={ranges?.defectFrequency}
                onChange={(e, newVal) =>
                  setRanges((prev) => ({ ...prev, defectFrequency: newVal }))
                }
                disableClearable
              />
            </FlexEnd>
          </SpaceBetween>

          <Box sx={{ width: '100%', position: 'relative', height: '300px' }}>
            {chartLoading?.status ? (
              <CustomCircularLoader />
            ) : (
              <StackedBarChart
                colors={
                  ranges?.defectTypeByStatus?.value === STATUS
                    ? statusColor
                    : colors
                }
                data={verticalChartData}
                xLabels={xAxisLabel}
                isLegendDisplay={false}
              />
            )}
          </Box>
        </ChartWrapper>
        <ChartWrapper>
          <SpaceBetween
            sx={{
              width: '100%',
              padding: '8px',
              borderBottom: `1px solid ${DIVIDER_COLOR}`,
            }}
          >
            <Typography variant="body1">{'Defects'}</Typography>
            <FlexEnd style={{ columnGap: '8px' }}>
              <CustomAutocomplete
                options={CUSTOMER_DASHBOARD_DEFECT_HORIZONTAL_CHART}
                value={ranges?.defectType}
                onChange={(e, newVal) =>
                  setRanges((prev) => ({ ...prev, defectType: newVal }))
                }
                disableClearable
              />
              <CustomAutocomplete
                options={MONTHS_DROPDOWN}
                value={ranges?.defectFrequencyForHorizontalChart}
                onChange={(e, newVal) =>
                  setRanges((prev) => ({
                    ...prev,
                    defectFrequencyForHorizontalChart: newVal,
                  }))
                }
                disableClearable
              />
            </FlexEnd>
          </SpaceBetween>
          <Box
            sx={{
              width: '100%',
              position: 'relative',
              height: '400px',
            }}
          >
            {chartLoading?.severity ? (
              <CustomCircularLoader />
            ) : (
              <StackedBarChart
                colors={colors}
                data={isHorizontalData > 0 ? horizontalChartData : []}
                isLegendDisplay={false}
                xLabels={isHorizontalData > 0 ? horizontalChartXAxisLabels : []}
                layout="horizontal"
                isXaxis={false}
                margin={{
                  left: isHorizontalData > 0 ? 300 : 0,
                }}
              />
            )}
          </Box>
        </ChartWrapper>
      </Box>
    </Box>
  );
};

export default DefectDashboard;
