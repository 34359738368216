import axios from 'axios';

import { navigate } from '../App';
import { COMMON_ERROR_MESSAGE } from '../constants/Constants';
import { ROUTENAME } from '../constants/RoutesConstants';

const {
  REACT_APP_API_END_POINT,
  REACT_APP_GOOGLE_MAP_VALIDATE_ADDRESS_END_POINT,
  REACT_APP_GOOGLE_MAP_ADDRESS_AUTOCOMPLETE_END_POINT,
  REACT_APP_SERVICE_FIRE,
  REACT_APP_SERVICE_AUTH,
  REACT_APP_SERVICE_PROPERTY,
  REACT_APP_SERVICE_JOBINSPECTION,
  REACT_APP_SERVICE_REPORTING,
  REACT_APP_SERVICE_BILLING,
  REACT_APP_SERVICE_DOCUMENTS,
  REACT_APP_NOTIFICATION,
} = process.env;

const axiosInstances = {
  axiosInstanceBase: axios.create({
    baseURL: REACT_APP_API_END_POINT,
  }),
  axiosInstanceCompany: axios.create({
    baseURL: `${REACT_APP_API_END_POINT}/${REACT_APP_SERVICE_FIRE}/`,
  }),
  axiosNotification: axios.create({
    baseURL: `${REACT_APP_API_END_POINT}/${REACT_APP_NOTIFICATION}/`,
  }),
  axiosInstanceAuthAndFilter: axios.create({
    baseURL: `${REACT_APP_API_END_POINT}/${REACT_APP_SERVICE_AUTH}/`,
  }),
  axiosPropertyUrl: axios.create({
    baseURL: `${REACT_APP_API_END_POINT}/${REACT_APP_SERVICE_PROPERTY}/`,
  }),
  axiosInstanceTechnician: axios.create({
    baseURL: `${REACT_APP_API_END_POINT}/${REACT_APP_SERVICE_JOBINSPECTION}/`,
  }),
  axiosInstanceReporting: axios.create({
    baseURL: `${REACT_APP_API_END_POINT}/${REACT_APP_SERVICE_REPORTING}/`,
  }),
  axiosInstanceBilling: axios.create({
    baseURL: `${REACT_APP_API_END_POINT}/${REACT_APP_SERVICE_BILLING}/`,
  }),
  axiosInstanceDocument: axios.create({
    baseURL: `${REACT_APP_API_END_POINT}/${REACT_APP_SERVICE_DOCUMENTS}/`,
  }),
};

const addRequestInterceptor = (axiosInstance) =>
  axiosInstance.interceptors.request.use(
    (config) => {
      const idToken = localStorage.getItem('idToken');
      const tokenType = localStorage.getItem('token_type');

      if (idToken) {
        config.headers['Authorization'] = `Bearer ${idToken}`;
      }

      if (tokenType) {
        config.headers['Auth-type'] = tokenType;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

const addResponseInterceptor = (axiosInstance) =>
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      let customError;

      if (error.response) {
        if (error.response.status === 500) {
          customError = {
            status: error.response.data.status,
            message: COMMON_ERROR_MESSAGE.default,
            errorDetails: error.response.data?.errors,
          };
        } else if (error.response.status === 401) {
          navigate(ROUTENAME.UNAUTHORIZED);
        } else {
          customError = {
            status: error.response.data.status,
            message:
              error?.response?.data?.message === 'Invalid token'
                ? null
                : error?.response.data.message || COMMON_ERROR_MESSAGE.default,
            errorDetails: error.response.data?.errors,
          };
        }
      } else if (error.request) {
        if (error?.config?.url === 'me') {
          customError = {
            message: `Sorry, you are not allowed to view this resource.<br />If you need access, please contact an administrator or sign in with the correct account.`,
          };
        } else {
          customError = {
            status: error.request.status,
            message: error?.message || COMMON_ERROR_MESSAGE.default,
            errorDetails: error.request.data?.errors,
          };
        }
      } else if (error?.code === 'ERR_NETWORK') {
        navigate(ROUTENAME.UNDERMAINTENANCE);
      } else {
        customError = {
          status: COMMON_ERROR_MESSAGE.default,
          message: COMMON_ERROR_MESSAGE.default,
          errorDetails: COMMON_ERROR_MESSAGE.default,
        };
      }

      return Promise.reject(customError);
    }
  );

Object.values(axiosInstances).forEach((axiosInstance) => {
  addRequestInterceptor(axiosInstance);
  addResponseInterceptor(axiosInstance);
});

export const {
  axiosInstanceAuthAndFilter,
  axiosInstanceCompany,
  axiosInstanceBase,
  axiosInstanceReporting,
  axiosInstanceTechnician,
  axiosPropertyUrl,
  axiosInstanceDocument,
  axiosInstanceBilling,
  axiosNotification,
} = axiosInstances;

export const axiosInstanceGoogleMapAPI = axios.create({
  baseURL: `${REACT_APP_GOOGLE_MAP_VALIDATE_ADDRESS_END_POINT}`,
});

export const axiosInstanceGoogle = axios.create({
  baseURL: REACT_APP_GOOGLE_MAP_ADDRESS_AUTOCOMPLETE_END_POINT,
});
