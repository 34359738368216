import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Box } from '@mui/material';

import { DIVIDER_COLOR } from '../../constants/Colors';
import { ROUTE_NAVIGATE_CONST } from '../../constants/Constants';
import { getUserRole } from '../../hoc/Permission';
import useDetailPageValidation from '../../hooks/useDetailPageValidation';
import { getDefectQuotesDetail } from '../../store/workOrder/api';
import { getRedirectURL } from '../../utils';
import MainWrapper from '../CommonComponents/MainWrapper';
import DefectQuotesView from '../WorkOrder/DefectQuotesView';

const DefectQuotesEdit = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const outletProps = useOutletContext();
  const [id, setId] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const {
    woId: uuid,
    nextStepTitle,
    setRefresh,
  } = { ...props, ...outletProps };

  useEffect(() => {
    if (uuid) {
      dispatch(
        getDefectQuotesDetail({
          quote_id: uuid,
        })
      )
        .then((res) =>
          setId({
            id: `Q-${res?.payload?.data?.[0]?.id}`,
            woUuid: res?.payload?.data?.[0]?.work_order_uuid,
          })
        )
        .finally(() => setLoading(false));
    }
  }, []);

  useDetailPageValidation({
    is_error: false,
    data: id?.woUuid ? id : [],
    is_loading: loading,
  });

  return (
    <>
      <Box sx={{ bgcolor: DIVIDER_COLOR, paddingBottom: '16px' }}>
        <MainWrapper
          defaultPadding="0px"
          title={`${t('attributes.defects.defects')} ${t('attributes.customer.quotes')}`}
          isStep={true}
          step={nextStepTitle?.step || id?.id}
          handleDefaultStep={() => {
            navigate(
              getRedirectURL()[getUserRole()][ROUTE_NAVIGATE_CONST.QUOTES]
            );
          }}
        ></MainWrapper>
      </Box>
      <DefectQuotesView workOrderId={id?.woUuid} setRefresh={setRefresh} />
    </>
  );
};

export default DefectQuotesEdit;
