import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider, Typography } from '@mui/material';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../../assets/commonStyled';
import { Validation } from '../../../constants/FieldValidationMsg';
import { getSiteRequirement } from '../../../store/property/api';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import CustomTextField from '../../CommonComponents/CustomTextField';

const SiteRequirementForm = ({ id, reset, control, trigger, setValue }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading: isLoading, data: editSiteRequirement } = useSelector(
    (state) => state.property.getSiteRequirement
  );

  useEffect(() => {
    if (id) {
      dispatch(getSiteRequirement(id));
    }
  }, []);

  useEffect(() => {
    if (!isLoading && editSiteRequirement && id) {
      reset({
        text: editSiteRequirement?.data?.[0]?.text,
      });
    }
  }, [isLoading, editSiteRequirement]);

  const clearHandler = (name) => setValue(name, '');

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">
            {`${t('attributes.configuration.siteRequirement.title')} ${t('common.details')}`}
          </Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="text"
              control={control}
              rules={{
                required: `${t('attributes.configuration.siteRequirement.title')} ${t('attributes.name')} ${Validation.general.required}`,
                maxLength: {
                  value: 256,
                  message: Validation.configuration.siteRequirement.name,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={`${t('attributes.configuration.siteRequirement.title')} ${t('attributes.name')}`}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('text');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
          </Box>
        </FormFieldWrapper>
      </CustomCard>
    </FormWrapper>
  );
};

export default SiteRequirementForm;
