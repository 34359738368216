import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Divider, styled, Typography } from '@mui/material';

import { DIVIDER_COLOR } from '../../constants/Colors';
import { getUserRole } from '../../hoc/Permission';
import useBrowserBackButtonHandler from '../../hooks/useBrowserBackButtonHandler';
import useDetailPageValidation from '../../hooks/useDetailPageValidation';
import useServerSideErrors from '../../hooks/useServerSideErrors';
// eslint-disable-next-line import-helpers/order-imports
// eslint-disable-next-line import-helpers/order-imports
import { ROUTE_NAVIGATE_CONST } from '../../constants/Constants';
import { snackbarToggle } from '../../store/CommonReducer';
import { editContact, updateContact } from '../../store/contacts/api';
import { resetCreate } from '../../store/contacts/reducer';
import { getRedirectURL, showHideTopbarLoader } from '../../utils';
import CustomButton from '../CommonComponents/CustomButton';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import MainWrapper from '../CommonComponents/MainWrapper';
import { ConfirmationModal } from '../CommonComponents/Modal';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import ContactForm from './ContactForm';
import { defaultValues } from './Contacts';

const StyledHeader = styled(Box)({
  padding: '16px',
  backgroundColor: 'rgba(235, 235, 235, 0.2)',
});

const SideWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
  padding: '16px',
  width: '100%',
});

const KeyValueComponent = ({
  name,
  value,
  minWidth = '180px',
  isCapitalize = true,
}) => (
  <Box sx={{ display: 'flex' }}>
    <Typography variant="body1" minWidth={minWidth}>
      {`${name}:`}
    </Typography>
    <Typography
      variant="body2"
      sx={{ textTransform: isCapitalize ? 'capitalize' : '' }}
    >
      {value}
    </Typography>
  </Box>
);

const ViewContactCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState(null);
  const [openEditConfirmationModal, setOpenEditConfirmationModal] =
    useState(false);
  const [hasFormValues, sethasFormValues] = useState(false);

  const [serverErrors, setServerErrors] = useState([]);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    watch,
    trigger,
  } = useForm({
    defaultValues: defaultValues,
  });

  useBrowserBackButtonHandler({
    hasUnsavedChanges: hasFormValues,
    setOpenConfirmationModal: setOpenEditConfirmationModal,
    open,
    watchMethod: watch,
    sethasFormValues,
  });

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );
  const {
    error,
    createdContact,
    isLoading: createLoading,
  } = useSelector((state) => state?.contact?.create);

  const { isLoading: customerLoading } = useSelector(
    (state) => state.customer.edit
  );

  useDetailPageValidation({
    is_error: false,
    data: details,
    is_loading: loading,
  });

  useEffect(() => {
    if (serverErrors) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const getContactData = () => {
    setLoading(true);

    dispatch(editContact(params?.contact_uuid))
      .then((res) => setDetails(res?.payload?.data?.[0] || []))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getContactData();
  }, [params?.contact_uuid]);

  useEffect(
    () => () => {
      dispatch(resetCreate());
    },
    []
  );

  const onSubmit = (value) => {
    const bodyData = {
      name: value.name,
      email: value.email,
      contact_phone: value.contact_phone,
      address: value.address,
      address_line_2: value.address_line_2 || '',
      city: value.city?.value,
      state: value.state.value,
      postcode: value.postcode,
      is_active: value.active,
    };

    dispatch(updateContact({ uuid: params?.contact_uuid, data: bodyData }));
  };

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  useEffect(() => {
    showHideTopbarLoader(createLoading, dispatch);
    if (!createLoading) {
      if (error) {
        setServerErrors(error?.errorDetails);
      }
      if (!error && createdContact) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: createdContact?.message,
          })
        );
        getContactData();
        setOpen(false);
      }
    }
  }, [error, createLoading]);

  return loading ? (
    <CustomCircularLoader />
  ) : (
    <>
      <Box sx={{ bgcolor: DIVIDER_COLOR, paddingBottom: '16px' }}>
        <MainWrapper
          defaultPadding="0px"
          title={t('attributes.masterContact.contact')}
          variant="body1"
          isStep={true}
          step={`C-${details?.user_id}`}
          handleDefaultStep={() =>
            navigate(
              getRedirectURL()[getUserRole()][ROUTE_NAVIGATE_CONST.CONTACT]
            )
          }
        ></MainWrapper>
      </Box>
      <Box sx={{ overflow: 'auto', height: 'calc(100vh - 310px)' }}>
        <Box>
          <StyledHeader
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="body1">
              {t('attributes.masterContact.contact')}
            </Typography>
            <EditIcon onClick={() => setOpen(true)} />
          </StyledHeader>
          <Divider />
          <StyledHeader>
            <Typography variant="body1">
              {`${t('attributes.contact')} ${t('attributes.masterContact.details')}`}
            </Typography>
          </StyledHeader>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
            }}
          >
            <SideWrapper>
              <KeyValueComponent
                name={t('attributes.name')}
                value={details?.name || '-'}
              />
              <KeyValueComponent
                name={t('attributes.email')}
                value={details?.email || '-'}
              />
              <KeyValueComponent
                name={t('attributes.masterContact.contact_phone')}
                value={details?.contact_phone || '-'}
              />
              <KeyValueComponent
                name={t('attributes.technician.contact_address')}
                value={
                  [
                    details?.address,
                    details?.address_line_2,
                    details?.city || null,
                    details?.state,
                    details?.postcode,
                  ]
                    .filter((value) => value && value !== 'null') // Remove null, undefined, or empty values
                    .join(', ') || '-'
                }
              />
            </SideWrapper>
          </Box>
          <Divider />
        </Box>
      </Box>
      {open && (
        <SwipeableDrawer
          open={open}
          title={t('attributes.billingCard.editBill')}
          onClose={() => {
            hasFormValues ? setOpenEditConfirmationModal(true) : setOpen(false);
          }}
          footerButton={
            <CustomButton
              text={t('attributes.save')}
              startIcon={<SaveIcon />}
              disabled={createLoading || customerLoading}
              onClick={handleSubmit(onSubmit)}
            />
          }
          width="500px"
        >
          <ContactForm
            id={params?.contact_uuid}
            watch={watch}
            trigger={trigger}
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
            setServerErrors={setServerErrors}
            stateVal={details?.state?.value}
            reset={reset}
          />
        </SwipeableDrawer>
      )}
      {openEditConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openEditConfirmationModal}
          setOpen={setOpenEditConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenEditConfirmationModal(false);
            sethasFormValues(false);
          }}
        />
      )}
    </>
  );
};

export default ViewContactCard;
