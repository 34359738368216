import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../constants/APIConstants';
import {
  CUSTOMER,
  GLOBAL_ADMIN,
  OFFICE_MANAGEMENT,
  OFFICE_STAFF,
  SUPER_ADMIN,
} from '../constants/Roles';
import {
  ACCOUNT_PARTNER_CHECK,
  GET_DOCUMENT,
  GET_ME,
  GET_STATE_CITY_LIST,
  GET_USER_WIDGETS,
  GET_WIDGETS,
  UPDATE_PROFILE,
  UPDATE_WIDGETS,
  UPLOAD_DOCUMENT,
} from './actionPrefix';
import {
  axiosInstanceAuthAndFilter,
  axiosInstanceBase,
  axiosInstanceBilling,
  axiosInstanceCompany,
  axiosInstanceReporting,
} from './axios';

export const getMe = createAsyncThunk(GET_ME, async () => {
  const response = await axiosInstanceAuthAndFilter.get('me');
  const { data } = response;

  const isSuccessfulResponse = data.success || response.status === 200;
  const hasWebPortal = data.portals.some((item) => item.field_value === 'web');

  if (isSuccessfulResponse && hasWebPortal) {
    const roleName = data.role_name;
    const profileUUID = data.profile_uuid;

    let APIEndPoint = null;
    let axiosInstance = null;

    switch (roleName) {
      case SUPER_ADMIN:
        APIEndPoint = APIs.SUPER_ADMIN.GET_SUPER_ADMIN_BY_ID.replace(
          '{uuid}',
          profileUUID
        );
        axiosInstance = axiosInstanceAuthAndFilter;
        break;
      case CUSTOMER:
        APIEndPoint = APIs.CUSTOMER.GET_CUSTOMER_BY_ID.replace(
          '{uuid}',
          profileUUID
        );
        axiosInstance = axiosInstanceCompany;
        break;
      case OFFICE_STAFF:
        APIEndPoint = APIs.OFFICE_STAFF.GET_OFFICE_STAFF_BY_ID.replace(
          '{id}',
          profileUUID
        );
        axiosInstance = axiosInstanceAuthAndFilter;
        break;
      case OFFICE_MANAGEMENT:
        APIEndPoint =
          APIs.OFFICE_MANAGEMENT.GET_OFFICE_MANAGEMENT_BY_ID.replace(
            '{id}',
            profileUUID
          );
        axiosInstance = axiosInstanceAuthAndFilter;
        break;
      default:
        break;
    }

    if (APIEndPoint) {
      const getProfileResponse = await axiosInstance.get(APIEndPoint);
      const { data: profieData } = getProfileResponse;
      const profileData = profieData?.data?.[0];

      localStorage.setItem('user_info', JSON.stringify(data));
      localStorage.setItem('role_name', roleName);
      localStorage.setItem('uuid', data?.uuid);
      const getAppVersion = await getLatestVersion();

      return { ...profileData, ...response.data, app_version: getAppVersion };
    }

    if (roleName === GLOBAL_ADMIN) {
      localStorage.setItem('user_info', JSON.stringify(data));
      localStorage.setItem('role_name', roleName);
      const getAppVersion = await getLatestVersion();

      return {
        ...response.data,
        name: 'Global Admin',
        app_version: getAppVersion,
      };
    }

    return response;
  }

  return response;
});

export const getLocation = createAsyncThunk(GET_STATE_CITY_LIST, async () => {
  const params = new URLSearchParams();

  params.append('include_ids', false);
  params.append('include_inactive', true);

  try {
    const response =
      await axiosInstanceCompany.get(`${APIs.CITY.CITY_LIST}?${params.toString()}
      `);

    return response.data;
  } catch (error) {
    console.error(error);
  }
});

export const uploadDocument = createAsyncThunk(
  UPLOAD_DOCUMENT,
  async ({ document_type = 'agreement', uploaded_by = 1, file }) => {
    let formData = new FormData();

    formData.append('file', file);
    const response = await axiosInstanceBase.post(
      `documents/upload`,
      formData,
      {
        params: {
          document_type: document_type,
          uploaded_by: uploaded_by,
        },
      }
    );

    return response.data;
  }
);

export const getDocument = createAsyncThunk(
  GET_DOCUMENT,
  async ({ doc_uuid, isPreview = '' }) => {
    const response = await axiosInstanceBase.get(
      `/documents/doc/${doc_uuid}?include_presigned_url=true${isPreview}`
    );

    return response.data;
  }
);

export const updateProfile = createAsyncThunk(
  UPDATE_PROFILE,
  async ({ uuid, value }) => {
    const response = await axiosInstanceAuthAndFilter.patch(
      APIs.PROFILE.UPDATE_PROFILE.replace(':uuid', uuid),
      { profile_pic_document_uuid: value }
    );

    return response.data;
  }
);

export const getLatestVersion = async () => {
  try {
    const response = await fetch(`${window.location.origin}/manifest.json`);
    const data = await response.json();

    return data.version;
  } catch (error) {
    console.error('Failed to fetch the latest version:', error);

    return '-';
  }
};

// Get Widget Data By ROle
export const getWidgetDataRole = createAsyncThunk(
  GET_WIDGETS,
  async (role_name, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceReporting.get(
        APIs.WIDGET.GET_WIDGETS.replace(':role_name', role_name)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Widget Data By USER
export const getWidgetDataByUser = createAsyncThunk(
  GET_USER_WIDGETS,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceReporting.get(
        APIs.WIDGET.GET_USER_WIDGET.replace(':user_id', uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCharts = createAsyncThunk(
  UPDATE_WIDGETS,
  async ({ uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceReporting.put(
        APIs.WIDGET.UPDATE_WIDGETS.replace(':user_id', uuid),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const accountPartnerHealthCheck = createAsyncThunk(
  ACCOUNT_PARTNER_CHECK,
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.get(
        APIs.INTEGRATIONS.HEALTH_ACCOUNT_PARTNER_CONFIGURATION
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
