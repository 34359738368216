import { createSlice } from '@reduxjs/toolkit';

import {
  addAccessProcedure,
  addAccessSchedule,
  addAssetVariant,
  addSiteRequirement,
  createAsset,
  createBuildingType,
  createProperty,
  createWorkOrderFromAsset,
  getAccessProcedure,
  getAccessProceduresList,
  getAccessSchedule,
  getAccessScheduleList,
  getAssetDetails,
  getAssetHistory,
  getAssetHistoryMessages,
  getAssetStatus,
  getAssetVariantsList,
  getBuildingClassList,
  getBuildingEraList,
  getBuildingStandardList,
  getBuildingType,
  getBuildingTypeList,
  getConstructionClassList,
  getEquipmentTypes,
  getLocalGovernmentAndZone,
  getLocalGovernmentAreaList,
  getParentPropertyList,
  getProducts,
  getPropertyAccessProcedureList,
  getPropertyAccessScheduleList,
  getPropertyAccountManagerList,
  getPropertyAssetsList,
  getPropertyBillingCardList,
  getPropertyCustomerList,
  getPropertyList,
  getPropertyStatusList,
  getPropertyTagsList,
  getRoutineList,
  getRoutineStandardFrequencyList,
  getSiteRequirement,
  getSiteRequirementsList,
  updateAccessProcedure,
  updateAccessSchedule,
  updateAsset,
  updateAssetVariant,
  updateBuildingType,
  updateProperty,
  updateRoutine,
  updateSiteRequirement,
} from './api';

const initialObjectData = {
  loading: false,
  data: false,
  error: false,
};

const propertySlice = createSlice({
  name: 'property',
  initialState: {
    propertyList: {
      loading: false,
      error: false,
      data: false,
      totalCount: 0,
      total: 0,
    },
    updatePropertyList: {
      loading: null,
      error: false,
      data: false,
    },
    createPropertyList: {
      loading: null,
      error: false,
      data: false,
    },
    propertyStatusList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyTagList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyAccessScheduleList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyAccessProcedureList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyBuildingEraList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyBuildingClassList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyConstructionClassList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyBuildingTypeList: {
      loading: true,
      error: null,
      data: [],
      page: 1,
      size: 10,
      total: 0,
      totalCount: 0,
    },
    propertyBuildingStandardList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyLocalGovernmentAreaList: {
      loading: false,
      error: false,
      data: false,
    },
    parentPropertyList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyAccountManagerList: { loading: false, error: false, data: false },
    propertyCustomerList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyBillingCardList: {
      loading: true,
      error: false,
      data: [],
    },
    assetsList: {
      loading: true,
      data: [],
      error: false,
      totalCount: 0,
      total: 0,
    },
    routinesList: {
      loading: true,
      data: [],
      error: false,
      totalCount: 0,
      total: 0,
    },
    routineStandardFrequencyList: {
      loading: true,
      data: [],
      error: false,
      totalCount: 0,
      total: 0,
    },
    assetVariantsList: {
      loading: true,
      error: null,
      data: [],
      page: 1,
      size: 10,
      total: 0,
      totalCount: 0,
    },
    localGovernmentAndZone: initialObjectData,
    equipmentTypesList: initialObjectData,
    assetStatusList: initialObjectData,
    products: initialObjectData,
    createAsset: initialObjectData,
    updatedAsset: initialObjectData,
    updatedRoutine: initialObjectData,
    assetDetails: initialObjectData,
    assetHistoryMessage: { ...initialObjectData, loading: true },
    assetHistory: { ...initialObjectData, loading: true },
    createWorkOrder: {
      loading: null,
      error: false,
      data: false,
    },
    getBuildingType: {
      loading: null,
      data: false,
      error: false,
    },
    createBuildingType: {
      loading: null,
      data: false,
      error: false,
    },
    updateBuildingType: {
      loading: null,
      data: false,
      error: false,
    },
    assetVariant: {
      loading: null,
      data: false,
      error: false,
    },
    updateAssetVariant: {
      loading: null,
      data: false,
      error: false,
    },
    createSiteRequirement: {
      ...initialObjectData,
    },
    getSiteRequirementList: {
      loading: true,
      error: null,
      data: [],
      page: 1,
      size: 10,
      total: 0,
      totalCount: 0,
    },
    getSiteRequirement: {
      ...initialObjectData,
    },
    createAccessProcedure: {
      ...initialObjectData,
    },
    getAccessProcedureList: {
      loading: true,
      error: null,
      data: [],
      page: 1,
      size: 10,
      total: 0,
      totalCount: 0,
    },
    getAccessProcedure: {
      ...initialObjectData,
    },
    createAccessSchedule: {
      ...initialObjectData,
    },
    getAccessSchedule: {
      ...initialObjectData,
    },
    getAccessScheduleList: {
      loading: true,
      error: null,
      data: [],
      page: 1,
      size: 10,
      total: 0,
      totalCount: 0,
    },
  },
  reducers: {
    resetPropertyList(state) {
      state.propertyList = {
        loading: false,
        error: false,
        data: false,
        totalCount: 0,
        total: 0,
      };
    },
    resetAssetsList(state) {
      state.assetsList = {
        loading: true,
        data: [],
        error: false,
        totalCount: 0,
        total: 0,
      };
    },
    resetRoutineList(state) {
      state.routinesList = {
        loading: true,
        data: [],
        error: false,
        totalCount: 0,
        total: 0,
      };
    },
    resetRoutineStandardFrequencyList(state) {
      state.routineStandardFrequencyList = {
        loading: true,
        data: [],
        error: false,
        totalCount: 0,
        total: 0,
      };
    },
    resetUpdatePropertyList(state) {
      state.updatePropertyList = { ...initialObjectData };
    },
    resetCreatePropertyList(state) {
      state.createPropertyList = { ...initialObjectData };
    },

    resetUpdateAsset(state) {
      state.updatedAsset = { ...initialObjectData };
    },
    resetAssetDetails(state) {
      state.assetDetails = { ...initialObjectData };
    },
    resetcreateAsset(state) {
      state.createAsset = { ...initialObjectData };
    },

    resetUpdateRoutine(state) {
      state.updatedRoutine = { ...initialObjectData };
    },
    resetParentPropertyList(state) {
      state.parentPropertyList = { ...initialObjectData };
    },
    resetLocalGovernmentAndZone(state) {
      state.localGovernmentAndZone = { ...initialObjectData };
    },
    resetPropertyBillingCardList(state) {
      state.propertyBillingCardList = {
        loading: true,
        error: false,
        data: [],
      };
    },
    resetPropertyCustomerList(state) {
      state.propertyCustomerList = { ...initialObjectData };
    },
    resetAssetHistoryMessage(state) {
      state.assetHistoryMessage = { ...initialObjectData, loading: true };
    },
    resetAssetHistory(state) {
      state.assetHistory = { ...initialObjectData, loading: true };
    },
    resetCreateWorkOrderFromAsset(state) {
      state.createWorkOrder = { ...initialObjectData };
    },
    resetCreateBuildingType(state) {
      state.createBuildingType = { ...initialObjectData };
    },
    resetBuildingTypeList(state) {
      state.propertyBuildingTypeList = {
        loading: true,
        error: null,
        data: [],
        page: 1,
        size: 10,
        total: 0,
        totalCount: 0,
      };
    },
    resetGetBuildingType(state) {
      state.getBuildingType = { ...initialObjectData };
    },
    resetUpdateBuildingType(state) {
      state.updateBuildingType = { ...initialObjectData };
    },
    resetAssetVariantType(state) {
      state.assetVariant = { ...initialObjectData };
    },
    resetUpdateAssetVariantType(state) {
      state.updateAssetVariant = { ...initialObjectData };
    },
    resetCreateSiteRequirement(state) {
      state.createSiteRequirement = { ...initialObjectData };
    },
    resetSiteRequirementList(state) {
      state.getSiteRequirementList = {
        loading: true,
        error: null,
        data: [],
        page: 1,
        size: 10,
        total: 0,
        totalCount: 0,
      };
    },
    resetGetSiteRequirement(state) {
      state.getSiteRequirement = { ...initialObjectData };
    },
    resetCreateAccessProcedure(state) {
      state.createAccessProcedure = { ...initialObjectData };
    },
    resetAccessProcedureList(state) {
      state.getAccessProcedureList = {
        loading: true,
        error: null,
        data: [],
        page: 1,
        size: 10,
        total: 0,
        totalCount: 0,
      };
    },
    resetGetAccessProcedure(state) {
      state.getAccessProcedure = { ...initialObjectData };
    },
    resetCreateAccessSchedule(state) {
      state.createAccessSchedule = { ...initialObjectData };
    },
    resetAccessScheduleList(state) {
      state.getAccessScheduleList = {
        loading: true,
        error: null,
        data: [],
        page: 1,
        size: 10,
        total: 0,
        totalCount: 0,
      };
    },
    resetGetAccessSchedule(state) {
      state.getAccessSchedule = { ...initialObjectData };
    },
  },
  extraReducers: (builder) => {
    builder
      // Property List
      .addCase(getPropertyList.pending, (state) => {
        state.propertyList.loading = true;
      })
      .addCase(getPropertyList.fulfilled, (state, action) => {
        state.propertyList.loading = false;
        state.propertyList.data = action.payload;
        state.propertyList.totalCount = action.payload.total_records;
        state.propertyList.total = action.payload.total;
      })
      .addCase(getPropertyList.rejected, (state, action) => {
        state.propertyList.loading = false;
        state.propertyList.error = action.error.message;
      })

      // Update Property
      .addCase(updateProperty.pending, (state) => {
        state.updatePropertyList.loading = true;
      })
      .addCase(updateProperty.fulfilled, (state, action) => {
        state.updatePropertyList.loading = false;
        state.updatePropertyList.error = false;
        state.updatePropertyList.data = action.payload;
      })
      .addCase(updateProperty.rejected, (state, action) => {
        state.updatePropertyList.loading = false;
        state.updatePropertyList.error = action.payload;
      })

      // Create Property
      .addCase(createProperty.pending, (state) => {
        state.createPropertyList.loading = true;
      })
      .addCase(createProperty.fulfilled, (state, action) => {
        state.createPropertyList.loading = false;
        state.createPropertyList.error = false;
        state.createPropertyList.data = action.payload;
      })
      .addCase(createProperty.rejected, (state, action) => {
        state.createPropertyList.loading = false;
        state.createPropertyList.error = action.payload;
      })
      // get property status list
      .addCase(getPropertyStatusList.pending, (state) => {
        state.propertyStatusList.loading = true;
      })
      .addCase(getPropertyStatusList.fulfilled, (state, action) => {
        state.propertyStatusList.loading = false;
        state.propertyStatusList.data = action.payload;
      })
      .addCase(getPropertyStatusList.rejected, (state, action) => {
        state.propertyStatusList.loading = false;
        state.propertyStatusList.error = action.error.message;
      })
      // get property tag list
      .addCase(getPropertyTagsList.pending, (state) => {
        state.propertyTagList.loading = true;
      })
      .addCase(getPropertyTagsList.fulfilled, (state, action) => {
        state.propertyTagList.loading = false;
        state.propertyTagList.data = action.payload;
      })
      .addCase(getPropertyTagsList.rejected, (state, action) => {
        state.propertyTagList.loading = false;
        state.propertyTagList.error = action.error.message;
      })
      // Property access schedule list
      .addCase(getPropertyAccessScheduleList.pending, (state) => {
        state.propertyAccessScheduleList.loading = true;
      })
      .addCase(getPropertyAccessScheduleList.fulfilled, (state, action) => {
        state.propertyAccessScheduleList.loading = false;
        state.propertyAccessScheduleList.data = action.payload;
      })
      .addCase(getPropertyAccessScheduleList.rejected, (state, action) => {
        state.propertyAccessScheduleList.loading = false;
        state.propertyAccessScheduleList.error = action.error.message;
      })
      // Property access procedure list
      .addCase(getPropertyAccessProcedureList.pending, (state) => {
        state.propertyAccessProcedureList.loading = true;
      })
      .addCase(getPropertyAccessProcedureList.fulfilled, (state, action) => {
        state.propertyAccessProcedureList.loading = false;
        state.propertyAccessProcedureList.data = action.payload;
      })
      .addCase(getPropertyAccessProcedureList.rejected, (state, action) => {
        state.propertyAccessProcedureList.loading = false;
        state.propertyAccessProcedureList.error = action.error.message;
      })
      // Building era list
      .addCase(getBuildingEraList.pending, (state) => {
        state.propertyBuildingEraList.loading = true;
      })
      .addCase(getBuildingEraList.fulfilled, (state, action) => {
        state.propertyBuildingEraList.loading = false;
        state.propertyBuildingEraList.data = action.payload;
      })
      .addCase(getBuildingEraList.rejected, (state, action) => {
        state.propertyBuildingEraList.loading = false;
        state.propertyBuildingEraList.error = action.error.message;
      })
      // Building class list
      .addCase(getBuildingClassList.pending, (state) => {
        state.propertyBuildingClassList.loading = true;
      })
      .addCase(getBuildingClassList.fulfilled, (state, action) => {
        state.propertyBuildingClassList.loading = false;
        state.propertyBuildingClassList.data = action.payload;
      })
      .addCase(getBuildingClassList.rejected, (state, action) => {
        state.propertyBuildingClassList.loading = false;
        state.propertyBuildingClassList.error = action.error.message;
      })
      // Construction class list
      .addCase(getConstructionClassList.pending, (state) => {
        state.propertyConstructionClassList.loading = true;
      })
      .addCase(getConstructionClassList.fulfilled, (state, action) => {
        state.propertyConstructionClassList.loading = false;
        state.propertyConstructionClassList.data = action.payload;
      })
      .addCase(getConstructionClassList.rejected, (state, action) => {
        state.propertyConstructionClassList.loading = false;
        state.propertyConstructionClassList.error = action.error.message;
      })

      // Building type list
      .addCase(getBuildingTypeList.pending, (state) => {
        state.propertyBuildingTypeList.loading = true;
      })
      .addCase(getBuildingTypeList.fulfilled, (state, action) => {
        const { data, is_active, total, total_records } = action.payload;

        state.propertyBuildingTypeList.loading = false;
        state.propertyBuildingTypeList.data = data;
        (state.propertyBuildingTypeList.total = total),
          (state.propertyBuildingTypeList.totalCount = total_records);
      })
      .addCase(getBuildingTypeList.rejected, (state, action) => {
        state.propertyBuildingTypeList.loading = false;
        state.propertyBuildingTypeList.error = action.error.message;
      })

      // Building standard list
      .addCase(getBuildingStandardList.pending, (state) => {
        state.propertyBuildingStandardList.loading = true;
      })
      .addCase(getBuildingStandardList.fulfilled, (state, action) => {
        state.propertyBuildingStandardList.loading = false;
        state.propertyBuildingStandardList.data = action.payload;
      })
      .addCase(getBuildingStandardList.rejected, (state, action) => {
        state.propertyBuildingStandardList.loading = false;
        state.propertyBuildingStandardList.error = action.error.message;
      })

      // Local government area list
      .addCase(getLocalGovernmentAreaList.pending, (state) => {
        state.propertyLocalGovernmentAreaList.loading = true;
      })
      .addCase(getLocalGovernmentAreaList.fulfilled, (state, action) => {
        state.propertyLocalGovernmentAreaList.loading = false;
        state.propertyLocalGovernmentAreaList.data = action.payload;
      })
      .addCase(getLocalGovernmentAreaList.rejected, (state, action) => {
        state.propertyLocalGovernmentAreaList.loading = false;
        state.propertyLocalGovernmentAreaList.error = action.error.message;
      })

      // Parent property list
      .addCase(getParentPropertyList.pending, (state) => {
        state.parentPropertyList.loading = true;
      })
      .addCase(getParentPropertyList.fulfilled, (state, action) => {
        state.parentPropertyList.loading = false;
        state.parentPropertyList.data = action.payload;
      })
      .addCase(getParentPropertyList.rejected, (state, action) => {
        state.parentPropertyList.loading = false;
        state.parentPropertyList.error = action.error.message;
      })

      // Property account manager list
      .addCase(getPropertyAccountManagerList.pending, (state) => {
        state.propertyAccountManagerList.loading = true;
      })
      .addCase(getPropertyAccountManagerList.fulfilled, (state, action) => {
        state.propertyAccountManagerList.loading = false;
        state.propertyAccountManagerList.data = action.payload;
      })
      .addCase(getPropertyAccountManagerList.rejected, (state, action) => {
        state.propertyAccountManagerList.loading = false;
        state.propertyAccountManagerList.error = action.error.message;
      })

      // Property customer list
      .addCase(getPropertyCustomerList.pending, (state) => {
        state.propertyCustomerList.loading = true;
      })
      .addCase(getPropertyCustomerList.fulfilled, (state, action) => {
        state.propertyCustomerList.loading = false;
        state.propertyCustomerList.data = action.payload;
      })
      .addCase(getPropertyCustomerList.rejected, (state, action) => {
        state.propertyCustomerList.loading = false;
        state.propertyCustomerList.error = action.error.message;
      })

      // Property billing card list
      .addCase(getPropertyBillingCardList.pending, (state) => {
        state.propertyBillingCardList.loading = true;
      })
      .addCase(getPropertyBillingCardList.fulfilled, (state, action) => {
        state.propertyBillingCardList.loading = false;
        state.propertyBillingCardList.data = action.payload;
      })
      .addCase(getPropertyBillingCardList.rejected, (state, action) => {
        state.propertyBillingCardList.loading = false;
        state.propertyBillingCardList.error = action.error.message;
      })

      // Assets List
      .addCase(getPropertyAssetsList.pending, (state) => {
        state.assetsList.loading = true;
      })
      .addCase(getPropertyAssetsList.fulfilled, (state, action) => {
        state.assetsList.loading = false;
        state.assetsList.data = action.payload;
        state.assetsList.totalCount = action.payload.total_records;
        state.assetsList.total = action.payload.total;
      })
      .addCase(getPropertyAssetsList.rejected, (state, action) => {
        state.assetsList.loading = false;
        state.assetsList.error = action.error.message;
      })

      // Routines List
      .addCase(getRoutineList.pending, (state) => {
        state.routinesList.loading = true;
      })
      .addCase(getRoutineList.fulfilled, (state, action) => {
        state.routinesList.loading = false;

        const { data, billingContractDetails } = action.payload || [];

        const routineData = data?.reduce((acc, item) => {
          const routineTypeId = item.routine_types.id;
          const frequencyCode = item.frequencies.code;

          // Check if the routine already exists in the accumulator
          let existingRoutine = acc.find(
            (routine) => routine.routine_types.id === routineTypeId
          );

          if (!existingRoutine) {
            // If routine is not in accumulator, add it with an empty frequencies list
            existingRoutine = { ...item, frequencies_list: [] };
            acc.push(existingRoutine);
          }

          // Add the frequency code if it's unique for this routine
          if (!existingRoutine.frequencies_list.includes(frequencyCode)) {
            existingRoutine.frequencies_list.push(frequencyCode);
          }

          /* This code updates the `contract_valid_till` property of an existing 
          routine with the `contract_end_date` from `billingContractDetails`, 
          but only if `contract_end_date` exists. */
          if (billingContractDetails?.contract_end_date) {
            existingRoutine.contract_valid_till =
              billingContractDetails?.contract_end_date;
          }

          return acc;
        }, []);

        state.routinesList.data = routineData;
        state.routinesList.totalCount = action.payload.total_records;
        state.routinesList.total = action.payload.total;
      })
      .addCase(getRoutineList.rejected, (state, action) => {
        state.routinesList.loading = false;
        state.routinesList.error = action.error.message;
      })

      // Equipement list
      .addCase(getEquipmentTypes.pending, (state) => {
        state.equipmentTypesList.loading = true;
      })
      .addCase(getEquipmentTypes.fulfilled, (state, action) => {
        state.equipmentTypesList.loading = false;
        state.equipmentTypesList.data = action.payload;
      })
      .addCase(getEquipmentTypes.rejected, (state, action) => {
        state.equipmentTypesList.loading = false;
        state.equipmentTypesList.error = action.error.message;
      })

      // Products
      .addCase(getProducts.pending, (state) => {
        state.products.loading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products.loading = false;
        state.products.data = action.payload;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.products.loading = false;
        state.products.error = action.error.message;
      })

      // Asser status list
      .addCase(getAssetStatus.pending, (state) => {
        state.assetStatusList.loading = true;
      })
      .addCase(getAssetStatus.fulfilled, (state, action) => {
        state.assetStatusList.loading = false;
        state.assetStatusList.data = action.payload;
      })
      .addCase(getAssetStatus.rejected, (state, action) => {
        state.assetStatusList.loading = false;
        state.assetStatusList.error = action.error.message;
      })

      // Create asset
      .addCase(createAsset.pending, (state) => {
        state.createAsset.loading = true;
      })
      .addCase(createAsset.fulfilled, (state, action) => {
        state.createAsset.loading = false;
        state.createAsset.error = false;
        state.createAsset.data = action.payload;
      })
      .addCase(createAsset.rejected, (state, action) => {
        state.createAsset.loading = false;
        state.createAsset.error = action.payload;
      })

      // update asset
      .addCase(updateAsset.pending, (state) => {
        state.updatedAsset.loading = true;
      })
      .addCase(updateAsset.fulfilled, (state, action) => {
        state.updatedAsset.loading = false;
        state.updatedAsset.error = false;
        state.updatedAsset.data = action.payload;
      })
      .addCase(updateAsset.rejected, (state, action) => {
        state.updatedAsset.loading = false;
        state.updatedAsset.error = action.payload;
      })

      // update routine
      .addCase(updateRoutine.pending, (state) => {
        state.updatedRoutine.loading = true;
      })
      .addCase(updateRoutine.fulfilled, (state, action) => {
        state.updatedRoutine.loading = false;
        state.updatedRoutine.error = false;
        state.updatedRoutine.data = action.payload;
      })
      .addCase(updateRoutine.rejected, (state, action) => {
        state.updatedRoutine.loading = false;
        state.updatedRoutine.error = action.payload;
      })

      // asset details
      .addCase(getAssetDetails.pending, (state) => {
        state.assetDetails.loading = true;
      })
      .addCase(getAssetDetails.fulfilled, (state, action) => {
        state.assetDetails.loading = false;
        state.assetDetails.error = false;
        state.assetDetails.data = action.payload.data[0];
      })
      .addCase(getAssetDetails.rejected, (state, action) => {
        state.assetDetails.loading = false;
        state.assetDetails.error = action.payload;
      })

      // Routine Standard Frequency List
      .addCase(getRoutineStandardFrequencyList.pending, (state) => {
        state.routineStandardFrequencyList.loading = true;
      })
      .addCase(getRoutineStandardFrequencyList.fulfilled, (state, action) => {
        state.routineStandardFrequencyList.loading = false;
        state.routineStandardFrequencyList.data = action.payload.data?.filter(
          (val) =>
            !val.standard_frequencies ||
            val.standard_frequencies.filter((freq) => !freq.frequencies)
              .length > 0
        );
      })
      .addCase(getRoutineStandardFrequencyList.rejected, (state, action) => {
        state.routineStandardFrequencyList.loading = false;
        state.routineStandardFrequencyList.error = action.error.message;
      })
      // get local government area and zone based on post code
      .addCase(getLocalGovernmentAndZone.pending, (state) => {
        state.localGovernmentAndZone.loading = true;
      })
      .addCase(getLocalGovernmentAndZone.fulfilled, (state, action) => {
        state.localGovernmentAndZone.loading = false;
        state.localGovernmentAndZone.data = action.payload?.data?.[0];
      })
      .addCase(getLocalGovernmentAndZone.rejected, (state, action) => {
        state.localGovernmentAndZone.loading = false;
        state.localGovernmentAndZone.error = action.error.message;
      })
      .addCase(getAssetHistoryMessages.pending, (state) => {
        state.assetHistoryMessage.loading = true;
      })
      .addCase(getAssetHistoryMessages.fulfilled, (state, action) => {
        state.assetHistoryMessage.loading = false;
        state.assetHistoryMessage.data = action.payload?.data;
      })
      .addCase(getAssetHistoryMessages.rejected, (state, action) => {
        state.assetHistoryMessage.loading = false;
        state.assetHistoryMessage.error = action.error.message;
      })
      .addCase(getAssetHistory.pending, (state) => {
        state.assetHistory.loading = true;
      })
      .addCase(getAssetHistory.fulfilled, (state, action) => {
        state.assetHistory.loading = false;
        state.assetHistory.data = action.payload;
      })
      .addCase(getAssetHistory.rejected, (state, action) => {
        state.assetHistory.loading = false;
        state.assetHistory.error = action.error.message;
      })
      .addCase(createWorkOrderFromAsset.pending, (state) => {
        state.createWorkOrder.loading = true;
      })
      .addCase(createWorkOrderFromAsset.fulfilled, (state, action) => {
        state.createWorkOrder.loading = false;
        state.createWorkOrder.error = false;
        state.createWorkOrder.data = action.payload;
      })
      .addCase(createWorkOrderFromAsset.rejected, (state, action) => {
        state.createWorkOrder.loading = false;
        state.createWorkOrder.error = action.payload;
      })
      .addCase(createBuildingType.pending, (state) => {
        state.createBuildingType.loading = true;
      })
      .addCase(createBuildingType.fulfilled, (state, action) => {
        state.createBuildingType.loading = false;
        state.createBuildingType.error = false;
        state.createBuildingType.data = action.payload;
      })
      .addCase(createBuildingType.rejected, (state, action) => {
        state.createBuildingType.loading = false;
        state.createBuildingType.error = action.payload;
      })
      .addCase(getBuildingType.pending, (state) => {
        state.getBuildingType.loading = true;
      })
      .addCase(getBuildingType.fulfilled, (state, action) => {
        state.getBuildingType.loading = false;
        state.getBuildingType.error = false;
        state.getBuildingType.data = action.payload;
      })
      .addCase(getBuildingType.rejected, (state, action) => {
        state.getBuildingType.loading = false;
        state.getBuildingType.error = action.payload;
      })
      .addCase(updateBuildingType.pending, (state) => {
        state.updateBuildingType.loading = true;
      })
      .addCase(updateBuildingType.fulfilled, (state, action) => {
        state.updateBuildingType.loading = false;
        state.updateBuildingType.error = false;
        state.updateBuildingType.data = action.payload;
      })
      .addCase(updateBuildingType.rejected, (state, action) => {
        state.updateBuildingType.loading = false;
        state.updateBuildingType.error = action.payload;
      })
      .addCase(addAssetVariant.pending, (state) => {
        state.assetVariant.loading = true;
      })
      .addCase(addAssetVariant.fulfilled, (state, action) => {
        state.assetVariant.loading = false;
        state.assetVariant.error = false;
        state.assetVariant.data = action.payload;
      })
      .addCase(addAssetVariant.rejected, (state, action) => {
        state.assetVariant.loading = false;
        state.assetVariant.error = action.payload;
      })
      .addCase(updateAssetVariant.pending, (state) => {
        state.updateAssetVariant.loading = true;
      })
      .addCase(updateAssetVariant.fulfilled, (state, action) => {
        state.updateAssetVariant.loading = false;
        state.updateAssetVariant.error = false;
        state.updateAssetVariant.data = action.payload;
      })
      .addCase(updateAssetVariant.rejected, (state, action) => {
        state.updateAssetVariant.loading = false;
        state.updateAssetVariant.error = action.payload;
      })
      // Building type list
      .addCase(getAssetVariantsList.pending, (state) => {
        state.assetVariantsList.loading = true;
      })
      .addCase(getAssetVariantsList.fulfilled, (state, action) => {
        const { data, is_active, total, total_records } = action.payload;

        state.assetVariantsList.loading = false;
        state.assetVariantsList.data = data;
        (state.assetVariantsList.total = total),
          (state.assetVariantsList.totalCount = total_records);
      })
      .addCase(getAssetVariantsList.rejected, (state, action) => {
        state.assetVariantsList.loading = false;
        state.assetVariantsList.error = action.error.message;
      })
      .addCase(getSiteRequirementsList.pending, (state) => {
        state.getSiteRequirementList.loading = true;
      })
      .addCase(getSiteRequirementsList.fulfilled, (state, action) => {
        const { data, is_active, total, total_records } = action.payload;

        state.getSiteRequirementList.loading = false;
        state.getSiteRequirementList.data = data;
        (state.getSiteRequirementList.total = total),
          (state.getSiteRequirementList.totalCount = total_records);
      })
      .addCase(getSiteRequirementsList.rejected, (state, action) => {
        state.getSiteRequirementList.loading = false;
        state.getSiteRequirementList.error = action.error.message;
      })
      .addCase(addSiteRequirement.pending, (state) => {
        state.createSiteRequirement.loading = true;
        state.createSiteRequirement.error = false;
      })
      .addCase(addSiteRequirement.fulfilled, (state, action) => {
        state.createSiteRequirement.loading = false;
        state.createSiteRequirement.error = false;
        state.createSiteRequirement.data = action.payload;
      })
      .addCase(addSiteRequirement.rejected, (state, action) => {
        state.createSiteRequirement.loading = false;
        state.createSiteRequirement.error = action.payload;
      })
      .addCase(updateSiteRequirement.pending, (state) => {
        state.createSiteRequirement.loading = true;
        state.createSiteRequirement.error = false;
      })
      .addCase(updateSiteRequirement.fulfilled, (state, action) => {
        state.createSiteRequirement.loading = false;
        state.createSiteRequirement.error = false;
        state.createSiteRequirement.data = action.payload;
      })
      .addCase(updateSiteRequirement.rejected, (state, action) => {
        state.createSiteRequirement.loading = false;
        state.createSiteRequirement.error = action.payload;
      })
      .addCase(getSiteRequirement.pending, (state) => {
        state.getSiteRequirement.loading = true;
      })
      .addCase(getSiteRequirement.fulfilled, (state, action) => {
        state.getSiteRequirement.loading = false;
        state.getSiteRequirement.error = false;
        state.getSiteRequirement.data = action.payload;
      })
      .addCase(getSiteRequirement.rejected, (state, action) => {
        state.getSiteRequirement.loading = false;
        state.getSiteRequirement.error = action.payload;
      })
      .addCase(getAccessProceduresList.pending, (state) => {
        state.getAccessProcedureList.loading = true;
      })
      .addCase(getAccessProceduresList.fulfilled, (state, action) => {
        const { data, is_active, total, total_records } = action.payload;

        state.getAccessProcedureList.loading = false;
        state.getAccessProcedureList.data = data;
        (state.getAccessProcedureList.total = total),
          (state.getAccessProcedureList.totalCount = total_records);
      })
      .addCase(getAccessProceduresList.rejected, (state, action) => {
        state.getAccessProcedureList.loading = false;
        state.getAccessProcedureList.error = action.error.message;
      })
      .addCase(addAccessProcedure.pending, (state) => {
        state.createAccessProcedure.loading = true;
      })
      .addCase(addAccessProcedure.fulfilled, (state, action) => {
        state.createAccessProcedure.loading = false;
        state.createAccessProcedure.error = false;
        state.createAccessProcedure.data = action.payload;
      })
      .addCase(addAccessProcedure.rejected, (state, action) => {
        state.createAccessProcedure.loading = false;
        state.createAccessProcedure.error = action.payload;
      })
      .addCase(updateAccessProcedure.pending, (state) => {
        state.createAccessProcedure.loading = true;
      })
      .addCase(updateAccessProcedure.fulfilled, (state, action) => {
        state.createAccessProcedure.loading = false;
        state.createAccessProcedure.error = false;
        state.createAccessProcedure.data = action.payload;
      })
      .addCase(updateAccessProcedure.rejected, (state, action) => {
        state.createAccessProcedure.loading = false;
        state.createAccessProcedure.error = action.payload;
      })
      .addCase(getAccessProcedure.pending, (state) => {
        state.getAccessProcedure.loading = true;
      })
      .addCase(getAccessProcedure.fulfilled, (state, action) => {
        state.getAccessProcedure.loading = false;
        state.getAccessProcedure.error = false;
        state.getAccessProcedure.data = action.payload;
      })
      .addCase(getAccessProcedure.rejected, (state, action) => {
        state.getAccessProcedure.loading = false;
        state.getAccessProcedure.error = action.payload;
      })
      // Property access schedule list
      .addCase(getAccessScheduleList.pending, (state) => {
        state.getAccessScheduleList.loading = true;
      })
      .addCase(getAccessScheduleList.fulfilled, (state, action) => {
        const { data, is_active, total, total_records } = action.payload;

        state.getAccessScheduleList.loading = false;
        state.getAccessScheduleList.data = data;
        (state.getAccessScheduleList.total = total),
          (state.getAccessScheduleList.totalCount = total_records);
      })
      .addCase(getAccessScheduleList.rejected, (state, action) => {
        state.getAccessScheduleList.loading = false;
        state.getAccessScheduleList.error = action.error.message;
      })
      .addCase(addAccessSchedule.pending, (state) => {
        state.createAccessSchedule.loading = true;
      })
      .addCase(addAccessSchedule.fulfilled, (state, action) => {
        state.createAccessSchedule.loading = false;
        state.createAccessSchedule.error = false;
        state.createAccessSchedule.data = action.payload;
      })
      .addCase(addAccessSchedule.rejected, (state, action) => {
        state.createAccessSchedule.loading = false;
        state.createAccessSchedule.error = action.payload;
      })
      .addCase(updateAccessSchedule.pending, (state) => {
        state.createAccessSchedule.loading = true;
      })
      .addCase(updateAccessSchedule.fulfilled, (state, action) => {
        state.createAccessSchedule.loading = false;
        state.createAccessSchedule.error = false;
        state.createAccessSchedule.data = action.payload;
      })
      .addCase(updateAccessSchedule.rejected, (state, action) => {
        state.createAccessSchedule.loading = false;
        state.createAccessSchedule.error = action.payload;
      })
      .addCase(getAccessSchedule.pending, (state) => {
        state.getAccessSchedule.loading = true;
      })
      .addCase(getAccessSchedule.fulfilled, (state, action) => {
        state.getAccessSchedule.loading = false;
        state.getAccessSchedule.error = false;
        state.getAccessSchedule.data = action.payload;
      })
      .addCase(getAccessSchedule.rejected, (state, action) => {
        state.getAccessSchedule.loading = false;
        state.getAccessSchedule.error = action.payload;
      });
  },
});

export const {
  resetPropertyList,
  resetUpdatePropertyList,
  resetCreatePropertyList,
  resetUpdateAsset,
  resetcreateAsset,
  resetUpdateRoutine,
  resetAssetDetails,
  resetAssetsList,
  resetRoutineList,
  resetPropertyBillingCardList,
  resetRoutineStandardFrequencyList,
  resetParentPropertyList,
  resetLocalGovernmentAndZone,
  resetPropertyCustomerList,
  resetAssetHistory,
  resetAssetHistoryMessage,
  resetCreateWorkOrderFromAsset,
  resetCreateBuildingType,
  resetUpdateBuildingType,
  resetGetBuildingType,
  resetBuildingTypeList,
  resetAssetVariantType,
  resetUpdateAssetVariantType,
  resetCreateSiteRequirement,
  resetGetSiteRequirement,
  resetSiteRequirementList,
  resetAccessProcedureList,
  resetCreateAccessProcedure,
  resetGetAccessProcedure,
  resetCreateAccessSchedule,
  resetAccessScheduleList,
  resetGetAccessSchedule,
} = propertySlice.actions;

export default propertySlice.reducer;
