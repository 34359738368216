import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { Box, styled } from '@mui/material';

import { DIVIDER_COLOR, SECONDARY } from '../../constants/Colors';
import { ROUTE_NAVIGATE_CONST } from '../../constants/Constants';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import { getPropertyList } from '../../store/property/api';
import {
  addSpaceAndCapitalizeString,
  getMomentDateTime,
  getRedirectURL,
  loggedInUserDetail,
  loggedInUserRole,
} from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import {
  FilterButton,
  RefreshDashboardButton,
  ResetFilterButton,
} from '../CommonComponents/FilterButton';
import MainWrapper from '../CommonComponents/MainWrapper';

//default filters
const defaultFilters = {
  invoice_date: getMomentDateTime({
    startDateKey: 'startDate',
    endDateKey: 'endDate',
    dateType: 'toDate',
  }),
  property: null,
};

const FiltersContainer = styled(Box)({
  borderRadius: '4px',
  backgroundColor: 'white',
  padding: '16px',
  display: 'flex',
  columnGap: '16px',
  rowGap: '16px',
  flexWrap: 'wrap',
});

const InvoiceDashboardLayout = () => {
  const { t } = useTranslation();
  const { status } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = loggedInUserRole();

  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [refresh, setRefresh] = useState(false);

  const { propertyDropdownLoading, propertyDropdownData } = getDropdownListHook(
    {
      reducerName: 'property',
      dropdownListName: 'propertyList',
      labelName: 'property_name',
      valueName: 'uuid',
      loading: 'loading',
    }
  );

  const handleResetFilterBtnClick = () => {
    setFilters(defaultFilters);
  };

  useEffect(() => {
    const req = {
      limit: -1,
      customerUuid: loggedInUserDetail()?.profile_uuid,
    };

    dispatch(getPropertyList(req));
  }, [dispatch, refresh]);

  return (
    <Box
      sx={{
        bgcolor: status ? SECONDARY : DIVIDER_COLOR,
        height: '100%',
      }}
    >
      <Box sx={{ bgcolor: DIVIDER_COLOR, paddingBottom: '16px' }}>
        <MainWrapper
          defaultPadding="0px"
          title={t('attributes.invoice.invoices')}
          variant="body1"
          isStep={status ? true : false}
          step={addSpaceAndCapitalizeString(status)}
          handleDefaultStep={() =>
            navigate(getRedirectURL()[role][ROUTE_NAVIGATE_CONST.INVOICES])
          }
          btn={
            status
              ? []
              : [
                  <ResetFilterButton
                    onClick={handleResetFilterBtnClick}
                    disabled={
                      !(
                        filters.property ||
                        filters.invoice_date.startDate ||
                        filters.invoice_date.endDate
                      )
                    }
                  />,
                  <FilterButton
                    onClick={() => setShowFilterOptions(!showFilterOptions)}
                    isActive={showFilterOptions}
                  />,
                  <RefreshDashboardButton
                    onClick={() => setRefresh(!refresh)}
                  />,
                ]
          }
        ></MainWrapper>
      </Box>
      {showFilterOptions && !status && (
        <Box sx={{ paddingTop: '16px' }}>
          <FiltersContainer>
            <CustomDateRangePicker
              onOkClick={(val) => {
                setFilters((prev) => ({
                  ...prev,
                  invoice_date: { startDate: val[0], endDate: val[1] },
                }));
              }}
              isClearIcon={false}
              fromDate={filters.invoice_date?.startDate}
              toDate={filters.invoice_date?.endDate}
            />
            <Autocomplete
              placeholder={t('attributes.invoice.properties')}
              options={propertyDropdownData}
              value={filters?.property}
              onChange={(e, newVal) => {
                if (newVal || newVal === null) {
                  setFilters((prev) => ({ ...prev, property: newVal }));
                }
              }}
              width="190px"
              isLoadingData={propertyDropdownLoading}
            />
            {/* <Autocomplete
              placeholder="Products"
              options={[
                { label: 'Product 1', value: 'Product 1' },
                { label: 'Product 2', value: 'Product 2' },
              ]}
              value={filters?.quoteType}
              onChange={(e, newVal) =>
                setFilters((prev) => ({ ...prev, quoteType: newVal }))
              }
              width="190px"
            /> */}
          </FiltersContainer>
        </Box>
      )}

      <Outlet context={{ dashboardFilter: filters, refresh: refresh }} />
    </Box>
  );
};

export default InvoiceDashboardLayout;
