import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PublicIcon from '@mui/icons-material/Public';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box } from '@mui/material';
import { debounce } from 'lodash';

import { DIVIDER_COLOR, SECONDARY } from '../../../constants/Colors';
import { exportToCSV } from '../../../constants/common';
import { ROUTE_NAVIGATE_CONST } from '../../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  EXPORT_FILE_NAMES,
} from '../../../constants/ExportConstant';
import { OFFICE_MANAGEMENT, OFFICE_STAFF } from '../../../constants/Roles';
import { SWIPEABLE_DRAWER_WIDTH } from '../../../constants/Typography';
import { getUserRole } from '../../../hoc/Permission';
import useBrowserBackButtonHandler from '../../../hooks/useBrowserBackButtonHandler';
import useServerSideErrors from '../../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../../store/CommonReducer';
import {
  createZone,
  getZone,
  getZonesList,
  updateZone,
} from '../../../store/zones/api';
import {
  resetCreateNewZoneLGA,
  resetGetZoneDetails,
  resetGetZonesLGAList,
  resetUpdateZoneLGA,
} from '../../../store/zones/reducer';
import { getRedirectURL, showHideTopbarLoader } from '../../../utils';
import { Edit, View } from '../../CommonComponents/ActionComponent';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import { FilterSection } from '../../CommonComponents/FilterComponent';
import MainWrapper from '../../CommonComponents/MainWrapper';
import { ConfirmationModal } from '../../CommonComponents/Modal';
import NewNoDataPage from '../../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import ZoneForm from './ZoneForm';
import ZoneView from './ZoneView';

const defaultValues = {
  zone_name: '',
  local_government_area: '',
  post_code: '',
};

const ZoneConfiguration = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('zone_name');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [selectedRows, setSelectedRows] = useState(null);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [zoneId, setZoneId] = useState('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [hasFormValues, sethasFormValues] = useState(false);
  const [serverErrors, setServerErrors] = useState([]);

  const {
    isLoading,
    data: zoneList,
    error,
    totalCount,
    total,
  } = useSelector((state) => state.zones.getZonesLGAList);

  const {
    isLoading: detailloading,
    data: zoneData,
    error: zoneError,
  } = useSelector((state) => state.zones.getZoneDetails);

  const {
    isLoading: createZoneLoading,
    data: createZoneData,
    error: createZoneError,
  } = useSelector((state) => state.zones.createNewZoneLGA);

  const {
    isLoading: updateZoneLoading,
    data: updateZoneData,
    error: updateZoneError,
  } = useSelector((state) => state.zones.updateZoneLGA);

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    trigger,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: defaultValues,
  });

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  useBrowserBackButtonHandler({
    hasUnsavedChanges: hasFormValues,
    setOpenConfirmationModal,
    open,
    watchMethod: watch,
    sethasFormValues,
  });

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'zone_name',
        headerName: t('attributes.configuration.zone.title'),
        flex: 2,
        sortable: true,
      },
      {
        field: 'local_government_area',
        headerName: t('attributes.configuration.zone.lga'),
        flex: 3,
        sortable: false,
      },
      {
        field: 'postcode',
        headerName: t('attributes.configuration.zone.postCode'),
        flex: 2,
        sortable: false,
      },
      {
        field: 'action',
        headerName: t('attributes.action'),
        flex: 1,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <View
              onClick={() => {
                dispatch(getZone({ uuid: row.id }));
                setViewOpen(true);
              }}
            />
            {![OFFICE_MANAGEMENT, OFFICE_STAFF].includes(getUserRole()) && (
              <Edit
                onClick={() => {
                  setZoneId(row.id);
                  setOpen(true);
                }}
              />
            )}
          </Box>
        ),
      },
    ];

    return baseColumns;
  }, []);

  useEffect(
    () => () => {
      dispatch(resetCreateNewZoneLGA());
      dispatch(resetUpdateZoneLGA());
      dispatch(resetGetZonesLGAList());
      dispatch(resetGetZoneDetails());
    },
    []
  );

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const getAllZoneList = useCallback(() => {
    setSelectedRows(null);
    const req = {
      order: order,
      orderBy: orderBy,
      page: currentPage,
      size: perPageData,
      search: searchText,
    };

    dispatch(getZonesList(req));
  }, [
    dispatch,
    perPageData,
    currentPage,
    order,
    orderBy,
    searchText,
    columns,
    columnVisibilityModel,
  ]);

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllZoneList, 500), [
    getAllZoneList,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const rows = zoneList?.data?.map((item) => ({
    id: item.uuid,
    zone_name: item.zone_name,
    local_government_area: item.local_government_areas
      ?.filter((lga) => lga.is_active !== false)
      ?.map((lga) => lga.local_government_areas_name)
      .join(', '),
    postcode: item.local_government_areas
      ?.filter((lga) => lga.is_active !== false)
      ?.map((lga) => lga.post_code)
      .join(', '),
  }));

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const onSubmit = (data) => {
    const request = {
      zone_name: data?.zone_name,
      is_active: true,
    };

    if (!zoneId) {
      request.locations = data?.local_government_areas;
      dispatch(createZone(request));
    } else {
      request.local_government_areas = data?.local_government_areas;
      dispatch(updateZone({ uuid: zoneId, req: request }));
    }
  };

  useEffect(() => {
    showHideTopbarLoader(createZoneLoading, dispatch);
    if (createZoneLoading !== null && !createZoneLoading) {
      if (createZoneError) {
        if (createZoneError.errorDetails) {
          setServerErrors(createZoneError.errorDetails);
        }
        if (createZoneError.message) {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: createZoneError.message,
            })
          );
        }
      } else {
        if (createZoneData) {
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: t('message.zone.createdSuccessfully'),
            })
          );
        }
      }
    }
  }, [createZoneError, createZoneLoading]);

  useEffect(() => {
    showHideTopbarLoader(updateZoneLoading, dispatch);
    if (updateZoneLoading !== null && !updateZoneLoading) {
      if (updateZoneError) {
        if (updateZoneError.errorDetails) {
          setServerErrors(updateZoneError.errorDetails);
        }
        if (updateZoneError.message) {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: updateZoneError.message,
            })
          );
        }
      } else {
        if (updateZoneData) {
          setOpen(false);
          debouncedFetchData();
          setZoneId('');
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: t('message.zone.updatedSuccessfully'),
            })
          );
        }
      }
    }
  }, [updateZoneError, updateZoneLoading]);

  const noData = (
    <Box
      sx={{
        backgroundColor: SECONDARY,
        borderRadius: '0px 0px 8px 8px',
        paddingTop: '16px',
      }}
    >
      <NewNoDataPage
        icon={<PublicIcon />}
        title={t('attributes.configuration.zone.title')}
        singularText={t('attributes.configuration.zone.title')}
        createBtnText={`${t('attributes.add')} ${t('attributes.configuration.zone.title')}`}
        filterHeight={200}
      />
    </Box>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setZoneId('');
            setOpenConfirmationModal(false);
            sethasFormValues(false);

            if (window.history.state === null) {
              window.history.back();
            }
          }}
        />
      )}
      <Box
        sx={{
          backgroundColor: DIVIDER_COLOR,
          height: 'inherit',
        }}
      >
        <MainWrapper
          defaultPadding="0px 0px 16px 0px"
          title={t('attributes.configuration.title')}
          variant="body1"
          isStep={true}
          step={t('attributes.configuration.zone.title')}
          handleDefaultStep={() => {
            navigate(
              getRedirectURL()[getUserRole()][
                ROUTE_NAVIGATE_CONST.CONFIGURATION
              ]
            );
          }}
        ></MainWrapper>
        <StyledMainWrapper
          btn={
            <>
              {![OFFICE_MANAGEMENT, OFFICE_STAFF].includes(getUserRole()) && (
                <CustomButton
                  text={t('attributes.configuration.zone.addZone')}
                  color="secondary"
                  sx={{ height: '52%' }}
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  onClick={() => {
                    setOpen(true);
                    setSelectedRows(null);
                  }}
                />
              )}
              <CustomButton
                text={t('attributes.export')}
                color="secondary"
                sx={{ height: '52%' }}
                startIcon={<OpenInNewIcon />}
                onClick={() => {
                  if (!selectedRows || selectedRows?.length === 0) {
                    dispatch(
                      snackbarToggle({
                        isOpen: true,
                        isErrorMsg: true,
                        msg: t('message.common.noRecordExportMessage'),
                      })
                    );
                    setSelectedRows(null);
                  } else {
                    exportToCSV(
                      selectedRows,
                      COLUMNS_TO_EXPORT.ZONE,
                      EXPORT_FILE_NAMES.ZONES
                    );
                  }
                }}
              />
            </>
          }
        >
          {!isLoading && totalCount === 0 ? (
            noData
          ) : (
            <Box
              sx={{
                backgroundColor: SECONDARY,
                borderRadius: '0px 0px 8px 8px',
                paddingTop: '16px',
              }}
            >
              <FilterSection
                searchText={searchText}
                onSearchChange={(e) => setSearchText(e.target.value)}
                isReset={false}
                isFilter={false}
                sx={{ marginRight: '16px' }}
              />
              <CustomGridTable
                columns={columns}
                rows={rows}
                total={total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                perPageData={perPageData}
                setPerPageData={setPerPageData}
                order={order}
                orderBy={orderBy}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                columnVisibilityModel={columnVisibilityModel}
                onRowSelectionModelChange={handleSelectionChange}
                setColumnVisibilityModel={setColumnVisibilityModel}
                filterHeight="232"
                noData={<NoRecordFound />}
                isLoading={isLoading}
              />
            </Box>
          )}
        </StyledMainWrapper>
      </Box>
      {open && (
        <SwipeableDrawer
          open={open}
          title={
            !zoneId
              ? t('attributes.configuration.zone.addZone')
              : t('attributes.configuration.zone.editZone')
          }
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          footerButton={
            <CustomButton
              text={!zoneId ? t('attributes.create') : t('attributes.save')}
              startIcon={!zoneId ? <AddIcon /> : <SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
            />
          }
          width={SWIPEABLE_DRAWER_WIDTH}
        >
          <ZoneForm
            id={zoneId}
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            setError={setError}
            setServerErrors={setServerErrors}
            defaultValues={defaultValues}
            clearErrors={clearErrors}
          />
        </SwipeableDrawer>
      )}

      {viewOpen && (
        <SwipeableDrawer
          open={viewOpen}
          title={detailloading ? '-' : zoneData?.zone_name}
          onClose={() => {
            setViewOpen(false);
            dispatch(resetGetZoneDetails());
          }}
          width={SWIPEABLE_DRAWER_WIDTH}
        >
          <ZoneView
            data={zoneData?.local_government_areas}
            isLoading={detailloading}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default ZoneConfiguration;
