import { useEffect, useState } from 'react';

const useBrowserBackButtonHandler = ({
  hasUnsavedChanges,
  setOpenConfirmationModal,
  open,
  watchMethod,
  sethasFormValues,
}) => {
  const [triggerBackButton, setTriggerBackButton] = useState(false);

  useEffect(() => {
    if (open && hasUnsavedChanges) {
      setTriggerBackButton(true);
      window.history.pushState({ isDrawerOpen: true }, ''); // Push state when the drawer opens
    }

    const handlePopState = () => {
      if (hasUnsavedChanges && open) {
        setOpenConfirmationModal(true); // Show confirmation modal or handle cancel logic
        window.history.pushState(null, ''); // Prevent navigation
      } else {
        if (triggerBackButton) {
          setTriggerBackButton(false);
        }
      }
    };

    const handleUnloadState = (e) => {
      if (hasUnsavedChanges && open) {
        e.preventDefault();
        e.returnValue = true;
      }
    };

    // Add the popstate event listener
    window.addEventListener('popstate', handlePopState);
    window.addEventListener('beforeunload', handleUnloadState);

    return () => {
      // Cleanup the event listener on unmount
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('beforeunload', handleUnloadState);
    };
  }, [hasUnsavedChanges, open]);

  useEffect(() => {
    const subscription = watchMethod(() => {
      sethasFormValues(true); // Set true if form values change
    });

    return () => subscription.unsubscribe();
  }, [watchMethod]);
};

export default useBrowserBackButtonHandler;
