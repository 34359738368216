import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { generatePDF as generatePDF1 } from '../components/CommonComponents/Templates/Template1/DownloadPDF';
import { PreviewPDF as PreviewPDF1 } from '../components/CommonComponents/Templates/Template1/PreviewPDF';
import { generatePDF as generatePDF2 } from '../components/CommonComponents/Templates/Template2/DownloadPDF';
import { PreviewPDF as PreviewPDF2 } from '../components/CommonComponents/Templates/Template2/PreviewPDF';
import { generatePDF as generatePDF3 } from '../components/CommonComponents/Templates/Template3/DownloadPDF';
import { PreviewPDF as PreviewPDF3 } from '../components/CommonComponents/Templates/Template3/PreviewPDF';
import { getRestTemplates } from '../store/template/api';
import { resetTemplateList } from '../store/template/reducer';

const usePreviewAndDownloadTemplate = ({ module, data }) => {
  const { isLoading: listTemplateLoading, data: listTemplateData } =
    useSelector((state) => state.template.listTemplate);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRestTemplates({}));

    return () => dispatch(resetTemplateList());
  }, []);

  const templateStyleId = listTemplateData && listTemplateData[module];

  const generatePDF = useCallback(() => {
    switch (templateStyleId) {
      case 1:
        return generatePDF1(module, data);
      case 2:
        return generatePDF2(module, data);
      case 3:
        return generatePDF3(module, data);
    }
  }, [templateStyleId, module, data]);

  const PreviewTemplatePDF = (() => {
    switch (templateStyleId) {
      case 1:
        return <PreviewPDF1 module={module} previewData={data} />;
      case 2:
        return <PreviewPDF2 module={module} previewData={data} />;
      case 3:
        return <PreviewPDF3 module={module} previewData={data} />;
      default:
        return null; // Return null if no matching case is found
    }
  })();

  return {
    listTemplateLoading,
    generatePDF,
    PreviewTemplatePDF,
  };
};

export default usePreviewAndDownloadTemplate;
