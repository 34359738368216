import React, { useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Divider, Typography } from '@mui/material';
import { BrowserMultiFormatReader } from '@zxing/library';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../assets/commonStyled';
import { Validation } from '../../constants/FieldValidationMsg';
import { SUPER_ADMIN } from '../../constants/Roles';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import useDidMount from '../../hooks/useDidMount';
import { snackbarToggle } from '../../store/CommonReducer';
import {
  getCategoryLevelsDropdown,
  getEquipmentClassDropdown,
} from '../../store/equipmentTypes/api';
import {
  resetCategoryLevels1DropdownList,
  resetEquipmentClassDropdownList,
} from '../../store/equipmentTypes/reducer';
import { getProductEnhancement } from '../../store/productCatalogue/api';
import {
  resetProductDetails,
  resetProductEnhancementData,
} from '../../store/productCatalogue/reducer';
import {
  addAssetVariant,
  getAssetDetails,
  getProducts,
} from '../../store/property/api';
import {
  resetAssetDetails,
  resetAssetVariantType,
  resetcreateAsset,
  resetUpdateAsset,
} from '../../store/property/reducer';
import {
  clearTextfields,
  datePickerFormat,
  loggedInUserRole,
  showHideTopbarLoader,
} from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomDatePicker from '../CommonComponents/CustomDatePicker';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';
import QRCodeUploader from '../CommonComponents/QRCodeUploader';
import { assetFormDefaultValues } from './AssetsList';
import AssetVariantForm from './AssetVariantForm';

const AssetForm = ({
  id,
  control,
  setValue,
  trigger,
  reset,
  nextStepTitle,
  setError,
  setServerErrors = () => {},
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { loading: assetVariantLoading, data: assetVariantData } = useSelector(
    (state) => state.property.assetVariant
  );

  // Get equipment type list
  const { equipmentTypesDropdownLoading, equipmentTypesDropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'equipmentTypesDropdownList',
      labelName: 'display_name',
      valueName: 'code',
      id: 'id',
      isPressure: 'is_pressure',
    });

  // Get equipment class list
  const { equipmentClassDropdownLoading, equipmentClassDropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'equipmentClassDropdownList',
      customNameForDropdown: 'equipmentClass',
      labelName: 'name',
      valueName: 'id',
    });

  // Get category levels 1 list
  const { categoryLevels1DropdownLoading, categoryLevels1DropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'categoryLevels1DropdownList',
      customNameForDropdown: 'categoryLevels1',
      labelName: 'name',
      valueName: 'id',
      isOther: 'is_other',
    });

  // Equipment class errors
  const { error: equipmentClassDropdownError } = useSelector(
    (state) => state.equipmentTypes.equipmentClassDropdownList
  );

  const { assetDetails } = useSelector((state) => state.property);
  const [qrCodeData, setQRCodeData] = useState('');

  const selectedEquipmentType = useWatch({
    control,
    name: 'equipment_type',
    defaultValue: '',
  });

  const selectedEquipmentClass = useWatch({
    control,
    name: 'product',
    defaultValue: '',
  });

  const assetVariant = useWatch({
    control,
    name: 'assetVariant',
    defaultValue: '',
  });

  const selectedCategoryLevel2 = useWatch({
    control,
    name: 'category_level2',
    defaultValue: '',
  });

  const selectedCategoryLevel3 = useWatch({
    control,
    name: 'category_level3',
    defaultValue: '',
  });

  const assetNumber = useWatch({
    control,
    name: 'asset_number',
    defaultValue: '',
  });

  const location = useWatch({
    control,
    name: 'location',
    defaultValue: '',
  });

  useEffect(() => {
    if (selectedEquipmentType?.label) {
      dispatch(
        getEquipmentClassDropdown({
          equipment_type_id: selectedEquipmentType?.id,
          status: true,
        })
      );
    }
  }, [selectedEquipmentType]);

  useEffect(() => {
    if (selectedEquipmentClass?.label) {
      const categoryLevels = [
        {
          ecid: selectedEquipmentClass?.value,
          parent_cid: null,
          current_category: 1,
        },
      ];

      categoryLevels.forEach((level) => {
        dispatch(getCategoryLevelsDropdown(level));
      });
    }
  }, [selectedEquipmentClass, assetVariantData]);

  useDidMount(() => {
    if (!selectedEquipmentClass?.label && !assetNumber && !location) {
      setValue('asset_name', '');
      trigger('asset_name');

      return;
    }

    const parts = [
      assetNumber || '',
      selectedEquipmentClass?.label || '',
      location || '',
    ].filter(Boolean);

    setValue('asset_name', parts.length ? parts.join(' - ') : '');
    trigger('asset_name');
  }, [selectedEquipmentClass, assetNumber, location]);

  useEffect(() => {
    dispatch(getProducts({ equipment_type: selectedEquipmentType?.value }));
  }, [selectedEquipmentType?.value]);

  useEffect(() => {
    if (selectedEquipmentType?.label) {
      dispatch(
        getProductEnhancement({
          equipment_types: selectedEquipmentType?.id,
          ecid: selectedEquipmentClass?.value || null,
          category1: assetVariant?.value || null,
          category2: selectedCategoryLevel2?.value || null,
          category3: selectedCategoryLevel3?.value || null,
          is_ecid_null: !selectedEquipmentClass?.label,
          is_category1_null: !assetVariant?.label,
          is_category2_null: !selectedCategoryLevel2?.label,
          is_category3_null: !selectedCategoryLevel3?.label,
        })
      );
    }
  }, [assetVariant]);

  useEffect(() => {
    if (id) {
      dispatch(getAssetDetails({ assetId: id }));
    }

    return () => {
      dispatch(resetUpdateAsset());
      dispatch(resetcreateAsset());
      if (!nextStepTitle) {
        dispatch(resetAssetDetails());
      }
      dispatch(resetEquipmentClassDropdownList());
      dispatch(resetCategoryLevels1DropdownList());
      dispatch(resetProductEnhancementData());
      dispatch(resetProductDetails());
      dispatch(resetAssetVariantType());
      reset(assetFormDefaultValues);
    };
  }, []);

  useEffect(() => {
    if (!assetDetails?.loading && assetDetails && id) {
      const details = assetDetails?.data;

      reset({
        label: details?.label,
        old_asset_name: details?.product?.name,
        ...(details.equipment_type?.id && {
          equipment_type: {
            label: details.equipment_type?.display_name,
            value: details.equipment_type?.code,
            id: details.equipment_type?.id,
          },
        }),
        ...(details?.productDetails?.equipment_class?.id && {
          product: {
            label: details?.productDetails?.equipment_class?.name,
            value: details?.productDetails?.equipment_class?.id,
          },
        }),
        ...(details?.productDetails?.category1_rel && {
          assetVariant: {
            label: details?.productDetails?.category1_rel?.name,
            value: details?.productDetails?.category1_rel?.id,
            isOther: details?.productDetails?.category1_rel?.is_other,
          },
        }),
        ...(details?.productDetails?.category2_rel && {
          category_level2: {
            label: details?.productDetails?.category2_rel?.name,
            value: details?.productDetails?.category2_rel?.id,
            isOther: details?.productDetails?.category2_rel?.is_other,
          },
        }),
        ...(details?.productDetails?.category3_rel && {
          category_level3: {
            label: details?.productDetails?.category3_rel?.name,
            value: details?.productDetails?.category3_rel?.id,
            isOther: details?.productDetails?.category3_rel?.is_other,
          },
        }),
        ...(details?.other_category_name && {
          category_name: details?.other_category_name,
        }),
        category_name: details?.other_category_name,

        location: details?.location,
        identification_number: details?.identification_number,
        qr_code: details?.barcode,
        pressure: details?.pressure,
        asset_number: details?.asset_number,
        walk_order: details?.wall_order,

        make: details?.make,
        model: details?.model,

        size: details?.size,
        quantity: details?.quantity,

        base_date: details?.base_date
          ? datePickerFormat(details?.base_date)
          : null,
        installation_date: details?.installation_date
          ? datePickerFormat(details?.installation_date)
          : null,
        internal_note: details?.internal_note,
        active: details?.is_active,
        status_id: details?.status?.id
          ? {
              label: details?.status?.display_name,
              value: details?.status?.id,
            }
          : null,
      });
    } else {
      reset();
    }
  }, [assetDetails]);

  useEffect(() => {
    setServerErrors((prev) =>
      equipmentClassDropdownError?.status === 404
        ? [
            ...prev,
            {
              field: 'product',
              message: t(
                'attributes.purchase_order.pleaseChooseRightCombination'
              ),
            },
          ]
        : prev?.filter((error) => error?.field !== 'product')
    );
  }, [equipmentClassDropdownError]);

  useEffect(() => {
    showHideTopbarLoader(assetVariantLoading, dispatch);
    if (!assetVariantLoading) {
      if (assetVariantData) {
        setIsModalOpen(false);
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: assetVariantData?.message,
          })
        );
        dispatch(resetCategoryLevels1DropdownList());
      }
    }
  }, [assetVariantLoading]);

  useEffect(() => {
    if (assetVariantData && categoryLevels1DropdownData.length > 0) {
      const result = categoryLevels1DropdownData?.filter(
        (item) => item.value === assetVariantData?.data[0]?.category_id
      )[0];

      setValue(
        'assetVariant',
        result
          ? {
              label: result?.label,
              value: result?.value,
              isOther: result?.isOther,
            }
          : null
      );
    }
  }, [assetVariantData, categoryLevels1DropdownData]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onload = async (e) => {
      const imageDataUrl = e.target.result;

      try {
        const decodedData = await decodeQRCode(imageDataUrl);

        if (decodedData) {
          setQRCodeData(decodedData);
          setValue('qr_code', decodedData);
          trigger('qr_code');
        }
      } catch (error) {
        setQRCodeData('');
        setValue('qr_code', null);
        setError('qr_code', {
          type: 'manual',
          message: t('attributes.property.assetInvalidQRMessage'),
        });
      }
    };

    reader.readAsDataURL(file);
  };

  const decodeQRCode = async (imageDataUrl) => {
    const codeReader = new BrowserMultiFormatReader();

    return new Promise((resolve, reject) => {
      codeReader
        .decodeFromImage(undefined, imageDataUrl)
        .then((result) => resolve(result.text))
        .catch((err) => reject(err));
    });
  };

  const onSubmit = (data, reset) => {
    const req = {
      equipment_type_id: selectedEquipmentType?.id,
      equipment_class_id: selectedEquipmentClass?.value,
      name: data?.asset_variant_name,
    };

    dispatch(addAssetVariant(req)).then(() => {
      reset();
    });
  };

  return assetDetails?.loading ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">General Information</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="equipment_type"
            control={control}
            rules={{
              required: `${t('attributes.property.assetGroup')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Autocomplete
                label={t('attributes.property.assetGroup')}
                options={equipmentTypesDropdownData}
                value={value}
                onChange={(e, newValue) => {
                  if (newValue || newValue === null) {
                    onChange(newValue);
                  }
                  trigger('equipment_type');
                  dispatch(resetEquipmentClassDropdownList());
                  setValue('assetVariant', null);
                  setValue('product', null);
                }}
                helperText={error ? error.message : ''}
                error={error}
                isLoadingData={equipmentTypesDropdownLoading}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={true}
                disabledDropdown={id}
              />
            )}
          />
          <Controller
            name="product"
            control={control}
            rules={{
              required: `${t('attributes.property.assetType')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                label={t('attributes.property.assetType')}
                options={equipmentClassDropdownData}
                value={value}
                onChange={(e, newValue) => {
                  if (newValue || newValue === null) {
                    onChange(newValue);
                  }
                  trigger('product');
                  setValue('assetVariant', null);
                  dispatch(resetCategoryLevels1DropdownList());
                }}
                isLoadingData={equipmentClassDropdownLoading}
                error={error}
                helperText={error ? error.message : ''}
                disabledDropdown={!selectedEquipmentType}
              />
            )}
          />
          <Controller
            name="assetVariant"
            control={control}
            rules={{
              required: `${t('attributes.property.assetVariant')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                label={t('attributes.property.assetVariant')}
                options={categoryLevels1DropdownData}
                value={value}
                onChange={(e, newValue) => {
                  if (newValue || newValue === null) {
                    onChange(newValue);
                  }
                }}
                isLoadingData={categoryLevels1DropdownLoading}
                error={error}
                helperText={error ? error.message : ''}
                disabledDropdown={!selectedEquipmentClass}
              />
            )}
          />

          {loggedInUserRole() === SUPER_ADMIN && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CustomButton
                startIcon={<AddCircleOutlineIcon />}
                variant="text"
                text={t('attributes.add')}
                onClick={() => setIsModalOpen(true)}
                sx={{ textDecoration: 'underline' }}
                disabled={
                  !selectedEquipmentType?.value || !selectedEquipmentClass
                }
              />
            </Box>
          )}
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="asset_number"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.property.assetNumber')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
            <Controller
              name="walk_order"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.property.walkOrder')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
          </Box>
          <Controller
            name="location"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '4px' }}
              >
                <CustomTextField
                  label={t('attributes.property.level')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              </Box>
            )}
          />
          <Controller
            name="asset_name"
            control={control}
            rules={{
              required: `${t('attributes.property.asset_name')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.property.asset_name')}
                fullWidth
                disabled={true}
                value={value}
                onChange={(e, newValue) => {
                  onChange(newValue);
                  trigger('asset_name');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
              />
            )}
          />
          {(selectedEquipmentType?.isPressure ||
            assetDetails?.data?.equipment_type?.is_pressure) && (
            <Controller
              name="pressure"
              control={control}
              rules={{
                required: `${t('attributes.property.pressureFieldWithUnit')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.property.pressureFieldWithUnit')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                />
              )}
            />
          )}
          {false && (
            <Controller
              name="serial"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Serial"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
          )}
          <Controller
            name="qr_code"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.property.qr_code')}
                fullWidth
                value={value || qrCodeData}
                onChange={(e) => {
                  onChange(e);
                  trigger('qr_code');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={false}
                displayEndAdornment={true}
                fixedEndAdornment={
                  <QRCodeUploader handleFileChange={handleFileChange} />
                }
              />
            )}
          />
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="make"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Make"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
            <Controller
              name="model"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Model"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
          </Box>

          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="size"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Size"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <CustomDatePicker
              control={control}
              name="base_date"
              label={t('attributes.property.manufacture_date')}
              pickerType="date"
              defaultValue={null}
              trigger={trigger}
            />
            <CustomDatePicker
              control={control}
              name="installation_date"
              label="Installation Date"
              pickerType="date"
              defaultValue={null}
              trigger={trigger}
            />
          </Box>
          <Controller
            name="internal_note"
            control={control}
            rules={{
              maxLength: {
                value: 300,
                message: Validation.general.address,
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CustomTextField
                label="Internal note"
                multiline
                isRequired={false}
                rows={3}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('internal_note');
                }}
                onClear={() => {
                  onChange('');
                }}
                helperText={error ? error.message : ''}
                error={error}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
      {id && (
        <CustomCard>
          <Box sx={{ padding: '0 16px 0 16px' }}>
            <CommonCheckbox
              control={control}
              name="active"
              label={t('common.active')}
              isRequired={false}
              showMessage={true}
              messageOnChecked={t('label.superAdmin.assetActiveCheckbox')}
              messageOnUnchecked={t('label.superAdmin.assetInactiveCheckbox')}
            />
          </Box>
        </CustomCard>
      )}

      <AssetVariantForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={onSubmit}
        disabled={assetVariantLoading}
      />
    </FormWrapper>
  );
};

export default AssetForm;
