import { createSlice } from '@reduxjs/toolkit';

import {
  createContact,
  editContact,
  getContactDropdownList,
  getContactList,
  updateContact,
} from './api';

const initialCreateData = {
  isLoading: false,
  error: false,
  createdContact: false,
};

const initialEditData = {
  isLoading: false,
  error: false,
  contact: false,
};

const initialGetData = {
  isLoading: true,
  error: null,
  contactActiveList: [],
  contactInactiveList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const initialGetDropdownData = {
  isLoading: true,
  error: null,
  data: [],
};

const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    get: { ...initialGetData },
    create: {
      ...initialCreateData,
    },
    edit: {
      ...initialEditData,
    },
    getDropdown: {
      ...initialGetDropdownData,
    },
  },
  reducers: {
    resetCreate(state) {
      state.create = { ...initialCreateData };
    },
    resetEdit(state) {
      state.edit = { ...initialEditData };
    },
    resetPagination(state) {
      state.get = { ...initialGetData };
    },
    resetDropdown(state) {
      state.getDropdown = { ...initialGetDropdownData };
    },
  },
  extraReducers: (builder) => {
    builder
      // Contact List
      .addCase(getContactList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getContactList.fulfilled, (state, action) => {
        const { data, active, pagination } = action.payload;

        state.get.isLoading = false;
        if (active) {
          state.get.contactActiveList = data;
        } else {
          state.get.contactInactiveList = data;
        }
        state.get.total = pagination.total_items;
        state.get.totalCount = pagination.total_count;
      })
      .addCase(getContactList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })
      // Create
      .addCase(createContact.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.create.isLoading = false;
        state.create.error = false;
        state.create.createdContact = action.payload;
      })
      .addCase(createContact.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Update
      .addCase(updateContact.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        state.create.isLoading = false;
        state.create.error = false;
        state.create.createdContact = action.payload;
      })
      .addCase(updateContact.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Edit
      .addCase(editContact.pending, (state) => {
        state.edit.isLoading = true;
      })
      .addCase(editContact.fulfilled, (state, action) => {
        state.edit.isLoading = false;
        state.edit.error = false;
        state.edit.contact = action.payload.data[0];
      })
      .addCase(editContact.rejected, (state, action) => {
        state.edit.isLoading = false;
        state.edit.error = action.payload;
      })
      // Contact name list for dropdown list
      .addCase(getContactDropdownList.pending, (state) => {
        state.getDropdown.isLoading = true;
      })
      .addCase(getContactDropdownList.fulfilled, (state, action) => {
        state.getDropdown.isLoading = false;
        state.getDropdown.data = action.payload;
      })
      .addCase(getContactDropdownList.rejected, (state, action) => {
        state.getDropdown.isLoading = false;
        state.getDropdown.error = action.payload;
      });
  },
});

export const { resetCreate, resetEdit, resetPagination, resetDropdown } =
  contactSlice.actions;

export default contactSlice.reducer;
