import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box } from '@mui/material';
import { debounce } from 'lodash';

import { DIVIDER_COLOR } from '../../../constants/Colors';
import { exportToCSV } from '../../../constants/common';
import { ROUTE_NAVIGATE_CONST } from '../../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  COLUMNS_TO_MAP,
  EXPORT_FILE_NAMES,
} from '../../../constants/ExportConstant';
import { getUserRole } from '../../../hoc/Permission';
import useBrowserBackButtonHandler from '../../../hooks/useBrowserBackButtonHandler';
import useServerSideErrors from '../../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../../store/CommonReducer';
import {
  createBuildingType,
  getBuildingTypeList,
  updateBuildingType,
} from '../../../store/property/api';
import {
  resetBuildingTypeList,
  resetCreateBuildingType,
  resetGetBuildingType,
  resetUpdateBuildingType,
} from '../../../store/property/reducer';
import { getRedirectURL, showHideTopbarLoader } from '../../../utils';
import { Edit } from '../../CommonComponents/ActionComponent';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import { FilterSection } from '../../CommonComponents/FilterComponent';
import MainWrapper from '../../CommonComponents/MainWrapper';
import { ConfirmationModal } from '../../CommonComponents/Modal';
import NewNoDataPage from '../../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import TabView from '../../CommonComponents/TabView';
import BuildingTypeForm from './BuildingTypeForm';

// Will be used for Super Admin
const defaultValues = {
  name: '',
  email: '',
  address: '',
  cell_phone: '',
  city: '',
  state: '',
  zip_code: '',
};

// Will be used for Global Admin
const globalAdminDefaultValues = {
  ...defaultValues,
  company_name: '',
};

//default filters
const defaultFilters = {
  city: null,
  state: null,
  company: null,
};
const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

const BuildingTypeConfiguration = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    control,
    reset,
    trigger,
    setError,
    clearErrors,
    watch,
    setValue,
  } = useForm();

  const {
    loading: listLoading,
    data: proertyBuildingTypeData,
    total,
    totalCount,
  } = useSelector((state) => state.property.propertyBuildingTypeList);
  const {
    loading: createLoading,
    data: createdBuildingType,
    error,
  } = useSelector((state) => state.property.createBuildingType);

  const {
    loading: updateLoading,
    data: updateBuildingTypeData,
    error: updatedBuildingTypeError,
  } = useSelector((state) => state.property.updateBuildingType);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [serverErrors, setServerErrors] = useState([]);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [hasFormValues, sethasFormValues] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [buildingTypeId, setBuildingTypeId] = useState('');

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [selectedRows, setSelectedRows] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const watchedFields = watch();

  useBrowserBackButtonHandler({
    hasUnsavedChanges: hasFormValues,
    setOpenConfirmationModal,
    open,
    watchMethod: watch,
    sethasFormValues,
  });

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );
  const navigate = useNavigate();

  // Memoized columns based on the role
  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: t('attributes.configuration.buildingType.title'),
        field: 'display_name',
        sortable: true,
        flex: 5,
      },
      {
        field: 'edit',
        headerName: t('attributes.action'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Edit
            onClick={() => {
              setBuildingTypeId(row.uuid);
              setOpen(true);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  // Function to fetch data based on search, pagination, and filter
  const getAllBuildingType = useCallback(() => {
    setSelectedRows(null);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);

    dispatch(
      getBuildingTypeList({
        active: activeTab === 0 ? true : false,
        limit: perPageData,
        page: currentPage,
        search: searchText,
        order: order,
        orderBy: orderBy,
        list_column_names: visibleFieldsString,
      })
    ).finally(() => {
      setIsDataLoading(false);
      setIsInitialLoading(false);
    });
  }, [
    dispatch,
    activeTab,
    perPageData,
    currentPage,
    order,
    orderBy,
    refresh,
    searchText,
  ]);

  useEffect(
    () => () => {
      dispatch(resetCreateBuildingType());
      dispatch(resetUpdateBuildingType());
      dispatch(resetBuildingTypeList());
      dispatch(resetGetBuildingType());
    },
    []
  );

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllBuildingType, 500), [
    getAllBuildingType,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    showHideTopbarLoader(createLoading, dispatch);
    if (createLoading !== null && !createLoading) {
      if (error) {
        if (error.errorDetails) {
          setServerErrors(error.errorDetails);
        }
      } else {
        if (createdBuildingType) {
          if (!buildingTypeId) setActiveTab(0);
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: createdBuildingType?.message,
            })
          );
        }
      }
    }
  }, [error, createLoading]);

  useEffect(() => {
    showHideTopbarLoader(updateLoading, dispatch);
    if (updateLoading !== null && !updateLoading) {
      if (updatedBuildingTypeError) {
        if (updatedBuildingTypeError?.errorDetails) {
          setServerErrors(updatedBuildingTypeError?.errorDetails);
        }
      } else {
        if (updateBuildingTypeData) {
          if (!buildingTypeId) setActiveTab(0);
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: updateBuildingTypeData?.message,
            })
          );
        }
      }
    }
  }, [updatedBuildingTypeError, updateLoading]);

  const rows = proertyBuildingTypeData?.map((item) => {
    const updatedItem = Object.keys(item).reduce((acc, key) => {
      acc[key] = item[key] || item[key] === 0 ? item[key] : '-'; // Ensures falsy values except `0` are replaced with '-'

      return acc;
    }, {});

    updatedItem.created_at = item.created_at;

    return updatedItem;
  });

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const onSubmit = async (data) => {
    const request = {
      display_name: data?.name,
      name: data?.name?.toLowerCase(),
      is_active: data.is_active,
    };

    if (!buildingTypeId) {
      dispatch(createBuildingType(request));
    } else {
      dispatch(updateBuildingType({ uuid: buildingTypeId, data: request }));
    }
  };

  const onTabChange = () => {
    setIsDataLoading(true);
    setSearchText('');
    setIsFilterComponentVisible(false);
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const noData = (
    <NewNoDataPage
      icon={<LocationCityOutlinedIcon />}
      title={t('attributes.configuration.buildingType.title')}
      singularText={t('attributes.configuration.buildingType.title')}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
        overflow: 'hidden',
      }}
      createBtnText={`${t('attributes.add')} ${t('attributes.configuration.buildingType.title')}`}
      filterHeight={isFilterComponentVisible ? 357 : 301}
    />
  );

  const renderedComponent = (
    <>
      <FilterSection
        searchText={searchText}
        isFilter={false}
        isReset={false}
        onSearchChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        onRowSelectionModelChange={handleSelectionChange}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  const mainWrapper = (
    <>
      <MainWrapper
        defaultPadding="0px 0px 16px 0px"
        title={t('attributes.configuration.title')}
        variant="body1"
        isStep={true}
        bgcolor={DIVIDER_COLOR}
        step={t('attributes.configuration.buildingType.title')}
        handleDefaultStep={() => {
          navigate(
            getRedirectURL()[getUserRole()][ROUTE_NAVIGATE_CONST.CONFIGURATION]
          );
        }}
      ></MainWrapper>
      <StyledMainWrapper
        btn={
          <>
            <CustomButton
              text={`${t('attributes.add')}  ${t('attributes.configuration.buildingType.title')}`}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              color="secondary"
              onClick={() => {
                setOpen(true);
                setBuildingTypeId('');
                reset(defaultValues);
              }}
            />
            <CustomButton
              text={t('attributes.export')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<OpenInNewIcon />}
              onClick={() => {
                if (!selectedRows || selectedRows?.length === 0) {
                  dispatch(
                    snackbarToggle({
                      isOpen: true,
                      isErrorMsg: true,
                      msg: t('message.common.noRecordExportMessage'),
                    })
                  );
                  setSelectedRows(null);
                } else {
                  exportToCSV(
                    selectedRows,
                    COLUMNS_TO_EXPORT.BUILDING_TYPE,
                    EXPORT_FILE_NAMES.BUILDING_TYPE,
                    COLUMNS_TO_MAP.BUILDING_TYPE
                  );
                }
              }}
            />
          </>
        }
      >
        {!isDataLoading && totalCount === 0 && noData}
        {isInitialLoading ? (
          <Box
            sx={{
              width: '100%',
              mt: 2,
              minHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
              maxHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CustomCircularLoader />
          </Box>
        ) : (
          !isInitialLoading &&
          totalCount > 0 && (
            <TabView
              tabs={[
                {
                  label: t('common.active'),
                  component: renderedComponent,
                },
                {
                  label: t('common.inactive'),
                  component: renderedComponent,
                },
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              onTabChange={onTabChange}
            />
          )
        )}
      </StyledMainWrapper>
    </>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
            sethasFormValues(false);

            if (window.history.state === null) {
              window.history.back();
            }
          }}
        />
      )}
      {mainWrapper}
      {open && (
        <SwipeableDrawer
          open={open}
          title={
            !buildingTypeId
              ? `${t('attributes.add')} ${t('attributes.configuration.buildingType.title')}`
              : `${t('attributes.edit')} ${t('attributes.configuration.buildingType.title')}`
          }
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          footerButton={
            <CustomButton
              text={
                createLoading || updateLoading
                  ? t('common.loading')
                  : !buildingTypeId
                    ? t('attributes.add')
                    : t('attributes.save')
              }
              disabled={createLoading || updateLoading}
              startIcon={!buildingTypeId ? <AddIcon /> : <SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
            />
          }
        >
          <BuildingTypeForm
            id={buildingTypeId}
            reset={reset}
            clearErrors={clearErrors}
            control={control}
            register={register}
            trigger={trigger}
            watch={watch}
            setValue={setValue}
            setServerErrors={setServerErrors}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default BuildingTypeConfiguration;
