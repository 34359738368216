import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Stack, Typography } from '@mui/material';

import { WORK_ORDER_TYPE_REPAIR } from '../../../constants/Constants';
import { snackbarToggle } from '../../../store/CommonReducer';
import {
  getAssetsList,
  getDefectsQuotesList,
  updateDefect,
} from '../../../store/workOrder/api';
import { resetUpdateDefect } from '../../../store/workOrder/reducer';
import {
  formatStatus,
  formattedDate,
  showHideTopbarLoader,
} from '../../../utils';
import { Edit, View } from '../../CommonComponents/ActionComponent';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import DefectQuotesEdit from './DefectsQuotesEdit';
import DefectsQuotesView from './DefectsQuotesView';

const DefectsQuotes = ({ workOrderUUID, work_order_type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('identified_date');

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [defects, setDefects] = useState(null);
  const [selectedDefect, setSelectedDefect] = useState(null);
  const { control, reset, handleSubmit } = useForm();
  const {
    data: updateDefectData,
    isLoading: updateDefectLoading,
    error: updateDefectError,
  } = useSelector((state) => state.workOrder.updateDefect);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'defects_id',
        headerName: `${t('attributes.work_order.defect')} ${t('attributes.work_order.id')}`,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          work_order_type === WORK_ORDER_TYPE_REPAIR ? (
            <Typography
              variant="body1"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {`D-${row?.defect?.defect_id || '-'}`}
            </Typography>
          ) : row?.id ? (
            <Typography
              variant="body1"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {`D-${row?.defect_id}`}
            </Typography>
          ) : (
            ''
          ),
      },
      ...(work_order_type !== WORK_ORDER_TYPE_REPAIR
        ? [
            {
              field: 'defect_title',
              headerName: `${t('attributes.work_order.defect')} ${t('attributes.work_order.title')}`,
              flex: 1,
              sortable: false,
            },
          ]
        : []),
      {
        field: 'asset_name',
        headerName: `${t('attributes.property.asset')} ${t('attributes.name')}`,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row?.asset_name ? (
            <Typography
              variant="body1"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {row?.asset_name}
            </Typography>
          ) : (
            '-'
          ),
      },
      {
        field: 'description',
        headerName: t('attributes.description'),
        flex: 1,
        sortable: false,
        ...(work_order_type === WORK_ORDER_TYPE_REPAIR && {
          renderCell: ({ row }) => (
            <Typography variant="body2">
              {row?.defect?.defect_description || '-'}
            </Typography>
          ),
        }),
      },
      {
        field: 'defect_resolution',
        headerName: `${t('attributes.work_order.defect')} ${t('attributes.work_order.resolution')}`,
        flex: 1,
        sortable: false,
        ...(work_order_type === WORK_ORDER_TYPE_REPAIR && {
          renderCell: ({ row }) => (
            <Typography variant="body2">
              {row?.defect?.short_resolution || '-'}
            </Typography>
          ),
        }),
      },
      {
        field: 'severity',
        headerName: t('attributes.work_order.severity'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          formatStatus(
            work_order_type === WORK_ORDER_TYPE_REPAIR
              ? row?.defect?.severity
              : row?.severity
          ),
      },
      ...(work_order_type === WORK_ORDER_TYPE_REPAIR
        ? [
            {
              field: 'status',
              headerName: t('attributes.work_order.status'),
              flex: 1,
              sortable: false,
              renderCell: ({ row }) => formatStatus(row?.defect?.latest_status),
            },
          ]
        : []),
      {
        field: 'identified_date',
        headerName: `${t('attributes.work_order.identified')} ${t('attributes.date')}`,
        flex: 1,
      },
      {
        field: 'view',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Stack direction="row" spacing={1}>
            <View
              onClick={() => {
                setOpen('view');
                setSelectedDefect(row);
              }}
            />
            <Edit
              onClick={() => {
                setOpen('edit');
                setSelectedDefect(row);
              }}
            />
          </Stack>
        ),
      },
    ];

    return baseColumns;
  }, []);

  const rows = defects?.data?.map((defect, index) => ({
    ...defect,
    id: defect?.defect_id || index + 1,
    asset_name: defect?.asset?.label,
    description: defect?.defect_description || '-',
    defect_resolution: defect?.short_resolution || '-',
    severity: defect?.severity || '-',
    identified_date: formattedDate(defect.created_at) || '-',
  }));

  const getDefects = useCallback(() => {
    setIsDataLoading(true);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    dispatch(
      work_order_type !== WORK_ORDER_TYPE_REPAIR
        ? getDefectsQuotesList({
            limit: perPageData,
            size: perPageData,
            page: currentPage,
            order: order,
            orderBy: orderBy,
            workorder_uuid: workOrderUUID,
          })
        : getAssetsList({
            workorder_uuid: workOrderUUID,
          })
    )
      .then((res) => setDefects(res?.payload))
      .finally(() => setIsDataLoading(false));

    setColumnVisibilityModel(visibleFieldsString);
  }, [
    perPageData,
    currentPage,
    order,
    orderBy,
    columns,
    columnVisibilityModel,
  ]);

  useEffect(() => {
    showHideTopbarLoader(updateDefectLoading, dispatch);
    if (updateDefectLoading !== null && !updateDefectLoading) {
      if (updateDefectData?.data?.length > 0) {
        setOpen(false);
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: updateDefectData?.message,
          })
        );
      } else if (updateDefectError) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: updateDefectError.message,
          })
        );
      }
      getDefects();
      dispatch(resetUpdateDefect());
    }
  }, [updateDefectData, updateDefectLoading]);

  const onSubmit = (data) => {
    const req = {
      severity: data?.severity?.value,
      defect_title: selectedDefect?.defect_title,
      defect_description: selectedDefect?.defect_description,
      short_resolution: selectedDefect?.short_resolution,
      equipment_type_id: selectedDefect?.equipment_type_id,
      equipment_class_id: selectedDefect?.equipment_class_id,
    };

    dispatch(
      updateDefect({
        woid: workOrderUUID,
        asset_id: selectedDefect?.asset_uuid,
        defect_id: selectedDefect?.uuid,
        data: req,
      })
    );
  };

  useEffect(
    () => () => {
      dispatch(resetUpdateDefect());
    },
    []
  );

  useEffect(() => {
    if (workOrderUUID) {
      getDefects();
    }
  }, [workOrderUUID, perPageData, currentPage, order, orderBy]);

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData]);

  return (
    <>
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={defects?.total || 0}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        paginationRequired={false}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        isLoading={isDataLoading}
        noData={<NoRecordFound />}
      />

      {open === 'view' && (
        <SwipeableDrawer
          open={open === 'view'}
          title={`D-${work_order_type === WORK_ORDER_TYPE_REPAIR ? selectedDefect?.defect?.id : selectedDefect?.id}`}
          onClose={() => setOpen(false)}
        >
          <DefectsQuotesView
            defect_id={
              work_order_type === WORK_ORDER_TYPE_REPAIR
                ? selectedDefect?.defect?.uuid
                : selectedDefect?.uuid
            }
            work_order_id={workOrderUUID}
          />
        </SwipeableDrawer>
      )}

      {open === 'edit' && (
        <SwipeableDrawer
          open={open === 'edit'}
          title={`${t('attributes.edit')} ${t('attributes.defects.defects')}`}
          onClose={() => setOpen(false)}
          footerButton={
            <CustomButton
              text={t('attributes.save')}
              disabled={updateDefectLoading}
              startIcon={<SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
            />
          }
        >
          <DefectQuotesEdit
            control={control}
            selectedDefect={selectedDefect}
            reset={reset}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default DefectsQuotes;
