import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { CssBaseline, ThemeProvider } from '@mui/material';

// Internal
import { theme } from './assets/theme';
import CustomSnackbar from './components/CommonComponents/Snackbar';
import { SOMETHING_WENT_WRONG_IMAGE } from './constants/Constants';
import { msalConfig } from './hoc/authConfig';
import { routes } from './routes';
import { snackbarToggle } from './store/CommonReducer';

// MSAL instance
export const msalInstance = new PublicClientApplication(msalConfig);

export let router;

export const navigate = (path) => {
  if (router) {
    const absolutePath = path.startsWith('/') ? path : `/${path}`;

    router.navigate(absolutePath, { replace: true });
  } else {
    console.error('Router not initialized yet');
  }
};

const cacheImage = async (url, key) => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const contentType = response.headers.get('content-type');

    if (!contentType.startsWith('image')) {
      throw new Error('Fetched content is not an image.');
    }

    const blob = await response.blob();
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Data = `data:${contentType};base64,${reader.result.split(',')[1]}`; // Properly format the Base64 string

      localStorage.setItem(key, base64Data); // Save Base64 string in localStorage
    };
    reader.readAsDataURL(blob); // Convert blob to base64
  } catch (error) {
    console.error('Failed to cache image:', error);
  }
};

const App = () => {
  const dispatch = useDispatch();

  const { snackbarOpen, snackbarMessage, isErrorMsg } = useSelector(
    (state) => state.common
  );

  useEffect(() => {
    router = routes;
  }, []);

  useEffect(() => {
    if (snackbarOpen) {
      const timer = setTimeout(() => {
        dispatch(
          snackbarToggle({
            snackbarOpen: false,
            snackbarMessage: '',
            isErrorMsg: false,
          })
        );
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [snackbarOpen]);

  useEffect(() => {
    cacheImage(
      `${process.env.REACT_APP_REDIRECT_URL}/images/something-wrong.png`,
      SOMETHING_WENT_WRONG_IMAGE
    );
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={routes} />
        <CustomSnackbar
          open={snackbarOpen}
          onClose={() => dispatch(snackbarToggle)}
          msg={snackbarMessage}
          isError={isErrorMsg}
        />
      </ThemeProvider>
    </MsalProvider>
  );
};

export default App;
