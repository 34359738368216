import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import FlagCircleOutlinedIcon from '@mui/icons-material/FlagCircleOutlined';
import { Box, Button, Typography } from '@mui/material';

import { ICON_DEFAULT_COLOR } from '../../../constants/Colors';
import { Validation } from '../../../constants/FieldValidationMsg';
import { clearTextfields } from '../../../utils';
import Autocomplete from '../../CommonComponents/AutoComplete';

const CityFirstRender = ({
  control,
  stateListDropdown,
  watch,
  setValue,
  onSelect,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100vh - 14vh)',
      }}
    >
      <Box
        component="form"
        sx={{
          width: '100%',
          maxWidth: 500,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 1,
          paddingX: { xs: 2, md: 4 },
          paddingY: { xs: 2, md: 3 },
        }}
      >
        <FlagCircleOutlinedIcon
          sx={{ height: 40, width: 40, color: ICON_DEFAULT_COLOR }}
        />
        <Typography variant="body1">
          {t('attributes.configuration.city.selectState')}
        </Typography>
        <Typography variant="body2" align="center" sx={{ fontSize: '12px' }}>
          {
            t('attributes.configuration.city.selectStateDescription').split(
              ' \n '
            )[0]
          }
          <br />
          {
            t('attributes.configuration.city.selectStateDescription').split(
              ' \n '
            )[1]
          }
        </Typography>
        <Controller
          name={'state'}
          control={control}
          rules={{
            required: `${t('common.state')} ${Validation.general.required}`,
          }}
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }) => (
            <Autocomplete
              label={t('common.state')}
              options={stateListDropdown}
              value={value || ''}
              onChange={(e, newValue) => {
                onChange(newValue);
              }}
              helperText={error ? error.message : ''}
              error={error}
              onClear={() => clearTextfields(setValue, name)}
            />
          )}
        />

        {watch('state') && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSelect(watch('state'))}
            endIcon={<ChevronRightOutlinedIcon />}
            sx={{ mt: 2 }}
          >
            {t('common.next')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CityFirstRender;
