import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { DIVIDER_COLOR } from '../../../constants/Colors';
import { ROUTE_NAVIGATE_CONST } from '../../../constants/Constants';
import { GLOBAL_ADMIN } from '../../../constants/Roles';
import { getRedirectURL } from '../../../utils';
import ComingSoon from '../../CommonComponents/ComingSoon';
import MainWrapper from '../../CommonComponents/MainWrapper';

export const GlobalAdminSecurityGroups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleDefaultStep = () => {
    navigate(
      getRedirectURL()[GLOBAL_ADMIN][ROUTE_NAVIGATE_CONST.CONTROL_PANEL]
    );
  };

  const TemplateDataWrapper = (
    <>
      <Box sx={{ backgroundColor: DIVIDER_COLOR, width: '100%' }}>
        <MainWrapper
          defaultPadding="0px 0px 16px 0px"
          title={t('attributes.controlPanel.title')}
          variant="body1"
          isStep={true}
          step={t('attributes.controlPanel.securityGroups')}
          handleDefaultStep={handleDefaultStep}
        ></MainWrapper>
      </Box>
      <ComingSoon height={'87vh'} />
    </>
  );

  return <>{TemplateDataWrapper}</>;
};
