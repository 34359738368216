import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, Divider, Modal, Typography } from '@mui/material';

import { HoverText, ImageWrapper } from '../../../assets/commonStyled';
import {
  DATE_TIME_RANGE_FORMAT_DEFAULT,
  DEFECT_STATUS,
  RESOLVED,
} from '../../../constants/Constants';
import { getDefectQuote } from '../../../store/workOrder/api';
import { formatStatus, formattedDate } from '../../../utils';
import CommonCarouselView from '../../CommonComponents/CommonCarouselView';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import ImageView, { imageModalView } from '../../CommonComponents/ImageView';

const KeyValueComponent = ({ name, value }) => (
  <Box>
    <Typography variant="body1">{name}</Typography>
    <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
      {value}
    </Typography>
  </Box>
);

const DefectsQuotesView = ({ work_order_id, defect_id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState(new Map());
  const [resolutionImgSrc, setResolutionImgSrc] = useState(new Map());
  const [type, setType] = useState('');

  const [defect, setDefect] = useState(null);

  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openImage = () => setOpen(true);

  const addImageToMap = (uuid, url) => {
    setResolutionImgSrc((prevMap) => {
      const newMap = new Map(prevMap);

      newMap.set(uuid, url);

      return newMap;
    });
  };

  const addImageToMapImages = (uuid, url) => {
    setImgSrc((prevMap) => {
      const newMap = new Map(prevMap);

      newMap.set(uuid, url);

      return newMap;
    });
  };

  // Resolution
  useEffect(() => {
    if (
      resolutionImgSrc?.size ===
      defect?.documents?.filter((doc) => doc?.document_type === RESOLVED)
        ?.length
    ) {
      const orderedImages = defect?.documents
        ?.filter((doc) => doc?.document_type === RESOLVED)
        ?.map((doc) => resolutionImgSrc.get(doc?.document_uuid));

      setResolutionImgSrc(orderedImages);
    }
  }, [defect, resolutionImgSrc]);

  // Photo Images
  useEffect(() => {
    if (
      imgSrc?.size ===
      defect?.documents?.filter((doc) => doc?.document_type === DEFECT_STATUS)
        ?.length
    ) {
      const orderedImages = defect?.documents
        ?.filter((doc) => doc?.document_type === DEFECT_STATUS)
        ?.map((doc) => imgSrc.get(doc?.document_uuid));

      setImgSrc(orderedImages);
    }
  }, [defect, imgSrc]);

  useEffect(() => {
    if (work_order_id && defect_id) {
      setIsLoading(true);
      dispatch(
        getDefectQuote({ work_order_id: work_order_id, defect_id: defect_id })
      )
        .then((res) => setDefect(res?.payload?.data?.[0]))
        .finally(() => setIsLoading(false));
    }

    return () => {
      setImgSrc(null);
    };
  }, [work_order_id, defect_id]);

  const dataimages = type === 'resolution' ? resolutionImgSrc : imgSrc;

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
      <Card sx={{ boxShadow: 'none !important' }}>
        <Box sx={{ padding: '12px' }}>
          <Typography variant="body1">{`${t('attributes.work_order.defect')} ${t('attributes.supplier.details')}`}</Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            padding: '12px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '16px',
            width: '100%',
          }}
        >
          <KeyValueComponent
            name={t('attributes.work_order.title')}
            value={defect?.defect_title || '-'}
          />
          <KeyValueComponent
            name={t('attributes.work_order.severity')}
            value={formatStatus(defect?.severity) || '-'}
          />
          <KeyValueComponent
            name={t('attributes.productCatalogue.description')}
            value={defect?.defect_description || '-'}
          />
          <KeyValueComponent
            name={t('attributes.work_order.resolution')}
            value={defect?.short_resolution || '-'}
          />

          <Box>
            <KeyValueComponent name={t('attributes.work_order.photos')} />
            <Box
              sx={{
                display: 'flex',
                columnGap: '16px',
                flexWrap: 'wrap',
              }}
            >
              {defect?.documents?.length > 0 ? (
                <CommonCarouselView
                  totalItems={
                    defect?.documents?.filter(
                      (doc) => doc?.document_type === DEFECT_STATUS
                    )?.length
                  }
                  centerMode={true}
                >
                  {defect?.documents
                    ?.filter((doc) => doc?.document_type === DEFECT_STATUS)
                    ?.map((doc, index) => (
                      <ImageWrapper key={doc?.document_uuid}>
                        <ImageView
                          uuid={doc?.document_uuid}
                          addImageToMap={addImageToMapImages}
                        />
                        <HoverText
                          className="hoverText"
                          onClick={() => {
                            setCurrentIndex(index);
                            openImage();
                            setType('photo');
                          }}
                        >
                          <Typography variant="body1">
                            {t('attributes.view')}
                          </Typography>
                        </HoverText>
                      </ImageWrapper>
                    ))}
                </CommonCarouselView>
              ) : (
                <>{t('common.no_document_uploaded')}</>
              )}
            </Box>
          </Box>
        </Box>
      </Card>
      <Card sx={{ boxShadow: 'none !important' }}>
        <Box sx={{ padding: '12px' }}>
          <Typography variant="body1">
            {t('attributes.work_order.resolution')}
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '16px',
          }}
        >
          <KeyValueComponent
            name={t('attributes.work_order.comment')}
            value={defect?.resolution_comment || '-'}
          />

          <Box>
            <KeyValueComponent name={t('attributes.work_order.photos')} />
            <Box
              sx={{
                display: 'flex',
                columnGap: '16px',
                flexWrap: 'wrap',
              }}
            >
              {defect?.documents?.length > 0 ? (
                <CommonCarouselView
                  totalItems={
                    defect?.documents?.filter(
                      (doc) => doc?.document_type === RESOLVED
                    )?.length
                  }
                  centerMode={true}
                >
                  {defect?.documents
                    ?.filter((doc) => doc?.document_type === RESOLVED)
                    ?.map((doc, index) => (
                      <ImageWrapper key={doc?.document_uuid}>
                        <ImageView
                          uuid={doc?.document_uuid}
                          addImageToMap={addImageToMap}
                        />
                        <HoverText
                          className="hoverText"
                          onClick={() => {
                            setCurrentIndex(index);
                            openImage();
                            setType('resolution');
                          }}
                        >
                          <Typography variant="body1">
                            {t('attributes.view')}
                          </Typography>
                        </HoverText>
                      </ImageWrapper>
                    ))}
                </CommonCarouselView>
              ) : (
                <>{t('common.no_document_uploaded')}</>
              )}
            </Box>
          </Box>

          <KeyValueComponent
            name={t('attributes.scheduleBoard.dateAndTime')}
            value={
              formattedDate(
                defect?.resolved_date_time,
                DATE_TIME_RANGE_FORMAT_DEFAULT
              ) || '-'
            }
          />
        </Box>
      </Card>
      {open && (
        <Modal
          open={open}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#000000D9',
          }}
        >
          <>
            <CloseIcon
              style={{
                fill: 'white',
                height: '45px',
                width: '45px',
                position: 'absolute',
                top: '10px',
                right: '10px',
              }}
              onClick={() => setOpen(false)}
            />
            <CommonCarouselView
              widthInPercentage={100}
              totalItems={dataimages?.length}
              showThumbs={true}
              arrowSize="16px"
              arrowWrapperSize="40px"
              arrowLeft="40px"
              arrowRight="40px"
              centerMode={true}
              isModal={true}
              currentIndex={currentIndex}
            >
              {dataimages?.map((img, index) => (
                <React.Fragment key={index}>
                  {imageModalView(img)}
                </React.Fragment>
              ))}
            </CommonCarouselView>
          </>
        </Modal>
      )}
    </Box>
  );
};

export default DefectsQuotesView;
