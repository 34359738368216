import React from 'react';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Box, styled, Typography, useMediaQuery } from '@mui/material';

// Internal
import { LandingPageImage } from '../../assets/images';
import { PRIMARY, SECONDARY } from '../../constants/Colors';

// Styled Components
const Container = styled(Box)(({ theme, isSmallScreen }) => ({
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: isSmallScreen ? 'column' : 'row',
  justifyContent: isSmallScreen ? 'space-evenly' : 'space-between',
}));

const Centered = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const DescriptionStyled = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const LandingPageBody = () => {
  const isSmallScreen = useMediaQuery('(max-width:1180px)');

  return (
    <Container
      sx={{ backgroundColor: `${SECONDARY} !important` }}
      isSmallScreen={isSmallScreen}
    >
      <Centered style={{ marginLeft: isSmallScreen ? '30px' : '100px' }}>
        <Box
          style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}
        >
          <Typography color="primary" variant="h5">
            Your Fire Safety <br /> Solution in the Cloud
          </Typography>
          <Typography color="secondary.contrastText" variant="h4">
            Streamlined Fire Safety Management
          </Typography>
          <DescriptionStyled>
            <span style={{ color: PRIMARY }}>STAM</span>’s PaaS and SaaS
            solutions streamline all aspects of fire <br /> safety and
            compliance. Our cloud-based platform adapts to your <br /> business,
            ensuring seamless management and scalability.
          </DescriptionStyled>
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CheckCircleRoundedIcon
                sx={{
                  color: PRIMARY,
                  height: '24px',
                  width: '24px',
                }}
              />
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '16px',
                  paddingLeft: '16px',
                }}
              >
                Service
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: '30px',
              }}
            >
              <CheckCircleRoundedIcon
                sx={{
                  color: PRIMARY,
                  height: '24px',
                  width: '24px',
                }}
              />
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '16px',
                  paddingLeft: '16px',
                }}
              >
                Testing
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: '30px',
              }}
            >
              <CheckCircleRoundedIcon
                sx={{
                  color: PRIMARY,
                  height: '24px',
                  width: '24px',
                }}
              />
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '16px',
                  paddingLeft: '16px',
                }}
              >
                Auditing
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: '30px',
              }}
            >
              <CheckCircleRoundedIcon
                sx={{
                  color: PRIMARY,
                  height: '24px',
                  width: '24px',
                }}
              />
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '16px',
                  paddingLeft: '16px',
                }}
              >
                Maintenance
              </Typography>
            </Box>
          </Box>
        </Box>
      </Centered>
      <Centered style={{ marginRight: isSmallScreen ? '30px' : '100px' }}>
        <img
          src={LandingPageImage}
          alt="Landing Page"
          style={{ width: '516px', height: '516px' }}
        />
      </Centered>
    </Container>
  );
};

export default LandingPageBody;
