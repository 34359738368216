import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box } from '@mui/material';
import { debounce } from 'lodash';

import { DIVIDER_COLOR } from '../../../constants/Colors';
import { exportToCSV } from '../../../constants/common';
import { ROUTE_NAVIGATE_CONST } from '../../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  EXPORT_FILE_NAMES,
} from '../../../constants/ExportConstant';
import { getUserRole } from '../../../hoc/Permission';
import useBrowserBackButtonHandler from '../../../hooks/useBrowserBackButtonHandler';
import useServerSideErrors from '../../../hooks/useServerSideErrors';
import { createCity, getCityList, updateCity } from '../../../store/city/api';
import {
  resetCreateCity,
  resetGetCityList,
  resetUpdateCity,
} from '../../../store/city/reducer';
import { getLocation } from '../../../store/CommonAPI';
import { snackbarToggle } from '../../../store/CommonReducer';
import { getRedirectURL, showHideTopbarLoader } from '../../../utils';
import { Edit } from '../../CommonComponents/ActionComponent';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import { FilterSection } from '../../CommonComponents/FilterComponent';
import MainWrapper from '../../CommonComponents/MainWrapper';
import { ConfirmationModal } from '../../CommonComponents/Modal';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import CityFirstRender from './CityFirstRender';
import CityForm from './CityForm';

// Will be used for Super Admin
const defaultValues = {
  name: '',
  parent_id: '',
};

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

const CityConfiguration = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    control,
    reset,
    trigger,
    setError,
    clearErrors,
    watch,
    setValue,
  } = useForm();

  const { loading: listLoading, data: cityList } = useSelector(
    (state) => state.city.getCityList
  );

  const {
    loading: createLoading,
    data: createCityData,
    error,
  } = useSelector((state) => state.city.createCity);

  const {
    loading: updateLoading,
    data: updateCityData,
    error: updatedCityTypeError,
  } = useSelector((state) => state.city.updateCity);

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [serverErrors, setServerErrors] = useState([]);

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [hasFormValues, sethasFormValues] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [cityId, setCityId] = useState('');

  const [isNotFirstRender, setIsNotFirstRender] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState(null);
  const watchedFields = watch();

  useBrowserBackButtonHandler({
    hasUnsavedChanges: hasFormValues,
    setOpenConfirmationModal,
    open,
    watchMethod: watch,
    sethasFormValues,
  });

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );
  const navigate = useNavigate();

  // Memoized columns based on the role
  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: t('common.state'),
        field: 'state',
        sortable: false,
        flex: 5,
      },
      {
        headerName: t('common.city'),
        field: 'city',
        sortable: false,
        flex: 5,
      },
      {
        field: 'edit',
        headerName: t('attributes.action'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Edit
            onClick={() => {
              setCityId(row);
              setOpen(true);
            }}
            disabled={row?.is_system_default}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  // Function to fetch data based on search, pagination, and filter
  const getAllCityType = useCallback(() => {
    setSelectedRows(null);
    setIsDataLoading(true);

    dispatch(
      getCityList({
        active: true,
        search: searchText,
        include_ids: true,
      })
    ).finally(() => {
      setIsDataLoading(false);
      setIsInitialLoading(false);
    });
  }, [dispatch, searchText]);

  useEffect(
    () => () => {
      dispatch(resetGetCityList());
      dispatch(resetUpdateCity());
      dispatch(resetCreateCity());
    },
    []
  );

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllCityType, 500), [
    getAllCityType,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    showHideTopbarLoader(createLoading, dispatch);
    if (createLoading !== null && !createLoading) {
      if (error) {
        if (error.errorDetails) {
          setServerErrors(error.errorDetails);
        } else {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: error?.message,
            })
          );
        }
      } else {
        if (createCityData) {
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: createCityData?.message,
            })
          );
          dispatch(getLocation());
        }
      }
    }
  }, [error, createLoading]);

  useEffect(() => {
    showHideTopbarLoader(updateLoading, dispatch);
    if (updateLoading !== null && !updateLoading) {
      if (updatedCityTypeError) {
        if (updatedCityTypeError?.errorDetails) {
          setServerErrors(updatedCityTypeError?.errorDetails);
        } else {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: updatedCityTypeError?.message,
            })
          );
        }
      } else {
        if (updateCityData) {
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: updateCityData?.message,
            })
          );
          dispatch(getLocation());
        }
      }
    }
  }, [updatedCityTypeError, updateLoading]);

  const rows = cityList?.states
    ?.filter((state) => state?.id === isNotFirstRender?.value)
    ?.flatMap((state) =>
      state?.cities?.map((city) => ({
        state: state?.name,
        city: city?.name,
        id: city?.id,
        parent_id: state?.id,
        is_system_default: city?.is_system_default,
      }))
    );
  const stateListDropdown = cityList?.states?.map((state) => ({
    label: state.name,
    value: state.id,
  }));

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const onSubmit = async (data) => {
    const request = {
      name: data?.name,
      type: 'city',
      parent_id: data?.parent_id?.value,
    };

    if (!cityId) {
      dispatch(createCity({ data: request }));
    } else {
      dispatch(updateCity({ id: cityId?.id, data: request }));
    }
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  // const noData = (
  //   <NewNoDataPage
  //     icon={<LocationCityOutlinedIcon />}
  //     title={t('attributes.configuration.city.title')}
  //     singularText={t('attributes.configuration.city.title')}
  //     sx={{
  //       display: 'flex',
  //       flexDirection: 'column',
  //       height: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
  //       overflow: 'hidden',
  //     }}
  //     createBtnText={`${t('attributes.add')} ${t('attributes.configuration.city.title')}`}
  //     filterHeight={isFilterComponentVisible ? 357 : 301}
  //   />
  // );

  const renderedComponent = (
    <>
      <FilterSection
        searchText={searchText}
        isFilter={false}
        isReset={false}
        onSearchChange={(e) => {
          setSearchText(e.target.value);
        }}
        sx={{
          marginTop: '16px',
          marginRight: '16px',
        }}
      />
      <CustomGridTable
        columns={columns}
        rows={rows}
        onRowSelectionModelChange={handleSelectionChange}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
        paginationRequired={false}
      />
    </>
  );

  const mainWrapper = (
    <>
      <MainWrapper
        defaultPadding="0px 0px 16px 0px"
        title={t('attributes.configuration.title')}
        variant="body1"
        isStep={true}
        bgcolor={DIVIDER_COLOR}
        step={t('attributes.configuration.city.title')}
        handleDefaultStep={() => {
          navigate(
            getRedirectURL()[getUserRole()][ROUTE_NAVIGATE_CONST.CONFIGURATION]
          );
        }}
      ></MainWrapper>
      <StyledMainWrapper
        btn={
          isNotFirstRender && (
            <>
              <CustomButton
                text={`${t('attributes.add')}  ${t('attributes.configuration.city.title')}`}
                startIcon={<AddCircleOutlineOutlinedIcon />}
                color="secondary"
                onClick={() => {
                  setOpen(true);
                  setCityId('');
                  reset(defaultValues);
                }}
              />
              <CustomButton
                text={t('attributes.export')}
                color="secondary"
                sx={{ height: '52%' }}
                startIcon={<OpenInNewIcon />}
                onClick={() => {
                  if (!selectedRows || selectedRows?.length === 0) {
                    dispatch(
                      snackbarToggle({
                        isOpen: true,
                        isErrorMsg: true,
                        msg: t('message.common.noRecordExportMessage'),
                      })
                    );
                    setSelectedRows(null);
                  } else {
                    exportToCSV(
                      selectedRows,
                      COLUMNS_TO_EXPORT.CITY,
                      EXPORT_FILE_NAMES.CITY
                    );
                  }
                }}
              />
            </>
          )
        }
      >
        {isInitialLoading ? (
          <Box
            sx={{
              width: '100%',
              mt: 2,
              minHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
              maxHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CustomCircularLoader />
          </Box>
        ) : !isInitialLoading && !isNotFirstRender ? (
          <CityFirstRender
            watch={watch}
            control={control}
            stateListDropdown={stateListDropdown}
            setValue={setValue}
            onSelect={setIsNotFirstRender}
          />
        ) : (
          renderedComponent
        )}
      </StyledMainWrapper>
    </>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
            sethasFormValues(false);

            if (window.history.state === null) {
              window.history.back();
            }
          }}
        />
      )}
      {mainWrapper}
      {open && (
        <SwipeableDrawer
          open={open}
          title={
            !cityId
              ? `${t('attributes.add')} ${t('attributes.configuration.city.title')}`
              : `${t('attributes.edit')} ${t('attributes.configuration.city.title')}`
          }
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          footerButton={
            <CustomButton
              text={
                createLoading || updateLoading
                  ? t('common.loading')
                  : !cityId
                    ? t('attributes.add')
                    : t('attributes.save')
              }
              disabled={createLoading || updateLoading}
              startIcon={!cityId ? <AddIcon /> : <SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
            />
          }
        >
          <CityForm
            id={cityId}
            reset={reset}
            clearErrors={clearErrors}
            control={control}
            register={register}
            trigger={trigger}
            watch={watch}
            setValue={setValue}
            setServerErrors={setServerErrors}
            stateListDropdown={stateListDropdown}
            selectedState={isNotFirstRender}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default CityConfiguration;
