import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { Box, Divider, styled, Typography } from '@mui/material';

import { CustomCard, FormWrapper } from '../../../assets/commonStyled';
import { DIVIDER_COLOR, PRIMARY } from '../../../constants/Colors';
import { TAX_NAME } from '../../../constants/Constants';
import {
  calculateTaxPercentage,
  calculateTotal,
  formatStatus,
  formattedDate,
} from '../../../utils';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';
const DetailContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
}));

export const PreviewPurchaseOrderInvoiceBill = ({
  isLoading,
  invoiceBilldata,
  providerUid,
  supplier,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      field: 'product_name',
      headerName: t('attributes.purchase_order.products_name'),
      flex: 5, // 40% width
      sortable: false,
    },
    {
      field: 'qty',
      headerName: t('attributes.purchase_order.qty'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'unit_price',
      headerName: t('attributes.purchase_order.unit_price'),
      flex: 2, // 10% width
      sortable: false,
    },

    {
      field: 'amount',
      headerName: t('attributes.amount'),
      flex: 2,
      sortable: false,
    },
  ];

  const rows =
    invoiceBilldata?.items?.map((item) => ({
      product_name: item.product_name,
      unit_price: item.unit_price,
      qty: item.quantity_to_bill,
      amount: item.unit_price * item.quantity_to_bill,
      ...item,
    })) || [];

  const totalAmount = rows.reduce((sum, row) => sum + row.amount, 0);
  const totalTaxAmount = rows.reduce((sum, row) => sum + row.tax, 0);

  return isLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <CustomCircularLoader />
    </Box>
  ) : (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">
            {t('attributes.purchase_order.bill_details')}
          </Typography>
        </Box>
        <Divider />
        <DetailContainer>
          <Box>
            <Typography>Invoice Number</Typography>
            <Typography variant="body2">
              {invoiceBilldata?.invoice_number}
            </Typography>
          </Box>
          <Box>
            <Typography>Invoice Date</Typography>
            <Typography variant="body2">
              {formattedDate(invoiceBilldata?.invoice_date)}
            </Typography>
          </Box>
          <Box>
            <Typography>Status</Typography>
            <Typography variant="body2">
              {formatStatus(invoiceBilldata?.status)}
            </Typography>
          </Box>
        </DetailContainer>
      </CustomCard>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">
            {t('attributes.purchase_order.accounting_integration')}
          </Typography>
        </Box>
        <Divider />
        <DetailContainer>
          <Box>
            <Typography>Invoice Number</Typography>
            <Typography variant="body2">
              {invoiceBilldata?.invoice_number}
            </Typography>
          </Box>
          <Box>
            <Typography>Invoice Date</Typography>
            <Typography variant="body2">
              {formattedDate(invoiceBilldata?.invoice_date)}
            </Typography>
          </Box>
          <Box>
            <Typography>Status</Typography>
            <Typography variant="body2">
              {formatStatus(invoiceBilldata?.status)}
            </Typography>
          </Box>
        </DetailContainer>
        {supplier?.billing_card_details?.account_partner_clickable_link && (
          <Box sx={{ display: 'flex', padding: '16px' }}>
            <Typography variant="body2" sx={{ display: 'flex', gap: '8px' }}>
              Partner UID:{' '}
              <Link
                style={{
                  display: 'flex',
                  gap: '4px',
                  color: PRIMARY,
                }}
                to={
                  supplier?.billing_card_details?.account_partner_clickable_link
                }
                target="_blank"
              >
                {supplier?.billing_card_details?.account_partner_uuid}
                <OpenInNewOutlinedIcon />
              </Link>
            </Typography>
          </Box>
        )}
      </CustomCard>
      <CustomCard>
        <CustomGridTable
          columns={columns}
          rows={rows}
          total={rows.length}
          checkboxSelection={false}
          paginationRequired={false}
          disableColumnMenu={true}
          noData={<NoRecordFound />}
          filterHeight={590}
          isLoading={false}
          isTableView={true}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            py: 2,
            pr: 1,
            gap: 2,
            backgroundColor: 'rgba(235, 235, 235, 0.2)',
            // border: `1px solid ${DIVIDER_COLOR}`,
          }}
        >
          <Typography variant="body1">
            {t('common.capital.subtotal')}:
          </Typography>
          <Typography variant="body2">{totalAmount?.toFixed(2)}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            py: 2,
            pr: 1,
            gap: 3,
            backgroundColor: 'rgba(235, 235, 235, 0.2)',
            border: `1px solid ${DIVIDER_COLOR}`,
          }}
        >
          <Typography variant="body1">
            {TAX_NAME} {calculateTaxPercentage(totalAmount, totalTaxAmount)}%:
          </Typography>
          <Typography variant="body2">{totalTaxAmount?.toFixed(2)}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            py: 2,
            pr: 1,
            gap: 2,
            backgroundColor: 'rgba(235, 235, 235, 0.2)',
            border: `1px solid ${DIVIDER_COLOR}`,
          }}
        >
          <Typography variant="body1">
            {t('attributes.purchase_order.total_inc_gst')}:
          </Typography>
          <Typography variant="body1">
            {calculateTotal({
              total: totalAmount,
              taxAmount: totalTaxAmount,
              type: 'total',
            })}
          </Typography>
        </Box>
      </CustomCard>
    </FormWrapper>
  );
};
