import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { CONTACT } from '../actionPrefix';
import { axiosInstanceAuthAndFilter } from '../axios';

const {
  CREATE_CONTACT,
  EDIT_CONTACT,
  GET_CONTACT_DROPDOWN_LIST,
  GET_CONTACT_LIST,
  UPDATE_CONTACT,
} = CONTACT;

export const getContactList = createAsyncThunk(
  GET_CONTACT_LIST,
  async (
    {
      page,
      search,
      size,
      active,
      country,
      state,
      city,
      order,
      orderBy,
      fromDate,
      toDate,
      list_column_names,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (size) params.append('items_per_page', size);
    params.append('active', active);
    if (state) params.append('state', state);
    if (city) params.append('city', city);
    if (order) params.append('sort_order', order);
    if (orderBy) params.append('sort_by', orderBy);
    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosInstanceAuthAndFilter.get(
        `${APIs.CONTACT.GET_CONTACTS}?${params.toString()}`
      );

      return { ...response.data, active };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateContact = createAsyncThunk(
  UPDATE_CONTACT,
  async ({ uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceAuthAndFilter.patch(
        APIs.CONTACT.UPDATE_CONTACT.replace('{uuid}', uuid),
        data
      );

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const createContact = createAsyncThunk(
  CREATE_CONTACT,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceAuthAndFilter.post(
        APIs.CONTACT.CREATE_CONTACT,
        data
      );

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const editContact = createAsyncThunk(
  EDIT_CONTACT,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceAuthAndFilter.get(
        APIs.CONTACT.GET_CONTACT_BY_ID.replace('{uuid}', uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getContactDropdownList = createAsyncThunk(
  GET_CONTACT_DROPDOWN_LIST,
  async (req, { rejectWithValue }) => {
    const params = new URLSearchParams();

    params.append('limit', -1);
    try {
      const response = await axiosInstanceAuthAndFilter.get(
        `${APIs.CONTACT.GET_CONTACTS}?${params.toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
