import { DO_AND_CHARGE, PERIODIC } from './Constants';

export const DROPDOWN = {
  CONTRACTS: {
    CONTRACT_TYPE: [
      {
        invoiceType: 'M',
        renderComponent: 0,
        value: PERIODIC,
        label: 'Periodic',
      },
      {
        renderComponent: 1,
        value: DO_AND_CHARGE,
        label: 'Do & Bill',
      },
    ],
    CPI_INCREASE: [
      { percentage: '0', value: 'n_a', label: 'N/A' },
      { percentage: '4', value: 'not_known', label: 'Not Known' },
      { percentage: null, value: 'other', label: 'Other' },
    ],
  },
  DURATION: [
    {
      label: '6 Months',
      value: 6,
    },
    {
      label: '12 Months',
      value: 12,
    },
    {
      label: '18 Months',
      value: 18,
    },
    {
      label: '24 Months',
      value: 24,
    },
    {
      label: '30 Months',
      value: 30,
    },
    {
      label: '36 Months',
      value: 36,
    },
  ],
  INVOICE_FREQUENCY: [
    {
      label: 'Monthly',
      value: 'M',
    },
    {
      label: 'Quarterly',
      value: 'Q',
    },
    {
      label: '6 Monthly',
      value: '6M',
    },
    {
      label: 'Yearly',
      value: 'Y',
    },
  ],
  RENEW_TYPE: [
    {
      label: 'Automatically',
      value: 'automatically',
    },
    {
      label: 'Manually',
      value: 'manually',
    },
  ],
};
