import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, Divider, Modal, Typography } from '@mui/material';

import { HoverText, ImageWrapper } from '../../assets/commonStyled';
import { getSingleDefect } from '../../store/defects/api';
import { formatStatus } from '../../utils';
import CommonCarouselView from '../CommonComponents/CommonCarouselView';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import ImageView, { imageModalView } from '../CommonComponents/ImageView';

const KeyValueComponent = ({ name, value, minWidth = '180px' }) => (
  <Box>
    <Typography variant="body1" minWidth={minWidth}>
      {`${name}:`}
    </Typography>
    <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
      {value}
    </Typography>
  </Box>
);

const DefectHistoryView = ({ defectUUID }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [defect, setDefect] = useState(null);

  const [imgSrc, setImgSrc] = useState(new Map());
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (defectUUID) {
      dispatch(getSingleDefect({ defect_id: defectUUID }))
        .then((res) => setDefect(res?.payload?.data?.[0]))
        .finally(() => setLoading(false));
    }
  }, [defectUUID]);

  const openImage = () => setOpen(true);

  const addImageToMap = (uuid, url) => {
    setImgSrc((prevMap) => {
      const newMap = new Map(prevMap);

      newMap.set(uuid, url);

      return newMap;
    });
  };

  // Photo Images
  useEffect(() => {
    if (imgSrc?.size === defect?.documents?.length) {
      const orderedImages = defect?.documents?.map((doc) =>
        imgSrc.get(doc?.document_uuid)
      );

      setImgSrc(orderedImages);
    }
  }, [defect, imgSrc]);

  return loading ? (
    <CustomCircularLoader />
  ) : (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
        <Card sx={{ boxShadow: 'none !important' }}>
          <Box sx={{ padding: '12px' }}>
            <Typography variant="body1">{`${t('attributes.work_order.defect')} ${t('attributes.supplier.details')}`}</Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              padding: '12px',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '16px',
              width: '100%',
            }}
          >
            <KeyValueComponent
              name={`${t('attributes.work_order.defect')} ${t('attributes.work_order.severity')}`}
              value={formatStatus(defect?.severity) || '-'}
            />
            <KeyValueComponent
              name={`${t('attributes.work_order.defect')} ${t('attributes.productCatalogue.description')}`}
              value={defect?.defect_description || '-'}
            />
            <KeyValueComponent
              name={`${t('attributes.work_order.defect')} ${t('attributes.work_order.resolution')}`}
              value={defect?.short_resolution || '-'}
            />
          </Box>
        </Card>
        <Card sx={{ boxShadow: 'none !important' }}>
          <Box sx={{ padding: '12px' }}>
            <Typography variant="body1">
              {t('attributes.work_order.photos')}
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              columnGap: '16px',
              padding: '16px',
              flexWrap: 'wrap',
            }}
          >
            {defect?.documents?.length ? (
              <CommonCarouselView
                totalItems={defect?.documents?.length}
                centerMode={true}
              >
                {defect?.documents?.map((doc, index) => (
                  <ImageWrapper key={index}>
                    <ImageView
                      uuid={doc?.document_uuid}
                      addImageToMap={addImageToMap}
                    />
                    <HoverText
                      className="hoverText"
                      onClick={() => {
                        setCurrentIndex(index);
                        openImage();
                      }}
                    >
                      <Typography variant="body1">
                        {t('attributes.view')}
                      </Typography>
                    </HoverText>
                  </ImageWrapper>
                ))}
              </CommonCarouselView>
            ) : (
              <>No Document Uploaded</>
            )}
          </Box>
        </Card>
      </Box>
      {open && (
        <Modal
          open={open}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#000000D9',
          }}
        >
          <>
            <CloseIcon
              style={{
                fill: 'white',
                height: '45px',
                width: '45px',
                position: 'absolute',
                top: '10px',
                right: '10px',
              }}
              onClick={() => setOpen(false)}
            />
            <CommonCarouselView
              widthInPercentage={100}
              totalItems={defect?.documents?.length}
              showThumbs={true}
              arrowSize="16px"
              arrowWrapperSize="40px"
              arrowLeft="40px"
              arrowRight="40px"
              centerMode={true}
              isModal={true}
              currentIndex={currentIndex}
            >
              {imgSrc?.map((img, index) => (
                <React.Fragment key={index}>
                  {imageModalView(img)}
                </React.Fragment>
              ))}
            </CommonCarouselView>
          </>
        </Modal>
      )}
    </>
  );
};

export default DefectHistoryView;
