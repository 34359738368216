import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { ZONES } from '../actionPrefix';
import { axiosPropertyUrl } from '../axios';

// Get Zones List API
export const getZonesDropdownList = createAsyncThunk(
  ZONES.GET_ZONES_DROPDOWN_LIST,
  async (req, { rejectWithValue }) => {
    const request = {
      ...req,
      post_code: 'all',
      unique_zone: true,
      unique_local_government_area: false,
    };

    try {
      const response = await axiosPropertyUrl.post(
        APIs.ZONES.GET_ZONES_DROPDOWN_LIST,
        request
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createZone = createAsyncThunk(
  ZONES.CREATE_ZONE,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.post(APIs.ZONES.CREATE_ZONE, req);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getZone = createAsyncThunk(
  ZONES.GET_ZONE,
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.ZONES.GET_ZONE.replace('{zone_uuid}', uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateZone = createAsyncThunk(
  ZONES.UPDATE_ZONE,
  async ({ uuid, req }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.patch(
        APIs.ZONES.UPDATE_ZONE.replace('{zone_uuid}', uuid),
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getZonesList = createAsyncThunk(
  ZONES.GET_ZONES_LIST,
  async (
    { page, search, size, order, orderBy, is_active, limit },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Build the query string dynamically
    if (page) params.append('page', page);
    if (size) params.append('size', size);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (search) params.append('search_value', search);
    if (is_active) params.append('is_active', is_active);
    if (limit) params.append('limit', limit);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.ZONES.GET_ZONES_LIST}?${params}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
