import { createSlice } from '@reduxjs/toolkit';

import {
  createBillingContract,
  getBillingContractById,
  getBillingContractList,
  updateBillingContractById,
  updateBillingContractDetails,
} from './api';

const initialBillingContractListData = {
  isLoading: false,
  error: null,
  billingContractActiveList: [],
  billingContractInactiveList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const initialCreateBillingContractData = {
  isLoading: null,
  error: null,
  data: null,
};

const initialGetBillingContractById = {
  isLoading: false,
  error: null,
  data: null,
};

const initialUpdateBillingContractDetails = {
  isLoading: false,
  error: null,
  data: null,
};

const initialUpdateBillingContractById = {
  isLoading: false,
  error: null,
  data: null,
};

const billingContractSlice = createSlice({
  name: 'billingContract',
  initialState: {
    getList: { ...initialBillingContractListData },
    createBillingContract: { ...initialCreateBillingContractData },
    getBillingContractById: { ...initialGetBillingContractById },
    updateBillingContractById: { ...initialUpdateBillingContractById },
    updateBillingContractDetails: { ...initialUpdateBillingContractDetails },
  },
  reducers: {
    resetBillingContractList(state) {
      state.getList = { ...initialBillingContractListData };
    },
    resetCreateBillingContract(state) {
      state.createBillingContract = { ...initialCreateBillingContractData };
    },
    resetGetBillingContractById(state) {
      state.getBillingContractById = { ...initialGetBillingContractById };
    },
    resetUpdateBillingContractById(state) {
      state.updateBillingContractById = {
        ...initialUpdateBillingContractById,
      };
    },
    resetUpdateBillingContractDetails(state) {
      state.updateBillingContractDetails = {
        ...initialUpdateBillingContractDetails,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // List Contract
      .addCase(getBillingContractList.pending, (state) => {
        state.getList.isLoading = true;
      })
      .addCase(getBillingContractList.fulfilled, (state, action) => {
        const { pagination, data, active } = action.payload;

        state.getList.isLoading = false;
        if (active === undefined || active) {
          state.getList.billingContractActiveList = data;
        } else {
          state.getList.billingContractInactiveList = data;
        }
        state.getList.total = pagination?.total_items;
        state.getList.totalCount = pagination?.total_count;
      })
      .addCase(getBillingContractList.rejected, (state, action) => {
        state.getList.isLoading = false;
        state.getList.error = action.payload;
      })
      // Create Contract
      .addCase(createBillingContract.pending, (state) => {
        state.createBillingContract.error = null;
        state.createBillingContract.isLoading = true;
        state.updateBillingContractDetails.isLoading = true;
      })
      .addCase(createBillingContract.fulfilled, (state, action) => {
        state.createBillingContract.isLoading = false;
        state.createBillingContract.data = action.payload;
      })
      .addCase(createBillingContract.rejected, (state, action) => {
        state.createBillingContract.isLoading = false;
        state.createBillingContract.error = action.payload;
        state.updateBillingContractDetails.isLoading = false;
      })
      // Get Contract By Id
      .addCase(getBillingContractById.pending, (state) => {
        state.getBillingContractById.error = null;
        state.getBillingContractById.isLoading = true;
      })
      .addCase(getBillingContractById.fulfilled, (state, action) => {
        state.getBillingContractById.isLoading = false;
        state.getBillingContractById.data = action?.payload?.data?.[0] || null;
      })
      .addCase(getBillingContractById.rejected, (state, action) => {
        state.getBillingContractById.isLoading = false;
        state.getBillingContractById.error = action.payload;
      })
      // Update Contract details
      .addCase(updateBillingContractById.pending, (state) => {
        state.updateBillingContractById.error = null;
        state.updateBillingContractById.isLoading = true;
        state.updateBillingContractDetails.isLoading = true;
      })
      .addCase(updateBillingContractById.fulfilled, (state, action) => {
        state.updateBillingContractById.isLoading = false;
        state.updateBillingContractById.data = action?.payload;
      })
      .addCase(updateBillingContractById.rejected, (state, action) => {
        state.updateBillingContractById.isLoading = false;
        state.updateBillingContractById.error = action.payload;
        state.updateBillingContractDetails.isLoading = false;
      })
      // Update Contract details/matrix
      .addCase(updateBillingContractDetails.pending, (state) => {
        state.updateBillingContractDetails.error = null;
        state.updateBillingContractDetails.isLoading = true;
      })
      .addCase(updateBillingContractDetails.fulfilled, (state, action) => {
        state.updateBillingContractDetails.isLoading = false;
        state.updateBillingContractDetails.data = action?.payload;
      })
      .addCase(updateBillingContractDetails.rejected, (state, action) => {
        state.updateBillingContractDetails.isLoading = false;
        state.updateBillingContractDetails.error = action.payload;
      });
  },
});

export const {
  resetBillingContractList,
  resetCreateBillingContract,
  resetGetBillingContractById,
  resetUpdateBillingContractById,
  resetUpdateBillingContractDetails,
} = billingContractSlice.actions;

export default billingContractSlice.reducer;
