import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box, Stack } from '@mui/material';
import { debounce } from 'lodash';

import { FlexStart } from '../../assets/commonStyled';
import { DIVIDER_COLOR } from '../../constants/Colors';
import { exportToCSV } from '../../constants/common';
import {
  CUSTOMER_DEFECT_STATUS,
  DEFECT_SEVERITY,
} from '../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  COLUMNS_TO_MAP,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import { snackbarToggle } from '../../store/CommonReducer';
import { getPropertyList } from '../../store/property/api';
import {
  getCustomerDefectList,
  getCustomerList,
} from '../../store/users/customer/api';
import { updateDefect } from '../../store/workOrder/api';
import { resetUpdateDefect } from '../../store/workOrder/reducer';
import {
  checkIsFiltersApplied,
  formatStatus,
  formattedDate,
  showHideTopbarLoader,
} from '../../utils';
import { Edit, View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import { FilterSection } from '../CommonComponents/FilterComponent';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import DefectQuotesEdit from '../WorkOrder/DefectsQuotes/DefectsQuotesEdit';
import ViewDefect from './ViewDefect';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

//default filters
const defaultFilters = {
  property: null,
  status: null,
  severity: null,
  customer: null,
  dateRange: null,
};

const DefectList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const queryStatus = searchParams.get('status');

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [perPageData, setPerPageData] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [selectedRows, setSelectedRows] = useState(null);
  const [filters, setFilters] = useState(defaultFilters);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [defects, setDefects] = useState(null);
  const [selectedDefect, setSelectedDefect] = useState(null);

  const [customerOptions, setCustomerOptions] = useState(null);
  const [customerLoading, setCustomerLoading] = useState(false);
  const { control, reset, handleSubmit } = useForm();
  const {
    data: updateDefectData,
    isLoading: updateDefectLoading,
    error: updateDefectError,
  } = useSelector((state) => state.workOrder.updateDefect);

  const { propertyDropdownLoading, propertyDropdownData } = getDropdownListHook(
    {
      reducerName: 'property',
      dropdownListName: 'propertyList',
      labelName: 'property_name',
      valueName: 'uuid',
      loading: 'loading',
    }
  );

  const noData = (
    <NewNoDataPage
      icon={<ReportGmailerrorredIcon />}
      title={`${t('attributes.defects.defects')} `}
      customText={t('attributes.defects.no_defect')}
      filterHeight={filterHeight(isFilterComponentVisible)}
      onlyTitle={true}
    />
  );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'id',
        headerName: t('attributes.defects.defectsId'),
        flex: 1,
        sortable: true,
      },
      // {
      //   field: 'title',
      //   headerName: t('attributes.defects.defectTitle'),
      //   flex: 1,
      //   sortable: false,
      // },
      {
        field: 'severity',
        headerName: t('attributes.defects.severity'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row.severity ? formatStatus(row?.severity) : '-',
      },
      {
        field: 'customer_name',
        headerName: `${t('attributes.customer.customer')}  ${t('attributes.name')}`,
        flex: 1,
        sortable: true,
      },
      {
        field: 'property_name',
        headerName: t('attributes.defects.propertyName'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'asset',
        headerName: `${t('attributes.defects.asset')} ${t('attributes.name')}`,
        flex: 1,
        sortable: false,
      },
      {
        field: 'raised_by',
        headerName: t('attributes.defects.raisedBy'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'description',
        headerName: t('attributes.defects.description'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'created_at',
        headerName: t('attributes.defects.identifiedDate'),
        flex: 1,
        sortable: true,
        renderCell: ({ row }) => formattedDate(row?.created_at),
      },

      {
        field: 'status',
        headerName: t('attributes.defects.status'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (row?.status ? formatStatus(row.status) : '-'),
      },

      {
        field: 'edit',
        headerName: t('attributes.defects.actions'),
        flex: 0.5,
        sortable: false,
        renderCell: ({ row }) => (
          <Stack direction="row" spacing={1}>
            <View
              onClick={() => {
                setOpen('view');
                setSelectedDefect(row);
              }}
            />
            <Edit
              onClick={() => {
                setOpen('edit');
                setSelectedDefect(row);
              }}
            />
          </Stack>
        ),
      },
    ];

    return baseColumns;
  }, []);

  const rows = defects?.map((defect, index) => ({
    ...defect,
    id: `D-${defect?.defect_id}` || index + 1,
    severity: defect?.severity,
    property_name: defect?.property?.property_name || '-',
    customer_name: defect?.property?.customer || '-',
    asset: defect?.asset?.label || '-',
    raised_by: defect?.work_order?.technician?.technician_name || '-',
    description: defect?.defect_description,
    title: defect?.defect_title || '-',
    status:
      CUSTOMER_DEFECT_STATUS.find(
        (status) => status.value === defect?.latest_status
      )?.label || '-',
    date: defect?.created_at ? formattedDate(defect?.created_at) : '',
  }));

  const getDefects = useCallback(() => {
    setSelectedRows(null);
    setIsDataLoading(true);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    dispatch(
      getCustomerDefectList({
        perPageData: perPageData,
        page: currentPage,
        order: order,
        orderBy: orderBy,
        property_uuid: filters?.property?.value,
        customer_uuid: [filters?.customer?.value],
        severity: filters?.severity?.value,
        status: filters?.status?.map((status) => status.value)?.toString(),
        fromDate: filters?.dateRange?.fromDate,
        toDate: filters?.dateRange?.toDate,
        search: searchText,
        list_column_names: visibleFieldsString,
      })
    )
      .then((res) => {
        setDefects(res?.payload?.data?.[0] || []);
        setCurrentPage(res?.payload?.pagination?.current_page || 1);
        setPerPageData(res?.payload?.pagination?.items_per_page || 10);
        setTotal(res?.payload?.pagination?.total_count || 0);
        setTotalItems(res?.payload?.pagination?.total_items || 0);
      })
      .finally(() => {
        setIsDataLoading(false);
        setIsInitialLoading(false);
      });
  }, [
    perPageData,
    currentPage,
    order,
    orderBy,
    columns,
    columnVisibilityModel,
    filters,
    searchText,
    refresh,
  ]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getDefects, 500), [
    getDefects,
  ]);

  useEffect(() => {
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  // For property dropdown
  useEffect(() => {
    const req = {
      limit: -1,
      customerUuid: filters?.customer?.value,
    };

    dispatch(getPropertyList(req));
  }, []);

  useEffect(() => {
    setCustomerLoading(true);
    dispatch(getCustomerList({ limit: -1 }))
      .then((res) => {
        const options = res?.payload?.data?.map((c) => ({
          label: c?.customer_name,
          value: c?.uuid,
        }));

        setCustomerOptions(options);
      })
      .finally(() => setCustomerLoading(false));
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData]);

  const resetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  useEffect(() => {
    if (queryStatus) {
      setFilters((pre) => ({
        ...pre,
        severity: DEFECT_SEVERITY?.find((s) => s.value === queryStatus),
      }));
      setIsFilterComponentVisible(true);
    }
  }, [queryStatus]);

  useEffect(() => {
    showHideTopbarLoader(updateDefectLoading, dispatch);
    if (updateDefectLoading !== null && !updateDefectLoading) {
      if (updateDefectData?.data?.length > 0) {
        setOpen(false);
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: updateDefectData?.message,
          })
        );
      } else if (updateDefectError) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: updateDefectError.message,
          })
        );
      }
      getDefects();
      dispatch(resetUpdateDefect());
    }
  }, [updateDefectData, updateDefectLoading]);

  const onSubmit = (data) => {
    const req = {
      severity: data?.severity?.value,
      defect_title: selectedDefect?.defect_title,
      defect_description: data?.defect_description,
      short_resolution: data?.short_resolution,
      equipment_type_id: selectedDefect?.equipment_type_id,
      equipment_class_id: selectedDefect?.equipment_class_id,
      latest_status: selectedDefect?.latest_status,
    };

    dispatch(
      updateDefect({
        woid: selectedDefect?.work_order_uuid,
        asset_id: selectedDefect?.asset_uuid,
        defect_id: selectedDefect?.uuid,
        data: req,
      })
    );
  };

  useEffect(
    () => () => {
      dispatch(resetUpdateDefect());
    },
    []
  );

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const renderedComponent = (
    <>
      <Box sx={{ padding: '8px' }}>
        {/* <FlexEnd>
          <ResetFilterButton
            onClick={resetFilter}
            disabled={
              !(
                searchText ||
                filters?.dateRange?.fromDate ||
                filters?.dateRange?.toDate ||
                filters?.severity ||
                filters?.status?.length > 0 ||
                filters?.property ||
                filters?.customer
              )
            }
          />
          <FilterButton
            onClick={() =>
              setIsFilterComponentVisible(!isFilterComponentVisible)
            }
            isActive={isFilterComponentVisible}
            disabled={checkIsFiltersApplied({ ...filters })}
          />
          <CustomSearch
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </FlexEnd> */}
        <FilterSection
          onFilterBtnClick={() =>
            setIsFilterComponentVisible(!isFilterComponentVisible)
          }
          searchText={searchText}
          isRefresh={true}
          onRefreshFilter={() => setRefresh(!refresh)}
          onResetFilter={resetFilter}
          // After API impl need to include debounce search
          onSearchChange={(e) => setSearchText(e.target.value)}
          isResetButtonVisible={
            searchText ||
            filters?.dateRange?.fromDate ||
            filters?.dateRange?.toDate ||
            filters?.severity ||
            filters?.status?.length > 0 ||
            filters?.property ||
            filters?.customer
          }
          isActive={isFilterComponentVisible}
          isFilterDisable={checkIsFiltersApplied({
            ...filters,
          })}
        />
        {isFilterComponentVisible && (
          <FlexStart
            style={{
              background: DIVIDER_COLOR,
              padding: '8px',
              margin: '8px 0',
            }}
          >
            <Autocomplete
              placeholder={t('attributes.customer.customer')}
              options={customerOptions}
              width="190px"
              value={filters?.customer}
              isLoadingData={customerLoading}
              onChange={(e, newVal) => {
                if (newVal || newVal === null) {
                  setFilters((pre) => ({ ...pre, customer: newVal }));
                }
              }}
            />
            <Autocomplete
              placeholder={t('attributes.property.property')}
              options={propertyDropdownData}
              isLoadingData={propertyDropdownLoading}
              width="190px"
              value={filters?.property}
              onChange={(e, newVal) => {
                if (newVal || newVal === null) {
                  setFilters((pre) => ({
                    ...pre,
                    property: newVal,
                  }));
                }
              }}
            />
            <Autocomplete
              placeholder={t('attributes.defects.severity')}
              options={DEFECT_SEVERITY}
              width="190px"
              value={filters?.severity}
              onChange={(e, newVal) => {
                if (newVal || newVal === null) {
                  setFilters((pre) => ({ ...pre, severity: newVal }));
                }
              }}
            />

            <Autocomplete
              multiple
              handleMultipleFilter={true}
              placeholder={t('attributes.defects.status')}
              options={CUSTOMER_DEFECT_STATUS}
              width="190px"
              value={filters?.status}
              onChange={(e, newVal) => {
                if (newVal || newVal === null) {
                  setFilters((pre) => ({ ...pre, status: newVal }));
                }
              }}
            />
            <CustomDateRangePicker
              onOkClick={(val) => {
                setFilters((pre) => ({
                  ...pre,
                  dateRange: { fromDate: val[0], toDate: val[1] },
                }));
              }}
              onClear={() => {
                setFilters((pre) => ({
                  ...pre,
                  dateRange: null,
                }));
              }}
              fromDate={filters?.dateRange?.fromDate}
              toDate={filters?.dateRange?.toDate}
            />
          </FlexStart>
        )}
      </Box>
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={totalItems}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        onRowSelectionModelChange={handleSelectionChange}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  return (
    <>
      <StyledMainWrapper
        title={t('attributes.defects.defects')}
        btn={
          <CustomButton
            text={t('attributes.export')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<OpenInNewIcon />}
            onClick={() => {
              if (!selectedRows || selectedRows?.length === 0) {
                dispatch(
                  snackbarToggle({
                    isOpen: true,
                    isErrorMsg: true,
                    msg: t('message.common.noRecordExportMessage'),
                  })
                );
                setSelectedRows(null);
              } else {
                exportToCSV(
                  selectedRows,
                  COLUMNS_TO_EXPORT.SUPER_ADMIN_DEFECTS,
                  EXPORT_FILE_NAMES.SUPER_ADMIN_DEFECTS,
                  COLUMNS_TO_MAP.DEFECTS
                );
              }
            }}
          />
        }
      >
        {!isDataLoading && total === 0 && noData}
        {isInitialLoading ? (
          <Box
            sx={{
              width: '100%',
              mt: 2,
              minHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
              maxHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CustomCircularLoader />
          </Box>
        ) : (
          !isInitialLoading &&
          total > 0 && (
            <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
              {renderedComponent}
            </Box>
          )
        )}
      </StyledMainWrapper>
      {open === 'view' && (
        <SwipeableDrawer
          open={open === 'view'}
          title={`D-${selectedDefect?.defect_id}`}
          onClose={() => {
            setOpen(false);
          }}
          width={500}
        >
          <ViewDefect defectUUID={selectedDefect?.uuid} />
        </SwipeableDrawer>
      )}
      {open === 'edit' && (
        <SwipeableDrawer
          open={open === 'edit'}
          title={`${t('attributes.edit')} ${t('attributes.defects.defects')}`}
          onClose={() => setOpen(false)}
          footerButton={
            <CustomButton
              text={t('attributes.save')}
              disabled={updateDefectLoading}
              startIcon={<SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
            />
          }
        >
          <DefectQuotesEdit
            control={control}
            selectedDefect={selectedDefect}
            reset={reset}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default DefectList;
