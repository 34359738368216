import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../assets/commonStyled';
import { Validation } from '../../constants/FieldValidationMsg';
import { EMAIL_REGEX } from '../../constants/Regex';
import { getEmailSettings } from '../../store/template/api';
import CustomTextField from '../CommonComponents/CustomTextField';

const EmailSetting = ({
  control,
  trigger,
  setValue,
  reset,
  setIsEmailSettings,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmailSettings()).then((res) => {
      if (res?.payload?.status !== 404) {
        setIsEmailSettings(true);
        reset({
          fromName: res?.payload?.data?.[0]?.default_from_name,
          toEmail: res?.payload?.data?.[0]?.default_reply_to,
        });
      }
    });
  }, []);

  const clearHandler = (name) => setValue(name, '');

  return (
    <FormWrapper>
      <CustomCard>
        <FormFieldWrapper>
          {/* <Controller
            name="fromName"
            control={control}
            rules={{
              required: `${t('attributes.company.company')} ${t('attributes.name')} ${Validation.general.required}`,
              minLength: {
                value: 2,
                message: Validation.company.companyName,
              },
              maxLength: {
                value: 255,
                message: Validation.company.companyName,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={`${t('common.defaultFrom')} ${t('attributes.name')}`}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('fromName');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          /> */}
          <Controller
            name="toEmail"
            control={control}
            rules={{
              required: `${t('attributes.usersManagement.email')} ${Validation.general.required}`,
              pattern: {
                value: EMAIL_REGEX,
                message: Validation.general.validEmail,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={`${t('common.defaultTo')} ${t('attributes.email')}`}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('toEmail');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
    </FormWrapper>
  );
};

export default EmailSetting;
