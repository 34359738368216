import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { ASSETS, PROPERTIES } from '../actionPrefix';
import { axiosPropertyUrl } from '../axios';

// get equipment type list
export const getEquipmentTypesDropdown = createAsyncThunk(
  PROPERTIES.GET_EQUIPEMENT_TYPE,
  async ({ property_uuid, standards_id } = {}, { rejectWithValue }) => {
    const params = new URLSearchParams();

    if (property_uuid) params.append('property_uuid', property_uuid);
    if (standards_id) params.append('standards_id', standards_id);
    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.PROPERTIES.GET_EQUIPEMENT_TYPE}?${params.toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get equipment class list
export const getEquipmentClassDropdown = createAsyncThunk(
  ASSETS.GET_EQUIPEMENT_CLASS,
  async ({ equipment_type_id, is_active = true }, { rejectWithValue }) => {
    const params = new URLSearchParams();

    // Add params conditionally
    params.append('is_active', is_active);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.ASSETS.EQUIPMENT_CLASS.replace(
          '{equipment_type_id}',
          equipment_type_id
        )}?${params.toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get category levels list
export const getCategoryLevelsDropdown = createAsyncThunk(
  ASSETS.GET_CATEGORY_LEVELS,
  async ({ ecid, parent_cid, current_category }, { rejectWithValue }) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (ecid) params.append('ECID', ecid);
    if (parent_cid) params.append('Parent_cid', parent_cid);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.ASSETS.CATEGORY_LEVELS}?${params.toString()}`
      );

      return { ...response.data, request: { current_category } };
    } catch (error) {
      return rejectWithValue({
        error,
        request: { current_category },
      });
    }
  }
);
