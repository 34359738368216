import { configureStore } from '@reduxjs/toolkit';

// Internal
import accountManager from './accountManager/reducer';
import billing from './billing/reducer';
import category from './category/reducer';
import city from './city/reducer';
import CommonReducer from './CommonReducer';
import company from './company/reducer';
import complianceStatus from './complianceStatus/reducer';
import contact from './contacts/reducer';
import document from './document/reducer';
import equipmentTypes from './equipmentTypes/reducer';
import filters from './filters/reducer';
import floorPlan from './floorPlan/reducer';
import frequency from './frequency/reducer';
import googleAPIs from './google/reducer';
import inspectionForm from './inspectionForm/reducers';
import accountConfiguration from './integrations/reducer';
import invoices from './invoices/reducer';
import officeManagement from './officeManagement/reducer';
import officeStaff from './officeStaff/reducer';
import productCatalogue from './productCatalogue/reducer';
import property from './property/reducer';
import purchaseOrders from './purchaseOrders/reducer';
import quotes from './quotes/reducer';
import routine from './routine/reducer';
import routineServiceFrequencies from './routineServiceFrequencies/reducer';
import scheduleBoard from './scheduleBoard/reducer';
import serviceAreas from './serviceAreas/reducer';
import skills from './skills/reducer';
import standards from './standards/reducer';
import superAdmin from './superAdmin/reducer';
import supplier from './supplier/reducer';
import technician from './technician/reducer';
import template from './template/reducer';
import timeSheet from './timesheet/reducer';
import userManagement from './userManagement/reducer';
import billingContract from './users/billingContract/reducer';
import customer from './users/customer/reducer';
import workOrder from './workOrder/reducer';
import zones from './zones/reducer';

const store = configureStore({
  reducer: {
    company: company,
    superAdmin: superAdmin,
    common: CommonReducer,
    property: property,
    customer: customer,
    technician: technician,
    category: category,
    skills: skills,
    zones: zones,
    accountManager: accountManager,
    serviceAreas: serviceAreas,
    officeStaff: officeStaff,
    quotes: quotes,
    workOrder: workOrder,
    invoices: invoices,
    complianceStatus: complianceStatus,
    supplier: supplier,
    productCatalogue: productCatalogue,
    equipmentTypes: equipmentTypes,
    scheduleBoard: scheduleBoard,
    googleAPIs: googleAPIs,
    purchaseOrders: purchaseOrders,
    timeSheet: timeSheet,
    billingContract: billingContract,
    standards: standards,
    routine: routine,
    frequency: frequency,
    billing: billing,
    document: document,
    userManagement: userManagement,
    officeManagement: officeManagement,
    accountConfiguration: accountConfiguration,
    routineServiceFrequencies: routineServiceFrequencies,
    inspectionForm: inspectionForm,
    floorPlan: floorPlan,
    template: template,
    city: city,
    contact: contact,
    filters: filters,
  },
});

export default store;
