import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Box } from '@mui/material';

import { DIVIDER_COLOR } from '../../../constants/Colors';
import { ROUTE_NAVIGATE_CONST } from '../../../constants/Constants';
import { getUserRole } from '../../../hoc/Permission';
import { getDefectQuotesDetail } from '../../../store/workOrder/api';
import { getRedirectURL } from '../../../utils';
import MainWrapper from '../../CommonComponents/MainWrapper';
import ServiceQuotesDetail from './ServiceQuotesDetail';

const ServiceQuotesEdit = (props) => {
  const navigate = useNavigate();
  const outletProps = useOutletContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [id, setId] = useState(null);
  const { nextStepTitle, setRefresh, woId } = { ...props, ...outletProps };

  useEffect(() => {
    if (!nextStepTitle && woId) {
      dispatch(
        getDefectQuotesDetail({
          quote_id: woId,
        })
      ).then((res) => setId(`Q-${res?.payload?.data?.[0]?.id}`));
    }
  }, [nextStepTitle]);

  return (
    <>
      <Box sx={{ bgcolor: DIVIDER_COLOR, paddingBottom: '16px' }}>
        <MainWrapper
          defaultPadding="0px"
          title={
            nextStepTitle?.title || t('attributes.service_quotes.service_quote')
          }
          // variant="body1"
          isStep={true}
          step={nextStepTitle?.step || id}
          handleDefaultStep={() => {
            navigate(
              getRedirectURL()[getUserRole()][
                ROUTE_NAVIGATE_CONST.SERVICE_QUOTES
              ]
            );
          }}
        ></MainWrapper>
      </Box>
      <ServiceQuotesDetail isFromServiceQuotes={true} setRefresh={setRefresh} />
    </>
  );
};

export default ServiceQuotesEdit;
