import { Navigate } from 'react-router-dom';

import { ROUTE_NAVIGATE_CONST } from '../constants/Constants';
import { getRedirectURL } from '../utils';
import { getUserRole } from './Permission';

const UnAuthLayout = ({ children }) => {
  const userInfo = localStorage.getItem('user_info');
  const userRole = getUserRole();
  const redirectPath =
    getRedirectURL()?.[userRole]?.[ROUTE_NAVIGATE_CONST.DASHBOARD];

  return userInfo ? <Navigate to={redirectPath} /> : children;
};

export default UnAuthLayout;
