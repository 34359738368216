import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { debounce } from 'lodash';

import {
  DATE_TIME_RANGE_FORMAT_DEFAULT,
  TAB_STATUS,
  getRolesList,
} from '../../constants/Constants';
import emailTemplateData from '../../constants/email_template.json';
import useBrowserBackButtonHandler from '../../hooks/useBrowserBackButtonHandler';
import { snackbarToggle } from '../../store/CommonReducer';
import {
  createEmailSettings,
  getTemplateDropDownData,
  getTemplates,
  sendEmail,
  updateEmailSettings,
  updateEmailTemplate,
} from '../../store/template/api';
import {
  capitalizeFirstLetterRemoveUnderscore,
  checkIsFiltersApplied,
  formattedDate,
  loggedInUserDetail,
} from '../../utils';
import { Edit } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import TabView from '../CommonComponents/TabView';
import EmailSetting from './EmailSetting';
import EmailTemplateForm from './EmailTemplateForm';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

const defaultFilters = {
  role: null,
  emailType: null,
  date: null,
};

const defaultValues = {
  fromName: '',
  toEmail: '',
  role: null,
  email_type: '',
  template_name: '',
  subject: '',
  reply_to: '',
  editor_data: '',
  variables: [],
  is_active: true,
};

const EmailTemplates = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const method = useForm({
    defaultValues: defaultValues,
  });
  const {
    setValue,
    trigger,
    watch,
    control,
    handleSubmit,
    clearErrors,
    reset,
  } = method;

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updated_at');
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  const [hasFormValues, sethasFormValues] = useState(false);
  const [emailTypeOptions, setEmailTypeOptions] = useState([]);
  const [isEmailTypeLoading, setIsEmailTypeLoading] = useState(true);

  const [templateList, setTemplateList] = useState([]);
  const [listLoading, setListLoading] = useState(true);

  const [emailSettingModal, setEmailSettingModal] = useState(false);
  const [isEmailSettings, setIsEmailSettings] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [emailTemplateModal, setEmailTemplateModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [emailTemplateConfirmationModal, setEmailTemplateConfirmationModal] =
    useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState(defaultFilters);

  const [isLoadingEditForm, setIsLoadingEditForm] = useState(false);

  const roleOptions = getRolesList();

  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: t('attributes.templates.template_name'),
        field: 'template_name',
        sortable: true,
        flex: 1,
      },
      {
        headerName: t('attributes.usersManagement.role'),
        field: 'role',
        sortable: false,
        flex: 1,
      },
      {
        headerName: t('attributes.property.type'),
        field: 'email_type',
        flex: 1,
        sortable: false,
      },
      {
        headerName: t('common.updatedAt'),
        field: 'updated_at',
        flex: 1,
        sortable: true,
      },
      {
        field: 'edit',
        headerName: t('attributes.edit'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Edit
            onClick={() => {
              setSelectedTemplate(row);
              setEmailTemplateModal(true);
              setIsLoadingEditForm(true);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  const onTabChange = () => {
    setFilters(defaultFilters);
    setSearchText('');
    setIsFilterComponentVisible(false);
  };

  const resetFilter = () => {
    setSearchText('');
    setFilters(defaultFilters);
  };

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  useBrowserBackButtonHandler({
    hasUnsavedChanges: hasFormValues,
    setOpenConfirmationModal: emailSettingModal
      ? setOpenConfirmationModal
      : setEmailTemplateConfirmationModal,
    open: emailSettingModal || emailTemplateModal,
    watchMethod: watch,
    sethasFormValues,
  });

  useEffect(() => {
    dispatch(getTemplateDropDownData())
      .then((res) => {
        const data = res?.payload?.data;
        const options = data?.map((d) => ({
          label: capitalizeFirstLetterRemoveUnderscore(d?.email_type),
          value: d?.email_type,
        }));

        setEmailTypeOptions(options);
      })
      .finally(() => setIsEmailTypeLoading(false));
  }, []);

  // Function to fetch data based on search, pagination, and filter
  const getAllTemplates = useCallback(() => {
    setListLoading(true);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    dispatch(
      getTemplates({
        active: activeTab === 0 ? TAB_STATUS.active : TAB_STATUS.inactive,
        page_size: perPageData,
        page: currentPage,
        search: searchText,
        order: order,
        orderBy: orderBy,
        role: filters.role?.value,
        email_type: filters.emailType?.value,
        fromDate: filters?.date?.from,
        toDate: filters?.date?.to,
        list_column_names: visibleFieldsString,
      })
    )
      .then((res) => setTemplateList(res?.payload?.data))
      .finally(() => setListLoading(false));
  }, [
    activeTab,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
    refresh,
  ]);

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllTemplates, 500), [
    getAllTemplates,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const settingHandler = (value) => {
    const body = {
      default_from_name: value?.fromName,
      default_reply_to: value?.toEmail,
    };

    if (isEmailSettings) {
      dispatch(updateEmailSettings({ data: body })).then((res) => {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: res?.payload?.message,
          })
        );
        setEmailSettingModal(false);
      });
    } else {
      dispatch(createEmailSettings({ data: body })).then((res) => {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: res?.payload?.message,
          })
        );
        setEmailSettingModal(false);
      });
    }
  };

  const emailTemplateHandler = (value) => {
    setIsLoadingEditForm(true);
    const bodyData = {
      role: value?.role?.value,
      email_type: value?.email_type?.value,
      template_name: value?.template_name,
      subject: value?.subject,
      template_content: value?.editor_data,
      reply_to: value?.reply_to,
      from_name: value?.from_name,
      is_active: value?.is_active,
      created_by: value?.created_by,
      master_email_template_uuid: value?.uuid,
    };

    dispatch(updateEmailTemplate({ id: value?.uuid, data: bodyData })).then(
      (res) => {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: res?.payload?.message,
          })
        );
        setIsLoadingEditForm(false);
        setEmailTemplateModal(false);
        getAllTemplates();
      }
    );
  };

  // sen Email
  const emailSendHandler = (value) => {
    setIsLoadingEditForm(true);
    const userDetail = loggedInUserDetail();
    const payloadOfVariables = new Map();

    value?.variables?.forEach((element) => {
      payloadOfVariables.set(element, emailTemplateData[element]);
    });

    const bodyData = {
      email_type: value?.email_type?.value,
      role: value?.role?.value,
      emails: [userDetail?.email],
      payload: Object.fromEntries(payloadOfVariables),
      company_uuid: userDetail?.company_uuid,
    };

    dispatch(sendEmail({ data: bodyData }))
      .then((res) => {
        setIsLoadingEditForm(false);

        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: `${value?.email_type?.label} email sent to ${userDetail?.email} sucessfully.`,
          })
        );
      })
      .catch((err) => console.log('-------', err));
  };

  const noData = (
    <NewNoDataPage
      icon={<EmailOutlinedIcon />}
      title={t('attributes.templates.email_templates')}
      singularText={t('attributes.templates.email_template')}
      filterHeight={filterHeight(isFilterComponentVisible)}
      createBtnText={`${t('attributes.add')} ${t('attributes.templates.email_template')}`}
    />
  );

  const rows = templateList?.map((t) => ({
    id: t?.uuid,
    uuid: t?.uuid,
    template_name: t?.master_template?.template_name || t?.template_name || '-',
    role: t?.master_template?.role
      ? capitalizeFirstLetterRemoveUnderscore(t?.master_template?.role)
      : t?.role
        ? capitalizeFirstLetterRemoveUnderscore(t?.role)
        : '-',
    email_type: t?.master_template?.email_type
      ? capitalizeFirstLetterRemoveUnderscore(t?.master_template?.email_type)
      : t?.email_type
        ? capitalizeFirstLetterRemoveUnderscore(t?.email_type)
        : '-',
    updated_at: t?.updated_at
      ? formattedDate(t?.updated_at, DATE_TIME_RANGE_FORMAT_DEFAULT)
      : '-',
  }));

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        onRefreshFilter={() => setRefresh(!refresh)}
        isRefresh={true}
        searchText={searchText}
        isActive={isFilterComponentVisible}
        onResetFilter={resetFilter}
        onSearchChange={(e) => setSearchText(e.target.value)}
        isResetButtonVisible={
          searchText ||
          filters.role ||
          filters.emailType ||
          filters.date?.from ||
          filters.date?.to
        }
        isFilterDisable={checkIsFiltersApplied(filters)}
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder={t('attributes.usersManagement.role')}
            options={roleOptions}
            value={filters?.role}
            onChange={(e, newValue) => {
              if (newValue || newValue === null) {
                setFilters((prev) => ({ ...prev, role: newValue }));
              }
            }}
            width="190px"
          />
          <Autocomplete
            placeholder={t('attributes.templates.email_type')}
            options={emailTypeOptions}
            value={filters?.emailType}
            isLoadingData={isEmailTypeLoading}
            onChange={(e, newValue) => {
              if (newValue || newValue === null) {
                setFilters((prev) => ({ ...prev, emailType: newValue }));
              }
            }}
            width="190px"
          />
          <CustomDateRangePicker
            onOkClick={(val) =>
              setFilters((prev) => ({
                ...prev,
                date: { from: val[0], to: val[1] },
              }))
            }
            onClear={() =>
              setFilters((prev) => ({
                ...prev,
                date: null,
              }))
            }
            fromDate={filters?.date?.from}
            toDate={filters?.date?.to}
            placeholder={t('attributes.updated_at')}
          />
        </FilterComponent>
      )}
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={templateList?.total || 0}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={listLoading}
        checkboxSelection={false}
        paginationRequired={false}
      />
    </>
  );

  return (
    <>
      <StyledMainWrapper
        btn={
          <CustomButton
            text={t('attributes.templates.email_setting')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<SettingsIcon />}
            onClick={() => setEmailSettingModal(true)}
          />
        }
      >
        <TabView
          tabs={[
            {
              label: t('common.active'),
              component: renderedComponent,
            },
            {
              label: t('common.inactive'),
              component: renderedComponent,
            },
          ]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onTabChange={onTabChange}
        />
      </StyledMainWrapper>
      {/* Email Setting Modal and Confirmation */}
      {emailSettingModal && (
        <SwipeableDrawer
          open={true}
          title={t('attributes.templates.email_setting')}
          footerButton={
            <CustomButton
              text={t('attributes.save')}
              startIcon={<SaveOutlinedIcon />}
              onClick={handleSubmit(settingHandler)}
            />
          }
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setEmailSettingModal(false);
          }}
          width={500}
        >
          <EmailSetting
            control={control}
            setValue={setValue}
            trigger={trigger}
            reset={reset}
            setIsEmailSettings={setIsEmailSettings}
          />
        </SwipeableDrawer>
      )}
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setEmailSettingModal(false);
            setOpenConfirmationModal(false);
            sethasFormValues(false);
          }}
        />
      )}
      {/* Email Temaplte Modal and Confirmation */}

      {emailTemplateModal && (
        <SwipeableDrawer
          open={emailTemplateModal}
          title={`${t('attributes.edit')} ${t('attributes.templates.email_template')}`}
          footerButton={
            <>
              <CustomButton
                text={t('attributes.templates.test_email')}
                startIcon={<ForwardToInboxOutlinedIcon />}
                onClick={handleSubmit(emailSendHandler)}
                color="inherit"
              />
              <CustomButton
                text={t('attributes.save')}
                startIcon={<SaveOutlinedIcon />}
                onClick={handleSubmit(emailTemplateHandler)}
              />
            </>
          }
          onClose={() => {
            hasFormValues
              ? setEmailTemplateConfirmationModal(true)
              : setEmailTemplateModal(false);
          }}
          width={700}
          disableEnforceFocus={true}
        >
          <EmailTemplateForm
            control={control}
            clearErrors={clearErrors}
            setValue={setValue}
            trigger={trigger}
            watch={watch}
            reset={reset}
            id={selectedTemplate?.uuid}
            isLoadingEditForm={isLoadingEditForm}
            setIsLoadingEditForm={setIsLoadingEditForm}
          />
        </SwipeableDrawer>
      )}
      {emailTemplateConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={emailTemplateConfirmationModal}
          setOpen={setEmailTemplateConfirmationModal}
          onConfirm={() => {
            setEmailTemplateConfirmationModal(false);
            setEmailTemplateModal(false);
            sethasFormValues(false);
          }}
        />
      )}
    </>
  );
};

export default EmailTemplates;
