import { uniqBy } from 'lodash';
import moment from 'moment';

import BasicBarChart from './components/Chart/BarChart/CustomBarChart';
import LineChart from './components/Chart/LineChart/LineChart';
import Piechart from './components/Chart/PieChart/PieChart';
// import StackedBarChart from './components/Chart/StackedBarChart/StackedBarChart';
import StatusLabel, {
  ChipStatusLabel,
} from './components/CommonComponents/StatusLabel';
import {
  DARK_SKY,
  LIGHT_SKY,
  LIGHT_WARNING,
  PRIMARY,
  RED,
  ROW_HIGHLIGHTER_COLOR,
  STATUS_COLORS,
  WARNING,
} from './constants/Colors';
import {
  ACTIVE,
  APPOINTMENT_CONFIRMED,
  APPROVED,
  ASSETS_STATUS_LABEL,
  BAR_CHART,
  CHART_COLOR,
  CHART_CONST,
  CLOSED_STATUS,
  COMPLETED,
  COMPLIANCE_STATUS,
  COUNTRY_COLOR,
  CREATED,
  CRITICAL_STATUS,
  CUSTOMER_COMPLIANCE_STATUS,
  CUSTOMER_DEFECT_QUOTE_LIST,
  CUSTOMER_QUOTATION_STATUS,
  DATE_CONVERT_UTC_FORMAT_API,
  DATE_FORMAT,
  DATE_FORMAT_API,
  DATE_FORMAT_API_WITH_TIME,
  DIFFERED,
  FAIL_STATUS,
  GST_PERCENTAGE,
  INSPECTION_FORM,
  IN_ACTIVE,
  IN_PROGRESS,
  IN_PROGRESS_STATUS,
  IN_REVIEW,
  LABELS,
  LINE_CHART,
  NON_COMPLIANCE_STATUS,
  NON_CONFORMANCE_STATUS,
  NON_CRITICAL_STATUS,
  NO_CHECK_STATUS,
  OPEN,
  OPEN_STATUS,
  OVERDUE,
  PAID,
  PASS_STATUS,
  PENDING,
  PIE_CHART,
  PURCHASE_ORDER_STATUS,
  RECOMMENDATION_STATUS,
  REJECTED_STATUS,
  RESOLVED,
  RE_VISIT,
  ROUTE_NAVIGATE_CONST,
  SCHEDULED,
  STATUS_BASED_COLOR,
  SUBMITTED,
  TEMPLATE_SET,
  TWELEVE_MONTHS,
  WO_CREATED,
} from './constants/Constants';
import {
  CUSTOMER,
  GLOBAL_ADMIN,
  OFFICE_MANAGEMENT,
  OFFICE_STAFF,
  SUPER_ADMIN,
} from './constants/Roles';
import { ROUTENAME } from './constants/RoutesConstants';
import { setIsTopBarLoader } from './store/CommonReducer';

export const loggedInUserRole = () => {
  const user_info = localStorage.getItem('user_info');
  const user = user_info ? JSON.parse(user_info) : null;

  return user?.role_name;
};

export const loggedInUserDetail = () => {
  const user_info = localStorage.getItem('user_info');
  const user = user_info ? JSON.parse(user_info) : null;

  return user;
};

export const getRandomColor = () => {
  const color = `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')}`;

  return color;
};

/**
 * Format date to be displayed in the UI
 * @param {string | Date} date - date in string or Date format
 * @param {string} [defaultFormat=DATE_FORMAT] - desired date format
 * @returns {string} - formatted date in the desired format
 */
export const formatDate = (date, defaultFormat = DATE_FORMAT) => {
  if (!date) return null;

  return moment(date).format(defaultFormat);
};

/**
 * Format date to be sent to API
 * @param {string | Date} date - date in string or Date format
 * @param {string} [defaultFormat=DATE_FORMAT_API] - desired date format
 * @returns {string} - formatted date in the desired format
 */
export const formatDateForAPI = (
  date,
  defaultFormat = DATE_FORMAT_API,
  isContractDate = false
) => {
  if (!date) return null;

  let formattedDate = moment(date);

  if (isContractDate) {
    // If the date is not the 1st, default to the 1st of the same month
    if (formattedDate.date() !== 1) {
      formattedDate = formattedDate.startOf('month');
    }
  }

  return formattedDate.format(defaultFormat);
};

export const calculateEndDate = (
  startDate,
  durationInMonths,
  format = 'YYYY-MM-DD',
  isRenewal = false
) => {
  if (!startDate || !durationInMonths) return null;

  const parsedDate = moment(startDate, 'YYYY-MM-DD', true);
  const endDate = parsedDate.add(durationInMonths - 1, 'months').endOf('month');

  if (isRenewal) {
    endDate.add(1, 'day');
  }

  return endDate.format(format);
};

/**
 * Format date to be displayed in the UI
 * @param {string | Date} date - date in string or Date format
 * @param {string} [defaultFormat=DATE_FORMAT] - desired date format
 * @returns {string} - formatted date in the desired format
 */
export const formattedDate = (date, defaultFormat = DATE_FORMAT) => {
  if (!date) return null;

  return moment.utc(date).local().format(defaultFormat); // Convert UTC to local and format it
};

/**
 * Format date to be sent to API
 * @param {string | Date} receivedDate - date in string or Date format
 * @param {string} [defaultFormat=DATE_FORMAT_API_WITH_TIME] - desired date format
 * @returns {string} - formatted date in the desired format
 */
export const filterDateFormatted = (
  receivedDate,
  defaultFormat = DATE_FORMAT_API_WITH_TIME
) => {
  const date = new Date(receivedDate);
  const newDate = moment(date).utc().format(defaultFormat);

  return newDate;
};

// export const datePickerFormat = (receivedDate) =>
//   moment.utc(receivedDate).local().toDate();

export const datePickerFormat = (receivedDate, isMonthOnly = false) => {
  const localDate = moment.utc(receivedDate).local();

  if (!localDate.isValid()) return null;

  if (isMonthOnly) {
    // Return the month and year only
    return localDate.format('YYYY-MM'); // e.g., "2025-07"
  }

  // Return the full date
  return localDate.toDate();
};

export const findDurationMatch = (startDate, endDate) => {
  if (!startDate || !endDate) return null;

  // Parse the dates from the ISO 8601 format
  const start = moment(startDate);
  const end = moment(endDate);

  if (!start.isValid() || !end.isValid()) {
    throw new Error(
      'Invalid date format. Expected ISO 8601 format: YYYY-MM-DDTHH:mm:ss'
    );
  }

  const totalMonths = end.diff(start, 'months');

  const matchedDuration = totalMonths + 1;

  return matchedDuration || null;
};

/**
 * Format date to be sent to API
 * @param {string | Date} date - date in string or Date format
 * @param {string} [defaultFormat=DATE_FORMAT_API_WITH_TIME] - desired date format
 * @returns {string} - formatted date in the desired format
 */
export const formatDateAPI = (
  date,
  defaultFormat = DATE_FORMAT_API_WITH_TIME,
  defaultUTCFormat = DATE_CONVERT_UTC_FORMAT_API
) => {
  if (!date) return null;

  return moment(date, defaultUTCFormat).utc().format(defaultFormat);
};

export const debounce = (func, wait) => {
  let timeout;

  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

export function getUniqueFrequencies(frequencyList) {
  const uniqueFrequencies = {};

  frequencyList.forEach((freq) => {
    uniqueFrequencies[freq.code] = freq;
  });

  return Object.values(uniqueFrequencies);
}

export const clearTextfields = (setFun, name) => setFun(name, '');

export const getWidgetChartData = (
  chartName,
  chartType,
  data,
  monthselector = 6
) => {
  switch (chartType) {
    case PIE_CHART: {
      if (chartName === CHART_CONST.PROPERTIES) {
        const resultantData = uniqBy(data, (o) => o?.name?.trim().toLowerCase())
          ?.map((d) => ({
            label: capitalizeFirstLetter(d?.display_name),
            value: d?.count,
            color: CHART_COLOR[d?.name?.toLowerCase()],
            id: d?.name?.toLowerCase(),
          }))
          ?.filter((d) => d.value !== 0);

        return {
          data: resultantData,
        };
      }
      if (chartName === CHART_CONST.PROPERTY_COMPLIANCE) {
        const resultantData = uniqBy(data, (o) =>
          o?.compliance_status?.trim().toLowerCase()
        )
          ?.map((d) => ({
            label: CUSTOMER_COMPLIANCE_STATUS?.find((c) =>
              d?.compliance_status == null
                ? c?.value === LABELS.NA
                : c.value === d?.compliance_status
            )?.label,
            value: d?.count,
            color:
              CHART_COLOR[
                d?.compliance_status == null
                  ? 'not_applicable'
                  : d?.compliance_status?.toLowerCase()
              ],
            id:
              d?.compliance_status == null
                ? LABELS.NA
                : d?.compliance_status?.toLowerCase(),
          }))
          ?.filter((d) => d.value !== 0);

        return {
          data: resultantData,
        };
      }
      if (chartName === CHART_CONST.DEFECTS) {
        return {
          data: uniqBy(data, (o) => o?.severity?.trim().toLowerCase())
            ?.map((d) => ({
              label: capitalizeFirstLetterRemoveUnderscore(d?.severity)
                ?.split(' ')
                ?.join('-'),
              value: d?.count,
              color: CHART_COLOR[d?.severity?.toLowerCase()],
              id: d?.severity?.toLowerCase(),
            }))
            ?.filter((d) => d.value !== 0),
        };
      }

      if (
        chartName === CHART_CONST.QUOTES ||
        chartName === CHART_CONST.SERVICE
      ) {
        return {
          data: uniqBy(data, (o) => o?.status?.trim().toLowerCase())
            ?.map((d) => ({
              label: CUSTOMER_DEFECT_QUOTE_LIST?.find(
                (c) => c.value === d?.status
              )?.label,
              value: d?.count,
              color: CHART_COLOR[d?.status?.toLowerCase()],
              id: d?.status?.toLowerCase(),
            }))
            ?.filter((d) => d.value !== 0),
        };
      }

      if (chartName === CHART_CONST.PURCHASE_ORDER) {
        return {
          data: uniqBy(data, (o) => o?.status?.trim().toLowerCase())
            ?.map((d) => ({
              label: capitalizeFirstLetter(d?.status),
              value: d?.count,
              color: CHART_COLOR[d?.status?.toLowerCase()],
              id: d?.status?.toLowerCase(),
            }))
            ?.filter((d) => d.value !== 0),
        };
      }
      if (chartName === CHART_CONST.INVOICES) {
        const invoiceData = uniqBy(data, (o) =>
          o?.status?.trim().toLowerCase()
        )?.map((d) => ({
          label: capitalizeFirstLetter(d?.status),
          value: d?.count,
          color: CHART_COLOR[d?.status?.toLowerCase()],
          id: d?.status?.toLowerCase(),
        }));

        return {
          data: invoiceData?.filter((v) => v.value !== 0),
        };
      }
      if (
        chartName === CHART_CONST.CUSTOMER_PROPERTIES ||
        chartName === CHART_CONST.FIRE_COMPANY ||
        chartName === CHART_CONST.USERS
      ) {
        return {
          data: data
            ?.map((d, index) => ({
              label: d?.state || '',
              value: d?.count,
              color: COUNTRY_COLOR[index],
              id: d?.state?.toLowerCase(),
            }))
            ?.filter((d) => d.value !== 0),
        };
      }
      break;
    }

    case BAR_CHART: {
      if (chartName === CHART_CONST.PROPERTIES) {
        let colors = [];
        let labels = [];
        let count = [];
        let ids = [];

        data?.forEach((d) => {
          colors?.push(CHART_COLOR[d?.name]);
          labels?.push(d?.display_name);
          count.push(d?.count);
          ids?.push(d?.name?.toLowerCase());
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
          ids: ids,
        };
      }

      if (chartName === CHART_CONST.PROPERTY_COMPLIANCE) {
        let colors = [];
        let labels = [];
        let count = [];
        let ids = [];

        data?.forEach((d) => {
          colors?.push(
            CHART_COLOR[
              d?.compliance_status == null
                ? 'not_applicable'
                : d?.compliance_status?.toLowerCase()
            ]
          );
          labels?.push(
            CUSTOMER_COMPLIANCE_STATUS?.find((c) =>
              d?.compliance_status == null
                ? c?.value === LABELS.NA
                : c.value === d?.compliance_status
            )?.label
          );
          count.push(d?.count);
          ids?.push(
            d?.compliance_status == null
              ? LABELS.NA
              : d?.compliance_status?.toLowerCase()
          );
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
          ids: ids,
        };
      }
      if (chartName === CHART_CONST.DEFECTS) {
        let colors = [];
        let labels = [];
        let count = [];
        let ids = [];

        data?.forEach((d) => {
          colors?.push(CHART_COLOR[d?.severity?.toLowerCase()]);
          labels?.push(
            capitalizeFirstLetterRemoveUnderscore(d?.severity)
              ?.split(' ')
              ?.join('-')
          );
          count.push(d?.count);
          ids?.push(d?.severity?.toLowerCase());
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
          ids: ids,
        };
      }
      if (
        chartName === CHART_CONST.QUOTES ||
        chartName === CHART_CONST.SERVICE
      ) {
        let colors = [];
        let labels = [];
        let count = [];
        let ids = [];

        data?.forEach((d) => {
          colors?.push(CHART_COLOR[d?.status]);
          labels?.push(
            CUSTOMER_DEFECT_QUOTE_LIST?.find((c) => c.value === d?.status)
              ?.label
          );
          count.push(d?.count);
          ids?.push(d?.status?.toLowerCase());
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
          ids: ids,
        };
      }

      if (chartName === CHART_CONST.PURCHASE_ORDER) {
        let colors = [];
        let labels = [];
        let count = [];
        let ids = [];

        data?.forEach((d) => {
          colors?.push(CHART_COLOR[d?.status.toLowerCase()]);
          labels?.push(capitalizeFirstLetter(d?.status));
          count.push(d?.count);
          ids?.push(d?.status?.toLowerCase());
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
          ids: ids,
        };
      }

      if (chartName === CHART_CONST.INVOICES) {
        let colors = [];
        let labels = [];
        let count = [];
        let ids = [];

        data?.forEach((d) => {
          colors?.push(CHART_COLOR[d?.status.toLowerCase()]);
          labels?.push(capitalizeFirstLetter(d?.status));
          count.push(d?.count);
          ids?.push(d?.status?.toLowerCase());
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
          ids: ids,
        };
      }
      if (
        chartName === CHART_CONST.CUSTOMER_PROPERTIES ||
        chartName === CHART_CONST.FIRE_COMPANY ||
        chartName === CHART_CONST.USERS
      ) {
        let colors = [];
        let labels = [];
        let count = [];
        let ids = [];

        data?.forEach((d, index) => {
          colors?.push(COUNTRY_COLOR[index]);
          labels?.push(d?.state);
          count.push(d?.count);
          ids?.push(d?.state?.toLowerCase());
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
          ids: ids,
        };
      }
      break;
    }

    case LINE_CHART: {
      if (chartName === CHART_CONST.PROPERTIES) {
        let labels = [];
        let count = [];

        data?.forEach((d) => {
          labels?.push(d?.display_name);
          count.push(d?.count);
        });

        return {
          data: data?.map((d) => ({
            label: d?.display_name,
            color: CHART_COLOR[d?.name],
            data: count,
          })),
          labels: count,
        };
      }
      if (chartName === CHART_CONST.DEFECTS) {
        let labels = [];
        let count = [];

        data?.forEach((d) => {
          labels?.push(capitalizeFirstLetterRemoveUnderscore(d?.severity));
          count.push(d?.count);
        });

        return {
          data: data?.map((d) => ({
            label: d?.severity,
            color: CHART_COLOR[d?.severity?.toLowerCase()],
            data: count,
          })),
          labels: count,
        };
      }
      if (chartName === CHART_CONST.QUOTES) {
        let labels = [];
        let count = [];

        data?.forEach((d) => {
          labels?.push(
            d?.status
              ?.split('_')
              ?.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
              .join(' ')
          );
          count.push(d?.count);
        });

        return {
          data: data?.map((d) => ({
            label: d?.status
              ?.split('_')
              ?.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
              .join(' '),
            color: CHART_COLOR[d?.status],
            data: count,
          })),
          labels: count,
        };
      }
      if (chartName === CHART_CONST.PURCHASE_ORDER) {
        let labels = [];
        let count = [];

        data?.forEach((d) => {
          labels?.push(d?.status[0] + d?.status?.substr(1)?.toLowerCase());
          count.push(d?.count);
        });

        return {
          data: data?.map((d) => ({
            label: d?.status[0] + d?.status?.substr(1)?.toLowerCase(),
            color: CHART_COLOR[d?.status.toLowerCase()],
            data: count,
          })),
          labels: count,
        };
      }
      if (chartName === CHART_CONST.FIRE_COMPANY_STATUS) {
        let labels = getXAxisLabel(monthselector, true);

        const currentMonth = moment().month() + 1;
        const currentYear = moment().year();

        const lastMonths = [...Array(monthselector)].map((_, index) => {
          const month = ((currentMonth - index - 1 + 12) % 12) + 1;
          const year =
            currentYear + Math.floor((currentMonth - index - 1) / 12);

          return { month, year };
        });

        const activeData = lastMonths.map(
          ({ month, year }) =>
            data?.find(
              (d) =>
                d.month === month && d.year === year && d?.status === ACTIVE
            )?.count || 0
        );
        const inActiveData = lastMonths.map(
          ({ month, year }) =>
            data?.find(
              (d) =>
                d.month === month && d.year === year && d?.status === IN_ACTIVE
            )?.count || 0
        );

        return {
          data: [
            {
              id: ACTIVE,
              data: activeData.reverse(),
              label: LABELS.ACTIVE,
              color: PRIMARY,
            },
            {
              id: IN_ACTIVE,
              data: inActiveData.reverse(),
              label: LABELS.IN_ACTIVE,
              color: RED,
            },
          ],
          labels: labels,
        };
      }
      break;
    }

    default:
      break;
  }
};

const chartClickHandler = ({ role, chartName, clickDataStatus }) => {
  const redirectionUrl = getRedirectURL()[role][chartName];

  return redirectionUrl ? `${redirectionUrl}?status=${clickDataStatus}` : false;
};

export const displayChart = (item) => {
  const data = item.widget_data[item?.type];

  switch (item?.type) {
    case PIE_CHART:
      return (
        <Piechart
          data={data?.data}
          isRadius={item?.is_radius}
          labelType={item?.label_type}
          onChartClick={(dataIndex) =>
            chartClickHandler({
              role: item?.role_name,
              chartName: item?.chart_name,
              clickDataStatus: data?.data?.[dataIndex]?.id,
            })
          }
        />
      );
    case BAR_CHART:
      return (
        <BasicBarChart
          data={data?.data}
          xLabels={data?.labels}
          colors={data?.colors}
          onChartClick={(dataIndex) =>
            chartClickHandler({
              role: item?.role_name,
              chartName: item?.chart_name,
              clickDataStatus: data?.ids?.[dataIndex],
            })
          }
        />
      );
    case LINE_CHART:
      return <LineChart data={data?.data} xLabels={data?.labels} />;

    // case GAUGE_CHART:
    //   break;
    // case SPARKLINE_CHART:
    //   break;
    // case SCATTER_CHART:
    //   break;
    default:
  }
};

export const isCreatedWithinLastFiveMinutes = (createdAt) => {
  if (!createdAt) return false;
  const nowUTC = moment.utc();
  const createdAtDate = moment.utc(createdAt);

  const diffInMinutes = nowUTC.diff(createdAtDate, 'minutes');

  return diffInMinutes <= 5;
};

export const findLabelByValue = (list, value) => {
  const item = list.find((option) => option.value === value);

  return item ? { ...item, label: item.label, value: item.value } : null;
};

export const findLabelByLabel = (list, label) => {
  const item = list.find((option) => option.label === label);

  return item ? { label: item.label, value: item.value } : null;
};

export const addSpaceAndCapitalizeString = (value) =>
  value
    ?.split('-')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ');

export const capitalizeFirstLetter = (value) =>
  value
    ?.split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const capitalizeFirstLetterRemoveUnderscore = (value) =>
  value
    ?.split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const formatStatus = (status, height = '12px', width = '12px') => {
  const lowerCaseStatus =
    typeof status === 'string' ? status?.toLowerCase() : status;

  if (status === ASSETS_STATUS_LABEL.fail || lowerCaseStatus === FAIL_STATUS) {
    return <StatusLabel label={ASSETS_STATUS_LABEL.fail} color="#C54036" />;
  }
  if (status === LABELS?.RE_VISIT || lowerCaseStatus === RE_VISIT) {
    return (
      <StatusLabel
        label={LABELS.RE_VISIT}
        color={STATUS_BASED_COLOR[RE_VISIT]}
      />
    );
  }
  if (status === LABELS?.WO_CREATED || lowerCaseStatus === WO_CREATED) {
    return (
      <StatusLabel
        label={LABELS.WO_CREATED}
        color={STATUS_BASED_COLOR[WO_CREATED]}
      />
    );
  }

  if (status === LABELS?.IN_REVIEW || lowerCaseStatus === IN_REVIEW) {
    return (
      <StatusLabel
        label={LABELS.IN_REVIEW}
        color={STATUS_BASED_COLOR[IN_REVIEW]}
      />
    );
  }
  if (status === ASSETS_STATUS_LABEL.pass || lowerCaseStatus === PASS_STATUS) {
    return <StatusLabel label={ASSETS_STATUS_LABEL.pass} color="#95C020" />;
  }
  if (status === APPROVED || lowerCaseStatus === APPROVED) {
    return <StatusLabel label="Approved" color="#95C020" />;
  }
  if (status === OPEN || lowerCaseStatus === OPEN) {
    return <StatusLabel label="Open" color="#95C020" />;
  }
  if (status === REJECTED_STATUS || lowerCaseStatus === REJECTED_STATUS) {
    return <StatusLabel label="Rejected" color="#C54036" />;
  }
  if (
    status === ASSETS_STATUS_LABEL.noCheck ||
    lowerCaseStatus === NO_CHECK_STATUS
  ) {
    return <StatusLabel label={ASSETS_STATUS_LABEL.noCheck} color="#59366B" />;
  }

  if (lowerCaseStatus === true) {
    return <StatusLabel label="Yes" color="#59C3C3" />;
  }
  if (lowerCaseStatus === false) {
    return <StatusLabel label="No" color="#454545" />;
  }

  if (
    lowerCaseStatus === CUSTOMER_QUOTATION_STATUS?.PENDING?.toLocaleLowerCase()
  ) {
    return (
      <StatusLabel label="Pending" color={CHART_COLOR.submit_to_customer} />
    );
  }

  if (lowerCaseStatus === CRITICAL_STATUS) {
    return (
      <StatusLabel
        label="Critical"
        color="#C04B20"
        height={height}
        width={width}
      />
    );
  }
  if (lowerCaseStatus === NON_CONFORMANCE_STATUS) {
    return (
      <StatusLabel
        label="Non-Conformance"
        color="#7A4E8C"
        height={height}
        width={width}
      />
    );
  }
  if (lowerCaseStatus === NON_CRITICAL_STATUS) {
    return (
      <StatusLabel
        label="Non-Critical"
        color="#95C020"
        height={height}
        width={width}
      />
    );
  }
  if (lowerCaseStatus === RECOMMENDATION_STATUS) {
    return (
      <StatusLabel
        label="Recommendation"
        color="#FD7E14"
        height={height}
        width={width}
      />
    );
  }

  if (lowerCaseStatus === OPEN_STATUS) {
    return <StatusLabel label="Open" color="#FF0000" />;
  }
  if (lowerCaseStatus === 'close') {
    return <StatusLabel label="Close" color="#95C020" />;
  }
  if (lowerCaseStatus === CLOSED_STATUS) {
    return <StatusLabel label="Closed" color="#95C020" />;
  }
  if (lowerCaseStatus === IN_PROGRESS_STATUS) {
    return <StatusLabel label="In Progress" color="#2196F3" />;
  }

  // Work Order list status start
  if (status === CREATED || status === CREATED.toLowerCase()) {
    return (
      <StatusLabel
        label={CREATED}
        color="#454545"
        height={height}
        width={width}
      />
    );
  }
  if (status === SCHEDULED || status === SCHEDULED.toLowerCase()) {
    return (
      <StatusLabel
        label={SCHEDULED}
        color="#59C3C3"
        height={height}
        width={width}
      />
    );
  }
  if (
    status === APPOINTMENT_CONFIRMED ||
    status === APPOINTMENT_CONFIRMED.toLowerCase()
  ) {
    return (
      <StatusLabel
        label={APPOINTMENT_CONFIRMED}
        color="#2CCDA1"
        height={height}
        width={width}
      />
    );
  }
  if (status === IN_PROGRESS || status === IN_PROGRESS.toLowerCase()) {
    return (
      <StatusLabel
        label={IN_PROGRESS}
        color="#F7A142"
        height={height}
        width={width}
      />
    );
  }
  if (status === COMPLETED || status === COMPLETED.toLowerCase()) {
    return (
      <StatusLabel
        label={COMPLETED}
        color="#95C020"
        height={height}
        width={width}
      />
    );
  }
  if (lowerCaseStatus === RESOLVED) {
    return (
      <StatusLabel
        label="Resolved"
        color={PRIMARY}
        height={height}
        width={width}
      />
    );
  }
  if (lowerCaseStatus === PENDING) {
    return (
      <StatusLabel
        label="Pending"
        color="#FD7E14"
        height={height}
        width={width}
      />
    );
  }
  if (lowerCaseStatus === PURCHASE_ORDER_STATUS.DELETED) {
    return <StatusLabel label="Deleted" color="#C54036" />;
  }
  if (lowerCaseStatus === PURCHASE_ORDER_STATUS.SUBMITTED) {
    return <StatusLabel label="Submitted" color="#95C020" />;
  }
  if (lowerCaseStatus === PURCHASE_ORDER_STATUS.DRAFT) {
    return <StatusLabel label="Draft" color="#999999" />;
  }
  if (lowerCaseStatus === PURCHASE_ORDER_STATUS.CREATED) {
    return <StatusLabel label="Created" color="#59C3C3" />;
  }
  if (lowerCaseStatus === PURCHASE_ORDER_STATUS.PAID) {
    return <StatusLabel label="Paid" color="#395B50" />;
  }
  if (lowerCaseStatus === CUSTOMER_QUOTATION_STATUS.OVERDUE) {
    return <StatusLabel label={LABELS.OVERDUE} color={STATUS_COLORS.OVERDUE} />;
  }
  if (lowerCaseStatus === PURCHASE_ORDER_STATUS.DELIVERED) {
    return <StatusLabel label="Delivered" color="#93B7BE" />;
  }
  if (lowerCaseStatus === DIFFERED) {
    return <StatusLabel label="Differed" color="#FFA500" />;
  }
  // Work Order list status end
};

export const formatChipStatus = (status) => {
  const lowerCase = status?.toLowerCase();

  if (lowerCase === RESOLVED) {
    return (
      <ChipStatusLabel
        label={LABELS.CLOSED}
        bgColor={ROW_HIGHLIGHTER_COLOR}
        color={PRIMARY}
      />
    );
  }
  if (lowerCase === PENDING || lowerCase === OPEN_STATUS) {
    return (
      <ChipStatusLabel
        label={LABELS.OPEN}
        bgColor={LIGHT_SKY}
        color={DARK_SKY}
      />
    );
  }

  if (lowerCase === COMPLIANCE_STATUS) {
    return (
      <ChipStatusLabel
        label={LABELS.COMPLIANCE_STATUS}
        bgColor={ROW_HIGHLIGHTER_COLOR}
        color={PRIMARY}
      />
    );
  }
  if (lowerCase === NON_COMPLIANCE_STATUS) {
    return (
      <ChipStatusLabel
        label={LABELS.NON_COMPLIANCE_STATUS}
        bgColor={LIGHT_WARNING}
        color={WARNING}
      />
    );
  }
};

export const formatPriceWithDecimalValue = (price, isFromPDF) => {
  if (price == null || isNaN(price)) return '';
  if (price == Math.floor(price)) return isFromPDF ? price.toFixed(2) : price;
  const numPrice = parseFloat(price);

  return numPrice.toFixed(2);
};

export const extractInitials = (str) => {
  // Split the string by spaces
  let parts = str?.split(' ');

  if (parts?.length > 0) {
    if (parts.length === 1) {
      return parts[0][0];
    } else {
      return parts[0][0] + (parts.length > 1 ? parts[1][0] : '');
    }
  } else {
    return '-';
  }
};

export const getAmountByQtyAndPrice = (data) =>
  data?.reduce((total, item) => {
    const amount = item.qty * Number(item.price);

    return total + amount;
  }, 0);

export const getTaxAmountByQtyAndPrice = (data) =>
  data?.reduce((acc, item) => acc + Number(item?.tax), 0).toFixed(2);

export const getXAxisLabel = (totalMonth, isYear = false) => {
  const currentMonth = moment().month();
  const currentYear = moment().year();

  const axisLabel = [...Array(totalMonth)].map((_, index) => {
    const monthIndex = (currentMonth - index + 12) % 12;
    const yearOffset = Math.floor((currentMonth - index) / 12);
    const year = currentYear + yearOffset;

    return `${TWELEVE_MONTHS[monthIndex]} ${isYear ? year : ''}`.trim();
  });

  return axisLabel.reverse(); // Reverse to get chronological order
};

export const getMergedArray = (array1, array2) =>
  array1.map((obj) => {
    const match = array2.find((item) => item.id === obj.id);

    return match ? { ...obj, ...match } : obj; // Override matching objects
  });

export const searchFromArray = (searchVal, array) => {
  if (searchVal) {
    return array?.filter((a) =>
      a?.name?.toLocaleLowerCase()?.includes(searchVal?.toLowerCase())
    );
  }

  return array;
};

export function transformInspectionForm(obj) {
  // Helper function to extract radio and comment values from elements
  const extractElementValues = (elements) => {
    let radioValue = '';
    let resultValue = '';
    let commentValue = '';

    elements?.forEach((element) => {
      if (element?.type === INSPECTION_FORM.RADIO_GROUP) {
        radioValue = element?.value || '';
      }
      if (element?.type === INSPECTION_FORM.COMMENT) {
        if (element?.title === INSPECTION_FORM.RESULT) {
          resultValue = element?.value?.trim() || '';
        } else if (element?.title === INSPECTION_FORM.COMMENT_CAPITAL) {
          commentValue = element?.value?.trim() || '';
        }
      }
    });

    return { radioValue, resultValue, commentValue };
  };

  // Recursive function to process panels and their nested elements
  const processPanel = (panel) => {
    const { radioValue, resultValue, commentValue } = extractElementValues(
      panel?.elements || []
    );

    const parentPanel = {
      title: panel?.title || '',
      description: panel?.description?.trim() || '',
      radio_checked: radioValue,
      result: resultValue,
      comment: commentValue,
    };

    // Process nested panels
    const nestedPanels = (panel.elements || [])
      .filter((el) => el?.type === INSPECTION_FORM.PANEL)
      .flatMap(processPanel);

    return [parentPanel, ...nestedPanels];
  };

  // Main transformation logic
  const processInspectionForm = (data) => {
    const pages = data?.inspection_form_json?.pages || [];
    const result = [];

    pages.forEach((page) => {
      page?.elements?.forEach((element) => {
        if (element.type === 'panel') {
          const processedPanels = processPanel(element);

          result.push(...processedPanels);
        }
      });
    });

    return result.flat();
  };

  try {
    return processInspectionForm(obj);
  } catch (error) {
    return [];
  }
}

export const transformToTitleCase = (title) =>
  title
    ?.split(' ')
    ?.map((val) => val?.charAt(0)?.toUpperCase() + val?.slice(1))
    ?.join(' ');

export const getMomentDateTime = ({
  format = 'YYYY-MM-DD HH:mm:ss',
  months = 6,
  monthYear = 'months',
  startDateKey = 'start_date',
  endDateKey = 'end_date',
  dateType = 'formated',
} = {}) => {
  switch (dateType) {
    case 'formated':
      return {
        [startDateKey]: moment()
          .clone()
          .subtract(months, monthYear)
          .startOf('day')
          .format(format),
        [endDateKey]: moment().clone().endOf('day').format(format),
      };
    case 'toDate':
      return {
        [startDateKey]: moment()
          .clone()
          .subtract(months, monthYear)
          .startOf('day')
          .toDate(),
        [endDateKey]: moment().clone().endOf('day').toDate(),
      };
  }
};

export const templateExtractData = (module, previewData) => {
  if (module === TEMPLATE_SET.INVOICE) {
    const { invoiceDetails, companyData, isLoadingCompany, contact } =
      previewData;

    const subTotal = invoiceDetails?.items?.reduce(
      (sum, item) => sum + item?.price * item?.qty,
      0
    );

    const tax = invoiceDetails?.items
      ?.reduce((acc, item) => acc + Number(item?.tax), 0)
      .toFixed(2);

    const taxAmount = Number(tax);

    return {
      moduleDetail: {
        ...invoiceDetails,
        address: formatAddress(
          contact?.address || '',
          contact?.address_line_2 || '',
          contact?.city || '',
          contact?.state || '',
          contact?.postcode
        ),
      },
      companyData: {
        ...companyData,
        address: formatAddress(
          companyData?.address,
          companyData?.address_line_2
        ),
      },
      isLoading: isLoadingCompany,
      propertyData: {},
      subTotal,
      taxAmount,
    };
  } else if (module === TEMPLATE_SET.DEFECT_QUOTES) {
    const {
      companyData,
      propertyData,
      quoteData,
      isPropertyLoading,
      isFromServiceQuotes,
      contact,
    } = previewData;

    const subTotal = quoteData?.quotation_items?.reduce(
      (sum, item) => sum + item?.sell_price * item?.qty,
      0
    );

    const { quotation_items: items, ...rest } = quoteData;

    return {
      companyData: {
        ...companyData,
        address: formatAddress(
          companyData?.address,
          companyData?.address_line_2
        ),
      },
      propertyData: {
        ...propertyData,
        address: formatAddress(
          propertyData?.address,
          propertyData?.address_line_2
        ),
      },
      moduleDetail: {
        ...rest,
        items,
        address: formatAddress(
          contact?.address || '',
          contact?.address_line_2 || '',
          contact?.city || '',
          contact?.state || '',
          contact?.postcode
        ),
      },
      isLoading: isPropertyLoading,
      subTotal,
      isFromServiceQuotes,
    };
  } else {
    const {
      company_data: companyData,
      isLoadingCompany,
      purchaseOrderDetails,
    } = previewData;

    const subTotal = purchaseOrderDetails?.items?.reduce(
      (sum, item) => sum + Number(item?.price) * item?.qty,
      0
    );

    return {
      companyData: {
        ...companyData,
        address: formatAddress(
          companyData?.address,
          companyData?.address_line_2
        ),
      },
      moduleDetail: {
        ...purchaseOrderDetails,
        address: formatAddress(
          purchaseOrderDetails?.address,
          purchaseOrderDetails?.address_line_2
        ),
      },
      isLoading: isLoadingCompany,
      propertyData: {},
      subTotal,
    };
  }
};

export const showHideTopbarLoader = (loading, dispatch) => {
  dispatch(setIsTopBarLoader(loading));
};

export const calculateTotal = ({
  data,
  type,
  total,
  taxPercentage = GST_PERCENTAGE,
  taxAmount,
}) => {
  const subtotal = total
    ? total
    : (data &&
        data?.quotation_items?.reduce(
          (acc, item) => acc + (item?.sell_price || 0) * (item?.qty || 0),
          0
        )) ||
      0;

  const gstAmount =
    taxAmount || taxAmount === 0 ? taxAmount : (subtotal * taxPercentage) / 100;

  switch (type) {
    case 'subtotal':
      return subtotal?.toFixed(2);
    case 'gst':
      return gstAmount?.toFixed(2);
    case 'total':
      return (subtotal + Number(gstAmount))?.toFixed(2);
    default:
      0;
  }
};

export const calculateTaxPercentage = (totalPrice, totalTax) => {
  if (!totalPrice || totalPrice <= 0) {
    throw new Error(
      'Invalid inputs. Total price must be greater than 0, and total tax must be a positive number.'
    );
  }

  // Calculate the percentage tax applied
  const taxPercentage = (totalTax / totalPrice) * 100;

  return parseFloat(taxPercentage.toFixed(2)); // Return the result rounded to 2 decimal places
};

export const invoiceStatusChartFilterCondition = {
  paid: (d) => d.payment_status === PAID,
  differed: (d) => d.invoice_status === DIFFERED,
  overdue: (d) => d.invoice_status === OVERDUE,
  pending: (d) =>
    d.payment_status === PENDING && d.invoice_status === SUBMITTED,
};

export const checkIsFiltersApplied = (filters) =>
  Object.values(filters).some(
    (value) =>
      value !== null &&
      value !== undefined &&
      (Array.isArray(value) ? value.length > 0 : true) &&
      value !== ''
  );

export const changeToCompanyName = (url) => {
  const user_info = JSON.parse(localStorage.getItem('user_info'));

  const user_role = user_info?.role_name;
  const company_name = user_info?.company_name;

  return user_role !== GLOBAL_ADMIN
    ? url?.replace(':company_name', company_name?.replaceAll(' ', '-'))
    : url?.replace(':company_name/', '');
};

export const getFormattedCompanyName = () => {
  const userInfo = JSON.parse(localStorage.getItem('user_info'));

  return userInfo?.company_name?.replaceAll(' ', '-') ?? '';
};

export const isPathSelected = (basePath, menuPath, includeBasePath = true) => {
  const fullPath = includeBasePath
    ? `/${basePath}/${menuPath}`
    : `/${menuPath}`;

  return location.pathname.startsWith(fullPath);
};

export const getRedirectURL = () => {
  const user_info = JSON.parse(localStorage.getItem('user_info') || '{}');
  const user_role = user_info?.role_name;
  const company_name = user_info?.company_name?.replaceAll(' ', '-');

  const BASE_COMPANY_ROUTE =
    user_role !== GLOBAL_ADMIN ? `/${company_name}` : '';

  return {
    [SUPER_ADMIN]: {
      [CHART_CONST.PROPERTIES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
      [CHART_CONST.INVOICES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}`,
      [CHART_CONST.QUOTES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.DEFECTS_QUOTES}`,
      [CHART_CONST.SERVICE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.QUOTES_SERVICES}`,
      [CHART_CONST.WORK_ORDERS]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}`,
      [CHART_CONST.SCHEDULE_BOARD]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.SCHEDULING}/${ROUTENAME.SCHEDULE_BOARD}`,
      [CHART_CONST.PURCHASE_ORDER]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.PURCHASE_ORDER}`,
      [CHART_CONST.PROPERTY_COMPLIANCE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
      [CHART_CONST.DEFECTS]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.DEFECTS}`,
      [ROUTE_NAVIGATE_CONST.PROPERTIES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
      [ROUTE_NAVIGATE_CONST.TEMPLATES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.SETTINGS}/${ROUTENAME.TEMPLATES}`,
      [ROUTE_NAVIGATE_CONST.REPORTING]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.REPORTS}/${ROUTENAME.SUPER_ADMIN_REPORTING}`,
      [ROUTE_NAVIGATE_CONST.TECHNICIAN_PERFORMANCE_LIST]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.REPORTS}/${ROUTENAME.SUPER_ADMIN_REPORTING}/${ROUTENAME.TECHNICIAN_PERFORMANCE}`,
      [ROUTE_NAVIGATE_CONST.TECHNICIAN_PERFORMANCE_CHARTS]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.REPORTS}/${ROUTENAME.SUPER_ADMIN_REPORTING}/${ROUTENAME.TECHNICIAN_PERFORMANCE}/${ROUTENAME.CHARTS}`,
      [ROUTE_NAVIGATE_CONST.INVOICE_EDIT]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}/${ROUTENAME.INVOICE_DETAIL}`,
      [ROUTE_NAVIGATE_CONST.INVOICE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_DETAIL]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_ASSET]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.ASSETS}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_FLOOR_PLAN]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_FLOOR_PLAN}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_ROUTINE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_ROUTINE}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_CONTRACT]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_CONTRACT}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_SERVICE_QUOTE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_SERVICE_QUOTE}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_INVOICE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_INVOICES}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_COMING_SOON]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_COMING_SOON}`,
      [ROUTE_NAVIGATE_CONST.WORK_ORDER_DETAIL]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}/${ROUTENAME.WORK_ORDERS_VIEW}`,
      [ROUTE_NAVIGATE_CONST.INTEGRATION]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.SETTINGS}/${ROUTENAME.INTEGRATIONS}`,
      [ROUTE_NAVIGATE_CONST.SUPPLIER]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.MASTER}/${ROUTENAME.SUPPLIERS}`,
      [ROUTE_NAVIGATE_CONST.DASHBOARD]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.DASHBOARD}`,
      [ROUTE_NAVIGATE_CONST.CUSTOMER]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.CUSTOMER}`,
      [ROUTE_NAVIGATE_CONST.CUSTOMER_BILLING]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.BILLING_INFORMATION}`,
      [ROUTE_NAVIGATE_CONST.BILLING_CONTRACTS]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.BILLING_CONTRACTS}`,
      [ROUTE_NAVIGATE_CONST.DATA_MANAGEMENT]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.REPORTS}/${ROUTENAME.DATA_MANAGEMENT}`,
      [ROUTE_NAVIGATE_CONST.IMPORT_DATA]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.REPORTS}/${ROUTENAME.DATA_MANAGEMENT}/${ROUTENAME.IMPORT_DATA}`,
      [ROUTE_NAVIGATE_CONST.PRODUCT_CATALOGUE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.MASTER}/${ROUTENAME.PRODUCT_CATALOGUE}`,
      [ROUTE_NAVIGATE_CONST.TECHNICIAN_LIST]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.USERS}/${ROUTENAME.TECHNICIAN}`,
      [ROUTE_NAVIGATE_CONST.SERVICE_QUOTES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.QUOTES_SERVICES}`,
      [ROUTE_NAVIGATE_CONST.CONFIGURATION]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.SETTINGS}/${ROUTENAME.CONFIGURATION}`,
      [ROUTE_NAVIGATE_CONST.CONTACT]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.MASTER}/${ROUTENAME.CONTACTS}`,
      [ROUTE_NAVIGATE_CONST.CONTACT_DETAIL]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.MASTER}/${ROUTENAME.CONTACTS}/${ROUTENAME.CONTACT_DETAIL}`,
      [ROUTE_NAVIGATE_CONST.NOT_FOUND_404]: `/${ROUTENAME.NOT_FOUND}`,
    },
    [GLOBAL_ADMIN]: {
      [CHART_CONST.FIRE_COMPANY]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.FIRE_COMPANY}/${ROUTENAME.COMPANIES}`,
      [CHART_CONST.USER_MANAGEMENT]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.USERS_MANAGEMENT}`,
      [CHART_CONST.USERS]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.USERS_MANAGEMENT}`,
      [ROUTE_NAVIGATE_CONST.PROPERTIES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.PROPERTIES}`,
      [CHART_CONST.CUSTOMER_PROPERTIES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.PROPERTIES}`,
      [ROUTE_NAVIGATE_CONST.CONTROL_PANEL]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CONTROL_PANEL}`,
      [ROUTE_NAVIGATE_CONST.TEMPLATE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CONTROL_PANEL}/${ROUTENAME.TEMPLATE}`,
      [ROUTE_NAVIGATE_CONST.WORK_ORDER_DETAIL]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}/${ROUTENAME.WORK_ORDERS_VIEW}`,
      [CHART_CONST.SCHEDULE_BOARD]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.SCHEDULING}/${ROUTENAME.SCHEDULE_BOARD}`,
      [ROUTE_NAVIGATE_CONST.DATA_MANAGEMENT]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.DATA_MANAGEMENT}`,
      [ROUTE_NAVIGATE_CONST.GLOBAL_ADMIN_INTEGRATIONS]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.GLOBAL_ADMIN_INTEGRATIONS}`,
      [ROUTE_NAVIGATE_CONST.DASHBOARD]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.DASHBOARD}`,
      [ROUTE_NAVIGATE_CONST.NOT_FOUND_404]: `/${ROUTENAME.NOT_FOUND}`,
    },
    [OFFICE_STAFF]: {
      [CHART_CONST.PROPERTIES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
      [CHART_CONST.INVOICES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}`,
      [CHART_CONST.QUOTES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.DEFECTS_QUOTES}`,
      [CHART_CONST.WORK_ORDERS]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}`,
      [CHART_CONST.PURCHASE_ORDER]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.PURCHASE_ORDER}`,
      [CHART_CONST.PROPERTY_COMPLIANCE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
      [CHART_CONST.DEFECTS]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.DEFECTS}`,
      [ROUTE_NAVIGATE_CONST.PROPERTIES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
      [ROUTE_NAVIGATE_CONST.TECHNICIAN_PERFORMANCE_LIST]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.REPORTS}/${ROUTENAME.SUPER_ADMIN_REPORTING}/${ROUTENAME.TECHNICIAN_PERFORMANCE}`,
      [ROUTE_NAVIGATE_CONST.TECHNICIAN_PERFORMANCE_CHARTS]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.REPORTS}/${ROUTENAME.SUPER_ADMIN_REPORTING}/${ROUTENAME.TECHNICIAN_PERFORMANCE}/${ROUTENAME.CHARTS}`,
      [ROUTE_NAVIGATE_CONST.INVOICE_EDIT]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}/${ROUTENAME.INVOICE_DETAIL}`,
      [ROUTE_NAVIGATE_CONST.INVOICE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_FLOOR_PLAN]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_FLOOR_PLAN}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_ROUTINE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_ROUTINE}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_CONTRACT]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_CONTRACT}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_SERVICE_QUOTE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_SERVICE_QUOTE}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_INVOICE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_INVOICES}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_COMING_SOON]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_COMING_SOON}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_ASSET]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.ASSETS}`,
      [ROUTE_NAVIGATE_CONST.WORK_ORDER_DETAIL]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}/${ROUTENAME.WORK_ORDERS_VIEW}`,
      [ROUTE_NAVIGATE_CONST.SUPPLIER]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.MASTER}/${ROUTENAME.SUPPLIERS}`,
      [ROUTE_NAVIGATE_CONST.DASHBOARD]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.DASHBOARD}`,
      [ROUTE_NAVIGATE_CONST.CUSTOMER]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.CUSTOMER}`,
      [ROUTE_NAVIGATE_CONST.CUSTOMER_BILLING]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.BILLING_INFORMATION}`,
      [ROUTE_NAVIGATE_CONST.DATA_MANAGEMENT]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.REPORTS}/${ROUTENAME.DATA_MANAGEMENT}`,
      [ROUTE_NAVIGATE_CONST.PRODUCT_CATALOGUE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.MASTER}/${ROUTENAME.PRODUCT_CATALOGUE}`,
      [ROUTE_NAVIGATE_CONST.TECHNICIAN_LIST]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.USERS}/${ROUTENAME.TECHNICIAN}`,
      [ROUTE_NAVIGATE_CONST.CONTACT]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.MASTER}/${ROUTENAME.CONTACTS}`,
      [ROUTE_NAVIGATE_CONST.CONTACT_DETAIL]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.MASTER}/${ROUTENAME.CONTACTS}/${ROUTENAME.CONTACT_DETAIL}`,
      [ROUTE_NAVIGATE_CONST.NOT_FOUND_404]: `/${ROUTENAME.NOT_FOUND}`,
    },
    [OFFICE_MANAGEMENT]: {
      [CHART_CONST.PROPERTIES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
      [CHART_CONST.INVOICES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}`,
      [CHART_CONST.QUOTES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.DEFECTS_QUOTES}`,
      [CHART_CONST.WORK_ORDERS]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}`,
      [CHART_CONST.PURCHASE_ORDER]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.PURCHASE_ORDER}`,
      [CHART_CONST.PROPERTY_COMPLIANCE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
      [CHART_CONST.DEFECTS]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.DEFECTS}`,
      [ROUTE_NAVIGATE_CONST.PROPERTIES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
      [ROUTE_NAVIGATE_CONST.REPORTING]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.REPORTS}/${ROUTENAME.SUPER_ADMIN_REPORTING}`,
      [ROUTE_NAVIGATE_CONST.TECHNICIAN_PERFORMANCE_LIST]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.REPORTS}/${ROUTENAME.SUPER_ADMIN_REPORTING}/${ROUTENAME.TECHNICIAN_PERFORMANCE}`,
      [ROUTE_NAVIGATE_CONST.TECHNICIAN_PERFORMANCE_CHARTS]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.REPORTS}/${ROUTENAME.SUPER_ADMIN_REPORTING}/${ROUTENAME.TECHNICIAN_PERFORMANCE}/${ROUTENAME.CHARTS}`,
      [ROUTE_NAVIGATE_CONST.INVOICE_EDIT]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}/${ROUTENAME.INVOICE_DETAIL}`,
      [ROUTE_NAVIGATE_CONST.INVOICE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_FLOOR_PLAN]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_FLOOR_PLAN}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_ROUTINE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_ROUTINE}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_CONTRACT]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_CONTRACT}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_SERVICE_QUOTE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_SERVICE_QUOTE}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_INVOICE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_INVOICES}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_COMING_SOON]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_COMING_SOON}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_ASSET]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.ASSETS}`,
      [ROUTE_NAVIGATE_CONST.WORK_ORDER_DETAIL]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}/${ROUTENAME.WORK_ORDERS_VIEW}`,
      [ROUTE_NAVIGATE_CONST.SUPPLIER]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.MASTER}/${ROUTENAME.SUPPLIERS}`,
      [ROUTE_NAVIGATE_CONST.DASHBOARD]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.DASHBOARD}`,
      [CHART_CONST.SCHEDULE_BOARD]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.SCHEDULING}/${ROUTENAME.SCHEDULE_BOARD}`,
      [ROUTE_NAVIGATE_CONST.DATA_MANAGEMENT]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.REPORTS}/${ROUTENAME.DATA_MANAGEMENT}`,
      [ROUTE_NAVIGATE_CONST.PRODUCT_CATALOGUE]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.MASTER}/${ROUTENAME.PRODUCT_CATALOGUE}`,
      [ROUTE_NAVIGATE_CONST.TECHNICIAN_LIST]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.USERS}/${ROUTENAME.TECHNICIAN}`,
      [ROUTE_NAVIGATE_CONST.CONTACT]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.MASTER}/${ROUTENAME.CONTACTS}`,
      [ROUTE_NAVIGATE_CONST.CONTACT_DETAIL]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.MASTER}/${ROUTENAME.CONTACTS}/${ROUTENAME.CONTACT_DETAIL}`,
      [ROUTE_NAVIGATE_CONST.NOT_FOUND_404]: `/${ROUTENAME.NOT_FOUND}`,
    },
    [CUSTOMER]: {
      [ROUTE_NAVIGATE_CONST.PROPERTIES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.DASHBOARD}/${ROUTENAME.PROPERTIES}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_ASSET]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.DASHBOARD}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.ASSETS}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_FLOOR_PLAN]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.DASHBOARD}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_FLOOR_PLAN}`,
      [ROUTE_NAVIGATE_CONST.PROPERTY_COMING_SOON]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.DASHBOARD}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_COMING_SOON}`,
      [ROUTE_NAVIGATE_CONST.DASHBOARD]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.DASHBOARD}/${ROUTENAME.PROPERTIES}`,
      [ROUTE_NAVIGATE_CONST.QUOTES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.DASHBOARD}/${ROUTENAME.QUOTES}`,
      [ROUTE_NAVIGATE_CONST.QUOTES_STATUS]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.DASHBOARD}/${ROUTENAME.QUOTES}/${ROUTENAME.QUOTES_STATUS}`,
      [ROUTE_NAVIGATE_CONST.INVOICES]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.DASHBOARD}/${ROUTENAME.INVOICES}`,
      [ROUTE_NAVIGATE_CONST.INVOICES_STATUS]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.DASHBOARD}/${ROUTENAME.INVOICES}/${ROUTENAME.INVOICES_STATUS}`,
      [ROUTE_NAVIGATE_CONST.DEFECTS]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.DASHBOARD}/${ROUTENAME.DEFECTS}`,
      [ROUTE_NAVIGATE_CONST.DEFECTS_STATUS]: `${BASE_COMPANY_ROUTE}/${ROUTENAME.DASHBOARD}/${ROUTENAME.DEFECTS}/${ROUTENAME.DEFECT_STATUS}`,
      [ROUTE_NAVIGATE_CONST.NOT_FOUND_404]: `/${ROUTENAME.NOT_FOUND}`,
    },
  };
};

export const formatAddress = (...rest) => [...rest].filter(Boolean).join(', ');
