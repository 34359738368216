import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { Box, Divider, styled, Typography } from '@mui/material';

import { DIVIDER_COLOR, SECONDARY } from '../../../constants/Colors';
import {
  CHART_CONST,
  DATE_FORMAT_API,
  DRAFT_STATUS,
  PURCHASE_ORDER_STATUS,
  TAX_NAME,
  TAX_VALUE,
  TEMPLATE_SET,
} from '../../../constants/Constants';
import {
  SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH,
  SWIPEABLE_DRAWER_INVOICE_BILL_WIDTH,
  SWIPEABLE_DRAWER_WIDTH,
} from '../../../constants/Typography';
import useBrowserBackButtonHandler from '../../../hooks/useBrowserBackButtonHandler';
import useDetailPageValidation from '../../../hooks/useDetailPageValidation';
import usePreviewAndDownloadTemplate from '../../../hooks/usePreviewAndDownloadTemplate';
import { snackbarToggle } from '../../../store/CommonReducer';
import { editCompany } from '../../../store/company/api';
import {
  addPurchaseOrderInvoiceBill,
  createPurchaseOrderProduct,
  deletePurchaseOrderProduct,
  getPurchaseOrderInvoiceBill,
  getPurchaseOrderInvoiceBillList,
  getPurchaseOrders,
  updatePurchaseOrder,
  updatePurchaseOrderProduct,
} from '../../../store/purchaseOrders/api';
import {
  resetAddPurchaseOrderInvoiceBill,
  resetCreatePurchaseOrderProduct,
  resetPurchaseOrderInvoiceBillList,
  resetUpdatePurchaseOrder,
} from '../../../store/purchaseOrders/reducer';
import { editSupplier } from '../../../store/supplier/api';
import { resetEdit } from '../../../store/supplier/reducer';
import { getWorkOrderQuotation } from '../../../store/workOrder/api';
import { resetDefectQuotes } from '../../../store/workOrder/reducer';
import {
  calculateTotal,
  formatDateAPI,
  formatDateForAPI,
  formatStatus,
  formattedDate,
  getRedirectURL,
  loggedInUserDetail,
  showHideTopbarLoader,
} from '../../../utils';
import { Delete, Edit, View } from '../../CommonComponents/ActionComponent';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import MainWrapper from '../../CommonComponents/MainWrapper';
import { ConfirmationModal } from '../../CommonComponents/Modal';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import TabView from '../../CommonComponents/TabView';
import { AddInvoiceBill } from './AddInvoiceBill';
import AddPurchaseOrder from './AddPurchaseOrder';
import AddPurchaseOrderProduct from './AddPurchaseOrderProduct';
import EditPurchaseOrderProduct from './EditPurchaseOrderProduct';
import { PreviewPurchaseOrderInvoiceBill } from './PreviewPurchaseOrderInvoiceBill';

const defaultValues = {
  equipment_type: null,
  product_type: null,
  search: '',
  invoice_date: null,
  invoice_number: '',
};

// Styles
const StyledHeader = styled(Box)({
  padding: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'rgba(235, 235, 235, 0.2)',
});

const StyledContentBox = styled(Box)(() => ({
  display: 'flex',
  gap: '24px',
  padding: '16px 0 16px 16px',
  width: '50%',
}));

const StyledColumnBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

const PurchaseOrderDetail = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { po_uuid: uuid } = useParams();
  const outletProps = useOutletContext();
  const {
    woId,
    workOrderId,
    nextStepTitle,
    setOpenEditPurchaseDetail,
    setStepTitle,
    isFromSideBar,
    setRefresh = () => {},
    setIsInitialLoading = () => {},
  } = { ...props, ...outletProps };

  const navigate = useNavigate();

  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [titleConfirmationModal, setTitleConfirmationModal] = useState(
    t('confirmationModal.title')
  );
  const [descriptionConfirmationModal, setDescriptionConfirmationModal] =
    useState(t('confirmationModal.description'));
  const [hasFormValues, sethasFormValues] = useState();
  const [markPOStatus, setMarkPOStatus] = useState(false);
  const [pOStatus, setPOStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState(null);
  const [selectedProductsError, setSelectedProductsError] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [editProductDetail, setEditProductDetail] = useState(null);
  const [editProductFormOpen, setEditProductFormOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deletePurchaseOrderDetail, setDeletePurchaseOrderDetail] = useState(
    {}
  );
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [addInvoiceBillOpen, setAddInvoiceBillOpen] = useState(false);
  const [addProductFromQuote, setAddProductFromQuote] = useState(false);
  const [pOInvoiceBillViewOpen, setPOInvoiceBillViewOpen] = useState(false);
  const [purchaseOrderEdit, setPurchaseOrderEdit] = useState(false);

  const {
    isLoading: purchaseOrderDetailLoading,
    error: purchaseOrderDetailError,
    purchaseOrderDetail,
  } = useSelector((state) => state.purchaseOrders.updatePurchaseOrder);

  const { isLoading: isLoadingCompany, company } = useSelector(
    (state) => state.company.edit
  );

  const { purchaseOrdersList, isLoading: purchaseOrderListLoading } =
    useSelector((state) => state.purchaseOrders.get);

  const {
    isLoading: createdPurchaseOrderProductLoading,
    createdPurchaseOrderProduct,
  } = useSelector((state) => state.purchaseOrders.createProduct);

  const { isLoading: updatePurchaseOrderLoading } = useSelector(
    (state) => state.purchaseOrders.updatePurchaseOrder
  );

  const {
    isLoading: updatePurchaseOrderProductLoading,
    updatePurchaseOrderProductData,
  } = useSelector((state) => state.purchaseOrders.updatePurchaseOrderProduct);

  const {
    isLoading: purchaseOrderInvoiceBillLoading,
    purchaseOrderInvoiceBillData,
  } = useSelector(
    (state) => state.purchaseOrders.addNewPurchaseOrderInvoiceBill
  );

  const { purchaseOrdersInvoiceBillList } = useSelector(
    (state) => state.purchaseOrders.getPurchaseOrderInvoiceBillList
  );

  const { isLoading: pOIBDLoading, purchaseOrdersInvoiceBillDetail } =
    useSelector(
      (state) => state.purchaseOrders.getPurchaseOrderInvoiceBillDetail
    );

  const {
    isLoading: deletePurchaseOrderProductLoading,
    deletePurchaseOrderProductData,
  } = useSelector((state) => state.purchaseOrders.deletePurchaseOrderProduct);

  const { isLoading: supplierDetailLoading, supplier } = useSelector(
    (state) => state.supplier.edit
  );

  const { defectQuotesList } = useSelector(
    (state) => state.workOrder.defectQuotes
  );

  const {
    isLoading: purchaseOrderInvoiceBill_new_loading,
    error: purchaseOrderInvoiceBill_new_error,
  } = useSelector(
    (state) => state.purchaseOrders.addNewPurchaseOrderInvoiceBill
  );

  const user = loggedInUserDetail();

  const { handleSubmit, control, watch, reset, setValue, trigger } = useForm({
    defaultValues: defaultValues,
  });

  useBrowserBackButtonHandler({
    hasUnsavedChanges: hasFormValues,
    setOpenConfirmationModal,
    open:
      openAddProduct ||
      editProductDetail ||
      editProductFormOpen ||
      addInvoiceBillOpen ||
      purchaseOrderEdit,
    watchMethod: watch,
    sethasFormValues,
  });

  const { listTemplateLoading, generatePDF, PreviewTemplatePDF } =
    usePreviewAndDownloadTemplate({
      module: TEMPLATE_SET.PURCHASE_ORDER,
      data: {
        company_data: company,
        purchaseOrderDetails: purchaseOrderDetails,
        isLoadingCompany: isLoadingCompany || purchaseOrderDetailLoading,
      },
    });

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  useEffect(() => {
    const detail = purchaseOrdersList?.find((item) =>
      nextStepTitle ? item.poid === nextStepTitle.step : item.uuid === uuid
    );

    setPurchaseOrderDetails(detail || []);
    setIsLoading(false);
  }, [purchaseOrdersList, uuid]);

  useEffect(() => {
    if (!createdPurchaseOrderProductLoading) {
      if (Object.keys(createdPurchaseOrderProduct).length > 0) {
        setOpenAddProduct(false);
        setDisableSave(false);

        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: t(
              'attributes.purchase_order.purchaseOrderAddProductSuccessMsg'
            ),
          })
        );
      }
    }
  }, [createdPurchaseOrderProductLoading, createdPurchaseOrderProduct]);

  useEffect(() => {
    if (
      createdPurchaseOrderProduct ||
      updatePurchaseOrderProductData ||
      deletePurchaseOrderProductData ||
      purchaseOrderDetail
    ) {
      setIsLoading(true);
      dispatch(
        getPurchaseOrders({
          work_order_uuid: workOrderId || woId,
          limit: -1,
        })
      );
    }
  }, [
    createdPurchaseOrderProduct,
    updatePurchaseOrderProductData,
    deletePurchaseOrderProductData,
    purchaseOrderDetail,
  ]);

  useEffect(() => {
    if (
      [
        PURCHASE_ORDER_STATUS.SUBMITTED,
        PURCHASE_ORDER_STATUS.PAID,
        PURCHASE_ORDER_STATUS.DELIVERED,
      ].includes(purchaseOrderDetails?.status)
    ) {
      dispatch(
        getPurchaseOrderInvoiceBillList({
          purchase_order_uuid: purchaseOrderDetails?.uuid,
        })
      );
    }
  }, [purchaseOrderDetails, purchaseOrderInvoiceBillData]);

  useEffect(() => {
    if (!updatePurchaseOrderProductLoading) {
      if (updatePurchaseOrderProductData?.data?.length > 0) {
        setEditProductFormOpen(false);
        setDisableSave(false);

        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: updatePurchaseOrderProductData?.message,
          })
        );
      }
    }
  }, [updatePurchaseOrderProductLoading, updatePurchaseOrderProductData]);

  useEffect(() => {
    if (purchaseOrderInvoiceBill_new_error) {
      setDisableSave(false);
      dispatch(
        snackbarToggle({
          isOpen: true,
          isErrorMsg: true,
          msg: purchaseOrderInvoiceBill_new_error?.message,
        })
      );
    }
  }, [
    purchaseOrderInvoiceBill_new_loading,
    purchaseOrderInvoiceBill_new_error,
  ]);

  // add purchase order invoice bill
  useEffect(() => {
    showHideTopbarLoader(purchaseOrderInvoiceBillLoading, dispatch);

    if (!purchaseOrderInvoiceBillLoading) {
      if (purchaseOrderInvoiceBillData?.data?.length > 0) {
        setAddInvoiceBillOpen(false);
        setDisableSave(false);

        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: purchaseOrderInvoiceBillData?.message,
          })
        );
      }
    }
  }, [purchaseOrderInvoiceBillLoading, purchaseOrderInvoiceBillData]);

  useEffect(() => {
    if (!deletePurchaseOrderProductLoading) {
      if (deletePurchaseOrderProductData) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: deletePurchaseOrderProductData?.message,
          })
        );
      }
    }
  }, [deletePurchaseOrderProductLoading, deletePurchaseOrderProductData]);

  useEffect(() => {
    if (!purchaseOrderDetailLoading) {
      if (purchaseOrderDetail) {
        setOpen(false);
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: purchaseOrderDetail.message,
          })
        );
      }
    }
  }, [purchaseOrderDetailLoading, purchaseOrderDetailError]);

  useEffect(
    () => () => {
      dispatch(resetCreatePurchaseOrderProduct());
      dispatch(resetDefectQuotes());
      dispatch(resetPurchaseOrderInvoiceBillList());
      dispatch(resetAddPurchaseOrderInvoiceBill());
      dispatch(resetEdit());
    },
    []
  );

  useEffect(() => {
    if (workOrderId || woId || purchaseOrderDetails?.work_order?.uuid) {
      dispatch(
        getWorkOrderQuotation({
          id: workOrderId || woId || purchaseOrderDetails?.work_order?.uuid,
        })
      );
    }
  }, [addProductFromQuote, purchaseOrderDetails]);

  useEffect(() => {
    if (purchaseOrderDetails) {
      dispatch(editSupplier(purchaseOrderDetails?.supplier?.uuid));
    }
  }, [purchaseOrderDetails]);

  useDetailPageValidation({
    is_error: false,
    data: purchaseOrderDetails,
    is_loading: isLoading,
  });

  // Calculate subtotal, tax, and total
  const total = purchaseOrderDetails?.items?.reduce(
    (acc, item) => acc + Number(item?.price) * item?.qty,
    0
  );

  const productsIdFromQuotes = defectQuotesList?.quotation_items?.map(
    (item) => ({
      uuid: item?.product_catalogue?.uuid,
      qty: item?.qty,
    })
  );

  if (isLoading)
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          minHeight: 'calc(100vh - 150px)',
        }}
      >
        <CustomCircularLoader />
      </Box>
    );

  const columns = [
    {
      field: 'product',
      headerName: t('attributes.purchase_order.product'),
      flex: 1, // 40% width
      sortable: false,
    },
    {
      field: 'description',
      headerName: t('attributes.description'),
      flex: 2, // 10% width
      sortable: false,
    },
    {
      field: 'qty',
      headerName: t('attributes.quantity'),
      flex: 'auto',
      align: 'right',
      sortable: false,
    },
    {
      field: 'unit_price',
      headerName: t('attributes.unit_price'),
      flex: 'auto',
      align: 'center',
      sortable: false,
    },
    {
      field: 'total',
      headerName: t('attributes.total'),
      flex: 'auto',
      align: 'center',
      sortable: false,
    },
    ...(purchaseOrderDetails?.status === PURCHASE_ORDER_STATUS.DRAFT
      ? [
          {
            field: 'actions',
            headerName: t('attributes.actions'),
            flex: 'auto',
            sortable: false,
            renderCell: ({ row }) => (
              <Box sx={{ display: 'flex', gap: '8px' }}>
                <Edit
                  onClick={() => {
                    setEditProductDetail(row);
                    setEditProductFormOpen(true);
                  }}
                />
                <Delete
                  onClick={() => {
                    setDeleteConfirmation(true);
                    setDeletePurchaseOrderDetail({
                      purchase_order_id: row?.uuid,
                      purchase_order_item_uuid: row?.product_uuid,
                    });
                  }}
                />
              </Box>
            ),
          },
        ]
      : []),
  ];

  const invoiceBillColumns = [
    {
      field: 'invoice_number',
      headerName: t('attributes.purchase_order.invoice_number'),
      flex: 5, // 40% width
      sortable: false,
    },
    {
      field: 'invoice_date',
      headerName: t('attributes.purchase_order.invoice_date'),
      flex: 1, // 10% width
      sortable: false,
      renderCell: ({ row }) => formattedDate(row.invoice_date),
    },
    {
      field: 'status',
      headerName: t('attributes.status'),
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => formatStatus(row.status),
    },
    {
      field: 'action',
      headerName: t('attributes.action'),
      flex: 0.5,
      sortable: false,
      renderCell: ({ row }) => (
        <View
          onClick={() => {
            dispatch(
              getPurchaseOrderInvoiceBill({
                purchase_order_bill_uuid: row.uuid,
              })
            );
            setPOInvoiceBillViewOpen(true);
          }}
        />
      ),
    },
  ];

  const invoiceBillRows = purchaseOrdersInvoiceBillList?.map((item, index) => ({
    invoice_number: item.invoice_number,
    status: item.status,
    ...item,
  }));

  const rows = purchaseOrderDetails?.items?.map((item, index) => ({
    id: index,
    product: item.product_catalogue?.product_name,
    description: item.product_catalogue?.description || '-',
    qty: item.qty,
    unit_price: Number(item?.price)?.toFixed(2),
    total: (Number(item?.price) * item.qty)?.toFixed(2),
    product_catalogue_uuid: item.product_catalogue_uuid,
    product_catalogue_type: item.product_catalogue_type,
    uuid: purchaseOrderDetails.uuid,
    product_uuid: item.uuid,
  }));

  const onSubmit = (formData) => {
    setDisableSave(true);

    if (!formData.selectedProducts || formData.selectedProducts.length === 0) {
      setSelectedProductsError(
        addProductFromQuote
          ? `${t('common.select_at_least_one_product')}!`
          : `${t('common.select_at_least_one_product')} ${t('attributes.purchase_order.to_add_in_purchase_order_line_items')}`
      );
      setDisableSave(false);

      return;
    }

    const productRequests = formData.selectedProducts.map((product) => ({
      product_catalogue_uuid: product.uuid,
      product_name: product.product_name,
      product_description: product.product_description,
      qty: product.qty,
      price: parseFloat(product.cost)?.toFixed(2),
      currency: product.sell_currency,
      um: product.um,
      product_catalogue_type: product.product_catalogue_type,
    }));

    dispatch(
      createPurchaseOrderProduct({
        purchase_order_id: purchaseOrderDetails?.uuid,
        data: { data: productRequests },
      })
    );
  };

  const handleUpdatePurchaseOrderStatus = (status = null) => {
    const request = {
      creation_date: formatDateAPI(
        purchaseOrderDetails?.creation_date,
        DATE_FORMAT_API
      ),
      supplier_uuid: purchaseOrderDetails.supplier?.uuid,
      work_order_uuid:
        workOrderId || woId
          ? workOrderId || woId
          : purchaseOrderDetails?.work_order?.uuid,
      description: purchaseOrderDetails?.description,
      delivery_instruction: purchaseOrderDetails?.delivery_instruction,
    };

    dispatch(
      updatePurchaseOrder({
        purchase_order_id: purchaseOrderDetails?.uuid,
        data: {
          ...request,
          status: status || purchaseOrderDetails?.status,
        },
      })
    ).then(() => {
      setOpenPreview(false);
    });
  };

  const onSubmitEditProduct = async (formData) => {
    setDisableSave(true);
    setEditProductDetail(null);
    const createData = {
      product_name: formData.product_name,
      qty: formData.qty,
      price: formData.sell_price,
      product_catalogue_uuid: formData?.product_catalogue_uuid,
      product_catalogue_type: formData.product_catalogue_type,
    };

    dispatch(
      updatePurchaseOrderProduct({
        purchase_order_id: formData?.uuid,
        purchase_order_item_uuid: formData?.product_uuid,
        data: createData,
      })
    );
  };

  const onUpdatePurchaseOrder = (formData) => {
    const request = {
      creation_date: formatDateForAPI(formData?.creation_date),
      supplier_uuid: formData.supplier?.value,
      work_order_uuid: workOrderId
        ? workOrderId
        : purchaseOrderDetails?.work_order?.uuid,
      description: formData?.description,
      delivery_instruction: formData?.delivery_instruction,
    };

    dispatch(
      updatePurchaseOrder({
        purchase_order_id: formData?.uuid,
        data: { ...request, status: formData?.status },
      })
    );
  };

  const onAddInvoiceBill = (formData) => {
    setDisableSave(true);

    if (!formData.selectedProducts || formData.selectedProducts.length === 0) {
      setSelectedProductsError(`${t('common.select_at_least_one_product')}!`);
      setDisableSave(false);

      return;
    }

    const prepInvoiceData = {
      invoice_number: formData.invoice_number,
      invoice_date: formatDateForAPI(formData.invoice_date),
      status: 'open',
      mark_all_goods_received: false,
      purchase_order_uuid: formData?.selectedProducts?.[0]?.uuid,
      reference_billing_card_uuid:
        purchaseOrderDetails?.supplier?.billing_card_uuid,
      items: formData.selectedProducts.map((item) => ({
        purchase_order_line_item_uuid: item.product_uuid,
        quantity_to_bill: item.qty,
        product_name: item.product,
        description: item.description,
        unit_price: item.unit_price,
        total_quantity: item.total_qty,
      })),
    };

    dispatch(addPurchaseOrderInvoiceBill({ data: prepInvoiceData }));
  };

  const productsDetailView = (
    <>
      {/* Table */}
      <Box sx={{ padding: '8px 16px' }}>
        <Box
          sx={{
            border: `1px solid ${DIVIDER_COLOR}`,
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <CustomGridTable
            columns={columns}
            rows={rows}
            total={rows?.length}
            checkboxSelection={false}
            paginationRequired={false}
            filterHeight={
              ![
                PURCHASE_ORDER_STATUS.SUBMITTED,
                PURCHASE_ORDER_STATUS.PAID,
                PURCHASE_ORDER_STATUS.DELIVERED,
              ].includes(purchaseOrderDetails?.status)
                ? 654
                : 596
            }
            disableColumnMenu={true}
            isLoading={false}
            isTableView={true}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              py: 2,
              pr: 1,
              gap: 1,
              border: `1px solid ${DIVIDER_COLOR}`,
            }}
          >
            <Typography variant="body1">
              {t('common.capital.subtotal')}:
            </Typography>
            <Typography variant="body2">
              {calculateTotal({ total: total, type: 'subtotal' })}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              py: 2,
              pr: 1,
              gap: 1,
              border: `1px solid ${DIVIDER_COLOR}`,
            }}
          >
            <Typography variant="body1">
              {company?.tax_type && company?.tax_value
                ? `${company.tax_type} ${company.tax_value}%`
                : `${TAX_NAME} ${TAX_VALUE}%`}
              :
            </Typography>
            <Typography variant="body2">
              {calculateTotal({
                total: total,
                type: 'gst',
                ...(company?.tax_value && {
                  taxPercentage: company?.tax_value,
                }),
              })}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              py: 2,
              pr: 1,
              gap: 1,
              backgroundColor: 'rgba(235, 235, 235, 0.2)',
              border: `1px solid ${DIVIDER_COLOR}`,
            }}
          >
            <Typography variant="body1">{t('common.total')}:</Typography>
            <Typography variant="body1">
              {calculateTotal({
                total: total,
                type: 'total',
                ...(company?.tax_value && {
                  taxPercentage: company?.tax_value,
                }),
              })}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />

      {/* Footer button */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          padding: '8px 16px 8px 16px',
          gap: '8px',
        }}
      >
        {purchaseOrderDetails?.status === DRAFT_STATUS && (
          <CustomButton
            text={t('attributes.create')}
            startIcon={<AddIcon />}
            disabled={purchaseOrderDetails?.items.length < 1}
            onClick={() =>
              handleUpdatePurchaseOrderStatus(PURCHASE_ORDER_STATUS.CREATED)
            }
          />
        )}
        {[
          PURCHASE_ORDER_STATUS.CREATED,
          PURCHASE_ORDER_STATUS.SUBMITTED,
          PURCHASE_ORDER_STATUS.DRAFT_STATUS,
          PURCHASE_ORDER_STATUS.PAID,
          PURCHASE_ORDER_STATUS.DELIVERED,
        ].includes(purchaseOrderDetails?.status) && (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <CustomButton
              text={t('attributes.preview')}
              startIcon={<RemoveRedEyeOutlinedIcon />}
              color="inherit"
              onClick={() => {
                setOpenPreview(true);
                if (user.company_uuid) {
                  dispatch(editCompany(user?.company_uuid));
                }
              }}
            />
            {purchaseOrderDetails?.status === PURCHASE_ORDER_STATUS.CREATED && (
              <CustomButton
                text={t('common.submit')}
                startIcon={<SendOutlinedIcon />}
                disabled={updatePurchaseOrderLoading}
                onClick={() =>
                  handleUpdatePurchaseOrderStatus(
                    PURCHASE_ORDER_STATUS.SUBMITTED
                  )
                }
              />
            )}
          </Box>
        )}
      </Box>
    </>
  );

  const invoiceDetailView = (
    <>
      <Box sx={{ padding: '16px' }}>
        <Box
          sx={{
            border: `1px solid ${DIVIDER_COLOR}`,
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0px 8px 0px 0px',
              height: '40px',
              borderBottom: `1px solid ${DIVIDER_COLOR}`,
            }}
          >
            <CustomButton
              text={t('attributes.addBill')}
              color="secondary"
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => {
                setAddInvoiceBillOpen(true);
              }}
            />
          </Box>
          <CustomGridTable
            columns={invoiceBillColumns}
            rows={invoiceBillRows}
            total={invoiceBillRows?.length}
            checkboxSelection={false}
            paginationRequired={false}
            filterHeight={476}
            disableColumnMenu={true}
            isLoading={false}
            isTableView={true}
          />
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '8px',
          gap: '8px',
        }}
      >
        <CustomButton
          text={t('attributes.markAsDelivered')}
          startIcon={<CheckCircleOutlinedIcon />}
          color="primary"
          disabled={
            purchaseOrderDetails?.status === PURCHASE_ORDER_STATUS.DELIVERED ||
            purchaseOrderDetails?.status === PURCHASE_ORDER_STATUS.PAID
          }
          onClick={() => {
            setOpenConfirmationModal(true);
            setTitleConfirmationModal(
              t(
                'confirmationModal.poInvoiceBill.markAsDeliveredConfirmation.title'
              )
            );
            setDescriptionConfirmationModal(
              t(
                'confirmationModal.poInvoiceBill.markAsDeliveredConfirmation.description'
              )
            );

            setMarkPOStatus(true);
            setPOStatus(PURCHASE_ORDER_STATUS.DELIVERED);
            // handleUpdatePurchaseOrderStatus(PURCHASE_ORDER_STATUS.DELIVERED);
          }}
        />
        <CustomButton
          text={t('attributes.markAsPaid')}
          startIcon={<CheckCircleOutlinedIcon />}
          color="primary"
          disabled={purchaseOrderDetails?.status === PURCHASE_ORDER_STATUS.PAID}
          onClick={() => {
            const allItemsPaid =
              Array.isArray(purchaseOrdersInvoiceBillList) &&
              purchaseOrdersInvoiceBillList?.length > 0 &&
              purchaseOrdersInvoiceBillList.every(
                (item) => item.status === PURCHASE_ORDER_STATUS.PAID
              );

            if (allItemsPaid) {
              setTitleConfirmationModal(
                t(
                  'confirmationModal.poInvoiceBill.markAsPaidConfirmation.title'
                )
              );
              setDescriptionConfirmationModal(
                t(
                  'confirmationModal.poInvoiceBill.markAsPaidConfirmation.description'
                )
              );

              setMarkPOStatus(true);
              setPOStatus(PURCHASE_ORDER_STATUS.PAID);
              setOpenConfirmationModal(true);
            } else {
              dispatch(
                snackbarToggle({
                  isOpen: true,
                  isErrorMsg: true,
                  msg: t(
                    'confirmationModal.poInvoiceBill.markAsPaidAlert.description'
                  ),
                })
              );
              setMarkPOStatus(false);
              setPOStatus('');
            }
          }}
        />
      </Box>
    </>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={titleConfirmationModal}
          description={descriptionConfirmationModal}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpenAddProduct(false);
            setOpenConfirmationModal(false);
            setEditProductFormOpen(false);
            setOpen(false);
            setAddInvoiceBillOpen(false);
            setEditProductDetail(null);
            setTitleConfirmationModal(t('confirmationModal.title'));
            setDescriptionConfirmationModal(t('confirmationModal.description'));
            if (markPOStatus) {
              handleUpdatePurchaseOrderStatus(pOStatus);
            }
            sethasFormValues(false);

            if (window.history.state === null) {
              window.history.back();
            }
          }}
          onClose={() => {
            setTitleConfirmationModal(t('confirmationModal.title'));
            setDescriptionConfirmationModal(t('confirmationModal.description'));
            setMarkPOStatus(false);
            setPOStatus('');
          }}
        />
      )}
      {deleteConfirmation && (
        <ConfirmationModal
          title={t('deleteConfirmationModal.title')}
          description={t('deleteConfirmationModal.description')}
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onConfirm={() => {
            dispatch(
              deletePurchaseOrderProduct({
                purchase_order_id: deletePurchaseOrderDetail?.purchase_order_id,
                purchase_order_item_uuid:
                  deletePurchaseOrderDetail?.purchase_order_item_uuid,
              })
            );
            setDeleteConfirmation(false);
          }}
        />
      )}
      <Box sx={{ backgroundColor: DIVIDER_COLOR }}>
        {isFromSideBar && (
          <MainWrapper
            defaultPadding="0px 0px 16px 0px"
            title={t('attributes.work_order.purchase_order')}
            variant="body1"
            isStep={true}
            step={
              nextStepTitle ? nextStepTitle?.step : purchaseOrderDetails?.poid
            }
            handleDefaultStep={() => {
              setStepTitle
                ? setStepTitle()
                : navigate(
                    getRedirectURL()[user?.role_name][
                      CHART_CONST.PURCHASE_ORDER
                    ]
                  );

              dispatch(resetUpdatePurchaseOrder());
              setRefresh((prev) => !prev);
              setIsInitialLoading(true);
            }}
          ></MainWrapper>
        )}
      </Box>
      {isLoading ||
      updatePurchaseOrderLoading ||
      listTemplateLoading ||
      supplierDetailLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            display: 'flex',
            paddingTop: '200px',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isLoading &&
        !updatePurchaseOrderLoading &&
        !listTemplateLoading &&
        !purchaseOrderDetailLoading && (
          <>
            <Box sx={{ borderRadius: '6px 6px 0 0' }}>
              <Box>
                {(purchaseOrderDetails?.status ===
                  PURCHASE_ORDER_STATUS.DRAFT ||
                  purchaseOrderDetails?.status ===
                    PURCHASE_ORDER_STATUS.CREATED) && (
                  <Box
                    sx={{
                      padding: '12px 16px 12px 16px',
                      display: 'flex',
                      gap: '8px',
                    }}
                  >
                    {purchaseOrderDetails?.status ===
                      PURCHASE_ORDER_STATUS.DRAFT && (
                      <>
                        {rows?.length === 0 && (
                          <CustomButton
                            text={t('attributes.purchase_order.add_from_quote')}
                            color="secondary"
                            sx={{ height: '52%' }}
                            startIcon={<AddCircleOutlineOutlinedIcon />}
                            onClick={() => {
                              setOpenAddProduct(true);
                              reset(defaultValues);
                              setSelectedProductsError(null);
                              setAddProductFromQuote(true);
                            }}
                          />
                        )}
                        <CustomButton
                          text={t('attributes.purchase_order.add_product')}
                          color="secondary"
                          sx={{ height: '52%' }}
                          startIcon={<AddCircleOutlineOutlinedIcon />}
                          onClick={() => {
                            setOpenAddProduct(true);
                            setAddProductFromQuote(false);
                            reset(defaultValues);
                            setSelectedProductsError(null);
                          }}
                        />
                        {/* <CustomButton
                        text="Delete"
                        color="secondary"
                        sx={{ height: '52%' }}
                        startIcon={<DeleteOutlineOutlinedIcon />}
                        onClick={() => ({})}
                      /> */}
                      </>
                    )}
                    {purchaseOrderDetails?.status ===
                      PURCHASE_ORDER_STATUS.CREATED && (
                      <CustomButton
                        text={t('attributes.set_as_draft')}
                        color="secondary"
                        sx={{ height: '52%' }}
                        startIcon={<EditNoteOutlinedIcon />}
                        onClick={() =>
                          handleUpdatePurchaseOrderStatus(
                            PURCHASE_ORDER_STATUS.DRAFT
                          )
                        }
                      />
                    )}
                  </Box>
                )}

                <Divider />
                <StyledHeader>
                  <Typography variant="body1">
                    {t('attributes.purchase_order.purchase_order_details')}
                  </Typography>
                  {purchaseOrderDetails?.status ===
                    PURCHASE_ORDER_STATUS.DRAFT && (
                    <ModeEditOutlineOutlinedIcon
                      onClick={() =>
                        setOpenEditPurchaseDetail
                          ? (setOpenEditPurchaseDetail(true),
                            setPurchaseOrderEdit(true),
                            sethasFormValues(true))
                          : (setOpen(true),
                            setPurchaseOrderEdit(true),
                            sethasFormValues(true))
                      }
                    />
                  )}
                </StyledHeader>
                <Divider />
                <Box sx={{ display: 'flex', width: '100%' }}>
                  <StyledContentBox>
                    <StyledColumnBox>
                      {/* Details section */}
                      {[
                        {
                          label: t(
                            'attributes.purchase_order.purchase_order_id'
                          ),
                          value: purchaseOrderDetails?.poid,
                        },
                        {
                          label: t('attributes.purchase_order.status'),
                          value: formatStatus(purchaseOrderDetails?.status),
                        },
                        {
                          label: t('attributes.purchase_order.created_on'),
                          value: formattedDate(
                            purchaseOrderDetails?.creation_date
                          ),
                        },
                      ].map(({ label, value }) => (
                        <Box
                          sx={{ display: 'flex', alignItems: 'center' }}
                          key={label}
                        >
                          <Typography minWidth="160px" variant="body1">
                            {label}:
                          </Typography>
                          <Typography variant="body2">{value}</Typography>
                        </Box>
                      ))}
                    </StyledColumnBox>
                  </StyledContentBox>
                  <StyledContentBox>
                    <StyledColumnBox>
                      {/* Additional details section */}
                      {[
                        {
                          label: t('attributes.purchase_order.supplier'),
                          value: purchaseOrderDetails?.supplier?.name,
                        },
                        {
                          label: t('attributes.description'),
                          value: purchaseOrderDetails?.description || '-',
                        },
                        {
                          label: t(
                            'attributes.purchase_order.delivery_instruction'
                          ),
                          value:
                            purchaseOrderDetails?.delivery_instruction || '-',
                        },
                      ].map(({ label, value }) => (
                        <Box
                          sx={{ display: 'flex', alignItems: 'center' }}
                          key={label}
                        >
                          <Typography minWidth="200px" variant="body1">
                            {label}:
                          </Typography>
                          <Typography variant="body2">{value}</Typography>
                        </Box>
                      ))}
                    </StyledColumnBox>
                  </StyledContentBox>
                </Box>
                <Divider />
              </Box>
            </Box>
            <Box
              sx={{
                bgcolor: DIVIDER_COLOR,
                minHeight: '520px',
                padding: '16px 0px 0px 0px',
              }}
            >
              <Box
                sx={{ bgcolor: SECONDARY, height: '100%', borderRadius: '6px' }}
              >
                <TabView
                  tabs={[
                    { label: 'Products', component: productsDetailView },
                    ...(purchaseOrderDetails?.status ===
                      PURCHASE_ORDER_STATUS.SUBMITTED ||
                    purchaseOrderDetails?.status ===
                      PURCHASE_ORDER_STATUS.PAID ||
                    purchaseOrderDetails?.status ===
                      PURCHASE_ORDER_STATUS.DELIVERED
                      ? [
                          {
                            label: 'Invoice Bills',
                            component: invoiceDetailView,
                          },
                        ]
                      : []),
                  ]}
                  subComponentPadding={false}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </Box>
            </Box>
          </>
        )
      )}
      {openAddProduct && (
        <SwipeableDrawer
          open={openAddProduct}
          title={
            addProductFromQuote
              ? `${t('attributes.purchase_order.add_from_quote')} Q-${defectQuotesList?.id || '-'}`
              : t('attributes.purchase_order.add_product')
          }
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setOpenAddProduct(false);
          }}
          footerButton={
            <CustomButton
              text={t('attributes.purchase_order.add_to_purchaseOrder')}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={disableSave}
            />
          }
          width={SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH}
        >
          <AddPurchaseOrderProduct
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            selectedProductsError={selectedProductsError}
            setSelectedProductsError={setSelectedProductsError}
            productsIdFromQuotes={productsIdFromQuotes}
            isFromDefectQuote={addProductFromQuote}
          />
        </SwipeableDrawer>
      )}
      {openPreview && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={openPreview}
          title={purchaseOrderDetails?.poid}
          onClose={() => {
            setOpenPreview(false);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.download')}
              color="inherit"
              disabled={isLoadingCompany}
              startIcon={<DownloadOutlinedIcon />}
              onClick={() => {
                generatePDF();
              }}
            />,
            purchaseOrderDetails?.status == PURCHASE_ORDER_STATUS.CREATED ? (
              <CustomButton
                text={t('common.submit')}
                disabled={updatePurchaseOrderLoading}
                startIcon={<SendOutlinedIcon />}
                onClick={() =>
                  handleUpdatePurchaseOrderStatus(
                    PURCHASE_ORDER_STATUS.SUBMITTED
                  )
                }
              />
            ) : (
              purchaseOrderDetails?.status == PURCHASE_ORDER_STATUS.DRAFT && (
                <CustomButton
                  text={t('attributes.create')}
                  disabled={updatePurchaseOrderLoading}
                  startIcon={<AddIcon />}
                  onClick={() =>
                    handleUpdatePurchaseOrderStatus(
                      PURCHASE_ORDER_STATUS.CREATED
                    )
                  }
                />
              )
            ),
          ]}
          width={653}
        >
          {PreviewTemplatePDF}
        </SwipeableDrawer>
      )}
      {editProductDetail && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={editProductFormOpen}
          title={`${editProductDetail?.product}`}
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setEditProductFormOpen(false) && setEditProductDetail(null);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.save')}
              disabled={updatePurchaseOrderLoading}
              startIcon={<SaveOutlinedIcon />}
              onClick={() => {
                handleSubmit(onSubmitEditProduct)();
              }}
            />,
          ]}
          width={653}
        >
          <EditPurchaseOrderProduct
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            data={editProductDetail}
          />
        </SwipeableDrawer>
      )}
      {open && (
        <SwipeableDrawer
          open={open}
          title={t('attributes.purchase_order.edit_purchase_order')}
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          footerButton={
            <CustomButton
              startIcon={<SaveOutlinedIcon />}
              text={t('attributes.save')}
              onClick={handleSubmit(onUpdatePurchaseOrder)}
              disabled={purchaseOrderDetailLoading}
            />
          }
          width={SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH}
        >
          <AddPurchaseOrder
            control={control}
            setValue={setValue}
            trigger={trigger}
            reset={reset}
            nextStepTitle={{ step: purchaseOrderDetails?.poid }}
          />
        </SwipeableDrawer>
      )}
      {addInvoiceBillOpen && (
        <SwipeableDrawer
          open={addInvoiceBillOpen}
          title={t('attributes.add_bill')}
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setAddInvoiceBillOpen(false);
          }}
          footerButton={
            <CustomButton
              startIcon={<AddCircleOutlineOutlinedIcon />}
              text={t('attributes.add')}
              onClick={handleSubmit(onAddInvoiceBill)}
              disabled={disableSave}
            />
          }
          width={SWIPEABLE_DRAWER_INVOICE_BILL_WIDTH}
        >
          <AddInvoiceBill
            control={control}
            setValue={setValue}
            trigger={trigger}
            reset={reset}
            selectedProductsError={selectedProductsError}
            setSelectedProductsError={setSelectedProductsError}
            purchaseOrderDetails={purchaseOrderDetails}
          />
        </SwipeableDrawer>
      )}
      {pOInvoiceBillViewOpen && (
        <SwipeableDrawer
          bgColor={DIVIDER_COLOR}
          open={pOInvoiceBillViewOpen}
          title={t('attributes.purchase_order.view_bill')}
          onClose={() => {
            setPOInvoiceBillViewOpen(false);
          }}
          width={SWIPEABLE_DRAWER_WIDTH}
        >
          <PreviewPurchaseOrderInvoiceBill
            isLoading={pOIBDLoading}
            invoiceBilldata={purchaseOrdersInvoiceBillDetail}
            providerUid={purchaseOrderDetails?.supplier?.billing_card_uuid}
            supplier={supplier}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default PurchaseOrderDetail;
