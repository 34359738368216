import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../assets/commonStyled';
import { Validation } from '../../constants/FieldValidationMsg';
import CustomTextField from '../CommonComponents/CustomTextField';

const EditDefectQuotesProduct = ({
  control,
  watch,
  reset,
  trigger,
  setValue,
  data,
  isFromServiceQuotes,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const clearHandler = (name) => setValue(name, '');

  useEffect(() => {
    if (data) {
      reset({
        product_name: data.product_catalogue.product_name,
        qty: data.qty,
        sell_price: data.sell_price,
        description: data.description || '',
      });
    }
  }, [data]);

  useEffect(() => {
    setValue('uuid', data.uuid);
  }, [data, setValue]);

  return (
    <FormWrapper>
      <CustomCard>
        <FormFieldWrapper component="form">
          <Controller
            name="product_name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CustomTextField
                label={t('attributes.productCatalogue.product_name')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('product_name');
                }}
                disabled={true}
                helperText={error ? error.message : ''}
                error={error}
              />
            )}
          />
          {isFromServiceQuotes && (
            <Controller
              name="description"
              control={control}
              rules={{
                required: `${t('attributes.description')} ${Validation.general.required}`,
                maxLength: {
                  value: 225,
                  message: Validation.general.title,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.description')}
                  fullWidth
                  rows={3}
                  multiline
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
          )}
          <Controller
            name="qty"
            control={control}
            rules={{
              required: `${t('attributes.quantity')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.quantity')}
                fullWidth
                value={value}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, '');

                  onChange(numericValue);
                  trigger('qty');
                }}
                onBlur={() => {
                  onChange(value?.trim());
                  onBlur();
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Controller
            name="sell_price"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.sell_price')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.productCatalogue.sell_price')}
                fullWidth
                value={value}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, '');

                  onChange(numericValue);
                  trigger('sell_price');
                }}
                onBlur={() => {
                  onChange(value?.trim());
                  onBlur();
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
    </FormWrapper>
  );
};

export default EditDefectQuotesProduct;
