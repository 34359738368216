import { createSlice } from '@reduxjs/toolkit';

import { createCity, getCityList, updateCity } from './api';

const initialData = {
  error: false,
  loading: false,
  data: null,
};

const citySlice = createSlice({
  name: 'city',
  initialState: {
    getCityList: { ...initialData },
    updateCity: { ...initialData },
    createCity: { ...initialData },
  },
  reducers: {
    resetGetCityList: (state, action) => {
      state.getCityList = { ...initialData };
    },
    resetUpdateCity: (state, action) => {
      state.updateCity = { ...initialData };
    },
    resetCreateCity: (state, action) => {
      state.createCity = { ...initialData };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCityList.pending, (state) => {
        state.getCityList.loading = true;
      })
      .addCase(getCityList.fulfilled, (state, action) => {
        state.getCityList.loading = false;
        state.getCityList.error = false;
        state.getCityList.data = action.payload?.data[0];
      })
      .addCase(getCityList.rejected, (state, action) => {
        state.getCityList.loading = false;
        state.getCityList.error = action.payload;
      })
      .addCase(updateCity.pending, (state) => {
        state.updateCity.loading = true;
      })
      .addCase(updateCity.fulfilled, (state, action) => {
        state.updateCity.loading = false;
        state.updateCity.error = false;
        state.updateCity.data = action.payload;
      })
      .addCase(updateCity.rejected, (state, action) => {
        state.updateCity.loading = false;
        state.updateCity.error = action.payload;
      })
      .addCase(createCity.pending, (state) => {
        state.createCity.loading = true;
      })
      .addCase(createCity.fulfilled, (state, action) => {
        state.createCity.loading = false;
        state.createCity.error = false;
        state.createCity.data = action.payload;
      })
      .addCase(createCity.rejected, (state, action) => {
        state.createCity.loading = false;
        state.createCity.error = action.payload;
      });
  },
});

export default citySlice.reducer;
export const { resetCreateCity, resetGetCityList, resetUpdateCity } =
  citySlice.actions; // Export the action
