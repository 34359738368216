import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import {
  Box,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';

import { theme } from '../../../assets/theme';
import {
  SCROLLBAR_THUMB_BACKGROUND,
  SCROLLBAR_TRACK_BACKGROUND,
  SCROLLBAR_TRACK_BOXSHADOW,
  SECONDARY,
} from '../../../constants/Colors';
import {
  CUSTOMER_TYPE,
  DRAFT_STATUS,
  INVITATION_PENDING,
  INVITED_STATUS,
  TAB_STATUS,
} from '../../../constants/Constants';
import { GLOBAL_ADMIN, SUPER_ADMIN } from '../../../constants/Roles';
import getDropdownListHook from '../../../hooks/getDropdownListHook';
import getStatesHook from '../../../hooks/getStatesHook';
import { getAccountManagerDropdownList } from '../../../store/accountManager/api';
import {
  resetDocumentData,
  snackbarToggle,
} from '../../../store/CommonReducer';
import { resetCreate } from '../../../store/company/reducer';
import { getContactDropdownList } from '../../../store/contacts/api';
import { resetCreate as resetContactCreate } from '../../../store/contacts/reducer';
import {
  createCustomer,
  getCustomerById,
  updateCustomer,
} from '../../../store/users/customer/api';
import { resetEdit, resetUpdate } from '../../../store/users/customer/reducer';
import {
  datePickerFormat,
  findLabelByValue,
  formatDateAPI,
  loggedInUserRole,
} from '../../../utils';
import CreateContactModal from '../../CommonComponents/CreateContactModal';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import AccountManager from './FormSteps/AccountManager';
import CustomerDetails from './FormSteps/CustomerDetails';
import InviteTheCustomer from './FormSteps/InviteTheCustomer';

const LayoutContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  height: '100%',
}));

const Section = styled(Box)(({ theme }) => ({
  display: 'flex',
  background: theme.palette.secondary.main,
  flexDirection: 'column',
  padding: '16px',
  borderRadius: '4px',
  gap: '16px',
}));

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderColor: theme.palette.grey,
    borderWidth: 2,
    borderLeftStyle: 'dotted',
  },
}));

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: ownerState.active ? theme.palette.primary.main : 'white',
  color: ownerState.active ? 'white' : 'black',
  zIndex: 1,
  width: 26,
  height: 26,
  border: ownerState.active ? '' : '1px solid black',
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.completed && {
    backgroundColor: 'black',
    color: 'white',
  }),
}));

const FormWrapper = styled(Box)(() => ({
  maxHeight: '84vh',
  overflowX: 'hidden',
  overflowY: 'auto',
  width: '100%',
  // scrollbarWidth: 'none',
  /* Scoped scrollbar styles for WebKit browsers */
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: SCROLLBAR_TRACK_BACKGROUND, // Fixed: Proper string format
    boxShadow: SCROLLBAR_TRACK_BOXSHADOW,
    borderRadius: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: SCROLLBAR_THUMB_BACKGROUND,
    borderRadius: '4px',
    width: '4px',
  },
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomerForm = ({
  customerId,
  formMode,
  sethasFormValues,
  setServerErrors,
  serverErrors,
  setMoveToBillingCard,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    trigger,
    reset,
    watch,
    setValue,
    getValues,
    clearErrors,
    setError,
    resetField,
  } = useFormContext();

  const watchedFields = watch();

  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isNextReady, setIsNextReady] = useState(true);
  const [open, setOpen] = useState(false);

  const watchedValues = useWatch({ control });

  const { isLoading: createdCustomerLoading } = useSelector(
    (state) => state.customer.create
  );
  const { isLoading, editCustomer } = useSelector(
    (state) => state.customer.edit
  );
  const { isLoading: updateCustomerLoading } = useSelector(
    (state) => state.customer.update
  );
  const { userManagerDropdownData } = getDropdownListHook({
    reducerName: 'userManagement',
    dropdownListName: 'get',
    labelName: 'user_name',
    fetchDataFrom: 'usersList',
    customNameForDropdown: 'userManager',
    valueName: 'uuid',
  });

  const { contactDropdownData } = getDropdownListHook({
    reducerName: 'contact',
    dropdownListName: 'getDropdown',
    labelName: 'name',
    valueName: 'uuid',
    address: 'address',
    address_line_2: 'address_line_2',
    email: 'email',
    city: 'city',
    state: 'state',
    postcode: 'postcode',
  });

  const steps = [
    `${t('attributes.customer.customer')} ${t('attributes.company.details')} `,
    t('attributes.account_manager.account_manager'),
    ...(editCustomer?.invitation_status === INVITED_STATUS
      ? []
      : ['Invite The Customer']),
  ];

  // Function to check if any field has a value
  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  useEffect(() => {
    if (serverErrors?.length > 0) {
      serverErrors.forEach(({ field }) => {
        clearErrors(field); // Clear error for this field when it changes
      });
    }
  }, [watchedValues]);

  const stateList = getStatesHook();

  useEffect(() => {
    if (loggedInUserRole() !== GLOBAL_ADMIN) {
      const req = {
        limit: -1,
        active: true,
      };

      dispatch(getAccountManagerDropdownList(req));
    }
    if (customerId) {
      dispatch(getCustomerById(customerId));
    }

    return () => {
      dispatch(resetCreate());
      dispatch(resetUpdate());
      dispatch(resetEdit());
      dispatch(resetDocumentData());
      dispatch(resetContactCreate());
    };
  }, []);

  const accountManagerById = (id) => {
    const foundItem = userManagerDropdownData.find((item) => item.value === id);

    return foundItem
      ? { label: foundItem.label, value: foundItem.value }
      : { label: '', value: '' };
  };

  useEffect(() => {
    if (formMode === 'create') {
      reset({
        customer_name: '',
        abnNumber: '',
        customer_type: null,
        agreementSignedDate: null,
        notes: '',
        contact_name: '',
        contact_email: '',
        contact_phone: '',
        address: '',
        contactCity: null,
        contactState: null,
        contactPostCode: '',
        agreementDocument: {},
        otherDocument: {},
        organization: '',
        billing_contact_name: '',
        billingEmailTo: '',
        accountManager: null,
        customer_contact: null,
      });
    }
  }, [formMode]);

  useEffect(() => {
    if (!isLoading && editCustomer && customerId) {
      reset({
        customer_name: editCustomer?.customer_name,
        abnNumber: editCustomer?.customer_abn_number,
        customer_type: findLabelByValue(
          CUSTOMER_TYPE,
          editCustomer?.customer_type
        ),
        agreementSignedDate: editCustomer?.agreement_signed_date
          ? datePickerFormat(editCustomer?.agreement_signed_date)
          : null,
        notes: editCustomer?.notes || '',
        active: editCustomer.status === 'active' ? true : false,

        contact_name: editCustomer?.primary_contact?.contact_name,
        contact_email: editCustomer?.primary_contact?.contact_email,
        contact_phone: editCustomer?.primary_contact?.contact_phone,
        agreementDocument:
          editCustomer?.documents.find(
            (d) => d.document_type === 'agreement'
          ) || {},
        otherDocument:
          editCustomer?.documents.find((d) => d.document_type === 'other') ||
          {},

        billing_contact_name: editCustomer?.billing?.billing_contact_name || '',
        billingEmailTo: editCustomer?.billing?.email_to || '',
        sameAsPrimary:
          editCustomer?.billing?.billing_contact_name &&
          editCustomer?.billing?.email_to &&
          editCustomer?.billing?.billing_contact_name ===
            editCustomer?.primary_contact?.contact_name &&
          editCustomer?.billing?.email_to ===
            editCustomer?.primary_contact?.contact_email,

        accountManager: accountManagerById(
          editCustomer.account_manager_uuid?.[0]
        ),
        customer_contact: findLabelByValue(
          contactDropdownData,
          editCustomer?.contact_uuid
        ),
      });
    }
  }, [isLoading, editCustomer]);

  useEffect(() => {
    dispatch(getContactDropdownList());
  }, []);

  const stepIcons = [
    <AccountBoxOutlinedIcon
      key="icon-01"
      sx={{ height: '15px', width: '15px' }}
    />,
    <BadgeOutlinedIcon key="icon-03" sx={{ height: '15px', width: '15px' }} />,
    <MarkEmailReadOutlinedIcon
      key="icon-04"
      sx={{ height: '15px', width: '15px' }}
    />,
  ];

  const CustomStepIcon = ({ icon, active, completed, className }) => (
    <CustomStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {stepIcons[icon - 1]}
    </CustomStepIconRoot>
  );

  const handleNext = async () => {
    setIsNextReady(false);
    const isValid = await trigger(); // Trigger validation for all fields in the current step

    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setIsNextReady(true);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmit = (submitType, moveToBilling) => async (data) => {
    setIsSubmitting(true);

    let documentsCollection = !isEmpty(data?.agreementDocument)
      ? [data?.agreementDocument]
      : [];

    if (!isEmpty(data?.otherDocument)) {
      documentsCollection = [...documentsCollection, data?.otherDocument];
    }

    const oldDocuments = editCustomer?.documents;
    const newDocumentId = documentsCollection?.map((d) => d?.document_uuid);

    const deletedDocuments = oldDocuments
      ?.filter((dc) => !newDocumentId.includes(dc?.document_uuid))
      ?.map((dd) => ({ ...dd, is_deleted: true }));

    const customerDetailsData = {
      customer_name: data?.customer_name,
      contact_name: data?.customer_contact.label,
      customer_abn_number: data?.abnNumber,
      customer_type: data?.customer_type?.value,
      agreement_signed_date: data?.agreementSignedDate
        ? formatDateAPI(data.agreementSignedDate)
        : null,
      contact_uuid: data?.customer_contact?.value,
      address: data?.customer_contact?.address,
      address_line_2: data?.customer_contact?.address_line_2 || '',
      state: data?.customer_contact?.state,
      city: data?.customer_contact?.city,
      postcode: data?.customer_contact?.postcode,
      email: data?.customer_contact?.email,
      notes: data?.notes || '',
      ...(data.active !== undefined && {
        status: data.active ? TAB_STATUS.active : TAB_STATUS.inactive,
      }),
      documents:
        deletedDocuments?.length > 0
          ? [...documentsCollection, ...deletedDocuments]
          : documentsCollection,
    };

    const accountManagerData = data?.accountManager?.value
      ? [data.accountManager.value]
      : [];

    const pageDataMapping = {
      0: customerDetailsData,
      1: {
        ...customerDetailsData,
        account_manager_uuid: accountManagerData,
      },
      2: {
        ...customerDetailsData,
        account_manager_uuid: accountManagerData,
      },
    };

    const req = {
      ...pageDataMapping[activeStep],
      invitation_status: submitType,
    };

    dispatch(
      customerId
        ? updateCustomer({ uuid: customerId, req: req })
        : createCustomer(req)
    )
      .then((res) => {
        setIsSubmitting(false);
        if (res.payload?.errorDetails && res.payload.errorDetails?.length > 0) {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: 'Something went wrong! Please check the data you entered or try again later.',
            })
          );
          setMoveToBillingCard(false);
        } else {
          setMoveToBillingCard(moveToBilling);
        }
      })
      .catch((error) => setIsSubmitting(false));
  };

  const handleStepClick = async (index) => {
    const isValid = await trigger(); // Optional: Validate the current step

    if (isValid) {
      setActiveStep(index); // Set the step index directly
    }
  };

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <LayoutContainer>
      {/* Steps UI */}
      <Box
        sx={{
          position: 'block',
          width: '259px',
          padding: '16px',
          background: SECONDARY,
          marginRight: '16px',
          borderRadius: '4px',
          height: '84vh',
        }}
      >
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          connector={<CustomConnector />}
        >
          {steps.map((label, idx) => (
            <Step
              key={label}
              onClick={
                formMode === 'edit' ? () => handleStepClick(idx) : undefined
              }
              sx={{ cursor: formMode === 'edit' ? 'pointer' : 'default' }}
            >
              <StepLabel StepIconComponent={CustomStepIcon}>
                <Typography
                  variant={idx <= activeStep ? 'body2' : 'body2'}
                  sx={{
                    color:
                      idx === activeStep ? theme.palette.primary.main : 'black',
                    cursor: formMode === 'edit' ? 'pointer' : 'default',
                  }}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <FormWrapper component="form" onSubmit={handleSubmit(onSubmit)}>
        {activeStep === 0 && (
          <CustomerDetails
            title={steps[0]}
            control={control}
            setValue={setValue}
            setError={setError}
            trigger={trigger}
            stateList={stateList}
            Section={Section}
            watch={watch}
            customerId={customerId}
            resetField={resetField}
            formMode={formMode}
            setServerErrors={setServerErrors}
            clearErrors={clearErrors}
            setOpen={setOpen}
          />
        )}
        {activeStep === 1 && (
          <AccountManager
            title={steps[1]}
            control={control}
            trigger={trigger}
            Section={Section}
          />
        )}
        {activeStep === 2 && (
          <InviteTheCustomer
            title={steps[2]}
            control={control}
            trigger={trigger}
            setValue={setValue}
            Section={Section}
            watch={watch}
          />
        )}
      </FormWrapper>

      {/* Form footer */}
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          height: '54px',
          width: '100%',
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center',
          backgroundColor: SECONDARY,
          padding: '16px',
          gap: 2,
        }}
      >
        {activeStep !== 0 && (
          <CustomButton
            startIcon={<KeyboardArrowLeftIcon />}
            text={t('attributes.previous')}
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
          />
        )}
        {formMode === 'create' && (
          <CustomButton
            startIcon={<SaveOutlinedIcon />}
            text={t('common.save_as_draft')}
            color="inherit"
            onClick={() =>
              handleSubmit(
                onSubmit(
                  activeStep === steps.length - 1
                    ? INVITATION_PENDING
                    : DRAFT_STATUS,
                  false
                )
              )()
            }
            disabled={
              createdCustomerLoading || updateCustomerLoading || isSubmitting
            }
          />
        )}

        {activeStep === steps.length - 1 ? (
          <CustomButton
            startIcon={
              editCustomer?.invitation_status !== INVITED_STATUS ? (
                <MarkEmailReadOutlinedIcon />
              ) : (
                <SaveOutlinedIcon />
              )
            }
            text={
              editCustomer?.invitation_status !== INVITED_STATUS
                ? t('common.send_the_invite')
                : t('attributes.save')
            }
            onClick={() => handleSubmit(onSubmit(INVITED_STATUS, false))()}
            type="submit"
            disabled={
              createdCustomerLoading || updateCustomerLoading || isSubmitting
            }
          />
        ) : (
          <CustomButton
            endIcon={<ChevronRightIcon />}
            text={t('common.next')}
            onClick={handleNext}
            disabled={!isNextReady}
          />
        )}
        {activeStep === steps.length - 1 && formMode === 'create' && (
          <CustomButton
            startIcon={<SaveOutlinedIcon />}
            text={t('attributes.customer.saveBilling')}
            onClick={() => handleSubmit(onSubmit(INVITED_STATUS, true))()}
            type="submit"
            disabled={
              createdCustomerLoading || updateCustomerLoading || isSubmitting
            }
          />
        )}
      </Box>

      {loggedInUserRole() === SUPER_ADMIN && (
        <CreateContactModal
          open={open}
          setOpen={setOpen}
          state={watch('state')}
          setServerErrors={setServerErrors}
          serverErrors={serverErrors}
        />
      )}
    </LayoutContainer>
  );
};

export default CustomerForm;
