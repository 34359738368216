import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { isEmpty } from 'lodash';

import { ICON_DEFAULT_COLOR } from '../../../constants/Colors';
import { readCSVFileData } from '../../../constants/common';
import {
  DOCUMENT_TEMPLATE_TYPES,
  FILE_UPLOAD_SIZES,
} from '../../../constants/Constants';
import Autocomplete from '../AutoComplete';
import CustomFileUploadButton from '../CustomFileUploadButton';
import UploadedDocumentView from '../UploadedDocumentView';

const FileSelection = ({
  setActiveStep,
  selectedDocument,
  setSelectedDocument,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { control, setValue, trigger } = useForm();

  const [selectDataType, setSelectDataType] = useState(null);
  const [documentError, setDocumentError] = useState('');
  const [isUploadingDocument, setIsUploadingDocument] = useState(false);

  const handleFileChange = (event, onChange) => {
    const file = event.target.files[0];

    if (!file) {
      setDocumentError(t('message.common.noFileCSV'));

      return;
    }

    const isValidFile = file && file.type === 'text/csv';

    if (file.size > FILE_UPLOAD_SIZES.DOCUMENT) {
      setDocumentError(t('message.customer.uploadDocumentMaxSizeError'));

      return;
    }

    if (isValidFile) {
      onChange(event);
      setIsUploadingDocument(true);
      const fileSize = (file.size / 1024).toFixed(0) + ' KB';

      setSelectedDocument({
        file: file,
        name: file.name,
        size: fileSize,
        fileModule: selectDataType?.value || '',
      });
      readCSVFileData({ file, setSelectedDocument });
      setDocumentError('');
    } else {
      setDocumentError(t('message.common.noFileCSV'));
    }
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100vh - 30vh)',
      }}
    >
      <Box
        component="form"
        sx={{
          width: '100%',
          maxWidth: 500,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 1,
          paddingX: { xs: 2, md: 4 },
          paddingY: { xs: 2, md: 3 },
        }}
      >
        <FactCheckOutlinedIcon
          sx={{ height: 40, width: 40, color: ICON_DEFAULT_COLOR }}
        />
        <Typography variant="body1">
          {t('attributes.reports.importData.selectData')}
        </Typography>
        <Typography variant="body2" align="center" sx={{ fontSize: '12px' }}>
          {
            t('attributes.reports.importData.selectDataDescription').split(
              ' \n '
            )[0]
          }
          <br />
          {
            t('attributes.reports.importData.selectDataDescription').split(
              ' \n '
            )[1]
          }
        </Typography>
        <Controller
          name="importType"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Autocomplete
              label={t('attributes.reports.importData.selectDataType')}
              options={DOCUMENT_TEMPLATE_TYPES || []}
              value={value}
              onChange={(e, newValue) => {
                if (newValue || newValue === null) {
                  onChange(newValue);
                }
                setSelectDataType(newValue);
              }}
              helperText={error ? error.message : ''}
              error={error}
            />
          )}
        />
        {selectDataType && (
          <Controller
            name="importDataDocument"
            control={control}
            rules={{
              required: `${t('attributes.reports.importData.document')}`,
            }}
            render={({
              field: { onChange, name, value },
              fieldState: { error },
            }) => (
              <>
                {isEmpty(value) && (
                  <CustomFileUploadButton
                    name={name}
                    onFileChange={(event) => {
                      handleFileChange(event, onChange);
                      trigger('importDataDocument');
                    }}
                    multiple={false}
                    buttonText={t('attributes.reports.importData.btnText')}
                    accept=".csv"
                    style={{ width: '100%' }}
                    error={!!documentError || error}
                    errorMessage={documentError || error?.message}
                  />
                )}
                {!isEmpty(value) && (
                  <UploadedDocumentView
                    fileName={
                      isUploadingDocument
                        ? selectedDocument?.name
                        : value?.document_name || ''
                    }
                    fileSize={
                      isUploadingDocument
                        ? selectedDocument?.size
                        : value?.document_size || ''
                    }
                    onDelete={() => {
                      setValue('importDataDocument', null);
                      setSelectedDocument(null);
                    }}
                    sx={{ width: '100%' }}
                    isUploadStatusRequired={false}
                  />
                )}
              </>
            )}
          />
        )}
        {selectedDocument && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            endIcon={<ChevronRightOutlinedIcon />}
            sx={{ mt: 2 }}
            disabled={!isUploadingDocument}
          >
            {t('common.next')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default FileSelection;
