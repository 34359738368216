import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { Box, styled } from '@mui/material';

import { DIVIDER_COLOR, SECONDARY } from '../../constants/Colors';
import { ROUTE_NAVIGATE_CONST } from '../../constants/Constants';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import { getPropertyList } from '../../store/property/api';
import { getCustomerDefectCount } from '../../store/users/customer/api';
import {
  capitalizeFirstLetterRemoveUnderscore,
  getMomentDateTime,
  getRedirectURL,
  loggedInUserDetail,
  loggedInUserRole,
} from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import {
  FilterButton,
  RefreshDashboardButton,
  ResetFilterButton,
} from '../CommonComponents/FilterButton';
import MainWrapper from '../CommonComponents/MainWrapper';

//default filters
const defaultFilters = {
  dateRange: getMomentDateTime({
    startDateKey: 'fromDate',
    endDateKey: 'toDate',
    dateType: 'toDate',
  }),
  property: null,
};

const FiltersContainer = styled(Box)({
  borderRadius: '4px',
  backgroundColor: 'white',
  padding: '16px',
  display: 'flex',
  columnGap: '16px',
  rowGap: '16px',
  flexWrap: 'wrap',
});

const DefectDashboardLayout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { status } = useParams();
  const role = loggedInUserRole();

  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [refresh, setRefresh] = useState(false);

  const dispatch = useDispatch();

  const { propertyDropdownLoading, propertyDropdownData } = getDropdownListHook(
    {
      reducerName: 'property',
      dropdownListName: 'propertyList',
      labelName: 'property_name',
      valueName: 'uuid',
      loading: 'loading',
    }
  );

  useEffect(() => {
    let req = {};

    if (!filters.dateRange.fromDate && !filters.dateRange.toDate) {
      req = getMomentDateTime();
    } else {
      req = {
        start_date: filters.dateRange.fromDate,
        end_date: filters.dateRange.toDate,
      };
    }

    if (filters.property) {
      req = { ...req, property_uuid: filters.property.value };
    }

    dispatch(getCustomerDefectCount(req));
  }, [filters, refresh]);

  // For property dropdown
  useEffect(() => {
    const req = {
      limit: -1,
      customerUuid: loggedInUserDetail()?.profile_uuid,
    };

    dispatch(getPropertyList(req));
  }, [dispatch, refresh]);

  const handleResetFilterBtnClick = () => {
    setFilters(defaultFilters);
  };

  return (
    <Box
      sx={{
        bgcolor: status ? SECONDARY : DIVIDER_COLOR,
        height: '100%',
      }}
    >
      <Box sx={{ bgcolor: DIVIDER_COLOR, paddingBottom: '16px' }}>
        <MainWrapper
          defaultPadding="0px"
          title={t('attributes.defects.defects')}
          variant="body1"
          isStep={status ? true : false}
          step={capitalizeFirstLetterRemoveUnderscore(status)
            ?.split(' ')
            ?.join('-')}
          handleDefaultStep={() =>
            navigate(getRedirectURL()[role][ROUTE_NAVIGATE_CONST.DEFECTS])
          }
          btn={
            status
              ? []
              : [
                  <ResetFilterButton
                    onClick={handleResetFilterBtnClick}
                    disabled={
                      !(
                        filters.quoteType ||
                        filters.property ||
                        filters.dateRange.fromDate ||
                        filters.dateRange.toDate
                      )
                    }
                  />,
                  <FilterButton
                    onClick={() => setShowFilterOptions(!showFilterOptions)}
                    isActive={showFilterOptions}
                  />,
                  <RefreshDashboardButton
                    onClick={() => setRefresh(!refresh)}
                  />,
                ]
          }
        ></MainWrapper>
      </Box>
      {showFilterOptions && !status && (
        <Box sx={{ paddingTop: '16px' }}>
          <FiltersContainer>
            <CustomDateRangePicker
              placeholder="Date Range"
              onOkClick={(val) => {
                setFilters((prev) => ({
                  ...prev,
                  dateRange: { fromDate: val[0], toDate: val[1] },
                }));
              }}
              isClearIcon={false}
              fromDate={filters.dateRange?.fromDate}
              toDate={filters.dateRange?.toDate}
            />
            <Autocomplete
              placeholder="Properties"
              isLoadingData={propertyDropdownLoading}
              options={propertyDropdownData}
              value={filters?.property}
              onChange={(e, newVal) => {
                if (newVal || newVal === null) {
                  setFilters((prev) => ({ ...prev, property: newVal }));
                }
              }}
              width="190px"
            />
          </FiltersContainer>
        </Box>
      )}

      <Outlet
        context={{
          dashboardFilter: filters,
          propertyDropdownData: propertyDropdownData,
          refresh: refresh,
        }}
      />
    </Box>
  );
};

export default DefectDashboardLayout;
