import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { ROUTINE } from '../actionPrefix';
import { axiosPropertyUrl } from '../axios';

// Get Inspection Form List
export const getInspectionFormList = createAsyncThunk(
  ROUTINE.GET_INSPECTION_FORM_LIST,
  async (req, { rejectWithValue }) => {
    let url = APIs.INSPECTION_FORM.GET_INSPECTION_FORM_LIST;

    if (req?.equipment_type_id) {
      url = `${APIs.INSPECTION_FORM.GET_INSPECTION_FORM_LIST}?equipment_type_id=${req?.equipment_type_id}`;
    }

    try {
      const response = await axiosPropertyUrl.get(url);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
