import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box } from '@mui/material';
import { debounce } from 'lodash';

import { FlexEnd, FlexStart } from '../../assets/commonStyled';
import { DIVIDER_COLOR } from '../../constants/Colors';
import { exportToCSV, validateAddress } from '../../constants/common';
import { ROUTE_NAVIGATE_CONST } from '../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
import useBrowserBackButtonHandler from '../../hooks/useBrowserBackButtonHandler';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { createBillingCard, getBillingList } from '../../store/billing/api';
import {
  resetBillingCreate,
  resetBillingData,
} from '../../store/billing/reducer';
import { snackbarToggle } from '../../store/CommonReducer';
import { getCustomerList } from '../../store/users/customer/api';
import { resetCreate } from '../../store/users/customer/reducer';
import {
  checkIsFiltersApplied,
  formattedDate,
  getRedirectURL,
  loggedInUserRole,
  showHideTopbarLoader,
} from '../../utils';
import { View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import {
  FilterButton,
  RefreshDashboardButton,
  ResetFilterButton,
} from '../CommonComponents/FilterButton';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import CustomSearch from '../CommonComponents/Search';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import BillingForm from './BillingForm';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

//default filters
const defaultFilters = {
  customer: null,
  accounting_partner: null,
  date: null,
};

export const defaultValues = {
  customer: null,
  billing_name: '',
  abn_number: '',
  customer_contact: null,
};

const Billing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { billing_uuid } = useParams();
  const [searchParams] = useSearchParams();
  const formOpen = searchParams.get('open');

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [filters, setFilters] = useState(defaultFilters);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [customerOptions, setCustomerOptions] = useState(null);

  const [selectedBill, setSelectedBill] = useState(null);

  const [serverErrors, setServerErrors] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [hasFormValues, sethasFormValues] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState(null);
  const [moveToBillingContract, setMoveToBillingContract] = useState(false);

  const { billingList, total, totalCount, isLoading } = useSelector(
    (state) => state?.billing?.getList
  );

  const { isLoading: customerLoading } = useSelector(
    (state) => state.customer.edit
  );

  const {
    error,
    bill,
    isLoading: createLoading,
  } = useSelector((state) => state?.billing?.create);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    watch,
    trigger,
    getValues,
  } = useForm({
    defaultValues: defaultValues,
  });

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  useBrowserBackButtonHandler({
    hasUnsavedChanges: hasFormValues,
    setOpenConfirmationModal,
    open,
    watchMethod: watch,
    sethasFormValues,
  });

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'id',
        headerName: t('attributes.work_order.id'),
        flex: 1,
        sortable: true,
        renderCell: ({ row }) => <>{`CB-${row?.card_id}`}</>,
      },
      {
        field: 'billing_name',
        headerName: t('attributes.name'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'customer',
        headerName: t('attributes.customer.customer'),
        flex: 1,
        sortable: false,
      },

      {
        field: 'contact_name',
        headerName: t('attributes.customer.contact_name'),
        flex: 1,
        sortable: false,
      },
      // {
      //   field: 'email',
      //   headerName: t('attributes.customer.contact_email'),
      //   flex: 1,
      // },
      // {
      //   field: 'phone',
      //   headerName: t('attributes.technician.contact_phone'),
      //   flex: 1,
      // },
      // {
      //   field: 'address',
      //   headerName: t('attributes.address'),
      //   flex: 1,
      //   sortable: false,
      // },
      {
        field: 'created_at',
        headerName: t('attributes.technician.created_at'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formattedDate(row?.created_at),
      },
      {
        field: 'edit',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        renderCell: ({ row }) => (
          <View
            onClick={() => {
              navigate(`${row?.uuid}`);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  const rows = billingList?.map((bill, index) => ({
    ...bill,
    billing_name: bill?.billing_name,
    customer: bill?.customer_name,
    contact_name: bill?.contact_name,
    contact_email: bill?.email,
    contact_phone: bill?.phone,
    address: `${bill?.address} ${bill?.address_line_2 ? ',' + bill?.address_line_2 : ''}`,
    created_at: bill?.created_at,
  }));

  const onSubmit = (moveToContract) => async (value) => {
    const hasError = await validateAddress({
      city: value.city,
      state: value.state,
      postcode: value.post_code,
      address1: value.address,
      address2: value.address_line_2,
      fieldName: {
        addressKey: 'address',
        addressValue: t('attributes.technician.contact_address'),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'postCode',
        postCodeValue: t('attributes.postcode'),
        address1Key: 'address',
        address1Value: t('attributes.addressLine1'),
        address2Key: 'address_line_2',
        address2Value: t('attributes.addressLine2'),
      },
      setServerErrors,
    });

    if (hasError) return;

    const bodyData = {
      customer_uuid: value?.customer?.value,
      billing_name: value?.billing_name,
      contact_name: value?.customer_contact.label,
      contact_uuid: value?.customer_contact?.value,
      abn_number: value?.abn_number?.length ? value?.abn_number : null,
      // email: value?.email,
      // phone: value?.phone,
      // address: value?.address,
      // address_line_2: value.address_line_2 || '',
      // state: value?.state?.value,
      // postcode: value?.post_code,
      // ...(value?.city?.value && { city: value.city.value }),
    };

    dispatch(createBillingCard(bodyData)).finally(() => {
      if (moveToContract) {
        setMoveToBillingContract(true);
      }
    });
  };

  const getBillsList = useCallback(() => {
    setIsDataLoading(true);
    setSelectedRows(null);
    dispatch(
      getBillingList({
        size: perPageData,
        page: currentPage,
        search: searchText,
        limit: 1,
        is_accounting_partner: filters?.accounting_partner?.value,
        customer_uuid: filters?.customer?.value,
        fromDate: filters?.date?.from_date,
        toDate: filters?.date?.to_date,
        order: order,
        orderBy: orderBy,
      })
    ).finally(() => {
      setIsDataLoading(false);
      setIsInitialLoading(false);
    });
  }, [
    perPageData,
    currentPage,
    searchText,
    order,
    orderBy,
    filters?.customer,
    filters?.accounting_partner,
    filters?.date?.from_date,
    filters?.date?.to_date,
  ]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getBillsList, 500), [
    getBillsList,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData]);

  useEffect(() => {
    if (serverErrors) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const resetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  useEffect(() => {
    dispatch(getCustomerList({ limit: -1 })).then((res) => {
      const options = res?.payload?.data?.map((c) => ({
        label: c?.customer_name,
        value: c?.uuid,
      }));

      setCustomerOptions(options);
    });
  }, []);

  useEffect(
    () => () => {
      dispatch(resetBillingData());
      dispatch(resetBillingCreate());
      showHideTopbarLoader(false, dispatch);
    },
    []
  );

  useEffect(() => {
    showHideTopbarLoader(createLoading, dispatch);
    if (!createLoading) {
      if (error) {
        if (error.errorDetails) {
          setServerErrors(error.errorDetails);
        } else {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: error.message,
            })
          );
        }
      }
      if (!error && bill) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: bill?.message,
          })
        );
        setOpen(false);
        if (moveToBillingContract) {
          navigate(
            getRedirectURL()[loggedInUserRole()][
              ROUTE_NAVIGATE_CONST.BILLING_CONTRACTS
            ] + '?open=true'
          );
        } else {
          dispatch(resetCreate());
        }
        getBillsList();
      }
    }
  }, [error, createLoading, moveToBillingContract]);

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  useEffect(() => {
    if (!billing_uuid && formOpen !== null) {
      setOpen(formOpen);
    }
  }, [billing_uuid, formOpen]);

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const noData = (
    <NewNoDataPage
      icon={<CreditCardIcon />}
      title={t('attributes.billingCard.billingInfoText')}
      singularText={t('attributes.billingCard.billingInfoText')}
      filterHeight={filterHeight(isFilterComponentVisible)}
      createBtnText={`${t('attributes.add')} ${t('attributes.new')}`}
    />
  );

  const renderedComponent = (
    <>
      <Box sx={{ padding: '8px' }}>
        <FlexEnd>
          <RefreshDashboardButton
            label={t('common.refresh')}
            onClick={getBillsList}
          />
          <ResetFilterButton
            onClick={resetFilter}
            disabled={
              !(
                searchText ||
                filters?.accounting_partner ||
                filters?.customer ||
                filters?.date?.from_date ||
                filters?.date?.to_date
              )
            }
          />
          <FilterButton
            onClick={() =>
              setIsFilterComponentVisible(!isFilterComponentVisible)
            }
            isActive={isFilterComponentVisible}
            disabled={checkIsFiltersApplied(filters)}
          />
          <CustomSearch
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </FlexEnd>
        {isFilterComponentVisible && (
          <FlexStart
            style={{
              background: DIVIDER_COLOR,
              padding: '8px',
              margin: '8px 0',
            }}
          >
            <Autocomplete
              placeholder={t('attributes.customer.customer')}
              options={customerOptions}
              width="190px"
              value={filters?.customer}
              onChange={(e, newVal) => {
                if (newVal || newVal === null) {
                  setFilters((pre) => ({ ...pre, customer: newVal }));
                }
              }}
            />
            {/* <Autocomplete
              placeholder={t('attributes.billingCard.accountpartner')}
              options={CONFIRMATIVE_OPTIONS}
              width="190px"
              value={filters?.accounting_partner}
              onChange={(e, newVal) => {
                if (newVal || newVal === null) {
                  setFilters((pre) => ({ ...pre, accounting_partner: newVal }));
                }
              }}
            /> */}
            <CustomDateRangePicker
              placeholder={t('attributes.technician.created_at')}
              onOkClick={(val) => {
                setFilters((prev) => ({
                  ...prev,
                  date: {
                    from_date: val[0],
                    to_date: val[1],
                  },
                }));
              }}
              onClear={() => {
                setFilters((prev) => ({
                  ...prev,
                  date: null,
                }));
              }}
              fromDate={filters?.date?.from_date}
              toDate={filters?.date?.to_date}
            />
          </FlexStart>
        )}
      </Box>
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading || isLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
        onRowSelectionModelChange={handleSelectionChange}
      />
    </>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setSelectedBill('');
            setOpenConfirmationModal(false);
            sethasFormValues(false);
            dispatch(resetCreate());

            if (window.history.state === null) {
              window.history.back();
            }
          }}
        />
      )}
      {billing_uuid ? (
        <Outlet />
      ) : (
        <StyledMainWrapper
          title={t('attributes.billingCard.billingInfoText')}
          btn={
            <>
              <CustomButton
                text={`${t('attributes.add')} ${t('attributes.new')}`}
                color="secondary"
                sx={{ height: '52%' }}
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() => setOpen(true)}
              />
              <CustomButton
                text={t('attributes.export')}
                color="secondary"
                sx={{ height: '52%' }}
                startIcon={<OpenInNewIcon />}
                onClick={() => {
                  if (!selectedRows || selectedRows?.length === 0) {
                    dispatch(
                      snackbarToggle({
                        isOpen: true,
                        isErrorMsg: true,
                        msg: t('message.common.noRecordExportMessage'),
                      })
                    );
                    setSelectedRows(null);
                  } else {
                    exportToCSV(
                      selectedRows,
                      COLUMNS_TO_EXPORT.BILLING_INFORMATION,
                      EXPORT_FILE_NAMES.BILLING_INFORMATION
                    );
                  }
                }}
              />
            </>
          }
        >
          <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
            {!isInitialLoading && totalCount === 0 && noData}
            {isInitialLoading ? (
              <Box
                sx={{
                  width: '100%',
                  mt: 2,
                  minHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
                  maxHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CustomCircularLoader />
              </Box>
            ) : (
              !isInitialLoading && totalCount > 0 && renderedComponent
            )}
          </Box>
        </StyledMainWrapper>
      )}
      {open && (
        <SwipeableDrawer
          open={open}
          title={t('attributes.billingCard.addBill')}
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setOpen(false) &&
                setSelectedBill('') &&
                dispatch(resetCreate());
          }}
          footerButton={
            <Box display="flex" gap="8px">
              <CustomButton
                text={t('attributes.add')}
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={handleSubmit(onSubmit())}
                disabled={isDataLoading || customerLoading}
              />
              {!selectedBill && (
                <CustomButton
                  text={t('attributes.billingCard.saveBillingContract')}
                  startIcon={<SaveOutlinedIcon />}
                  onClick={handleSubmit(onSubmit(true))}
                  disabled={isDataLoading || customerLoading}
                />
              )}
            </Box>
          }
          width="500px"
        >
          <BillingForm
            id={selectedBill}
            watch={watch}
            trigger={trigger}
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
            setServerErrors={setServerErrors}
            reset={reset}
            getValues={getValues}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default Billing;
