import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { WarningAmberOutlined } from '@mui/icons-material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import { Box, Divider, Tooltip, Typography } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';

import {
  DARK_GRAY,
  PRIMARY,
  RED,
  SECONDARY,
  STATUS_COLORS,
} from '../../constants/Colors';
import {
  ACTIVE,
  APPROVED,
  CHART_CONST,
  CREATED_STATUS,
  CUSTOMER_QUOTATION_STATUS,
  DATE_FORMAT_API,
  DEFECT,
  DIFFERED,
  DRAFT_STATUS,
  OVERDUE,
  REJECTED_STATUS,
  SCHEDULE_BOARD_WO_UTC_LOCAL,
  SUBMITTED,
  SUBMIT_TO_CUSTOMER_STATUS,
  TEMPLATE_SET,
  WORK_ORDER_STEPS,
  WORK_ORDER_TYPE_REPAIR,
  WO_CREATED,
} from '../../constants/Constants';
import { SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH } from '../../constants/Typography';
import { getUserRole } from '../../hoc/Permission';
import useBrowserBackButtonHandler from '../../hooks/useBrowserBackButtonHandler';
import usePreviewAndDownloadTemplate from '../../hooks/usePreviewAndDownloadTemplate';
import { snackbarToggle } from '../../store/CommonReducer';
import { editCompany } from '../../store/company/api';
import { editContact } from '../../store/contacts/api';
import { resetEdit } from '../../store/contacts/reducer';
import { getProperty } from '../../store/property/api';
import {
  createDefectQuote,
  createWorkOrder,
  getDefectQuotesDetail,
  getDefectsQuotesList,
  getWorkOrderQuotation,
  updateDefectQuotationStatus,
} from '../../store/workOrder/api';
import {
  resetCreateDefectQuote,
  resetCreateWorkOrder,
  resetDefectQuotes,
  resetDefectQuotesDetail,
  resetUpdateQuotationStatus,
} from '../../store/workOrder/reducer';
import {
  calculateTotal,
  formattedDate,
  getRedirectURL,
  showHideTopbarLoader,
} from '../../utils';
import { Edit, Generate, View } from '../CommonComponents/ActionComponent';
import CustomButton from '../CommonComponents/CustomButton';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StatusLabel from '../CommonComponents/StatusLabel';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import AddProduct from './AddDefectQuotesProduct';

export const formatDefectQuotesStatus = (status = '', t = () => {}) => {
  const lowerCaseStatus =
    typeof status === 'string' ? status?.toLowerCase() : status;

  if (lowerCaseStatus === REJECTED_STATUS) {
    return <StatusLabel label={t('common.status.rejected')} color="#C54036" />;
  }
  if (lowerCaseStatus === APPROVED) {
    return <StatusLabel label={t('common.status.approved')} color="#95C020" />;
  }
  if (lowerCaseStatus === DRAFT_STATUS) {
    return <StatusLabel label={t('common.status.draft')} color="#999999" />;
  }
  if (lowerCaseStatus === SUBMIT_TO_CUSTOMER_STATUS) {
    return <StatusLabel label={t('common.status.submitted')} color="#59366B" />;
  }
  if (lowerCaseStatus === WO_CREATED) {
    return (
      <StatusLabel label={t('common.status.wo_created')} color={DARK_GRAY} />
    );
  }
  if (lowerCaseStatus === CREATED_STATUS) {
    return (
      <StatusLabel
        label={t('common.status.inReview')}
        color="var(--in-review-color)"
      />
    );
  }
  if (lowerCaseStatus === OVERDUE) {
    return (
      <StatusLabel
        label={t('attributes.invoice.overdue')}
        color={STATUS_COLORS.OVERDUE}
      />
    );
  }
  if (lowerCaseStatus === DIFFERED) {
    return (
      <StatusLabel
        label={t('common.status.differed')}
        color={STATUS_COLORS.DIFFERED}
      />
    );
  }
};

const DefectQuotes = ({ workOrderId, setNextStepTitle, technician_uuid }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [quote, setQuote] = useState('');
  const [hasFormValues, sethasFormValues] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [isPropertyLoading, setIsPropertyLoading] = useState(false);
  const [propertyData, setPropertyData] = useState([]);

  const { defectQuotesList, isLoading: defectQuotesLoading } = useSelector(
    (state) => state.workOrder.defectQuotes
  );

  const { data, isLoading } = useSelector(
    (state) => state.workOrder.defectQuotesDetail
  );
  const { isLoading: isLoadingCompany, company } = useSelector(
    (state) => state.company.edit
  );
  const { isLoading: updateQuotiationLoading, data: updateQuotiationData } =
    useSelector((state) => state.workOrder.updateQuotationStatus);

  const {
    isLoading: createDefectQuoteLoading,
    data: createDefectQuoteData,
    error,
  } = useSelector((state) => state.workOrder.createDefectQuote);

  const { isLoading: createWorkOrderLoading } = useSelector(
    (state) => state.workOrder.createWorkOrder
  );

  const { isLoading: contactLoading, contact } = useSelector(
    (state) => state.contact.edit
  );

  const user_info = localStorage.getItem('user_info');
  const user = user_info ? JSON.parse(user_info) : null;

  const handleOpenNewWindowLink = (link) => {
    const fullPath = `${window.location.origin}${link}`;

    window.open(fullPath, '_blank');
  };

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    trigger,
    setError,
    clearErrors,
  } = useForm();

  useBrowserBackButtonHandler({
    hasUnsavedChanges: hasFormValues,
    setOpenConfirmationModal,
    open,
    watchMethod: watch,
    sethasFormValues,
  });

  const { listTemplateLoading, generatePDF, PreviewTemplatePDF } =
    usePreviewAndDownloadTemplate({
      module: TEMPLATE_SET.DEFECT_QUOTES,
      data: {
        companyData: company,
        propertyData,
        isPropertyLoading: isPropertyLoading || isLoading || contactLoading,
        quoteData: data,
        contact,
      },
    });

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: `${t('attributes.work_order.quote_id')}`,
        field: 'quote_id',
        sortable: true,
        flex: 1,
      },
      {
        headerName: `${t('attributes.work_order.repair_work_order_id')}`,
        field: 'repair_work_order_id',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) =>
          row?.attached_work_orders?.length > 0 ? (
            <Box
              sx={{ display: 'flex', columnGap: '4px', alignItems: 'center' }}
            >
              {row?.attached_work_orders?.map((l, index) => (
                <Typography
                  key={index}
                  color="primary"
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() =>
                    handleOpenNewWindowLink(
                      `${getRedirectURL()[getUserRole()][CHART_CONST.WORK_ORDERS]}/${l?.uuid}`
                    )
                  }
                >
                  {l?.woid}
                </Typography>
              ))}
            </Box>
          ) : (
            '-'
          ),
      },
      {
        headerName: `${t('attributes.work_order.status')}`,
        field: 'status',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => formatDefectQuotesStatus(row.status, t),
      },
      {
        headerName: `${t('attributes.work_order.quote_date')}`,
        field: 'quote_date',
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t('attributes.work_order.due_date')}`,
        field: 'due_date',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => {
          if (!row?.due_date) {
            return '-';
          }

          const dueDate = moment(
            formattedDate(row?.due_date, SCHEDULE_BOARD_WO_UTC_LOCAL)
          );

          if (dueDate.isBefore(moment())) {
            return (
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography color={RED}>
                  {formattedDate(row?.due_date)}
                </Typography>
                <WarningAmberOutlined sx={{ color: `${RED}!important` }} />
              </Box>
            );
          }

          return formattedDate(row.due_date);
        },
      },
      {
        headerName: `${t('attributes.amount')}`,
        field: 'amount',
        sortable: false,
        flex: 1,
        renderHeader: () => (
          <Tooltip
            title={`${t('attributes.amount')} (${t('common.including')} ${company?.tax_type})`}
            arrow
          >
            <Typography
              variant="body1"
              textAlign="left"
              sx={{
                display: 'flex',
                justifyContent: 'start',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {t('attributes.amount')}
              <br />
              {`(${t('common.including')} ${company?.tax_type})`}
            </Typography>
          </Tooltip>
        ),
        renderCell: ({ row }) => (
          <Typography
            variant="body2"
            sx={{ display: 'flex', justifyContent: 'start' }}
          >
            {row?.amount}
          </Typography>
        ),
      },
      {
        field: `${t('attributes.actions')}`,
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <View
              onClick={() => {
                if (user.company_uuid && row.property_uuid) {
                  setIsPropertyLoading(true);
                  // dispatch(editCompany(user?.company_uuid));
                  dispatch(getProperty(row?.property_uuid))
                    .then((res) => setPropertyData(res.payload?.data[0]))
                    .finally(() => setIsPropertyLoading(false));
                }
                if (row?.id) {
                  setQuote(row);
                  dispatch(
                    getDefectQuotesDetail({
                      work_order_id: workOrderId,
                      quote_id: row?.id,
                    })
                  );
                }
                if (row?.customer_details?.contact_uuid) {
                  dispatch(editContact(data?.customer_details?.contact_uuid));
                }
                setOpenPreview(true);
              }}
            />
            {row?.status === APPROVED || row?.status === WO_CREATED ? (
              <Generate
                onClick={() =>
                  dispatch(
                    getDefectsQuotesList({
                      workorder_uuid: workOrderId,
                      limit: -1,
                    })
                  ).then((res) => generateWorkOrder(row, res?.payload?.data))
                }
                disabled={
                  row?.status === WO_CREATED ||
                  row?.property?.property_status?.name !== ACTIVE
                }
              />
            ) : (
              <Edit
                onClick={() => {
                  dispatch(resetUpdateQuotationStatus());
                  if (row?.id) {
                    setQuote(row);
                    dispatch(
                      getDefectQuotesDetail({
                        work_order_id: workOrderId,
                        quote_id: row?.id,
                      })
                    );
                    setNextStepTitle({
                      title: WORK_ORDER_STEPS.defect_quotes,
                      step: `Q-${row?.quotation_id}`,
                    });
                    setOpen(true);
                  }
                }}
                disabled={row?.status === SUBMITTED}
              />
            )}
          </Box>
        ),
      },
    ];

    return baseColumns;
  }, [company]);

  useEffect(() => {
    dispatch(editCompany(user?.company_uuid));
  }, []);

  useEffect(() => {
    showHideTopbarLoader(updateQuotiationLoading, dispatch);
    if (updateQuotiationLoading !== null && !updateQuotiationLoading) {
      if (updateQuotiationData?.data?.length > 0) {
        setOpenPreview(false);
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: t('attributes.work_order.quotationSuccessMessage'),
          })
        );
      }
    }
  }, [updateQuotiationData, updateQuotiationLoading]);

  useEffect(() => {
    showHideTopbarLoader(createDefectQuoteLoading, dispatch);
    if (!createDefectQuoteLoading) {
      if (error) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: error.message,
          })
        );
      }
      if (createDefectQuoteData) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: createDefectQuoteData.message,
          })
        );
        debouncedFetchData();
      }
    }
  }, [error, createDefectQuoteLoading, createDefectQuoteData]);

  const formatQuoteItem = (item) => ({
    ...item,
    id: item?.uuid || '-',
    quote_id: item?.quotation_id ? `Q-${item?.quotation_id}` : '-',
    status: item?.status,
    quote_date: formattedDate(item?.created_at) || '-',
    due_date: item?.due_date,
    amount: calculateTotal({
      data: item,
      type: 'total',
      ...(company?.tax_value && {
        taxPercentage: company?.tax_value,
      }),
    }),
    property_uuid: item?.property_uuid,
    repair_work_order_id: item?.attached_work_orders,
  });

  const rows = Array.isArray(defectQuotesList)
    ? defectQuotesList.map(formatQuoteItem)
    : typeof defectQuotesList === 'object'
      ? [formatQuoteItem(defectQuotesList)]
      : [];

  useEffect(
    () => () => {
      dispatch(resetDefectQuotes());
      dispatch(resetDefectQuotesDetail());
      dispatch(resetUpdateQuotationStatus());
      dispatch(resetCreateDefectQuote());
      dispatch(resetCreateWorkOrder());
      showHideTopbarLoader(false, dispatch);
      dispatch(resetEdit());
    },
    []
  );

  // Function to fetch data based on search, pagination, and filter
  const getAllWorkOrder = useCallback(() => {
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);

    dispatch(
      getWorkOrderQuotation({
        id: workOrderId,
        order: order,
        orderBy: orderBy,
        list_column_names: visibleFieldsString,
        type: DEFECT.toLocaleLowerCase(),
      })
    ).finally(() => {
      setIsInitialLoading(false);
      setIsDataLoading(false);
    });
  }, [
    dispatch,
    order,
    orderBy,
    columns,
    columnVisibilityModel,
    updateQuotiationData,
  ]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllWorkOrder, 500), [
    getAllWorkOrder,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const sendDefectQuotation = () => {
    dispatch(
      updateDefectQuotationStatus({
        work_order_id: workOrderId,
        quote_id: quote?.id,
        status: SUBMIT_TO_CUSTOMER_STATUS,
      })
    );
  };

  const handleCreateDefectQuote = () => {
    dispatch(
      createDefectQuote({
        work_order_uuid: workOrderId,
        quotation_type: 'defect',
        property_uuid: null,
        technician_uuid: technician_uuid,
      })
    );
  };

  const generateWorkOrder = (selected = {}, defects = []) => {
    const bodyData = {
      customer_uuid: selected?.customer_uuid,
      property_uuid: selected?.property_uuid,
      property_zone: selected?.work_order?.property_zone,
      due_date: moment().add(1, 'month').format(DATE_FORMAT_API),
      work_order_type: WORK_ORDER_TYPE_REPAIR,
      defects: defects?.map((def) => ({
        asset_uuid: def?.asset_uuid,
        defect_uuid: def?.uuid,
      })),
      parent_work_order_uuid: workOrderId,
      parent_quotation_approved_uuid: selected?.uuid,
      work_order_name: `W-${selected?.work_order?.id} - Repair`,
    };

    dispatch(createWorkOrder({ data: bodyData })).then((res) => {
      dispatch(
        snackbarToggle({
          isOpen: true,
          isErrorMsg: false,
          msg: res?.payload?.message,
        })
      );
      getAllWorkOrder();
      setOpenPreview(false);
      dispatch(resetCreateWorkOrder());
    });
  };

  return (
    <>
      <Box sx={{ padding: '16px' }}>
        <CustomButton
          text={t('common.create_new')}
          color="secondary"
          sx={{ height: '52%' }}
          startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={handleCreateDefectQuote}
          disabled={
            rows.length >= 1 || defectQuotesLoading || createDefectQuoteLoading
          }
        />
      </Box>
      <Divider />
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
            sethasFormValues(false);

            if (window.history.state === null) {
              window.history.back();
            }
          }}
        />
      )}
      {isInitialLoading ||
      defectQuotesLoading ||
      listTemplateLoading ||
      createWorkOrderLoading ||
      isLoadingCompany ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            display: 'flex',
            paddingTop: '200px',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isInitialLoading &&
        !isDataLoading &&
        !listTemplateLoading &&
        rows?.length > 0 && (
          <CustomGridTable
            columns={columns}
            rows={rows || []}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
            noData={<NoRecordFound />}
            isLoading={isDataLoading}
            checkboxSelection={false}
            paginationRequired={false}
            sx={{ paddingLeft: '18px' }}
          />
        )
      )}
      {open && (
        <SwipeableDrawer
          open={open}
          title="Add Asset"
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          // footerButton={
          //   <CustomButton
          //     text={t('attributes.save')}
          //     startIcon={<SaveOutlinedIcon />}
          //     onClick={handleSubmit(onSubmit)}
          //     disabled={createAssetLoading || updatedAssetLoading}
          //   />
          // }
          width={SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH}
        >
          <AddProduct
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
          />
        </SwipeableDrawer>
      )}

      {openPreview && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={openPreview}
          title={`Q-${quote?.quotation_id}` || '-'}
          onClose={() => {
            setOpenPreview(false);
          }}
          footerButton={[
            quote?.status === CUSTOMER_QUOTATION_STATUS.APPROVED && (
              <CustomButton
                text="Create Repair Work Order"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() =>
                  dispatch(
                    getDefectsQuotesList({
                      workorder_uuid: quote?.work_order_uuid,
                      limit: -1,
                    })
                  ).then((res) => generateWorkOrder(quote, res?.payload?.data))
                }
              />
            ),
            <CustomButton
              text="Donwload"
              color="inherit"
              disabled={isPropertyLoading || isLoadingCompany || isLoading}
              startIcon={<DownloadOutlinedIcon />}
              onClick={() => {
                generatePDF();
              }}
            />,
            quote?.status !== CUSTOMER_QUOTATION_STATUS.PENDING &&
              quote?.status !== DRAFT_STATUS &&
              quote?.status !== WO_CREATED &&
              quote?.status !== CUSTOMER_QUOTATION_STATUS.APPROVED &&
              data.status !== CUSTOMER_QUOTATION_STATUS.OVERDUE && (
                <CustomButton
                  text="Send Defects Quote"
                  disabled={
                    isPropertyLoading ||
                    isLoadingCompany ||
                    updateQuotiationLoading ||
                    quote?.status === CUSTOMER_QUOTATION_STATUS.APPROVED
                  }
                  startIcon={<ForwardToInboxOutlinedIcon />}
                  onClick={sendDefectQuotation}
                  sx={{
                    '&.Mui-disabled': {
                      backgroundColor: PRIMARY,
                      color: `${SECONDARY} !important`,
                      opacity: 0.5,
                    },
                  }}
                />
              ),
          ]}
          width={653}
        >
          {PreviewTemplatePDF}
        </SwipeableDrawer>
      )}
    </>
  );
};

export default DefectQuotes;
