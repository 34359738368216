import { Box, Typography } from '@mui/material';
import { PieChart as CustomPieChart } from '@mui/x-charts/PieChart';

import { DIVIDER_COLOR, SECONDARY } from '../../../constants/Colors';

export const NoData = () => (
  <g>
    <text
      x="50%"
      y="50%"
      textAnchor="middle"
      dominantBaseline="middle"
      style={{
        fontSize: '14px',
        fontWeight: 400,
        fill: SECONDARY,
        zIndex: 99999,
      }}
    >
      No data to display
    </text>
  </g>
);

const PieChart = ({ data = [] }) => {
  // Data Config
  let defaultConfig = {
    data: data,
    type: 'pie',
    cx: 120,
    cy: 120,
    outerRadius: 90,
  };

  // Legend
  let defaultLegendConfig = {
    hidden: true,
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        height: '100%',
        width: '100%',
      }}
    >
      <CustomPieChart
        className={data?.length > 0 ? 'custom-pie-chart' : ''}
        series={[defaultConfig]}
        slotProps={{
          legend: defaultLegendConfig,
        }}
        slots={{
          noDataOverlay: NoData,
        }}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {data?.map((d, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderTop: `1px solid ${DIVIDER_COLOR}`,
              padding: '8px',
            }}
          >
            <Typography variant="body2">{d.label}</Typography>
            <Box
              sx={{
                height: '20px',
                width: '19px',
                borderRadius: '4px',
                background: d.color,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography fontSize="10px" fontWeight={600} color="secondary">
                {d?.value}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PieChart;
