import { useEffect, useState } from 'react';

import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import { Box, styled, Typography } from '@mui/material';
import { addMonths, isWithinInterval } from 'date-fns';
import moment from 'moment';
import { DatePicker, DateRangePicker } from 'rsuite';

import 'rsuite/dist/rsuite.min.css';
import { PRIMARY, WARNING } from '../../constants/Colors';
import {
  DATE_FORMAT,
  DATE_RANGE_FORMAT,
  DATE_TIME_RANGE_FORMAT,
  DATE_TIME_RANGE_FORMAT_DEFAULT,
} from '../../constants/Constants';

const DateLabel = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isactive',
})(({ isactive, value, theme }) => ({
  position: 'absolute',
  left: '0.75rem',
  transitionProperty: 'all',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDuration: '300ms',
  pointerEvents: 'none',
  ...(isactive
    ? {
        top: '-0.7rem',
        paddingLeft: '0.25rem',
        paddingRight: '0.25rem',
        color: !value ? PRIMARY : theme.palette.grey[400],
        zIndex: 10,
        backgroundColor: 'white',
      }
    : {
        top: '0.5rem',
        color: theme.palette.grey[600],
        zIndex: 10,
      }),
}));

const Calender = styled(EventOutlinedIcon)(({ theme }) => ({
  position: 'absolute',
  right: '0.75rem',
  top: '0.70rem',
  zIndex: 11,
  color: theme.palette.grey[600],
  pointerEvents: 'none',
}));

export const CustomDatePicker = ({ ...rest }) => <DatePicker {...rest} />;

const CustomDateRangePicker = ({
  size = 'md',
  placeholder = `${DATE_FORMAT} - ${DATE_FORMAT}`,
  onOkClick = () => {},
  onClear = () => {},
  fromDate,
  toDate,
  placement = 'bottomStart',
  format = DATE_RANGE_FORMAT,
  width = '260px',
  label = '',
  isRequired = false,
  isClearIcon = true,
  dynamicWidth = false,
  maxRangeMonths, // New prop to control the max range
  ...rest
}) => {
  const [value, setValue] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const formatDate = () => {
    if (!value.length || value.some((date) => date === null)) {
      return placeholder;
    }

    return format === DATE_TIME_RANGE_FORMAT
      ? `${moment(value[0]).format(DATE_TIME_RANGE_FORMAT_DEFAULT)} - ${moment(value[1]).format(DATE_TIME_RANGE_FORMAT_DEFAULT)}`
      : `${moment(value[0]).format(DATE_FORMAT)} - ${moment(value[1]).format(DATE_FORMAT)}`;
  };

  useEffect(() => {
    if (!fromDate && !toDate) setValue([]);
    if (fromDate || toDate) {
      setValue([fromDate, toDate]);
    }
  }, [fromDate, toDate]);

  const isActive = isFocused || value.length > 0;

  // Disable dates if maxRangeMonths is provided
  const disabledDate = (date, selectValue) => {
    if (maxRangeMonths && selectValue?.[0]) {
      const start = selectValue[0];
      const endLimit = addMonths(start, maxRangeMonths); // Add max range months to start date

      return !isWithinInterval(date, {
        start,
        end: endLimit,
      });
    }

    return false; // No restriction if maxRangeMonths is not set
  };

  return (
    <Box position="relative">
      <DateLabel isactive={isActive} value={value.length > 0}>
        <Typography variant="body2">
          {label}

          {isRequired && <span style={{ color: WARNING }}>*</span>}
        </Typography>
      </DateLabel>

      {!value.length && <Calender />}
      <DateRangePicker
        size={size}
        placeholder={isActive && `${DATE_FORMAT} - ${DATE_FORMAT}`}
        showHeader={false}
        character=" - "
        ranges={[]}
        format={format}
        onOk={(dates) => {
          setValue(dates);
          onOkClick(dates);
        }}
        onClean={() => {
          setValue([]);
          onClear();
        }}
        cleanable={isClearIcon}
        value={value}
        renderValue={formatDate}
        style={{ width: dynamicWidth ? '100%' : width }}
        placement={placement}
        onOpen={() => setIsFocused(true)}
        onClose={() => setIsFocused(false)}
        shouldDisableDate={disabledDate} // Apply disabledDate only if maxRangeMonths is passed
        preventOverflow
        {...rest}
      />
    </Box>
  );
};

export default CustomDateRangePicker;
