import GridOffOutlinedIcon from '@mui/icons-material/GridOffOutlined';
import { Box, Typography } from '@mui/material';

import { NODATA_ICON_COLOR } from '../../../constants/Colors';

const NoRecordFound = ({
  isShowIcon = true,
  text = 'No records found',
  bold = true,
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    {isShowIcon && (
      <GridOffOutlinedIcon
        sx={{ fontSize: '40px !important', color: NODATA_ICON_COLOR }}
      />
    )}
    <Typography
      variant={bold ? 'body1' : 'body2'}
      sx={{ fontSize: bold ? 14 : 12 }}
    >
      {text}
    </Typography>
  </Box>
);

export default NoRecordFound;
