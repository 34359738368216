import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../constants/APIConstants';
import { DATE_RANGE_FORMAT_API, TAB_STATUS } from '../../constants/Constants';
import { formatDateAPI } from '../../utils';
import { TEMPLATES } from '../actionPrefix';
import { axiosNotification } from '../axios';

// Email Temapltes
export const getTemplates = createAsyncThunk(
  TEMPLATES.GET_TEMPLATES,
  async (
    {
      page,
      search,
      size,
      email_type,
      role,
      fromDate,
      toDate,
      active,
      list_column_names,
      order,
      orderBy,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (size) params.append('size', size);
    if (active)
      params.append('is_active', active === TAB_STATUS.active ? true : false);
    if (email_type) params.append('email_type', email_type);
    if (role) params.append('role_type', role);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (fromDate)
      params.append(
        'updated_at__gte',
        formatDateAPI(moment(fromDate).format(DATE_RANGE_FORMAT_API))
      );
    if (toDate)
      params.append(
        'updated_at__lte',
        formatDateAPI(moment(toDate).endOf('day').format(DATE_RANGE_FORMAT_API))
      );

    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosNotification.get(
        `${APIs.TEMPLATES.GET_TEMPLATES}?${params.toString()}`
      );

      return { ...response.data, active };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateEmailTemplate = createAsyncThunk(
  TEMPLATES.UPDATE_TEMPLATE,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axiosNotification.put(
        APIs.TEMPLATES.GET_TEMPLATE_DETAIL.replace(':id', id),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createEmailTemplate = createAsyncThunk(
  TEMPLATES.UPDATE_TEMPLATE,
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosNotification.post(
        APIs.TEMPLATES.UPDATE_TEMPLATE,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getEmailTemplateDetail = createAsyncThunk(
  TEMPLATES.GET_TEMPLATE_DETAIL,
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosNotification.get(
        APIs.TEMPLATES.GET_TEMPLATE_DETAIL.replace(':id', id)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Email Settings
export const getEmailSettings = createAsyncThunk(
  TEMPLATES.GET_EMAIL_SETTINGS,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosNotification.get(
        APIs.TEMPLATES.GET_EMAIL_SETTINGS
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createEmailSettings = createAsyncThunk(
  TEMPLATES.CREATE_EMAIL_SETTINGS,
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosNotification.post(
        APIs.TEMPLATES.GET_EMAIL_SETTINGS,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateEmailSettings = createAsyncThunk(
  TEMPLATES.UPDATE_EMAIL_SETTINGS,
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosNotification.patch(
        APIs.TEMPLATES.GET_EMAIL_SETTINGS,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// DropDown Data
export const getTemplateDropDownData = createAsyncThunk(
  TEMPLATES.GET_TEMPLATE_DATA,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosNotification.get(
        APIs.TEMPLATES.GET_TEMPLATE_DATA
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendEmail = createAsyncThunk(
  TEMPLATES.SEND_EMAIL,
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosNotification.post(
        APIs.TEMPLATES.SEND_EMAIL,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRestTemplates = createAsyncThunk(
  TEMPLATES.REST_ALL_TEMPLATAES,
  async ({ rejectWithValue }) => {
    try {
      const response = await axiosNotification.get(
        APIs.TEMPLATES.GET_REST_TEMPLATES
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editRestTemplates = createAsyncThunk(
  TEMPLATES.EDIT_REST_TEMPLATES,
  async ({ req }, { rejectWithValue }) => {
    try {
      const response = await axiosNotification.post(
        APIs.TEMPLATES.EDIT_REST_TEMPLATES,
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
