import { createSlice } from '@reduxjs/toolkit';

import { editRestTemplates, getRestTemplates } from './api';

const initialData = {
  isLoading: true,
  error: null,
  data: false,
};

const templateSlice = createSlice({
  name: 'template',
  initialState: {
    listTemplate: { ...initialData },
    editTemplate: {
      ...initialData,
    },
  },
  reducers: {
    resetTemplateEdit(state) {
      state.listTemplate = { ...initialData };
    },
    resetTemplateList(state) {
      state.editTemplate = { ...initialData };
    },
  },
  extraReducers: (builder) => {
    builder
      // Supplier List
      .addCase(getRestTemplates.pending, (state) => {
        state.listTemplate.isLoading = true;
      })
      .addCase(getRestTemplates.fulfilled, (state, action) => {
        state.listTemplate.isLoading = false;
        state.listTemplate.data = action.payload?.data?.[0]?.default_template;
      })
      .addCase(getRestTemplates.rejected, (state, action) => {
        state.listTemplate.isLoading = false;
        state.listTemplate.error = action.payload;
      })
      .addCase(editRestTemplates.pending, (state) => {
        state.editTemplate.isLoading = true;
      })
      .addCase(editRestTemplates.fulfilled, (state, action) => {
        state.editTemplate.isLoading = false;
        state.editTemplate.data = action.payload?.data?.[0]?.default_template;
      })
      .addCase(editRestTemplates.rejected, (state, action) => {
        state.editTemplate.isLoading = false;
        state.editTemplate.error = action.payload;
      });
  },
});

export const { resetTemplateEdit, resetTemplateList } = templateSlice.actions;

export default templateSlice.reducer;
