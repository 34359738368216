import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider, Typography } from '@mui/material';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../../assets/commonStyled';
import { getAccessSchedule } from '../../../store/property/api';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';

const AccessScheduleView = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading: isLoading, data: editAccessSchedule } = useSelector(
    (state) => state.property.getAccessSchedule
  );

  useEffect(() => {
    if (id) {
      dispatch(getAccessSchedule(id));
    }
  }, []);

  const KeyValueComponent = ({ name, value }) => (
    <Box display="flex" flexDirection="column">
      <Typography variant="body1">{name}</Typography>
      <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
        {value}
      </Typography>
    </Box>
  );

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">
            {`${t('attributes.configuration.accessSchedule.title')} ${t('common.details')}`}
          </Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Box display="flex" flexDirection="column" rowGap="8px" width="100%">
            <KeyValueComponent
              name={t('attributes.configuration.accessSchedule.title')}
              value={editAccessSchedule?.data?.[0]?.display_name}
            />
          </Box>
        </FormFieldWrapper>
      </CustomCard>
    </FormWrapper>
  );
};

export default AccessScheduleView;
