import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import moment from 'moment';

const TechnicianPerformanceLayout = () => {
  const [month, setMonth] = useState(moment());
  return <Outlet context={{ parentMonth: month, setParentMonth: setMonth }} />;
};
export default TechnicianPerformanceLayout;
