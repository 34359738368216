const supplier = [
  {
    headerName: `Name`,
    field: 'name',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Cell Phone',
    field: 'business_number',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Contact Name',
    field: 'contact_name',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Contact Email',
    field: 'contact_email',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Contact Phone',
    field: 'contact_phone',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Address',
    field: 'address',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'City',
    field: 'city',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'State',
    field: 'state',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Postcode',
    field: 'postcode',
    flex: 1,
    sortable: false,
  },
];

const product_catalogue = [
  {
    headerName: 'Product Type',
    field: 'type',
    sortable: false,
    minWidth: 110,
  },
  {
    headerName: 'Name',
    field: 'name',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Equipment Type',
    field: 'equipment_type',
    sortable: false,
    minWidth: 130,
  },
  {
    headerName: 'Product Name',
    field: 'product_name',
    sortable: false,
    minWidth: 120,
  },
  {
    headerName: 'Category1 Name',
    field: 'category1_name',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Category2 Name',
    field: 'category2_name',
    sortable: false,
    minWidth: 140,
  },
  { headerName: 'Description', field: 'description', sortable: false, flex: 1 },
  {
    headerName: 'Cost Currency',
    field: 'cost_currency',
    sortable: false,
    minWidth: 120,
  },
  {
    headerName: 'Sell Currency',
    field: 'sell_currency',
    sortable: false,
    flex: 1,
  },
  { headerName: 'UM', field: 'um', sortable: false },
  {
    headerName: 'Cost Price',
    field: 'cost',
    sortable: false,
  },
  {
    headerName: 'Default Sell Price',
    field: 'sell_price',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Estimated Time Hours',
    field: 'estimated_time_hours',
    sortable: false,
    minWidth: 170,
  },

  {
    headerName: 'Supplier Email',
    field: 'supplier_email',
    sortable: false,
    minWidth: 120,
  },
];

const customer = [
  {
    headerName: 'Customer Name',
    field: 'customer_name',
    sortable: false,
    minWidth: 130,
  },
  {
    headerName: 'Customer ABN Number',
    field: 'customer_abn_number',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'Customer Type',
    field: 'customer_type',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Categories',
    field: 'categories',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Address',
    field: 'address',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'State',
    field: 'state',
    sortable: false,
  },
  {
    headerName: 'City',
    field: 'city',
    sortable: false,
  },
  {
    headerName: 'Postcode',
    field: 'postcode',
    sortable: false,
  },
  {
    headerName: 'Notes',
    field: 'notes',
    sortable: false,
  },
  {
    headerName: 'Agreement Signed Date',
    field: 'agreement_signed_date',
    sortable: false,
    minWidth: 185,
  },
  {
    headerName: 'Company Name',
    field: 'company_name',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Billing Contact Name',
    field: 'billing_contact_name',
    sortable: false,
    minWidth: 170,
  },
  {
    headerName: 'Email To',
    field: 'email_to',
    sortable: false,
  },
  {
    headerName: 'Primary Contact Name',
    field: 'primary_contact_name',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'Primary Contact Email',
    field: 'primary_contact_email',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'Primary Contact Phone',
    field: 'primary_contact_phone',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'Account Manager Email',
    field: 'account_manager_email',
    sortable: false,
    minWidth: 180,
  },
];

const property = [
  {
    headerName: 'Property Name',
    field: 'property_name',
    sortable: false,
    minWidth: 130,
  },
  {
    headerName: 'Address',
    field: 'address',
    sortable: false,
  },
  {
    headerName: 'City',
    field: 'city',
    sortable: false,
  },
  {
    headerName: 'State',
    field: 'state',
    sortable: false,
  },
  {
    headerName: 'Country',
    field: 'country',
    sortable: false,
  },
  {
    headerName: 'Zip Code',
    field: 'zip_code',
    sortable: false,
  },
  {
    headerName: 'OCSP Number',
    field: 'ocsp_number',
    sortable: false,
    minWidth: 120,
  },
  {
    headerName: 'Internal Note',
    field: 'internal_note',
    sortable: false,
    minWidth: 110,
  },
  {
    headerName: 'Lot Number',
    field: 'lot_number',
    sortable: false,
  },
  {
    headerName: 'Status',
    field: 'status',
    sortable: false,
    minWidth: 110,
  },
  {
    headerName: 'Customer Email',
    field: 'customer_email',
    sortable: false,
    minWidth: 135,
  },
  {
    headerName: 'Zone',
    field: 'zone',
    sortable: false,
  },
  {
    headerName: 'Is Booking Required',
    field: 'is_booking_required',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Annual Compliance Date',
    field: 'annual_compliance_date',
    sortable: false,
    minWidth: 190,
  },
  {
    headerName: 'Technician Email',
    field: 'technician_email',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Active Billing Contract Name',
    field: 'active_billing_contract_name',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Billing Card Email',
    field: 'billing_card_email',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Local Government Area',
    field: 'local_government_area',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Contact Info Name',
    field: 'contact_info_name',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Contact Info Phone',
    field: 'contact_info_phone',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Contact Info Email',
    field: 'contact_info_email',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Annual Due Certification Date',
    field: 'annual_due_certification_date',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Building Size',
    field: 'building_size',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Stories Above Ground',
    field: 'stories_above_ground',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Stories Below Ground',
    field: 'stories_below_ground',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Building Class',
    field: 'building_class',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Building Type',
    field: 'building_type',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Construction Class',
    field: 'construction_class',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Building Era',
    field: 'building_era',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'OP Number',
    field: 'op_number',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'OP Date',
    field: 'op_date',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'OP Location',
    field: 'op_location',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Safety Requirements',
    field: 'safety_requirements',
    sortable: false,
    minWidth: 80,
  },
  {
    headerName: 'Service Requirements',
    field: 'service_requirements',
    sortable: false,
    minWidth: 80,
  },
  {
    headerName: 'Signature Required',
    field: 'signature_required',
    sortable: false,
    minWidth: 80,
  },
  {
    headerName: 'Induction Required',
    field: 'induction_required',
    sortable: false,
    minWidth: 80,
  },
  {
    headerName: 'Log Book Entry Required',
    field: 'log_book_entry_required',
    sortable: false,
    minWidth: 80,
  },
  {
    headerName: 'Police Check Required',
    field: 'police_check_required',
    sortable: false,
    minWidth: 80,
  },
  {
    headerName: 'Working With Children Check Required',
    field: 'working_with_children_check_required',
    sortable: false,
    minWidth: 80,
  },
  {
    headerName: 'Access Code',
    field: 'access_code',
    sortable: false,
    minWidth: 80,
  },
  {
    headerName: 'Access Note',
    field: 'access_note',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Property Access Schedule',
    field: 'property_access_schedule',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Property Access Procedure',
    field: 'property_access_procedure',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Parent Property Id',
    field: 'parent_property_id',
    sortable: false,
    minWidth: 80,
  },
];

const technician = [
  {
    headerName: 'Technician Name',
    field: 'technician_name',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Company Name',
    field: 'company_name',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Notes',
    field: 'notes',
    sortable: false,
  },
  {
    headerName: 'Skills',
    field: 'skills',
    sortable: false,
  },
  {
    headerName: 'Service Areas',
    field: 'service_areas',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Zones',
    field: 'zones',
    sortable: false,
  },
  {
    headerName: 'Categories',
    field: 'categories',
    sortable: false,
    minWidth: 120,
  },
  {
    headerName: 'Contact Email',
    field: 'contact_email',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Contact Phone',
    field: 'contact_phone',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Address',
    field: 'address',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'City',
    field: 'city',
    sortable: false,
  },
  {
    headerName: 'State',
    field: 'state',
    sortable: false,
  },
  {
    headerName: 'Postcode',
    field: 'postcode',
    sortable: false,
    minWidth: 120,
  },
  {
    headerName: 'Rates',
    field: 'rates',
    sortable: false,
    minWidth: 140,
  },
];

const asset = [
  {
    headerName: 'Equipment Type',
    field: 'equipment_type',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'Product Name',
    field: 'product_name',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Category 1 Name',
    field: 'category1_name',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Category 2 Name',
    field: 'category2_name',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Category 3 Name',
    field: 'category3_name',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Location',
    field: 'location',
    sortable: false,
    minWidth: 120,
  },
  {
    headerName: 'Status',
    field: 'status',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Barcode',
    field: 'barcode',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Internal Note',
    field: 'internal_note',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Installation Date',
    field: 'installation_date',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Make',
    field: 'make',
    sortable: false,
    minWidth: 80,
  },
  {
    headerName: 'Size',
    field: 'size',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Quantity',
    field: 'quantity',
    sortable: false,
    minWidth: 90,
  },
  {
    headerName: 'Model',
    field: 'model',
    sortable: false,
    minWidth: 100,
  },
  {
    headerName: 'Identification Number',
    field: 'identification_number',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Property Id',
    field: 'property_id',
    sortable: false,
    minWidth: 110,
  },
];

export const COLUMNS = {
  supplier,
  product_catalogue,
  customer,
  property,
  technician,
  asset,
};
