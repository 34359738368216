import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { DIVIDER_COLOR } from '../../constants/Colors';
import {
  CHART_CONST,
  DEFECTS,
  DEFECT_QUOTES,
  SCHEDULE_BOARD_WO_UTC_LOCAL,
  WORK_ORDERS,
  redirectURL,
} from '../../constants/Constants';
// eslint-disable-next-line import-helpers/order-imports
// eslint-disable-next-line import-helpers/order-imports
import { dateTimeRange } from '../../constants/common';
import { getPropertyList } from '../../store/property/api';
import {
  formatPriceWithDecimalValue,
  formatStatus,
  formattedDate,
  loggedInUserRole,
} from '../../utils';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import { formatDefectQuotesStatus } from '../WorkOrder/DefectQuotes';

const TableData = ({
  type,
  data,
  order = 'desc',
  setOrder = () => {},
  orderBy = '',
  setOrderBy = () => {},
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState({ columns: [], rows: [] });

  const [propertyDetailsList, setPropertyDetailsList] = useState([]);

  const { propertyList } = useSelector((state) => state.property);

  const findProperty = (property_uuid) => {
    const property = propertyDetailsList?.find(
      (prop) => prop.uuid === property_uuid
    );

    return property;
  };

  const handleOpenNewWindowLink = (link) => {
    const fullPath = `${window.location.origin}${link}`;

    window.open(fullPath, '_blank');
  };

  const getDynamicTableData = () => {
    switch (type) {
      case WORK_ORDERS: {
        const rows = data?.map((r) => ({
          ...r,
          id: r?.woid,
          technician: r?.technician?.technician_name,
          frequency:
            r?.property_equipment_routine_service_schedule?.frequencies?.code,
          status: r?.latest_status,
          work_order_type: r.work_order_type.toUpperCase().replace('_', '-'),
        }));
        const columns = [
          {
            field: 'id',
            headerName: t('attributes.work_order.id'),
            flex: 1,
            sortable: true,
          },
          {
            field: 'work_order_name',
            headerName: t('attributes.work_order.work_order_name'),
            flex: 1,
            sortable: false,
            renderCell: ({ row }) => (
              <Typography
                variant="body1"
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() =>
                  handleOpenNewWindowLink(
                    `${redirectURL[loggedInUserRole()][CHART_CONST.WORK_ORDERS]}/${row?.uuid}`
                  )
                }
              >
                {row.work_order_name}
              </Typography>
            ),
          },
          {
            field: 'work_order_type',
            headerName: t('attributes.work_order.work_order_type'),
            flex: 1,
            sortable: false,
          },
          {
            field: 'property',
            headerName: t('attributes.property.property'),
            flex: 1,
            sortable: false,
            renderCell: ({ row }) => (
              <Box>
                <Typography variant="body1">
                  {row?.property?.property_name}
                </Typography>
                <Typography variant="body2">
                  {row?.property?.address}
                </Typography>
              </Box>
            ),
          },
          {
            field: 'asset_count',
            headerName: t('attributes.work_order.asset_count'),
            flex: 1,
            sortable: false,
            renderCell: ({ row }) => (
              <Box
                sx={{
                  p: '10px',
                  height: 20,
                  width: 20,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid black',
                  borderRadius: '50%',
                  wordBreak: 'keep-all',
                }}
              >
                {row.asset_count}
              </Box>
            ),
          },
          {
            field: 'technician',
            headerName: t('attributes.technician.technician'),
            flex: 1,
            sortable: false,
          },
          {
            field: 'frequency',
            headerName: t('attributes.work_order.frequency'),
            flex: 1,
            sortable: false,
            renderCell: ({ row }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '4px',
                }}
              >
                {row.frequency ? (
                  <Box
                    sx={{
                      px: '16px',
                      py: '4px',
                      border: '1px solid black',
                      borderRadius: '24px',
                      bgcolor: DIVIDER_COLOR,
                    }}
                  >
                    {row.frequency}
                  </Box>
                ) : (
                  '-'
                )}
              </Box>
            ),
          },
          {
            field: 'status',
            headerName: t('attributes.work_order.status'),
            flex: 1,
            sortable: false,
            renderCell: ({ row }) => formatStatus(row.status),
          },
          {
            field: 'appointment_date',
            headerName: `${t('attributes.work_order.appointment')} ${t('attributes.work_order.date_nd_time')}`,
            flex: 1,
            sortable: false,
            renderHeader: () => (
              <Typography variant="body1" fontWeight={700}>
                {t('attributes.work_order.appointment')}
                <br />
                {t('attributes.work_order.date_nd_time')}
              </Typography>
            ),
            renderCell: ({ row }) => {
              if (row.schedule_start_date_time === '-') return '-';
              const {
                startFormattedDate,
                endFormattedDate,
                startTime,
                endTime,
              } = dateTimeRange(
                formattedDate(
                  row?.scheduled_start_date_time,
                  SCHEDULE_BOARD_WO_UTC_LOCAL
                ),
                formattedDate(
                  row?.scheduled_end_date_time,
                  SCHEDULE_BOARD_WO_UTC_LOCAL
                )
              );

              return startFormattedDate === endFormattedDate ? (
                <Box>
                  {startFormattedDate}
                  <br />
                  {`${startTime} to ${endTime}`}
                </Box>
              ) : (
                <Box>
                  <span>
                    {startFormattedDate} {startTime}
                  </span>
                  <br />
                  <span>
                    to {endFormattedDate} {endTime}
                  </span>
                </Box>
              );
            },
          },
        ];
        return { rows, columns };
      }

      case DEFECTS: {
        const rows = data?.map((r) => ({
          ...r,
          id: r?.id,
          customer_name: r?.property?.customer,
          property_name: r?.property?.property_name,
          asset: r?.asset?.label,
          description: r?.defect_description || '-',
          status: r?.latest_status,
        }));
        const columns = [
          {
            field: 'id',
            headerName: t('attributes.defects.defectsId'),
            flex: 1,
            sortable: true,
            renderCell: ({ row }) =>
              row?.property_name ? (
                <Typography
                  variant="body1"
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() =>
                    handleOpenNewWindowLink(
                      redirectURL[loggedInUserRole()][CHART_CONST.DEFECTS]
                    )
                  }
                >
                  {`D-${row.id}`}
                </Typography>
              ) : (
                '-'
              ),
          },
          {
            field: 'customer_name',
            headerName: `${t('attributes.customer.customer')}  ${t('attributes.name')}`,
            flex: 1,
            sortable: false,
          },
          {
            field: 'property_name',
            headerName: t('attributes.defects.propertyName'),
            flex: 1,
            sortable: false,
          },
          {
            field: 'asset',
            headerName: `${t('attributes.defects.asset')} ${t('attributes.name')}`,
            flex: 1,
            sortable: false,
          },
          {
            field: 'description',
            headerName: t('attributes.defects.description'),
            flex: 1,
            sortable: false,
          },
          {
            field: 'created_at',
            headerName: t('attributes.defects.identifiedDate'),
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => formattedDate(row?.created_at),
          },
          {
            field: 'status',
            headerName: t('attributes.defects.status'),
            flex: 1,
            sortable: false,
            renderCell: ({ row }) =>
              row?.status ? formatStatus(row.status) : '-',
          },
        ];
        return { rows, columns };
      }
      case DEFECT_QUOTES: {
        const rows = data?.map((r) => ({
          ...r,
          id: r?.id,
          work_order_id: r?.work_order_id || r?.work_order?.woid,
          quote_id: r?.quote_id || `Q-${r?.id}`,
          customer: r?.customer_details?.customer_name,
          property_name: findProperty(r?.property_uuid)?.property_name,
          property_address: findProperty(r?.property_uuid)?.address,
          amount: r?.quotation_items.reduce(
            (sum, item) => sum + item?.sell_price * item?.qty,
            0
          ),
        }));
        const columns = [
          {
            field: 'work_order_id',
            headerName: t('attributes.work_order.work_order_ID'),
            flex: 1,
            sortable: true,
          },
          {
            field: 'quote_id',
            headerName: t('attributes.work_order.quote_id'),
            flex: 1,
            sortable: false,
            renderCell: ({ row }) =>
              row?.quote_id ? (
                <Typography
                  variant="body1"
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() =>
                    handleOpenNewWindowLink(
                      redirectURL[loggedInUserRole()][CHART_CONST.QUOTES]
                    )
                  }
                >
                  {row?.quote_id}
                </Typography>
              ) : (
                '-'
              ),
          },
          {
            field: 'customer',
            headerName: t('roles.customer'),
            flex: 1,
            sortable: false,
          },
          {
            field: 'property_name',
            headerName: t('attributes.property.propertyName'),
            flex: 1,
            sortable: false,
          },
          {
            field: 'property_address',
            headerName: `${t('attributes.property.property')} ${t('attributes.address')}`,
            flex: 1,
            sortable: false,
          },
          {
            field: 'status',
            headerName: t('attributes.property.status'),
            flex: 1,
            sortable: false,
            renderCell: ({ row }) =>
              row?.status ? formatDefectQuotesStatus(row?.status, t) : '-',
          },
          {
            field: 'quote_date',
            headerName: t('attributes.work_order.quote_date'),
            flex: 1,
            sortable: false,
            renderCell: ({ row }) =>
              row?.quote_date ? formattedDate(row?.quote_date) : '-',
          },
          {
            field: 'created_at',
            headerName: t('attributes.work_order.due_date'),
            flex: 1,
            sortable: true,
            renderCell: ({ row }) =>
              row?.due_date ? formattedDate(row?.due_date) : '-',
          },
          {
            field: 'amount',
            headerName: t('attributes.amount'),
            flex: 1,
            sortable: false,
            renderCell: ({ row }) =>
              `${formatPriceWithDecimalValue(row?.amount)}`,
          },
        ];
        return { rows, columns };
      }
      default:
        break;
    }
  };

  useEffect(() => {
    const latestData = getDynamicTableData();
    setTableData({
      columns: latestData?.columns || [],
      rows: latestData?.rows || [],
    });
  }, [data, propertyDetailsList]);

  useEffect(() => {
    const req = {
      limit: -1,
    };

    dispatch(getPropertyList(req));
  }, []);

  useEffect(() => {
    setPropertyDetailsList(propertyList?.data?.data);
  }, [propertyList]);

  return (
    <CustomGridTable
      columns={tableData?.columns}
      rows={tableData.rows}
      paginationRequired={false}
      checkboxSelection={false}
      noData={<NoRecordFound />}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      disableColumnMenu={true}
      filterHeight={180}
    />
  );
};

export default TableData;
