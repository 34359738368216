import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddHomeWorkOutlinedIcon from '@mui/icons-material/AddHomeWorkOutlined';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SaveIcon from '@mui/icons-material/Save';
import SettingsAccessibilityOutlinedIcon from '@mui/icons-material/SettingsAccessibilityOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import {
  Box,
  Chip,
  Divider,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from '@mui/material';
import { debounce, uniqueId } from 'lodash';

import { theme } from '../../assets/theme';
import {
  DIVIDER_COLOR,
  SCROLLBAR_THUMB_BACKGROUND,
  SCROLLBAR_TRACK_BACKGROUND,
  SCROLLBAR_TRACK_BOXSHADOW,
  SECONDARY,
} from '../../constants/Colors';
import { validateAddress } from '../../constants/common';
import {
  certificationNumberDropdown,
  DP_DN,
  NON_CONTRACT,
} from '../../constants/Constants';
import { Validation } from '../../constants/FieldValidationMsg';
import { SUPER_ADMIN } from '../../constants/Roles';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import getStatesHook from '../../hooks/getStatesHook';
import useAddressDetail from '../../hooks/useAddressDetail';
import useAutocompleteAddress from '../../hooks/useAutcompleteAddress';
import { createBillingCard } from '../../store/billing/api';
import { snackbarToggle } from '../../store/CommonReducer';
import {
  createProperty,
  getLocalGovernmentAndZone,
  getParentPropertyList,
  getPropertyBillingCardList,
  getSiteRequirementsList,
  updateProperty,
} from '../../store/property/api';
import {
  resetParentPropertyList,
  resetPropertyBillingCardList,
} from '../../store/property/reducer';
import { getStandardsDropdownList } from '../../store/standards/api';
import { resetStandardsDropdownList } from '../../store/standards/reducer';
import { getBillingContractList } from '../../store/users/billingContract/api';
import { resetBillingContractList } from '../../store/users/billingContract/reducer';
import {
  clearTextfields,
  datePickerFormat,
  findLabelByLabel,
  findLabelByValue,
  formatDateAPI,
  loggedInUserRole,
  showHideTopbarLoader,
} from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CreateCityModal from '../CommonComponents/CreateCityModal';
import CustomButton from '../CommonComponents/CustomButton';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomDatePicker from '../CommonComponents/CustomDatePicker';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';
import CreateCustomerBill from './CreateCustomerBillModal';

const postCodeLength = 4;

const stepIcons = [
  <AddHomeWorkOutlinedIcon
    key="icon-01"
    sx={{ height: '15px', width: '15px' }}
  />,
  <SettingsAccessibilityOutlinedIcon
    key="icon-02"
    sx={{ height: '15px', width: '15px' }}
  />,
  <ChecklistOutlinedIcon
    key="icon-03"
    sx={{ height: '15px', width: '15px' }}
  />,
  <DomainAddOutlinedIcon
    key="icon-04"
    sx={{ height: '15px', width: '15px' }}
  />,
  <WorkspacePremiumOutlinedIcon
    key="icon-05"
    sx={{ height: '15px', width: '15px' }}
  />,
];

// Custom Connector with dotted line
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderColor: theme.palette.grey,
    borderWidth: 2,
    borderLeftStyle: 'dotted',
  },
}));

// Custom Step Icon
const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: ownerState.active ? theme.palette.primary.main : 'white',
  color: ownerState.active ? 'white' : 'black',
  zIndex: 1,
  width: 26,
  height: 26,
  border: ownerState.active ? '' : '1px solid black',
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.completed && {
    backgroundColor: 'black',
    color: 'white',
  }),
}));

const CustomStepIcon = ({ icon, active, completed, className }) => (
  <CustomStepIconRoot ownerState={{ completed, active }} className={className}>
    {stepIcons[icon - 1]}
  </CustomStepIconRoot>
);
const LayoutContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  height: '100%',
}));

const Section = styled(Box)(({ theme }) => ({
  display: 'flex',
  background: theme.palette.secondary.main,
  flexDirection: 'column',
  padding: '16px',
  borderRadius: '4px',
  gap: '16px',
}));

const RowBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '16px',
}));

export const PropertyForm = ({
  formMode,
  propertyData,
  formStatusList,
  accessScheduleList,
  accessProcedureList,
  buildingEraList,
  buildingClassList,
  constructionClassList,
  buildingTypeList,
  localGovermentAreaList,
  customerList,
  isOpen,
  activeStep,
  setActiveStep,
  isFromDetails,
  zoneList,
  isZoneLoading,
  setServerErrors,
  createLoading,
  updateLoading,
  setSelectedProperty,
  isPropertyDetailsLoading,
  serverErrors,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Selector for standards dropdown
  const { standardsDropdownLoading, standardsDropdownData } =
    getDropdownListHook({
      reducerName: 'standards',
      dropdownListName: 'standardsDropdownList',
      labelName: 'code',
      valueName: 'id',
    });

  // Selector for billing card dropdown
  const { billingCardDropdownLoading, billingCardDropdownData } =
    getDropdownListHook({
      reducerName: 'property',
      dropdownListName: 'propertyBillingCardList',
      customNameForDropdown: 'billingCard',
      loading: 'loading',
      labelName: 'billing_name',
      valueName: 'uuid',
    });

  // Selector for contract dropdown
  const { contractDropdownLoading, contractDropdownData } = getDropdownListHook(
    {
      reducerName: 'billingContract',
      dropdownListName: 'getList',
      customNameForDropdown: 'contract',
      fetchDataFrom: 'billingContractActiveList',
      labelName: 'name',
      valueName: 'uuid',
    }
  );

  const {
    control,
    handleSubmit,
    trigger,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useFormContext();

  const [addressBlur, setAddressBlur] = useState(true);
  const [address2Blur, setAddress2Blur] = useState(false);
  const [stateBlur, setStateBlur] = useState(true);
  const [cityBlur, setCityBlur] = useState(true);
  const [postcodeBlur, setPostcodeBlur] = useState(true);
  const [coords, setCoords] = useState(null);
  const [open, setOpen] = useState(false);
  const [createBillCardDisabled, setCreateBillCardDisabled] = useState(false);
  const state = watch('stateId');
  const city = watch('cityId');
  const address = watch('propertyAddress');
  const addressLine2 = watch('address_line_2');
  const postcode = watch('postCode');
  const [addressRecheck, setAddressRecheck] = useState(false);
  const [openCity, setOpenCity] = useState(false);

  const {
    isLoading: isAutocompleteAddressLoading,
    data: autocompleteAddressData,
  } = useSelector((state) => state.googleAPIs.autocompleteAddress);
  const typedValue = watch('searchAddress');
  const [searchAddress, setSearchAddress] = useState('');
  const prevValues = useRef({});
  const watchedValues = useWatch({ control });
  const { loading: listLoading, data: propertySiteRequirementData } =
    useSelector((state) => state.property.getSiteRequirementList);

  useAutocompleteAddress(searchAddress);
  useAddressDetail({
    selectedAddress: typedValue,
    setValue: setValue,
    fieldName: {
      address1: 'propertyAddress',
      stateKey: 'stateId',
      cityKey: 'cityId',
      postCodeKey: 'postCode',
      address2: 'address_line_2',
    },
  });

  useEffect(() => {
    if (
      state &&
      (city || true) &&
      address &&
      postcode &&
      (addressLine2 || true)
    ) {
      setAddressBlur(true);
      setStateBlur(true);
      setCityBlur(true);
      setPostcodeBlur(true);
      setAddressRecheck(true);
      setAddress2Blur(true);
    }
  }, [state, city, address, postcode, addressLine2]);

  useEffect(() => {
    validateAddress({
      address1: address,
      address2: addressLine2,
      city: city,
      state: state,
      postcode: postcode,
      clearErrors,
      addressRecheck,
      setCoords,
      fieldName: {
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'postCode',
        postCodeValue: t('attributes.postCode'),
        address1Key: 'propertyAddress',
        address1Value: t('attributes.addressLine1'),
        address2Key: 'address_line_2',
        address2Value: t('attributes.addressLine2'),
      },
      setServerErrors,
    });
  }, [addressBlur, address2Blur, stateBlur, cityBlur, postcodeBlur]);

  const { technicianDropdownData } = getDropdownListHook({
    reducerName: 'technician',
    dropdownListName: 'technicianNamesDropdownList',
    labelName: 'name',
    valueName: 'uuid',
  });

  const selectedState = watch('stateId');

  const {
    error,
    bill,
    isLoading: createBillLoading,
  } = useSelector((state) => state?.billing?.create);

  useEffect(() => {
    if (serverErrors.length > 0) {
      serverErrors.forEach(({ field }) => {
        clearErrors(field); // Clear error for this field when it changes
      });
    }
  }, [watchedValues]);

  useEffect(() => {
    showHideTopbarLoader(createBillLoading, dispatch);
    if (!createBillLoading) {
      if (error) {
        if (error.errorDetails) {
          setServerErrors(error.errorDetails);
        } else {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: error.message,
            })
          );
        }
      }
      if (!error && bill) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: bill?.message,
          })
        );
        setOpen(false);
      }
    }
    setCreateBillCardDisabled(false);
  }, [error, createBillLoading]);

  const stateList = getStatesHook();
  const cityList = getCityByStateHook(selectedState?.value);

  const formRef = useRef(null);
  const [isNextReady, setIsNextReady] = useState(true);
  const [billingCardIdDisabled, setBillingCardIdDisabled] = useState(true);
  const [contractUUIDDisabled, setContractUUIDDisabled] = useState(true);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    dispatch(getStandardsDropdownList());
    if (isOpen && formRef.current) {
      formRef.current.scrollTop = 0; // Scroll to top
    }

    return () => {
      dispatch(resetStandardsDropdownList());
      setActiveStep(0);
      reset();
    };
  }, [isOpen]);

  const [parentCustomerUUID, setParentCustomerUUID] = useState(null);

  useEffect(() => {
    dispatch(
      getParentPropertyList({
        limit: -1,
        customerUuid: parentCustomerUUID?.value,
      })
    );
  }, [parentCustomerUUID]);

  const { parentPropertyDropdownLoading, parentPropertyDropdownData } =
    getDropdownListHook({
      reducerName: 'property',
      dropdownListName: 'parentPropertyList',
      customNameForDropdown: 'parentProperty',
      labelName: 'property_name',
      valueName: 'uuid',
    });

  useEffect(() => {
    setParentCustomerUUID(getValues('parentPropertyCustomer'));
    setValue('parentPropertyId', null);

    return () => {
      dispatch(resetParentPropertyList());
    };
  }, [getValues('parentPropertyCustomer')]);

  useEffect(() => {
    if (!getValues('parentPropertyAssociateCheckbox')) {
      setValue('parentPropertyId', null);
    }
  }, [getValues('parentPropertyAssociateCheckbox')]);

  // Billing Card Start
  // Use `useEffect` to check `customerId` only when it changes
  useEffect(() => {
    const customerIdValue = getValues('customerId');

    setBillingCardIdDisabled(!customerIdValue); // Disable if `customerId` is empty
    setContractUUIDDisabled(!customerIdValue); // Disable if `customerId` is empty

    if (customerIdValue?.value) {
      setContractUUIDDisabled((prev) =>
        customerIdValue?.type === NON_CONTRACT ? 'hidden' : prev
      );

      dispatch(
        getPropertyBillingCardList({
          limit: -1,
          customer_uuid: customerIdValue?.value,
        })
      );

      customerIdValue?.type !== NON_CONTRACT &&
        dispatch(
          getBillingContractList({
            limit: -1,
            customerUUID: customerIdValue?.value,
            active: true,
          })
        );
    }

    return () => {
      dispatch(resetPropertyBillingCardList());
      dispatch(resetBillingContractList());
    };
  }, [getValues('customerId'), bill]); // Dependency on `customerId` value

  // get local government area and zone based on postcode

  const [localGovernmentAreaLoading, setLocalGovernmentAreaLoading] =
    useState(false);
  const { data: localGovernmentZoneData } = useSelector(
    (state) => state.property.localGovernmentAndZone
  );

  const getLocalGovernmentAreaAndZone = useCallback(() => {
    const { postcode: prevPostcode, typedValue: prevTypedValue } =
      prevValues.current;

    const isPostcodeChanged = postcode !== prevPostcode;
    const isTypedValueChanged = typedValue?.value !== prevTypedValue;

    prevValues.current = {
      postcode,
      typedValue: typedValue?.value,
    };

    if (postcode && isPostcodeChanged && isTypedValueChanged) {
      setLocalGovernmentAreaLoading(true);

      dispatch(getLocalGovernmentAndZone({ post_code: postcode })).finally(() =>
        setLocalGovernmentAreaLoading(false)
      );
    }
  }, [postcode]);

  const debouncedFetchData = useCallback(
    debounce(getLocalGovernmentAreaAndZone, 500),
    [getLocalGovernmentAreaAndZone]
  );

  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    setValue(
      'localGovermentArea',
      findLabelByLabel(
        localGovermentAreaList,
        localGovernmentZoneData?.local_government_areas
      ),
      { shouldValidate: false }
    );
    setValue(
      'zones',
      findLabelByLabel(zoneList, localGovernmentZoneData?.zone),
      {
        shouldValidate: false,
      }
    );
  }, [localGovernmentZoneData]);

  // Billing Card End

  // Create new billing card
  const addNewCustomerBilling = async () => {
    const isValid = await trigger('customerId');
    const customer = getValues('customerId');

    if (customer) {
      setOpen(true);
    }
  };

  const createNewCustomerBill = (value) => {
    setCreateBillCardDisabled(true);
    const bodyData = {
      customer_uuid: value?.customer,
      billing_name: value?.billing_name,
      contact_name: value?.contact_name,
      abn_number: value?.abn_number?.length ? value?.abn_number : null,
      email: value?.email,
      phone: value?.phone,
      address: value?.address,
      address_line_2: value?.address_line_2 || '',
      city: value?.city?.value,
      state: value?.state?.value,
      postcode: value?.post_code,
    };

    dispatch(createBillingCard(bodyData));
  };
  // Create new billing card end

  useEffect(() => {
    if (formMode) {
      !isFromDetails && setActiveStep(0);
      reset({
        propertyName: '',
        propertyAddress: '',
        address_line_2: '',
        cityId: null,
        stateId: null,
        postCode: '',
        customerId: null,
        accountManagerId: null,
        statusId: null,
        landLotNumber: '',
        parentPropertyId: null,
        parentPropertyAssociateCheckbox: false,
        property_identification_number: '',
        certificationNumber: null,
        annualComplianceDate: null,
        contractStartDate: null,
        contractEndDate: null,
        technician: null,
        localGovermentArea: '',
        internalNote: '',
        zones: '',
        billingCardId: '',
        propertyManagerName: '',
        propertyManagerEmail: '',
        propertyManagerPhone: '',
        accessCode: '',
        accessNote: '',
        accessSchedule: [],
        signatureRequired: false,
        inductionRequired: false,
        logBookEntryRequired: false,
        policeCheckRequired: false,
        workingWithChildernCheckRequired: false,
        serviceRequirement: '',
        safetyRequirement: '',
        building_size: [],
        storiesAboveGround: '',
        storiesBelowGround: '',
        occupancyPermitNumber: '',
        occupancyPermitDate: null,
        occupancyPermitLocation: '',
        active_billing_contract_uuid: '',
        buildingPart: '',
      });
    }

    if (formMode === 'edit' && propertyData) {
      let checkboxToBeTicked = {};

      propertyData?.site_requirements_details?.forEach((item) => {
        checkboxToBeTicked[`${item.text}-${item.id}`] = true;
      });
      setCoords({
        longitude: propertyData.longitude || '',
        latitude: propertyData.latitude || '',
      });
      setAddressBlur(true);
      setCityBlur(true);
      setStateBlur(true);
      setPostcodeBlur(true);
      reset({
        propertyName: propertyData.property_name || '',
        propertyAddress: propertyData.address || '',
        address_line_2: propertyData.address_line_2 || '',
        stateId: { label: propertyData?.state, value: propertyData?.state },
        cityId: propertyData?.city
          ? {
              label: propertyData?.city,
              value: propertyData?.city,
            }
          : null,
        postCode: propertyData.zip_code || '',
        propertyManagerName: propertyData?.property_contact_info?.name || '',
        propertyManagerEmail: propertyData?.property_contact_info?.email || '',
        propertyManagerPhone: propertyData?.property_contact_info?.phone || '',
        customerId: findLabelByValue(customerList, propertyData?.customer_uuid),
        billingCardId: {
          label: propertyData?.billing_card_details?.billing_name || '',
          value: propertyData?.billing_card_details?.uuid || '',
        },
        parentPropertyAssociateCheckbox: propertyData?.parent_property_uuid
          ? true
          : false,
        zones: findLabelByValue(zoneList, propertyData?.zone) || '',
        statusId: findLabelByValue(
          formStatusList,
          propertyData?.property_status?.id
        ),
        landLotNumber: propertyData?.lot_number || '',
        certificationNumber: certificationNumberDropdown?.find(
          (d) => d?.value === propertyData?.property_identification_type
        ),
        property_identification_number:
          propertyData?.property_identification_number || '',
        ocspDpDnNumber: propertyData?.ocsp_number || '',
        annualComplianceDate: propertyData?.contract_assignment_details
          ?.annual_compliance_date
          ? datePickerFormat(
              propertyData?.contract_assignment_details?.annual_compliance_date
            )
          : null,
        contractStartDate: propertyData?.contract_assignment_details
          ?.contract_start_date
          ? datePickerFormat(
              propertyData?.contract_assignment_details?.contract_start_date
            )
          : null,
        contractEndDate: propertyData?.contract_assignment_details
          ?.contract_expiry_date
          ? datePickerFormat(
              propertyData?.contract_assignment_details?.contract_expiry_date
            )
          : null,
        technician: propertyData?.contract_assignment_details?.technician_uuid
          ? {
              label: propertyData?.contract_assignment_details?.technician,
              value: propertyData?.contract_assignment_details?.technician_uuid,
            }
          : null,
        contractName:
          propertyData?.contract_assignment_details?.contract_name || '',
        localGovermentArea: findLabelByValue(
          localGovermentAreaList,
          propertyData?.local_government_area_name
        ),
        internalNote: propertyData?.internal_note || '',
        accessCode: propertyData.access_details?.[0]?.access_code || '',
        accessNote: propertyData.access_details?.[0]?.access_note || '',
        accessSchedule:
          propertyData.access_details?.[0]?.access_detail_schedule?.map((ads) =>
            findLabelByValue(accessScheduleList, ads.schedules?.id)
          ) || [],
        accessProcedure:
          propertyData.access_details?.[0]?.access_detail_procedure?.map(
            (adp) => findLabelByValue(accessProcedureList, adp.procedures?.id)
          ) || [],
        signatureRequired:
          propertyData.site_requirements?.[0]?.signature_required || false,
        inductionRequired:
          propertyData.site_requirements?.[0]?.induction_required || false,
        logBookEntryRequired:
          propertyData.site_requirements?.[0]?.log_book_entry_required || false,
        policeCheckRequired:
          propertyData.site_requirements?.[0]?.police_check_required || false,
        isBookingRequired: propertyData?.is_booking_required || false,
        workingWithChildernCheckRequired:
          propertyData.site_requirements?.[0]
            ?.working_with_children_check_required || false,
        serviceRequirement:
          propertyData.site_requirements?.[0]?.service_requirements || '',
        safetyRequirement:
          propertyData.site_requirements?.[0]?.technician_requirements || '',
        buildingEra: findLabelByValue(
          buildingEraList,
          propertyData.building_attributes?.[0]?.property_building_era?.id
        ),
        buildingPart:
          propertyData?.building_attributes[0]?.building_part_description,
        building_class:
          propertyData.building_classes?.map((bc) => ({
            label: bc?.display_name,
            value: bc?.id,
          })) || [],
        buildingType: findLabelByValue(
          buildingTypeList,
          propertyData.building_attributes?.[0]?.property_building_type?.id
        ),
        building_size:
          propertyData.building_attributes?.[0]?.building_size || [],
        storiesAboveGround: String(
          propertyData.building_attributes?.[0]?.stories_above_ground ?? ''
        ),
        storiesBelowGround: String(
          propertyData.building_attributes?.[0]?.stories_below_ground ?? ''
        ),
        constructionClass: findLabelByValue(
          constructionClassList,
          propertyData.building_attributes?.[0]?.property_construction_class?.id
        ),
        // buildingStandards: findLabelByValue(
        //   standardsDropdownData,
        //   propertyData.standard_id
        // ),
        occupancyPermitNumber:
          propertyData.occupancy_permit?.[0]?.op_number || '',
        occupancyPermitDate: propertyData.occupancy_permit?.[0]?.op_date
          ? datePickerFormat(propertyData.occupancy_permit[0]?.op_date)
          : null,
        occupancyPermitLocation:
          propertyData.occupancy_permit?.[0]?.op_location || '',
        ...checkboxToBeTicked,
      });
    }
  }, [isOpen, formMode, propertyData, reset]);

  const onSubmit = (data) => {
    // Initialize nested objects
    let accessDetail = {};
    let siteRequirements = {};
    let occupancyPermit = {};
    let buildingAttributes = {};
    let contract_detail = {};
    let building_classes = [];
    let site_requirement_id = propertySiteRequirementData
      ?.filter((item, index) => data[`${item.text}-${item.id}`] === true)
      ?.map((item) => item?.id);

    // Conditionally add fields to accessDetail
    if (data.accessCode) accessDetail.access_code = data.accessCode;
    if (data.accessNote) accessDetail.access_note = data.accessNote;
    if (data.accessSchedule?.length) {
      accessDetail.property_access_schedule = data.accessSchedule.map(
        (accSchedule) => accSchedule?.value
      );
    }

    if (data.accessProcedure?.length) {
      accessDetail.property_access_procedure = data.accessProcedure.map(
        (accProcedure) => accProcedure?.value
      );
    }
    if (data.safetyRequirement)
      // Conditionally add fields to siteRequirements
      siteRequirements.technician_requirements = data.safetyRequirement;
    if (data.serviceRequirement)
      siteRequirements.service_requirements = data.serviceRequirement;
    if (data.signatureRequired !== undefined)
      siteRequirements.signature_required = data.signatureRequired;
    if (data.inductionRequired !== undefined)
      siteRequirements.induction_required = data.inductionRequired;
    if (data.logBookEntryRequired !== undefined)
      siteRequirements.log_book_entry_required = data.logBookEntryRequired;
    if (data.policeCheckRequired !== undefined)
      siteRequirements.police_check_required = data.policeCheckRequired;
    if (data.workingWithChildernCheckRequired !== undefined)
      siteRequirements.working_with_children_check_required =
        data.workingWithChildernCheckRequired;

    // Conditionally add fields to occupancyPermit
    if (data.occupancyPermitNumber)
      occupancyPermit.op_number = data.occupancyPermitNumber;
    if (data.occupancyPermitDate)
      occupancyPermit.op_date = formatDateAPI(data.occupancyPermitDate);
    if (data.occupancyPermitLocation)
      occupancyPermit.op_location = data.occupancyPermitLocation;
    if (data.buildingPart)
      buildingAttributes.building_part_description = data?.buildingPart;

    if (data.building_size)
      buildingAttributes.building_size = data.building_size;
    if (data.storiesAboveGround)
      buildingAttributes.stories_above_ground = data.storiesAboveGround;
    if (data.storiesBelowGround)
      buildingAttributes.stories_below_ground = data.storiesBelowGround;
    // if (data.buildingStandards)
    //   buildingAttributes.building_standard = data.buildingStandards?.value;
    if (data.buildingEra)
      buildingAttributes.building_era = data.buildingEra?.value;
    if (data.building_class)
      building_classes = data.building_class.map((bc) => bc?.value);
    if (data.buildingType)
      buildingAttributes.building_type = data.buildingType?.value;
    if (data.constructionClass)
      buildingAttributes.construction_class = data.constructionClass?.value;

    if (data.annualComplianceDate)
      contract_detail.annual_compliance_date = formatDateAPI(
        data.annualComplianceDate
      );

    // TODO: Remove this comment once the contract module is ready.
    // if (data.contractStartDate)
    //   contract_detail.contract_start_date = formatDateAPI(
    //     data.contractStartDate
    //   );
    // if (data.contractEndDate)
    //   contract_detail.contract_expiry_date = formatDateAPI(
    //     data.contractEndDate
    //   );
    if (data.technician)
      contract_detail.technician_uuid = data.technician?.value;
    if (data?.active_billing_contract_uuid?.value) {
      contract_detail.active_billing_contract_uuid =
        data.active_billing_contract_uuid.value;
      contract_detail.contract_name = data.contractName || '';
    }

    // Prepare the main preparedData object
    const preparedData = {
      ...(data.propertyName ? { property_name: data.propertyName } : {}),
      customer_reference: 'customer reference came here',
      longitude: coords?.longitude?.toString() || '',
      latitude: coords?.latitude?.toString() || '',
      ...(data.propertyAddress ? { address: data.propertyAddress } : {}),
      ...(data.address_line_2 ? { address_line_2: data.address_line_2 } : {}),
      ...(data.stateId || data.stateId?.value
        ? { state: data.stateId?.value || data.stateId }
        : {}),
      ...(data.cityId || data.cityId?.value
        ? { city: data.cityId?.value || data.cityId }
        : null),
      ...(data.postCode ? { zip_code: data.postCode } : {}),
      ...(data.customerReference
        ? { customer_reference: data.customerReference }
        : {}),
      zone: data.zones?.value,
      building_class_association: {
        building_class: building_classes?.length > 0 ? building_classes : [],
      },
      site_requirements_details: {
        site_requirement_id:
          site_requirement_id?.length > 0 ? site_requirement_id : [],
      },
      is_booking_required: data?.isBookingRequired ? true : false,
      ...(data.ocspDpDnNumber ? { ocsp_number: data.ocspDpDnNumber } : {}),
      ...(data.localGovermentArea?.value
        ? { local_government_area_name: data.localGovermentArea.value }
        : {}),
      ...(data.internalNote ? { internal_note: data.internalNote } : {}),
      ...(data.landLotNumber ? { lot_number: data.landLotNumber } : {}),
      ...(data.parentPropertyId && data.parentPropertyAssociateCheckbox
        ? { parent_property_uuid: data.parentPropertyId?.value }
        : {}),
      ...(data.statusId?.value ? { status: data.statusId.value } : {}),
      ...(data.customerId?.value
        ? { customer_uuid: data.customerId.value }
        : {}),
      ...(data.accountManagerId?.value
        ? { account_manager: data.accountManagerId.value }
        : {}),
      ...(data.billingCardId?.value
        ? { billing_card_uuid: data.billingCardId.value }
        : {}),
      ...(Object.keys(accessDetail).length
        ? { access_detail: accessDetail }
        : {}),
      ...(Object.keys(siteRequirements).length
        ? { site_requirements: siteRequirements }
        : {}),
      ...(Object.keys(occupancyPermit).length
        ? { occupancy_permit: occupancyPermit }
        : {}),
      ...(Object.keys(buildingAttributes).length
        ? { building_attributes: buildingAttributes }
        : {}),
      ...(Object.keys(contract_detail).length
        ? { contract_detail: contract_detail }
        : {}),
      property_contact_info: {
        name: data?.propertyManagerName,
        phone: data?.propertyManagerPhone,
        email: data?.propertyManagerEmail,
      },
      property_identification_type: data?.certificationNumber?.value,
      property_identification_number: data?.property_identification_number,
      // standard_id: data.buildingStandards?.value,
    };

    // Dispatch the prepared data
    dispatch(
      formMode === 'edit'
        ? updateProperty({ uuid: propertyData?.uuid, data: preparedData })
        : createProperty(preparedData)
    ).then((res) => {
      if (res.payload?.errorDetails && res.payload.errorDetails?.length > 0) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: 'Something went wrong! Please check the data you entered or try again later.',
          })
        );
      } else {
        setSelectedProperty(null);
        setActiveStep(0);
      }
    });
  };

  const handleNext = async () => {
    setIsNextReady(false);

    const isValid = await trigger(); // Trigger validation for all fields in the current step
    let hasError = false;

    if (activeStep === 0) {
      hasError = await validateAddress({
        address1: address,
        address2: addressLine2,
        city: city,
        state: state,
        postcode: postcode,
        clearErrors,
        addressRecheck,
        setCoords,
        fieldName: {
          stateKey: 'state',
          stateValue: t('common.state'),
          cityKey: 'city',
          cityValue: t('common.city'),
          postCodeKey: 'postCode',
          postCodeValue: t('attributes.postCode'),
          address1Key: 'propertyAddress',
          address1Value: t('attributes.addressLine1'),
          address2Key: 'address_line_2',
          address2Value: t('attributes.addressLine2'),
        },
        setServerErrors,
      });
      trigger();
    }

    if (hasError) {
      setIsNextReady(true);

      return;
    }

    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setIsNextReady(true);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    `${t('attributes.property.property')} ${t('attributes.company.details')}`,
    `${t('attributes.property.access')} ${t('attributes.company.details')}`,
    t('attributes.property.siteRequirement'),
    t('attributes.property.buildingAttributes'),
    t('attributes.property.occupancyPermit'),
  ];

  const handleStepClick = async (index) => {
    const isValid = await trigger(); // Optional: Validate the current step

    if (isValid) {
      setActiveStep(index); // Set the step index directly
    }
  };

  useEffect(() => {
    // Set default standard value when dropdown data is available
    if (
      formMode === 'edit' &&
      propertyData &&
      contractDropdownData?.length > 0 &&
      !watch('active_billing_contract_uuid')
    ) {
      setValue(
        'active_billing_contract_uuid',
        findLabelByValue(
          contractDropdownData,
          propertyData?.contract_assignment_details
            ?.active_billing_contract_uuid
        ),
        {
          shouldValidate: false,
        }
      );
    }
  }, [contractDropdownData, setValue, watch]);

  useEffect(() => {
    // Set default standard value when dropdown data is available
    if (
      formMode === 'edit' &&
      propertyData &&
      parentPropertyDropdownData?.length > 0 &&
      !watch('parentPropertyId')
    ) {
      setValue(
        'parentPropertyId',
        findLabelByValue(
          parentPropertyDropdownData,
          propertyData?.parent_property_uuid
        ),
        {
          shouldValidate: false,
        }
      );
    }
  }, [parentPropertyDropdownData, propertyData, setValue, watch]);

  useEffect(() => {
    // Set default standard value when dropdown data is available
    if (
      formMode === 'edit' &&
      propertyData &&
      customerList &&
      !watch('parentPropertyCustomer')
    ) {
      setValue(
        'parentPropertyCustomer',
        findLabelByValue(
          customerList,
          propertyData?.parent_property_data?.customer_uuid
        ),
        {
          shouldValidate: false,
        }
      );
    }
  }, [propertyData, setValue, watch, customerList]);

  useEffect(() => {
    dispatch(getSiteRequirementsList({ limit: -1 }));
  }, []);

  return (!propertyData && formMode === 'edit') ||
    isPropertyDetailsLoading ||
    listLoading ? (
    <CustomCircularLoader />
  ) : (
    <>
      <LayoutContainer>
        {/* Custom Stepper */}
        <Box
          sx={{
            position: 'block',
            width: '259px',
            padding: '16px',
            background: SECONDARY,
            marginRight: '16px',
            borderRadius: '4px',
            height: '84vh',
          }}
        >
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            connector={<CustomConnector />}
          >
            {steps.map((label, idx) => (
              <Step
                key={label}
                onClick={
                  formMode === 'edit' ? () => handleStepClick(idx) : undefined
                }
                sx={{ cursor: formMode === 'edit' ? 'pointer' : 'default' }}
              >
                <StepLabel StepIconComponent={CustomStepIcon}>
                  <Typography
                    variant={idx <= activeStep ? 'body1' : 'body2'}
                    sx={{
                      color:
                        idx === activeStep
                          ? theme.palette.primary.main
                          : 'black',
                      cursor: formMode === 'edit' ? 'pointer' : 'default',
                    }}
                  >
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box
          component="form"
          ref={formRef}
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            maxHeight: '84vh',
            overflowX: 'hidden',
            overflowY: 'auto',
            width: '100%',
            '&::-webkit-scrollbar': {
              width: '10px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: SCROLLBAR_TRACK_BACKGROUND, // Fixed: Proper string format
              boxShadow: SCROLLBAR_TRACK_BOXSHADOW,
              borderRadius: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: SCROLLBAR_THUMB_BACKGROUND,
              borderRadius: '4px',
              width: '4px',
            },
            //scrollbarWidth: 'none',
          }}
        >
          {/* Property details section */}
          <Box
            sx={{
              background: SECONDARY,
              borderRadius: '4px',
              width: '468px',
            }}
          >
            {activeStep === 0 && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  background: DIVIDER_COLOR,
                }}
              >
                <Box>
                  <Box
                    sx={{
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      background: SECONDARY,
                      borderRadius: '4px 4px 0 0',
                    }}
                  >
                    <Typography variant="h2" style={{ paddingLeft: '16px' }}>
                      {steps[0]}
                    </Typography>
                    {formMode === 'edit' && (
                      <Typography variant="h2" style={{ paddingRight: '16px' }}>
                        {t('attributes.property.propertyId')}:{' '}
                        {propertyData?.pid}
                      </Typography>
                    )}
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      background: theme.palette.secondary.main,
                      flexDirection: 'column',
                      padding: '16px',
                      borderRadius: '0 0 4px 4px',
                      gap: '16px',
                    }}
                  >
                    <Controller
                      name="propertyName"
                      control={control}
                      rules={{
                        required: `${t('attributes.property.property')} ${t('attributes.name')} ${Validation.general.required}`,
                        maxLength: {
                          value: 100,
                          message: Validation.property.name,
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <CustomTextField
                          label={`${t('attributes.property.property')} ${t('attributes.name')}`}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            trigger('propertyName');
                          }}
                          onClear={() => {
                            onChange('');
                          }}
                          helperText={error ? error.message : ''}
                          error={error}
                        />
                      )}
                    />

                    <Controller
                      name="searchAddress"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          label={t('common.searchAddress')}
                          options={autocompleteAddressData || []}
                          value={value}
                          isRequired={false}
                          isLoadingData={isAutocompleteAddressLoading}
                          onChange={(e, newValue) => {
                            if (newValue || newValue === null) {
                              onChange(newValue);
                            }
                            setSearchAddress(e.target?.value);
                          }}
                          helperText={error ? error.message : ''}
                          error={error}
                        />
                      )}
                    />
                    <Controller
                      name="propertyAddress"
                      control={control}
                      rules={{
                        required: `${t('attributes.addressLine1')} ${Validation.general.required}`,

                        maxLength: {
                          value: 300,
                          message: Validation.general.address,
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <CustomTextField
                          label={t('attributes.addressLine1')}
                          multiline
                          rows={3}
                          value={value || ''}
                          setBlur={setAddressBlur}
                          onChange={(e) => {
                            onChange(e);
                            trigger('propertyAddress');
                          }}
                          onClear={() => {
                            onChange('');
                          }}
                          helperText={error ? error.message : ''}
                          error={error}
                        />
                      )}
                    />
                    <Controller
                      name={'address_line_2'}
                      control={control}
                      rules={{
                        maxLength: {
                          value: 300,
                          message: Validation.general.address2,
                        },
                      }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <CustomTextField
                          label={t('attributes.addressLine2')}
                          fullWidth
                          value={value || ''}
                          onChange={(e) => {
                            onChange(e);
                            trigger('address_line_2');
                          }}
                          isRequired={false}
                          multiline
                          rows={2}
                          helperText={error ? error.message : ''}
                          error={error}
                          onClear={() => clearTextfields(setValue, name)}
                          setBlur={setAddress2Blur}
                        />
                      )}
                    />

                    <RowBox>
                      <Controller
                        name="stateId"
                        control={control}
                        rules={{
                          required: `${t('common.state')} ${Validation.general.required}`,
                        }}
                        render={({
                          field: { onChange, value, name },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            options={stateList}
                            fullWidth
                            label={t('common.state')}
                            setBlur={setStateBlur}
                            onChange={(e, newValue) => {
                              if (newValue || newValue === null) {
                                onChange(newValue);
                              }
                              trigger('stateId');
                              setValue('cityId', null);
                              clearErrors('cityId');
                            }}
                            value={value}
                            helperText={error ? error.message : ''}
                            onClear={() => clearTextfields(setValue, name)}
                            error={error}
                          />
                        )}
                      />
                      <Controller
                        name="cityId"
                        control={control}
                        render={({
                          field: { onChange, value, name },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            options={cityList}
                            label={t('common.city')}
                            isRequired={false}
                            setBlur={setCityBlur}
                            onChange={(e, newValue) => {
                              if (newValue || newValue === null) {
                                onChange(newValue);
                              }
                              trigger('cityId');
                            }}
                            value={value}
                            helperText={error ? error.message : ''}
                            error={error}
                            disabledDropdown={!selectedState}
                            onClear={() => clearTextfields(setValue, name)}
                          />
                        )}
                      />
                    </RowBox>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: '-16px 0px',
                      }}
                    >
                      <CustomButton
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        text={t('attributes.add')}
                        style={{ textDecoration: 'underline' }}
                        variant="text"
                        onClick={() => setOpenCity(true)}
                      />
                    </Box>
                    <Controller
                      name="postCode"
                      control={control}
                      rules={{
                        required: `${t('attributes.postCode')} ${Validation.general.required}`,
                        validate: (value) => {
                          if (value && !/^\d+$/.test(value)) {
                            return Validation.general.numericValue;
                          }

                          return true;
                        },
                        minLength: {
                          value: postCodeLength,
                          message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                        },
                        maxLength: {
                          value: postCodeLength,
                          message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                        },
                      }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <CustomTextField
                          fullWidth
                          label={t('attributes.postCode')}
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(
                              /\D/g,
                              ''
                            );

                            onChange(numericValue);
                            trigger('postCode');
                          }}
                          value={value}
                          setBlur={setPostcodeBlur}
                          onClear={() => clearTextfields(setValue, name)}
                          helperText={error ? error.message : ''}
                          error={error}
                        />
                      )}
                    />
                    <Controller
                      name="zones"
                      control={control}
                      rules={{
                        required: ` ${t('attributes.technician.zones')} ${Validation.general.required}`,
                      }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          options={zoneList}
                          fullWidth
                          label="Zones"
                          onChange={(e, newValue) => {
                            if (newValue || newValue === null) {
                              onChange(newValue);
                            }
                            trigger('zones');
                          }}
                          value={value}
                          helperText={error ? error.message : ''}
                          isLoadingData={
                            isZoneLoading || localGovernmentAreaLoading
                          }
                          error={error}
                          onClear={() => clearTextfields(setValue, name)}
                        />
                      )}
                    />
                    <Controller
                      name="localGovermentArea"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          options={localGovermentAreaList}
                          fullWidth
                          isRequired={false}
                          isLoadingData={localGovernmentAreaLoading}
                          label={t('attributes.property.localGovArea')}
                          onChange={(e, newValue) => {
                            if (newValue || newValue === null) {
                              onChange(newValue);
                            }
                            trigger('localGovermentArea');
                          }}
                          value={value}
                          helperText={error ? error.message : ''}
                          error={error}
                        />
                      )}
                    />
                    <Controller
                      name="landLotNumber"
                      rules={{
                        maxLength: {
                          value: 20,
                          message: Validation.property.landLotNumber,
                        },
                      }}
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <CustomTextField
                          isRequired={false}
                          label={t('attributes.property.landLotNumber')}
                          onChange={(e) => {
                            onChange(e);
                            trigger('landLotNumber');
                          }}
                          value={value}
                          onClear={() => {
                            onChange('');
                          }}
                          helperText={error ? error.message : ''}
                          error={error}
                        />
                      )}
                    />
                    <Controller
                      name="statusId"
                      control={control}
                      rules={{
                        required: `${t('attributes.property.status')} ${Validation.general.required}`,
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          options={formStatusList}
                          fullWidth
                          label={t('attributes.property.status')}
                          onChange={(e, newValue) => {
                            if (newValue || newValue === null) {
                              onChange(newValue);
                            }
                            trigger('statusId');
                          }}
                          value={value}
                          helperText={error ? error.message : ''}
                          error={error}
                        />
                      )}
                    />
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Controller
                        name="customerId"
                        control={control}
                        rules={{
                          required: `${t('attributes.customer.customer')} ${Validation.general.required}`,
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            options={customerList}
                            useSearchIcon={true}
                            fullWidth
                            label={t('attributes.customer.customer')}
                            onChange={(e, newValue) => {
                              if (newValue || newValue === null) {
                                onChange(newValue);
                              }
                              trigger('customerId');
                              setValue('billingCardId', null);
                              clearErrors('billingCardId');
                              setValue('active_billing_contract_uuid', null);
                              clearErrors('active_billing_contract_uuid');
                            }}
                            value={value}
                            helperText={error ? error.message : ''}
                            error={error}
                          />
                        )}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Controller
                        name="billingCardId"
                        control={control}
                        rules={{
                          required: `${t('attributes.property.billingCard')} ${Validation.general.required}`,
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            options={billingCardDropdownData}
                            fullWidth
                            label={t('attributes.property.billingCard')}
                            useSearchIcon={true}
                            onChange={(e, newValue) => {
                              if (newValue || newValue === null) {
                                onChange(newValue);
                              }
                              trigger('billingCardId');
                            }}
                            value={value}
                            helperText={error ? error.message : ''}
                            error={error}
                            isLoadingData={
                              !billingCardIdDisabled &&
                              billingCardDropdownLoading
                            }
                            disabledDropdown={billingCardIdDisabled}
                          />
                        )}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: '-16px 0px',
                      }}
                    >
                      <CustomButton
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        text={t('attributes.add')}
                        style={{ textDecoration: 'underline' }}
                        variant="text"
                        onClick={addNewCustomerBilling}
                      />
                    </Box>
                    {/* add checkbox here */}
                    <CommonCheckbox
                      control={control}
                      name="parentPropertyAssociateCheckbox"
                      label={t(
                        'attributes.property.parentPropertyAssociateCheckbox'
                      )}
                      isRequired={false}
                    />
                    {getValues('parentPropertyAssociateCheckbox') && (
                      <>
                        <Controller
                          name="parentPropertyCustomer"
                          control={control}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <Autocomplete
                              options={customerList}
                              fullWidth
                              label={`${t('attributes.property.parent')} ${t('attributes.property.property')} ${t('attributes.property.customer')}`}
                              onChange={(e, newValue) => {
                                if (newValue || newValue === null) {
                                  onChange(newValue);
                                }
                                trigger('parentPropertyCustomer');
                              }}
                              useSearchIcon={true}
                              value={value}
                              helperText={error ? error.message : ''}
                              error={error}
                            />
                          )}
                        />
                        <Controller
                          name="parentPropertyId"
                          control={control}
                          rules={{
                            required: `${t('attributes.property.parent')} ${t('attributes.property.property')} ${Validation.general.required}`,
                          }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <Autocomplete
                              options={parentPropertyDropdownData}
                              disabled={
                                parentPropertyDropdownData.length > 0
                                  ? false
                                  : true
                              }
                              isLoadingData={parentPropertyDropdownLoading}
                              fullWidth
                              label={`${t('attributes.property.parent')} ${t('attributes.property.property')}`}
                              onChange={(e, newValue) => {
                                if (newValue || newValue === null) {
                                  onChange(newValue);
                                }
                                trigger('parentPropertyId');
                              }}
                              useSearchIcon={true}
                              value={value}
                              helperText={error ? error.message : ''}
                              error={error}
                            />
                          )}
                        />
                      </>
                    )}
                    <Box sx={{ display: 'flex', columnGap: '16px' }}>
                      <Controller
                        name="certificationNumber"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            options={certificationNumberDropdown}
                            fullWidth
                            label={t(
                              'attributes.property.certification_dropdown_text'
                            )}
                            onChange={(e, newValue) => {
                              if (newValue || newValue === null) {
                                onChange(newValue);
                                setValue('property_identification_number', '');
                              }
                              trigger('certificationNumber');
                            }}
                            value={value}
                            helperText={error ? error.message : ''}
                            error={error}
                            isRequired={false}
                          />
                        )}
                      />

                      {getValues('certificationNumber')?.value === DP_DN ? (
                        <Controller
                          name="property_identification_number"
                          control={control}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <CustomTextField
                              fullWidth
                              label={
                                getValues('certificationNumber') &&
                                t('attributes.property.dp_np_number')
                              }
                              onChange={(e) => {
                                onChange(e);
                                trigger('property_identification_number');
                              }}
                              isRequired={false}
                              value={value}
                              onClear={() => {
                                onChange('');
                              }}
                              disabled={!getValues('certificationNumber')}
                              helperText={error ? error.message : ''}
                              error={error}
                            />
                          )}
                        />
                      ) : (
                        <Controller
                          name="property_identification_number"
                          control={control}
                          rules={{
                            maxLength: {
                              value: 30,
                              message: Validation.property.ocspNumber,
                            },
                          }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <CustomTextField
                              fullWidth
                              label={
                                getValues('certificationNumber') &&
                                t('attributes.property.ocspNumber')
                              }
                              onChange={(e) => {
                                onChange(e);
                                trigger('property_identification_number');
                              }}
                              isRequired={false}
                              value={value}
                              onClear={() => {
                                onChange('');
                              }}
                              disabled={!getValues('certificationNumber')}
                              helperText={error ? error.message : ''}
                              error={error}
                            />
                          )}
                        />
                      )}
                    </Box>

                    <Controller
                      name="internalNote"
                      control={control}
                      rules={{
                        maxLength: {
                          value: 65535,
                          message: Validation.property.internalNote,
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <CustomTextField
                          multiline
                          rows={2}
                          fullWidth
                          isRequired={false}
                          label={t('attributes.property.internalNote')}
                          onChange={(e) => {
                            onChange(e);
                            trigger('internalNote');
                          }}
                          value={value}
                          onClear={() => {
                            onChange('');
                          }}
                          helperText={error ? error.message : ''}
                          error={error}
                        />
                      )}
                    />
                  </Box>
                </Box>

                <Box>
                  <Box
                    sx={{
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      background: SECONDARY,
                      borderRadius: '4px 4px 0 0',
                    }}
                  >
                    <Typography variant="h2" style={{ paddingLeft: '16px' }}>
                      {t('attributes.property.contractAndAssignmentDetails')}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      background: theme.palette.secondary.main,
                      flexDirection: 'column',
                      padding: '16px',
                      borderRadius: '0 0 4px 4px',
                      gap: '16px',
                    }}
                  >
                    <CustomDatePicker
                      control={control}
                      name="annualComplianceDate"
                      label={t('attributes.property.annualComplianceDate')}
                      pickerType="date"
                      isRequired={true}
                      trigger={trigger}
                    />
                    {contractUUIDDisabled !== 'hidden' && (
                      <Controller
                        name="active_billing_contract_uuid"
                        control={control}
                        rules={{
                          required: `${t('attributes.property.contract')} ${Validation.general.required}`,
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            options={contractDropdownData}
                            fullWidth
                            label={t('attributes.property.contract')}
                            useSearchIcon={true}
                            onChange={(e, newValue) => {
                              if (newValue || newValue === null) {
                                onChange(newValue);
                              }
                              trigger('active_billing_contract_uuid');
                            }}
                            value={value}
                            helperText={error ? error.message : ''}
                            error={error}
                            isLoadingData={
                              !contractUUIDDisabled && contractDropdownLoading
                            }
                            disabledDropdown={contractUUIDDisabled}
                          />
                        )}
                      />
                    )}
                    <Controller
                      name="technician"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          options={technicianDropdownData}
                          fullWidth
                          label={t('attributes.property.defaultTechnician')}
                          onChange={(e, newValue) => {
                            if (newValue || newValue === null) {
                              onChange(newValue);
                            }
                            trigger('technician');
                          }}
                          value={value}
                          isRequired={false}
                          helperText={error ? error.message : ''}
                          error={error}
                        />
                      )}
                    />
                  </Box>
                </Box>

                <Box>
                  <Box
                    sx={{
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      background: SECONDARY,
                      borderRadius: '4px 4px 0 0',
                    }}
                  >
                    <Typography variant="h2" style={{ paddingLeft: '16px' }}>
                      {t('attributes.property.propertyManager')}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      background: theme.palette.secondary.main,
                      flexDirection: 'column',
                      padding: '16px',
                      borderRadius: '0 0 4px 4px',
                      gap: '16px',
                    }}
                  >
                    <Controller
                      name="propertyManagerName"
                      control={control}
                      rules={{
                        required: `${t('attributes.property.contact')} ${t('attributes.name')} ${Validation.general.required}`,
                        maxLength: {
                          value: 100,
                          message: Validation.property.contactName,
                        },
                      }}
                      render={({
                        field: { onChange, value, onBlur },
                        fieldState: { error },
                      }) => (
                        <CustomTextField
                          label={`${t('attributes.property.contact')} ${t('attributes.name')}`}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            trigger('propertyManagerName');
                          }}
                          onClear={() => {
                            onChange('');
                          }}
                          onBlur={() => {
                            onChange(value.trim());
                            onBlur();
                          }}
                          helperText={error ? error.message : ''}
                          error={error}
                        />
                      )}
                    />
                    <Controller
                      name="propertyManagerEmail"
                      control={control}
                      rules={{
                        required: `${t('attributes.email')} ${Validation.general.required}`,
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: Validation.general.validEmail,
                        },
                        maxLength: {
                          value: 254,
                          message: Validation.general.email,
                        },
                      }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <CustomTextField
                          label={`${t('attributes.property.contact')} ${t('attributes.email')}`}
                          fullWidth
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            trigger('propertyManagerEmail');
                          }}
                          helperText={error ? error.message : ''}
                          error={error}
                          onClear={() => clearTextfields(setValue, name)}
                        />
                      )}
                    />
                    <Controller
                      name="propertyManagerPhone"
                      control={control}
                      rules={{
                        // required: `Contact phone ${Validation.general.required}`,
                        pattern: {
                          value: /^\d{0,50}$/,
                          message: Validation.general.contactPhone,
                        },
                      }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <CustomTextField
                          label={`${t('attributes.property.contact')} ${t('attributes.phone')}`}
                          fullWidth
                          value={value}
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(
                              /\D/g,
                              ''
                            );

                            onChange(numericValue);
                            trigger('propertyManagerPhone');
                          }}
                          helperText={error ? error.message : ''}
                          error={error}
                          onClear={() => clearTextfields(setValue, name)}
                          isRequired={false}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            {activeStep === 1 && (
              <Box>
                <Box
                  sx={{
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h2" style={{ paddingLeft: '16px' }}>
                    {steps[1]}
                  </Typography>
                  {formMode === 'edit' && (
                    <Typography variant="h2" style={{ paddingRight: '16px' }}>
                      {t('attributes.property.propertyId')}: {propertyData?.pid}
                    </Typography>
                  )}
                </Box>
                <Divider />
                <Section>
                  <Controller
                    name="accessSchedule"
                    control={control}
                    rules={{
                      required: `${t('attributes.property.access')} ${t('attributes.property.schedule')} ${Validation.general.required}`,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={accessScheduleList}
                        label={`${t('attributes.property.access')} ${t('attributes.property.schedule')}`}
                        multiple={true}
                        value={value}
                        onChange={(e, newValue) => {
                          if (newValue || newValue === null) {
                            onChange(newValue);
                          }
                          trigger('accessSchedule');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="accessProcedure"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={accessProcedureList}
                        isRequired={false}
                        multiple={true}
                        label={`${t('attributes.property.access')} ${t('attributes.property.procedure')}`}
                        value={value}
                        onChange={(e, newValue) => {
                          if (newValue || newValue === null) {
                            onChange(newValue);
                          }
                          trigger('accessProcedure');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="accessCode"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 15,
                        message: Validation.property.accessCode,
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={`${t('attributes.property.access')} ${t('attributes.code')}`}
                        isRequired={false}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('accessCode');
                        }}
                        onClear={() => {
                          onChange('');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="accessNote"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 4000,
                        message: Validation.property.accessNote,
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        multiline
                        rows={2}
                        isRequired={false}
                        label={`${t('attributes.property.access')} ${t('attributes.note')}`}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('accessNote');
                        }}
                        onClear={() => {
                          onChange('');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                </Section>
              </Box>
            )}
            {activeStep === 2 && (
              <Box>
                <Box
                  sx={{
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h2" style={{ paddingLeft: '16px' }}>
                    {steps[2]}
                  </Typography>
                  {formMode === 'edit' && (
                    <Typography variant="h2" style={{ paddingRight: '16px' }}>
                      {t('attributes.property.propertyId')}: {propertyData?.pid}
                    </Typography>
                  )}
                </Box>
                <Divider />
                <Section>
                  <Box
                    sx={{ gap: 0, display: 'flex', flexDirection: 'column' }}
                  >
                    <CommonCheckbox
                      control={control}
                      name="signatureRequired"
                      label={`${t('attributes.property.signature')} ${t('attributes.required')}`}
                      isRequired={false}
                    />
                    <CommonCheckbox
                      control={control}
                      name="inductionRequired"
                      label={`${t('attributes.property.induction')} ${t('attributes.required')}`}
                      isRequired={false}
                    />
                    <CommonCheckbox
                      control={control}
                      name="logBookEntryRequired"
                      label={`${t('attributes.property.logBookEntry')} ${t('attributes.required')}`}
                      isRequired={false}
                    />
                    <CommonCheckbox
                      control={control}
                      name="policeCheckRequired"
                      label={`${t('attributes.property.policeCheck')} ${t('attributes.required')}`}
                      isRequired={false}
                    />
                    <CommonCheckbox
                      control={control}
                      name="isBookingRequired"
                      label={`${t('attributes.property.isBooking')} ${t('attributes.required')}`}
                      isRequired={false}
                    />
                    <CommonCheckbox
                      control={control}
                      name="workingWithChildernCheckRequired"
                      label={`${t('attributes.property.workingWithChildren')} ${t('attributes.required')}`}
                      isRequired={false}
                    />
                    {propertySiteRequirementData?.map((item, index) => (
                      <CommonCheckbox
                        key={uniqueId(index)}
                        control={control}
                        name={`${item.text}-${item.id}`}
                        label={item.text}
                        isRequired={false}
                      />
                    ))}
                  </Box>
                  <Controller
                    name="serviceRequirement"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 65535,
                        message: Validation.property.serviceRequirements,
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={`${t('attributes.property.service')} ${t('attributes.requirements')}`}
                        isRequired={false}
                        multiline
                        rows={2}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('serviceRequirement');
                        }}
                        onClear={() => {
                          onChange('');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="safetyRequirement"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 65535,
                        message: Validation.property.safetyRequirements,
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={`${t('attributes.property.safety')} ${t('attributes.requirements')}`}
                        multiline
                        rows={2}
                        isRequired={false}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('safetyRequirement');
                        }}
                        onClear={() => {
                          onChange('');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                </Section>
              </Box>
            )}
            {activeStep === 3 && (
              <Box>
                <Box
                  sx={{
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h2" style={{ paddingLeft: '16px' }}>
                    {steps[3]}
                  </Typography>
                  {formMode === 'edit' && (
                    <Typography variant="h2" style={{ paddingRight: '16px' }}>
                      {t('attributes.property.propertyId')}: {propertyData?.pid}
                    </Typography>
                  )}
                </Box>
                <Divider />
                <Section>
                  <Controller
                    name="buildingEra"
                    control={control}
                    rules={{
                      required: `${t('attributes.property.buildingEra')} ${Validation.general.required}`,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={buildingEraList}
                        label={t('attributes.property.buildingEra')}
                        value={value}
                        onChange={(e, newValue) => {
                          if (newValue || newValue === null) {
                            onChange(newValue);
                          }
                          trigger('buildingEra');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="building_class"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={buildingClassList}
                        isRequired={false}
                        label={t('attributes.property.buildingClass')}
                        multiple={true}
                        value={value}
                        onChange={(e, newValue) => {
                          if (newValue || newValue === null) {
                            onChange(newValue);
                          }
                          trigger('building_class');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="buildingType"
                    control={control}
                    rules={{
                      required: `${t('attributes.property.buildingType')} ${Validation.general.required}`,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={buildingTypeList}
                        label={t('attributes.property.buildingType')}
                        value={value}
                        onChange={(e, newValue) => {
                          if (newValue || newValue === null) {
                            onChange(newValue);
                          }
                          trigger('buildingType');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="building_size"
                    rules={{
                      maxLength: {
                        value: 40,
                        message: Validation.property.buildingSize,
                      },
                    }}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={[]}
                        fullWidth
                        multiple={true}
                        freeSolo
                        label={t('attributes.property.buildingSize')}
                        isRequired={false}
                        value={value || []}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        onChange={(e, newValue) => {
                          if (!Array.isArray(newValue)) return;

                          const validValues = newValue.filter((val) =>
                            /^\d+$/.test(val)
                          );

                          onChange(validValues);
                          setInputValue('');
                          trigger('building_size');
                        }}
                        onClear={() => {
                          onChange([]);
                        }}
                        helperText={error ? error.message : ''}
                        error={!!error}
                        onBlur={(event) => {
                          const inputValue = event?.target?.value?.trim();

                          if (inputValue && /^\d+$/.test(inputValue)) {
                            onChange([...value, inputValue]);
                            setInputValue('');
                          }
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({ index });

                            return (
                              <Chip
                                label={option}
                                key={key}
                                {...tagProps}
                                onDelete={() => {
                                  const newValues = value.filter(
                                    (_, idx) => idx !== index
                                  );

                                  onChange(newValues);
                                }}
                              />
                            );
                          })
                        }
                        onKeyDown={(event) => {
                          if (event.key === 'Enter' || event.key === 'Tab') {
                            event.preventDefault();
                            const inputValue = event?.target?.value?.trim();

                            if (inputValue && /^\d+$/.test(inputValue)) {
                              onChange([...value, inputValue]);
                              setInputValue('');
                            }
                          }
                        }}
                      />
                    )}
                  />
                  {/* <Controller
                    name="buildingSizeSqm"
                    rules={{
                      maxLength: {
                        value: 20,
                        message: Validation.property.buildingSizeSqm,
                      },
                    }}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={t('attributes.property.buildingSizeSqm')}
                        isRequired={false}
                        value={SQFT_TO_SQM(
                          watch('building_size')?.slice(-1)[0]
                        )}
                        onChange={(e) => {
                          onChange(e);
                          trigger('buildingSizeSqm');
                        }}
                        onClear={() => {
                          onChange('');
                        }}
                        disabled={true}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  /> */}
                  <Controller
                    name="storiesAboveGround"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 3,
                        message: Validation.property.storyAboveGround,
                      },
                      validate: (value) => {
                        if (value && !/^\d+$/.test(value)) {
                          return Validation.general.numericValue;
                        }

                        return true;
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={t('attributes.property.storyAboveGround')}
                        value={value}
                        isRequired={false}
                        onChange={(e) => {
                          onChange(e);
                          trigger('storiesAboveGround');
                        }}
                        onClear={() => {
                          onChange('');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="storiesBelowGround"
                    rules={{
                      maxLength: {
                        value: 3,
                        message: Validation.property.storyBelowGround,
                      },
                      validate: (value) => {
                        if (value && !/^\d+$/.test(value)) {
                          return Validation.general.numericValue;
                        }

                        return true;
                      },
                    }}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={t('attributes.property.storyBelowGround')}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('storiesBelowGround');
                        }}
                        onClear={() => {
                          onChange('');
                        }}
                        isRequired={false}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="constructionClass"
                    control={control}
                    rules={{
                      required: `${t('attributes.property.constructionClass')} ${Validation.general.required}`,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={constructionClassList}
                        label={t('attributes.property.constructionClass')}
                        value={value}
                        onChange={(e, newValue) => {
                          if (newValue || newValue === null) {
                            onChange(newValue);
                          }
                          trigger('constructionClass');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="buildingPart"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={t('attributes.property.buildingPart')}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('buildingPart');
                        }}
                        onClear={() => {
                          onChange('');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                        isRequired={false}
                      />
                    )}
                  />
                  {/* <Controller
                  name="buildingStandards"
                  control={control}
                  rules={{
                    required: `${t('attributes.property.buildingStandards')} ${Validation.general.required}`,
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      options={standardsDropdownData}
                      label={t('attributes.property.buildingStandards')}
                      isRequired={true}
                      value={value}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                        trigger('buildingStandards');
                      }}
                      isLoadingData={standardsDropdownLoading}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                /> */}
                </Section>
              </Box>
            )}
            {activeStep === 4 && (
              <Box>
                <Box
                  sx={{
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h2" style={{ paddingLeft: '16px' }}>
                    {steps[4]}
                  </Typography>
                  {formMode === 'edit' && (
                    <Typography variant="h2" style={{ paddingRight: '16px' }}>
                      {t('attributes.property.propertyId')}: {propertyData?.pid}
                    </Typography>
                  )}
                </Box>
                <Divider />
                <Section>
                  <Controller
                    name="occupancyPermitNumber"
                    rules={{
                      maxLength: {
                        value: 30,
                        message: Validation.property.occupancyPermitNumber,
                      },
                    }}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={`${t('attributes.property.occupancyPermit')} ${t('attributes.number')}`}
                        isRequired={false}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('occupancyPermitNumber');
                        }}
                        onClear={() => {
                          onChange('');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <CustomDatePicker
                    control={control}
                    name="occupancyPermitDate"
                    label={`${t('attributes.property.occupancyPermit')} ${t('attributes.date')}`}
                    pickerType="date"
                  />
                  <Controller
                    name="occupancyPermitLocation"
                    rules={{
                      maxLength: {
                        value: 300,
                        message: Validation.property.occupancyPermitLocation,
                      },
                    }}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={`${t('attributes.property.occupancyPermit')} ${t('attributes.location')}`}
                        isRequired={false}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('occupancyPermitLocation');
                        }}
                        onClear={() => {
                          onChange('');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                </Section>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            height: '54px',
            width: '100%',
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            backgroundColor: SECONDARY,
            padding: '16px',
            gap: 2,
          }}
        >
          {activeStep !== 0 && (
            <CustomButton
              startIcon={<KeyboardArrowLeftIcon />}
              text={t('attributes.previous')}
              color="inherit"
              disabled={activeStep === 0 || createLoading || updateLoading}
              onClick={handleBack}
            />
          )}

          {activeStep === steps.length - 1 ? (
            <CustomButton
              startIcon={formMode === 'edit' ? <SaveIcon /> : <AddIcon />}
              text={
                formMode === 'edit' ? t('attributes.save') : t('attributes.add')
              }
              disabled={createLoading || updateLoading}
              onClick={handleSubmit(onSubmit)} // Attach the form submission handler
              type="submit" // Ensure the button is of type submit
            />
          ) : (
            <CustomButton
              endIcon={<ChevronRightIcon />}
              text={t('common.next')}
              onClick={handleNext}
              disabled={!isNextReady}
            />
          )}
        </Box>
      </LayoutContainer>
      <CreateCustomerBill
        open={open}
        setOpen={setOpen}
        customer={getValues('customerId')}
        onConfirm={createNewCustomerBill}
        buttonDisabled={createBillCardDisabled}
      />
      {loggedInUserRole() === SUPER_ADMIN && (
        <CreateCityModal
          open={openCity}
          setOpen={setOpenCity}
          state={watch('stateId')}
          setServerErrors={setServerErrors}
        />
      )}
    </>
  );
};
