import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, TextField, Typography } from '@mui/material';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../../assets/commonStyled';
import { Validation } from '../../../constants/FieldValidationMsg';
import { clearTextfields } from '../../../utils';
import { Delete } from '../../CommonComponents/ActionComponent';
import CustomDatePicker from '../../CommonComponents/CustomDatePicker';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomTextField from '../../CommonComponents/CustomTextField';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';

export const AddInvoiceBill = ({
  control,
  setValue,
  trigger,
  reset,
  selectedProductsError,
  setSelectedProductsError,
  purchaseOrderDetails,
}) => {
  const { t } = useTranslation();

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [rows, setRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [productList, setProductList] = useState(purchaseOrderDetails);

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    // Check if all individual checkboxes are selected
    if (
      selectedProducts.length === productList?.items?.length &&
      productList?.items?.length > 0
    ) {
      setIsAllSelected(true);
      setSelectedProductsError(null); // Clear error if at least one product is selected
    } else {
      setIsAllSelected(false);
    }
  }, [selectedProducts, productList]);

  const handleSelectAllChange = () => {
    setIsAllSelected((prevIsAllSelected) => {
      const newIsAllSelected = !prevIsAllSelected;

      if (newIsAllSelected) {
        setSelectedProducts((prevSelected) => {
          // Find products that are not already in the selectedProducts list
          const newProductsToAdd = rows?.filter(
            (product) =>
              !prevSelected.some((p) => p.product_uuid === product.product_uuid)
          );

          // Merge already selected products with new products
          return [
            ...prevSelected,
            ...newProductsToAdd.map((product) => ({
              ...product,
              id: product.product_uuid,
              qty: product.total_qty,
              product_uuid: product.product_uuid,
              product_catalogue_type: product.type,
            })),
          ];
        });
      } else {
        // Clear selectedProducts when "Select All" is unchecked
        setSelectedProducts([]);
      }

      return newIsAllSelected;
    });
  };

  const handleCheckboxChange = (product) => {
    setSelectedProducts((prevSelected) => {
      const existingProduct = prevSelected.find(
        (p) => p.product_uuid === product.product_uuid
      );

      if (existingProduct) {
        return prevSelected.filter(
          (p) => p.product_uuid !== product.product_uuid
        );
      } else {
        // Add if not selected
        return [
          ...prevSelected,
          { ...product, qty: product.total_qty, id: product.product_uuid },
        ];
      }
    });
  };

  useEffect(() => {
    setValue('selectedProducts', selectedProducts);
  }, [selectedProducts, setValue]);

  const columns = [
    {
      field: 'product',
      headerName: t('attributes.purchase_order.product_name'),
      flex: 1.25, // 40% width
      sortable: false,
      renderHeader: () => (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            color="primary"
            checked={isAllSelected}
            onChange={handleSelectAllChange}
          />
          <Typography variant="body1">
            {t('attributes.purchase_order.product_name')}
          </Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            color="primary"
            checked={selectedProducts.some(
              (p) => p.product_uuid === params.row.product_uuid
            )}
            onChange={() => handleCheckboxChange(params.row)}
          />
          <Typography variant="body2">{params.row.product}</Typography>
        </Box>
      ),
    },
    {
      field: 'description',
      headerName: t('attributes.description'),
      flex: 0.68, // 10% width
      sortable: false,
    },
    {
      field: 'unit_price',
      headerName: t('attributes.unit_price'),
      flex: 0.65,
      align: 'center',
      sortable: false,
    },
    {
      field: 'total_qty',
      headerName: `${t('attributes.total_qty')}`,
      flex: 0.55,
      align: 'right',
      sortable: false,
    },
    {
      field: 'qty_to_bill',
      headerName: t('attributes.qty_to_bill'),
      flex: 0.6,
      align: 'center',
      sortable: false,
      renderCell: (params) => (
        <TextField
          name="qty"
          type="number"
          sx={{
            '& .MuiInputBase-input': {
              padding: '0 10px',
              height: '30px',
            },
          }}
          value={
            selectedProducts.find(
              (p) => p.product_uuid === params.row.product_uuid
            )?.qty || params.row.total_qty
          }
          onChange={(e) => handleQuantityChange(params.row, e.target.value)}
        />
      ),
    },
    {
      field: 'actions',
      headerName: t('attributes.actions'),
      flex: 0.5,
      sortable: false,
      align: 'center',
      renderCell: ({ row }) => (
        <Delete
          onClick={() => {
            removeRowFromPurchaseOrderDetails(row);
          }}
        />
      ),
    },
  ];

  const removeRowFromPurchaseOrderDetails = (row) => {
    const updatedItems = productList?.items.filter(
      (item) => item.uuid !== row.product_uuid
    );

    const updatedPurchaseOrderDetails = {
      ...productList,
      items: updatedItems,
    };

    setSelectedProducts((prevSelected) =>
      prevSelected.filter((p) => p.product_uuid !== row.product_uuid)
    );

    setProductList(updatedPurchaseOrderDetails);
  };

  const handleQuantityChange = (product, qty) => {
    setSelectedProducts((prevSelected) => {
      const existingProduct = prevSelected.find(
        (p) => p.product_uuid === product.product_uuid
      );

      if (!existingProduct) {
        return [...prevSelected, { ...product, qty: Number(qty) }];
      }

      return prevSelected.map((p) =>
        p.product_uuid === product.product_uuid ? { ...p, qty: Number(qty) } : p
      );
    });
  };

  useEffect(() => {
    setRows(
      productList?.items.map((item, index) => ({
        id: index,
        product: item.product_catalogue?.product_name,
        description: item.product_catalogue?.description || '-',
        total_qty: item.qty,
        unit_price: Number(item?.price)?.toFixed(2),
        total: (Number(item?.price) * item.qty)?.toFixed(2),
        product_catalogue_uuid: item.product_catalogue_uuid,
        product_catalogue_type: item.product_catalogue_type,
        uuid: purchaseOrderDetails.uuid,
        product_uuid: item.uuid,
        item_details: item,
      }))
    );
  }, [productList]);

  return (
    <FormWrapper>
      <CustomCard>
        <FormFieldWrapper component="form">
          <CustomDatePicker
            control={control}
            name="invoice_date"
            label={t('attributes.purchase_order.invoice_date')}
            pickerType="date"
            defaultValue={null}
            trigger={trigger}
            isRequired={true}
          />
          <Controller
            name="invoice_number"
            control={control}
            rules={{
              required: `${t('attributes.purchase_order.invoice_number')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.purchase_order.invoice_number')}
                fullWidth
                value={value}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, '');

                  onChange(numericValue);
                  trigger('invoice_number');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={true}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
      <CustomCard>
        {selectedProductsError && (
          <Typography sx={{ padding: '16px' }} color="error">
            {selectedProductsError}
          </Typography>
        )}
        <CustomGridTable
          columns={columns}
          rows={rows}
          total={rows.length}
          checkboxSelection={false}
          paginationRequired={false}
          disableColumnMenu={true}
          noData={<NoRecordFound />}
          filterHeight={285}
          isLoading={false}
          isTableView={true}
        />
      </CustomCard>
    </FormWrapper>
  );
};
