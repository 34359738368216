import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { Box, Divider, Typography } from '@mui/material';

import { DIVIDER_COLOR, SECONDARY } from '../../../constants/Colors';
import {
  CREATED_STATUS,
  CUSTOMER_QUOTATION_STATUS,
  DIFFERED,
  DRAFT_STATUS,
  OVERDUE,
  PAID,
  PENDING,
  PURCHASE_ORDER_STATUS,
  ROUTE_NAVIGATE_CONST,
  TAX_NAME,
  TEMPLATE_SET,
} from '../../../constants/Constants';
import { SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH } from '../../../constants/Typography';
import useBrowserBackButtonHandler from '../../../hooks/useBrowserBackButtonHandler';
import useDetailPageValidation from '../../../hooks/useDetailPageValidation';
import usePreviewAndDownloadTemplate from '../../../hooks/usePreviewAndDownloadTemplate';
import { snackbarToggle } from '../../../store/CommonReducer';
import { editCompany } from '../../../store/company/api';
import { editContact } from '../../../store/contacts/api';
import { resetEdit } from '../../../store/contacts/reducer';
import {
  createInvoiceProduct,
  deleteInvoiceProduct,
  updateInvoice,
  updateInvoiceProduct,
} from '../../../store/invoices/api';
import {
  resetCreateInvoiceProduct,
  resetDeleteInvoiceProduct,
  resetUpdateInvoice,
  resetUpdateInvoiceProduct,
} from '../../../store/invoices/reducer';
import {
  getDefectInformation,
  getWorkOrderQuotation,
} from '../../../store/workOrder/api';
import { resetDefectQuotes } from '../../../store/workOrder/reducer';
import {
  calculateTaxPercentage,
  calculateTotal,
  formatStatus,
  formattedDate,
  getRedirectURL,
  loggedInUserDetail,
  loggedInUserRole,
} from '../../../utils';
import { Delete, Edit } from '../../CommonComponents/ActionComponent';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import MainWrapper from '../../CommonComponents/MainWrapper';
import { ConfirmationModal } from '../../CommonComponents/Modal';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import AddInvoiceProduct from './AddInvoiceProduct';
import EditInvoiceProduct from './EditInvoiceProduct';

const defaultValues = {
  equipment_type: null,
  product_type: null,
  search: '',
};

const InvoiceDetail = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { invoice_uuid } = useParams();
  const outletContext = useOutletContext();
  const useRole = loggedInUserRole();

  const {
    nextStepTitle,
    setStepTitle,
    isFromSideBar,
    isFromWorkOrder,
    workOrderId: woId,
  } = { ...props, ...outletContext };

  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState();
  const [openPreview, setOpenPreview] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(true);
  const [hasFormValues, sethasFormValues] = useState();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedProductsError, setSelectedProductsError] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [editProductDetail, setEditProductDetail] = useState(null);
  const [editProductFormOpen, setEditProductFormOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteInvoiceDetail, setDeleteInvoiceDetail] = useState({});
  const [addProductFromQuote, setAddProductFromQuote] = useState(false);

  const { isLoading: isLoadingCompany, company } = useSelector(
    (state) => state.company.edit
  );

  const { invoiceList, isLoading } = useSelector((state) => state.invoices.get);
  const {
    data: createInvoiceProductData,
    isLoading: createInvoiceProductLoading,
  } = useSelector((state) => state.invoices.createProduct);

  const {
    data: updateInvoiceProductData,
    isLoading: updateInvoiceProductLoading,
  } = useSelector((state) => state.invoices.updateProduct);

  const {
    data: deleteInvoiceProductData,
    isLoading: deleteInvoiceProductLoading,
  } = useSelector((state) => state.invoices.deleteProduct);

  const {
    isLoading: updateInvoiceLoading,
    data: updateInvoiceData,
    error: updateInvoiceError,
  } = useSelector((state) => state.invoices.update);

  const { defectQuotesList } = useSelector(
    (state) => state.workOrder.defectQuotes
  );

  const { isLoading: contactLoading, contact } = useSelector(
    (state) => state.contact.edit
  );

  const user = loggedInUserDetail();
  const { handleSubmit, control, watch, reset, setValue, trigger } = useForm({
    defaultValues: defaultValues,
  });

  useBrowserBackButtonHandler({
    hasUnsavedChanges: hasFormValues,
    setOpenConfirmationModal,
    open,
    watchMethod: watch,
    sethasFormValues,
  });

  const { listTemplateLoading, generatePDF, PreviewTemplatePDF } =
    usePreviewAndDownloadTemplate({
      module: TEMPLATE_SET.INVOICE,
      data: {
        companyData: company,
        isLoadingCompany: isLoadingCompany || contactLoading,
        invoiceDetails,
        contact,
      },
    });

  const navigate = useNavigate();

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  useEffect(() => {
    const detail = invoiceList?.find(
      (item) => item.uuid === invoice_uuid || item.uuid === nextStepTitle?.uuid
    );

    setInvoiceDetails(detail || []);
  }, [invoiceList, nextStepTitle]);

  useDetailPageValidation({
    is_error: true,
    data: invoiceDetails,
    is_loading: isLoading,
  });

  useEffect(() => {
    setTimeout(() => {
      setIsShowLoading(false);
    }, 1000);

    return () => {
      dispatch(resetCreateInvoiceProduct());
      dispatch(resetUpdateInvoiceProduct());
      dispatch(resetDeleteInvoiceProduct());
      dispatch(resetUpdateInvoice());
      dispatch(resetDefectQuotes());
      dispatch(resetEdit());
    };
  }, []);

  useEffect(() => {
    if (!createInvoiceProductLoading) {
      if (createInvoiceProductData) {
        setOpenAddProduct(false);
        setDisableSave(false);
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: createInvoiceProductData.message,
          })
        );
      }
    }
  }, [createInvoiceProductLoading, createInvoiceProductData]);

  useEffect(() => {
    if (!updateInvoiceProductLoading) {
      if (updateInvoiceProductData) {
        setEditProductFormOpen(false);
        setDisableSave(false);
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: updateInvoiceProductData.message,
          })
        );
      }
    }
  }, [updateInvoiceProductLoading, updateInvoiceProductData]);

  useEffect(() => {
    if (!deleteInvoiceProductLoading) {
      if (deleteInvoiceProductData) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: deleteInvoiceProductData.message,
          })
        );
      }
    }
  }, [deleteInvoiceProductLoading, deleteInvoiceProductData]);

  useEffect(() => {
    if (woId || invoiceDetails?.work_order_data?.uuid) {
      dispatch(
        getWorkOrderQuotation({
          id: woId || invoiceDetails?.work_order_data?.uuid,
        })
      );
    }
  }, [addProductFromQuote, invoiceDetails]);

  useEffect(() => {
    if (updateInvoiceError) {
      dispatch(
        snackbarToggle({
          isOpen: true,
          isErrorMsg: true,
          msg: updateInvoiceError.message,
        })
      );
    }
  }, [updateInvoiceError]);

  // Calculate subtotal, tax, and total
  const total = invoiceDetails?.items?.reduce(
    (acc, item) => acc + Number(item?.price) * item?.qty,
    0
  );

  const tax = invoiceDetails?.items
    ?.reduce((acc, item) => acc + Number(item?.tax), 0)
    .toFixed(2);

  const totalTax = Number(tax);

  const productsIdFromQuotes = defectQuotesList?.quotation_items?.map(
    (item) => ({
      uuid: item?.product_catalogue?.uuid,
      qty: item?.qty,
      sell_price: item?.cost,
    })
  );

  const columns = [
    {
      field: 'product',
      headerName: t('attributes.purchase_order.product'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'code',
      headerName: t('attributes.productCatalogue.code'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'description',
      headerName: t('attributes.description'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'qty',
      headerName: t('attributes.quantity'),
      flex: 0.5,
      sortable: false,
    },
    {
      field: 'unit_price',
      headerName: t('attributes.unit_price'),
      flex: 0.5,
      sortable: false,
    },
    {
      field: 'total',
      headerName: t('attributes.total'),
      flex: 0.5,
      sortable: false,
    },
    ...(invoiceDetails?.status === DRAFT_STATUS ||
    invoiceDetails?.status === DIFFERED
      ? [
          {
            field: 'action',
            headerName: t('attributes.action'),
            flex: 0.5,
            sortable: false,
            renderCell: ({ row }) => (
              <Box sx={{ display: 'flex', gap: '8px' }}>
                <Edit
                  onClick={() => {
                    setEditProductDetail(row);
                    setEditProductFormOpen(true);
                  }}
                />
                <Delete
                  onClick={() => {
                    setDeleteConfirmation(true);
                    setDeleteInvoiceDetail({
                      invoice_uuid: row?.uuid,
                      invoice_item_uuid: row?.product_uuid,
                    });
                  }}
                />
              </Box>
            ),
          },
        ]
      : []),
  ];
  const rows = invoiceDetails?.items?.map((item, index) => ({
    id: index,
    product: item?.product_name,
    description: item?.product_description || '-',
    qty: item.qty,
    unit_price: Number(item?.price)?.toFixed(2),
    total: (Number(item?.price) * item.qty)?.toFixed(2),
    product_catalogue_uuid: item.product_catalogue_uuid,
    product_catalogue_type: item.product_catalogue_type,
    uuid: invoiceDetails.uuid,
    product_uuid: item.uuid,
    currency: item.currency,
    code: item.code,
    meta_data: {
      defect_description: item?.asset_defects?.defect_description,
      defect_title: item?.asset_defects?.defect_title,
      defect_id: item?.asset_defects?.defect_id,
    },
  }));

  const handleUpdateInvoiceStatus = (status) => {
    const request = {
      type: invoiceDetails?.type,
      invoice_creation_date: invoiceDetails?.created_at,
      due_date: invoiceDetails?.due_date,
      payment_status:
        invoiceDetails?.status === DIFFERED
          ? PENDING
          : invoiceDetails?.payment_status,
    };

    dispatch(
      updateInvoice({
        invoice_uuid: invoiceDetails?.uuid,
        data: { ...request, status: status },
      })
    ).finally(() => {
      setOpenPreview(false);
    });
  };

  const onSubmit = (formData) => {
    // setDisableSave(true);

    if (!formData.selectedProducts || formData.selectedProducts.length === 0) {
      setSelectedProductsError(
        addProductFromQuote
          ? t('common.select_at_least_one_product')
          : `${t('common.select_at_least_one_product')} ${t('attributes.invoice.to_add_in_invoice_line_items')}`
      );
      setDisableSave(false);

      return;
    }

    const productCatalogueUuids = formData.selectedProducts.map(
      (product) => product.uuid
    );

    dispatch(
      getDefectInformation({
        work_order_uuid: invoiceDetails?.work_order_uuid,
        product_catalogue_uuids: productCatalogueUuids,
      })
    ).then((res) => {
      const data = res.payload.data || [];
      const defectDataMap = data.reduce((acc, item) => {
        acc[item.product_catalogue_uuid] = item.asset_defects;

        return acc;
      }, {});

      const productRequests = formData.selectedProducts.map((product) => {
        const defectData = defectDataMap[product.uuid] || {};

        return {
          type: invoiceDetails?.type,
          routine_standard_frequency_id:
            invoiceDetails?.routine_standard_frequency_id,
          routine_standard_frequency_uuid:
            invoiceDetails?.routine_standard_frequency_id,
          product_name: product?.product_name,
          product_description: product?.description,
          price: product?.sell_price,
          qty: product?.qty,
          currency: product?.sell_currency,
          UM: product?.um,
          product_type: product?.product_catalogue_type,
          reference_product_catalogue_uuid: product?.uuid,
          code: product?.code,
          meta_data: {
            defect_description: defectData?.defect_description,
            defect_title: defectData?.defect_title,
            defect_id: defectData?.defect_id,
          },
        };
      });

      dispatch(
        createInvoiceProduct({
          invoice_uuid: invoiceDetails?.uuid,
          data: { items: productRequests },
        })
      );
    });
  };

  const onSubmitEditProduct = async (formData) => {
    setDisableSave(true);
    setEditProductDetail(null);
    const createData = {
      type: invoiceDetails?.type,
      routine_standard_frequency_id:
        invoiceDetails?.routine_standard_frequency_id,
      routine_standard_frequency_uuid:
        invoiceDetails?.routine_standard_frequency_id,
      product_name: formData?.product_name,
      product_description: formData?.product_description,
      price: formData?.sell_price,
      qty: formData?.qty,
      currency: formData?.currency,
      UM: formData?.um,
      product_type: formData?.product_catalogue_type,
      reference_product_catalogue_uuid:
        invoiceDetails?.reference_product_catalogue_uuid,
      meta_data: {
        defect_description: formData?.asset_defects?.defect_description,
        defect_title: formData?.asset_defects?.defect_title,
        defect_id: formData?.asset_defects?.defect_id,
      },
    };

    dispatch(
      updateInvoiceProduct({
        invoice_uuid: invoiceDetails?.uuid,
        invoice_item_uuid: formData?.product_uuid,
        data: createData,
      })
    );
  };

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpenAddProduct(false);
            setOpenConfirmationModal(false);
            setEditProductDetail(null);
            setEditProductFormOpen(false);

            sethasFormValues(false);

            if (window.history.state === null) {
              window.history.back();
            }
          }}
        />
      )}
      {deleteConfirmation && (
        <ConfirmationModal
          title={t('deleteConfirmationModal.title')}
          description={t('deleteConfirmationModal.description')}
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onConfirm={() => {
            dispatch(
              deleteInvoiceProduct({
                invoice_uuid: deleteInvoiceDetail?.invoice_uuid,
                invoice_item_uuid: deleteInvoiceDetail?.invoice_item_uuid,
              })
            );
            setDeleteConfirmation(false);
          }}
        />
      )}
      <Box sx={{ backgroundColor: DIVIDER_COLOR }}>
        {isFromSideBar && (
          <MainWrapper
            defaultPadding="0px 0px 16px 0px"
            title={t('attributes.invoice.invoices')}
            variant="body1"
            isStep={true}
            step={
              nextStepTitle?.step ??
              invoiceDetails?.account_partner_invoice_number
            }
            handleDefaultStep={() =>
              setStepTitle
                ? setStepTitle()
                : navigate(
                    getRedirectURL()[useRole][ROUTE_NAVIGATE_CONST.INVOICE]
                  )
            }
          ></MainWrapper>
        )}
      </Box>
      {isLoading ||
      isShowLoading ||
      updateInvoiceLoading ||
      listTemplateLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            display: 'flex',
            paddingTop: '200px',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isLoading &&
        !isShowLoading &&
        !updateInvoiceLoading &&
        !listTemplateLoading && (
          <Box
            sx={{
              width: '100%',
              backgroundColor: DIVIDER_COLOR,
            }}
          >
            <Box
              sx={{
                bgcolor: 'white',
                marginBottom: '16px',
                width: '100%',
                borderRadius: '4px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ padding: '16px' }}>
                    <Typography variant="body1">
                      {nextStepTitle?.step ??
                        invoiceDetails?.account_partner_invoice_number}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      padding: '16px',
                      borderLeft: `1px solid ${DIVIDER_COLOR}`,
                      borderRight: `1px solid ${DIVIDER_COLOR}`,
                    }}
                  >
                    <Typography variant="body1">
                      {formatStatus(
                        invoiceDetails?.payment_status === PAID
                          ? PAID
                          : invoiceDetails?.payment_status === OVERDUE
                            ? OVERDUE
                            : invoiceDetails?.status === DIFFERED
                              ? DIFFERED
                              : invoiceDetails?.status
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    borderRight: `1px solid ${DIVIDER_COLOR}`,
                  }}
                >
                  <Box sx={{ padding: '16px' }}>
                    <Typography variant="body1">
                      {t('attributes.invoice.invoice_date')}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {formattedDate(invoiceDetails?.created_at)}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    borderRight: `1px solid ${DIVIDER_COLOR}`,
                  }}
                >
                  <Box sx={{ padding: '16px' }}>
                    <Typography variant="body1">
                      {t('attributes.invoice.due_date')}
                    </Typography>
                    <Typography variant="body2">
                      {invoiceDetails?.due_date
                        ? formattedDate(invoiceDetails?.due_date)
                        : '-'}
                    </Typography>
                  </Box>
                </Box>

                {!isFromWorkOrder && invoiceDetails?.work_order_data && (
                  <Box
                    sx={{
                      display: 'flex',
                      borderRight: `1px solid ${DIVIDER_COLOR}`,
                    }}
                  >
                    <Box sx={{ padding: '16px' }}>
                      <Typography variant="body1">
                        {t('attributes.invoice.work_order_id')}
                      </Typography>
                      <Typography variant="body2">
                        {invoiceDetails?.work_order_data?.woid}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                borderRadius: '6px 6px 0 0',
                height: `calc(100vh - ${
                  invoiceDetails?.status === PURCHASE_ORDER_STATUS.SUBMITTED
                    ? '268px'
                    : '310px'
                })`,
                bgcolor: 'white',
              }}
            >
              {/* Table */}
              <Box sx={{ padding: '16px' }}>
                <Box
                  sx={{
                    border: `1px solid ${DIVIDER_COLOR}`,
                    borderRadius: '6px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  {(invoiceDetails?.status === PURCHASE_ORDER_STATUS.DRAFT ||
                    invoiceDetails?.status === PURCHASE_ORDER_STATUS.CREATED ||
                    invoiceDetails?.status === DIFFERED) && (
                    <>
                      <Box sx={{ display: 'flex', padding: '6px' }}>
                        {(invoiceDetails?.status ===
                          PURCHASE_ORDER_STATUS.DRAFT ||
                          invoiceDetails?.status === DIFFERED) && (
                          <>
                            {rows?.length === 0 && (
                              <CustomButton
                                text={t(
                                  'attributes.purchase_order.add_from_quote'
                                )}
                                color="secondary"
                                sx={{ height: '52%' }}
                                startIcon={<AddCircleOutlineOutlinedIcon />}
                                onClick={() => {
                                  setOpenAddProduct(true);
                                  reset(defaultValues);
                                  setAddProductFromQuote(true);
                                  setSelectedProductsError(false);
                                }}
                              />
                            )}
                            <CustomButton
                              text={t('attributes.purchase_order.add_product')}
                              color="secondary"
                              sx={{ height: '52%' }}
                              startIcon={<AddCircleOutlineOutlinedIcon />}
                              onClick={() => {
                                setOpenAddProduct(true);
                                setAddProductFromQuote(false);
                                reset(defaultValues);
                                setSelectedProductsError(false);
                              }}
                            />
                            {/* <CustomButton
                          text="Delete"
                          color="secondary"
                          sx={{ height: '52%' }}
                          startIcon={<DeleteOutlineOutlinedIcon />}
                          onClick={() => ({})}
                        /> */}
                          </>
                        )}
                        {invoiceDetails?.status ===
                          PURCHASE_ORDER_STATUS.CREATED && (
                          <CustomButton
                            text={t('attributes.set_as_draft')}
                            color="secondary"
                            sx={{ height: '52%' }}
                            startIcon={<EditNoteOutlinedIcon />}
                            onClick={() =>
                              handleUpdateInvoiceStatus(
                                PURCHASE_ORDER_STATUS.DRAFT
                              )
                            }
                          />
                        )}
                      </Box>
                      <Divider />
                    </>
                  )}

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <CustomGridTable
                      columns={columns}
                      rows={rows}
                      total={rows?.length}
                      checkboxSelection={false}
                      paginationRequired={false}
                      filterHeight={
                        invoiceDetails?.status ===
                          PURCHASE_ORDER_STATUS.SUBMITTED &&
                        invoiceDetails?.status !== DIFFERED
                          ? 520
                          : 568
                      }
                      disableColumnMenu={true}
                      isLoading={false}
                      isTableView={true}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        py: 2,
                        pr: 1,
                        gap: 1,
                        border: `1px solid ${DIVIDER_COLOR}`,
                      }}
                    >
                      <Typography variant="body1">
                        {t('common.capital.subtotal')}:
                      </Typography>
                      <Typography variant="body2">
                        {calculateTotal({ total: total, type: 'subtotal' })}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        py: 2,
                        pr: 1,
                        gap: 1,
                        border: `1px solid ${DIVIDER_COLOR}`,
                      }}
                    >
                      <Typography variant="body1">
                        {`${TAX_NAME} ${
                          total && totalTax
                            ? calculateTaxPercentage(total, totalTax)
                            : 0
                        }%`}
                        :
                      </Typography>
                      <Typography variant="body2">{totalTax}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        py: 2,
                        pr: 1,
                        gap: 1,
                        backgroundColor: 'rgba(235, 235, 235, 0.2)',
                        border: `1px solid ${DIVIDER_COLOR}`,
                      }}
                    >
                      <Typography variant="body1">
                        {t('common.total')}:
                      </Typography>
                      <Typography variant="body1">
                        {calculateTotal({
                          total: total,
                          taxAmount: totalTax,
                          type: 'total',
                        })}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider />

              {/* Footer button */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  padding: '8px 16px 0 0',
                }}
              >
                {invoiceDetails?.status === DRAFT_STATUS && (
                  <CustomButton
                    text={t('attributes.create')}
                    startIcon={<AddIcon />}
                    disabled={
                      invoiceDetails?.items.length < 1 || updateInvoiceLoading
                    }
                    onClick={() => {
                      handleUpdateInvoiceStatus(PURCHASE_ORDER_STATUS.CREATED);
                    }}
                  />
                )}
                {(invoiceDetails?.status === CREATED_STATUS ||
                  invoiceDetails?.status === PURCHASE_ORDER_STATUS.SUBMITTED ||
                  invoiceDetails?.status === DIFFERED) && (
                  <Box sx={{ display: 'flex', gap: '8px' }}>
                    <CustomButton
                      text={t('attributes.preview')}
                      startIcon={<RemoveRedEyeOutlinedIcon />}
                      color="inherit"
                      onClick={() => {
                        setOpenPreview(true);
                        if (user.company_uuid) {
                          dispatch(editCompany(user?.company_uuid));
                        }
                        if (invoiceDetails?.customer?.contact_uuid) {
                          dispatch(
                            editContact(invoiceDetails?.customer?.contact_uuid)
                          );
                        }
                      }}
                    />
                    {(invoiceDetails?.status !==
                      PURCHASE_ORDER_STATUS.SUBMITTED ||
                      invoiceDetails?.status === DIFFERED) && (
                      <CustomButton
                        text={t('common.submit')}
                        startIcon={<SendOutlinedIcon />}
                        disabled={updateInvoiceLoading}
                        onClick={() =>
                          handleUpdateInvoiceStatus(
                            PURCHASE_ORDER_STATUS.SUBMITTED
                          )
                        }
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )
      )}

      {openPreview && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={openPreview}
          title={invoiceDetails?.account_partner_invoice_number}
          onClose={() => {
            setOpenPreview(false);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.download')}
              color="inherit"
              disabled={isLoading || listTemplateLoading}
              startIcon={<DownloadOutlinedIcon />}
              onClick={() => {
                generatePDF();
              }}
            />,
            invoiceDetails?.status !== PURCHASE_ORDER_STATUS.SUBMITTED &&
              invoiceDetails?.payment_status !==
                CUSTOMER_QUOTATION_STATUS.OVERDUE && (
                <CustomButton
                  text={t('attributes.invoice.send_invoice')}
                  disabled={updateInvoiceLoading}
                  startIcon={<ForwardToInboxOutlinedIcon />}
                  onClick={() =>
                    handleUpdateInvoiceStatus(PURCHASE_ORDER_STATUS.SUBMITTED)
                  }
                />
              ),
          ]}
          width={653}
        >
          {PreviewTemplatePDF}
        </SwipeableDrawer>
      )}

      {openAddProduct && (
        <SwipeableDrawer
          open={openAddProduct}
          title={
            addProductFromQuote
              ? `${t('attributes.purchase_order.add_from_quote')} Q-${defectQuotesList?.id || '-'}`
              : t('attributes.purchase_order.add_product')
          }
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setOpenAddProduct(false);
          }}
          footerButton={
            <CustomButton
              text={t('attributes.invoice.add_to_invoice')}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={disableSave}
            />
          }
          width={SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH}
        >
          <AddInvoiceProduct
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            selectedProductsError={selectedProductsError}
            setSelectedProductsError={setSelectedProductsError}
            productsIdFromQuotes={productsIdFromQuotes}
            isFromDefectQuote={addProductFromQuote}
          />
        </SwipeableDrawer>
      )}
      {editProductDetail && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={editProductFormOpen}
          title={`${editProductDetail?.product}`}
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setEditProductFormOpen(false) && setEditProductDetail(null);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.save')}
              // disabled={updateInvoiceProductLoading}
              startIcon={<SaveOutlinedIcon />}
              onClick={() => {
                handleSubmit(onSubmitEditProduct)();
              }}
            />,
          ]}
          width={653}
        >
          <EditInvoiceProduct
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            data={editProductDetail}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default InvoiceDetail;
