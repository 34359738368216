import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Box, Divider, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { SECONDARY } from '../../../../constants/Colors';
import { previewDocument } from '../../../../constants/common';
import {
  CUSTOMER_TYPE,
  FILE_UPLOAD_SIZES,
} from '../../../../constants/Constants';
import { Validation } from '../../../../constants/FieldValidationMsg';
import getDropdownListHook from '../../../../hooks/getDropdownListHook';
import { uploadDocument } from '../../../../store/CommonAPI';
import { clearTextfields } from '../../../../utils';
import Autocomplete from '../../../CommonComponents/AutoComplete';
import CustomButton from '../../../CommonComponents/CustomButton';
import CommonCheckbox from '../../../CommonComponents/CustomCheckbox';
import CustomDatePicker from '../../../CommonComponents/CustomDatePicker';
import CustomFileUploadButton from '../../../CommonComponents/CustomFileUploadButton';
import CustomTextField from '../../../CommonComponents/CustomTextField';
import { ConfirmationModal } from '../../../CommonComponents/Modal';
import UploadedDocumentView from '../../../CommonComponents/UploadedDocumentView';

const CustomerDetails = ({
  title,
  control,
  setValue,
  setError,
  trigger,
  stateList,
  Section,
  watch,
  customerId,
  formMode,
  clearErrors,
  resetField,
  setServerErrors,
  setOpen,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [documentType, setDocumentType] = useState('');

  const { isDocumentLoading, uploadedSuccessfully, documentData } = useSelector(
    (state) => state.common
  );
  const {
    isLoading: createLoading,
    createdContact,
    error,
  } = useSelector((state) => state.contact.create);

  const [uploadedDocuments, setUploadedDocuments] = useState({
    agreement: null,
    other: null,
  });
  const [agreementError, setAgreementError] = useState('');
  const [otherError, setOtherError] = useState('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [isUploadingAgreement, setIsUploadingAgreement] = useState(false);
  const [isUploadingOther, setIsUploadingOther] = useState(false);

  const handleFileChange = (type) => (event, onChange) => {
    const file = event.target.files[0];

    if (!file || file.type !== 'application/pdf') {
      type === 'agreement'
        ? setAgreementError('Invalid file type. Please upload a PDF.')
        : setOtherError('Invalid file type. Please upload a PDF.');

      return;
    }

    if (file.size > FILE_UPLOAD_SIZES.DOCUMENT) {
      type === 'agreement'
        ? setAgreementError(t('message.customer.uploadDocumentMaxSizeError'))
        : setOtherError(t('message.customer.uploadDocumentMaxSizeError'));

      return;
    }

    type === 'agreement' ? setAgreementError('') : setOtherError('');
    type === 'agreement'
      ? setIsUploadingAgreement(true)
      : setIsUploadingOther(true);

    if (type === 'agreement') {
      setAgreementDocFile(file);
    } else {
      setOtherDocFile(file);
    }

    const fileSize = (file.size / 1024).toFixed(0) + ' KB';

    setUploadedDocuments((prev) => ({
      ...prev,
      [type]: { name: file.name, size: fileSize },
    }));

    onChange(event);
    dispatch(
      uploadDocument({ document_type: type, uploaded_by: 1, file })
    ).then((response) => {
      if (response.payload?.data[0]) {
        const documentData = response.payload?.data[0];

        setValue(`${type}Document`, {
          document_uuid: documentData?.uuid,
          document_type: documentData?.document_type,
          document_name: file.name,
          document_size: fileSize,
        });

        type === 'agreement'
          ? setIsUploadingAgreement(false)
          : setIsUploadingOther(false);
      } else {
        type === 'agreement'
          ? setAgreementError('File upload failed. Please try again.')
          : setOtherError('File upload failed. Please try again.');
        type === 'agreement'
          ? setIsUploadingAgreement(false)
          : setIsUploadingOther(false);
      }
    });
  };

  const handleDeleteDocument = (type) => {
    setUploadedDocuments((prev) => ({
      ...prev,
      [type]: null,
    }));

    setValue(`${type}Document`, null);
    const input = document.querySelector(`input[name="${type}Document"]`);

    if (input) {
      input.value = ''; // Reset the file input
    }

    type === 'agreement' ? setAgreementError('') : setOtherError('');
  };

  const [agreementDocFile, setAgreementDocFile] = useState(null);
  const [otherDocFile, setOtherDocFile] = useState(null);

  const { contactDropdownLoading, contactDropdownData } = getDropdownListHook({
    reducerName: 'contact',
    dropdownListName: 'getDropdown',
    labelName: 'name',
    valueName: 'uuid',
    address: 'address',
    address_line_2: 'address_line_2',
    email: 'email',
    city: 'city',
    state: 'state',
    postcode: 'postcode',
  });

  useEffect(() => {
    if (createdContact && contactDropdownData.length > 0) {
      const result = contactDropdownData?.filter(
        (item) => item.value === createdContact?.data[0]?.uuid
      )[0];

      setValue(
        'customer_contact',
        result
          ? {
              ...result,
            }
          : null
      );
    }
  }, [createdContact, contactDropdownData]);

  return (
    <>
      {/* Contact details */}
      <Box sx={{ background: SECONDARY, borderRadius: '4px' }}>
        <Box>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="h2">{title}</Typography>
          </Box>
          <Divider />
          <Section>
            <Controller
              name="customer_name"
              control={control}
              rules={{
                required: `Customer Name ${Validation.general.required}`,
                maxLength: {
                  value: 80,
                  message: Validation.general.customerName,
                },
              }}
              render={({
                field: { onChange, value, name, onBlur },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Customer Name"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('customer_name');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  onBlur={() => {
                    onChange(value.trim());
                    onBlur();
                  }}
                />
              )}
            />
            <Controller
              name="customer_contact"
              control={control}
              rules={{
                required: `${t('attributes.customer.customerContact')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={`${t('attributes.customer.customerContact')}`}
                  options={contactDropdownData}
                  value={value}
                  onChange={(e, newValue) => {
                    if (newValue || newValue === null) {
                      onChange(newValue);
                    }
                    trigger('customer_contact');
                  }}
                  isLoadingData={contactDropdownLoading}
                  helperText={error ? error.message : ''}
                  error={error}
                />
              )}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '-16px 0px',
              }}
            >
              <CustomButton
                startIcon={<AddCircleOutlineOutlinedIcon />}
                text={t('attributes.add')}
                style={{ textDecoration: 'underline' }}
                variant="text"
                onClick={() => setOpen(true)}
              />
            </Box>
            <Controller
              name="abnNumber"
              control={control}
              rules={{
                required: `ABN Number ${Validation.general.required}`,
                pattern: {
                  value: /^\d{11}$/,
                  message: Validation.general.abnNumber,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="ABN Number"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '');

                    onChange(numericValue);
                    trigger('abnNumber');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                />
              )}
            />
            <Controller
              name="customer_type"
              control={control}
              rules={{
                required: `${t('attributes.productCatalogue.type')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={`${t('attributes.productCatalogue.type')}`}
                  options={CUSTOMER_TYPE}
                  value={value}
                  onChange={(e, newValue) => {
                    if (newValue || newValue === null) {
                      onChange(newValue);
                    }
                    trigger('customer_type');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                />
              )}
            />
            <CustomDatePicker
              control={control}
              name="agreementSignedDate"
              isRequired={true}
              label="Agreement Signed Date"
              pickerType="date"
              defaultValue={null}
              trigger={trigger}
            />
            <Controller
              name="notes"
              control={control}
              rules={{
                maxLength: {
                  value: 65535,
                  message: Validation.general.notes,
                },
              }}
              render={({
                field: { onChange, value, name, onBlur },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Notes"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('notes');
                  }}
                  multiline
                  rows={2}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                  onBlur={() => {
                    onChange(value.trim());
                    onBlur();
                  }}
                />
              )}
            />
          </Section>
        </Box>
      </Box>

      {customerId && formMode === 'edit' && (
        <Box
          sx={{
            background: SECONDARY,
            borderRadius: '4px',
            width: '468px',
          }}
        >
          <Box sx={{ padding: '16px' }}>
            <CommonCheckbox
              control={control}
              name="active"
              label="Active"
              isRequired={false}
              showMessage={true}
              messageOnChecked="Customer will be moved to active"
              messageOnUnchecked="Customer will be moved to inactive"
            />
          </Box>
        </Box>
      )}

      {/* Attachment Form */}
      <Box
        sx={{
          background: SECONDARY,
          borderRadius: '4px',
          width: '468px',
        }}
      >
        <Box sx={{ padding: '16px' }}>
          <Typography variant="h2">
            {t('common.attach_agreement_documents')}
          </Typography>
        </Box>
        <Divider />
        <Section>
          <Controller
            name="agreementDocument"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <>
                <CustomFileUploadButton
                  name={name}
                  onFileChange={(event) => {
                    handleFileChange('agreement')(event, onChange);
                    setAgreementDocFile(event.target.files[0]);
                    trigger('agreementDocument');
                  }}
                  multiple={false}
                  buttonText="Click to upload or drag and drop the PDF file"
                  accept=".pdf"
                  error={!!agreementError}
                  errorMessage={agreementError}
                />
                {((!isEmpty(value) && value?.document_uuid) ||
                  uploadedDocuments?.agreement?.name) && (
                  <UploadedDocumentView
                    fileName={
                      isUploadingAgreement
                        ? uploadedDocuments?.agreement?.name
                        : value?.document_name || ''
                    }
                    fileSize={
                      isUploadingAgreement
                        ? uploadDocument?.agreement?.name
                        : value?.document_size || ''
                    }
                    isUploading={isUploadingAgreement}
                    onclick={() => {
                      if (agreementDocFile) {
                        previewDocument(
                          t,
                          dispatch,
                          { file: agreementDocFile },
                          true
                        );
                      } else {
                        previewDocument(t, dispatch, value?.document_uuid);
                      }
                    }}
                    onDelete={() => {
                      setOpenConfirmationModal(true);
                      setDocumentType('agreement');
                    }}
                  />
                )}
              </>
            )}
          />
        </Section>
      </Box>

      {/* Other Documents */}
      <Box
        sx={{
          background: SECONDARY,
          borderRadius: '4px',
          width: '468px',
        }}
      >
        <Box sx={{ padding: '16px' }}>
          <Typography variant="h2">{t('common.otherDocument')}</Typography>
        </Box>
        <Divider />
        <Section>
          <Controller
            name="otherDocument"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <>
                <CustomFileUploadButton
                  name={name}
                  onFileChange={(event) => {
                    handleFileChange('other')(event, onChange);
                    setOtherDocFile(event.target.files[0]);
                    // onChange(event);
                    trigger('otherDocument');
                  }}
                  multiple={false}
                  buttonText="Click to upload or drag and drop the PDF file"
                  accept=".pdf"
                  error={!!otherError}
                  errorMessage={otherError}
                />
                {((!isEmpty(value) && value?.document_uuid) ||
                  uploadedDocuments?.other?.name) && (
                  <UploadedDocumentView
                    fileName={
                      isUploadingOther
                        ? uploadedDocuments?.other?.name
                        : value?.document_name || ''
                    }
                    fileSize={
                      isUploadingOther
                        ? uploadDocument?.other?.name
                        : value?.document_size || ''
                    }
                    isUploading={isUploadingOther}
                    onclick={() => {
                      if (otherDocFile) {
                        previewDocument(
                          t,
                          dispatch,
                          { file: otherDocFile },
                          true
                        );
                      } else {
                        previewDocument(t, dispatch, value?.document_uuid);
                      }
                    }}
                    onDelete={() => {
                      setOpenConfirmationModal(true);
                      setDocumentType('other');
                    }}
                  />
                )}
              </>
            )}
          />
        </Section>
      </Box>

      {openConfirmationModal && (
        <ConfirmationModal
          title="Delete Document"
          description="Are you sure you want to delete this document?"
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            handleDeleteDocument(documentType);
            setOpenConfirmationModal(false);
          }}
        />
      )}
    </>
  );
};

export default CustomerDetails;
