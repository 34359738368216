import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { USER_MANAGEMENT } from '../actionPrefix';
import { axiosInstanceAuthAndFilter } from '../axios';

export const getUsersList = createAsyncThunk(
  USER_MANAGEMENT.GET_USER_LIST,
  async (
    {
      page,
      search,
      limit,
      active,
      state,
      city,
      order,
      orderBy,
      status,
      role,
      company,
      list_column_names,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (limit) params.append('items_per_page', limit);
    if (active) params.append('active', active);
    if (state) params.append('state', state);
    if (city) params.append('city', city);
    if (order) params.append('sort_order', order);
    if (orderBy) params.append('sort_by', orderBy);
    if (company) params.append('company_uuid', company);
    if (status) params.append('status', status);
    if (role) params.append('role', role);

    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosInstanceAuthAndFilter.get(
        `${APIs.USER_MANAGEMENT.GET_USER_LIST}?${params.toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
