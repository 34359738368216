import { createSlice } from '@reduxjs/toolkit';

const propertyAssetFilter = {
  searchText: '',
  equipmentType: null,
  product: null,
  status: null,
  active: { label: 'Yes', value: 'yes' },
};

const propertyFloorPlanFilter = {
  searchText: '',
};

const propertyServiceQuoteFilter = {
  status: null,
  due_date: null,
  quote_date: null,
  searchText: '',
};

const propertyInvoiceQuoteFilter = {
  status: null,
  invoice_date: null,
  due_date: null,
  searchText: '',
};

const filtersState = createSlice({
  name: 'filters',
  initialState: {
    propertyAssetFilter,
    propertyFloorPlanFilter,
    propertyServiceQuoteFilter,
    propertyInvoiceQuoteFilter,
  },
  reducers: {
    setPropertyAssetFilter: (state, action) => {
      state.propertyAssetFilter = { ...action.payload };
    },
    resetPropertyAssetFilter: (state) => {
      state.propertyAssetFilter = { ...propertyAssetFilter };
    },
    setPropertyFloorPlanFilter: (state, action) => {
      state.propertyFloorPlanFilter = { ...action.payload };
    },
    resetPropertyFloorPlanFilter: (state) => {
      state.propertyFloorPlanFilter = { ...propertyFloorPlanFilter };
    },
    setPropertyServiceQuoteFilter: (state, action) => {
      state.propertyServiceQuoteFilter = { ...action.payload };
    },
    resetPropertyServiceQuoteFilter: (state) => {
      state.propertyServiceQuoteFilter = { ...propertyServiceQuoteFilter };
    },
    setPropertyInvoiceQuoteFilter: (state, action) => {
      state.propertyInvoiceQuoteFilter = { ...action.payload };
    },
    resetPropertyInvoiceQuoteFilter: (state) => {
      state.propertyInvoiceQuoteFilter = { ...propertyInvoiceQuoteFilter };
    },
  },
});

export const {
  setPropertyAssetFilter,
  resetPropertyAssetFilter,
  setPropertyFloorPlanFilter,
  resetPropertyFloorPlanFilter,
  setPropertyServiceQuoteFilter,
  resetPropertyServiceQuoteFilter,
  setPropertyInvoiceQuoteFilter,
  resetPropertyInvoiceQuoteFilter,
} = filtersState.actions;
export default filtersState.reducer;
