import React from 'react';

import { Box, Tab, Tabs, Typography } from '@mui/material';

const CustomTabPanel = (props) => {
  const {
    children,
    value,
    index,
    isSubDetails,
    subComponentPadding,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: !isSubDetails && subComponentPadding ? 2 : 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const TabView = ({
  tabs = [],
  activeTab = 0,
  setActiveTab = () => {},
  onTabChange = () => {},
  isSubDetails = false,
  subComponentPadding = true,
}) => {
  const handleChange = (event, newValue) => {
    onTabChange();
    setActiveTab(newValue);
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs?.map((tab, tabIndex) => (
            <Tab
              key={tabIndex}
              label={<Typography variant="subtitle2">{tab?.label}</Typography>}
              {...a11yProps(tabIndex)}
            />
          ))}
        </Tabs>
      </Box>
      {tabs?.map((tab, tabIndex) => (
        <CustomTabPanel
          value={activeTab}
          index={tabIndex}
          key={tabIndex}
          isSubDetails={isSubDetails}
          subComponentPadding={subComponentPadding}
        >
          {tab.component}
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default TabView;
