import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';

import { Box } from '@mui/material';

import { DIVIDER_COLOR } from '../../constants/Colors';
import {
  ACTIVE,
  PROPERTY_DETAIL_STEP,
  ROUTE_NAVIGATE_CONST,
} from '../../constants/Constants';
import {
  CUSTOMER,
  GLOBAL_ADMIN,
  OFFICE_MANAGEMENT,
  OFFICE_STAFF,
  SUPER_ADMIN,
} from '../../constants/Roles';
import useDetailPageValidation from '../../hooks/useDetailPageValidation';
import {
  resetPropertyAssetFilter,
  resetPropertyFloorPlanFilter,
  resetPropertyInvoiceQuoteFilter,
  resetPropertyServiceQuoteFilter,
} from '../../store/filters/reducer';
import { resetUpdateInvoice } from '../../store/invoices/reducer';
import { resetGetBillingContractById } from '../../store/users/billingContract/reducer';
import { resetUpdateQuotationStatus } from '../../store/workOrder/reducer';
import ComingSoon from '../CommonComponents/ComingSoon';
import MainWrapper from '../CommonComponents/MainWrapper';
import TabView from '../CommonComponents/TabView';
import BillingContract from '../Users/BillingContracts/BillingContracts';
import Invoices from '../WorkOrder/Invoices/Invoices';
import AssetHistory from './AssetHistory';
import DefectHistoryList from './DefectHistoryList';
import FloorPlanDetail from './FloorPlanDetail';
import PropertyDetail from './PropertyDetail';
import ServiceQuotes from './ServiceQuotes/ServiceQuotes';

// Constants for tab map and labels
const TAB_HASH_MAP = {
  0: '',
  1: ROUTE_NAVIGATE_CONST.PROPERTY_ASSET,
  2: ROUTE_NAVIGATE_CONST.PROPERTY_FLOOR_PLAN,
  3: ROUTE_NAVIGATE_CONST.PROPERTY_ROUTINE,
  4: ROUTE_NAVIGATE_CONST.PROPERTY_CONTRACT,
  5: ROUTE_NAVIGATE_CONST.PROPERTY_SERVICE_QUOTE,
  6: ROUTE_NAVIGATE_CONST.PROPERTY_INVOICE,
  7: ROUTE_NAVIGATE_CONST.PROPERTY_COMING_SOON,
};

const PropertyDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const {
    propertyId,
    selectedProperty,
    propertyDataloading,
    propertyNumId,
    handleEditPropertyForm,
    isEditable,
    handleDefaultStep,
  } = { ...outletContext, ...props };

  const { profileDetails } = useSelector((state) => state.common);

  useDetailPageValidation({
    is_error: false,
    data: selectedProperty,
    is_loading: propertyDataloading,
  });

  // Deriving initial tab index from URL hash
  const initialTabIndex =
    Object.keys(TAB_HASH_MAP).find(
      (key) => pathname.split('/').pop() === TAB_HASH_MAP[key]
    ) || 0;

  const [activeTab, setActiveTab] = useState(initialTabIndex);
  const [nextStepTitle, setNextStepTitle] = useState();

  // Reset steps on change
  const handleNextStep = useCallback(() => {
    setNextStepTitle(undefined);
    dispatch(resetUpdateInvoice());
    dispatch(resetUpdateQuotationStatus());
  }, [dispatch]);

  const handleTabChange = (index) => {
    setActiveTab(index);
    if (nextStepTitle?.title !== PROPERTY_DETAIL_STEP.assetDetails) {
      const newHash = TAB_HASH_MAP[index] || '';

      navigate(`${newHash}`);
    }
  };

  useEffect(() => {
    if (nextStepTitle?.title !== PROPERTY_DETAIL_STEP.assetDetails) {
      const last_route = pathname.split('/').pop();
      const hashIndex = Object.keys(TAB_HASH_MAP).find(
        (key) => last_route === TAB_HASH_MAP[key]
      );

      setActiveTab(hashIndex ? parseInt(hashIndex) : 0);
    } else {
      setActiveTab(0);
    }

    return () => {
      dispatch(resetGetBillingContractById());
    };
  }, [pathname, nextStepTitle, dispatch]);

  useEffect(
    () => () => {
      dispatch(resetPropertyAssetFilter());
      dispatch(resetPropertyFloorPlanFilter());
      dispatch(resetPropertyServiceQuoteFilter());
      dispatch(resetPropertyInvoiceQuoteFilter());
    },
    []
  );

  // Tabs definition
  const tabs = [
    {
      label: t('attributes.property.propertyDetails'),
      component: (
        <PropertyDetail
          selectedProperty={selectedProperty}
          propertyDataloading={propertyDataloading}
          handleEditPropertyForm={handleEditPropertyForm}
          isEditable={isEditable}
        />
      ),
      access: [
        GLOBAL_ADMIN,
        SUPER_ADMIN,
        CUSTOMER,
        OFFICE_STAFF,
        OFFICE_MANAGEMENT,
      ],
    },
    {
      label:
        nextStepTitle?.title === PROPERTY_DETAIL_STEP.assetDetails
          ? t('attributes.property.asset_details')
          : t('attributes.property.assets'),
      component: (
        <Outlet
          context={{
            propertyId: propertyId,
            isEditable: isEditable,
            nextStepTitle: nextStepTitle,
            setNextStepTitle: setNextStepTitle,
            setActiveTab: setActiveTab,
            isPropertyActive:
              selectedProperty?.property_status?.name === ACTIVE,
            propertyDataloading: propertyDataloading,
            selectedProperty: selectedProperty,
          }}
        />
      ),
      access: [SUPER_ADMIN, CUSTOMER, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
    {
      label: t('attributes.property.floorPlans'),
      component: (
        <Outlet
          context={{
            propertyId: propertyId,
            nextStepTitle: nextStepTitle,
            setNextStepTitle: setNextStepTitle,
            setActiveTab: setActiveTab,
          }}
        />
      ),
      access: [SUPER_ADMIN, CUSTOMER, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
    {
      label: 'Routines',
      component: <Outlet context={{ selectedProperty }} />,
      access: [SUPER_ADMIN, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
    {
      label: t('attributes.billingContract.billingContracts'),
      component: (
        <Outlet
          context={{
            propertyId: propertyNumId,
            isFromProperty: true,
            setNextStepTitle: setNextStepTitle,
            setActiveTab: setActiveTab,
            selectedProperty: selectedProperty,
          }}
        />
      ),
      access: [SUPER_ADMIN, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
    {
      label: t('attributes.service_quotes.service_quotes'),
      component: (
        <Outlet
          context={{
            propertyId: propertyId,
            nextStepTitle: nextStepTitle,
            setNextStepTitle: setNextStepTitle,
          }}
        />
      ),
      access: [SUPER_ADMIN, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
    {
      label: t('attributes.invoice.invoices'),
      component: (
        <Outlet
          context={{
            propertyId: propertyId,
            nextStepTitle: nextStepTitle,
            setNextStepTitle: setNextStepTitle,
            isFromWorkOrder: false,
          }}
        />
      ),
      access: [SUPER_ADMIN, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
    {
      label: t('attributes.comingSoon'),
      component: <Outlet />,
      access: [SUPER_ADMIN, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
  ];

  const detailsTabs = [
    {
      label:
        nextStepTitle?.title === PROPERTY_DETAIL_STEP.assetDetails
          ? t('attributes.property.asset_details')
          : t('attributes.property.assets'),
      component: (
        <Outlet
          context={{
            propertyId: propertyId,
            isEditable: isEditable,
            nextStepTitle: nextStepTitle,
            setNextStepTitle: setNextStepTitle,
            setActiveTab: setActiveTab,
          }}
        />
      ),
      access: [SUPER_ADMIN, CUSTOMER, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
    {
      label: t('attributes.property.defect_history'),
      component: (
        <DefectHistoryList
          propertyId={propertyId}
          assetUUID={nextStepTitle?.asset_uuid}
        />
      ),
      access: [SUPER_ADMIN, CUSTOMER, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
    {
      label: t('attributes.property.history'),
      component: (
        <AssetHistory
          propertyId={propertyId}
          assetUUID={nextStepTitle?.asset_uuid}
        />
      ),
      access: [SUPER_ADMIN, CUSTOMER, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
  ];

  const floorTab = [
    {
      label: '',
      component: <ComingSoon />,
      access: [SUPER_ADMIN, CUSTOMER, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
  ];

  // Filter tabs based on the user's role
  const filteredTabs = tabs.filter((tab) =>
    tab.access.includes(profileDetails?.role_name)
  );
  const filteredDetailsTabs = detailsTabs.filter((tab) =>
    tab.access.includes(profileDetails?.role_name)
  );

  const renderTabView = () => (
    <Box sx={{ width: '100%', backgroundColor: DIVIDER_COLOR }}>
      <Box sx={{ width: '100%', backgroundColor: 'white' }}>
        <TabView
          tabs={
            nextStepTitle?.title === PROPERTY_DETAIL_STEP.assetDetails
              ? filteredDetailsTabs
              : filteredTabs
          }
          activeTab={activeTab}
          setActiveTab={handleTabChange}
          isSubDetails={true}
        />
      </Box>
    </Box>
  );

  const renderBillingContract = () => (
    <Box sx={{ maxHeight: 'calc(100vh - 200px)' }}>
      <BillingContract
        propertyId={propertyNumId}
        isFromProperty={true}
        nextStepTitle={nextStepTitle}
        setNextStepTitle={setNextStepTitle}
        setActiveTab={setActiveTab}
        selectedProperty={selectedProperty}
      />
    </Box>
  );

  const renderInvoices = () => (
    <Box sx={{ maxHeight: 'calc(100vh - 200px)' }}>
      <Invoices
        nextStepTitle={nextStepTitle}
        setNextStepTitle={setNextStepTitle}
        isFromWorkOrder={false}
      />
    </Box>
  );

  const renderFloorPlanDetail = () => (
    <Box sx={{ maxHeight: 'calc(100vh - 200px)' }}>
      <FloorPlanDetail />
    </Box>
  );

  const renderServiceQuotesDetail = () => (
    <Box sx={{ maxHeight: 'calc(100vh - 200px)' }}>
      <ServiceQuotes
        propertyId={propertyId}
        nextStepTitle={nextStepTitle}
        setNextStepTitle={setNextStepTitle}
      />
    </Box>
  );

  return (
    <>
      <Box sx={{ bgcolor: DIVIDER_COLOR, paddingBottom: '16px' }}>
        <MainWrapper
          defaultPadding="0px"
          title="Properties"
          variant="body1"
          isStep={true}
          nextStepTitle={nextStepTitle}
          step={propertyDataloading ? '' : selectedProperty?.pid}
          handleDefaultStep={handleDefaultStep}
          handleNextStep={handleNextStep}
        />
      </Box>

      {nextStepTitle?.title !== PROPERTY_DETAIL_STEP.billingContracts &&
        nextStepTitle?.title !== PROPERTY_DETAIL_STEP.invoice &&
        nextStepTitle?.title !== PROPERTY_DETAIL_STEP.floorPlan &&
        nextStepTitle?.title !== PROPERTY_DETAIL_STEP.serviceQuote &&
        renderTabView()}

      {nextStepTitle?.title === PROPERTY_DETAIL_STEP.billingContracts &&
        renderBillingContract()}

      {nextStepTitle?.title === PROPERTY_DETAIL_STEP.invoice &&
        renderInvoices()}
      {nextStepTitle?.title === PROPERTY_DETAIL_STEP.floorPlan &&
        renderFloorPlanDetail()}

      {nextStepTitle?.title === PROPERTY_DETAIL_STEP.serviceQuote &&
        renderServiceQuotesDetail()}
    </>
  );
};

export default PropertyDetails;
