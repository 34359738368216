import { Box, styled } from '@mui/material';

// Internal
import {
  FilterButton,
  RefreshDashboardButton,
  ResetFilterButton,
} from '../CommonComponents/FilterButton';
import Search from '../CommonComponents/Search';

const FilterComponentStyled = styled(Box)(({ theme }) => ({
  borderRadius: '4px',
  backgroundColor: theme.palette.divider,
  padding: '8px',
  display: 'flex',
  columnGap: '16px',
  rowGap: '16px',
  flexWrap: 'wrap',
}));

const FilterSectionStyled = styled(Box)(({ theme, sx }) => ({
  display: 'flex',
  columnGap: '16px',
  justifyContent: 'flex-end',
  alignItems: 'center',
  margin: '0 0 16px 0',
  ...sx,
}));

export const FilterComponent = ({ children }) => (
  <FilterComponentStyled>{children}</FilterComponentStyled>
);

export const FilterSection = ({
  isSearch = true,
  isFilter = true,
  isReset = true,
  isRefresh = false,
  onFilterBtnClick = () => {},
  searchText = '',
  onSearchChange = () => {},
  onResetFilter = () => {},
  onRefreshFilter = () => {},
  isResetButtonVisible = false,
  isActive = false,
  isFilterDisable = false,
  refreshButtonText = 'Refresh',
  sx = {},
}) => (
  <FilterSectionStyled sx={sx}>
    {isRefresh && (
      <RefreshDashboardButton
        onClick={onRefreshFilter}
        disabled={false}
        label={refreshButtonText}
      />
    )}
    {isReset && (
      <ResetFilterButton
        onClick={onResetFilter}
        disabled={!isResetButtonVisible}
      />
    )}
    {isFilter && (
      <FilterButton
        onClick={onFilterBtnClick}
        isActive={isActive}
        disabled={isFilterDisable}
      />
    )}
    {isSearch && <Search value={searchText} onChange={onSearchChange} />}
  </FilterSectionStyled>
);
