import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box, Typography } from '@mui/material';
import { debounce } from 'lodash';

import { exportToCSV } from '../../constants/common';
import { PROPERTY_DETAIL_STEP } from '../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  COLUMNS_TO_MAP,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
import { CUSTOMER, GLOBAL_ADMIN } from '../../constants/Roles';
import { SWIPEABLE_DRAWER_WIDTH } from '../../constants/Typography';
import getDropdownDataHook from '../../hooks/getDropdownDataHook';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import useBrowserBackButtonHandler from '../../hooks/useBrowserBackButtonHandler';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../store/CommonReducer';
import {
  getCategoryLevelsDropdown,
  getEquipmentClassDropdown,
} from '../../store/equipmentTypes/api';
import { setPropertyAssetFilter } from '../../store/filters/reducer';
import {
  createAsset,
  createWorkOrderFromAsset,
  getAssetDetails,
  getAssetStatus,
  getEquipmentTypes,
  getProducts,
  getPropertyAssetsList,
  updateAsset,
} from '../../store/property/api';
import {
  resetAssetsList,
  resetcreateAsset,
  resetCreateWorkOrderFromAsset,
  resetUpdateAsset,
} from '../../store/property/reducer';
import { getBillingContractList } from '../../store/users/billingContract/api';
import {
  checkIsFiltersApplied,
  formatDateAPI,
  formatStatus,
  formattedDate,
  loggedInUserRole,
  showHideTopbarLoader,
} from '../../utils';
import { Edit, Generate, View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import AssetDetails from './AssetDetails';
import AssetForm from './AssetForm';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

//default filters
const defaultFilters = {
  equipmentType: null,
  product: null,
  status: null,
  active: { label: 'Yes', value: 'yes' },
};

export const assetFormDefaultValues = {
  equipment_type: null,
  product: null,
  location: null,
  identification_number: null,
  qr_code: null,
  make: null,
  model: null,
  size: null,
  quantity: null,
  base_date: null,
  installation_date: null,
  internal_note: null,
  active: null,
  category_name: '',
  category_level1: null,
  category_level2: null,
  category_level3: null,
  asset_number: 0,
  walk_order: 0,
};

const AssetsList = (props) => {
  const dispatch = useDispatch();
  const outletProps = useOutletContext();
  const {
    propertyId,
    isEditable,
    nextStepTitle,
    setNextStepTitle,
    setActiveTab,
    isPropertyActive,
    propertyDataloading,
    selectedProperty,
  } = { ...props, ...outletProps };
  const { t } = useTranslation();

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(true);
  const [filters, setFilters] = useState(defaultFilters);
  const [searchText, setSearchText] = useState('');
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [open, setOpen] = useState(false);
  const [serverErrors, setServerErrors] = useState([]);
  const [assetId, setAssetId] = useState('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [hasFormValues, sethasFormValues] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [selectedRows, setSelectedRows] = useState(null);

  const { equipmentTypesList, assetStatusList, products, assetsList } =
    useSelector((state) => state.property);
  const {
    data: createdAsset,
    loading: createAssetLoading,
    error: createAssetError,
  } = useSelector((state) => state.property.createAsset);

  const {
    data: updatedAsset,
    loading: updatedAssetLoading,
    error: updatedAssetError,
  } = useSelector((state) => state.property.updatedAsset);

  // Product Enhancement Data
  const { data: productEnhancementData } = useSelector(
    (state) => state.productCatalogue.productEnhancementData
  );

  const {
    data: createWorkOrder,
    loading: createWorkOrderLoading,
    error: createWorkOrderError,
  } = useSelector((state) => state.property.createWorkOrder);

  const { propertyAssetFilter } = useSelector((state) => state.filters);

  const { equipmentTypesDropdownLoading, equipmentTypesDropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'equipmentTypesDropdownList',
      labelName: 'display_name',
      valueName: 'id',
      code: 'code',
      isPressure: 'is_pressure',
    });

  // Get equipment class list
  const { equipmentClassDropdownLoading, equipmentClassDropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'equipmentClassDropdownList',
      customNameForDropdown: 'equipmentClass',
      labelName: 'name',
      valueName: 'id',
    });

  // Get category levels 1 list
  const { categoryLevels1DropdownLoading, categoryLevels1DropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'categoryLevels1DropdownList',
      customNameForDropdown: 'categoryLevels1',
      labelName: 'name',
      valueName: 'id',
      isOther: 'is_other',
    });

  const { billingContractActiveList } = useSelector(
    (state) => state.billingContract.getList
  );

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    trigger,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      quantity: 1,
    },
  });

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  useBrowserBackButtonHandler({
    hasUnsavedChanges: hasFormValues,
    setOpenConfirmationModal,
    open,
    watchMethod: watch,
    sethasFormValues,
  });

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  const assetStatus = getDropdownDataHook({
    data: assetStatusList?.data?.data,
    labelName: 'display_name',
    valueName: 'id',
  });

  useEffect(() => {
    dispatch(getProducts({ equipment_type: filters.equipmentType?.code }));
  }, [filters.equipmentType]);

  const findIdByCode = (data, selectedData) => {
    const matchedItem = data?.find(
      (item) => item?.value === selectedData?.value
    );

    return matchedItem ? matchedItem.value : null;
  };

  useEffect(() => {
    if (filters.equipmentType) {
      dispatch(
        getEquipmentClassDropdown({
          equipment_type_id: filters.equipmentType?.value,
          status: true,
        })
      );
    }
  }, [filters.equipmentType]);

  useEffect(() => {
    if (filters.assetType) {
      const categoryLevels = [
        {
          ecid: filters.assetType?.value,
          parent_cid: null,
          current_category: 1,
        },
      ];

      categoryLevels.forEach((level) => {
        dispatch(getCategoryLevelsDropdown(level));
      });
    }
  }, [filters.assetType]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'asset_name',
        headerName: 'Asset Name',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Typography
            variant="body1"
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              setAssetId(row?.id);
              dispatch(getAssetDetails({ assetId: row?.id }));
              setNextStepTitle({
                title: PROPERTY_DETAIL_STEP.assetDetails,
                step: row.asset_name,
                asset_uuid: row.uuid,
              });
            }}
          >
            {row.asset_name}
          </Typography>
        ),
      },
      {
        field: 'equipment_type',
        headerName: t('attributes.property.assetGroup'),
        flex: 1,
        sortable: true,
      },
      {
        field: 'equipment_class',
        headerName: t('attributes.property.assetType'),
        flex: 1,
        sortable: true,
      },
      {
        field: 'product',
        headerName: t('attributes.property.assetVariant'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'qr_code',
        headerName: t('attributes.property.qr_code'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'location',
        headerName: 'Level',
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: ({ row }) => formatStatus(row.status),
      },
      // {
      //   field: 'active',
      //   headerName: 'Active',
      //   flex: 1,
      //   sortable: false,
      //   renderCell: ({ row }) => formatStatus(row.active),
      // },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <View
              onClick={() => {
                setAssetId(row?.id);
                dispatch(getAssetDetails({ assetId: row?.id }));
                setNextStepTitle({
                  title: PROPERTY_DETAIL_STEP.assetDetails,
                  step: row.asset_name,
                  asset_uuid: row?.uuid,
                  id: row?.id,
                });
                setActiveTab(0);
              }}
            />
            {loggedInUserRole() !== CUSTOMER && (
              <Edit
                onClick={() => {
                  setOpen(true);
                  setAssetId(row?.id);
                }}
              />
            )}
            {(loggedInUserRole() !== GLOBAL_ADMIN ||
              loggedInUserRole() === CUSTOMER) && (
              <Generate
                disabled={row?.is_work_order || createWorkOrderLoading}
                onClick={() => {
                  showHideTopbarLoader(true, dispatch);
                  dispatch(createWorkOrderFromAsset({ asset_id: row?.id }));
                }}
              />
            )}
          </Box>
        ),
      },
    ];

    return baseColumns;
  }, []);

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  useEffect(() => {
    dispatch(
      getBillingContractList({
        limit: -1,
        customerUUID: selectedProperty?.customer_uuid,
        active: true,
      })
    );
    dispatch(getAssetStatus());

    return () => {
      dispatch(resetUpdateAsset());
      dispatch(resetcreateAsset());
      dispatch(resetAssetsList());
      dispatch(resetCreateWorkOrderFromAsset());
    };
  }, []);

  useEffect(() => {
    if (billingContractActiveList) {
      const propertyStandardId = () =>
        billingContractActiveList?.filter(
          (item) =>
            item.uuid ===
            selectedProperty?.contract_assignment_details
              ?.active_billing_contract_uuid
        )?.[0]?.standard_id;

      dispatch(getEquipmentTypes({ standards_id: propertyStandardId() }));
    }
  }, [billingContractActiveList, selectedProperty]);

  const visibleFieldsString = [
    ...columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field),
    'label',
  ].join(',');

  const getAllAssetsList = useCallback(() => {
    setSelectedRows(null);
    const req = {
      order: order,
      orderBy: orderBy,
      page: currentPage,
      size: perPageData,
      search: searchText,
      status: filters.status?.map((item) => item.value)?.toString(),
      is_active: filters.active?.value,
      status_id: filters.status?.map((item) => item.value)?.toString(),
      product_id: findIdByCode(
        categoryLevels1DropdownData,
        filters?.assetVariant
      ),
      equipment_class_id: findIdByCode(
        equipmentClassDropdownData,
        filters?.assetType
      ),
      equipment_type_id: findIdByCode(
        equipmentTypesDropdownData,
        filters?.equipmentType
      ),
      property_uuid: propertyId,
      list_column_names: visibleFieldsString,
    };

    setIsDataLoading(true);
    dispatch(getPropertyAssetsList(req)).finally(() => {
      setIsDataLoading(false);
      setIsInitialLoading(false);
    });
  }, [
    dispatch,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
    propertyId,
    refresh,
  ]);

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllAssetsList, 500), [
    getAllAssetsList,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const rows = assetsList?.data?.data?.map((item) => ({
    ...item,
    active: item.is_active,
    asset_name: item.label,
    base_date: formattedDate(item.base_date),
    installation_date: formattedDate(item.installation_date),
    equipment_type: item.equipment_type.display_name,
    equipment_class: item.equipment_class.name,
    product:
      `${item?.product?.display_name}${item?.other_category_name ? `(${item?.other_category_name})` : ''}` ||
      '-',
    status: item.status?.display_name ?? '-',
    qr_code: item?.barcode,
  }));

  useEffect(() => {
    showHideTopbarLoader(createAssetLoading, dispatch);
    if (createAssetLoading !== null && !createAssetLoading) {
      if (createAssetError) {
        if (typeof createAssetError?.message === 'string') {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: createAssetError?.message,
            })
          );
        } else if (createAssetError.errorDetails) {
          setServerErrors(createAssetError.errorDetails);
        }
      } else {
        if (createdAsset) {
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: createdAsset.message,
            })
          );
        }
      }
    }
  }, [createAssetError, createAssetLoading]);

  useEffect(() => {
    showHideTopbarLoader(updatedAssetLoading, dispatch);
    if (updatedAssetLoading !== null && !updatedAssetLoading) {
      if (updatedAssetError) {
        if (typeof updatedAssetError?.message === 'string') {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: updatedAssetError?.message,
            })
          );
        } else if (updatedAssetError.errorDetails) {
          setServerErrors(updatedAssetError.errorDetails);
        }
      } else {
        if (updatedAsset) {
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: updatedAsset.message,
            })
          );
        }
      }
    }
  }, [updatedAssetError, updatedAssetLoading]);

  useEffect(() => {
    showHideTopbarLoader(createWorkOrderLoading, dispatch);
    if (createWorkOrderLoading !== null && !createWorkOrderLoading) {
      if (createWorkOrderError) {
        if (typeof createWorkOrderError?.message === 'string') {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: createWorkOrderError?.message,
            })
          );
        } else if (createWorkOrderError.errorDetails) {
          setServerErrors(createWorkOrderError.errorDetails);
        }
      } else {
        if (createWorkOrder) {
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: createWorkOrder.message,
            })
          );
        }
      }
      dispatch(resetCreateWorkOrderFromAsset());
    }
  }, [createWorkOrderError, createWorkOrderLoading]);

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const resetFilter = () => {
    setSearchText('');
    setFilters({ ...defaultFilters, active: null });
  };

  const onSubmit = (data) => {
    setDisableSave(true);
    const requestData = {
      equipment_type_id: data?.equipment_type?.id,
      equipment_class_id: data?.product?.value,
      label:
        data?.old_asset_name === data?.asset_name
          ? data?.label
          : data?.asset_name,
      ...(data?.category_name && { other_category_name: data?.category_name }),
      location: data?.location,
      identification_number: data?.identification_number,
      barcode: data?.qr_code,
      pressure: data?.pressure,
      ...(data?.asset_number && {
        asset_number: data.asset_number,
      }),
      ...(data?.walk_order && {
        wall_order: data.walk_order,
      }),

      make: data?.make,
      model: data?.model,

      size: data?.size,
      quantity: data?.quantity,

      base_date: data?.base_date ? formatDateAPI(data?.base_date) : null,
      installation_date: data?.installation_date
        ? formatDateAPI(data?.installation_date)
        : null,
      internal_note: data?.internal_note,

      property_uuid: propertyId,
      is_active: data?.active,
      status_id: data?.status_id?.value,
      ...(data?.category_name && {
        other_category_name: data?.category_name,
      }),
      ...(data?.product?.label && {
        product_id: productEnhancementData?.data?.[0]?.id || null,
      }),
      // standard_id: 1,
      // routine_type_id: 1,
      // frequency_id: 1,
      // form_id: 1,
      // created_type: '1',
      // description: '1',
      // TODO: Need to replace static document uuid to dynamic
      documents: [],
    };

    if (!assetId) {
      dispatch(createAsset(requestData)).finally(() => setDisableSave(false));
    } else {
      dispatch(updateAsset({ assetId: assetId, data: requestData })).finally(
        () => setDisableSave(false)
      );
    }
  };

  useEffect(() => {
    if (propertyAssetFilter) {
      setFilters(propertyAssetFilter);
      setSearchText(propertyAssetFilter?.searchText);
    }
  }, []);

  useEffect(() => {
    if (filters || searchText) {
      dispatch(setPropertyAssetFilter({ ...filters, searchText }));
    }
  }, [filters, searchText]);

  const styledButtonDispalyer = isEditable && (
    <>
      <CustomButton
        text="Add Asset"
        color="secondary"
        sx={{ height: '52%' }}
        startIcon={<AddCircleOutlineOutlinedIcon />}
        disabled={propertyDataloading}
        onClick={() => {
          setOpen(true);
          setAssetId('');
          reset(assetFormDefaultValues);
        }}
      />
      <CustomButton
        text="Export"
        color="secondary"
        sx={{ height: '52%' }}
        startIcon={<OpenInNewIcon />}
        onClick={() => {
          if (!selectedRows || selectedRows?.length === 0) {
            dispatch(
              snackbarToggle({
                isOpen: true,
                isErrorMsg: true,
                msg: t('message.common.noRecordExportMessage'),
              })
            );
            setSelectedRows(null);
          } else {
            exportToCSV(
              selectedRows,
              COLUMNS_TO_EXPORT.ASSETS,
              EXPORT_FILE_NAMES.ASSETS,
              COLUMNS_TO_MAP.ASSETS
            );
          }
        }}
      />
    </>
  );

  const noData = (
    <NewNoDataPage
      icon={<CategoryOutlinedIcon />}
      title={t('attributes.property.assets')}
      singularText={t('attributes.property.asset')}
      filterHeight={filterHeight(isFilterComponentVisible)}
      createBtnText={`${t('attributes.add')} ${t('attributes.property.asset')}`}
    />
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
            sethasFormValues(false);

            if (window.history.state === null) {
              window.history.back();
            }
          }}
        />
      )}
      {nextStepTitle?.title === PROPERTY_DETAIL_STEP.assetDetails ? (
        <AssetDetails
          setOpen={setOpen}
          setAssetId={setAssetId}
          assetId={nextStepTitle?.id}
        />
      ) : (
        <StyledMainWrapper btn={styledButtonDispalyer} isSubDetails={true}>
          {!isDataLoading && assetsList?.totalCount === 0 && noData}
          {isInitialLoading ? (
            <Box
              sx={{
                width: '100%',
                mt: 2,
                minHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
                maxHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CustomCircularLoader />
            </Box>
          ) : (
            !isInitialLoading &&
            assetsList?.totalCount > 0 && (
              <Box sx={{ padding: '16px 16px 0 16px' }}>
                <FilterSection
                  onFilterBtnClick={() =>
                    setIsFilterComponentVisible(!isFilterComponentVisible)
                  }
                  isRefresh={true}
                  searchText={searchText}
                  isActive={isFilterComponentVisible}
                  onRefreshFilter={() => setRefresh(!refresh)}
                  onResetFilter={resetFilter}
                  onSearchChange={(e) => setSearchText(e.target.value)}
                  isResetButtonVisible={
                    searchText ||
                    filters.equipmentType ||
                    filters.product ||
                    filters.status?.length > 0 ||
                    filters.active
                  }
                  sx={{ marginRight: '16px' }}
                  isFilterDisable={checkIsFiltersApplied(filters)}
                />
                {isFilterComponentVisible && (
                  <FilterComponent>
                    <Autocomplete
                      placeholder={t('attributes.property.assetGroup')}
                      options={equipmentTypesDropdownData}
                      value={filters?.equipmentType}
                      onChange={(e, newValue) => {
                        if (newValue || newValue === null) {
                          setFilters((prev) => ({
                            ...prev,
                            equipmentType: newValue,
                            assetType: null,
                            assetVariant: null,
                          }));
                        }
                      }}
                      width="190px"
                      isLoadingData={equipmentTypesList?.loading}
                    />
                    <Autocomplete
                      placeholder={t('attributes.property.assetType')}
                      options={equipmentClassDropdownData}
                      value={filters?.assetType}
                      onChange={(e, newValue) => {
                        if (newValue || newValue === null) {
                          setFilters((prev) => ({
                            ...prev,
                            assetVariant: null,
                            assetType: newValue,
                          }));
                        }
                      }}
                      width="190px"
                      isLoadingData={equipmentClassDropdownLoading}
                      disabledDropdown={!filters?.equipmentType}
                    />
                    <Autocomplete
                      placeholder={t('attributes.property.assetVariant')}
                      options={categoryLevels1DropdownData}
                      value={filters?.assetVariant}
                      onChange={(e, newValue) => {
                        if (newValue || newValue === null) {
                          setFilters((prev) => ({
                            ...prev,
                            assetVariant: newValue,
                          }));
                        }
                      }}
                      width="190px"
                      isLoadingData={categoryLevels1DropdownLoading}
                      disabledDropdown={!filters?.assetType}
                    />
                    <Autocomplete
                      multiple
                      handleMultipleFilter={true}
                      placeholder="Status"
                      options={assetStatus}
                      value={filters?.status}
                      onChange={(e, newValue) => {
                        if (newValue || newValue === null) {
                          setFilters((prev) => ({ ...prev, status: newValue }));
                        }
                      }}
                      width="190px"
                      isLoadingData={assetStatusList?.loading}
                    />
                    <Autocomplete
                      placeholder="Active"
                      options={[
                        { label: 'Yes', value: 'yes' },
                        { label: 'No', value: 'no' },
                      ]}
                      value={filters?.active}
                      onChange={(e, newValue) => {
                        if (newValue || newValue === null) {
                          setFilters((prev) => ({ ...prev, active: newValue }));
                        }
                      }}
                      width="190px"
                      isLoadingData={false}
                    />
                  </FilterComponent>
                )}
                <CustomGridTable
                  columns={columns}
                  rows={rows}
                  total={assetsList?.total}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  perPageData={perPageData}
                  setPerPageData={setPerPageData}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  columnVisibilityModel={columnVisibilityModel}
                  onRowSelectionModelChange={handleSelectionChange}
                  setColumnVisibilityModel={setColumnVisibilityModel}
                  noData={<NoRecordFound />}
                  isLoading={assetsList?.loading}
                  filterHeight={filterHeight(isFilterComponentVisible)}
                />
              </Box>
            )
          )}
        </StyledMainWrapper>
      )}
      {open && (
        <SwipeableDrawer
          open={open}
          title={!assetId ? 'Add Asset' : 'Edit Asset'}
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          footerButton={
            <CustomButton
              text={!assetId ? t('attributes.add') : t('attributes.save')}
              startIcon={!assetId ? <AddIcon /> : <SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={disableSave}
            />
          }
          width={SWIPEABLE_DRAWER_WIDTH}
        >
          <AssetForm
            id={assetId}
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            equipmentTypes={equipmentTypesDropdownData}
            assetStatusList={assetStatus}
            nextStepTitle={nextStepTitle}
            setServerErrors={setServerErrors}
            setError={setError}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default AssetsList;
