import { createSlice } from '@reduxjs/toolkit';

import {
  getCategoryLevelsDropdown,
  getEquipmentClassDropdown,
  getEquipmentTypesDropdown,
} from './api';

const equipmentTypesDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const equipmentClassDropdownList = {
  isLoading: false,
  error: false,
  data: false,
};

const categoryLevels1DropdownList = {
  isLoading: false,
  error: false,
  data: false,
};

const categoryLevels2DropdownList = {
  isLoading: false,
  error: false,
  data: false,
};

const categoryLevels3DropdownList = {
  isLoading: false,
  error: false,
  data: false,
};

const equipmentTypesSlice = createSlice({
  name: 'equipmentTypes',
  initialState: {
    equipmentTypesDropdownList,
    equipmentClassDropdownList,
    categoryLevels1DropdownList,
    categoryLevels2DropdownList,
    categoryLevels3DropdownList,
  },
  reducers: {
    resetEquipmentTypesDropdownList(state) {
      state.equipmentTypesDropdownList = { ...equipmentTypesDropdownList };
    },
    resetEquipmentClassDropdownList(state) {
      state.equipmentClassDropdownList = { ...equipmentClassDropdownList };
    },
    resetCategoryLevels1DropdownList(state) {
      state.categoryLevels1DropdownList = {
        ...categoryLevels1DropdownList,
      };
    },
    resetCategoryLevels2DropdownList(state) {
      state.categoryLevels2DropdownList = {
        ...categoryLevels2DropdownList,
      };
    },
    resetCategoryLevels3DropdownList(state) {
      state.categoryLevels3DropdownList = {
        ...categoryLevels3DropdownList,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Equipment Types
      .addCase(getEquipmentTypesDropdown.pending, (state) => {
        state.equipmentTypesDropdownList.isLoading = true;
      })
      .addCase(getEquipmentTypesDropdown.fulfilled, (state, action) => {
        state.equipmentTypesDropdownList.isLoading = false;
        state.equipmentTypesDropdownList.data = action.payload;
      })
      .addCase(getEquipmentTypesDropdown.rejected, (state, action) => {
        state.equipmentTypesDropdownList.isLoading = false;
        state.equipmentTypesDropdownList.error = action.payload;
      })
      // Equipment Class
      .addCase(getEquipmentClassDropdown.pending, (state) => {
        state.equipmentClassDropdownList.isLoading = true;
      })
      .addCase(getEquipmentClassDropdown.fulfilled, (state, action) => {
        state.equipmentClassDropdownList.isLoading = false;
        state.equipmentClassDropdownList.data = action.payload;
      })
      .addCase(getEquipmentClassDropdown.rejected, (state, action) => {
        state.equipmentClassDropdownList.isLoading = false;
        state.equipmentClassDropdownList.error = action.payload;
      })
      // Category Levels
      .addCase(getCategoryLevelsDropdown.pending, (state, action) => {
        const { current_category } = action.meta.arg;

        state.categoryLevels1DropdownList.isLoading = true;
      })
      .addCase(getCategoryLevelsDropdown.fulfilled, (state, action) => {
        const { current_category } = action.meta.arg;

        state.categoryLevels1DropdownList.isLoading = false;
        state.categoryLevels1DropdownList.data = action.payload;
      })
      .addCase(getCategoryLevelsDropdown.rejected, (state, action) => {
        const { current_category } = action.meta.arg;

        state.categoryLevels1DropdownList.isLoading = false;
        state.categoryLevels1DropdownList.error = action.payload;
      });
  },
});

export const {
  resetEquipmentTypesDropdownList,
  resetEquipmentClassDropdownList,
  resetCategoryLevels1DropdownList,
  resetCategoryLevels2DropdownList,
  resetCategoryLevels3DropdownList,
} = equipmentTypesSlice.actions;

export default equipmentTypesSlice.reducer;
