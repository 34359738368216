import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Box, Divider, Typography } from '@mui/material';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../assets/commonStyled';
import { validateAddress } from '../../constants/common';
import { Validation } from '../../constants/FieldValidationMsg';
import { EMAIL_REGEX } from '../../constants/Regex';
import { SUPER_ADMIN } from '../../constants/Roles';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getStatesHook from '../../hooks/getStatesHook';
import useAddressDetail from '../../hooks/useAddressDetail';
import useAutocompleteAddress from '../../hooks/useAutcompleteAddress';
import { getSuperAdminById } from '../../store/superAdmin/api';
import { formattedDate, loggedInUserRole } from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CreateCityModal from '../CommonComponents/CreateCityModal';
import CustomButton from '../CommonComponents/CustomButton';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';

const SuperAdminForm = ({
  id,
  clearErrors,
  reset,
  control,
  trigger,
  isGlobalAdmin,
  companylistOptions,
  watch,
  setValue,
  isCompanyNameLoading,
  setServerErrors,
  isFromCompanyForm = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading, editSuperAdmin } = useSelector(
    (state) => state.superAdmin.edit
  );

  const {
    isLoading: isAutocompleteAddressLoading,
    data: autocompleteAddressData,
  } = useSelector((state) => state.googleAPIs.autocompleteAddress);

  const selectedState = watch(isFromCompanyForm ? 'superadmin_state' : 'state');
  const typedValue = watch(
    isFromCompanyForm ? 'superadmin_searchAddress' : 'searchAddress'
  );
  const addressLine1 = watch('address');
  const addressLine2 = watch('address_line_2');
  const stateList = getStatesHook();
  const cityList = getCityByStateHook(selectedState?.value);
  const [searchAddress, setSearchAddress] = useState('');
  const [open, setOpen] = useState(false);

  useAutocompleteAddress(searchAddress);
  useAddressDetail({
    selectedAddress: typedValue,
    setValue: setValue,
    fieldName: {
      stateKey: isFromCompanyForm ? 'superadmin_state' : 'state',
      cityKey: isFromCompanyForm ? 'superadmin_city' : 'city',
      postCodeKey: isFromCompanyForm ? 'superadmin_zip_code' : 'zip_code',
      address1: 'address',
      address2: 'address_line_2',
    },
  });

  useEffect(() => {
    if (id) {
      dispatch(getSuperAdminById(id));
    }
  }, []);

  useEffect(() => {
    if (!isLoading && editSuperAdmin && id) {
      reset({
        uuid: editSuperAdmin.uuid,
        name: editSuperAdmin.name,
        cell_phone: editSuperAdmin.cell_phone,
        email: editSuperAdmin.email,
        address: editSuperAdmin.address || '',
        address_line_2: editSuperAdmin.address_line_2 || '',
        state: { label: editSuperAdmin.state, value: editSuperAdmin.state },
        city: editSuperAdmin?.city
          ? {
              label: editSuperAdmin.city,
              value: editSuperAdmin.city,
            }
          : null,
        zip_code: editSuperAdmin.zip_code,
        company_name: {
          label: editSuperAdmin.company_name,
          value: editSuperAdmin.company_uuid,
        },
        active: editSuperAdmin.active,
        created_at: formattedDate(editSuperAdmin.created),
      });
    }
  }, [isLoading, editSuperAdmin]);

  const clearHandler = (name) => setValue(name, '');

  const labels = (label) =>
    t(`label.${isGlobalAdmin ? 'globalAdmin' : 'superAdmin'}.${label}`);

  const postCodeLength = 4;

  const [address1Blur, setAddress1Blur] = useState(false);
  const [address2Blur, setAddress2Blur] = useState(false);
  const [stateBlur, setStateBlur] = useState(false);
  const [cityBlur, setCityBlur] = useState(false);
  const [postcodeBlur, setPostcodeBlur] = useState(false);
  const [addressRecheck, setAddressRecheck] = useState(false);
  const address = watch(isFromCompanyForm ? 'superadmin_address' : 'address');
  const state = watch(isFromCompanyForm ? 'superadmin_state' : 'state');
  const city = watch(isFromCompanyForm ? 'superadmin_city' : 'city');
  const postcode = watch(
    isFromCompanyForm ? 'superadmin_zip_code' : 'zip_code'
  );

  useEffect(() => {
    if (
      state &&
      (city || true) &&
      addressLine1 &&
      postcode &&
      (addressLine2 || true)
    ) {
      setAddress1Blur(true);
      setAddress2Blur(true);
      setStateBlur(true);
      setCityBlur(true);
      setPostcodeBlur(true);
      setAddressRecheck(true);
    }
  }, [state, city, addressLine1, addressLine2, postcode]);

  useEffect(() => {
    validateAddress({
      address: address,
      address1: addressLine1,
      address2: addressLine2,
      city: city,
      state: state,
      postcode: postcode,
      clearErrors,
      addressRecheck,
      fieldName: {
        addressKey: isFromCompanyForm ? 'superadmin_address' : 'address',
        addressValue: t('attributes.address'),
        stateKey: isFromCompanyForm ? 'superadmin_state' : 'state',
        stateValue: t('common.state'),
        cityKey: isFromCompanyForm ? 'superadmin_city' : 'city',
        cityValue: t('common.city'),
        postCodeKey: isFromCompanyForm ? 'superadmin_zip_code' : 'zip_code',
        postCodeValue: t('attributes.postCode'),
        address1Key: 'address',
        address1Value: 'Address Line 1',
        address2Key: 'address_line_2',
        address2Value: 'Address Line 2',
      },
      setServerErrors,
    });
  }, [address1Blur, address2Blur, stateBlur, cityBlur, postcodeBlur]);

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <>
      <FormWrapper>
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1">
              {isFromCompanyForm
                ? `${t('roles.superAdmin')} ${t('attributes.information')}`
                : `${t('attributes.contact')} ${t('attributes.information')}`}
            </Typography>
          </Box>
          <Divider />
          <FormFieldWrapper>
            <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: `${t('attributes.name')} ${Validation.general.required}`,
                  maxLength: {
                    value: 80,
                    message: Validation.general.contactName,
                  },
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    label={t('attributes.name')}
                    fullWidth
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      trigger('name');
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    onClear={() => clearHandler(name)}
                  />
                )}
              />
              <Controller
                name={
                  isFromCompanyForm ? 'superadmin_cell_phone' : 'cell_phone'
                }
                control={control}
                rules={{
                  // required: `${t('attributes.company.cell_phone')} ${Validation.general.required}`,
                  pattern: {
                    value: /^\d{0,50}$/,
                    message: Validation.general.cellPhone,
                  },
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    label={t('attributes.company.cell_phone')}
                    fullWidth
                    value={value}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/\D/g, '');

                      onChange(numericValue);
                      trigger(
                        isFromCompanyForm
                          ? 'superadmin_cell_phone'
                          : 'cell_phone'
                      );
                    }}
                    isRequired={false}
                    helperText={error ? error.message : ''}
                    error={error}
                    onClear={() => clearHandler(name)}
                  />
                )}
              />
            </Box>
            <Controller
              name={isFromCompanyForm ? 'superadmin_email' : 'email'}
              control={control}
              rules={{
                required: `${t('attributes.email')} ${Validation.general.required}`,
                maxLength: {
                  value: 254,
                  message: Validation.general.email,
                },
                pattern: {
                  value: EMAIL_REGEX,
                  message: Validation.general.validEmail,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.email')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger(isFromCompanyForm ? 'superadmin_email' : 'email');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                  disabled={editSuperAdmin && id}
                />
              )}
            />
            <Controller
              name={
                isFromCompanyForm ? 'superadmin_searchAddress' : 'searchAddress'
              }
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={t('common.searchAddress')}
                  options={autocompleteAddressData || []}
                  value={value}
                  isRequired={false}
                  isLoadingData={isAutocompleteAddressLoading}
                  onChange={(e, newValue) => {
                    if (newValue || newValue === null) {
                      onChange(newValue);
                    }
                    setSearchAddress(e.target?.value);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                />
              )}
            />
            <Controller
              name={'address'}
              control={control}
              rules={{
                required: `${t('attributes.addressLine1')} ${Validation.general.required}`,
                maxLength: {
                  value: 300,
                  message: Validation.general.address1,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.addressLine1')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('address');
                  }}
                  multiline
                  rows={2}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                  setBlur={setAddress1Blur}
                />
              )}
            />
            <Controller
              name={'address_line_2'}
              control={control}
              rules={{
                maxLength: {
                  value: 300,
                  message: Validation.general.address2,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.addressLine2')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('address_line_2');
                  }}
                  isRequired={false}
                  multiline
                  rows={2}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                  setBlur={setAddress2Blur}
                />
              )}
            />
            <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
              <Controller
                name={isFromCompanyForm ? 'superadmin_state' : 'state'}
                control={control}
                rules={{
                  required: `${t('common.state')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('common.state')}
                    options={stateList}
                    value={value}
                    onChange={(e, newValue) => {
                      if (newValue || newValue === null) {
                        onChange(newValue);
                      }
                      clearErrors(
                        isFromCompanyForm ? 'superadmin_city' : 'city'
                      );
                      setValue(
                        isFromCompanyForm ? 'superadmin_city' : 'city',
                        null
                      );
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    setBlur={setStateBlur}
                    onClear={() => clearHandler(name)}
                  />
                )}
              />
              <Controller
                name={isFromCompanyForm ? 'superadmin_city' : 'city'}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('common.city')}
                    options={cityList}
                    value={value}
                    onChange={(e, newValue) => {
                      if (newValue || newValue === null) {
                        onChange(newValue);
                      }
                    }}
                    isRequired={false}
                    helperText={error ? error.message : ''}
                    error={error}
                    disabledDropdown={!selectedState}
                    setBlur={setCityBlur}
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '-16px 0px',
              }}
            >
              <CustomButton
                startIcon={<AddCircleOutlineOutlinedIcon />}
                text={t('attributes.add')}
                style={{ textDecoration: 'underline' }}
                variant="text"
                onClick={() => setOpen(true)}
              />
            </Box>
            <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
              <Controller
                name={isFromCompanyForm ? 'superadmin_zip_code' : 'zip_code'}
                control={control}
                rules={{
                  required: `${t('attributes.postCode')} ${Validation.general.required}`,
                  validate: (value) => {
                    if (value && !/^\d+$/.test(value)) {
                      return Validation.general.numericValue;
                    }

                    return true;
                  },
                  minLength: {
                    value: postCodeLength,
                    message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                  },
                  maxLength: {
                    value: postCodeLength,
                    message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                  },
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    label={t('attributes.postCode')}
                    fullWidth
                    value={value}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/\D/g, '');

                      onChange(numericValue);
                      trigger(
                        isFromCompanyForm ? 'superadmin_zip_code' : 'zip_code'
                      );
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    onClear={() => clearHandler(name)}
                    setBlur={setPostcodeBlur}
                  />
                )}
              />
            </Box>
          </FormFieldWrapper>
        </CustomCard>
        {isGlobalAdmin ? (
          <CustomCard>
            <Box sx={{ padding: '16px' }}>
              <Typography variant="body1">{`${t('attributes.company.company')} ${t('attributes.company.details')}`}</Typography>
            </Box>
            <Divider />
            <FormFieldWrapper>
              <Controller
                name="company_name"
                control={control}
                rules={{
                  required: `${t('attributes.company.company')} ${t('attributes.name')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={`${t('attributes.company.company')} ${t('attributes.name')}`}
                    options={companylistOptions}
                    value={value}
                    onChange={(e, newValue) => {
                      if (newValue || newValue === null) {
                        onChange(newValue);
                      }
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    isLoadingData={isCompanyNameLoading}
                    disabledDropdown={id}
                  />
                )}
              />
            </FormFieldWrapper>
          </CustomCard>
        ) : (
          // Keep the company_name in the form state even when not displayed
          <input
            type="hidden"
            {...control.register('company_name')}
            value={JSON.stringify(watch('company_name'))}
          />
        )}
        {id && (
          <CustomCard>
            <Box sx={{ px: '16px' }}>
              <CommonCheckbox
                control={control}
                name="active"
                label={t('common.active')}
                isRequired={false}
                showMessage={true} // Enable message display
                messageOnChecked={t(
                  'label.superAdmin.superAdminActiveCheckbox'
                )}
                messageOnUnchecked={t(
                  'label.superAdmin.superAdminInactiveCheckbox'
                )}
              />
            </Box>
          </CustomCard>
        )}
      </FormWrapper>
      {loggedInUserRole() === SUPER_ADMIN && (
        <CreateCityModal
          open={open}
          setOpen={setOpen}
          state={watch('state')}
          onConfirm={() => {}}
          buttonDisabled={() => {}}
          setServerErrors={setServerErrors}
        />
      )}
    </>
  );
};

export default SuperAdminForm;
