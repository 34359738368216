import { useEffect, useRef } from 'react';

const useDidMount = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      func(); // Runs on updates (from second render onward)
    } else {
      didMount.current = true; // Skips first render
    }
  }, deps);
};

export default useDidMount;
