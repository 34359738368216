import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Navigate,
  Outlet,
  createBrowserRouter,
  useNavigate,
} from 'react-router-dom';

// Internal
import { AboutApp } from './components/AboutApp';
import Billing from './components/Billing/Billing';
import ViewBillingCard from './components/Billing/ViewBillingCard';
import ComingSoon from './components/CommonComponents/ComingSoon';
import { DataManagement } from './components/CommonComponents/Reports/DataManagement';
import { ExportData } from './components/CommonComponents/Reports/ExportData';
import { GlobalAdminExportData } from './components/CommonComponents/Reports/GlobalAdmin/ExportData';
import { GlobalAdminImportData } from './components/CommonComponents/Reports/GlobalAdmin/ImportData';
import { RefernceData } from './components/CommonComponents/Reports/GlobalAdmin/ReferenceData';
import { TBC } from './components/CommonComponents/Reports/GlobalAdmin/TBC';
import ImportData from './components/CommonComponents/Reports/ImportData';
import { Templates } from './components/CommonComponents/Reports/Templates';
import Companies from './components/Company/Companies';
import AccessProcedureConfiguration from './components/Configuration/AccessProcedure/AccessProcedureConfiguration';
import AccessScheduleConfiguration from './components/Configuration/AccessSchedule/AccessScheduleConfiguration';
import AssetVariants from './components/Configuration/AssetVariants/AssetVariants';
import BuildingTypeConfiguration from './components/Configuration/BuildingType/BuildingTypeConfiguration';
import CityConfiguration from './components/Configuration/City/CityConfiguration';
import Configuration from './components/Configuration/Configuration';
import SiteRequirementConfiguration from './components/Configuration/SiteRequirement/SiteRequirementConfiguration';
import TaxConfiguration from './components/Configuration/Tax/TaxConfiguration';
import ZoneConfiguration from './components/Configuration/Zone/ZoneConfiguration';
import Contacts from './components/Contacts/Contacts';
import ViewContactCard from './components/Contacts/ViewContactCard';
import ControlPanel from './components/ControlPanel/ControlPanel';
import { GlobalAdminLanguages } from './components/ControlPanel/GlobalAdmin/Languages';
import { GlobalAdminRoutineService } from './components/ControlPanel/GlobalAdmin/RoutineService';
import { GlobalAdminSecurityGroups } from './components/ControlPanel/GlobalAdmin/SecurityGroups';
import { GlobalAdminStandards } from './components/ControlPanel/GlobalAdmin/Standards';
import { GlobalAdminTemplates } from './components/ControlPanel/GlobalAdmin/Templates';
import { GlobalAdminTimezone } from './components/ControlPanel/GlobalAdmin/Timezone';
import CustomerPropertyDashboard from './components/Dashboard/CustomerPropertyDashboard';
import Dashboard from './components/Dashboard/Dashboard';
import { DeepLinking } from './components/DeepLinking';
import DefectDashboard from './components/DefectDashboard/DefectDashboard';
import DefectDashboardLayout from './components/DefectDashboard/DefectDashboardLayout';
import DefectList from './components/DefectDashboard/DefectList';
import HelpAndSupport from './components/HelpAndSupport/HelpAndSupport';
import BusinessCentralIntegration from './components/Integrations/BusinessCentralIntegration';
import { GlobalAdminBusinessCentral } from './components/Integrations/GlobalAdmin/BusinessCentral';
import { GlobalAdminGoogleIndoorMaps } from './components/Integrations/GlobalAdmin/GoogleIndoorMaps';
import { GlobalAdminGoogleMaps } from './components/Integrations/GlobalAdmin/GoogleMaps';
import { GlobalAdminSSO } from './components/Integrations/GlobalAdmin/SSO';
import { GlobalAdminXeroIntegration } from './components/Integrations/GlobalAdmin/XeroIntegrations';
import Integrations from './components/Integrations/Integrations';
import XeroIntegrations from './components/Integrations/XeroIntegrations';
import InvoiceDashboard from './components/InvoiceDashboard/InvoiceDashboard';
import InvoiceDashboardLayout from './components/InvoiceDashboard/InvoiceDashboardLayout';
import InvoiceList from './components/InvoiceDashboard/InvoiceList';
import LandingPage from './components/LandingPage';
import AppErrorPage from './components/NotFound/AppErrorPage';
import PageNotFound from './components/NotFound/PageNotFound';
import UnAuthorized from './components/NotFound/UnAuthorized';
import UnderMaintenance from './components/NotFound/UnderMaintenance';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import ProductCatalogue from './components/ProductCatalogue/ProductCatalogue';
import Profile from './components/Profile/Profile';
import AssetsList from './components/Properties/AssetsList';
import FloorPlan from './components/Properties/FloorPlan';
import Properties from './components/Properties/Properties';
import PropertyDetails from './components/Properties/PropertyDetails';
import RoutineList from './components/Properties/RoutineList';
import ServiceQuotes from './components/Properties/ServiceQuotes/ServiceQuotes';
import ServiceQuotesEdit from './components/Properties/ServiceQuotes/ServiceQuotesEdit';
import QuotesDashboard from './components/QuotesDashboard/QuotesDashboard';
import QuotesDashboardLayout from './components/QuotesDashboard/QuotesDashboardLayout';
import QuotesList from './components/QuotesDashboard/QuotesList';
import ActivityReport from './components/Reporting/ActivityReport';
import Reporting from './components/Reporting/Reporting';
import TechnicianPerformance from './components/Reporting/TechnicianPerformance';
import TechnicianPerformanceCharts from './components/Reporting/TechnicianPerformanceCharts';
import TechnicianPerformanceLayout from './components/Reporting/TechnicianPerformanceLayout';
import RoutineServiceFrequencies from './components/RoutineServiceFrequencies/RoutineServiceFrequencies';
import ScheduleBoard from './components/ScheduleBoard/ScheduleBoard';
import Layout from './components/Shared/Layout';
import SuperAdminDefectsList from './components/SuperAdmin/DefectList';
import DefectQuotesEdit from './components/SuperAdmin/DefectQuotesEdit';
import SuperAdmins from './components/SuperAdmin/SuperAdmin';
import SuperAdminDefectsQoutes from './components/SuperAdmin/SuperAdminDefectsQoutes';
import SuperAdminTemplates from './components/SuperAdmin/SuperAdminTemplates';
import Suppliers from './components/Suppliers/Suppliers';
import OraAiChatbot from './components/Support/OraAiChatbot';
import Support from './components/Support/Support';
import EmailTemplateWrapper from './components/Templates/EmailTemplateWrapper';
import InvoicesTemplate from './components/Templates/Invoices';
import PurchaseOrdersTemplate from './components/Templates/PurchaseOrder';
import QuotesTemplate from './components/Templates/Quotes';
import { TermsService } from './components/TermsService';
import Timesheet from './components/Timesheet/Timesheet';
import BillingContractForm from './components/Users/BillingContracts/BillingContractForm';
import BillingContract from './components/Users/BillingContracts/BillingContracts';
import Customer from './components/Users/Customer/Customer';
import OfficeStaff from './components/Users/OfficeStaff/OfficeStaff';
import StaffManagement from './components/Users/StaffManagement/StaffManagement';
import Technician from './components/Users/Technician/Technician';
import { UsersManagement } from './components/UsersManagement/UsersManagement';
import InvoiceDetail from './components/WorkOrder/Invoices/InvoiceDetail';
import Invoices from './components/WorkOrder/Invoices/Invoices';
import PurchaseOrderDetail from './components/WorkOrder/PurchaseOrders/PurchaseOrderDetail';
import PurchaseOrders from './components/WorkOrder/PurchaseOrders/PurchaseOrders';
import WorkOrder from './components/WorkOrder/WorkOrder';
import WorkOrderDetails from './components/WorkOrder/WorkOrderDetails';
import { ROUTE_NAVIGATE_CONST } from './constants/Constants';
import { CUSTOMER } from './constants/Roles';
import { AUTH_ROUTES, ROUTENAME } from './constants/RoutesConstants';
import AuthLayout from './hoc/AuthLayout';
import AuthRoute from './hoc/AuthRoute';
import UnAuthLayout from './hoc/UnAuthLayout';
import { getRedirectURL, loggedInUserRole } from './utils';

const ErrorBoundaryLayout = () => {
  const navigate = useNavigate();

  // Use sessionStorage to persist offline state across page reloads
  const [isOffline, setIsOffline] = useState(navigator.onLine ? false : true);

  useEffect(() => {
    const handleOffline = () => {
      sessionStorage.setItem('wasOffline', 'true'); // Store offline state
      setIsOffline(true);
      navigate(ROUTENAME.OFFLINE);
    };

    const handleOnline = () => {
      sessionStorage.removeItem('wasOffline'); // Remove offline state
      setIsOffline(false);

      if (loggedInUserRole() === CUSTOMER) {
        navigate(getRedirectURL()[CUSTOMER][ROUTE_NAVIGATE_CONST.PROPERTIES]);
      } else {
        window.history.back();
      }
    };

    // Check if the user was offline before reload
    if (sessionStorage.getItem('wasOffline')) {
      setIsOffline(true);
    }

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, [navigate]);

  return (
    <ErrorBoundary
      FallbackComponent={UnderMaintenance}
      onReset={() => {
        window.location.reload();
      }}
    >
      <Outlet context={{ isOffline }} />
    </ErrorBoundary>
  );
};

export const routes = createBrowserRouter([
  {
    element: <ErrorBoundaryLayout />,
    children: [
      // Public route for the landing page
      {
        path: ROUTENAME.DEFAULT_ROUTE, // e.g., '/'
        element: (
          <UnAuthLayout>
            <LandingPage />
          </UnAuthLayout>
        ),
      },
      {
        path: ROUTENAME.DEEP_LINKING_ROUTE,
        element: <DeepLinking />,
      },
      {
        path: ROUTENAME.PRIVACY_POLICY,
        element: <PrivacyPolicy />,
      },
      {
        path: ROUTENAME.TERMS_SERVICE,
        element: <TermsService />,
      },
      {
        path: ROUTENAME.ABOUT_APP,
        element: <AboutApp />,
      },
      // Global Admin Part
      {
        path: ROUTENAME.DEFAULT_ROUTE,
        element: (
          <AuthLayout>
            <Layout />
          </AuthLayout>
        ),
        children: [
          {
            path: ROUTENAME.DASHBOARD,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_DASHBOARD}>
                    {/* Global Admin & Super Admin */}
                    <Dashboard />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.HELP_AND_SUPPORT,
            element: (
              <AuthRoute type={AUTH_ROUTES.HELP_AND_SUPPORT}>
                <HelpAndSupport />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.SYSTEM_SETTING,
            element: (
              <AuthRoute type={AUTH_ROUTES.SYSTEM_SETTING}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.BELL_NOTIFICATION,
            element: (
              <AuthRoute type={AUTH_ROUTES.BELL_NOTIFICATION}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.FIRE_COMPANY,
            children: [
              {
                index: true,
                element: <Navigate to={ROUTENAME.COMPANIES} />,
              },
              {
                path: ROUTENAME.COMPANIES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.FIRE_COMPANIES}>
                    <Companies />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.SUPER_ADMINS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMINS}>
                    <SuperAdmins />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.DATA_MANAGEMENT,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.DATA_MANAGEMENT}>
                    <DataManagement />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.REFERENCE_DATA,
                element: (
                  <AuthRoute type={AUTH_ROUTES.REFERENCE_DATA}>
                    <RefernceData />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.IMPORT_DATA,
                element: (
                  <AuthRoute type={AUTH_ROUTES.IMPORT_DATA}>
                    <GlobalAdminImportData />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.EXPORT_DATA,
                element: (
                  <AuthRoute type={AUTH_ROUTES.EXPORT_DATA}>
                    <GlobalAdminExportData />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.TBC_DATA,
                element: (
                  <AuthRoute type={AUTH_ROUTES.TBC_DATA}>
                    <TBC />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.GLOBAL_ADMIN_INTEGRATIONS,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_INTEGRATIONS}>
                    <Integrations />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.GLOBAL_ADMIN_XERO_INTEGRATIONS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_XERO_INTEGRATIONS}>
                    <GlobalAdminXeroIntegration />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.GLOBAL_ADMIN_BUSINESS_CENTRAL,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_BUSINESS_CENTRAL}>
                    <GlobalAdminBusinessCentral />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.GLOBAL_ADMIN_GOOGLE_MAPS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_GOOGLE_MAPS}>
                    <GlobalAdminGoogleMaps />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.GLOBAL_ADMIN_GOOGLE_INDOOR_MAPS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_GOOGLE_INDOOR_MAPS}>
                    <GlobalAdminGoogleIndoorMaps />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.GLOBAL_ADMIN_SSO,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_SSO}>
                    <GlobalAdminSSO />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.MONITORING_ALERT,
            element: (
              <AuthRoute type={AUTH_ROUTES.MONITORING_ALERT}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.PROPERTIES,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_PROPERTIES}>
                <Properties />
              </AuthRoute>
            ),

            children: [
              {
                path: ROUTENAME.PROPERTIES_DETAILS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_PROPERTIES_DETAILS}>
                    <PropertyDetails />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.USERS,
            children: [
              {
                path: ROUTENAME.GLOBAL_ADMIN,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMINS}>
                    <ComingSoon />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.CONTROL_PANEL,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_CONTROL_PANEL}>
                    <ControlPanel />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.GLOBAL_ADMIN_TEMPLATES,
                children: [
                  {
                    index: true,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_TEMPLATES}>
                        <GlobalAdminTemplates />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.GLOBAL_ADMIN_INVOICE_TEMPLATES,
                    element: (
                      <AuthRoute
                        type={AUTH_ROUTES.GLOBAL_ADMIN_INVOICE_TEMPLATES}
                      >
                        <InvoicesTemplate />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.GLOBAL_ADMIN_QUOTES_TEMPLATES,
                    element: (
                      <AuthRoute
                        type={AUTH_ROUTES.GLOBAL_ADMIN_QUOTES_TEMPLATES}
                      >
                        <QuotesTemplate />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.GLOBAL_ADMIN_PURCHASE_ORDER_TEMPLATES,
                    element: (
                      <AuthRoute
                        type={AUTH_ROUTES.GLOBAL_ADMIN_PURCHASE_ORDER_TEMPLATES}
                      >
                        <PurchaseOrdersTemplate />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.GLOBAL_ADMIN_EMAIL_TEMPLATES,
                    element: (
                      <AuthRoute
                        type={AUTH_ROUTES.GLOBAL_ADMIN_EMAIL_TEMPLATES}
                      >
                        <EmailTemplateWrapper />
                      </AuthRoute>
                    ),
                  },
                ],
              },
              {
                path: ROUTENAME.GLOBAL_ADMIN_ROUTINE_SERVICES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_ROUTINE_SERVICES}>
                    <GlobalAdminRoutineService />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.GLOBAL_ADMIN_STANDARDS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_STANDARDS}>
                    <GlobalAdminStandards />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.GLOBAL_ADMIN_LANGUAGES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_LANGUAGES}>
                    <GlobalAdminLanguages />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.GLOBAL_ADMIN_TIMEZONE,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_TIMEZONE}>
                    <GlobalAdminTimezone />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.GLOBAL_ADMIN_SECURITY_GROUPS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_SECURITY_GROUPS}>
                    <GlobalAdminSecurityGroups />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.FORMS,
            children: [
              {
                index: true,
                element: <Navigate to={ROUTENAME.ROUTINE_SERVICES} />,
              },
              {
                path: ROUTENAME.ROUTINE_SERVICES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_ROUTINE_SERVICES}>
                    {/* Add dependencies in package.json for survey-core */}
                    {/* <Forms />  */}
                    <ComingSoon />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.ASSETS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_ASSETS}>
                    <ComingSoon />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.INVOICES,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_INVOICES}>
                {/* Global Admin */}
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.MAKE_REQUEST,
            element: (
              <AuthRoute type={AUTH_ROUTES.MAKE_A_REQUEST}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.ASSETS,
            element: (
              <AuthRoute type={AUTH_ROUTES.CUSTOMER_ASSETS}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.ROUTINE_ACTIVITIES,
            element: (
              <AuthRoute type={AUTH_ROUTES.ROUTINE_ACTIVITIES}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.REACTIVE_TASK,
            element: (
              <AuthRoute type={AUTH_ROUTES.REACTIVE_TASK}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.DOCUMENTS,
            element: (
              <AuthRoute type={AUTH_ROUTES.DOCUMENTS}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.SETTINGS,
            children: [
              {
                path: ROUTENAME.TEMPLATES,
                children: [
                  {
                    index: true,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.TEMPLATES}>
                        <SuperAdminTemplates />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.SUPER_ADMIN_INVOICE_TEMPLATES,
                    element: (
                      <AuthRoute
                        type={AUTH_ROUTES.SUPER_ADMIN_INVOICE_TEMPLATES}
                      >
                        <InvoicesTemplate />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.SUPER_ADMIN_QUOTES_TEMPLATES,
                    element: (
                      <AuthRoute
                        type={AUTH_ROUTES.SUPER_ADMIN_QUOTES_TEMPLATES}
                      >
                        <QuotesTemplate />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.SUPER_ADMIN_PURCHASE_ORDER_TEMPLATES,
                    element: (
                      <AuthRoute
                        type={AUTH_ROUTES.SUPER_ADMIN_PURCHASE_ORDER_TEMPLATES}
                      >
                        <PurchaseOrdersTemplate />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.SUPER_ADMIN_EMAIL_TEMPLATES,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_EMAIL_TEMPLATES}>
                        <EmailTemplateWrapper />
                      </AuthRoute>
                    ),
                  },
                ],
              },
              {
                path: ROUTENAME.FORMS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.FORMS}>
                    {/* Product Catalogue */}
                    <ComingSoon />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.ROUTINE_SERVICE_FREQUENCIES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.ROUTINE_SERVICE_FREQUENCIES}>
                    <RoutineServiceFrequencies />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.INTEGRATIONS,
                children: [
                  {
                    index: true,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.INTEGRATIONS}>
                        <Integrations />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.XERO_INTEGRATION,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.XERO_INTEGRATION}>
                        <XeroIntegrations />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.BC_INTEGRATION,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.BC_INTEGRATION}>
                        <BusinessCentralIntegration />
                      </AuthRoute>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: ROUTENAME.PROFILE,
            element: (
              <AuthRoute type={AUTH_ROUTES.PROFILE}>
                <Profile />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.COMPANY_PROFILE,
            element: (
              <AuthRoute type={AUTH_ROUTES.COMPANY_PROFILE}>
                <Profile />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.USERS_MANAGEMENT,
            element: (
              <AuthRoute type={AUTH_ROUTES.USERS_MANAGEMENT}>
                <UsersManagement />
              </AuthRoute>
            ),

            children: [
              {
                path: ROUTENAME.PROFILE_DETAIL,
                element: (
                  <AuthRoute type={AUTH_ROUTES.PROFILE_DETAIL_VIEW}>
                    <Profile />
                  </AuthRoute>
                ),
              },
            ],
          },
        ],
      },
      // Protected routes under AuthLayout
      {
        path: ROUTENAME.DEFAULT_ROUTE_COMPANY,
        element: (
          <AuthLayout>
            <Layout />
          </AuthLayout>
        ),
        children: [
          {
            path: ROUTENAME.DASHBOARD,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.ADMIN_DASHBOARD}>
                    {/* Global Admin & Super Admin */}
                    <Dashboard />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.PROPERTIES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.CUSTOMER_DASHBOARD_PROPERTIES}>
                    <CustomerPropertyDashboard />
                  </AuthRoute>
                ),
                children: [
                  {
                    path: ROUTENAME.PROPERTIES_DETAILS,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.CUSTOMER_PROPERTIES_DETAIL}>
                        <PropertyDetails />
                      </AuthRoute>
                    ),
                    children: [
                      {
                        path: ROUTENAME.ASSETS,
                        element: (
                          <AuthRoute
                            type={AUTH_ROUTES.CUSTOMER_PROPERTIES_ASSETS}
                          >
                            <AssetsList />
                          </AuthRoute>
                        ),
                        children: [
                          {
                            path: ROUTENAME.SUPER_ADMIN_PROPERTIES_ASSETS_DETAIL,
                            element: (
                              <AuthRoute
                                type={
                                  AUTH_ROUTES.CUSTOMER_PROPERTIES_ASSETS_DETAIL
                                }
                              >
                                <AssetsList />
                              </AuthRoute>
                            ),
                          },
                        ],
                      },
                      {
                        path: ROUTENAME.SUPER_ADMIN_FLOOR_PLAN,
                        element: (
                          <AuthRoute
                            type={AUTH_ROUTES.CUSTOMER_PROPERTIES_FLOOR_PLAN}
                          >
                            <FloorPlan />
                          </AuthRoute>
                        ),
                      },
                      {
                        path: ROUTENAME.SUPER_ADMIN_PROPERTIES_COMING_SOON,
                        element: (
                          <AuthRoute
                            type={AUTH_ROUTES.CUSTOMER_PROPERTIES_COMING_SOON}
                          >
                            <ComingSoon height="80vh" />
                          </AuthRoute>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: ROUTENAME.QUOTES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.CUSTOMER_QUOTES}>
                    {/* Customer Quotes Dashboard */}
                    <QuotesDashboardLayout />
                  </AuthRoute>
                ),
                children: [
                  {
                    index: true,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.CUSTOMER_QUOTES}>
                        <QuotesDashboard />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.QUOTES_STATUS,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.CUSTOMER_QUOTES_STATUS}>
                        <QuotesList />
                      </AuthRoute>
                    ),
                  },
                ],
              },
              {
                path: ROUTENAME.INVOICES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.CUSTOMER_INVOICES}>
                    {/* Customer */}
                    <InvoiceDashboardLayout />
                  </AuthRoute>
                ),
                children: [
                  {
                    index: true,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.CUSTOMER_INVOICES}>
                        <InvoiceDashboard />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.INVOICES_STATUS,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.CUSTOMER_INVOICE_STATUS}>
                        <InvoiceList />
                      </AuthRoute>
                    ),
                  },
                ],
              },
              {
                path: ROUTENAME.DEFECTS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.DEFECTS}>
                    {/* Customer */}
                    <DefectDashboardLayout />
                  </AuthRoute>
                ),
                children: [
                  {
                    index: true,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.DEFECTS}>
                        {/* Customer */}
                        <DefectDashboard />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.DEFECT_STATUS,
                    element: (
                      <AuthRoute
                        type={AUTH_ROUTES.CUSTOMER_QUOTES_DEFECTS_STATUS}
                      >
                        {/* Customer */}
                        <DefectList />
                      </AuthRoute>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: ROUTENAME.HELP_AND_SUPPORT,
            element: (
              <AuthRoute type={AUTH_ROUTES.HELP_AND_SUPPORT}>
                <HelpAndSupport />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.SYSTEM_SETTING,
            element: (
              <AuthRoute type={AUTH_ROUTES.SYSTEM_SETTING}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.BELL_NOTIFICATION,
            element: (
              <AuthRoute type={AUTH_ROUTES.BELL_NOTIFICATION}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.SUPPORT,
            children: [
              {
                index: true,
                path: ROUTENAME.ORA_AI,
                element: (
                  <AuthRoute type={AUTH_ROUTES.ORA_AI}>
                    <OraAiChatbot />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.VOICE_AI,
                element: (
                  <AuthRoute type={AUTH_ROUTES.VOICE_AI}>
                    <Support />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.DATA_MANAGEMENT,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.DATA_MANAGEMENT}>
                    <DataManagement />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.REFERENCE_DATA,
                element: (
                  <AuthRoute type={AUTH_ROUTES.REFERENCE_DATA}>
                    <RefernceData />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.IMPORT_DATA,
                element: (
                  <AuthRoute type={AUTH_ROUTES.IMPORT_DATA}>
                    <GlobalAdminImportData />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.EXPORT_DATA,
                element: (
                  <AuthRoute type={AUTH_ROUTES.EXPORT_DATA}>
                    <GlobalAdminExportData />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.TBC_DATA,
                element: (
                  <AuthRoute type={AUTH_ROUTES.TBC_DATA}>
                    <TBC />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.MONITORING_ALERT,
            element: (
              <AuthRoute type={AUTH_ROUTES.MONITORING_ALERT}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.SCHEDULING,
            children: [
              {
                index: true,
                element: <Navigate to={ROUTENAME.SCHEDULE_BOARD} />,
              },
              // {
              //   path: ROUTENAME.ROUTINE_SERVICES,
              //   element: (
              //     <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_ROUTINE_SERVICES}>
              //       <ComingSoon />
              //     </AuthRoute>
              //   ),
              // },
              // {
              //   path: ROUTENAME.SERVICE_REQUESTS,
              //   element: (
              //     <AuthRoute type={AUTH_ROUTES.SERVICE_REQUESTS}>
              //       <ComingSoon />
              //     </AuthRoute>
              //   ),
              // },
              {
                path: ROUTENAME.SCHEDULE_BOARD,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SCHEDULE_BOARD}>
                    <ScheduleBoard />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.WORK_ORDERS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.WORK_ORDERS}>
                    <WorkOrder />
                  </AuthRoute>
                ),

                children: [
                  {
                    path: ROUTENAME.WORK_ORDERS_VIEW,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.WORK_ORDERS_VIEW}>
                        <WorkOrderDetails />
                      </AuthRoute>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: ROUTENAME.CUSTOMERS,
            children: [
              {
                index: true,
                element: <Navigate to={ROUTENAME.CUSTOMER} />,
              },
              {
                path: ROUTENAME.CUSTOMER,
                element: (
                  <AuthRoute type={AUTH_ROUTES.NAME}>
                    <Customer />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.PROPERTIES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_PROPERTIES}>
                    <Properties />
                  </AuthRoute>
                ),
                children: [
                  {
                    path: ROUTENAME.PROPERTIES_DETAILS,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.PROPERTIES_DETAILS}>
                        <PropertyDetails />
                      </AuthRoute>
                    ),
                    children: [
                      {
                        path: ROUTENAME.ASSETS,
                        element: (
                          <AuthRoute
                            type={AUTH_ROUTES.SUPER_ADMINS_PROPERTIES_ASSETS}
                          >
                            <AssetsList />
                          </AuthRoute>
                        ),
                        children: [
                          {
                            path: ROUTENAME.SUPER_ADMIN_PROPERTIES_ASSETS_DETAIL,
                            element: (
                              <AuthRoute
                                type={
                                  AUTH_ROUTES.SUPER_ADMIN_PROPERTIES_ASSETS_DETAIL
                                }
                              >
                                <AssetsList />
                              </AuthRoute>
                            ),
                          },
                        ],
                      },
                      {
                        path: ROUTENAME.SUPER_ADMIN_FLOOR_PLAN,
                        element: (
                          <AuthRoute
                            type={AUTH_ROUTES.SUPER_ADMIN_PROPERTIES_FLOOR_PLAN}
                          >
                            <FloorPlan />
                          </AuthRoute>
                        ),
                      },
                      {
                        path: ROUTENAME.SUPER_ADMIN_PROPERTIES_ROUTINE,
                        element: (
                          <AuthRoute
                            type={AUTH_ROUTES.SUPER_ADMIN_PROPERTIES_ROUTINE}
                          >
                            <RoutineList />
                          </AuthRoute>
                        ),
                      },
                      {
                        path: ROUTENAME.SUPER_ADMIN_PROPERTIES_CONTRACT,
                        element: (
                          <AuthRoute
                            type={AUTH_ROUTES.SUPER_ADMIN_PROPERTIES_CONTRACT}
                          >
                            <BillingContract />
                          </AuthRoute>
                        ),
                      },
                      {
                        path: ROUTENAME.SUPER_ADMIN_PROPERTIES_SERVICE_QUOTE,
                        element: (
                          <AuthRoute
                            type={
                              AUTH_ROUTES.SUPER_ADMIN_PROPERTIES_SERVICE_QUOTE
                            }
                          >
                            <ServiceQuotes />
                          </AuthRoute>
                        ),
                      },
                      {
                        path: ROUTENAME.SUPER_ADMIN_PROPERTIES_INVOICES,
                        element: (
                          <AuthRoute
                            type={AUTH_ROUTES.SUPER_ADMIN_PROPERTIES_INVOICES}
                          >
                            <Invoices
                              isFromWorkOrder={false}
                              isFromSideBar={false}
                            />
                          </AuthRoute>
                        ),
                      },
                      {
                        path: ROUTENAME.SUPER_ADMIN_PROPERTIES_COMING_SOON,
                        element: (
                          <AuthRoute
                            type={
                              AUTH_ROUTES.SUPER_ADMIN_PROPERTIES_COMING_SOON
                            }
                          >
                            <ComingSoon height="80vh" />
                          </AuthRoute>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: ROUTENAME.BILLING_CONTRACTS,
                exact: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.BILLING_CONTRACTS}>
                    <BillingContract />
                  </AuthRoute>
                ),
                children: [
                  {
                    path: ROUTENAME.BILLING_CONTRACTS_EDIT,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.BILLING_CONTRACTS_EDIT}>
                        <BillingContractForm />
                      </AuthRoute>
                    ),
                  },
                ],
              },
              {
                path: ROUTENAME.BILLING_INFORMATION,
                exact: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_BILLING_INFO}>
                    <Billing />
                  </AuthRoute>
                ),
                children: [
                  {
                    path: ROUTENAME.BILLING_DETAIL,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_BILLING_VIEW}>
                        <ViewBillingCard />
                      </AuthRoute>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: ROUTENAME.USERS,
            children: [
              {
                path: ROUTENAME.SUPER_ADMIN,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN}>
                    <SuperAdmins />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.OFFICE_STAFF,
                element: (
                  <AuthRoute type={AUTH_ROUTES.OFFICE_STAFFS}>
                    <OfficeStaff />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.STAFF_MANAGEMENT,
                element: (
                  <AuthRoute type={AUTH_ROUTES.STAFF_MANAGEMENT}>
                    <StaffManagement />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.TECHNICIAN,
                element: (
                  <AuthRoute type={AUTH_ROUTES.TECHNICIANS}>
                    <Technician />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.MASTER,
            children: [
              {
                path: ROUTENAME.PRODUCT_CATALOGUE,
                element: (
                  <AuthRoute type={AUTH_ROUTES.PRODUCT_CATALOGUE}>
                    {/* Product Catalogue */}
                    <ProductCatalogue />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.SUPPLIERS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPPLIERS}>
                    {/* Supplier */}
                    <Suppliers />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.CONTACTS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_CONTACTS}>
                    <Contacts />
                  </AuthRoute>
                ),
                children: [
                  {
                    path: ROUTENAME.CONTACT_DETAIL,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_CONTACT_DETAIL}>
                        <ViewContactCard />
                      </AuthRoute>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: ROUTENAME.COMMERCIAL,
            children: [
              {
                index: true,
                element: <Navigate to={ROUTENAME.DEFECTS_QUOTES} />,
              },
              // Super Admin
              {
                path: ROUTENAME.DEFECTS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_DEFECTS}>
                    <SuperAdminDefectsList />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.DEFECTS_QUOTES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.DEFECTS_QUOTES}>
                    <SuperAdminDefectsQoutes />
                  </AuthRoute>
                ),
                children: [
                  {
                    path: ROUTENAME.DEFECT_QUOTES_VIEW,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.DEFECT_QUOTES_VIEW}>
                        <DefectQuotesEdit />
                      </AuthRoute>
                    ),
                  },
                ],
              },
              {
                path: ROUTENAME.QUOTES_SERVICES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.QUOTES_SERVICES}>
                    <ServiceQuotes isFromSideBar={true} />
                  </AuthRoute>
                ),
                children: [
                  {
                    path: ROUTENAME.QUOTES_SERVICES_VIEW,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.QUOTES_SERVICES_VIEW}>
                        <ServiceQuotesEdit isFromServiceQuotes={true} />
                      </AuthRoute>
                    ),
                  },
                ],
              },
              {
                path: ROUTENAME.PURCHASE_ORDER,
                element: (
                  <AuthRoute
                    type={AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL_PURCHASE_ORDER}
                  >
                    <PurchaseOrders isFromSideBar={true} />
                  </AuthRoute>
                ),

                children: [
                  {
                    path: ROUTENAME.PURCHASE_ORDER_EDIT,
                    element: (
                      <AuthRoute
                        type={
                          AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL_PURCHASE_ORDER_EDIT
                        }
                      >
                        <PurchaseOrderDetail isFromSideBar={true} />
                      </AuthRoute>
                    ),
                  },
                ],
              },
              {
                path: ROUTENAME.INVOICES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL_INVOICES}>
                    <Invoices isFromSideBar={true} isFromWorkOrder={false} />
                  </AuthRoute>
                ),
                children: [
                  {
                    path: ROUTENAME.INVOICE_DETAIL,
                    element: (
                      <AuthRoute
                        type={
                          AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL_INVOICE_ORDER_EDIT
                        }
                      >
                        <InvoiceDetail
                          isFromSideBar={true}
                          isFromWorkOrder={false}
                        />
                      </AuthRoute>
                    ),
                  },
                ],
              },
              {
                path: ROUTENAME.TIMESHEET,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_TIMESHEET}>
                    <Timesheet />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.WAREHOUSE,
            children: [
              {
                index: true,
                element: <Navigate to={ROUTENAME.PRODUCTS} />,
              },
              {
                path: ROUTENAME.PRODUCTS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.PRODUCTS}>
                    <ComingSoon />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.ASSETS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_ASSETS}>
                    <ComingSoon />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.INVOICES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_INVOICES}>
                    {/* Super Admin */}
                    <ComingSoon />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.USERS,
            children: [
              {
                path: ROUTENAME.GLOBAL_ADMIN,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMINS}>
                    <ComingSoon />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.REPORTS,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={[AUTH_ROUTES.CUSTOMER_REPORTS]}>
                    <ComingSoon />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.DATA_MANAGEMENT,
                children: [
                  {
                    index: true,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.DATA_MANAGEMENT}>
                        <DataManagement />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.IMPORT_DATA,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.IMPORT_DATA}>
                        <ImportData />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.EXPORT_DATA,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.EXPORT_DATA}>
                        <ExportData />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.SAMPLE_TEMPLATES,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.SAMPLE_TEMPLATES}>
                        <Templates />
                      </AuthRoute>
                    ),
                  },
                ],
              },
              {
                path: ROUTENAME.SUPER_ADMIN_REPORTING,
                children: [
                  {
                    index: true,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_REPORTING}>
                        <Reporting />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.ACTIVITY_REPORT,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_ACITVITY_REPORT}>
                        <ActivityReport />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.TECHNICIAN_PERFORMANCE,
                    element: (
                      <AuthRoute
                        type={AUTH_ROUTES.SUPER_ADMIN_TECHNICIAN_PERFORMANCE}
                      >
                        <TechnicianPerformanceLayout />
                      </AuthRoute>
                    ),
                    children: [
                      {
                        index: true,
                        element: (
                          <AuthRoute
                            type={
                              AUTH_ROUTES.SUPER_ADMIN_TECHNICIAN_PERFORMANCE
                            }
                          >
                            <TechnicianPerformance />
                          </AuthRoute>
                        ),
                      },
                      {
                        path: ROUTENAME.CHARTS,
                        element: (
                          <AuthRoute
                            type={
                              AUTH_ROUTES.SUPER_ADMIN_TECHNICIAN_PERFORMANCE_CHARTS
                            }
                          >
                            <TechnicianPerformanceCharts />
                          </AuthRoute>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: ROUTENAME.REPORTING,
                element: (
                  <AuthRoute type={AUTH_ROUTES.REPORTING}>
                    <ComingSoon />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.FORMS,
            children: [
              {
                index: true,
                element: <Navigate to={ROUTENAME.ROUTINE_SERVICES} />,
              },
              {
                path: ROUTENAME.ROUTINE_SERVICES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_ROUTINE_SERVICES}>
                    {/* Add dependencies in package.json for survey-core */}
                    {/* <Forms />  */}
                    <ComingSoon />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.ASSETS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_ASSETS}>
                    <ComingSoon />
                  </AuthRoute>
                ),
              },
            ],
          },

          {
            path: ROUTENAME.INVOICES,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_INVOICES}>
                {/* Global Admin */}
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.MAKE_REQUEST,
            element: (
              <AuthRoute type={AUTH_ROUTES.MAKE_A_REQUEST}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.ASSETS,
            element: (
              <AuthRoute type={AUTH_ROUTES.CUSTOMER_ASSETS}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.ROUTINE_ACTIVITIES,
            element: (
              <AuthRoute type={AUTH_ROUTES.ROUTINE_ACTIVITIES}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.REACTIVE_TASK,
            element: (
              <AuthRoute type={AUTH_ROUTES.REACTIVE_TASK}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.DOCUMENTS,
            element: (
              <AuthRoute type={AUTH_ROUTES.DOCUMENTS}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.SETTINGS,
            children: [
              {
                path: ROUTENAME.TEMPLATES,
                children: [
                  {
                    index: true,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.TEMPLATES}>
                        <SuperAdminTemplates />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.SUPER_ADMIN_INVOICE_TEMPLATES,
                    element: (
                      <AuthRoute
                        type={AUTH_ROUTES.SUPER_ADMIN_INVOICE_TEMPLATES}
                      >
                        <InvoicesTemplate />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.SUPER_ADMIN_QUOTES_TEMPLATES,
                    element: (
                      <AuthRoute
                        type={AUTH_ROUTES.SUPER_ADMIN_QUOTES_TEMPLATES}
                      >
                        <QuotesTemplate />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.SUPER_ADMIN_PURCHASE_ORDER_TEMPLATES,
                    element: (
                      <AuthRoute
                        type={AUTH_ROUTES.SUPER_ADMIN_PURCHASE_ORDER_TEMPLATES}
                      >
                        <PurchaseOrdersTemplate />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.SUPER_ADMIN_EMAIL_TEMPLATES,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_EMAIL_TEMPLATES}>
                        <EmailTemplateWrapper />
                      </AuthRoute>
                    ),
                  },
                ],
              },
              {
                path: ROUTENAME.FORMS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.FORMS}>
                    {/* Product Catalogue */}
                    <ComingSoon />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.ROUTINE_SERVICE_FREQUENCIES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.ROUTINE_SERVICE_FREQUENCIES}>
                    <RoutineServiceFrequencies />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.INTEGRATIONS,
                children: [
                  {
                    index: true,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.INTEGRATIONS}>
                        <Integrations />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.XERO_INTEGRATION,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.XERO_INTEGRATION}>
                        <XeroIntegrations />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.BC_INTEGRATION,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.BC_INTEGRATION}>
                        <BusinessCentralIntegration />
                      </AuthRoute>
                    ),
                  },
                ],
              },
              {
                path: ROUTENAME.CONFIGURATION,
                children: [
                  {
                    index: true,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.CONFIGURATION}>
                        <Configuration />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.ZONE_CONFIGURATION,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.ZONE_CONFIGURATION}>
                        <ZoneConfiguration />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.TAX_CONFIGURATION,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.TAX_CONFIGURATION}>
                        <TaxConfiguration />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.BUILDING_TYPE_CONFIGURATION,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.BUILDING_TYPE_CONFIGURATION}>
                        <BuildingTypeConfiguration />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.CITY_CONFIGURATION,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.CITY_CONFIGURATION}>
                        <CityConfiguration />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.ASSET_VARIANT_CONFIGURATION,
                    element: (
                      <AuthRoute type={AUTH_ROUTES.ASSET_VARIANT_CONFIGURATION}>
                        <AssetVariants />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.SITE_REQUIREMENT_CONFIGURATION,
                    element: (
                      <AuthRoute
                        type={AUTH_ROUTES.SUPER_ADMIN_SITE_REQUIREMENT}
                      >
                        <SiteRequirementConfiguration />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.ACCESS_SCHEDULE_CONFIGURATION,
                    element: (
                      <AuthRoute
                        type={AUTH_ROUTES.ACCESS_SCHEDULE_CONFIGURATION}
                      >
                        <AccessScheduleConfiguration />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ROUTENAME.ACCESS_PROCEDURE_CONFIGURATION,
                    element: (
                      <AuthRoute
                        type={AUTH_ROUTES.SUPER_ADMIN_ACCESS_PROCEDURE}
                      >
                        <AccessProcedureConfiguration />
                      </AuthRoute>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: ROUTENAME.PROFILE,
            element: (
              <AuthRoute type={AUTH_ROUTES.PROFILE}>
                <Profile key={ROUTENAME.PROFILE} />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.COMPANY_PROFILE,
            element: (
              <AuthRoute type={AUTH_ROUTES.COMPANY_PROFILE}>
                <Profile key={ROUTENAME.COMPANY_PROFILE} />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.USERS_MANAGEMENT,
            element: (
              <AuthRoute type={AUTH_ROUTES.USERS_MANAGEMENT}>
                <UsersManagement />
              </AuthRoute>
            ),

            children: [
              {
                path: ROUTENAME.PROFILE_DETAIL,
                element: (
                  <AuthRoute type={AUTH_ROUTES.PROFILE_DETAIL_VIEW}>
                    <Profile />
                  </AuthRoute>
                ),
              },
            ],
          },
        ],
      },

      {
        path: ROUTENAME.OFFLINE,
        element: <AppErrorPage />,
      },

      {
        path: ROUTENAME.UNAUTHORIZED,
        element: <UnAuthorized />,
      },

      {
        path: ROUTENAME.UNDERMAINTENANCE,
        element: <UnderMaintenance />,
      },
      {
        path: ROUTENAME.NOT_FOUND,
        element: <PageNotFound />,
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
    ],
  },
]);
