import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  SwipeableDrawer as MUISwipeableDrawer,
  Typography,
  styled,
} from '@mui/material';

// Internal
import {
  SCROLLBAR_THUMB_BACKGROUND,
  SCROLLBAR_TRACK_BACKGROUND,
  SCROLLBAR_TRACK_BOXSHADOW,
} from '../../constants/Colors';
import {
  HEADER_HEIGHT,
  SWIPEABLE_DRAWER_WIDTH,
} from '../../constants/Typography';

const MUISwipeableDrawerStyled = styled(MUISwipeableDrawer)(
  ({ theme, width, bgcolor = '' }) => ({
    '& .MuiDrawer-paper': {
      borderRadius: 0,
      width: width || SWIPEABLE_DRAWER_WIDTH,
      background: bgcolor ? bgcolor : theme.palette.divider,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  })
);

const StyledHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  height: `${HEADER_HEIGHT}px`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
}));

const StyledFooter = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.main,
  display: 'flex',
  height: `${HEADER_HEIGHT}px`,
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0 8px',
  borderTop: `1px solid ${theme.palette.divider}`,
  position: 'relative', // Footer sticks at the bottom within the flex layout
  gap: '16px',
}));

const ScrollableSection = styled(Box)(({ nopadding }) => ({
  flex: 1, // Allows the section to grow and fill the available space
  overflowX: 'hidden',
  overflowY: 'auto', // Enables scrolling when content overflows
  padding: nopadding,
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: SCROLLBAR_TRACK_BACKGROUND, // Fixed: Proper string format
    boxShadow: SCROLLBAR_TRACK_BOXSHADOW,
    borderRadius: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: SCROLLBAR_THUMB_BACKGROUND,
    borderRadius: '4px',
    width: '4px',
  },
}));

const SwipeableDrawer = ({
  anchor = 'right',
  open,
  title = '',
  onOpen = () => {},
  onClose = () => {},
  children,
  footerButton,
  width,
  bgColor = '',
  fontWeight = 700,
  noPadding = false,
  disableEnforceFocus = false,
}) => (
  <MUISwipeableDrawerStyled
    anchor={anchor}
    open={open}
    onClose={onClose}
    onOpen={onOpen}
    width={width}
    bgcolor={bgColor}
    disableEnforceFocus={disableEnforceFocus}
  >
    <StyledHeader>
      <Typography variant="h2" fontWeight={fontWeight}>
        {title}
      </Typography>
      <CloseIcon onClick={onClose} />
    </StyledHeader>
    <ScrollableSection nopadding={noPadding ? 0 : '16px'}>
      {children}
    </ScrollableSection>
    {footerButton && (
      <StyledFooter>
        {Array.isArray(footerButton)
          ? footerButton.map((buttonElement, index) => (
              <Box key={index}>{buttonElement}</Box>
            ))
          : footerButton}
      </StyledFooter>
    )}
  </MUISwipeableDrawerStyled>
);

export default SwipeableDrawer;
