import { createSlice } from '@reduxjs/toolkit';

import {
  createCompany,
  editCompany,
  getCompanyDropdownList,
  getCompanyList,
  updateCompany,
  updateCompanyTaxConfiguration,
} from './api';

const initialCreateData = {
  isLoading: false,
  error: false,
  createdCompany: false,
};

const initialEditData = {
  isLoading: false,
  error: false,
  company: false,
};

const initialGetData = {
  isLoading: true,
  error: null,
  companyActiveList: [],
  companyInactiveList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const initialGetDropdownData = {
  isLoading: true,
  error: null,
  data: [],
};

const initialTaxData = {
  isLoading: false,
  error: false,
  data: false,
};

const companySlice = createSlice({
  name: 'company',
  initialState: {
    get: { ...initialGetData },
    create: {
      ...initialCreateData,
    },
    edit: {
      ...initialEditData,
    },
    getDropdown: {
      ...initialGetDropdownData,
    },
    updateTax: initialTaxData,
  },
  reducers: {
    resetCreate(state) {
      state.create = { ...initialCreateData };
    },
    resetEdit(state) {
      state.edit = { ...initialEditData };
    },
    resetPagination(state) {
      state.get = { ...initialGetData };
    },
    resetDropdown(state) {
      state.getDropdown = { ...initialGetDropdownData };
    },
    resetUpdateTax(state) {
      state.updateTax = { ...initialTaxData };
    },
  },
  extraReducers: (builder) => {
    builder
      // Company List
      .addCase(getCompanyList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getCompanyList.fulfilled, (state, action) => {
        const { data, active, pagination } = action.payload;

        state.get.isLoading = false;
        if (active) {
          state.get.companyActiveList = data;
        } else {
          state.get.companyInactiveList = data;
        }
        state.get.total = pagination.total_items;
        state.get.totalCount = pagination.total_count;
      })
      .addCase(getCompanyList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })
      // Create
      .addCase(createCompany.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        state.create.isLoading = false;
        state.create.createdCompany = action.payload.data[0];
        state.create.error = null;
      })
      .addCase(createCompany.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Update
      .addCase(updateCompany.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.create.isLoading = false;
        state.create.error = false;
        state.create.createdCompany = action.payload.data[0];
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Edit
      .addCase(editCompany.pending, (state) => {
        state.edit.isLoading = true;
      })
      .addCase(editCompany.fulfilled, (state, action) => {
        state.edit.isLoading = false;
        state.edit.error = false;
        state.edit.company = action.payload.data[0];
      })
      .addCase(editCompany.rejected, (state, action) => {
        state.edit.isLoading = false;
        state.edit.error = action.payload;
      })
      // company name list for dropdown list
      .addCase(getCompanyDropdownList.pending, (state) => {
        state.getDropdown.isLoading = true;
      })
      .addCase(getCompanyDropdownList.fulfilled, (state, action) => {
        state.getDropdown.isLoading = false;
        state.getDropdown.data = action.payload;
      })
      .addCase(getCompanyDropdownList.rejected, (state, action) => {
        state.getDropdown.isLoading = false;
        state.getDropdown.error = action.payload;
      })
      // Update Tax Configuration
      .addCase(updateCompanyTaxConfiguration.pending, (state) => {
        state.updateTax.isLoading = true;
      })
      .addCase(updateCompanyTaxConfiguration.fulfilled, (state, action) => {
        state.updateTax.isLoading = false;
        state.updateTax.error = false;
        state.updateTax.data = action.payload;
      })
      .addCase(updateCompanyTaxConfiguration.rejected, (state, action) => {
        state.updateTax.isLoading = false;
        state.updateTax.error = action.payload;
      });
  },
});

export const {
  resetCreate,
  resetEdit,
  resetPagination,
  resetDropdown,
  resetUpdateTax,
} = companySlice.actions;

export default companySlice.reducer;
