export const ROUTENAME = {
  DEFAULT_ROUTE: '/',
  DEEP_LINKING_ROUTE: '/download-mobile-app',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_SERVICE: '/terms-of-service',
  ABOUT_APP: '/about-app',

  PROFILE: 'profile',
  CHARTS: 'charts',

  OFFLINE: 'offline',
  UNAUTHORIZED: 'unauthorized',
  UNDERMAINTENANCE: 'undermaintenance',
  NOT_FOUND: '404-not-found',

  // Company Name
  DEFAULT_ROUTE_COMPANY: ':company_name',

  // Global and Super admin
  DASHBOARD: 'dashboard',

  // Global Admin
  COMPANIES: 'companies',
  SUPER_ADMINS: 'super-admins',
  EMAIL_TEMPLATE: 'email-template',
  NOTIFICATION_TEMPLATE: 'notification-template',
  FORMS: 'forms',
  MONITORING_ALERT: 'monitoring_alert',

  // Super admin
  USERS: 'users',
  CUSTOMERS: 'customers',
  BILLING_CONTRACTS: 'billing-contracts',
  BILLING_CONTRACTS_EDIT: ':billing_contract_uuid',
  SUPER_ADMIN: 'super-admin',
  OFFICE_STAFF: 'office-staff',
  STAFF_MANAGEMENT: 'staff-management',
  TECHNICIAN: 'technician',
  CUSTOMER: 'customer',
  PROPERTIES: 'properties',
  PROPERTIES_DETAILS: ':property_uuid',
  SCHEDULING: 'scheduling',
  ROUTINE_SERVICES: 'routine-services',
  SERVICE_REQUESTS: 'service-requests',
  SCHEDULE_BOARD: 'schedule-board',
  SUPER_ADMIN_INVOICE_TEMPLATES: 'invoice',
  SUPER_ADMIN_QUOTES_TEMPLATES: 'quotes',
  SUPER_ADMIN_PURCHASE_ORDER_TEMPLATES: 'purchase-order',
  SUPER_ADMIN_EMAIL_TEMPLATES: 'emails',
  SUPER_ADMINS_PROPERTIES_ASSETS: 'assets',
  SUPER_ADMIN_PROPERTIES_ASSETS_DETAIL: ':asset_id',
  SUPER_ADMIN_FLOOR_PLAN: 'floor-plan',
  SUPER_ADMIN_PROPERTIES_ROUTINE: 'routine',
  SUPER_ADMIN_PROPERTIES_CONTRACT: 'contract',
  SUPER_ADMIN_PROPERTIES_SERVICE_QUOTE: 'service-quote',
  SUPER_ADMIN_PROPERTIES_INVOICES: 'property-invoices',
  SUPER_ADMIN_PROPERTIES_COMING_SOON: 'coming-soon',

  GLOBAL_ADMIN_INTEGRATIONS: 'integrations',
  GLOBAL_ADMIN_XERO_INTEGRATIONS: 'xero-integration',
  GLOBAL_ADMIN_BUSINESS_CENTRAL: 'business-central',
  GLOBAL_ADMIN_GOOGLE_MAPS: 'google-maps',
  GLOBAL_ADMIN_GOOGLE_INDOOR_MAPS: 'google-indoor-maps',
  GLOBAL_ADMIN_SSO: 'sso',
  SUPER_ADMIN_REPORTING: 'reporting',
  ACTIVITY_REPORT: 'activity-report',
  TECHNICIAN_PERFORMANCE: 'technician-performance',
  GLOBAL_ADMIN_TEMPLATES: 'template',
  GLOBAL_ADMIN_ROUTINE_SERVICES: 'routine-services',
  GLOBAL_ADMIN_STANDARDS: 'standards',
  GLOBAL_ADMIN_LANGUAGES: 'languages',
  GLOBAL_ADMIN_TIMEZONE: 'timezone',
  GLOBAL_ADMIN_SECURITY_GROUPS: 'security-groups',
  GLOBAL_ADMIN_INVOICE_TEMPLATES: 'invoices',
  GLOBAL_ADMIN_QUOTES_TEMPLATES: 'quotes',
  GLOBAL_ADMIN_EMAIL_TEMPLATES: 'emails',
  GLOBAL_ADMIN_PURCHASE_ORDER_TEMPLATES: 'purchase-order',
  CONFIGURATION: 'configuration',
  ZONE_CONFIGURATION: 'zone-configuration',
  TAX_CONFIGURATION: 'tax-configuration',
  BUILDING_TYPE_CONFIGURATION: 'building-type-configuration',
  CITY_CONFIGURATION: 'city-configuration',
  ASSET_VARIANT_CONFIGURATION: 'asset-variant-configuration',
  SITE_REQUIREMENT_CONFIGURATION: 'site-requirement-configuration',
  ACCESS_SCHEDULE_CONFIGURATION: 'access-schedule-configuration',
  ACCESS_PROCEDURE_CONFIGURATION: 'access-procedure-configuration',
  // Work Order Start
  WORK_ORDERS: 'work-orders',
  WORK_ORDERS_VIEW: ':uuid',
  // Work Order End
  QUOTES: 'quotes',
  // Commercial Start
  COMMERCIAL: 'commercial',
  PURCHASE_ORDER: 'purchase-order',
  PURCHASE_ORDER_EDIT: ':po_uuid',
  TIMESHEET: 'timesheet',
  // Billing
  BILLING_INFORMATION: 'billing-information',
  BILLING_DETAIL: ':billing_uuid',

  // Commercial End
  DEFECTS_QUOTES: 'defect-quotes',
  DEFECT_QUOTES_VIEW: ':quote_uuid',
  DEFECTS: 'defects',
  QUOTES_SERVICES: 'quotes-services',
  QUOTES_SERVICES_VIEW: ':service_quote_uuid',
  WAREHOUSE: 'warehouse',
  PRODUCTS: 'products',
  ASSETS: 'assets',
  INVOICES: 'invoices',
  INVOICE: 'invoice',
  INVOICE_DETAIL: ':invoice_uuid',
  CONTROL_PANEL: 'control-panel',
  REPORTS: 'reports',
  FIRE_COMPANY: 'fire-company',
  GLOBAL_ADMIN: 'global-admins',
  TEMPLATES: 'templates',
  TEMPLATE: 'template',
  PURCHASE_ORDERS: 'purchase-orders',
  HELP_AND_SUPPORT: 'help-support',

  // Customer
  MAKE_REQUEST: 'make-request',
  ROUTINE_ACTIVITIES: 'routine-activities',
  REACTIVE_TASK: 'reactive-task',
  DOCUMENTS: 'documents',
  SETTINGS: 'settings',
  SUPPLIERS: 'suppliers',
  PRODUCT_CATALOGUE: 'product-catalogue',
  QUOTES_STATUS: ':status',
  INVOICES_STATUS: ':status',
  DEFECT_STATUS: ':status',
  DEFECT_LIST: 'list',
  DATA_MANAGEMENT: 'data-management',
  IMPORT_DATA: 'import-data',
  SAMPLE_TEMPLATES: 'templates',
  REFERENCE_DATA: 'reference-data',
  EXPORT_DATA: 'export-data',
  TBC_DATA: 'tbc-data',

  MASTER: 'master',

  // Office-Staff
  REPORTING: 'reporting',
  INTEGRATIONS: 'integrations',
  XERO_INTEGRATION: 'xero-integration',
  BC_INTEGRATION: 'business-central-integration',
  USERS_MANAGEMENT: 'user-management',
  ROUTINE_SERVICE_FREQUENCIES: 'routine-service-frequinces',
  PROFILE_DETAIL: ':role/:uuid',
  SYSTEM_SETTING: 'system-setting',
  BELL_NOTIFICATION: 'bell-notification',
  COMPANY_PROFILE: 'company-profile',
  SUPPORT: 'support',
  VOICE_AI: 'voice-ai',
  ORA_AI: 'ora-ai',
  CONTACTS: 'contacts',
  CONTACT_DETAIL: ':contact_uuid',
};

// Using at routes.js and Sidebar.jsx
export const AUTH_ROUTES = {
  ADMIN_DASHBOARD: 'admin_dashboard',
  GLOBAL_ADMIN_DASHBOARD: 'global_admin_dashboard',
  FIRE_COMPANIES: 'fire_companies',
  COMPANY_LIST: 'company_list',
  SUPER_ADMINS: 'super_admins',
  SUPER_ADMIN_DEFECTS: 'super_admin_defects',
  GLOBAL_ADMIN_USERS: 'global_admin_users',
  GLOBAL_ADMINS: 'global_admins',
  TEMPLATES: 'templates',
  EMAIL_TEMPLATE: 'email_template',
  GLOBAL_ADMIN_REPORT: 'global_admin_report',
  QUOTE: 'quote',
  INVOICES: 'invoices',
  PURCHASE_ORDERS: 'purchase_orders',
  FORMS: 'forms',
  GLOBAL_ADMIN_ROUTINE_SERVICES: 'global_admin_routine_services',
  // Templates
  GLOBAL_ADMIN_INVOICE_TEMPLATES: 'global_admin_invoice_templates',
  GLOBAL_ADMIN_QUOTES_TEMPLATES: 'global_admin_quotes_templates',
  GLOBAL_ADMIN_PURCHASE_ORDER_TEMPLATES:
    'global_admin_purchase_order_templates',
  GLOBAL_ADMIN_EMAIL_TEMPLATES: 'global_admin_email_templates',

  GLOBAL_ADMIN_STANDARDS: 'global_admin_standards',
  GLOBAL_ADMIN_ASSETS: 'global_admin_assets',
  GLOBAL_ADMIN_INVOICES: 'global_admin_invoices',
  GLOBAL_ADMIN_CONTROL_PANEL: 'global_admin_control_panel',
  GLOBAL_ADMIN_TEMPLATES: 'global_admin_templates',
  GLOBAL_ADMIN_LANGUAGES: 'global_admin_languages',
  GLOBAL_ADMIN_TIMEZONE: 'global_admin_timezone',
  GLOBAL_ADMIN_SECURITY_GROUPS: 'global_admin_security_groups',
  GLOBAL_ADMIN_INTEGRATIONS: 'global_admin_integrations',
  GLOBAL_ADMIN_XERO_INTEGRATIONS: 'global_admin_xero_integrations',
  GLOBAL_ADMIN_BUSINESS_CENTRAL: 'global_admin_business_central',
  GLOBAL_ADMIN_GOOGLE_MAPS: 'global_admin_google_maps',
  GLOBAL_ADMIN_GOOGLE_INDOOR_MAPS: 'global_admin_google_indoor_maps',
  GLOBAL_ADMIN_SSO: 'global_admin_sso',
  MONITORING_ALERT: 'monitoring_alert',
  GLOBAL_ADMIN_PROPERTIES: 'global_admin_property',
  GLOBAL_ADMIN_PROPERTIES_DETAILS: 'global_admin_properties_details',
  // Super Admin Customer Start
  CUSTOMERS: 'customers',
  NAME: 'name',
  BILLING_CONTRACTS: 'billing_contracts',
  BILLING_CONTRACTS_EDIT: 'billing_contracts_edit',
  // Super Admin Customer End
  SUPER_ADMIN_PROPERTIES: 'super_admin_properties',
  PROPERTIES_DETAILS: 'properties_details',
  SCHEDULING: 'scheduling',
  SUPER_ADMIN_ROUTINE_SERVICES: 'super_admin_routine_services',
  SERVICE_REQUESTS: 'service_requests',
  SUPER_ADMIN_REPORTING: 'super_admin_reporting',
  SUPER_ADMIN_ACITVITY_REPORT: 'super_admin_activity_report',
  SUPER_ADMIN_TECHNICIAN_PERFORMANCE: 'super_admin_technician_performance',
  SUPER_ADMIN_TECHNICIAN_PERFORMANCE_CHARTS:
    'super_admin_technician_performance_charts',
  SUPER_ADMINS_PROPERTIES_ASSETS: 'super_admin_properties_assets',
  SUPER_ADMIN_PROPERTIES_ASSETS_DETAIL: 'super_admin_properties_assets_detail',
  SUPER_ADMIN_PROPERTIES_FLOOR_PLAN: 'super_admin_properties_floor_plan',
  SUPER_ADMIN_PROPERTIES_ROUTINE: 'super_admin_properties_routine',
  SUPER_ADMIN_PROPERTIES_CONTRACT: 'super_admin_properties_contract',
  SUPER_ADMIN_PROPERTIES_SERVICE_QUOTE: 'super_admin_properties_service_quote',
  SUPER_ADMIN_PROPERTIES_INVOICES: 'super_admin_properties_invoices',
  SUPER_ADMIN_PROPERTIES_COMING_SOON: 'super_admin_properties_coming_soon',
  CONFIGURATION: 'configuration',
  ZONE_CONFIGURATION: 'zone_configuration',
  TAX_CONFIGURATION: 'tax_configuration',
  BUILDING_TYPE_CONFIGURATION: 'building_type_configuration',
  CITY_CONFIGURATION: 'city_configuration',
  ASSET_VARIANT_CONFIGURATION: 'product_variants_configuration',
  ACCESS_SCHEDULE_CONFIGURATION: 'access_schedule_configuration',

  // Work Order Start
  WORK_ORDERS: 'work_orders',
  WORK_ORDERS_VIEW: 'work_orders_view',
  // Work Order End
  SCHEDULE_BOARD: 'schedule_board',
  SUPER_ADMIN_USERS: 'super_admin_users',
  SUPER_ADMIN: 'super_admin',
  OFFICE_STAFFS: 'office_staffs',
  STAFF_MANAGEMENT: 'staff_management',
  TECHNICIANS: 'technicians',
  SUPER_ADMIN_QUOTES: 'super_admin_quotes',
  DEFECTS_QUOTES: 'defects_quotes',
  DEFECT_QUOTES_VIEW: 'defect_quotes_edit',
  QUOTES_SERVICES: 'quotes_services',
  QUOTES_SERVICES_VIEW: 'quotes_services_edit',
  WAREHOUSE: 'warehouse',
  PRODUCTS: 'products',
  // Templates
  SUPER_ADMIN_INVOICE_TEMPLATES: 'super_admin_invoice_templates',
  SUPER_ADMIN_QUOTES_TEMPLATES: 'super_admin_quotes_templates',
  SUPER_ADMIN_PURCHASE_ORDER_TEMPLATES: 'super_admin_purchase_order_templates',
  SUPER_ADMIN_EMAIL_TEMPLATES: 'super_admin_email_templates',

  SUPER_ADMIN_ASSETS: 'super_admin_assets',
  SUPER_ADMIN_INVOICES: 'super_admin_invoices',
  SUPER_ADMIN_CONTROL_PANEL: 'super_admin_control_panel',
  SUPER_ADMIN_REPORTS: 'super_admin_reports',
  CUSTOMER_DASHBOARD: 'customer_dashboard',
  CUSTOMER_DASHBOARD_PROPERTIES: 'customer_dashboard_properties',
  CUSTOMER_PROPERTIES: 'customer_properties',
  CUSTOMER_QUOTES: 'customer_quotes',
  CUSTOMER_QUOTES_STATUS: 'customer_quotes_status',
  // Customer Invoice Start
  CUSTOMER_INVOICES: 'customer_invoices',
  CUSTOMER_INVOICE_STATUS: 'customer_invoice_status',

  // Customer Defects Dashboard Start
  CUSTOMER_QUOTES_DEFECTS_STATUS: 'customer_quotes_defects_status',
  // Customer Defects Dashboard End

  // Customer Invoice End
  DEFECTS: 'defects',
  CUSTOMER_REPORTS: 'customer_reports',
  MAKE_A_REQUEST: 'make_a_request',
  CUSTOMER_ASSETS: 'customer_assets',
  ROUTINE_ACTIVITIES: 'routine_activities',
  REACTIVE_TASK: 'reactive_task',
  DOCUMENTS: 'documents',
  SETTINGS: 'settings',
  SUPPLIERS: 'suppliers',
  PRODUCT_CATALOGUE: 'product_catalogue',
  CUSTOMER_DASHBOARD_DEFECT_LIST: 'customer_defect_dashboard',
  CUSTOMER_PROPERTIES_ASSETS: 'customer_properties_assets',
  CUSTOMER_PROPERTIES_ASSETS_DETAIL: 'customer_properties_assets_detail',
  CUSTOMER_PROPERTIES_FLOOR_PLAN: 'customer_properties_floor_plan',
  CUSTOMER_PROPERTIES_DETAIL: 'customer_properties_detail',
  CUSTOMER_PROPERTIES_COMING_SOON: 'customer_properties_coming_soon',

  // Commercial Start
  SUPER_ADMIN_COMMERCIAL: 'super_admin_commercial',
  SUPER_ADMIN_TIMESHEET: 'super_admin_timesheet',
  SUPER_ADMIN_BILLING_INFO: 'super_admin_billing_info',
  SUPER_ADMIN_BILLING_VIEW: 'super_admin_billing_view',
  SUPER_ADMIN_COMMERCIAL_INVOICES: 'super_admin_commercial_invoices',
  SUPER_ADMIN_COMMERCIAL_PURCHASE_ORDER:
    'super_admin_commercial_purchase_order',
  SUPER_ADMIN_COMMERCIAL_PURCHASE_ORDER_EDIT:
    'super_admin_commercial_purchase_order_edit',
  SUPER_ADMIN_COMMERCIAL_INVOICE_ORDER_EDIT:
    'super_admin_commercial_invoice_order_edit',
  // Commercial End

  DATA_MANAGEMENT: 'data_management',
  IMPORT_DATA: 'import_data',
  SAMPLE_TEMPLATES: 'sample_templates',
  EXPORT_DATA: 'export_data',
  REPORTING: 'reporting',

  MASTER: 'master',

  HELP_AND_SUPPORT: 'help_support',
  PROFILE: 'profile',
  INTEGRATIONS: 'integrations',
  XERO_INTEGRATION: 'xero_integration',
  BC_INTEGRATION: 'business_central_integration',
  USERS_MANAGEMENT: 'user_management',
  ROUTINE_SERVICE_FREQUENCIES: 'routine_service_frequencies',
  PROFILE_DETAIL_VIEW: 'profile_detail_view',
  REFERENCE_DATA: 'reference_data',
  TBC_DATA: 'tbc_data',
  SYSTEM_SETTING: 'system_setting',
  BELL_NOTIFICATION: 'bell_notification',
  COMPANY_PROFILE: 'company_profile',
  SUPPORT: 'support',
  VOICE_AI: 'voice_ai',
  ORA_AI: 'ora_ai',
  SUPER_ADMIN_CONTACTS: 'super_admin_contacts',
  SUPER_ADMIN_CONTACT_DETAIL: 'super_admin_contact_detail',
  SUPER_ADMIN_SITE_REQUIREMENT: 'super_admin_site_requirement',
  SUPER_ADMIN_ACCESS_PROCEDURE: 'super_admin_access_procedure',
};
