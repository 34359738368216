import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box, Divider, Typography } from '@mui/material';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../../assets/commonStyled';
import { DEFECT_SEVERITY } from '../../../constants/Constants';
import { Validation } from '../../../constants/FieldValidationMsg';
import { findLabelByValue } from '../../../utils';
import Autocomplete from '../../CommonComponents/AutoComplete';
import CustomTextField from '../../CommonComponents/CustomTextField';

const DefectQuotesEdit = ({ control, selectedDefect, reset }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    reset({
      severity: findLabelByValue(DEFECT_SEVERITY, selectedDefect?.severity),
      defect_description: selectedDefect?.defect_description,
      short_resolution: selectedDefect?.short_resolution,
    });
  }, []);

  return (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">
            {`${t('attributes.work_order.defect')} ${t('attributes.information')}`}
          </Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="severity"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={`${t('attributes.work_order.defect')} ${t('attributes.work_order.severity')}`}
                  options={DEFECT_SEVERITY}
                  value={value}
                  onChange={(e, newValue) => {
                    if (newValue || newValue === null) {
                      onChange(newValue);
                    }
                  }}
                  isRequired={false}
                  helperText={error ? error.message : ''}
                  error={error}
                />
              )}
            />
          </Box>
          {/* Defect Description */}
          <Controller
            name="defect_description"
            control={control}
            rules={{
              required: `Defect Description ${Validation.general.required}`,
              maxLength: {
                value: 200,
                message: `Defect Description must be within 200 characters.`,
              },
            }}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                label="Defect Description"
                fullWidth
                value={value}
                onChange={(e) => onChange(e.target.value)}
                helperText={error ? error.message : ''}
                error={error}
                onBlur={() => onChange(value.trim())}
              />
            )}
          />

          {/* Defect Resolution */}
          <Controller
            name="short_resolution"
            control={control}
            rules={{
              required: `Defect Resolution ${Validation.general.required}`,
              maxLength: {
                value: 200,
                message: `Defect Resolution must be within 200 characters.`,
              },
            }}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                label="Defect Resolution"
                fullWidth
                value={value}
                onChange={(e) => onChange(e.target.value)}
                helperText={error ? error.message : ''}
                error={error}
                onBlur={() => onChange(value.trim())}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
    </FormWrapper>
  );
};

export default DefectQuotesEdit;
