import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { WarningAmberOutlined } from '@mui/icons-material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Box, Divider, Tooltip, Typography } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment/moment';

import { RED, SECONDARY } from '../../../constants/Colors';
import { exportToCSV } from '../../../constants/common';
import {
  CUSTOMER_QUOTATION_STATUS,
  DIFFERED,
  INVOICE_STATUS_LIST,
  OVERDUE,
  PAID,
  PERIODIC,
  PURCHASE_ORDER_STATUS,
  ROUTE_NAVIGATE_CONST,
  SCHEDULE_BOARD_WO_UTC_LOCAL,
  SUPPLIER_STATUS,
  TAX_NAME,
  TEMPLATE_SET,
  WORK_ORDER_STEPS,
  WORK_ORDER_TYPE_ROUTINE,
} from '../../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  COLUMNS_TO_MAP,
  EXPORT_FILE_NAMES,
} from '../../../constants/ExportConstant';
import getDropdownListHook from '../../../hooks/getDropdownListHook';
import usePreviewAndDownloadTemplate from '../../../hooks/usePreviewAndDownloadTemplate';
import { snackbarToggle } from '../../../store/CommonReducer';
import { editCompany } from '../../../store/company/api';
import { editContact } from '../../../store/contacts/api';
import { resetEdit } from '../../../store/contacts/reducer';
import { setPropertyInvoiceQuoteFilter } from '../../../store/filters/reducer';
import {
  createInvoice,
  createInvoiceProduct,
  getInvoiceList,
  updateInvoice,
} from '../../../store/invoices/api';
import {
  resetCreateInvoice,
  resetCreateInvoiceProduct,
  resetInvoiceList,
  resetUpdateInvoice,
} from '../../../store/invoices/reducer';
import {
  getPropertyCustomerList,
  getPropertyList,
} from '../../../store/property/api';
import { resetPropertyCustomerList } from '../../../store/property/reducer';
import { getWorkOrderQuotation } from '../../../store/workOrder/api';
import {
  calculateTotal,
  checkIsFiltersApplied,
  formatDateAPI,
  formatStatus,
  formattedDate,
  getAmountByQtyAndPrice,
  getRedirectURL,
  getTaxAmountByQtyAndPrice,
  loggedInUserDetail,
  showHideTopbarLoader,
} from '../../../utils';
import { Edit, View } from '../../CommonComponents/ActionComponent';
import Autocomplete from '../../CommonComponents/AutoComplete';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomDateRangePicker from '../../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../../CommonComponents/FilterComponent';
import NewNoDataPage from '../../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import InvoiceDetail from './InvoiceDetail';

//default filters
const defaultFilters = {
  property: null,
  customer: null,
  status: null,
  invoice_date: null,
  due_date: null,
};

const Invoices = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { invoice_uuid } = useParams();
  const outletProps = useOutletContext();

  const {
    workOrderId,
    nextStepTitle,
    setNextStepTitle,
    isFromWorkOrder = true,
    isFromSideBar = false,
    propertyId,
  } = { ...props, ...outletProps };

  const [searchParams] = useSearchParams();
  const queryStatus = searchParams.get('status');

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [filters, setFilters] = useState(defaultFilters);
  const [searchText, setSearchText] = useState('');
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [invoiceId, setInvoiceId] = useState();
  const [invoiceDetails, setInvoiceDetails] = useState();
  const [stepTitle, setStepTitle] = useState();
  const [selectedRows, setSelectedRows] = useState(null);
  const navigate = useNavigate();

  const user = loggedInUserDetail();

  const filterHeight = (isFilterOpen) =>
    isFilterOpen ? 360 : isFromSideBar ? 301 : 390;

  const { invoiceList, total, totalCount, isLoading } = useSelector(
    (state) => state.invoices.get
  );

  const {
    data: createInvoiceData,
    isLoading: createInvoiceLoading,
    error: createInvoiceError,
  } = useSelector((state) => state.invoices.create);

  const { data: updateInvoiceData, isLoading: updateInvoiceLoading } =
    useSelector((state) => state.invoices.update);

  const {
    data: createInvoiceProductData,

    isLoading: createInvoiceProductLoading,
  } = useSelector((state) => state.invoices.createProduct);

  const { data: updateInvoiceProductData } = useSelector(
    (state) => state.invoices.updateProduct
  );

  const { data: deleteInvoiceProductData } = useSelector(
    (state) => state.invoices.deleteProduct
  );

  const { isLoading: isLoadingCompany, company } = useSelector(
    (state) => state.company.edit
  );

  // Selector for billing contract by its ID
  const {
    isLoading: isGetBillingContractByIdLoading,
    data: getBillingContractByIdData,
  } = useSelector((state) => state.billingContract.getBillingContractById);

  // Selector for work order by its ID
  const { workOrder: workOrderDetails } = useSelector(
    (state) => state.workOrder?.details
  );
  const { propertyInvoiceQuoteFilter } = useSelector((state) => state.filters);

  const {
    defectQuotesList,
    error: defectQuoteListError,
    isLoading: defectQuoteListLoading,
  } = useSelector((state) => state.workOrder.defectQuotes);
  const { isLoading: contactLoading, contact } = useSelector(
    (state) => state.contact.edit
  );

  const { propertyDropdownLoading, propertyDropdownData } = getDropdownListHook(
    {
      reducerName: 'property',
      dropdownListName: 'propertyList',
      labelName: 'property_name',
      valueName: 'uuid',
      loading: 'loading',
    }
  );

  const {
    propertyDropdownLoading: customerDropdownLoading,
    propertyDropdownData: customerDropdownData,
  } = getDropdownListHook({
    reducerName: 'property',
    dropdownListName: 'propertyCustomerList',
    labelName: 'customer_name',
    valueName: 'uuid',
  });

  const { listTemplateLoading, generatePDF, PreviewTemplatePDF } =
    usePreviewAndDownloadTemplate({
      module: TEMPLATE_SET.INVOICE,
      data: {
        companyData: company,
        isLoadingCompany: isLoadingCompany || contactLoading,
        invoiceDetails,
        contact,
      },
    });

  // From dashboard chart
  useEffect(() => {
    if (queryStatus) {
      const selected = INVOICE_STATUS_LIST?.find(
        (d) => d.value === queryStatus
      );

      setFilters((prev) => ({ ...prev, status: [selected] }));
      setIsFilterComponentVisible(true);
    }
  }, [queryStatus]);

  useEffect(() => {
    const detail = invoiceList?.find((item) => item.id === invoiceId);

    detail && setInvoiceDetails(detail);
  }, [invoiceList, invoiceId]);

  useEffect(
    () => () => {
      isFromSideBar && dispatch(resetInvoiceList());
      dispatch(resetCreateInvoice());
      dispatch(resetUpdateInvoice());
      dispatch(resetPropertyCustomerList());
      dispatch(resetCreateInvoiceProduct());
      dispatch(resetEdit());
    },
    []
  );

  useEffect(() => {
    showHideTopbarLoader(createInvoiceLoading, dispatch);
    if (!createInvoiceLoading && isFromWorkOrder) {
      if (createInvoiceError) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: createInvoiceError.message,
          })
        );
      }
      if (createInvoiceData) {
        debouncedFetchData();
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: createInvoiceData.message,
          })
        );
      }
    }
  }, [createInvoiceLoading, createInvoiceData, createInvoiceError]);

  useEffect(() => {
    showHideTopbarLoader(updateInvoiceLoading, dispatch);
    if (!updateInvoiceLoading) {
      if (updateInvoiceData) {
        setOpenPreview(false);
        debouncedFetchData();
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: updateInvoiceData.message,
          })
        );
        dispatch(resetUpdateInvoice());
      }
    }
  }, [updateInvoiceLoading, updateInvoiceData]);

  // For property dropdown
  useEffect(() => {
    const req = {
      limit: -1,
      customerUuid: filters.customer?.value,
    };

    dispatch(getPropertyList(req));
    dispatch(getPropertyCustomerList({}));
  }, [filters.customer?.value]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: `${t('common.id')}`,
        field: 'id',
        sortable: true,
        flex: 0.5,
        renderCell: ({ row }) => (
          <Box>
            <Typography variant="body1">
              {row.account_partner_invoice_number || '-'}
            </Typography>
          </Box>
        ),
      },
      {
        headerName: `${t('attributes.invoice.invoice_date')}`,
        field: 'invoice_creation_date',
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t('attributes.invoice.due_date')}`,
        field: 'due_date',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => {
          if (
            moment(
              formattedDate(row?.due_date, SCHEDULE_BOARD_WO_UTC_LOCAL)
            ).isBefore(moment())
          ) {
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography color={RED}>
                  {formattedDate(row?.due_date)}
                </Typography>
                <WarningAmberOutlined sx={{ color: `${RED}!important` }} />
              </Box>
            );
          }

          return formattedDate(row.due_date);
        },
      },
      {
        headerName: `${t('common.total_amount')}`,
        field: 'totalAmount',
        sortable: false,
        flex: 1,
        renderHeader: () => (
          <Tooltip
            title={`${t('common.total_amount')} (${t('common.including')} ${TAX_NAME})`}
            arrow
          >
            <Typography
              variant="body1"
              textAlign="left"
              sx={{
                display: 'flex',
                justifyContent: 'start',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {t('common.total_amount')}
              <br />
              {`(${t('common.including')} ${TAX_NAME})`}
            </Typography>
          </Tooltip>
        ),
        renderCell: ({ row }) => (
          <Typography
            variant="body2"
            sx={{ display: 'flex', justifyContent: 'start' }}
          >
            {row?.totalAmount}
          </Typography>
        ),
      },
      {
        headerName: `${t('attributes.invoice.status')}`,
        field: 'status',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => formatStatus(row.status),
      },
      {
        field: `${t('attributes.actions')}`,
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <View
              disabled={row?.status === PURCHASE_ORDER_STATUS.DRAFT}
              onClick={() => {
                setInvoiceId(row?.id);
                setOpenPreview(true);
                if (user.company_uuid) {
                  dispatch(editCompany(user?.company_uuid));
                }
                if (row?.contact_uuid) {
                  dispatch(editContact(row?.contact_uuid));
                }
              }}
            />
            {row?.status !== PURCHASE_ORDER_STATUS.SUBMITTED &&
              row?.status !== PURCHASE_ORDER_STATUS.PAID && (
                <Edit
                  onClick={() => {
                    setInvoiceId(row?.id);
                    if (isFromSideBar) {
                      navigate(
                        getRedirectURL()[user?.role_name][
                          ROUTE_NAVIGATE_CONST.INVOICE_EDIT
                        ].replace(':invoice_uuid', row?.uuid)
                      );
                    } else {
                      setStepTitle({
                        title: WORK_ORDER_STEPS.invoice,
                        step: row.account_partner_invoice_number,
                        uuid: row.uuid,
                      });
                      setNextStepTitle &&
                        setNextStepTitle({
                          title: WORK_ORDER_STEPS.invoice,
                          step: row.account_partner_invoice_number,
                          uuid: row.uuid,
                        });
                    }
                    dispatch(resetCreateInvoice());
                  }}
                />
              )}
          </Box>
        ),
      },
    ];

    if (!isFromWorkOrder) {
      baseColumns.splice(
        1,
        0,
        {
          field: 'wo_id',
          headerName: `${t('attributes.invoice.wo_id')}`,
          sortable: false,
          flex: 1,
        },
        {
          field: 'billing_information',
          headerName: `${t('attributes.billingCard.billingInfoText')}`,
          sortable: false,
          flex: 1,
        }
      );
    }

    if (isFromSideBar) {
      baseColumns.splice(
        1,
        0,
        {
          field: 'customer',
          headerName: `${t('attributes.customer.customer')}`,
          sortable: false,
          flex: 1,
        },
        {
          field: 'property',
          headerName: `${t('attributes.property.property')}`,
          sortable: false,
          flex: 1,
          renderCell: ({ row }) =>
            row?.property ? (
              <Box>
                <Typography variant="body1">
                  {row?.property?.property_name}
                </Typography>
                <Typography variant="body2">
                  {[
                    row?.property?.address,
                    row?.property?.address_line_2,
                    row?.property?.city,
                    row?.property?.state,
                    row?.property?.zip_code,
                  ]
                    .filter((item) => item && item.trim()) // This removes null/undefined values
                    .join(', ')}
                </Typography>
              </Box>
            ) : (
              '-'
            ),
        }
      );
    }

    return baseColumns;
  }, []);

  const rows = invoiceList?.map((item) => ({
    ...item,
    id: item.id,
    account_partner_invoice_number: item.account_partner_invoice_number,
    invoice_creation_date: formattedDate(item.invoice_creation_date) || '-',
    due_date: item.due_date || '-',
    totalAmount:
      getAmountByQtyAndPrice(item.items) &&
      getTaxAmountByQtyAndPrice(item.items)
        ? calculateTotal({
            total: getAmountByQtyAndPrice(item.items),
            taxAmount: getTaxAmountByQtyAndPrice(item.items),
            type: 'total',
          })
        : '-',
    status:
      item?.payment_status === PAID
        ? PAID
        : item?.payment_status === OVERDUE
          ? OVERDUE
          : item?.status === DIFFERED
            ? DIFFERED
            : item.status,
    wo_id: item.work_order_data?.woid ? item.work_order_data?.woid : '-',
    property: item?.property,
    billing_information: item.billing_name,
    customer: item?.customer?.customer_name || '-',
    contact_uuid: item?.customer?.contact_uuid,
  }));

  // Function to fetch data based on search, pagination, and filter
  const getAllInvoices = useCallback(() => {
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);

    dispatch(
      getInvoiceList({
        order: order,
        orderBy: orderBy,
        page: currentPage,
        size: perPageData,
        search: searchText,
        work_order_uuid: workOrderId,
        customer_uuid: filters.customer?.value,
        list_column_names: visibleFieldsString,
        invoiceFromDate: filters.invoice_date?.startDate,
        invoiceToDate: filters.invoice_date?.endDate,
        status: filters.status,
        property_uuid: propertyId ? propertyId : filters.property?.value,
        dueDateFrom: filters.due_date?.startDate,
        dueDateTo: filters.due_date?.endDate,
        type_not_in: SUPPLIER_STATUS,
      })
    ).finally(() => {
      setIsInitialLoading(false);
      setIsDataLoading(false);
    });
  }, [
    order,
    orderBy,
    columns,
    searchText,
    currentPage,
    perPageData,
    columnVisibilityModel,
    filters,
    refresh,
  ]);

  useEffect(() => {
    debouncedFetchData();
  }, [
    createInvoiceProductData,
    updateInvoiceProductData,
    deleteInvoiceProductData,
  ]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllInvoices, 500), [
    getAllInvoices,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  // Todo:- Reset all the reducers
  useEffect(() => {
    if (createInvoiceData && defectQuotesList?.quotation_items?.length > 0) {
      const productRequests = defectQuotesList?.quotation_items?.map(
        (product) => ({
          type: createInvoiceData?.data?.[0]?.[0]?.type,
          routine_standard_frequency_id:
            createInvoiceData?.data?.[0]?.[0]?.routine_standard_frequency_id,
          routine_standard_frequency_uuid:
            createInvoiceData?.data?.[0]?.[0]?.routine_standard_frequency_id,
          product_name: product?.product_catalogue?.product_name,
          product_description: product?.product_catalogue?.description,
          price: product?.sell_price,
          qty: product?.qty,
          currency: product?.sell_currency,
          UM: product?.product_catalogue?.um,
          product_type: product?.product_catalogue?.type,
          reference_product_catalogue_uuid: product?.product_catalogue_uuid,
          code: product?.product_catalogue?.code,
          meta_data: {
            defect_description: product?.asset_defects?.defect_description,
            defect_title: product?.asset_defects?.defect_title,
            defect_id: product?.asset_defects?.defect_id,
          },
        })
      );

      dispatch(
        createInvoiceProduct({
          invoice_uuid: createInvoiceData?.data?.[0]?.[0]?.uuid,
          data: { items: productRequests },
        })
      ).finally(() => {
        dispatch(resetCreateInvoiceProduct());
      });
      dispatch(resetCreateInvoice());
    }
  }, [defectQuotesList, createInvoiceData]);

  useEffect(() => {
    if (!createInvoiceProductLoading) {
      if (createInvoiceProductData) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: createInvoiceProductData.message,
          })
        );
      }
    }
  }, [createInvoiceProductLoading, createInvoiceProductData]);

  useEffect(() => {
    if (propertyInvoiceQuoteFilter && !isFromSideBar && !isFromWorkOrder) {
      setFilters(propertyInvoiceQuoteFilter);
      setSearchText(propertyInvoiceQuoteFilter?.searchText);
      checkIsFiltersApplied(propertyInvoiceQuoteFilter) &&
        setIsFilterComponentVisible(true);
    }
  }, []);

  useEffect(() => {
    if ((filters || searchText) && !isFromSideBar && !isFromWorkOrder) {
      dispatch(setPropertyInvoiceQuoteFilter({ ...filters, searchText }));
    }
  }, [filters, searchText]);

  const onResetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  const handleCreateInvoice = () => {
    showHideTopbarLoader(true, dispatch);
    dispatch(
      getWorkOrderQuotation({
        id: workOrderId,
      })
    ).finally(() => {
      dispatch(createInvoice({ work_order_uuid: workOrderId }));
    });
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const isCreateInvoiceButtonVisible =
    isFromWorkOrder &&
    !isGetBillingContractByIdLoading &&
    !(
      workOrderDetails.work_order_type === WORK_ORDER_TYPE_ROUTINE &&
      getBillingContractByIdData?.sub_type === PERIODIC
    );

  const noData = (
    <>
      <NewNoDataPage
        icon={<PersonOutlineOutlinedIcon />}
        title={t('attributes.invoice.invoice')}
        createBtnText={t('common.create_new')}
        singularText="Invoice"
        filterHeight={380}
        onlyTitle={isFromWorkOrder ? false : true}
        customText={
          isFromWorkOrder ? '' : t('attributes.invoice.no_invoice_listed')
        }
      />
    </>
  );

  const RenderedComponent = (
    <Box sx={{ width: '100%' }}>
      {isCreateInvoiceButtonVisible && (
        <>
          <Box sx={{ padding: '16px' }}>
            <CustomButton
              text={t('common.create_new')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => {
                handleCreateInvoice(true);
              }}
              disabled={
                invoiceList?.length >= 1 ||
                createInvoiceLoading ||
                isLoading ||
                createInvoiceProductLoading
              }
            />
          </Box>
          <Divider />
        </>
      )}
      {!isDataLoading && totalCount === 0 && noData}
      {isInitialLoading ||
      listTemplateLoading ||
      (isFromWorkOrder && isGetBillingContractByIdLoading) ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            minHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
            maxHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isInitialLoading &&
        totalCount > 0 && (
          <Box>
            {!isFromWorkOrder && (
              <Box sx={{ p: '16px' }}>
                <FilterSection
                  onFilterBtnClick={() =>
                    setIsFilterComponentVisible(!isFilterComponentVisible)
                  }
                  searchText={searchText}
                  onSearchChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  isRefresh={true}
                  onRefreshFilter={() => setRefresh(!refresh)}
                  isActive={isFilterComponentVisible}
                  onResetFilter={onResetFilter}
                  isResetButtonVisible={
                    searchText ||
                    filters.status?.length > 0 ||
                    filters.invoice_date?.startDate ||
                    filters.invoice_date?.endDate ||
                    filters.due_date?.startDate ||
                    filters.due_date?.endDate ||
                    filters.customer ||
                    filters.property
                  }
                  isFilterDisable={checkIsFiltersApplied(filters)}
                />
                {isFilterComponentVisible && (
                  <FilterComponent>
                    {isFromSideBar && (
                      <>
                        <Autocomplete
                          placeholder={t('attributes.customer.customer')}
                          options={customerDropdownData}
                          onChange={(e, newVal) => {
                            if (newVal || newVal === null) {
                              setFilters((prev) => ({
                                ...prev,
                                customer: newVal,
                              }));
                              setFilters((prev) => ({
                                ...prev,
                                property: null,
                              }));
                            }
                          }}
                          value={filters?.customer}
                          isLoadingData={customerDropdownLoading}
                          width="190px"
                        />
                        <Autocomplete
                          placeholder={t('attributes.property.properties')}
                          options={propertyDropdownData}
                          onChange={(e, newVal) => {
                            if (newVal || newVal === null) {
                              setFilters((prev) => ({
                                ...prev,
                                property: newVal,
                              }));
                            }
                          }}
                          isLoadingData={propertyDropdownLoading}
                          value={filters?.property}
                          disabledDropdown={!filters?.customer?.value}
                          width="190px"
                        />
                      </>
                    )}
                    <Autocomplete
                      multiple
                      handleMultipleFilter={true}
                      placeholder={t('attributes.property.status')}
                      options={INVOICE_STATUS_LIST}
                      value={filters?.status}
                      onChange={(e, newVal) => {
                        if (newVal || newVal === null) {
                          setFilters((prev) => ({
                            ...prev,
                            status: newVal,
                          }));
                        }
                      }}
                      width="190px"
                    />
                    <CustomDateRangePicker
                      placeholder="Invoice Date"
                      onOkClick={(val) => {
                        setFilters((pre) => ({
                          ...pre,
                          invoice_date: { startDate: val[0], endDate: val[1] },
                        }));
                      }}
                      onClear={() => {
                        setFilters((pre) => ({
                          ...pre,
                          invoice_date: null,
                        }));
                      }}
                      fromDate={filters.invoice_date?.startDate}
                      toDate={filters.invoice_date?.endDate}
                    />
                    <CustomDateRangePicker
                      placeholder="Due Date"
                      onOkClick={(val) => {
                        setFilters((pre) => ({
                          ...pre,
                          due_date: { startDate: val[0], endDate: val[1] },
                        }));
                      }}
                      onClear={() => {
                        setFilters((pre) => ({
                          ...pre,
                          due_date: null,
                        }));
                      }}
                      fromDate={filters.due_date?.startDate}
                      toDate={filters.due_date?.endDate}
                    />
                  </FilterComponent>
                )}
              </Box>
            )}
            <CustomGridTable
              columns={columns}
              rows={rows || []}
              total={total}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              perPageData={perPageData}
              setPerPageData={setPerPageData}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              columnVisibilityModel={columnVisibilityModel}
              setColumnVisibilityModel={setColumnVisibilityModel}
              noData={<NoRecordFound />}
              isLoading={
                createInvoiceLoading ||
                isDataLoading ||
                createInvoiceProductLoading
              }
              checkboxSelection={isFromSideBar ? true : false}
              filterHeight={filterHeight(isFilterComponentVisible)}
              sx={{ paddingLeft: '8px' }}
              onRowSelectionModelChange={handleSelectionChange}
            />
          </Box>
        )
      )}
    </Box>
  );

  const MainWrapper = isFromSideBar ? (
    <StyledMainWrapper
      title={isFromSideBar ? t('attributes.invoice.invoices') : ' '}
      btn={
        isFromSideBar && (
          <CustomButton
            text={t('attributes.export')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<OpenInNewIcon />}
            onClick={() => {
              if (!selectedRows || selectedRows?.length === 0) {
                dispatch(
                  snackbarToggle({
                    isOpen: true,
                    isErrorMsg: true,
                    msg: t('message.common.noRecordExportMessage'),
                  })
                );
                setSelectedRows(null);
              } else {
                exportToCSV(
                  selectedRows,
                  COLUMNS_TO_EXPORT.INVOICES,
                  EXPORT_FILE_NAMES.INVOICES,
                  COLUMNS_TO_MAP.INVOICES
                );
              }
            }}
          />
        )
      }
    >
      {RenderedComponent}
    </StyledMainWrapper>
  ) : (
    <>{RenderedComponent}</>
  );

  const handleUpdateInvoiceStatus = (status) => {
    const request = {
      type: invoiceDetails?.type,
      invoice_creation_date: formatDateAPI(invoiceDetails?.created_at),
      due_date: formatDateAPI(invoiceDetails?.due_date),
      payment_status: invoiceDetails?.payment_status,
    };

    dispatch(
      updateInvoice({
        invoice_uuid: invoiceDetails?.uuid,
        data: { ...request, status: status },
      })
    );
  };

  return (
    <>
      {!(nextStepTitle ? nextStepTitle : stepTitle) ? (
        !invoice_uuid ? (
          MainWrapper
        ) : (
          <Outlet
            context={{
              workOrderId,
            }}
          />
        )
      ) : (
        <InvoiceDetail
          workOrderId={workOrderId}
          invoiceId={invoiceId}
          nextStepTitle={nextStepTitle ? nextStepTitle : stepTitle}
          setStepTitle={setStepTitle}
          isFromSideBar={isFromSideBar}
          isFromWorkOrder={isFromWorkOrder}
        />
      )}

      {openPreview && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={openPreview}
          title={invoiceDetails?.account_partner_invoice_number}
          onClose={() => {
            setOpenPreview(false);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.download')}
              color="inherit"
              disabled={isLoadingCompany || listTemplateLoading}
              startIcon={<DownloadOutlinedIcon />}
              onClick={() => {
                generatePDF();
              }}
            />,
            invoiceDetails?.status !== PURCHASE_ORDER_STATUS.SUBMITTED &&
              invoiceDetails?.payment_status !==
                CUSTOMER_QUOTATION_STATUS.OVERDUE && (
                <CustomButton
                  text={t('attributes.invoice.send_invoice')}
                  disabled={updateInvoiceLoading}
                  startIcon={<ForwardToInboxOutlinedIcon />}
                  onClick={() =>
                    handleUpdateInvoiceStatus(PURCHASE_ORDER_STATUS.SUBMITTED)
                  }
                />
              ),
          ]}
          width={653}
        >
          {PreviewTemplatePDF}
        </SwipeableDrawer>
      )}
    </>
  );
};

export default Invoices;
