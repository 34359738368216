import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import { Box, Chip, Divider, styled, Typography } from '@mui/material';
import { toNumber } from 'lodash';
import moment from 'moment';

import {
  APPOINTMENT_CONFIRMED,
  CREATED,
  getStatusLabels,
  PENDING,
  SCHEDULE_BOARD_WO_UTC_LOCAL,
  SCHEDULED,
  WORK_ORDER_TYPE,
} from '../../constants/Constants';
// eslint-disable-next-line import-helpers/order-imports
import { longDateTimeRange } from '../../constants/common';
import { snackbarToggle } from '../../store/CommonReducer';
import { assignUnAssignWOTechnician } from '../../store/workOrder/api';
import { formatAddress, formattedDate } from '../../utils';
import { CustomBadgeButton } from '../CommonComponents/CustomBadgeButton';
import CustomGoogleMap from '../CommonComponents/GoogleMap';
import { ConfirmationModal } from '../CommonComponents/Modal';
import AssignTechnicianModal from './AssignTechnicianModal';

const StyledHeader = styled(Box)({
  padding: '16px',
  backgroundColor: 'rgba(235, 235, 235, 0.2)',
});

const SideWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
  padding: '16px',
  width: '100%',
});

const KeyValueComponent = ({
  name,
  value,
  minWidth = '180px',
  capitalize = true,
}) => (
  <Box sx={{ display: 'flex' }}>
    <Typography variant="body1" minWidth={minWidth}>
      {`${name}:`}
    </Typography>
    <Typography
      variant="body2"
      sx={{ ...(capitalize && { textTransform: 'capitalize' }) }}
    >
      {value}
    </Typography>
  </Box>
);

const WorkOrderData = ({ data, selectedProperty }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    data: assignTechnicianData,
    isLoading: assignTechnicianLoading,
    error: errorAssignTechnician,
  } = useSelector((state) => state.workOrder.asssignTechnician);

  const markers = [
    {
      id: 1,
      lat: toNumber(selectedProperty?.latitude) ?? '',
      lng: toNumber(selectedProperty?.longitude) ?? '',
      tooltipText: formatAddress(
        selectedProperty?.address,
        selectedProperty?.address
      ),
      color: '#395B50',
    },
  ];

  const center = {
    lat: toNumber(selectedProperty?.latitude) ?? '',
    lng: toNumber(selectedProperty?.longitude) ?? '',
  };
  const buildingEra = selectedProperty?.building_attributes.length
    ? selectedProperty?.building_attributes[0]
    : null;
  const accessDetail = selectedProperty?.access_details?.[0];

  const requirements = [
    { key: 'induction_required', value: 'Induction Required' },
    { key: 'log_book_entry_required', value: 'Log Book Entry Required' },
    { key: 'police_check_required', value: 'Police Check Required' },
    {
      key: 'working_with_children_check_required',
      value: ' Working with Children Check Required',
    },
  ];

  const displayRequirements = requirements
    ?.filter((r) => selectedProperty?.site_requirements[0][r.key])
    .map((v) => v?.value)
    .join(',');

  const [selectedWORow, setSelectedWORow] = useState(null);

  useEffect(() => {
    if (assignTechnicianLoading !== null && !assignTechnicianLoading) {
      if (errorAssignTechnician) {
        if (errorAssignTechnician.errorDetails) {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: errorAssignTechnician.errorDetails,
            })
          );
        }
      } else {
        if (assignTechnicianData) {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: assignTechnicianData?.[0]?.technician_uuid
                ? t('attributes.work_order.technician_assigned')
                : t('attributes.work_order.technician_unassigned'),
            })
          );
        }
      }
    }
  }, [errorAssignTechnician, assignTechnicianLoading]);

  const assignUnAssignTechnician = (data) => {
    const req = {
      technician_uuid: data?.technician?.value || null,
    };

    if (!req.technician_uuid) {
      req.schedule_status = PENDING;
      req.scheduled_start_date_time = null;
      req.scheduled_end_date_time = null;
    }

    dispatch(
      assignUnAssignWOTechnician({
        work_order_uuid: selectedWORow.uuid,
        req: req,
      })
    );
    setSelectedWORow(null);
  };

  return (
    <Box sx={{ overflow: 'auto', height: 'calc(100vh - 310px)' }}>
      <Box>
        <StyledHeader>
          <Typography variant="body1">
            {t('attributes.work_order.work_order_details')}
          </Typography>
        </StyledHeader>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <SideWrapper>
            <KeyValueComponent
              name={t('attributes.work_order.work_order_ID')}
              value={data?.woid || '-'}
            />
            <KeyValueComponent
              name={t('attributes.property.propertyName')}
              value={selectedProperty?.property_name || '-'}
            />
            <Box sx={{ display: 'flex' }}>
              <Typography variant="body1" minWidth="180px">
                {`${t('attributes.address')}:`}
              </Typography>
              <Box>
                <Typography
                  variant="body2"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {selectedProperty?.zip_code
                    ? `${selectedProperty?.address || ''}, ${selectedProperty?.address_line_2 ? selectedProperty?.address_line_2 + ', ' : ''}  ${selectedProperty?.city || ''} ${selectedProperty?.state || ''} ${selectedProperty?.zip_code || ''}`
                    : '-'}
                </Typography>
                <Box sx={{ height: '100px', width: '200px', marginTop: '8px' }}>
                  <CustomGoogleMap
                    isDashboard={false}
                    markers={markers}
                    center={center}
                  />
                </Box>
              </Box>
            </Box>
          </SideWrapper>
          <Divider orientation="vertical" flexItem />
          <SideWrapper>
            <KeyValueComponent
              name={t('attributes.work_order.work_order_name')}
              value={data?.work_order_name}
            />
            <KeyValueComponent
              name={t('attributes.work_order.tolerance_date_duration')}
              value={
                data?.tolerance
                  ? `${formattedDate(data?.due_date)} - ${formattedDate(moment(data?.due_date).add(data?.tolerance, 'days'))}`
                  : '-'
              }
            />
            <KeyValueComponent
              name={t('attributes.work_order.estimated_duration')}
              value={
                data?.tolerance
                  ? `${data?.tolerance} ${data?.tolerance > 1 ? 'days' : 'day'}`
                  : '-'
              }
            />
            <KeyValueComponent
              name={t('attributes.work_order.assign_to')}
              value={
                <CustomBadgeButton
                  variant="outlined"
                  fullWidth={true}
                  isLoading={assignTechnicianLoading}
                  text={
                    data?.technician?.technician_name ||
                    t('attributes.workOrder.assign')
                  }
                  onClick={() =>
                    [CREATED, SCHEDULED, APPOINTMENT_CONFIRMED]?.includes(
                      getStatusLabels(data)
                    )
                      ? setSelectedWORow(data)
                      : () => {}
                  }
                  filterName={data?.technician?.technician_name}
                  endIcon={
                    assignTechnicianLoading ? null : [
                        CREATED,
                        SCHEDULED,
                        APPOINTMENT_CONFIRMED,
                      ].includes(getStatusLabels(data)) &&
                      data?.technician?.technician_name ? (
                      <CloseIcon />
                    ) : null
                  }
                  startIcon={
                    assignTechnicianLoading ? null : !data?.technician
                        ?.technician_name ? (
                      <EngineeringOutlinedIcon />
                    ) : null
                  }
                />
              }
            />
            <KeyValueComponent
              name={t('attributes.property.type')}
              value={
                WORK_ORDER_TYPE.filter(
                  (wo) => wo.value === data?.work_order_type?.toLowerCase()
                )[0]?.label
              }
            />
            <KeyValueComponent
              name={t('attributes.work_order.revisit_reason')}
              value={data?.remark || '-'}
            />
          </SideWrapper>
        </Box>
        <Divider />
      </Box>
      <Box>
        <StyledHeader>
          <Typography variant="body1">
            {t('attributes.work_order.contract_details')}
          </Typography>
        </StyledHeader>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <SideWrapper>
            <KeyValueComponent
              name={`${t('attributes.work_order.appointment')} ${t('attributes.work_order.date_nd_time')}`}
              value={longDateTimeRange(
                formattedDate(
                  data?.scheduled_start_date_time,
                  SCHEDULE_BOARD_WO_UTC_LOCAL
                ),
                formattedDate(
                  data?.scheduled_end_date_time,
                  SCHEDULE_BOARD_WO_UTC_LOCAL
                )
              )}
              minWidth="200px"
            />
            <KeyValueComponent
              name={t('attributes.customer.customer')}
              value={selectedProperty?.customer}
              minWidth="200px"
            />
          </SideWrapper>
          <Divider orientation="vertical" flexItem />
          <SideWrapper>
            <KeyValueComponent
              name={t('attributes.work_order.property_contact')}
              value={selectedProperty?.property_contact_info?.name}
            />
            <KeyValueComponent
              name={t('attributes.company.cell_phone')}
              value={selectedProperty?.property_contact_info?.phone}
            />
            <KeyValueComponent
              name={t('attributes.email')}
              capitalize={false}
              value={selectedProperty?.property_contact_info?.email ?? '-'}
            />
          </SideWrapper>
        </Box>
        <Divider />
      </Box>
      <Box>
        <StyledHeader>
          <Typography variant="body1">
            {t('attributes.property.accessDetails')}
          </Typography>
        </StyledHeader>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <SideWrapper>
            <KeyValueComponent
              name={t('attributes.property.accessSchedule')}
              value={
                accessDetail?.access_detail_schedule?.map((item) => (
                  <Chip key={item.id} label={item.schedules?.display_name} />
                )) ?? '-'
              }
            />
            <KeyValueComponent
              name={t('attributes.property.accessProcedure')}
              value={
                accessDetail?.access_detail_procedure?.map((item) => (
                  <Chip
                    key={item.id}
                    label={item.procedures?.display_name}
                    sx={{ height: '20px' }}
                  />
                )) ?? '-'
              }
            />
          </SideWrapper>
          <Divider orientation="vertical" flexItem />
          <SideWrapper>
            <KeyValueComponent
              name={t('attributes.property.accessCode')}
              value={accessDetail?.access_code ?? '-'}
            />
            <KeyValueComponent
              name={t('attributes.property.accessNote')}
              value={accessDetail?.access_note ?? '-'}
            />
          </SideWrapper>
        </Box>
        <Divider />
      </Box>
      <Box>
        <StyledHeader>
          <Typography variant="body1">
            {t('attributes.property.siteRequirement')}
          </Typography>
        </StyledHeader>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <SideWrapper>
            <KeyValueComponent
              name={t('attributes.requirements')}
              value={displayRequirements}
            />
            <KeyValueComponent
              name={t('attributes.property.serviceRequirements')}
              value={
                selectedProperty?.site_requirements[0]?.service_requirements ??
                '-'
              }
            />
            <KeyValueComponent
              name={t('attributes.property.safetyRequirements')}
              value={
                selectedProperty?.site_requirements[0]
                  ?.technician_requirements ?? '-'
              }
            />
          </SideWrapper>
        </Box>
        <Divider />
      </Box>
      <Box>
        <StyledHeader>
          <Typography variant="body1">
            {t('attributes.property.buildingAttributes')}
          </Typography>
        </StyledHeader>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <SideWrapper>
            <KeyValueComponent
              name={t('attributes.property.annualDueCertificateDate')}
              value={
                formattedDate(buildingEra?.annual_due_certification_date) || '-'
              }
              minWidth="220px"
            />
            <KeyValueComponent
              name={t('attributes.property.buildingEra')}
              value={buildingEra?.property_building_era?.display_name || '-'}
              minWidth="220px"
            />
            <KeyValueComponent
              name={t('attributes.property.buildingClass')}
              value={buildingEra?.property_building_class?.display_name || '-'}
              minWidth="220px"
            />
            <KeyValueComponent
              name={t('attributes.property.buildingType')}
              value={buildingEra?.property_building_type?.display_name || '-'}
              minWidth="220px"
            />
            <KeyValueComponent
              name={t('attributes.property.buildingSizeText')}
              value={buildingEra?.building_size?.join(', ') || '-'}
              minWidth="220px"
            />
            {/* <KeyValueComponent
              name={t('attributes.property.buildingSizeSqm')}
              value={SQFT_TO_SQM(buildingEra?.building_size) || '-'}
              minWidth="220px"
            /> */}
            <KeyValueComponent
              name={t('attributes.property.internalNote')}
              value={selectedProperty?.internal_note || '-'}
              minWidth="220px"
            />
          </SideWrapper>
          <Divider orientation="vertical" flexItem />
          <SideWrapper>
            <KeyValueComponent
              name={t('attributes.property.storyAboveGround')}
              value={buildingEra?.stories_above_ground || '-'}
            />
            <KeyValueComponent
              name={t('attributes.property.storyBelowGround')}
              value={buildingEra?.stories_below_ground || '-'}
            />
            <KeyValueComponent
              name={t('attributes.property.constructionClass')}
              value={
                buildingEra?.property_construction_class?.display_name || '-'
              }
            />
            <KeyValueComponent
              name={t('attributes.property.buildingPart')}
              value={buildingEra?.building_part || '-'}
            />
            <KeyValueComponent
              name={t('attributes.property.buildingTenancies')}
              value={buildingEra?.building_tenancies_no || '-'}
            />
            <KeyValueComponent
              name={t('attributes.property.buildingStandards')}
              value={
                buildingEra?.property_building_standard
                  ?.standard_display_name || '-'
              }
            />
          </SideWrapper>
        </Box>
        <Divider />
      </Box>
      {selectedWORow !== null &&
        (selectedWORow?.technician_uuid ? (
          <ConfirmationModal
            open={true}
            setOpen={() => setSelectedWORow(null)}
            title={t('confirmationModal.unassignTechnician.title')}
            description={`${t('confirmationModal.unassignTechnician.description')} ${selectedWORow?.technician?.technician_name || ''}?`}
            onConfirm={(data) => assignUnAssignTechnician(data)}
          />
        ) : (
          <AssignTechnicianModal
            open={true}
            setOpen={() => setSelectedWORow(null)}
            onConfirm={(data) => assignUnAssignTechnician(data)}
          />
        ))}
    </Box>
  );
};

export default WorkOrderData;
