import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Tooltip } from '@mui/material';

import { PRIMARY } from '../../constants/Colors';

const QRCodeUploader = ({ handleFileChange }) => {
  const { t } = useTranslation();

  const fileInputRef = useRef();

  const handleFileClick = () => {
    if (fileInputRef?.current) {
      fileInputRef.current?.click();
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Tooltip title={t('attributes.property.uploadQRCode')}>
        <Box
          onClick={handleFileClick}
          sx={{
            cursor: 'pointer',
            padding: '5px',
            display: 'flex',
            backgroundColor: PRIMARY,
            borderRadius: '4px',
          }}
        >
          <FileUploadOutlinedIcon
            style={{
              color: 'white',
              height: '20px',
              width: '20px',
            }}
          />
        </Box>
      </Tooltip>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }} // Hide the file input
      />
    </div>
  );
};

export default QRCodeUploader;
