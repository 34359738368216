import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box } from '@mui/material';
import { debounce } from 'lodash';

import { exportToCSV, validateAddress } from '../../constants/common';
import { INVITED_STATUS } from '../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
import { GLOBAL_ADMIN } from '../../constants/Roles';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getStatesHook from '../../hooks/getStatesHook';
import useBrowserBackButtonHandler from '../../hooks/useBrowserBackButtonHandler';
import useRoleHook from '../../hooks/useRoleHook';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../store/CommonReducer';
import { getCompanyDropdownList } from '../../store/company/api';
import { resetDropdown } from '../../store/company/reducer';
import {
  createSuperAdmin,
  getSuperAdminList,
  updateSuperAdmin,
} from '../../store/superAdmin/api';
import {
  resetCreate,
  resetEdit,
  resetPagination,
} from '../../store/superAdmin/reducer';
import {
  checkIsFiltersApplied,
  formattedDate,
  showHideTopbarLoader,
} from '../../utils';
import { Edit } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import TabView from '../CommonComponents/TabView';
import SuperAdminForm from './SuperAdminForm';

// Will be used for Super Admin
const defaultValues = {
  name: '',
  email: '',
  address: '',
  address_line_2: '',
  cell_phone: '',
  city: '',
  state: '',
  zip_code: '',
};

// Will be used for Global Admin
const globalAdminDefaultValues = {
  ...defaultValues,
  company_name: '',
};

//default filters
const defaultFilters = {
  city: null,
  state: null,
  company: null,
};
const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

const SuperAdmin = () => {
  const dispatch = useDispatch();
  // Will return which role is associated with user currently from GLOBAL_ADMIN, SUPER_ADMIN
  const hasRole = useRoleHook();
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    control,
    reset,
    trigger,
    setError,
    clearErrors,
    watch,
    setValue,
  } = useForm();

  const { isLoading, data } = useSelector((state) => state.company.getDropdown);
  const {
    isLoading: listLoading,
    superAdminActiveList,
    superAdminInactiveList,
    total,
    totalCount,
  } = useSelector((state) => state.superAdmin.get);
  const {
    isLoading: createLoading,
    createdSuperAdmin,
    error,
  } = useSelector((state) => state.superAdmin.create);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [serverErrors, setServerErrors] = useState([]);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [hasFormValues, sethasFormValues] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [superAdminId, setSuperAdminId] = useState('');

  const [filters, setFilters] = useState(defaultFilters);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [companylistOptions, setCompanylistOptions] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [selectedRows, setSelectedRows] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const watchedFields = watch();

  useBrowserBackButtonHandler({
    hasUnsavedChanges: hasFormValues,
    setOpenConfirmationModal,
    open,
    watchMethod: watch,
    sethasFormValues,
  });

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  const cityList = getCityByStateHook(filters.state?.value);
  const stateList = getStatesHook();
  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  // Memoized columns based on the role
  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: t('attributes.name'),
        field: 'name',
        sortable: true,
        flex: 1,
      },
      {
        headerName: t('attributes.email'),
        field: 'email',
        sortable: false,
        flex: 1,
      },
      {
        headerName: t('attributes.company.cell_phone'),
        field: 'cell_phone',
        sortable: false,
        flex: 1,
      },
      {
        headerName: t('attributes.address'),
        field: 'address',
        sortable: false,
        flex: 1,
      },
      { headerName: t('common.city'), field: 'city', sortable: false, flex: 1 },
      {
        headerName: t('common.state'),
        field: 'state',
        sortable: false,
        flex: 1,
      },
      {
        headerName: t('attributes.customer.created_at'),
        field: 'created_at',
        sortable: true,
        flex: 1,
        valueFormatter: (params) => (params ? formattedDate(params) : ''),
      },
      {
        field: 'edit',
        headerName: t('attributes.edit'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Edit
            onClick={() => {
              setSuperAdminId(row.uuid);
              setOpen(true);
            }}
          />
        ),
      },
    ];

    // Conditionally add company name column for Global Admin
    if (hasRole === GLOBAL_ADMIN) {
      baseColumns.splice(6, 0, {
        headerName: `${t('attributes.company.company')} ${t('attributes.name')}`,
        field: 'company_name',
        sortable: false,
        flex: 1,
      });
    }

    return baseColumns;
  }, []);

  // Function to fetch data based on search, pagination, and filter
  const getAllSuperAdmin = useCallback(() => {
    setSelectedRows(null);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);

    dispatch(
      getSuperAdminList({
        active: activeTab === 0 ? true : false,
        limit: perPageData,
        page: currentPage,
        search: searchText,
        order: order,
        orderBy: orderBy,
        company: filters.company?.label,
        state: filters.state?.value,
        city: filters.city?.value,
        fromDate: fromDate,
        toDate: toDate,
        list_column_names: visibleFieldsString,
      })
    ).finally(() => {
      setIsDataLoading(false);
      setIsInitialLoading(false);
    });
  }, [
    dispatch,
    activeTab,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    fromDate,
    toDate,
    columnVisibilityModel,
    refresh,
  ]);

  useEffect(
    () => () => {
      dispatch(resetCreate());
      dispatch(resetEdit());
      dispatch(resetPagination());
      dispatch(resetDropdown());
    },
    []
  );

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText, fromDate, toDate]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllSuperAdmin, 500), [
    getAllSuperAdmin,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    if (hasRole === GLOBAL_ADMIN) {
      dispatch(getCompanyDropdownList());
    }
  }, [dispatch]);

  const companyList = useMemo(() => {
    const options = data?.data?.map((item) => ({
      label: item.company_name,
      value: item.uuid,
    }));

    return options || [];
  }, [data]);

  useEffect(() => {
    if (!isLoading) {
      setCompanylistOptions(companyList);
    }
  }, [companyList]);

  useEffect(() => {
    showHideTopbarLoader(createLoading, dispatch);
    if (createLoading !== null && !createLoading) {
      if (error) {
        if (error.errorDetails) {
          setServerErrors(error.errorDetails);
        }
      } else {
        if (createdSuperAdmin) {
          if (!superAdminId) setActiveTab(0);
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: !superAdminId
                ? 'Super admin created successfully'
                : 'Super admin updated successfully',
            })
          );
        }
      }
    }
  }, [error, createLoading]);

  const rows =
    activeTab === 0
      ? superAdminActiveList?.map((item) => {
          const updatedItem = Object.keys(item).reduce((acc, key) => {
            acc[key] = item[key] || item[key] === 0 ? item[key] : '-'; // Ensures falsy values except `0` are replaced with '-'

            return acc;
          }, {});

          updatedItem.created_at = item.created_at;
          updatedItem.address = `${updatedItem.address}, ${updatedItem.address_line_2}`;
          return updatedItem;
        })
      : superAdminInactiveList?.map((item) => {
          const updatedItem = Object.keys(item).reduce((acc, key) => {
            acc[key] = item[key] || item[key] === 0 ? item[key] : '-';

            return acc;
          }, {});

          updatedItem.created_at = item.created_at;

          return updatedItem;
        });

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const onSubmit = async (data) => {
    const hasError = await validateAddress({
      address: data.address,
      city: data.city,
      state: data.state,
      postcode: data.zip_code,
      address1: data.address,
      address2: data.address_line_2,
      fieldName: {
        addressKey: 'address',
        addressValue: t('attributes.address'),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'zip_code',
        postCodeValue: t('attributes.postCode'),
        address1Key: 'address',
        address1Value: 'Address Line 1',
        address2Key: 'address_line_2',
        address2Value: 'Address Line 2',
      },
      setServerErrors,
    });

    if (hasError) return;

    const request = {
      name: data.name,
      email: data.email,
      address: data.address,
      address_line_2: data.address_line_2 || '',
      cell_phone: data.cell_phone,
      city: data.city?.value,
      state: data.state?.value,
      zip_code: data.zip_code,
      company_uuid: data.company_name.value,
      company_name: data.company_name.label,
      active: data.active,
      status: INVITED_STATUS,
    };

    if (!superAdminId) {
      dispatch(createSuperAdmin(request));
    } else {
      dispatch(updateSuperAdmin({ uuid: data?.uuid, req: request }));
    }
  };

  const resetFilter = () => {
    setFilters({ state: null, city: null });
    setSearchText('');
    setFromDate(null);
    setToDate(null);
  };

  const onTabChange = () => {
    setIsDataLoading(true);
    setFilters({ state: null, city: null });
    setSearchText('');
    setIsFilterComponentVisible(false);
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const noData = (
    <NewNoDataPage
      icon={<ManageAccountsOutlinedIcon />}
      title={t('attributes.superAdmin.superAdmins')}
      singularText={t('attributes.superAdmin.superAdmin')}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
        overflow: 'hidden',
      }}
      createBtnText={`${t('attributes.add')} ${t('attributes.superAdmin.superAdmin')}`}
      filterHeight={isFilterComponentVisible ? 357 : 301}
    />
  );

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        searchText={searchText}
        isRefresh={true}
        onRefreshFilter={() => setRefresh(!refresh)}
        onSearchChange={(e) => {
          setSearchText(e.target.value);
        }}
        isActive={isFilterComponentVisible}
        onResetFilter={resetFilter}
        isResetButtonVisible={
          searchText ||
          filters.state ||
          filters.city ||
          filters.company ||
          fromDate ||
          toDate
        }
        isFilterDisable={checkIsFiltersApplied({
          ...filters,
          fromDate,
          toDate,
        })}
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          {hasRole === GLOBAL_ADMIN && (
            <Autocomplete
              placeholder={`${t('attributes.company.company')} ${t('attributes.name')}`}
              value={filters?.company}
              options={companylistOptions}
              width="190px"
              onChange={(event, newVal) => {
                if (newVal || newVal === null) {
                  setFilters((prev) => ({ ...prev, company: newVal }));
                }
              }}
              isLoadingData={isLoading}
            />
          )}

          <Autocomplete
            placeholder={t('common.state')}
            options={stateList}
            value={filters?.state}
            onChange={(e, newVal) => {
              if (newVal || newVal === null) {
                setFilters((prev) => ({ ...prev, state: newVal, city: null }));
              }
            }}
            width="190px"
          />
          <Autocomplete
            placeholder={t('common.city')}
            options={cityList}
            value={filters?.city}
            onChange={(e, newVal) => {
              if (newVal || newVal === null) {
                setFilters((prev) => ({ ...prev, city: newVal }));
              }
            }}
            width="190px"
            // disabledDropdown={!filters.state}
          />
          <CustomDateRangePicker
            onOkClick={(val) => {
              setFromDate(val[0]);
              setToDate(val[1]);
            }}
            onClear={() => {
              setFromDate(null);
              setToDate(null);
            }}
            fromDate={fromDate}
            toDate={toDate}
          />
        </FilterComponent>
      )}

      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        onRowSelectionModelChange={handleSelectionChange}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  const mainWrapper = (
    <StyledMainWrapper
      title={t('attributes.superAdmin.superAdmins')}
      btn={
        <>
          <CustomButton
            text={`${t('attributes.add')}  ${t('attributes.superAdmin.superAdmin')}`}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            color="secondary"
            onClick={() => {
              setOpen(true);
              setSuperAdminId('');
              reset(
                hasRole === GLOBAL_ADMIN
                  ? globalAdminDefaultValues
                  : defaultValues
              );
            }}
          />
          <CustomButton
            text={t('attributes.export')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<OpenInNewIcon />}
            onClick={() => {
              const isGlobalAdmin = hasRole === GLOBAL_ADMIN;

              if (!selectedRows || selectedRows?.length === 0) {
                dispatch(
                  snackbarToggle({
                    isOpen: true,
                    isErrorMsg: true,
                    msg: t('message.common.noRecordExportMessage'),
                  })
                );
                setSelectedRows(null);
              } else {
                exportToCSV(
                  selectedRows,
                  isGlobalAdmin
                    ? COLUMNS_TO_EXPORT.GLOBAL_SUPER_ADMIN
                    : COLUMNS_TO_EXPORT.SUPER_ADMIN,
                  isGlobalAdmin
                    ? EXPORT_FILE_NAMES.GLOBAL_SUPER_ADMIN
                    : EXPORT_FILE_NAMES.SUPER_ADMIN
                );
              }
            }}
          />
        </>
      }
    >
      {!isDataLoading && totalCount === 0 && noData}
      {isInitialLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            minHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
            maxHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isInitialLoading &&
        totalCount > 0 && (
          <TabView
            tabs={[
              {
                label: t('common.active'),
                component: renderedComponent,
              },
              {
                label: t('common.inactive'),
                component: renderedComponent,
              },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onTabChange={onTabChange}
          />
        )
      )}
    </StyledMainWrapper>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
            sethasFormValues(false);

            if (window.history.state === null) {
              window.history.back();
            }
          }}
        />
      )}
      {mainWrapper}
      {open && (
        <SwipeableDrawer
          open={open}
          title={
            !superAdminId
              ? `${t('attributes.add')} ${t('attributes.superAdmin.superAdmin')}`
              : `${t('attributes.edit')} ${t('attributes.superAdmin.superAdmin')}`
          }
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          footerButton={
            <CustomButton
              text={
                createLoading
                  ? t('common.loading')
                  : !superAdminId
                    ? t('attributes.add')
                    : t('attributes.save')
              }
              disabled={createLoading}
              startIcon={!superAdminId ? <AddIcon /> : <SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
            />
          }
        >
          <SuperAdminForm
            id={superAdminId}
            reset={reset}
            clearErrors={clearErrors}
            isGlobalAdmin={hasRole === GLOBAL_ADMIN}
            control={control}
            register={register}
            trigger={trigger}
            companylistOptions={companylistOptions}
            watch={watch}
            setValue={setValue}
            isCompanyNameLoading={isLoading}
            setServerErrors={setServerErrors}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default SuperAdmin;
