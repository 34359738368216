import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Skeleton,
  Typography,
} from '@mui/material';

import 'react-image-crop/dist/ReactCrop.css';
import { DIVIDER_COLOR, PRIMARY, WARNING } from '../../constants/Colors';
import { previewDocument, validImageTypes } from '../../constants/common';
import { filterRoles, MAX_FILE_SIZE_MB } from '../../constants/Constants';
import {
  CUSTOMER,
  GLOBAL_ADMIN,
  OFFICE_MANAGEMENT,
  OFFICE_STAFF,
  SUPER_ADMIN,
  TECHNICIAN,
} from '../../constants/Roles';
import { ROUTENAME } from '../../constants/RoutesConstants';
import { getUserRole } from '../../hoc/Permission';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import {
  getDocument,
  getMe,
  updateProfile,
  uploadDocument,
} from '../../store/CommonAPI';
// eslint-disable-next-line
import { getAccountManagerDropdownList } from '../../store/accountManager/api';
import { snackbarToggle } from '../../store/CommonReducer';
import { editCompany, updateCompany } from '../../store/company/api';
import { getOfficeManagementById } from '../../store/officeManagement/api';
import { getOfficeStaffById } from '../../store/officeStaff/api';
import { getSuperAdminById } from '../../store/superAdmin/api';
import { getTechnicianById } from '../../store/technician/api';
import { getCustomerById } from '../../store/users/customer/api';
import {
  extractInitials,
  formatAddress,
  formattedDate,
  showHideTopbarLoader,
} from '../../utils';
import ImageCropper from '../CommonComponents/CropImage/Modal';
import CustomButton from '../CommonComponents/CustomButton';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import MainWrapper from '../CommonComponents/MainWrapper';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import UploadedDocumentView from '../CommonComponents/UploadedDocumentView';

const CommonCard = ({ title, children }) => (
  <Box
    sx={{
      borderRadius: '6px',
      border: `1px solid ${DIVIDER_COLOR}`,
    }}
  >
    <Box sx={{ padding: '16px 16px 16px 32px', background: '#EBEBEB33' }}>
      <Typography variant="body1">{title}</Typography>
    </Box>
    <Divider />
    <Box sx={{ padding: '16px 16px 16px 32px' }}>{children}</Box>
  </Box>
);

const KeyValueComponent = ({
  name,
  value,
  isChip = false,
  isArray = false,
}) => (
  <Box
    sx={{
      display: 'flex',
      columnGap: '8px',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}
  >
    {name && (
      <Typography
        variant="body1"
        sx={{ minWidth: '180px' }}
      >{`${name}:`}</Typography>
    )}
    {isArray && Array.isArray(value) ? (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
        {value.map((item, index) => (
          <Chip
            key={index}
            label={
              <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                {item}
              </Typography>
            }
          />
        ))}
      </Box>
    ) : isChip ? (
      <Chip
        label={
          <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
            {value}
          </Typography>
        }
      />
    ) : (
      <Typography variant="body2">{value}</Typography>
    )}
  </Box>
);

const Profile = () => {
  let managers = '-';
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const notFound = `/${ROUTENAME.NOT_FOUND}`;
  const currentPath = location.pathname.split('/').pop();

  const fileInputRef = useRef(null);
  const params = useParams();

  const [profilePic, setProfilePic] = useState(null);
  const [userRole, setUserRole] = useState(
    currentPath !== ROUTENAME.COMPANY_PROFILE
      ? getUserRole()
      : ROUTENAME.COMPANY_PROFILE
  );
  const [userDetailLoading, setUserDetailLoading] = useState(false);

  const [isImageCropModal, setIsImageCropModal] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);

  const { profileDetails, isAuthLoading } = useSelector(
    (state) => state.common
  );
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    if (profileDetails) {
      if (currentPath !== ROUTENAME.COMPANY_PROFILE) {
        setUserRole(profileDetails?.role_name);
        setProfileData(profileDetails);
      }
      if (userRole === CUSTOMER && profileDetails?.company_uuid) {
        getAccManagerDropDownList(profileDetails?.company_uuid);
      }
    }
  }, [profileDetails]);

  const getAccManagerDropDownList = (companyUUID) => {
    const req = {
      limit: -1,
      companyUUID: companyUUID,
    };

    if (companyUUID) {
      dispatch(getAccountManagerDropdownList(req));
    }
  };

  useEffect(() => {
    if (currentPath !== ROUTENAME.COMPANY_PROFILE && params?.uuid) {
      setUserDetailLoading(true);
      userDetails();
    }
  }, [location]);

  useEffect(() => {
    if (currentPath === ROUTENAME.COMPANY_PROFILE) {
      setUserDetailLoading(true);
      dispatch(editCompany(profileDetails?.company_uuid)).then((res) => {
        setProfileData(res?.payload?.data?.[0]);
        setUserDetailLoading(false);
        setUserRole(ROUTENAME.COMPANY_PROFILE);
      });
    } else if (currentPath === ROUTENAME.PROFILE) {
      dispatch(getMe());
    }
  }, [location]);

  const userDetails = () => {
    const role = params?.role;

    if (
      ![
        GLOBAL_ADMIN,
        SUPER_ADMIN,
        CUSTOMER,
        OFFICE_STAFF,
        TECHNICIAN,
        OFFICE_MANAGEMENT,
      ].includes(role)
    ) {
      navigate(notFound);
    }
    setUserRole(role);
    if (role === SUPER_ADMIN) {
      dispatch(getSuperAdminById(params.uuid)).then((res) => {
        if (res?.error) {
          navigate(notFound);
        }

        setProfileData(res?.payload?.data?.[0]);
        setUserDetailLoading(false);
      });
    }
    if (role === OFFICE_STAFF) {
      dispatch(getOfficeStaffById(params.uuid)).then((res) => {
        if (res?.error) {
          navigate(notFound);
        }

        setProfileData(res?.payload?.data?.[0]);
        setUserDetailLoading(false);
      });
    }
    if (role === OFFICE_MANAGEMENT) {
      dispatch(getOfficeManagementById(params.uuid)).then((res) => {
        if (res?.error) {
          navigate(notFound);
        }

        setProfileData(res?.payload?.data?.[0]);
        setUserDetailLoading(false);
      });
    }
    if (role === TECHNICIAN) {
      dispatch(getTechnicianById(params.uuid)).then((res) => {
        if (res?.error) {
          navigate(notFound);
        }

        setProfileData(res?.payload?.data?.[0]);
        setUserDetailLoading(false);
      });
    }
    if (role === CUSTOMER) {
      dispatch(getCustomerById(params.uuid)).then((res) => {
        if (res?.error) {
          navigate(notFound);
        }

        setProfileData(res?.payload?.data?.[0]);
        setUserDetailLoading(false);
      });
    }
  };

  const { zonesDropdownData } = getDropdownListHook({
    reducerName: 'zones',
    dropdownListName: 'getZonesLGAList',
    labelName: 'zone_name',
    valueName: 'zone_name',
  });
  const { skillsDropdownData } = getDropdownListHook({
    reducerName: 'skills',
    dropdownListName: 'skillsDropdownList',
    labelName: 'display_name',
    valueName: 'name',
  });
  const { serviceAreasDropdownData } = getDropdownListHook({
    reducerName: 'serviceAreas',
    dropdownListName: 'serviceAreasDropdownList',
    labelName: 'display_name',
    valueName: 'name',
  });

  const mapDropdownValues = (selectedValues, dropdownData) =>
    selectedValues?.map((value) => {
      const match = dropdownData.find((item) => {
        if (item.value === value) {
          return item;
        }
      });

      return match ? match.label : '-';
    });

  const mappedSkills = mapDropdownValues(
    profileData?.skills,
    skillsDropdownData
  );
  const mappedServiceAreas = mapDropdownValues(
    profileData?.service_areas,
    serviceAreasDropdownData
  );
  const mappedZones = mapDropdownValues(profileData?.zones, zonesDropdownData);

  const { accountManagerDropdownData } = getDropdownListHook({
    reducerName: 'accountManager',
    dropdownListName: 'accountManagerDropdownList',
    labelName: 'name',
    valueName: 'uuid',
  });

  if (profileData?.account_manager_uuid?.length) {
    const managerData = accountManagerDropdownData?.filter((d) =>
      profileData?.account_manager_uuid?.includes(d?.value)
    );

    managers = managerData?.map((name) => name?.label)?.join(',');
  }

  const mappedAccountManagers = mapDropdownValues(
    profileData?.account_manager_uuid,
    accountManagerDropdownData
  );

  const roleBaseProfile = {
    [GLOBAL_ADMIN]: [
      {
        title: t('common.personal_information'),
        column1: [
          { label: t('attributes.name'), value: profileData?.name || '-' },
          {
            label: t('attributes.role'),
            value:
              profileData?.role_title ||
              (params?.role && filterRoles(userRole, t)) ||
              '-',
            isChip: true,
          },
        ],
      },
      {
        title: t('attributes.office_staff.contact_information'),
        column1: [
          { label: t('attributes.email'), value: profileData?.email || '-' },
        ],
      },
    ],
    [SUPER_ADMIN]: [
      {
        title: t('common.personal_information'),
        column1: [
          { label: t('attributes.name'), value: profileData?.name || '-' },
          {
            label: t('attributes.role'),
            value:
              profileData?.role_title ||
              (params?.role && filterRoles(userRole, t)) ||
              '-',
            isChip: true,
          },
        ],
      },
      {
        title: t('attributes.office_staff.contact_information'),
        column1: [
          {
            label: t('attributes.company.cell_phone'),
            value: profileData?.cell_phone || '-',
          },
          { label: t('attributes.email'), value: profileData?.email || '-' },
        ],
      },
      {
        title: t('common.address_information'),
        column1: [
          {
            label: t('attributes.address'),
            value: profileData?.address
              ? formatAddress(profileData?.address, profileData?.address_line_2)
              : '-',
          },
          { label: t('common.state'), value: profileData?.state || '-' },
        ],
        column2: [
          { label: t('common.city'), value: profileData?.city || '-' },
          {
            label: t('attributes.postCode'),
            value: profileData?.zip_code || '-',
          },
        ],
      },
    ],
    [CUSTOMER]: [
      {
        title: t('common.personal_information'),
        column1: [
          {
            label: `${t('attributes.customer.customer')} ${t('attributes.name')}`,
            value: profileData?.customer_name || '-',
          },
          {
            label: t('attributes.company.abnNumber'),
            value: profileData?.customer_abn_number || '-',
          },
        ],
        column2: [
          {
            label: t('attributes.customer.agreementSignDate'),
            value: formattedDate(profileData?.agreement_signed_date) || '-',
          },
        ],
      },
      {
        title: t('attributes.office_staff.contact_information'),
        column1: [
          {
            label: t('attributes.name'),
            value: profileData?.primary_contact?.contact_name || '-',
          },
          {
            label: t('attributes.company.cell_phone'),
            value: profileData?.primary_contact?.contact_phone || '-',
          },
        ],
        column2: [
          {
            label: t('attributes.email'),
            value: profileData?.primary_contact?.contact_email || '-',
          },
        ],
      },
      {
        title: t('common.address_information'),
        column1: [
          {
            label: t('attributes.address'),
            value: profileData?.address
              ? formatAddress(profileData?.address, profileData?.address_line_2)
              : '-',
          },
          {
            label: t('common.state'),
            value: profileData?.state || '-',
          },
        ],
        column2: [
          {
            label: t('common.city'),
            value: profileData?.city || '-',
          },
          {
            label: t('attributes.postCode'),
            value: profileData?.postcode || '-',
          },
        ],
      },
      {
        title: t('attributes.billingCard.billingInfoText'),
        column1: [
          {
            label: t('attributes.customer.billingContactName'),
            value: profileData?.billing?.billing_contact_name || '-',
          },
        ],
        column2: [
          {
            label: t('attributes.customer.emailTo'),
            value: profileData?.billing?.email_to || '-',
          },
        ],
      },
      {
        title: t('common.account_manager_info'),
        column1: [
          {
            label: t('attributes.property.accountManager'),
            value: mappedAccountManagers || '-',
          },
        ],
      },
    ],
    [OFFICE_STAFF]: [
      {
        title: t('common.personal_information'),
        column1: [
          { label: t('attributes.name'), value: profileData?.name || '-' },
          {
            label: t('attributes.role'),
            value:
              profileData?.role_title ||
              (params?.role && filterRoles(userRole, t)) ||
              '-',
            isChip: true,
          },
        ],
      },
      {
        title: t('attributes.office_staff.contact_information'),
        column1: [
          {
            label: t('attributes.company.cell_phone'),
            value: profileData?.cell_phone || '-',
          },
          { label: t('attributes.email'), value: profileData?.email || '-' },
        ],
      },
      {
        title: t('common.address_information'),
        column1: [
          {
            label: t('attributes.address'),
            value: profileData?.address
              ? formatAddress(profileData?.address, profileData?.address_line_2)
              : '-',
          },
          { label: t('common.state'), value: profileData?.state || '-' },
        ],
        column2: [
          { label: t('common.city'), value: profileData?.city || '-' },
          {
            label: t('attributes.postCode'),
            value: profileData?.postcode || '-',
          },
        ],
      },
    ],
    [OFFICE_MANAGEMENT]: [
      {
        title: t('common.personal_information'),
        column1: [
          { label: t('attributes.name'), value: profileData?.name || '-' },
          {
            label: t('attributes.role'),
            value:
              profileData?.role_title ||
              (params?.role && filterRoles(userRole, t)) ||
              '-',
            isChip: true,
          },
        ],
      },
      {
        title: t('attributes.office_staff.contact_information'),
        column1: [
          {
            label: t('attributes.company.cell_phone'),
            value: profileData?.cell_phone || '-',
          },
          { label: t('attributes.email'), value: profileData?.email || '-' },
        ],
      },
      {
        title: t('common.address_information'),
        column1: [
          {
            label: t('attributes.address'),
            value: profileData?.address
              ? formatAddress(profileData?.address, profileData?.address_line_2)
              : '-',
          },
          { label: t('common.state'), value: profileData?.state || '-' },
        ],
        column2: [
          { label: t('common.city'), value: profileData?.city || '-' },
          {
            label: t('attributes.postCode'),
            value: profileData?.zip_code || profileData?.postcode || '-',
          },
        ],
      },
    ],
    [TECHNICIAN]: [
      {
        title: t('common.general_details'),
        column1: [
          {
            label: t('common.primary_contact'),
            value: profileData?.technician_name || '-',
          },
          {
            label: t('attributes.email'),
            value: profileData?.technician_email || '-',
          },
          {
            label: t('attributes.technician.company_name'),
            value: profileData?.company_name || '-',
          },
          {
            label: t('attributes.technician.category'),
            value: profileData?.categories?.join(', ') || '-',
          },
        ],
        column2: [
          {
            label: t('attributes.technician.skills'),
            value: mappedSkills || '-',
            isArray: true,
          },
          {
            label: t('attributes.technician.service_areas'),
            value: mappedServiceAreas || '-',
            isArray: true,
          },
          {
            label: t('attributes.technician.zones'),
            value: mappedZones || '-',
            isArray: true,
          },
          {
            label: t('attributes.technician.notes'),
            value: profileData?.notes || '-',
          },
        ],
      },
      {
        title: t('attributes.technician.contact_details'),
        column1: [
          {
            label: t('attributes.technician.contact_phone'),
            value: profileData?.primary_contact_details?.contact_phone || '-',
          },
          {
            label: t('attributes.address'),
            value: profileData?.primary_contact_details?.address
              ? formatAddress(
                  profileData?.primary_contact_details?.address,
                  profileData?.primary_contact_details?.address_line_2
                )
              : '-',
          },
        ],
        column2: [
          {
            label: t('common.state'),
            value: profileData?.primary_contact_details?.state || '-',
          },
          {
            label: t('common.city'),
            value: profileData?.primary_contact_details?.city || '-',
          },
          {
            label: t('attributes.postCode'),
            value: profileData?.primary_contact_details?.postcode || '-',
          },
        ],
      },
      {
        title: t('attributes.technician.hourly_rate'),
        column1: [
          {
            label: t('attributes.technician.hourly_rate_aud'),
            value: profileData?.rates?.default_cost_rate || '-',
          },
        ],
      },
      {
        title: t('attributes.technician.apfs_details'),
        column1: [
          {
            label: t('attributes.technician.apfs_certificate'),
            value: profileData?.apfs_number ? 'Yes' : 'No' || '-',
          },
        ],
        column2: [
          {
            label: t('attributes.technician.apfs_number'),
            value: profileData?.apfs_number || '-',
          },
        ],
      },
      {
        title: t('common.attach_documnet'),
        column1: [
          {
            value: profileData?.documents?.length
              ? profileData.documents.map((doc, index) => (
                  <UploadedDocumentView
                    key={index}
                    fileName={doc.document_name || 'Document'}
                    isUploadStatusRequired={false} // Hide upload status
                    onclick={() => {
                      previewDocument(t, dispatch, doc?.document_uuid);
                    }}
                    onDelete={null} // Remove delete functionality
                  />
                ))
              : t('common.no_documents_found'),
          },
        ],
      },
    ],
    [ROUTENAME.COMPANY_PROFILE]: [
      {
        title: t('attributes.company.company_details'),
        column1: [
          {
            label: t('attributes.company.company_name'),
            value: profileData?.company_name || '-',
          },
          {
            label: t('attributes.address'),
            value: profileData?.address
              ? formatAddress(profileData?.address, profileData?.address_line_2)
              : '-',
          },
          {
            label: t('common.city'),
            value: profileData?.city || '-',
          },
        ],
        column2: [
          {
            label: t('attributes.company.abnNumber'),
            value: profileData?.abn || '-',
          },
          {
            label: t('common.state'),
            value: profileData?.state || '-',
          },
          {
            label: t('attributes.postCode'),
            value: profileData?.zip_code || '-',
          },
        ],
      },
      {
        title: t('attributes.company.business_owner_information'),
        column1: [
          {
            label: t('attributes.company.business_owner_name'),
            value: profileData?.contact_name || '-',
          },
          {
            label: t('attributes.email'),
            value: profileData?.email || '-',
          },
        ],
        column2: [
          {
            label: t('attributes.company.cell_phone'),
            value: profileData?.cell_phone || '-',
          },
        ],
      },
    ],
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onSelectFile = (e) => {
    const file = e.target.files?.[0];

    if (!file) return;

    if (!validImageTypes.includes(file.type)) {
      // Show validation error using Snackbar or Toastr
      dispatch(
        snackbarToggle({
          isOpen: true,
          isErrorMsg: true,
          msg: t('common.invalidLogoType'),
        })
      );
      e.target.value = '';

      return;
    }

    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      dispatch(
        snackbarToggle({
          isOpen: true,
          isErrorMsg: true,
          msg: `${t('common.fileSizeExceedsLimit').replace('{size}', `${MAX_FILE_SIZE_MB}${t('common.mb')}`)}`,
        })
      );
      e.target.value = '';

      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      setImgSrc(reader.result?.toString() || '');
      setIsImageCropModal(true);
    };
    reader.readAsDataURL(file);

    e.target.value = '';
  };

  function base64ToBlob(base64Data, contentType) {
    // Check if the string contains the data URL prefix and strip it
    const base64String = base64Data.split(',')[1]; // Removes 'data:image/png;base64,'

    try {
      const byteCharacters = atob(base64String);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    } catch (e) {
      console.error('Invalid base64 string:', e);

      return null;
    }
  }

  const updateAvatar = async (imgSRC) => {
    // Convert to File if needed:
    const blob = base64ToBlob(imgSRC, 'image/png');
    let file;

    if (blob) {
      file = new File(
        [blob],
        `smallLogo_image_${Math.floor(Date.now() / 1000)}.png`,
        {
          type: 'image/png',
        }
      );
    }

    showHideTopbarLoader(true, dispatch);

    setProfilePic(imgSRC);
    let type = 'other';

    if (userRole === ROUTENAME.COMPANY_PROFILE) {
      type = 'company_logo_document_uuid';
    }

    dispatch(
      uploadDocument({
        document_type: type,
        uploaded_by: 1,
        file: file,
      })
    ).then((res) => {
      const documentUUID = res?.payload?.data?.[0]?.uuid;

      if (!documentUUID) {
        return;
      }
      showHideTopbarLoader(true, dispatch);

      if (userRole === ROUTENAME.COMPANY_PROFILE) {
        const company_uuid = profileData?.uuid;

        dispatch(
          updateCompany({
            uuid: company_uuid,
            data: {
              company_logo_document_uuid: res?.payload?.data?.[0]?.uuid,
              company_name: profileData.company_name,
              address: profileData.address,
              address_line_2: profileData.address_line_2,
              city: profileData.city,
              state: profileData.state,
              zip_code: profileData.zip_code,
              contact_name: profileData.contact_name,
              email: profileData?.email,
              abn: profileData.abn,
              office_phone: profileData.office_phone,
              cell_phone: profileData.cell_phone,
              status: profileData?.status,
            },
          })
        ).then((res) => {
          dispatch(editCompany(company_uuid)).then((res) => {
            setProfileData(res?.payload?.data?.[0]);
            setUserRole(ROUTENAME.COMPANY_PROFILE);
            showHideTopbarLoader(false, dispatch);
            dispatch(
              snackbarToggle({
                isOpen: true,
                isErrorMsg: false,
                msg: t('message.company.companyLogoUpdatedMessage'),
              })
            );
          });
        });
      } else {
        dispatch(
          updateProfile({
            uuid: profileData?.uuid,
            value: res?.payload?.data?.[0]?.uuid,
          })
        ).then((res) => {
          dispatch(getMe());
          showHideTopbarLoader(false, dispatch);
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: t('message.company.profilePhotoUpdatedMessage'),
            })
          );
        });
      }
    });
  };

  const deleteProfileHandler = () => {
    setProfilePic(null);
    showHideTopbarLoader(true, dispatch);

    if (userRole === ROUTENAME.COMPANY_PROFILE) {
      const company_uuid = profileData?.uuid;

      dispatch(
        updateCompany({
          uuid: company_uuid,
          data: {
            company_logo_document_uuid: null,
            company_name: profileData.company_name,
            address: profileData.address,
            address_line_2: profileData.address_line_2,
            city: profileData.city,
            state: profileData.state,
            zip_code: profileData.zip_code,
            contact_name: profileData.contact_name,
            email: profileData?.email,
            abn: profileData.abn,
            office_phone: profileData.office_phone,
            cell_phone: profileData.cell_phone,
          },
        })
      ).then((res) => {
        dispatch(editCompany(company_uuid)).then((res) => {
          setProfileData(res?.payload?.data?.[0]);
          setUserDetailLoading(false);
          setUserRole(ROUTENAME.COMPANY_PROFILE);
          showHideTopbarLoader(false, dispatch);
        });
      });
    } else {
      dispatch(
        updateProfile({
          uuid: profileData?.uuid,
          value: null,
        })
      ).then((res) => {
        dispatch(getMe());
        showHideTopbarLoader(false, dispatch);
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: t('message.company.profilePhotoDeletedMessage'),
          })
        );
      });
    }
  };

  useEffect(() => {
    if (
      profileData?.profile_pic_document_uuid &&
      currentPath !== ROUTENAME.COMPANY_PROFILE
    ) {
      dispatch(
        getDocument({ doc_uuid: profileData?.profile_pic_document_uuid })
      ).then((res) => {
        setProfilePic(res?.payload?.data?.[0]?.presigned_url);
      });
    } else if (
      currentPath === ROUTENAME.COMPANY_PROFILE &&
      profileData?.logo_url
    ) {
      setProfilePic(profileData?.logo_url);
    } else {
      setProfilePic(null);
    }
  }, [profileData]);

  const handleDefaultStep = () => {
    navigate(`/${ROUTENAME.USERS_MANAGEMENT}`);
  };

  return (
    <>
      <StyledMainWrapper
        title={
          userRole === ROUTENAME.COMPANY_PROFILE
            ? t('common.companyInformation')
            : !params?.uuid
              ? t('common.profile')
              : ''
        }
        style={{ overflow: 'hidden', height: '100%' }}
      >
        {params?.uuid && (
          <MainWrapper
            defaultPadding="0px 0px 16px 0px"
            title={t('attributes.usersManagement.title')}
            bgcolor={DIVIDER_COLOR}
            variant="body1"
            isStep={true}
            step={filterRoles(userRole, t)}
            handleDefaultStep={handleDefaultStep}
          />
        )}
        {roleBaseProfile?.[userRole]?.length ? (
          <Box
            sx={{
              width: '100%',
              p: '16px 16px 34px 16px',
              height: '100%',
              overflowY: 'auto',
            }}
          >
            <Box
              sx={{
                padding: '16px',
                display: 'flex',
                columnGap: '16px',
                alignItems: 'center',
              }}
            >
              {isAuthLoading ? (
                userRole === ROUTENAME.COMPANY_PROFILE ? (
                  <>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                    >
                      <Typography>
                        {t('attributes.company.company')}{' '}
                        {t('attributes.company.logoCapital')}
                      </Typography>
                      <Skeleton variant="rectangle" width={210} height={90} />
                    </Box>
                  </>
                ) : (
                  <Skeleton variant="circular" width={80} height={80} />
                )
              ) : profilePic && userRole !== ROUTENAME.COMPANY_PROFILE ? (
                <Avatar
                  sx={{ width: '80px', height: '80px', bgcolor: PRIMARY }}
                  src={profilePic}
                />
              ) : profilePic && userRole === ROUTENAME.COMPANY_PROFILE ? (
                <>
                  <Box
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                  >
                    <Typography>
                      {t('attributes.company.company')}{' '}
                      {t('attributes.company.logoCapital')}
                    </Typography>
                    {profilePic || profileData?.logo_url ? (
                      <Avatar
                        sx={{
                          width: '210px',
                          height: '90px',
                          bgcolor: PRIMARY,
                          border: `1px solid ${DIVIDER_COLOR}`,
                          borderRadius: '4px',
                          '& img': {
                            objectFit: 'contain',
                          },
                        }}
                        src={profilePic || profileData?.logo_url}
                      />
                    ) : (
                      <Skeleton
                        variant="rectangle"
                        width={210}
                        height={90}
                        animation="wave"
                      />
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '8px',
                      }}
                    >
                      <CustomButton
                        text={
                          userRole === ROUTENAME.COMPANY_PROFILE
                            ? `${t('attributes.company.upload')} ${t('attributes.company.company')} ${t('attributes.company.logoCapital')}`
                            : t('common.upload_photo')
                        }
                        startIcon={<UploadFileOutlinedIcon />}
                        onClick={handleButtonClick}
                      />
                      <input
                        type="file"
                        ref={fileInputRef}
                        name="profile_pic"
                        onChange={onSelectFile}
                        accept="image/png, image/jpg, image/jpeg"
                        style={{ display: 'none' }}
                      />
                    </Box>
                  </Box>
                </>
              ) : userRole === ROUTENAME.COMPANY_PROFILE ? (
                <>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography>
                      {t('attributes.company.company')}{' '}
                      {t('attributes.company.logo')}
                    </Typography>
                    <Avatar
                      sx={{
                        width: '210px',
                        height: '90px',
                        borderRadius: '0%',
                        bgcolor: PRIMARY,
                      }}
                    >
                      {extractInitials(
                        profileData?.name ||
                          profileData?.customer_name ||
                          profileData?.technician_name ||
                          profileData?.company_name
                      )}
                    </Avatar>
                  </Box>
                </>
              ) : (
                <Avatar
                  sx={{ width: '80px', height: '80px', bgcolor: PRIMARY }}
                >
                  {extractInitials(
                    profileData?.name ||
                      profileData?.customer_name ||
                      profileData?.technician_name ||
                      profileData?.company_name
                  )}
                </Avatar>
              )}
              {!params?.uuid && userRole !== ROUTENAME.COMPANY_PROFILE && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '8px',
                  }}
                >
                  <CustomButton
                    text={
                      userRole === ROUTENAME.COMPANY_PROFILE
                        ? `${t('attributes.company.upload')} ${t('attributes.company.company')} ${t('attributes.company.logo')}`
                        : t('common.upload_photo')
                    }
                    startIcon={<UploadFileOutlinedIcon />}
                    disabled={profilePic}
                    onClick={handleButtonClick}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    name="profile_pic"
                    onChange={onSelectFile}
                    accept="image/png, image/jpg, image/jpeg"
                    style={{ display: 'none' }}
                  />
                  <CustomButton
                    text={t('attributes.delete')}
                    sx={{
                      color: 'white',
                      backgroundColor: WARNING,
                      ':hover': {
                        backgroundColor: !profilePic ? DIVIDER_COLOR : WARNING,
                      },
                      width: 'fit-content',
                    }}
                    startIcon={<DeleteOutlineIcon />}
                    onClick={deleteProfileHandler}
                    disabled={!profilePic}
                  />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
              }}
            >
              {userDetailLoading ? (
                <Box
                  sx={{
                    paddingTop: `${userRole === CUSTOMER && 'calc(100vh - 50%)'}`,
                  }}
                >
                  <CustomCircularLoader />
                </Box>
              ) : (
                roleBaseProfile?.[userRole]?.map((profile, index) => (
                  <CommonCard title={profile?.title} key={index}>
                    {profile?.column1 && profile?.column2 ? (
                      <Box sx={{ display: 'flex' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '16px',
                            width: '50%',
                          }}
                        >
                          {profile?.column1?.map((c1, index) => (
                            <KeyValueComponent
                              name={c1?.label}
                              value={c1?.value}
                              isChip={c1?.isChip}
                              isArray={c1?.isArray}
                              key={index}
                            />
                          ))}
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '16px',
                            width: '50%',
                          }}
                        >
                          {profile?.column2?.map((c2, index) => (
                            <KeyValueComponent
                              key={index}
                              name={c2?.label}
                              value={c2?.value}
                              isChip={c2?.isChip}
                              isArray={c2?.isArray}
                            />
                          ))}
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '16px',
                        }}
                      >
                        {profile?.column1?.map((c1, index) => (
                          <KeyValueComponent
                            key={index}
                            name={c1?.label}
                            value={c1?.value}
                            isChip={c1?.isChip}
                            isArray={c1?.isArray}
                          />
                        ))}
                      </Box>
                    )}
                  </CommonCard>
                ))
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            Coming Soon
          </Box>
        )}
      </StyledMainWrapper>

      {isImageCropModal && (
        <ImageCropper
          imgSrc={imgSrc}
          updateAvatar={updateAvatar}
          open={isImageCropModal}
          onClose={() => {
            setIsImageCropModal(false);
          }}
          isForCompany={userRole === ROUTENAME.COMPANY_PROFILE}
        />
      )}
    </>
  );
};

export default Profile;
