import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Document,
  Image,
  Page,
  pdf,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import moment from 'moment';

import { DarkLogo } from '../../../assets/images';
import { DESCRIPTION_COLOR, TABLE_BG_COLOR } from '../../../constants/Colors';
import {
  DATE_FORMAT,
  DEFECT_STATUS,
  RESOLVED,
} from '../../../constants/Constants';

// Styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 30,
    fontSize: 11,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerRow: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerCell: {
    display: 'flex',
    flexDirection: 'row',
    lineHeight: 1.5,
  },
  headerCellTitle: {
    minWidth: '100px',
  },
  headerCellAnswer: {
    color: DESCRIPTION_COLOR,
  },
  section: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 10,
  },
  tableCellRight: {
    padding: '16px',
    border: '1px solid #EBEBEB',
    textAlign: 'right',
  },
  table: {
    marginTop: '24p',
    width: 'auto',
    // marginVertical: 10,
    border: '1px solid #ececec',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: '1px solid #ccc',
  },
  tableRowHeader: {
    backgroundColor: TABLE_BG_COLOR,
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: 11,
    border: '1px solid #ececec',
    height: '100%',
    flex: 1,
    padding: '8px',
  },
  tableCell: {
    flex: 1,
    fontSize: 11,
    padding: '8px',
    border: '1px solid #ececec',
  },
  logo: {
    height: 68,
    objectFit: 'contain',
    maxWidth: 150,
  },
  image: {
    height: 68,
    objectFit: 'contain',
    maxWidth: 150,
  },
  bold: {
    fontWeight: 'bold',
  },
  footer: {
    marginTop: 20,
    lineHeight: 1.5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  address: {
    lineHeight: 1.5,
    color: '#646464',
  },
  title: {
    marginBottom: 4,
    fontWeight: 'bold',
  },
});

const DownloadPDF = ({
  selectedProperty,
  defects,
  workOrder,
  precheckListData,
  imgSrc,
  company,
}) => {
  const { t } = useTranslation();

  const getImageSource = ({ document_uuid, isSignatureImage = false }) => {
    const imageSource = imgSrc?.find((item) => {
      const key = Object.keys(item)[0];

      return key === document_uuid?.toString();
    });

    if (imageSource) {
      const uri = imageSource[document_uuid];

      return (
        <Image
          src={{
            uri,
            method: 'GET',
          }}
          style={isSignatureImage ? styles.image : styles.logo}
        />
      );
    }

    return <></>;
  };

  return (
    <Document>
      <Page style={styles.page}>
        {/* Header */}
        <View style={styles.headerContainer}>
          <View style={styles.header}>
            <View style={styles.headerRow}>
              <View style={styles.headerCell}>
                <Text style={styles.headerCellTitle}>
                  {t('attributes.customer.customer')} {t('attributes.name')}:
                </Text>
                <Text style={styles.headerCellAnswer}>
                  {selectedProperty?.customer || '-'}
                </Text>
              </View>
              <View style={styles.headerCell}>
                <Text style={styles.headerCellTitle}>
                  {t('attributes.property.property')} {t('attributes.name')}:
                </Text>
                <Text style={styles.headerCellAnswer}>
                  {selectedProperty?.property_name || '-'}
                </Text>
              </View>
              <View style={[styles.headerCell]}>
                <Text style={[styles.headerCellTitle]}>
                  {t('attributes.work_order.property_address')}:
                </Text>
                <Text style={[styles.headerCellAnswer, { width: '280px' }]}>
                  {`${selectedProperty?.address}, ${selectedProperty?.state}, ${
                    selectedProperty?.city ? `${selectedProperty?.city}, ` : ''
                  }${selectedProperty?.zip_code || ''}`}
                </Text>
              </View>
              <View style={styles.headerCell}>
                <Text style={styles.headerCellTitle}>
                  {t('attributes.work_order.work_order_name')}:
                </Text>
                <Text style={styles.headerCellAnswer}>
                  {workOrder?.work_order_name || '-'}
                </Text>
              </View>
              <View style={styles.headerCell}>
                <Text style={styles.headerCellTitle}>
                  {t('attributes.work_order.work_order_ID')}:
                </Text>
                <Text style={styles.headerCellAnswer}>
                  {workOrder?.woid || '-'}
                </Text>
              </View>
            </View>
          </View>
          <Image src={company?.logo_url ?? DarkLogo} style={styles.image} />
        </View>

        {/* Table */}
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableRowHeader]}>
            <Text style={[styles.tableHeader, { minWidth: '250px' }]}>
              {t('attributes.work_order.asset_location')}
            </Text>
            <Text style={styles.tableHeader}>
              {t('attributes.work_order.result')}
            </Text>
          </View>
          {defects?.map((item, index) => {
            const defect = item?.defect || {};

            return (
              <React.Fragment key={index}>
                <View style={styles.tableRow}>
                  <View
                    style={[
                      styles.tableCell,
                      { minWidth: '250px', gap: '8px' },
                    ]}
                  >
                    <Text>{`${index + 1} - ${item?.asset?.label}`}</Text>
                    <Text
                      style={styles.headerCellAnswer}
                    >{`${t('attributes.location')}: ${item?.asset?.location || '-'}`}</Text>
                  </View>

                  <Text style={styles.tableCell}>
                    {item?.defect_status === RESOLVED
                      ? t('attributes.work_order.resolved')
                      : t('attributes.work_order.open')}
                  </Text>
                </View>
                {item?.defect_status === RESOLVED && (
                  <>
                    <View style={styles.tableRow}>
                      <View
                        style={[
                          styles.tableCell,
                          { minWidth: '250px', gap: '8px' },
                        ]}
                      >
                        <Text>
                          {t('attributes.work_order.defect_description')}
                        </Text>
                        <Text style={styles.headerCellAnswer}>
                          {defect?.defect_description || '-'}
                        </Text>

                        <Text style={{ paddingTop: '10px' }}>
                          {t('attributes.work_order.photos')}
                        </Text>

                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '8px',
                            flexWrap: 'wrap',
                          }}
                        >
                          {item?.defect?.documents?.map(
                            (doc) =>
                              doc?.document_type === DEFECT_STATUS && (
                                <View key={doc?.document_uuid}>
                                  {getImageSource({
                                    document_uuid: doc?.document_uuid,
                                  })}
                                </View>
                              )
                          )}
                        </View>
                      </View>
                    </View>
                    <View style={styles.tableRow}>
                      <View
                        style={[
                          styles.tableCell,
                          { minWidth: '250px', gap: '8px' },
                        ]}
                      >
                        <Text>
                          {t('attributes.work_order.resolution_comments')}
                        </Text>
                        <Text style={styles.headerCellAnswer}>
                          {defect?.resolution_comment || '-'}
                        </Text>

                        <Text style={{ paddingTop: '10px' }}>
                          {t('attributes.work_order.resolution_photos')}
                        </Text>

                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '8px',
                            flexWrap: 'wrap',
                          }}
                        >
                          {item?.defect?.documents?.map(
                            (doc) =>
                              doc?.document_type === RESOLVED && (
                                <View key={doc?.document_uuid}>
                                  {getImageSource({
                                    document_uuid: doc?.document_uuid,
                                  })}
                                </View>
                              )
                          )}
                        </View>
                      </View>
                    </View>
                  </>
                )}
              </React.Fragment>
            );
          })}
        </View>

        <View style={styles.footer}>
          <View>
            <Text>
              {t('attributes.technician.technician_name')}:{' '}
              <Text style={styles.headerCellAnswer}>
                {workOrder?.technician?.technician_name || '-'}
              </Text>
            </Text>
            <Text>
              {t('attributes.work_order.performed_date')}:{' '}
              <Text style={styles.headerCellAnswer}>
                {precheckListData?.signature_time
                  ? moment(precheckListData?.signature_time).format(DATE_FORMAT)
                  : '-'}
              </Text>
            </Text>
          </View>

          <View>
            <Text>{t('attributes.work_order.technician_signature')}:</Text>
            {getImageSource({
              document_uuid:
                precheckListData?.technician_signature_document_uuid,
              isSignatureImage: true,
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
};

// PDF Preview Component
const generatePDF = async (
  selectedProperty,
  defects,
  workOrder,
  precheckListData,
  imgSrc,
  setIsDownloadingRectificationPDF,
  company
) => {
  setIsDownloadingRectificationPDF(true);
  const blob = await pdf(
    <DownloadPDF
      selectedProperty={selectedProperty}
      defects={defects}
      workOrder={workOrder}
      precheckListData={precheckListData}
      imgSrc={imgSrc}
      company={company}
    />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.download = `${moment().format(DATE_FORMAT)}-RECTIFICATION-REPORT`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  setIsDownloadingRectificationPDF(false);
};

export default generatePDF;
