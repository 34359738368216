import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTENAME } from '../constants/RoutesConstants';

const useDetailPageValidation = ({
  is_error = false,
  error = false,
  data = true,
  is_loading = true,
}) => {
  const navigate = useNavigate();

  const redirect_to_404 = () => {
    navigate(`/${ROUTENAME.NOT_FOUND}`);
  };

  useEffect(() => {
    if (!is_loading) {
      if (is_error && error) {
        redirect_to_404();
      } else if (!data || data?.length === 0) {
        redirect_to_404();
      }
    }
  }, [is_error, error, data, is_loading]);
};

export default useDetailPageValidation;
