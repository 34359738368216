import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box } from '@mui/material';

import { BrokenImage } from '../../assets/images';
import { getDocument } from '../../store/CommonAPI';
import CustomCircularLoader from './CustomLoader';

const ImageView = ({
  uuid,
  onClick = () => {},
  setImgSrc = () => {},
  containerSx = {},
  imageSx = {},
  storeAsObject = false,
  setImageLoading = () => {},
  addImageToMap = () => {},
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [image, setImage] = useState(null);

  useEffect(() => {
    if (uuid) {
      setIsLoading(true);
      setImageLoading(true);
      dispatch(getDocument({ doc_uuid: uuid }))
        .then((res) => {
          if (storeAsObject) {
            setImgSrc((prev) =>
              prev
                ? [
                    ...prev,
                    {
                      [uuid]: res?.payload?.data?.[0]?.presigned_url,
                    },
                  ]
                : [
                    {
                      [uuid]: res?.payload?.data?.[0]?.presigned_url,
                    },
                  ]
            );
          } else {
            addImageToMap(uuid, res?.payload?.data?.[0]?.presigned_url);
          }
          setImage(res?.payload?.data?.[0]?.presigned_url);
        })
        .catch((err) => {
          setError(true);
        })
        .finally(() => {
          setIsLoading(false);
          setImageLoading(false);
        });
    }
  }, [uuid]);

  // Default styles for the container and image
  const defaultContainerSx = {
    width: '100px',
    height: '100px',
    borderRadius: '8px',
  };
  const defaultImageSx = { width: '100%', height: '100%', objectFit: 'cover' };

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <Box sx={{ ...defaultContainerSx, ...containerSx }}>
      {error ? (
        <img
          src={BrokenImage}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      ) : (
        <Box
          onClick={onClick}
          sx={imageSx ? { width: '100%', height: '100%' } : {}}
        >
          <img
            src={image}
            alt="User Document"
            onError={() => setError(true)}
            style={{ ...defaultImageSx, ...imageSx }}
            loading="lazy"
          />
        </Box>
      )}
    </Box>
  );
};

export default ImageView;

export const imageModalView = (img, key, style = {}) => (
  <img
    key={key}
    src={img}
    alt={`User Document${key}`}
    style={{ width: '500px', height: '500px', objectFit: 'contain', ...style }}
  />
);
