import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CloseOutlined } from '@mui/icons-material';
import { Backdrop, Box, Modal, Typography, styled } from '@mui/material';

import { Validation } from '../../constants/FieldValidationMsg';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';

const modalStyle = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, 0)',
  width: 470,
  bgcolor: 'background.paper',
  borderRadius: '4px',
};

const TitleStyled = styled(Box)(({ theme }) => ({
  background: theme.palette.divider,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px',
  borderRadius: '4px 4px 0 0',
}));

const DescriptionStyled = styled(Box)(() => ({
  padding: '16px',
}));

const FooterButton = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px',
  columnGap: '16px',
});

const AssignTechnicianModal = ({ open, setOpen, onConfirm = () => {} }) => {
  const { t } = useTranslation();

  const { technicianDropdownLoading, technicianDropdownData } =
    getDropdownListHook({
      reducerName: 'technician',
      dropdownListName: 'technicianNamesDropdownList',
      labelName: 'display_name',
      valueName: 'uuid',
    });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: { technician: '' },
  });

  useEffect(() => {
    if (open) reset({ technician: '' });
  }, [open, reset]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={open}
      onClose={setOpen}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
    >
      <Box component="form" onSubmit={handleSubmit(onConfirm)} sx={modalStyle}>
        <TitleStyled>
          <Typography variant="h1">
            {t('confirmationModal.assignTechnician.title')}
          </Typography>
          <CloseOutlined onClick={setOpen} style={{ cursor: 'pointer' }} />
        </TitleStyled>

        <DescriptionStyled>
          <Controller
            name="technician"
            control={control}
            rules={{
              required: `${t('attributes.technician.technician')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                label={`${t('common.select')} ${t('attributes.technician.technician')}`}
                placeholder={`${t('common.select')} ${t('attributes.technician.technician')}`}
                options={technicianDropdownData || []}
                isLoadingData={technicianDropdownLoading}
                value={value}
                onChange={(e, newValue) => {
                  if (newValue || newValue === null) {
                    onChange(newValue);
                  }
                }}
                isRequired={true}
                helperText={error?.message || ''}
                error={!!error}
              />
            )}
          />
        </DescriptionStyled>

        <FooterButton>
          <CustomButton
            text={t('common.cancel')}
            color="inherit"
            onClick={setOpen}
          />
          <CustomButton text={t('common.assign')} type="submit" />
        </FooterButton>
      </Box>
    </Modal>
  );
};

export default AssignTechnicianModal;
