export const LANDINGPAGE_LOGO_HEIGHT = '47px';
export const LANDINGPAGE_LOGO_WIDTH = '142.5px';

export const HEADER_HEIGHT = 54;
export const DRAWER_WIDTH = 260;
export const ICON_DRAWER_WIDTH = 60;
export const SWIPEABLE_DRAWER_WIDTH = 500;
export const SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH = 653;
export const SWIPEABLE_DRAWER_INVOICE_BILL_WIDTH = 672;
export const SWIPEABLE_DRAWER_STEPPER_WIDTH = 775;
