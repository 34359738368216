import { createSlice } from '@reduxjs/toolkit';

import {
  addAccountPartnerConfiguration,
  getAccountPartnerConfiguration,
  verifyAccountPartnerConfiguration,
} from './api';

const initialData = {
  isLoading: false,
  error: false,
  data: false,
};

const accountConfigurationSlice = createSlice({
  name: 'accountConfiguration',
  initialState: {
    add: { ...initialData },
    get: { ...initialData },
    verify: { ...initialData },
  },
  reducers: {
    resetAddAccountPartnerConfiguration(state) {
      state.add = { ...initialData };
    },
    resetGetAccountPartnerConfiguration(state) {
      state.get = { ...initialData };
    },
    resetVerifyAccountPartnerConfiguration(state) {
      state.verify = { ...initialData };
    },
  },
  extraReducers: (builder) => {
    builder
      // Add account partner configuration
      .addCase(addAccountPartnerConfiguration.pending, (state) => {
        state.add.isLoading = true;
      })
      .addCase(addAccountPartnerConfiguration.fulfilled, (state, action) => {
        state.add.isLoading = false;
        state.add.error = false;
        state.add.data = action.payload;
      })
      .addCase(addAccountPartnerConfiguration.rejected, (state, action) => {
        state.add.isLoading = false;
        state.add.error = action.payload;
      })

      // Get account partner configuration
      .addCase(getAccountPartnerConfiguration.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getAccountPartnerConfiguration.fulfilled, (state, action) => {
        state.get.isLoading = false;
        state.get.error = false;
        state.get.data = action.payload?.data[0];
        state.get.listData = action.payload?.data;
      })
      .addCase(getAccountPartnerConfiguration.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })

      // Verify account partner configuration
      .addCase(verifyAccountPartnerConfiguration.pending, (state) => {
        state.verify.isLoading = true;
      })
      .addCase(verifyAccountPartnerConfiguration.fulfilled, (state, action) => {
        state.verify.isLoading = false;
        state.verify.error = false;
        state.verify.data = action.payload;
      })
      .addCase(verifyAccountPartnerConfiguration.rejected, (state, action) => {
        state.verify.isLoading = false;
        state.verify.error = action.payload;
      });
  },
});

export const {
  resetAddAccountPartnerConfiguration,
  resetGetAccountPartnerConfiguration,
  resetVerifyAccountPartnerConfiguration,
} = accountConfigurationSlice.actions;

export default accountConfigurationSlice.reducer;
