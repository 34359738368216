import {
  Box,
  Card,
  CardContent,
  Grid,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';

import { PRIMARY } from '../../constants/Colors';

const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
}));

const CustomCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: '8px',
  paddingBottom: 0,
}));

const ShimmerCard = () => (
  <Card
    sx={{
      height: '143px',
      boxShadow: 0,
      borderRadius: '4px',
    }}
  >
    <CustomCardContent>
      <IconBox>
        <Skeleton variant="circular" width={35} height={35} />
        <Skeleton variant="rectangular" width={24} height={24} />
      </IconBox>
      <Skeleton variant="text" width="60%" />
      <Skeleton variant="text" width="80%" />
    </CustomCardContent>
  </Card>
);

const Cards = ({ cardData, role, isLoading, cardCount }) => {
  if (isLoading) {
    return (
      <Grid container spacing="25px">
        {[...Array(cardCount)].map((_, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
            <ShimmerCard />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Grid container spacing="25px">
      {cardData[role]?.map((card, index) => (
        <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
          <Card
            sx={{
              height: '143px',
              boxShadow: 0,
              borderRadius: '4px',
              cursor: card.disabled ? 'not-allowed' : 'pointer',
              opacity: card.disabled ? 0.5 : 1,
            }}
            onClick={!card.disabled ? card.onclick : undefined}
          >
            <CustomCardContent>
              <IconBox>
                <IconBox sx={{ gap: '8px' }}>
                  {card.rightIcon}
                  {card?.inlineIconTitle && (
                    <CustomTypography variant="common600">
                      {card.title}
                    </CustomTypography>
                  )}
                  {!card.disabled && card.cardActive && (
                    <Box
                      sx={{
                        height: '24px',
                        width: '54px',
                        backgroundColor: PRIMARY,
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '20px',
                      }}
                    >
                      Active
                    </Box>
                  )}
                </IconBox>
                {card.leftIcon}
              </IconBox>
              <CustomTypography variant="common600">
                {!card?.inlineIconTitle && card.title}
              </CustomTypography>
              <CustomTypography variant="common400">
                {card.description}
              </CustomTypography>
            </CustomCardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Cards;
