import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Search } from '@mui/icons-material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Box, Divider, InputAdornment, Typography } from '@mui/material';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../../assets/commonStyled';
import { Validation } from '../../../constants/FieldValidationMsg';
import { getZone } from '../../../store/zones/api';
import { resetGetZoneDetails } from '../../../store/zones/reducer';
import { clearTextfields } from '../../../utils';
import { Delete } from '../../CommonComponents/ActionComponent';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomTextField from '../../CommonComponents/CustomTextField';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';

const ZoneForm = ({
  id,
  control,
  watch,
  reset,
  setValue,
  trigger,
  defaultValues,
  setError,
  setServerErrors = () => {},
  clearErrors,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isLoading, data, error } = useSelector(
    (state) => state.zones.getZoneDetails
  );

  const clearHandler = (name) => setValue(name, '');
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleDelete = (id) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, is_active: false } : row
      )
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(getZone({ uuid: id }));
    } else {
      reset(defaultValues);
    }

    return () => {
      setRows([]);
      dispatch(resetGetZoneDetails());
    };
  }, []);

  useEffect(() => {
    if (data) {
      const { zone_name, local_government_areas } = data;

      setValue('zone_name', zone_name);
      setRows(
        local_government_areas.map((item) => ({
          ...item,
          id: new Date().toISOString(),
        }))
      );
    }
  }, [data]);

  const columns = [
    {
      field: 'local_government_areas_name',
      headerName: t('attributes.configuration.zone.lganame'),
      flex: 3,
      sortable: false,
    },
    {
      field: 'post_code',
      headerName: t('attributes.configuration.zone.postCode'),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'action',
      headerName: t('attributes.action'),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) => <Delete onClick={() => handleDelete(row.id)} />,
    },
  ];

  const handleAddLGA = async () => {
    const isValid = await trigger(['local_government_area', 'post_code']);

    if (!isValid) {
      return;
    } else {
      const local_government_area = watch('local_government_area');
      const post_code = watch('post_code');

      if (!local_government_area || !post_code) {
        if (!local_government_area) {
          setError('local_government_area', {
            type: 'manual',
            message: t('attributes.configuration.zone.lgaRequired'),
          });
        }
        if (!post_code) {
          setError('post_code', {
            type: 'manual',
            message: t('attributes.configuration.zone.postCodeRequired'),
          });
        }

        return;
      }

      const newRow = {
        id: new Date().toISOString(), // Generate ID using date string
        local_government_areas_name: local_government_area,
        post_code: post_code,
        is_active: true,
      };

      // setRows([...rows, newRow]);
      setRows((prevRows) => [...prevRows, newRow]);

      // Clear fields after adding
      setValue('local_government_area', '');
      setValue('post_code', '');
    }
  };

  useEffect(() => {
    if (rows) {
      const updatedRows = rows.map(({ id, ...rest }) => rest);

      setValue('local_government_areas', updatedRows);
    }
  }, [rows]);

  const displayRows = rows
    .filter((row) => row.is_active !== false)
    .filter(
      (row) =>
        row.local_government_areas_name?.toLowerCase().includes(searchTerm) ||
        row.post_code?.toString().includes(searchTerm)
    );

  return (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">
            {t('attributes.configuration.zone.zoneDetails')}
          </Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="zone_name"
            control={control}
            rules={{
              required: t('attributes.configuration.zone.zoneRequired'),
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '4px' }}
              >
                <CustomTextField
                  label="Zone Name"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={true}
                />
              </Box>
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">
            {t('attributes.configuration.zone.lgaDetails')}
          </Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="local_government_area"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '4px' }}
              >
                <CustomTextField
                  label={t('attributes.configuration.zone.lgaName')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors('local_government_area');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              </Box>
            )}
          />
          <Controller
            name="post_code"
            control={control}
            rules={{
              minLength: {
                value: 4,
                message: `${Validation.superAdmin.postCodeCharacter} 4 ${Validation.superAdmin.characters}`,
              },
              maxLength: {
                value: 4,
                message: `${Validation.superAdmin.postCodeCharacter} 4 ${Validation.superAdmin.characters}`,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '4px' }}
              >
                <CustomTextField
                  label={t('attributes.configuration.zone.postCode')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const input = e.target.value;

                    if (/^[0-9]*$/.test(input) || input === '') {
                      onChange(input);
                      clearErrors('post_code');
                    }
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              </Box>
            )}
          />
        </FormFieldWrapper>
        <Box
          sx={{
            padding: '0px 16px 16px 0px',
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <CustomButton
            text={t('attributes.add_to_list')}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={handleAddLGA}
            disabled={false}
          />
        </Box>
      </CustomCard>
      <CustomCard>
        <Box sx={{ padding: '8px 16px 8px 16px' }}>
          <Controller
            name="search"
            control={control}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                placeholder={t('attributes.search')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search sx={{ height: '20px', width: '20px' }} />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                value={value}
                onChange={(e) => {
                  const inputValue = e.target.value;

                  onChange(inputValue);
                  setSearchTerm(inputValue.toLowerCase());
                  trigger('search');
                }}
                onBlur={() => {
                  onChange(value.trim());
                  onBlur();
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => {
                  clearHandler(name);
                  setSearchTerm('');
                }}
                isRequired={false}
              />
            )}
          />
        </Box>
        <Divider />
        <CustomGridTable
          columns={columns}
          rows={displayRows}
          total={displayRows.length}
          checkboxSelection={false}
          paginationRequired={false}
          disableColumnMenu={true}
          isLoading={isLoading}
          noData={
            <NoRecordFound
              isShowIcon={false}
              text={t('attributes.configuration.zone.noData')}
              bold={false}
            />
          }
          isTableView={true}
          filterHeight={538}
        />
      </CustomCard>
    </FormWrapper>
  );
};

export default ZoneForm;
