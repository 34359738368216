import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAddressDetail as getDetail } from '../store/google/api';
import { resetAddressDetail } from '../store/google/reducer';
import getCityByStateHook from './getCityByStateHook';

const useAddressDetail = ({
  selectedAddress,
  setValue = () => {},
  fieldName,
}) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.googleAPIs.addressDetail);

  const [hasUpdatedForm, setHasUpdatedForm] = useState(false);
  const [state, setState] = useState(null);
  const cityList = getCityByStateHook(state);

  const getAddressDetail = useCallback(() => {
    dispatch(getDetail({ place_id: selectedAddress?.value || '' })).finally(
      () => {}
    );
  }, [dispatch, selectedAddress?.value]);

  useEffect(() => () => dispatch(resetAddressDetail()), []);

  useEffect(() => {
    if (selectedAddress?.value) {
      getAddressDetail();
    }
  }, [getAddressDetail, selectedAddress?.value]);

  useEffect(() => {
    if (data && data?.result && !hasUpdatedForm && selectedAddress) {
      const extractedAddress = {
        state: null,
        postal_code: null,
        address: '',
      };

      const components = data?.result?.address_components;

      const addressLine1 = [];
      const addressLine2 = [];

      components.forEach((component) => {
        if (
          component?.types?.some((item) =>
            ['street_number', 'route'].includes(item)
          )
        ) {
          addressLine1.push(component?.long_name);
        } else if (
          !component?.types?.includes('postal_code') &&
          !component?.types?.includes('administrative_area_level_1') &&
          !component?.types?.includes('country')
        ) {
          addressLine2.push(component?.long_name);
        }

        if (component.types?.includes('administrative_area_level_1')) {
          extractedAddress.state = component?.long_name;
          setState(extractedAddress.state);
        } else if (component.types.includes('postal_code')) {
          extractedAddress.postal_code = component?.long_name;
        }
      });

      setValue(fieldName.stateKey, {
        label: extractedAddress.state,
        value: extractedAddress.state,
      });

      setValue(fieldName.postCodeKey, extractedAddress.postal_code);
      setValue(fieldName.address1, addressLine1?.join(' '));
      setValue(fieldName.address2, addressLine2?.join(', '));
      setHasUpdatedForm(true);
    }

    return () => {
      dispatch(resetAddressDetail());
    };
  }, [data, hasUpdatedForm, state]);

  useEffect(() => {
    if (cityList && cityList.length > 0 && data?.result) {
      const components = data?.result?.address_components;
      let extractedCity = '';

      components.forEach((component) => {
        if (component?.types.includes('administrative_area_level_2')) {
          const cityName = component?.short_name;

          extractedCity = cityList.find((city) => city.label === cityName)
            ? cityName
            : null;
        }
      });

      // Update the city field in the form
      if (extractedCity) {
        setValue(fieldName.cityKey, {
          label: extractedCity,
          value: extractedCity,
        });
      } else {
        setValue(fieldName.cityKey, null);
      }
    }
  }, [cityList, setValue, data]);

  useEffect(() => {
    setHasUpdatedForm(false);
  }, [selectedAddress]);

  return {};
};

export default useAddressDetail;
