import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Divider, Typography } from '@mui/material';
import { debounce } from 'lodash';

import { DIVIDER_COLOR } from '../../constants/Colors';
import {
  CHART_CONST,
  getStatusLabels,
  ROUTE_NAVIGATE_CONST,
} from '../../constants/Constants';
import { getUserRole } from '../../hoc/Permission';
import { resetDropdown } from '../../store/company/reducer';
import { getServiceAreasDropdownList } from '../../store/serviceAreas/api';
import { getSkillsDropdownList } from '../../store/skills/api';
import { getWorkOrderList } from '../../store/workOrder/api';
import { resetPagination } from '../../store/workOrder/reducer';
import {
  formatStatus,
  formattedDate,
  getRedirectURL,
  loggedInUserRole,
} from '../../utils';
import { OpenInNewTabIcon } from '../CommonComponents/ActionComponent';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import { WorkOrderFilterComponent } from './WorkOrderFilterComponent';
const defaultFilters = {
  propertyZone: null,
};

export const ScheduleBoardWOList = ({
  handleDragStart,
  zonesDropdownLoading,
  zonesDropdownData,
  isWorkOrderUpdated,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { workOrderList, total, totalCount } = useSelector(
    (state) => state.workOrder.get
  );

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('due_date');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [filters, setFilters] = useState(defaultFilters);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const navigate = useNavigate();

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: ' ',
        headerName: ' ',
        flex: 0.25,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <div
            draggable="true"
            onDragStart={() =>
              handleDragStart({
                workOrderId: row.uuid,
                title: `${row.id} ${row.work_order_name ? `- ${row.work_order_name}` : ''}`,
              })
            }
          >
            <DragIndicatorIcon
              color="rgba(0, 0, 0, 0.56)"
              sx={{ cursor: 'grab' }}
            />
          </div>
        ),
      },
      {
        field: 'id',
        headerName: t('attributes.work_order.id'),
        flex: 1,
        sortable: true,
      },
      {
        field: 'work_order_name',
        headerName: t('attributes.work_order.work_order_name'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'work_order_type',
        headerName: t('attributes.work_order.work_order_type'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'property',
        headerName: t('attributes.property.property'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Box>
            <Typography
              variant="body1"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() =>
                navigate(
                  getRedirectURL()[loggedInUserRole()][
                    ROUTE_NAVIGATE_CONST.PROPERTY_DETAIL
                  ]?.replace(':property_uuid', row?.property?.uuid)
                )
              }
            >
              {row.property_name}
            </Typography>
            <Box>{`${row.address}`}</Box>
          </Box>
        ),
      },
      {
        field: 'asset_count',
        headerName: t('attributes.work_order.asset_count'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Box
            sx={{
              p: '10px',
              height: 20,
              width: 20,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid black',
              borderRadius: '50%',
            }}
          >
            {row.asset_count}
          </Box>
        ),
      },
      {
        field: 'frequency',
        headerName: t('attributes.work_order.frequency'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '4px',
            }}
          >
            {row.frequency && (
              <Box
                sx={{
                  px: '16px',
                  py: '4px',
                  border: '1px solid black',
                  borderRadius: '24px',
                  bgcolor: DIVIDER_COLOR,
                }}
              >
                {row.frequency}
              </Box>
            )}
          </Box>
        ),
      },
      {
        field: 'status',
        headerName: t('attributes.work_order.status'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <Box>{formatStatus(row.status)}</Box>,
      },
      {
        field: 'due_date',
        headerName: t('attributes.work_order.due_date'),
        flex: 1,
      },
      {
        field: 'view',
        headerName: t('attributes.work_order.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => {
          const url = `${getRedirectURL()[getUserRole()][CHART_CONST.WORK_ORDERS]}/${row.uuid}`;

          return (
            <Link to={url} target="_blank">
              <OpenInNewTabIcon />
            </Link>
          );
        },
      },
    ];

    return baseColumns;
  }, []);

  // Function to fetch data based on search, pagination, and filter
  const getAllWorkOrder = useCallback(() => {
    const visibleFieldsString = columns
      .filter(
        (col) =>
          columnVisibilityModel[col.field] !== false && col.field?.trim() !== ''
      )
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);

    dispatch(
      getWorkOrderList({
        size: perPageData,
        page: currentPage,
        search: searchText,
        order: order,
        orderBy: orderBy,
        list_column_names: visibleFieldsString,
        unassignedWorkOrder: true,
        propertyZone: filters.propertyZone?.value,
      })
    ).finally(() => {
      setIsDataLoading(false);
      setIsInitialLoading(false);
    });
  }, [
    dispatch,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
    isWorkOrderUpdated,
    perPageData,
    currentPage,
  ]);

  useEffect(
    () => () => {
      dispatch(resetPagination());
      dispatch(resetDropdown());
    },
    []
  );

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllWorkOrder, 500), [
    getAllWorkOrder,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  // Replace list with related list dropdown once API is ready
  useEffect(() => {
    dispatch(getSkillsDropdownList());
    dispatch(getServiceAreasDropdownList());
  }, []);

  const rows = workOrderList?.map((item) => ({
    ...item,
    id: item.woid,
    work_order_type: item.work_order_type.toUpperCase(),
    work_order_name: item.work_order_name,
    asset_count: item.asset_count || 0,
    technician: item.technician || '-',
    status: getStatusLabels(item),
    property_name: item?.property?.property_name || '-',
    address: item?.property?.address || '-',
    frequency:
      item?.property_equipment_routine_service_schedule?.frequencies?.code,
    appointment_date: formattedDate(item.scheduled_date),
    due_date: formattedDate(item.due_date),
  }));

  const noData = (
    <NewNoDataPage
      icon={<BallotOutlinedIcon />}
      title={t('attributes.workOrder.noDataNote')}
      onlyTitle={true}
      filterHeight={670}
    />
  );

  const renderedComponent = (
    <>
      <WorkOrderFilterComponent
        searchText={searchText}
        filters={filters}
        setFilters={setFilters}
        onSearchChange={(e) => setSearchText(e.target.value)}
        zonesDropdownLoading={zonesDropdownLoading}
        zonesDropdownData={zonesDropdownData}
      />
      <Divider />
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        isLoading={isDataLoading}
        checkboxSelection={false}
        filterHeight={710}
        noData={<NoRecordFound />}
      />
    </>
  );

  return (
    <>
      {!isDataLoading && totalCount === 0 && noData}
      {isInitialLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            minHeight: `calc(100vh - 680px)`,
            maxHeight: `calc(100vh - 680px)`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isInitialLoading && totalCount > 0 && renderedComponent
      )}
    </>
  );
};
