import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { ORA_AI_CHATBOT_LINK } from '../../constants/Constants';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';

const OraAiChatbot = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <StyledMainWrapper title={t('attributes.support.ora_ai')}>
      {isLoading && (
        <Box sx={{ height: 'calc(100vh - 130px)' }}>
          <CustomCircularLoader />
        </Box>
      )}
      <iframe
        src={ORA_AI_CHATBOT_LINK}
        style={{
          width: '100%',
          height: 'calc(100vh - 130px)',
          border: 'none',
          display: isLoading ? 'none' : 'block',
        }}
        title={t('attributes.support.ora_ai')}
        onLoad={handleLoad}
      ></iframe>
    </StyledMainWrapper>
  );
};

export default OraAiChatbot;
