import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, Divider, Modal, styled, Typography } from '@mui/material';

import { HoverText, ImageWrapper } from '../../assets/commonStyled';
import {
  FAIL_STATUS,
  NO_CHECK_STATUS,
  PASS_STATUS,
} from '../../constants/Constants';
import { getAssetDetails } from '../../store/property/api';
import { resetAssetDetails } from '../../store/property/reducer';
import { formattedDate, loggedInUserRole } from '../../utils';
import CommonCarouselView from '../CommonComponents/CommonCarouselView';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import ImageView, { imageModalView } from '../CommonComponents/ImageView';
import StatusLabel from '../CommonComponents/StatusLabel';

const StyledHeaderBox = styled(Box)(({ value }) => ({
  padding: '7px 16px',
  backgroundColor: value ? 'rgba(255, 255, 255, 1)' : 'rgb(235, 235, 235, 0.2)',
  display: 'flex',
  justifyContent: 'space-between',
}));

const StyledContentBox = styled(Box)(() => ({
  display: 'flex',
  gap: '24px',
  padding: '16px 0 16px 16px',
  width: '50%',
}));

const StyledColumnBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

const Centered = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '100px',
  flexDirection: 'column',
  gap: '20px',
});

const KeyTypography = styled(Typography)(({ theme }) => ({}));
const ValueTypography = styled(Typography)(({ theme }) => ({
  paddingRight: '5px',
}));

const AssetDetails = ({ setOpen, setAssetId, assetId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { assetDetails } = useSelector((state) => state.property);
  const {
    equipment_type,
    equipment_class,
    product,
    label,
    location,
    status,
    is_active,
    asset_number,
    wall_order,
    barcode,
    make,
    model,
    size,
    base_date,
    installation_date,
    internal_note,
    id,
    other_category_name,
    documents,
    pressure,
  } = assetDetails.data;

  const { data: updatedAsset } = useSelector(
    (state) => state.property.updatedAsset
  );

  const [imgSrc, setImgSrc] = useState(new Map());
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openImageView, setOpenImageView] = useState(false);

  useEffect(() => {
    if (!assetDetails?.data) {
      dispatch(getAssetDetails({ assetId: assetId }));
    }

    return () => {
      dispatch(resetAssetDetails());
    };
  }, []);

  useEffect(() => {
    updatedAsset && dispatch(getAssetDetails({ assetId: id }));
  }, [updatedAsset]);

  useEffect(() => {
    if (imgSrc?.size === documents?.length) {
      const orderedImages = documents?.map((doc) =>
        imgSrc.get(doc?.document_uuid)
      );

      setImgSrc(orderedImages);
    }
  }, [documents, imgSrc]);

  const formatStatus = (status) => {
    const lowerCaseStatus =
      typeof status === 'string' ? status?.toLowerCase() : status;

    if (lowerCaseStatus === FAIL_STATUS) {
      return <StatusLabel label="Fail" color="#C54036" />;
    }
    if (lowerCaseStatus === PASS_STATUS) {
      return <StatusLabel label="Pass" color="#95C020" />;
    }
    if (lowerCaseStatus === NO_CHECK_STATUS) {
      return <StatusLabel label="No Check" color="#59366B" />;
    }

    if (lowerCaseStatus === true) {
      return <StatusLabel label="Yes" color="#59C3C3" />;
    }
    if (lowerCaseStatus === false) {
      return <StatusLabel label="No" color="#454545" />;
    }
  };

  const openImage = () => setOpenImageView(true);

  const addImageToMap = (uuid, url) => {
    setImgSrc((prevMap) => {
      const newMap = new Map(prevMap);

      newMap.set(uuid, url);

      return newMap;
    });
  };

  return assetDetails?.loading ? (
    <Box sx={{ paddingTop: '180px' }}>
      <CustomCircularLoader />
    </Box>
  ) : (
    <>
      <Box sx={{ overflowY: 'auto', height: 'calc(100vh - 170px)' }}>
        <StyledHeaderBox>
          <Typography variant="body1">
            {t('common.general_information')}
          </Typography>
          {loggedInUserRole() !== 'customer' && (
            <ModeEditOutlineOutlinedIcon
              onClick={() => {
                setOpen(true);
                setAssetId(id);
              }}
            />
          )}
        </StyledHeaderBox>
        <Divider />
        {/* Details */}
        <Box sx={{ display: 'flex', width: '100%' }}>
          {/* Left Side Content */}
          <StyledContentBox sx={{ width: '100%' }}>
            <StyledColumnBox sx={{ gap: 1, width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('attributes.property.assetGroup')}
                </KeyTypography>
                <ValueTypography variant="body2">
                  {equipment_type?.display_name ?? '-'}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('attributes.property.assetType')}
                </KeyTypography>
                <ValueTypography variant="body2">
                  {`${equipment_class?.name}` || '-'}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('attributes.property.assetVariant')}
                </KeyTypography>
                <Box>
                  <ValueTypography variant="body2">
                    {`${product?.display_name}` || '-'}
                  </ValueTypography>
                </Box>
              </Box>
              {/* <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('attributes.property.assetVariant')}
                </KeyTypography>
                <Box>
                  <ValueTypography variant="body2">
                    {`${product?.display_name}` || '-'}
                  </ValueTypography>
                </Box>
              </Box> */}
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('attributes.property.asset_name')}
                </KeyTypography>
                <Box>
                  <ValueTypography variant="body2">
                    {label ?? '-'}
                  </ValueTypography>
                </Box>
              </Box>
              {equipment_type?.is_pressure && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <KeyTypography minWidth="200px" variant="body1">
                    {t('attributes.property.pressureFieldWithUnit')}
                  </KeyTypography>
                  <ValueTypography variant="body2">
                    {`${pressure}` || '-'}
                  </ValueTypography>
                </Box>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('attributes.level')}
                </KeyTypography>
                <ValueTypography variant="body2">
                  {location ?? '-'}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('attributes.property.status')}
                </KeyTypography>
                <ValueTypography variant="body2">
                  {formatStatus(status?.name) ?? '-'}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('common.active')}
                </KeyTypography>
                <ValueTypography variant="body2">
                  {formatStatus(is_active) ?? '-'}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('attributes.property.assetNumber')}:
                </KeyTypography>
                <ValueTypography variant="body2">
                  {asset_number ?? '-'}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('attributes.property.walkOrder')}:
                </KeyTypography>
                <ValueTypography variant="body2">
                  {wall_order ?? '-'}
                </ValueTypography>
              </Box>
            </StyledColumnBox>
          </StyledContentBox>

          {/* Center Divider */}
          <Divider orientation="vertical" flexItem />

          {/* Right Side Content */}
          <StyledContentBox sx={{ width: '100%' }}>
            <StyledColumnBox sx={{ gap: 1, width: '100%' }}>
              <Box sx={{ display: 'flex' }}>
                <KeyTypography minWidth="148px" variant="body1">
                  {t('attributes.property.qr_code')}:
                </KeyTypography>
                <ValueTypography variant="body2">
                  {barcode ?? '-'}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <KeyTypography minWidth="148px" variant="body1">
                  {t('attributes.property.make')}:
                </KeyTypography>
                <ValueTypography variant="body2">{make ?? '-'}</ValueTypography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <KeyTypography minWidth="148px" variant="body1">
                  {t('attributes.property.model')}:
                </KeyTypography>
                <ValueTypography variant="body2">
                  {model ?? '-'}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <KeyTypography minWidth="148px" variant="body1">
                  {t('attributes.property.size')}:
                </KeyTypography>
                <ValueTypography variant="body2">{size ?? '-'}</ValueTypography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <KeyTypography minWidth="148px" variant="body1">
                  {t('attributes.property.manufacture_date')}:
                </KeyTypography>
                <ValueTypography variant="body2">
                  {formattedDate(base_date)}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <KeyTypography minWidth="148px" variant="body1">
                  {t('attributes.property.installation_date')}:
                </KeyTypography>
                <ValueTypography variant="body2">
                  {formattedDate(installation_date)}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <KeyTypography minWidth="148px" variant="body1">
                  {t('attributes.property.internal_note')}:
                </KeyTypography>
                <ValueTypography variant="body2">
                  {internal_note ?? '-'}
                </ValueTypography>
              </Box>
            </StyledColumnBox>
          </StyledContentBox>
        </Box>
        <Divider />
        <StyledHeaderBox>
          <Typography variant="body1">
            {t('attributes.work_order.photos')}
          </Typography>
        </StyledHeaderBox>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            padding: '16px',
            flexWrap: 'wrap',
            width: '40%',
          }}
        >
          {documents?.length ? (
            <CommonCarouselView
              totalItems={documents?.length}
              centerMode={true}
            >
              {documents?.map((doc, index) => (
                <ImageWrapper>
                  <ImageView
                    uuid={doc?.document_uuid}
                    addImageToMap={addImageToMap}
                  />
                  <HoverText
                    className="hoverText"
                    onClick={() => {
                      setCurrentIndex(index);
                      openImage();
                    }}
                  >
                    <Typography variant="body1">
                      {t('attributes.view')}
                    </Typography>
                  </HoverText>
                </ImageWrapper>
              ))}
            </CommonCarouselView>
          ) : (
            <>No Photo Uploaded</>
          )}
        </Box>
      </Box>

      {openImageView && (
        <Modal
          open={openImageView}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#000000D9',
            zIndex: '10000',
          }}
        >
          <>
            <CloseIcon
              style={{
                fill: 'white',
                height: '45px',
                width: '45px',
                position: 'absolute',
                top: '10px',
                right: '10px',
              }}
              onClick={() => setOpenImageView(false)}
            />
            <CommonCarouselView
              widthInPercentage={100}
              totalItems={documents?.length}
              showThumbs={true}
              arrowSize="16px"
              arrowWrapperSize="40px"
              arrowLeft="40px"
              arrowRight="40px"
              centerMode={true}
              isModal={true}
              currentIndex={currentIndex}
            >
              {imgSrc?.map((img, index) => (
                <React.Fragment key={index}>
                  {imageModalView(img)}
                </React.Fragment>
              ))}
            </CommonCarouselView>
          </>
        </Modal>
      )}
    </>
  );
};

export default AssetDetails;
