import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { Box, Typography } from '@mui/material';

import { DIVIDER_COLOR, SECONDARY } from '../../constants/Colors';
import { TEMPLATE_SET } from '../../constants/Constants';
import InvoiceData from '../../constants/invoice_template.json';
import POData from '../../constants/po_template.json';
import DefectQutoesData from '../../constants/quote_template.json';
import { SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH } from '../../constants/Typography';
import { snackbarToggle } from '../../store/CommonReducer';
import { editRestTemplates, getRestTemplates } from '../../store/template/api';
import {
  resetTemplateEdit,
  resetTemplateList,
} from '../../store/template/reducer';
import { View } from './ActionComponent';
import CustomButton from './CustomButton';
import CustomGridTable from './CustomGridTable';
import NoRecordFound from './NoDataPage/NoRecordFound';
import SwipeableDrawer from './SwipeableDrawer';
import { generatePDF as generatePDF1 } from './Templates/Template1/DownloadPDF';
import { PreviewPDF as PreviewPDF1 } from './Templates/Template1/PreviewPDF';
import { generatePDF as generatePDF2 } from './Templates/Template2/DownloadPDF';
import { PreviewPDF as PreviewPDF2 } from './Templates/Template2/PreviewPDF';
import { generatePDF as generatePDF3 } from './Templates/Template3/DownloadPDF';
import { PreviewPDF as PreviewPDF3 } from './Templates/Template3/PreviewPDF';

const TemplatesTable = ({ module, onLoadCallback = () => {} }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState(false);

  const { isLoading: listLoading, data: listData } = useSelector(
    (state) => state.template.listTemplate
  );
  const {
    isLoading: editLoading,
    data: editData,
    error,
  } = useSelector((state) => state.template.editTemplate);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRestTemplates({}));

    return () => {
      dispatch(resetTemplateList());
      dispatch(resetTemplateEdit());
    };
  }, []);

  useEffect(() => {
    if (editLoading !== null && !editLoading) {
      if (error) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: error?.message,
          })
        );
      } else {
        if (editData) {
          dispatch(getRestTemplates({}));
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: t('attributes.templates.default_update'),
            })
          );
        }
      }
    }
  }, [error, editLoading]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'template_name',
        headerName: t('attributes.templates.template_name'),
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
            {row?.template_name}
            {row?.isDefault && (
              <Box
                sx={{
                  background: DIVIDER_COLOR,
                  padding: '4px 8px',
                  borderRadius: '4px',
                }}
              >
                <Typography variant="body1">{t('common.default')}</Typography>
              </Box>
            )}
          </Box>
        ),
      },
      {
        field: 'created_at',
        headerName: t('attributes.technician.created_at'),
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'edit',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', columnGap: '8px' }}>
            <View
              onClick={() => {
                setOpen(row?.id);
              }}
            />
            {!row?.isDefault && (
              <CustomButton
                variant="outlined"
                sx={{
                  '&.MuiButton-root': {
                    minWidth: '24px',
                    height: '24px',
                    padding: '5px',
                  },
                }}
                onClick={() => {
                  setClicked(true);
                  dispatch(
                    editRestTemplates({
                      req: {
                        default_template: {
                          ...listData,
                          [module]: row?.id,
                        },
                      },
                    })
                  ).finally(() => {
                    setClicked(false);
                  });
                }}
              >
                <Typography variant="body2">
                  {t('common.set_as_default')}
                </Typography>
              </CustomButton>
            )}
          </Box>
        ),
      },
    ];

    return baseColumns;
  }, [listData]);

  const generatePDF = useCallback(
    (module, data) => {
      switch (open) {
        case 1:
          return generatePDF1(module, data);
        case 2:
          return generatePDF2(module, data);
        case 3:
          return generatePDF3(module, data);
      }
    },
    [open]
  );

  const rows = [
    {
      id: 1,
      template_name: 'Template 1',
      created_at: '29/08/2024 09:00 AM',
      isDefault: listData && listData[module] === 1,
    },
    {
      id: 2,
      template_name: 'Template 2',
      created_at: '29/08/2024 09:00 AM',
      isDefault: listData && listData[module] === 2,
    },
    {
      id: 3,
      template_name: 'Template 3',
      created_at: '29/08/2024 09:00 AM',
      isDefault: listData && listData[module] === 3,
    },
  ];
  const previewData =
    module === TEMPLATE_SET.INVOICE
      ? InvoiceData
      : module === TEMPLATE_SET.DEFECT_QUOTES
        ? DefectQutoesData
        : POData;

  return (
    <>
      <CustomGridTable
        columns={columns}
        rows={rows}
        paginationRequired={false}
        noData={<NoRecordFound />}
        isLoading={listLoading || clicked}
        checkboxSelection={false}
      />
      <SwipeableDrawer
        open={open}
        bgColor={SECONDARY}
        onClose={() => {
          setOpen(false);
        }}
        width={SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH}
        title={
          module === TEMPLATE_SET.INVOICE
            ? 'INV-01'
            : module === TEMPLATE_SET.DEFECT_QUOTES
              ? 'Q-01'
              : 'PO-01'
        }
        footerButton={[
          <CustomButton
            text={t('attributes.download')}
            color="inherit"
            disabled={false}
            startIcon={<DownloadOutlinedIcon />}
            onClick={() => {
              generatePDF(module, previewData);
            }}
          />,
        ]}
      >
        {open === 1 ? (
          <PreviewPDF1 module={module} previewData={previewData} />
        ) : open === 2 ? (
          <PreviewPDF2 module={module} previewData={previewData} />
        ) : (
          <PreviewPDF3 module={module} previewData={previewData} />
        )}
      </SwipeableDrawer>
    </>
  );
};

export default TemplatesTable;
