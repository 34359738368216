import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';

import { navigate } from '../../../App';
import { DIVIDER_COLOR, SECONDARY } from '../../../constants/Colors';
import { ROUTE_NAVIGATE_CONST } from '../../../constants/Constants';
import { Validation } from '../../../constants/FieldValidationMsg';
import { getUserRole } from '../../../hoc/Permission';
import { snackbarToggle } from '../../../store/CommonReducer';
import {
  editCompany,
  updateCompanyTaxConfiguration,
} from '../../../store/company/api';
import { resetEdit, resetUpdateTax } from '../../../store/company/reducer';
import {
  getRedirectURL,
  loggedInUserDetail,
  showHideTopbarLoader,
} from '../../../utils';
import CustomTextField from '../../CommonComponents/CustomTextField';
import MainWrapper from '../../CommonComponents/MainWrapper';
import StyledMainWrapper from '../../CommonComponents/StyledMainWrapper';

const TaxConfiguration = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = loggedInUserDetail();

  const { control, handleSubmit, setValue, reset, watch, trigger } = useForm();
  const watchedFields = watch();

  const { isLoading, company } = useSelector((state) => state.company.edit);
  const { isLoading: isTaxUpdatedLoading, data } = useSelector(
    (state) => state.company.updateTax
  );

  const [isConfigurationChanged, setIsConfigurationChanged] = useState(false);
  const [taxConfigurationData, setTaxConfigurationData] = useState();

  useEffect(() => {
    if (user?.company_uuid) {
      dispatch(editCompany(user?.company_uuid));
    }

    return () => {
      dispatch(resetEdit());
      dispatch(resetUpdateTax());
    };
  }, []);

  useEffect(() => {
    showHideTopbarLoader(isLoading, dispatch);
    if (company) {
      reset({
        taxName: company?.tax_type || '',
        taxValue: company?.tax_value || '',
      });
    }
  }, [isLoading, company]);

  useEffect(() => {
    showHideTopbarLoader(isTaxUpdatedLoading, dispatch);

    if (!isTaxUpdatedLoading) {
      if (data) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: data?.message,
          })
        );
      }
    }
  }, [isTaxUpdatedLoading, data]);

  useEffect(() => {
    const isTaxConfigurationChanged = taxConfigurationData
      ? taxConfigurationData?.taxName !== watchedFields?.taxName ||
        Number(taxConfigurationData?.taxValue) !==
          Number(watchedFields?.taxValue)
      : company?.tax_type !== watchedFields?.taxName ||
        company?.tax_value !== Number(watchedFields?.taxValue);

    setIsConfigurationChanged(isTaxConfigurationChanged);
  }, [watchedFields, company]);

  const onSubmit = (data) => {
    setTaxConfigurationData(data);
    const req = {
      tax_type: data?.taxName,
      tax_value: Number(data?.taxValue),
    };

    dispatch(
      updateCompanyTaxConfiguration({
        uuid: user?.company_uuid,
        data: req,
      })
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: DIVIDER_COLOR,
        height: 'inherit',
      }}
    >
      <MainWrapper
        defaultPadding="0px 0px 16px 0px"
        title={t('attributes.configuration.title')}
        variant="body1"
        isStep={true}
        step={t('attributes.configuration.tax.title')}
        handleDefaultStep={() => {
          navigate(
            getRedirectURL()[getUserRole()][ROUTE_NAVIGATE_CONST.CONFIGURATION]
          );
        }}
      ></MainWrapper>
      <StyledMainWrapper>
        <Box
          sx={{
            backgroundColor: SECONDARY,
            borderRadius: '8px',
          }}
        >
          <Box sx={{ padding: '16px' }}>
            <Stack spacing={2} direction="column">
              <Typography variant="body2">
                {
                  t('attributes.configuration.tax.taxNameTitle')?.split(
                    '{taxName}'
                  )[0]
                }
                <Typography variant="body1" component="span">
                  {t('attributes.configuration.tax.taxName')}
                </Typography>
                {
                  t('attributes.configuration.tax.taxNameTitle')?.split(
                    '{taxName}'
                  )[1]
                }
              </Typography>
              <Controller
                name="taxName"
                control={control}
                rules={{
                  required: `${t('attributes.configuration.tax.taxName')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    label={t('attributes.configuration.tax.taxName')}
                    sx={{ width: '362px' }}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      trigger('taxName');
                    }}
                    helperText={error ? error.message : ''}
                    error={!!error}
                    onClear={() => setValue(name, '')}
                    disabled={isLoading}
                    isRequired={true}
                  />
                )}
              />
            </Stack>
            <Stack spacing={2} direction="column" sx={{ paddingTop: '16px' }}>
              <Typography variant="body2">
                {
                  t('attributes.configuration.tax.taxValueTitle')?.split(
                    '{taxValue}'
                  )[0]
                }
                <Typography variant="body1" component="span">
                  {t('attributes.configuration.tax.taxValue')}
                </Typography>
                {
                  t('attributes.configuration.tax.taxValueTitle')?.split(
                    '{taxValue}'
                  )[1]
                }
              </Typography>
              <Controller
                name="taxValue"
                control={control}
                rules={{
                  required: `${t('attributes.configuration.tax.taxValue')} ${Validation.general.required}`,
                  validate: (value) => {
                    if (!/^\d+(\.\d+)?$/.test(value)) {
                      return `${t('attributes.configuration.tax.taxValue')} must be a valid number`;
                    }
                    if (parseFloat(value) < 0 || parseFloat(value) > 100) {
                      return `${t('attributes.configuration.tax.taxValue')} must be between 0 and 100`;
                    }

                    return true;
                  },
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    label={t('attributes.configuration.tax.taxValue')}
                    sx={{ width: '362px' }}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      trigger('taxValue');
                    }}
                    helperText={error ? error.message : ''}
                    error={!!error}
                    onClear={() => setValue(name, '')}
                    disabled={isLoading}
                    isRequired={true}
                    fixedEndAdornment="%"
                  />
                )}
              />
            </Stack>
          </Box>
          <Divider />
          <Box
            sx={{
              padding: '8px 16px 8px 8px',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              disabled={
                isLoading || isTaxUpdatedLoading || !isConfigurationChanged
              }
              onClick={handleSubmit(onSubmit)}
            >
              {t('attributes.save')}
            </Button>
          </Box>
        </Box>
      </StyledMainWrapper>
    </Box>
  );
};

export default TaxConfiguration;
