import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { Undermaintenance } from '../../assets/images';

const UnderMaintenance = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 300px',
        height: '100vh',
        background: 'white',
        textAlign: 'center',
      }}
    >
      <img
        src={Undermaintenance}
        alt="Undermaintenance"
        style={{ height: '235px', width: '235px', objectFit: 'contain' }}
      />
      <Typography variant="h5">
        {t('errorPage.underMaintenanceTitle')}
      </Typography>
      <Typography variant="h1" fontWeight={400}>
        {t('errorPage.underMaintenanceDescription')}
      </Typography>
    </Box>
  );
};
export default UnderMaintenance;
