import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Document,
  Image,
  Page,
  pdf,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { uniqueId } from 'lodash';
import moment from 'moment';

import { DarkLogo } from '../../../assets/images';
import {
  PRIMARY,
  RADIO_GREY_COLOR,
  SECONDARY,
  WARNING,
} from '../../../constants/Colors';
import {
  ASSETS_STATUS_REPORT_LABEL,
  DATE_FORMAT,
  INSPECTION_FORM_RADIO,
  RADIO_DEFAULT,
  RADIO_GREY,
  RADIO_RED,
  SCHEDULE_BOARD_MONTH,
} from '../../../constants/Constants';
import { formatDate } from '../../../utils';

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 30,
    fontSize: 11,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerCell: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerCellTitle: {
    minWidth: '100px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerRow: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
  },
  sectionTitle: {
    marginTop: 24,
    fontWeight: 'bold',
    fontSize: 11,
  },
  table: {
    marginTop: '24p',
    width: 'auto',
    // marginVertical: 10,
    border: '1px solid #ececec',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: '1px solid #ccc',
  },
  tableRowHeader: {
    backgroundColor: '#e0e0e0',
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: 11,
    border: '1px solid #ececec',
    height: '100%',
    flex: 1,
    padding: '8px',
  },
  tableCell: {
    flex: 1,
    fontSize: 11,
    padding: '8px',
    border: '1px solid #ececec',
  },
  radioCircle: {
    width: 12,
    height: 12,
    borderRadius: 6,
    borderWidth: 1,
    marginRight: 4,
    position: 'relative',
  },
  radioLabel: {
    marginRight: 20,
  },
  textArea: {
    marginVertical: 5,
    lineHeight: 1.5,
  },
  inspectionFormContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '8px 8px 0px 16px',
  },
  inspectionForm: {
    maxWidth: '250px',
  },
  inspectionFormMarginTop: {
    marginTop: '8px',
  },
  inspectionFormTitle: {
    fontWeight: 'bold',
  },
  radioGroup: {
    display: 'flex',
    marginRight: '8px',
    gap: '4px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  radioContainer: {
    height: '12px',
    width: '12px',
    borderRadius: '6px',
    border: `1px solid black`,
    backgroundColor: SECONDARY,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radio: {
    width: '8px',
    height: '8px',
    borderRadius: '4px',
  },
  radioDefault: {
    backgroundColor: PRIMARY,
  },
  radioRed: { backgroundColor: WARNING },
  radioGrey: { backgroundColor: RADIO_GREY_COLOR },
  logo: {
    height: 68,
    objectFit: 'contain',
    maxWidth: 150,
  },
  bold: {
    fontWeight: 'bold',
  },
});

// Function to render radio button with dynamic color
const RadioButton = ({ color, label }) => (
  <View style={styles.radioGroup}>
    <View
      style={[
        styles.radioContainer,
        color === RADIO_DEFAULT && { borderColor: PRIMARY },
        color === RADIO_RED && { borderColor: WARNING },
        color === RADIO_GREY && { borderColor: RADIO_GREY_COLOR },
      ]}
    >
      {color === RADIO_DEFAULT && (
        <View style={[styles.radio, styles.radioDefault]} />
      )}
      {color === RADIO_RED && <View style={[styles.radio, styles.radioRed]} />}
      {color === RADIO_GREY && (
        <View style={[styles.radio, styles.radioGrey]} />
      )}
    </View>
    <Text style={styles.bold}>{label}</Text>
  </View>
);

const MyDocument = ({
  data,
  property,
  asset,
  work_order_name,
  frequency,
  company,
}) => {
  const { t } = useTranslation();

  return (
    <Document>
      <Page style={styles.page}>
        {/* Header */}
        <View style={styles.headerContainer}>
          <View style={styles.header}>
            <View style={styles.headerRow}>
              <View style={styles.headerCell}>
                <Text style={[styles.bold, styles.headerCellTitle]}>
                  {t('attributes.customer.customer')} {t('attributes.name')}:
                </Text>
                <Text>{property?.customer || '-'}</Text>
              </View>
              <View style={styles.headerCell}>
                <Text style={[styles.bold, styles.headerCellTitle]}>
                  {t('attributes.property.property')} {t('attributes.name')}:
                </Text>
                <Text>{property?.property_name || '-'}</Text>
              </View>
              <View style={styles.headerCell}>
                <Text
                  style={[
                    styles.headerCellTitle,
                    { maxWidth: '250px' },
                    styles.bold,
                  ]}
                >
                  {t('attributes.address')}:
                </Text>
                <Text style={{ maxWidth: '300px' }}>
                  {property?.address || ''},
                </Text>
              </View>
              <View style={styles.headerCell}>
                <Text
                  style={[
                    styles.headerCellTitle,
                    { maxWidth: '250px' },
                    styles.bold,
                  ]}
                ></Text>
                <Text>
                  {property?.city ? `${property?.city}, ` : ''}
                  {property?.state ? `${property?.state}, ` : ''}{' '}
                  {property?.zip_code || ''}
                </Text>
              </View>
              <View style={styles.headerCell}>
                <Text style={[styles.bold, styles.headerCellTitle]}>
                  {t('attributes.work_order.work_order_name')}:
                </Text>
                <Text>{work_order_name || '-'}</Text>
              </View>
              <View style={styles.headerCell}>
                <Text style={[styles.bold, styles.headerCellTitle]}>
                  {t('attributes.property.frequency')}:
                </Text>
                <Text>{frequency || '-'}</Text>
              </View>
            </View>
          </View>
          <Image src={company?.logo_url ?? DarkLogo} style={styles.logo} />
        </View>

        {/* Table */}
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableRowHeader]}>
            <Text style={[styles.tableHeader, { minWidth: '250px' }]}>
              {t('attributes.property.asset')}/{t('attributes.location')}
            </Text>
            <Text style={[styles.tableHeader, { textAlign: 'center' }]}>
              {t('attributes.reports.result')}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableCell, { minWidth: '250px', gap: '8px' }]}>
              <Text style={styles.bold}>{asset?.asset?.label || '-'}</Text>
              <Text>{asset?.asset?.location || '-'}</Text>
              <Text>
                {t('attributes.last')} {t('attributes.property.service')}:{' '}
                {formatDate(asset?.inspected_time, SCHEDULE_BOARD_MONTH)}
              </Text>
            </View>

            <Text
              style={[
                styles.tableCell,
                {
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  paddingTop: '28px',
                },
              ]}
            >
              {ASSETS_STATUS_REPORT_LABEL[asset?.status?.toLowerCase()]}
            </Text>
          </View>
          {data?.map((row) => (
            <View style={styles.inspectionFormContainer} key={uniqueId()}>
              {/* Form Details */}
              <View style={styles.inspectionForm}>
                <Text style={styles.inspectionFormTitle}>{row.title}</Text>
                <Text style={styles.inspectionFormMarginTop}>
                  {row.description}
                </Text>
                <Text style={styles.inspectionFormMarginTop}>
                  {row.comment}
                </Text>
                <Text style={styles.inspectionFormMarginTop}>{row.result}</Text>
              </View>
              {/* Radio CheckBox */}
              <View style={styles.radioGroup}>
                <RadioButton
                  color={
                    row.radio_checked === INSPECTION_FORM_RADIO.DEFAULT &&
                    RADIO_DEFAULT
                  }
                  label="Pass"
                />
                <RadioButton
                  color={
                    row.radio_checked === INSPECTION_FORM_RADIO.RED && RADIO_RED
                  }
                  label="Fail"
                />
                <RadioButton
                  color={
                    row.radio_checked === INSPECTION_FORM_RADIO.GREY &&
                    RADIO_GREY
                  }
                  label="No Check"
                />
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export { MyDocument };

// PDF generation function
const generatePDF = async (
  data,
  property,
  asset,
  work_order_name,
  frequency,
  company
) => {
  const blob = await pdf(
    <MyDocument
      data={data}
      property={property}
      asset={asset}
      work_order_name={work_order_name}
      frequency={frequency}
      company={company}
    />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.download = `${moment().format(DATE_FORMAT)}-Asset-Inspection-Report`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default generatePDF;
