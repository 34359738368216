import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { CITY } from '../actionPrefix';
import { axiosInstanceCompany } from '../axios';

export const getCityList = createAsyncThunk(
  CITY.GET_CITY_LIST,
  async ({ include_ids, active, search }, { rejectWithValue }) => {
    const params = new URLSearchParams();

    if (include_ids !== undefined || include_ids !== null)
      params.append('include_ids', include_ids);
    if (active !== undefined || active !== null)
      params.append('include_inactive', active);
    if (search) params.append('search_term', search);

    params.append('search_type', 'city');

    try {
      const response =
        await axiosInstanceCompany.get(`${APIs.CITY.CITY_LIST}?${params.toString()}
        `);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCity = createAsyncThunk(
  CITY.UPDATE_CITY,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceCompany.patch(
        APIs.CITY.UPDATE_CITY.replace('{id}', id),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createCity = createAsyncThunk(
  CITY.CREATE_CITY,
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceCompany.post(
        APIs.CITY.CREATE_CITY,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
