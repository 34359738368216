import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Stack, Typography } from '@mui/material';

import { DIVIDER_COLOR } from '../../../constants/Colors';
import { ROUTE_NAVIGATE_CONST } from '../../../constants/Constants';
import { getRedirectURL, loggedInUserRole } from '../../../utils';
import MainWrapper from '../MainWrapper';

export const ExportData = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userRole = loggedInUserRole();

  const handleDefaultStep = () => {
    navigate(getRedirectURL()[userRole][ROUTE_NAVIGATE_CONST.DATA_MANAGEMENT]);
  };

  const TemplateDataWrapper = (
    <>
      <Box sx={{ backgroundColor: DIVIDER_COLOR, width: '100%' }}>
        <MainWrapper
          defaultPadding="0px 0px 16px 0px"
          title={t('attributes.reports.dataManagement.title')}
          variant="body1"
          isStep={true}
          step={t('attributes.reports.dataManagement.exportData')}
          handleDefaultStep={handleDefaultStep}
        ></MainWrapper>
      </Box>
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '87vh',
        }}
      >
        <Typography>Coming soon...</Typography>
      </Stack>
    </>
  );

  return <>{TemplateDataWrapper}</>;
};
