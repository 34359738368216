import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const Ckeditor = ({ setData = () => {}, data = '', name = '', ...rest }) => (
  <CKEditor
    name={name}
    editor={ClassicEditor}
    data={data}
    config={{
      licenseKey: 'GPL',
      toolbar: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        '|',
        'numberedList',
        'bulletedList',
        '|',
        'link',
      ],
    }}
    onChange={(event, editor) => {
      const editorData = editor.getData();
      setData(editorData);
    }}
    {...rest}
  />
);

export default Ckeditor;
