import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, styled, Typography } from '@mui/material';

import { DarkLogo } from '../../../../assets/images';
import {
  DIVIDER_COLOR,
  PRIMARY,
  SECONDARY,
} from '../../../../constants/Colors';
import {
  TAX_NAME,
  TAX_VALUE,
  TEMPLATE_SET,
} from '../../../../constants/Constants';
import {
  calculateTaxPercentage,
  calculateTotal,
  formatAddress,
  formatPriceWithDecimalValue,
  formattedDate,
  templateExtractData,
} from '../../../../utils';
import CustomCircularLoader from '../../CustomLoader';

const Container = styled(Box)(() => ({
  backgroundColor: 'white',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const PreviewPDF = ({ module, previewData }) => {
  const { t } = useTranslation();

  const {
    companyData,
    isLoading,
    moduleDetail,
    propertyData,
    subTotal,
    taxAmount,
    isFromServiceQuotes,
  } = templateExtractData(module, previewData);

  // const tax = subTotal * 0.0625;
  const total = subTotal;
  const totalTax = taxAmount || 0;

  const TotalCountRowsUI = (
    <>
      <tr>
        <td
          colSpan={3}
          style={{
            padding: '8px',
            textAlign: 'right',
            border: 'none',
          }}
        >
          <Typography variant="body1">
            {t('common.capital.subtotal')}
          </Typography>
        </td>
        <td
          style={{
            padding: '8px',
            textAlign: 'right',
          }}
        >
          <Typography variant="body2">
            {calculateTotal({ total: total, type: 'subtotal' })}
          </Typography>
        </td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td
          style={{
            padding: '8px',
            textAlign: 'right',
            borderBottom: '1px solid #EBEBEB',
            minWidth: '90px',
          }}
        >
          <Typography variant="body1">
            {module === TEMPLATE_SET?.INVOICE
              ? `${TAX_NAME} ${
                  total && totalTax
                    ? calculateTaxPercentage(total, totalTax)
                    : 0
                }%`
              : companyData?.tax_type && companyData?.tax_value
                ? `${companyData.tax_type} ${companyData.tax_value}%`
                : `${TAX_NAME} ${TAX_VALUE}%`}
          </Typography>
        </td>
        <td
          style={{
            padding: '8px',
            textAlign: 'right',
            borderBottom: '1px solid #EBEBEB',
            minWidth: '90px',
          }}
        >
          <Typography variant="body2">
            {module === TEMPLATE_SET?.INVOICE
              ? totalTax
              : calculateTotal({
                  total: total,
                  type: 'gst',
                  taxPercentage: companyData?.tax_value,
                })}
          </Typography>
        </td>
      </tr>
      <tr>
        <td
          colSpan={3}
          style={{
            padding: '8px',
            textAlign: 'right',
            border: 'none',
          }}
        >
          <Typography variant="body1">{t('common.total')}</Typography>
        </td>
        <td
          style={{
            padding: '8px',
            textAlign: 'right',
          }}
        >
          <Typography variant="body1">
            {module === TEMPLATE_SET?.INVOICE
              ? calculateTotal({
                  total: total,
                  taxAmount: totalTax,
                  type: 'total',
                })
              : calculateTotal({
                  total: total,
                  type: 'total',
                  taxPercentage: companyData?.tax_value,
                })}
          </Typography>
        </td>
      </tr>
    </>
  );

  return isLoading === true ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <CustomCircularLoader />
    </Box>
  ) : (
    <Container>
      {/* Header */}
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '20px',
              }}
            >
              <Box
                sx={{ textAlign: 'left' }}
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
              >
                {module === TEMPLATE_SET?.INVOICE ? (
                  <>
                    {' '}
                    <Typography variant="body1">
                      {t('attributes.invoice.invoice_number')}:
                    </Typography>
                    <Typography variant="body1">
                      {t('attributes.invoice.issued_date')}:
                    </Typography>
                    <Typography variant="body1">
                      {t('attributes.invoice.invoice_due')}:
                    </Typography>
                  </>
                ) : module === TEMPLATE_SET?.DEFECT_QUOTES ? (
                  <>
                    <Typography variant="body1">
                      {t('attributes.work_order.quote')}:
                    </Typography>
                    <Typography variant="body1">
                      {t('attributes.work_order.quote_date')}:
                    </Typography>
                    <Typography variant="body1">
                      {t('attributes.work_order.quote_due')}:
                    </Typography>
                    <Typography variant="body1">
                      {t('attributes.work_order.ref')} #:
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="body1">
                      {t('attributes.work_order.purchase_order')}:
                    </Typography>
                    <Typography variant="body1">
                      {t('attributes.purchase_order.purchase_order_date')}:
                    </Typography>
                  </>
                )}
              </Box>

              <Box
                sx={{ textAlign: 'left' }}
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
              >
                {module === TEMPLATE_SET?.INVOICE ? (
                  <>
                    {' '}
                    <Typography variant="body2">
                      {moduleDetail?.account_partner_invoice_number ?? '-'}
                    </Typography>
                    <Typography variant="body2">
                      {formattedDate(moduleDetail?.created_at) ?? '-'}
                    </Typography>
                    <Typography variant="body2">
                      {formattedDate(moduleDetail?.due_date) ?? '-'}
                    </Typography>
                  </>
                ) : module === TEMPLATE_SET?.DEFECT_QUOTES ? (
                  <>
                    <Typography variant="body2">
                      Q-{moduleDetail?.quotation_id || '-'}
                    </Typography>
                    <Typography variant="body2">
                      {formattedDate(moduleDetail?.quote_date) ?? '-'}
                    </Typography>
                    <Typography variant="body2">
                      {formattedDate(moduleDetail?.due_date) ?? '-'}
                    </Typography>
                    <Typography variant="body2">-</Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="body2">
                      {moduleDetail?.poid}
                    </Typography>
                    <Typography variant="body2">
                      {formattedDate(moduleDetail?.created_at) ?? '-'}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>

            <img
              src={companyData?.logo_url || DarkLogo}
              alt="Company Logo"
              style={{
                height: '68px',
                width: 'auto',
                objectFit: 'contain',
                maxWidth: '150px',
              }}
            />
          </Box>

          <Box
            sx={{
              paddingTop: '52px',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '20px',
            }}
          >
            <Box
              sx={{
                minWidth: '300px',
                padding: '16px',
                border: `1px solid ${DIVIDER_COLOR}`,
                borderRadius: '4px',
                bgcolor: 'var(--box-gray)',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'baseline',
                  gap: '8px',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ paddingBottom: '8px', minWidth: '80px' }}
                >
                  {module === TEMPLATE_SET.INVOICE
                    ? t('attributes.invoice.invoice_by')
                    : module === TEMPLATE_SET?.DEFECT_QUOTES
                      ? t('attributes.work_order.quote_by')
                      : t('attributes.purchase_order.purchase_order_from')}
                </Typography>
                <Typography variant="body2" sx={{ maxWidth: '110px' }}>
                  {companyData?.company_name}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'baseline',
                  gap: '8px',
                }}
              >
                <Typography
                  variant="body1"
                  minWidth={
                    module === TEMPLATE_SET.PURCHASE_ORDER ? '146px' : '80px'
                  }
                >
                  {t('attributes.address')}
                </Typography>
                <Typography variant="body2">
                  {companyData?.address}
                  <br />
                  {formatAddress(
                    companyData?.city?.trim() || '',
                    companyData?.state,
                    companyData?.zip_code
                  )}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                minWidth: '300px',
                padding: '16px',
                border: `1px solid ${DIVIDER_COLOR}`,
                borderRadius: '4px',
                bgcolor: 'var(--box-gray)',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'baseline',
                  gap: '8px',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ paddingBottom: '8px', minWidth: '80px' }}
                >
                  {module === TEMPLATE_SET.INVOICE
                    ? t('attributes.invoice.invoice_to')
                    : module === TEMPLATE_SET?.DEFECT_QUOTES
                      ? t('attributes.work_order.quote_to')
                      : t('attributes.purchase_order.purchase_order_to')}
                </Typography>
                <Typography variant="body2" sx={{ maxWidth: '120px' }}>
                  {module === TEMPLATE_SET.INVOICE
                    ? moduleDetail?.contact_name
                    : module === TEMPLATE_SET?.DEFECT_QUOTES
                      ? propertyData?.customer
                      : moduleDetail?.supplier?.name}{' '}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'baseline',
                  gap: '8px',
                }}
              >
                <Typography
                  variant="body1"
                  minWidth={
                    module === TEMPLATE_SET.PURCHASE_ORDER ? '130px' : '80px'
                  }
                >
                  {t('attributes.address')}
                </Typography>
                <Typography variant="body2">
                  {module === TEMPLATE_SET?.PURCHASE_ORDER
                    ? moduleDetail?.supplier?.address
                    : moduleDetail?.address}
                  <br />{' '}
                  {[
                    module === TEMPLATE_SET?.PURCHASE_ORDER &&
                      moduleDetail?.supplier?.city,
                    module === TEMPLATE_SET?.PURCHASE_ORDER &&
                      moduleDetail?.supplier?.state,
                    module === TEMPLATE_SET?.PURCHASE_ORDER &&
                      moduleDetail?.supplier?.postcode,
                  ]
                    .filter(Boolean) // Removes null, undefined, or empty values
                    .join(', ')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Property Table */}
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              marginTop: '16px',
            }}
          >
            <Box
              sx={{
                padding: '16px',
                border: `1px solid ${DIVIDER_COLOR}`,
                bgcolor: 'var(--box-gray)',
                borderRadius: '4px',
              }}
            >
              {propertyData?.property_name && (
                <Box sx={{ display: 'flex', gap: '4px' }}>
                  <Typography variant="body1">
                    {' '}
                    {t('attributes.property.propertyName')}:
                  </Typography>
                  <Typography variant="body2">
                    {propertyData?.property_name}
                  </Typography>
                </Box>
              )}{' '}
              {module === TEMPLATE_SET.PURCHASE_ORDER && (
                <Box
                  sx={{ gap: '8px', display: 'flex', alignItems: 'baseline' }}
                >
                  <Typography variant="body1">
                    {' '}
                    {t('attributes.purchase_order.delivery_instruction')}:
                  </Typography>
                  <Typography variant="body2">
                    {moduleDetail?.delivery_instruction ?? ''}
                  </Typography>
                </Box>
              )}
              {moduleDetail?.property?.property_name && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '20px',
                  }}
                >
                  <Box
                    sx={{ textAlign: 'left' }}
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                  >
                    <Typography variant="body1">
                      {t('attributes.property.propertyName')}:
                    </Typography>
                    <Typography variant="body1">
                      {t('attributes.invoice.work_order_name')}:
                    </Typography>
                  </Box>

                  <Box
                    sx={{ textAlign: 'left' }}
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                  >
                    <Typography variant="body2">
                      {moduleDetail?.property?.property_name}
                    </Typography>
                    <Typography variant="body2">
                      {moduleDetail?.work_order_data?.work_order_name}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>

            <Box sx={{ paddingTop: '30px' }}>
              {module === TEMPLATE_SET?.INVOICE ? (
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          padding: '8px',
                          border: '1px solid #EBEBEB',
                          borderRight: '0px',
                          textAlign: 'left',
                          backgroundColor: PRIMARY,
                          color: SECONDARY,
                        }}
                      >
                        <Typography variant="body1">
                          {t(
                            'attributes.productCatalogue.product'
                          )?.toUpperCase()}
                        </Typography>
                      </th>
                      <th
                        style={{
                          padding: '8px',
                          border: '1px solid #EBEBEB',
                          borderLeft: '0px',
                          borderRight: '0px',
                          textAlign: 'center',
                          backgroundColor: PRIMARY,
                          color: SECONDARY,
                        }}
                      >
                        <Typography variant="body1">
                          {t('common.qty')}
                        </Typography>
                      </th>
                      <th
                        style={{
                          padding: '8px',
                          border: '1px solid #EBEBEB',
                          borderLeft: '0px',
                          borderRight: '0px',
                          textAlign: 'right',
                          backgroundColor: PRIMARY,
                          color: SECONDARY,
                        }}
                      >
                        <Typography variant="body1">
                          {t('common.unit_price')}
                        </Typography>
                      </th>
                      <th
                        style={{
                          padding: '8px',
                          border: '1px solid #EBEBEB',
                          borderLeft: '0px',
                          textAlign: 'right',
                          backgroundColor: PRIMARY,
                          color: SECONDARY,
                        }}
                      >
                        <Typography variant="body1">
                          {t('common.amount')}
                        </Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {moduleDetail?.items?.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: `${index & 1 && 'var(--box-gray)'}`,
                        }}
                      >
                        <td
                          style={{
                            padding: '8px',
                            border: '1px solid #EBEBEB',
                            textAlign: 'left',
                          }}
                        >
                          <Typography variant="body1">
                            {item?.product_name}
                          </Typography>
                          <Typography variant="body1">
                            {item?.meta_data?.defect_title || ''}
                          </Typography>
                          <Typography variant="body2">
                            {item?.meta_data?.defect_description || ''}
                          </Typography>
                        </td>
                        <td
                          style={{
                            padding: '8px',
                            border: '1px solid #EBEBEB',
                            textAlign: 'center',
                          }}
                        >
                          {item.qty}
                        </td>
                        <td
                          style={{
                            padding: '8px',
                            border: '1px solid #EBEBEB',
                            textAlign: 'right',
                          }}
                        >
                          <Typography variant="body2">
                            {item.price?.toFixed(2)}
                          </Typography>
                        </td>
                        <td
                          style={{
                            padding: '8px',
                            border: '1px solid #EBEBEB',
                            textAlign: 'right',
                          }}
                        >
                          <Typography variant="body2">
                            {formatPriceWithDecimalValue(
                              item.qty * item.price,
                              true
                            )}
                          </Typography>
                        </td>
                      </tr>
                    ))}

                    {TotalCountRowsUI}
                  </tbody>
                </table>
              ) : module === TEMPLATE_SET?.DEFECT_QUOTES ? (
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          padding: '8px',
                          border: '1px solid #EBEBEB',
                          borderRight: '0px',
                          textAlign: 'left',
                          backgroundColor: PRIMARY,
                          color: SECONDARY,
                        }}
                      >
                        <Typography variant="body1">
                          {t('common.desciption')}
                        </Typography>
                      </th>
                      <th
                        style={{
                          padding: '8px',
                          border: '1px solid #EBEBEB',
                          borderLeft: '0px',
                          borderRight: '0px',
                          textAlign: 'center',
                          backgroundColor: PRIMARY,
                          color: SECONDARY,
                        }}
                      >
                        <Typography variant="body1">
                          {t('common.qty')}
                        </Typography>
                      </th>
                      <th
                        style={{
                          padding: '8px',
                          border: '1px solid #EBEBEB',
                          textAlign: 'right',
                          borderLeft: '0px',
                          borderRight: '0px',
                          backgroundColor: PRIMARY,
                          color: SECONDARY,
                        }}
                      >
                        <Typography variant="body1">
                          {t('common.unit_price')}
                        </Typography>
                      </th>
                      <th
                        style={{
                          padding: '8px',
                          border: '1px solid #EBEBEB',
                          textAlign: 'right',
                          borderLeft: '0px',
                          backgroundColor: PRIMARY,
                          color: SECONDARY,
                        }}
                      >
                        <Typography variant="body1">
                          {t('common.amount')}
                        </Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {moduleDetail?.items?.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: `${index & 1 && 'var(--box-gray)'}`,
                        }}
                      >
                        <td
                          style={{
                            padding: '8px',
                            border: '1px solid #EBEBEB',
                            textAlign: 'left',
                          }}
                        >
                          <Typography variant="body1">
                            {item.product_catalogue?.product_name}
                          </Typography>
                          <Typography variant="body2">
                            {item.asset_details?.label}
                          </Typography>
                          <Typography variant="body1">
                            {item?.asset_defects?.defect_title}
                          </Typography>
                          <Typography variant="body2">
                            {item.asset_defects?.defect_description}
                          </Typography>
                        </td>
                        <td
                          style={{
                            padding: '8px',
                            border: '1px solid #EBEBEB',
                            textAlign: 'center',
                          }}
                        >
                          {item.qty}
                        </td>
                        <td
                          style={{
                            padding: '8px',
                            border: '1px solid #EBEBEB',
                            textAlign: 'right',
                          }}
                        >
                          <Typography variant="body2">
                            {formatPriceWithDecimalValue(item.sell_price, true)}
                          </Typography>
                        </td>
                        <td
                          style={{
                            padding: '8px',
                            border: '1px solid #EBEBEB',
                            textAlign: 'right',
                          }}
                        >
                          <Typography variant="body2">
                            {formatPriceWithDecimalValue(
                              item.qty * item.sell_price,
                              true
                            )}
                          </Typography>
                        </td>
                      </tr>
                    ))}
                    {TotalCountRowsUI}
                  </tbody>
                </table>
              ) : (
                <>
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            padding: '8px',
                            border: '1px solid #EBEBEB',
                            borderRight: '0px',
                            textAlign: 'left',
                            backgroundColor: PRIMARY,
                            color: SECONDARY,
                          }}
                        >
                          <Typography variant="body1">
                            {t('common.desciption')}
                          </Typography>
                        </th>
                        <th
                          style={{
                            padding: '8px',
                            border: '1px solid #EBEBEB',
                            textAlign: 'center',
                            borderLeft: '0px',
                            borderRight: '0px',
                            backgroundColor: PRIMARY,
                            color: SECONDARY,
                          }}
                        >
                          <Typography variant="body1">
                            {t('common.qty')}
                          </Typography>
                        </th>
                        <th
                          style={{
                            padding: '8px',
                            border: '1px solid #EBEBEB',
                            textAlign: 'right',
                            borderLeft: '0px',
                            borderRight: '0px',
                            backgroundColor: PRIMARY,
                            color: SECONDARY,
                          }}
                        >
                          <Typography variant="body1">
                            {t('common.unit_price')}
                          </Typography>
                        </th>
                        <th
                          style={{
                            padding: '8px',
                            border: '1px solid #EBEBEB',
                            textAlign: 'right',
                            borderLeft: '0px',
                            backgroundColor: PRIMARY,
                            color: SECONDARY,
                          }}
                        >
                          <Typography variant="body1">
                            {t('common.amount')}
                          </Typography>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {moduleDetail?.items?.map((item, index) => (
                        <tr
                          key={index}
                          style={{
                            backgroundColor: `${index & 1 && 'var(--box-gray)'}`,
                          }}
                        >
                          <td
                            style={{
                              padding: '8px',
                              border: '1px solid #EBEBEB',
                              textAlign: 'left',
                            }}
                          >
                            <Typography variant="body1">
                              {item.product_catalogue?.product_name}
                            </Typography>
                          </td>
                          <td
                            style={{
                              padding: '8px',
                              border: '1px solid #EBEBEB',
                              textAlign: 'center',
                            }}
                          >
                            {item.qty}
                          </td>
                          <td
                            style={{
                              padding: '8px',
                              border: '1px solid #EBEBEB',
                              textAlign: 'right',
                            }}
                          >
                            <Typography variant="body2">
                              {Number(item.price)?.toFixed(2)}
                            </Typography>
                          </td>
                          <td
                            style={{
                              padding: '8px',
                              border: '1px solid #EBEBEB',
                              textAlign: 'right',
                            }}
                          >
                            <Typography variant="body2">
                              {formatPriceWithDecimalValue(
                                item.qty * Number(item.price),
                                true
                              )}
                            </Typography>
                          </td>
                        </tr>
                      ))}
                      {TotalCountRowsUI}
                    </tbody>
                  </table>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {module === TEMPLATE_SET.DEFECT_QUOTES && (
        <Box sx={{ padding: '20px 0 10px 0' }}>
          <Typography variant="body1" style={{ textDecoration: 'underline' }}>
            {t('common.termsAndCondition')}
          </Typography>
          <Typography variant="body2">
            {t('common.serviceQuotePaymentDescription')}
          </Typography>
          {/* {isFromServiceQuotes ? (
            <Typography variant="body2">
              {t('common.serviceQuotePaymentDescription')}
            </Typography>
          ) : (
            <>
              <Typography variant="body2">
                {t('common.paymentDecription1')}
              </Typography>
              <Typography variant="body1" style={{ paddingTop: '5px' }}>
                {t('common.paymentDecription2')} {companyData?.company_name}
              </Typography>
            </>
          )} */}
        </Box>
      )}
    </Container>
  );
};
