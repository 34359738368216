export const Validation = {
  general: {
    required: 'is required',
    mustHave80Characters: 'must have maximum 80 characters',
    mustHave999Characters: 'must be between 1 and 999 months.',
    mustBeGreaterThan0: 'must be greater than 0.',
    abnNumber: 'ABN number must have 11 characters',
    address: 'Address must have maximum 255 characters',
    address1: 'Address Line 1 must have maximum 255 characters',
    address2: 'Address Line 2 must have maximum 255 characters',
    supplieraddress: 'Address must have maximum 100 characters',
    notes: 'Notes must have maximum 65,535 characters',
    postCode: 'Postal code must have 4 characters',
    contactName: 'Contact name must have maximum 80 characters',
    title: 'Title must have maximum 80 characters',
    customerName: 'Customer name must have maximum 80 characters',
    cellPhone: 'Cell phone must have maximum 50 characters',
    email: 'Email must have maximum 254 characters',
    validEmail: 'Enter valid email address',
    customerContact: 'Customer contact must have maximum 50 characters',
    contactPhone: 'Contact phone must have maximum 50 characters',
    maxContactPhone: 'Contact phone must have maximum 50 characters',
    organization: 'Organisation must have maximum 100 characters',
    billingContactName: 'Billing contact name must have maximum 80 characters',
    quotingContactName: 'Quoting Contact Name must have maximum 80 characters',
    numericValue: 'Only numeric values are allowed',
    description: 'Description must have maximum 300 characters',
    code: 'Product Code is required',
    delivery_instruction:
      'Delivery Instruction must have maximum 300 characters',
    generalTextArea: 'Field must have maximum 300 characters',
    fileUpload: 'Please upload the file',
  },
  company: {
    companyName:
      'Company name must have minimum 2 characters and maximum 255 characters',
  },
  superAdmin: {
    postCodeCharacter: 'Post Code must have',
    characters: 'characters',
  },
  property: {
    name: 'Property Name must have maximum 100 characters',
    contactName: 'Contact Name must have maximum 100 characters',
    landLotNumber: 'Land/Lot Number must have maximum 20 characters',
    ocspNumber: 'OCSP or DP/DN Number must have maximum 30 characters',
    organization: 'Organisation must have maximum 100 characters',
    internalNote: 'Internal Note must have maximum 65535 characters',
    accessCode: 'Access Code must have maximum 15 characters',
    accessNote: 'Access Note must have maximum 4000 characters',
    serviceRequirements:
      'Service Requirements must have maximum 65535 characters',
    safetyRequirements:
      'Safety Requirements must have maximum 65535 characters',
    buildingSize: 'Buiding Size must have maximum 40 characters',
    buildingSizeSqm: 'Buiding Size (sqm) must have maximum 20 characters',
    storyAboveGround: 'Stories Above Ground must have maximum 3 characters',
    storyBelowGround: 'Stories Below Ground must have maximum 3 characters',
    buildingPart: 'Building Part must have maximum 200 characters',
    buildingTenancies: 'Building Tenancies must have maximum 200 characters',
    occupancyPermitNumber:
      'Occupancy Permit Number must have maximum 30 characters',
    occupancyPermitLocation:
      'Occupancy Permit Location must have maximum 300 characters',
    levelName: 'Level Name must have maximum 80 characters',
  },
  supplier: {
    name: 'Supplier name must have minimum 2 characters and maximum 255 characters',
    business_number:
      'Business Number must have minimum 2 characters and maximum 255 characters',
    postCodeCharacter: 'Post Code must have',
    characters: 'characters',
    phone: 'Contact phone must have 10 characters',
    maxPhone: 'Contact phone must have maximum 50 characters',
  },
  officeStaff: {
    name: 'Office Staff name must have minimum 2 characters and maximum 255 characters',
    postCodeCharacter: 'Post Code must have',
    characters: 'characters',
    phone: 'Contact phone must have 0 characters',
    maxPhone: 'Cell phone must have maximum 50 characters',
  },
  officeManagement: {
    name: 'Office Management name must have minimum 2 characters and maximum 255 characters',
    postCodeCharacter: 'Post Code must have',
    characters: 'characters',
    phone: 'Contact phone must have 10 characters',
    maxPhone: 'Cell phone must have maximum 50 characters',
  },
  billingCard: {
    address: 'Address must have maximum 100 characters',
    address_line_2: 'Address must have maximum 50 characters',
    info: ' Customer Billing Not Linked with Accounting Partners',
  },
  workOrder: {
    quotation: 'Selected work order do not have quotation.',
  },
  routineServiceFrequencies: {
    frequency: 'Frequency must have maximum 2 characters.',
    tolerance: 'Tolerance must have maximum 2 characters.',
  },
  configuration: {
    buildingType: {
      name: 'Building Type Name can maximum have 80 characters.',
    },
    city: {
      name: 'City name can maximum have 80 characters.',
    },
    siteRequirement: {
      name: 'Site Requirement Name can maximum have 256 characters.',
    },
    accessProcedure: {
      name: 'Access Procedure Name can maximum have 256 characters.',
      description: 'Description can have maximum 300 characters.',
    },
    accessSchedule: {
      name: 'Access Schedule Name can maximum have 256 characters.',
      description: 'Description can have maximum 300 characters.',
    },
  },
  contact: {
    name: 'Contact name must have minimum 2 characters and maximum 255 characters',
    postCodeCharacter: 'Post Code must have',
    characters: 'characters',
    phone: 'Contact phone must have 10 characters',
    maxPhone: 'Contact phone must have maximum 50 characters',
  },
};
