import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Card, Divider, Grid, styled, Typography } from '@mui/material';

import {
  INVOICE_FREQUENCY_NUM,
  STANDARD_COUNTRY,
  STANDARD_CURRENCY_CODE,
} from '../../../constants/Constants';
import { DROPDOWN } from '../../../constants/Dropdown';
import { Validation } from '../../../constants/FieldValidationMsg';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import CustomTextField from '../../CommonComponents/CustomTextField';

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const CustomCard = styled(Card)(({ percentage }) => ({
  boxShadow: 'none',
  marginTop: '16px',
  borderRadius: '4px 4px 0 0',
  height: `calc(100vh - ${percentage === null ? '490px' : '430px'})`,
}));

const BillingContactPeriodic = ({
  control,
  setValue,
  trigger,
  clearHandler,
  watchedCPIIncreaseValue,
  isEdit,
  invoiceFrequency,
  watch,
  standardGroup,
}) => {
  const { t } = useTranslation();

  // Selector for billing contract by its ID
  const { data: getBillingContractByIdData } = useSelector(
    (state) => state.billingContract.getBillingContractById
  );
  const { isLoading: routineLoading } = useSelector(
    (state) => state.routine.propertyRoutineList
  );

  const annual_cost = watch('annual_cost');
  const currency =
    STANDARD_CURRENCY_CODE[
      standardGroup?.codeName ?? STANDARD_COUNTRY.AUSTRALIA
    ];

  useEffect(() => {
    if (isEdit && getBillingContractByIdData?.details?.[0]?.um === 'annual') {
      setValue(
        'annual_cost',
        Number(getBillingContractByIdData.details[0].price).toFixed(2),
        { shouldValidate: false }
      );
    }
  }, [getBillingContractByIdData, isEdit, setValue]);

  useEffect(() => {
    if (annual_cost || invoiceFrequency) {
      setValue(
        'monthly_cost',
        (
          (annual_cost || 0) / INVOICE_FREQUENCY_NUM[invoiceFrequency?.value] ??
          12
        ).toFixed(2),
        {
          shouldValidate: false,
        }
      );
    }
  }, [annual_cost, invoiceFrequency]);

  return routineLoading ? (
    <Box
      sx={{
        width: '100%',
        mt: 2,
        minHeight: 'calc(100vh - 490px)',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <CustomCircularLoader />
    </Box>
  ) : (
    <CustomCard
      percentage={isEdit ? null : watchedCPIIncreaseValue?.percentage}
    >
      <Box sx={{ padding: '16px' }}>
        <Typography variant="body1">{`${t('attributes.billingContract.pricing')} ${t('common.specifications')}`}</Typography>
      </Box>
      <Divider />
      <Typography variant="body1" sx={{ padding: '16px 0 0 16px' }}>
        {`${t('attributes.billingContract.lineItem')}: ${t('attributes.billingContract.annualContractValue')}`}
      </Typography>
      <FormFieldWrapper>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="annual_cost"
              control={control}
              rules={{
                required: `${t('attributes.billingContract.annualCost')} ${Validation.general.required}`,
                validate: (value) => {
                  if (value) {
                    if (value < 1) {
                      return `${t('attributes.billingContract.annualCost')} ${Validation.general.mustBeGreaterThan0}`;
                    }

                    return true;
                  }

                  return true;
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  type="number"
                  isRequired={true}
                  label={`${t('attributes.billingContract.annualCost')} (${currency})`}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    if (e?.target?.value >= 0 || e?.target?.value === '') {
                      onChange(e);
                      trigger('annual_cost');
                    }
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                  disabled={
                    !invoiceFrequency ||
                    (isEdit && !getBillingContractByIdData?.is_active)
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="monthly_cost"
              control={control}
              render={({ field: { value } }) => (
                <CustomTextField
                  isRequired={false}
                  disabled={true}
                  label={`${invoiceFrequency?.label ?? DROPDOWN.INVOICE_FREQUENCY[0]?.label} (${currency})`}
                  fullWidth
                  value={value}
                />
              )}
            />
          </Grid>
        </Grid>
      </FormFieldWrapper>
    </CustomCard>
  );
};

export default BillingContactPeriodic;
