import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { SomethingWrong } from '../../assets/images';
import { SOMETHING_WENT_WRONG_IMAGE } from '../../constants/Constants';

const AppErrorPage = () => {
  const { t } = useTranslation();

  const context = useOutletContext();

  let cachedImage = SomethingWrong;

  if (context.isOffline) {
    cachedImage = localStorage.getItem(SOMETHING_WENT_WRONG_IMAGE);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 300px',
        height: '100vh',
        background: 'white',
        textAlign: 'center',
      }}
    >
      <img
        src={cachedImage}
        alt="Something Wrong"
        style={{ height: '235px', width: '235px', objectFit: 'contain' }}
      />
      <Typography variant="h5">{t('errorPage.someThingWrongTitle')}</Typography>
      <Typography variant="h1" fontWeight={400}>
        {t('errorPage.someThingWrongSubTitle')}
      </Typography>
      <Typography variant="h1" fontWeight={400}>
        {t('errorPage.someThingWrongDescription')}
      </Typography>
    </Box>
  );
};

export default AppErrorPage;
