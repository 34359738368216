export const APIs = {
  TEMPLATES: {
    GET_EMAIL_SETTINGS: 'email-setting',
    GET_TEMPLATES: 'email-template',
    GET_TEMPLATE_DATA: 'email-template-data',
    UPDATE_TEMPLATE: 'email-template',
    GET_TEMPLATE_DETAIL: 'email-template/:id',
    SEND_EMAIL: '/send-email',
    GET_REST_TEMPLATES: '/user/templates',
    EDIT_REST_TEMPLATES: '/user/templates',
  },
  SUPER_ADMIN: {
    GET_SUPER_ADMINS: '/user/super-admin/company',
    GET_SUPER_ADMIN_BY_ID: '/user/super-admin/{uuid}',
    GET_UPDATE_SUPER_ADMINS: '/user/super-admin/{uuid}',
    CREATE_SUPER_ADMINS: '/user/super-admin',

    GET_PROPERTY_CHART_DATA: '/dashboard/property',
    GET_DEFECT_DASHBOARD_CHART: '/dashboard/defect',
    GET_QUOTES_DASHBOARD_CHART: '/dashboard/quotation',
    GET_PURCHASE_ORDER_DASHBOARD_CHART: '/dashboard/purchase-order',

    UPDATE_COMPANY_TAX_CONFIGURATION: 'company/{uuid}/tax',
  },

  GLOBAL_ADMIN: {
    GET_PROPERTY_DASHBOARD_CHART: '/dashboard/property-state',
    GET_FIRE_COMPANY_DASHBOARD_CHART: '/dashboard/fire-company-state',
    GET_UERS_DASHBOARD_CHART: '/dashboard/user_state',
    GET_DASHBOARD_COUNT: '/dashboard/active_state',
    GET_ACTIVE_INACTIVE_COMPANY_CHART: '/dashboard/company_status_by_monthly',
  },

  DEFECT: {
    GET_DEFECT_MONTHLY_CHART:
      '/customer_dashboard/defect_severity_count_month_wise',
    GET_DEFECT_BY_EQUIPEMENT_CHART:
      '/customer-dashboard/defect-severity-counts-by-equipment-types',
    GET_DEFECT_STATUS_MONTHLY_CHART:
      'customer_dashboard/defect_status_counts_monthwise',
    GET_DEFECT_BY_PROPERTY_CHART:
      'customer_dashboard/defect_severity_count_property_wise',
    GET_DEFECT_VIEW: '/defect/:uuid',
  },

  WIDGET: {
    GET_WIDGETS: '/dashboard-widget/:role_name',
    UPDATE_WIDGETS: '/user/:user_id/dashboard-widget',
    GET_USER_WIDGET: '/user/:user_id/dashboard-widgets',
  },

  PROFILE: {
    UPDATE_PROFILE: '/userauth/profilepic/:uuid',
  },

  TIME_SHEET: {
    GET_TIME_SHEETS: '/timesheet',
    DOWNLOAD_TIME_SHEETS: '/timesheet',
    UPDATE_TIME_SHEET: '/timesheet/:timesheet_uuid',
    APPROVE_OR_REJECT: '/timesheet/status',
    DELETE_TIME_SHEET: '/timesheet/:timesheet_uuid',
    GET_TIME_ENTRY: '/timesheet',
  },
  BILLING_CARD: {
    BILLING_CARDS: '/bill_cards',
    UPDATE_BILLING_CARD: '/bill_cards/:billing_uuid',
  },
  INVOICE: {
    GET_CARD_STATUS_DATA: '/dashboard/invoice',
    GET_INVOICE_CHART_BY_AMOUNT: '/customer_dashboard/invoice_amount',
    GET_INVOICE_CHART_BY_STATUS: '/customer_dashboard/invoice_status_counts',
    GET_INVOICE_CHART_BY_AMOUNT_CHART:
      '/customer_dashboard/invoice_amount_month',
    GET_INVOICE_CHART_BY_STATUS_CHART:
      '/customer_dashboard/invoice_amount_status_month',
  },
  PROPERTIES: {
    GET_PROPERTIES: '/detail',
    GET_PROPERTY: '/detail/{propertyUuid}',
    UPDATE_PROPERTY: '/detail/{propertyUuid}',
    CREATE_PROPERTY: '/detail',
    GET_COMPLIANCE_STATUS_CHART: '/customer_dashboard/property_compliance',
    GET_DASHBOARD_COMPLIANCE_CHART: '/dashboard/property_compliance',
    GET_LOCAL_GOVERNMENT_AND_ZONE: '/zone_local_government',
    // status api list
    GET_STATUS_LIST: '/status',
    // Tags list api
    GET_TAG_LIST: '/tag',
    // Access schedule api
    GET_ACCESS_SCHEDULE_LIST: '/access_schedule',
    // Access procedure api
    GET_ACCESS_PROCEDURE_LIST: '/access_procedure',
    // Building era
    GET_BUILDING_ERA_LIST: '/building_era',
    // Building class
    GET_BUILDING_CLASS_LIST: '/building_class',
    // Construction list
    GET_CONSTRUCTION_LIST: '/construction_class',
    // Building type list
    GET_BUILDING_TYPE_LIST: '/building_type',
    // Building standard list
    GET_BUILDING_STANDARD_LIST: '/building_standard',
    // Get local government area
    GET_LOCAL_GOVERNMENT_AREA_LIST: '/zone_local_government',
    // Get parent property list
    GET_PARENT_PROPERTY_LIST: '/detail',
    // Get property account manager list
    GET_PROPERTY_ACCOUNT_MANAGER_LIST: '/account_managers',
    // Get property customer list
    GET_PROPERTY_CUSTOMER_LIST: '/user/customer',
    // Get property billing card list
    GET_PROPERTY_BILLING_CARD_LIST: '/billing_cards',
    GET_PROPERTY_ASSETS_LIST: '/detail/{property_uuid}/asset',
    GET_EQUIPEMENT_TYPE: '/equipment_types',
    GET_PRODUCTS: '/products',
    GET_ASSET_STATUS: '/asset_status',
    CREATE_ASSET: '/asset_new',
    GET_ASSET_DETAILS: '/asset/{asset_id}',
    UPDATE_ASSET_DETAILS: '/asset/{asset_id}',
    GET_PROPERTY_ROUTINE_LIST: '/routine_schedule',
    GET_PROPERTY_ROUTINE_STANDARD_FREQUENCY: '/routine-property',
    UPDATE_ROUTINE_DETAILS: '/routine_schedule/{routine_id}',
    GET_COMPLIANCE_STATUS: '/compliance',
    GET_INVOICE_COUNT: '/customer_dashboard/property_invoice',
    GET_QUOTE_COUNT: '/customer_dashboard/property_quotes',
    GET_DEFECT_COUNT: '/customer_dashboard/property_defects',
    GET_FLOOR_PLAN_LIST: '/:property_uuid/floor-plans',
    CREATE_FLOOR_PLAN: '/floor-plan',
    GET_FLOOR_PLAN: '/floor-plan/:uuid',
    UPDATE_FLOOR_PLAN: '/floor-plan/:uuid',
    DELETE_FLOOR_PLAN: '/floor-plan/:uuid',
    GET_ASSET_HISTORY_MESSAGES: '/master-asset-history-messages',
    GET_ASSET_HISTORY: '/asset/{uuid}/history',
    CREATE_WORK_ORDER: '/work_order/{asset_id}',
    CREATE_BUILDING_TYPE: '/building_type',
    GET_BUILDING_TYPE: '/building_type/{uuid}',
    UPDATE_BUILDING_TYPE: '/building_type/{uuid}',
    CREATE_ASSET_VARIANT: '/product_variant',
    UPDATE_ASSET_VARIANT: '/product_variant/{uuid}',
    GET_PRODUCT_VARIANTS: '/product_variants',
    CREATE_SITE_REQUIREMENT: '/site_requirement_details',
    UPDATE_SITE_REQUIREMENT: '/site_requirement_details/{uuid}',
    GET_SITE_REQUIREMENTS: '/site_requirement_details',
    GET_SITE_REQUIREMENT: '/site_requirement_details/{uuid}',
    CREATE_ACCESS_PROCEDURE: '/access_procedure',
    UPDATE_ACCESS_PROCEDURE: '/access_procedure/{uuid}',
    GET_ACCESS_PROCEDURES: '/access_procedure',
    GET_ACCESS_PROCEDURE: '/access_procedure/{uuid}',
    // Access schedule api
    GET_ACCESS_SCHEDULE_LISTS: '/access_schedule',
    CREATE_ACCESS_SCHEDULE: '/access_schedule',
    UPDATE_ACCESS_SCHEDULE: '/access_schedule/{uuid}',
    GET_ACCESS_SCHEDULE: '/access_schedule/{uuid}',
  },
  CUSTOMER: {
    GET_CUSTOMERS: '/user/customer',
    GET_CUSTOMER_BY_ID: '/user/customer/{uuid}',
    GET_UPDATE_CUSTOMER: '/user/customer/{uuid}',
    CREATE_CUSTOMER: 'user/customer',
    GET_CUSTOMER_CATEGORY_DROPDOWN_LIST: '/customer/categories',

    GET_QUOTES_LIST: '/quotation',
    GET_QUOTES_TYPE: '/quotes/type',
    GET_QUOTES_STATUS: '/quotes/status',
    GET_QUOTES_PROPERTYNAME: '/quotes/property_name',

    GET_INVOICE_LIST: '/invoices',
    GET_INVOICE_TYPE: '/invoice/type',
    GET_INVOICE_STATUS: '/invoice/status',
    GET_INVOICE_PROPERTYNAME: '/invoice/property_name',
    GET_INVOICE_VIEW: '/invoices/{invoice_uuid}/view',
    CREATE_INVOICE: '/invoices',
    UPDATE_INVOICE: '/invoices/{invoice_uuid}',
    CREATE_INVOICE_PRODUCT: '/invoice/{invoice_uuid}/item',
    UPDATE_INVOICE_PRODUCT: '/invoice/{invoice_uuid}/item/{item_uuid}',

    GET_QUOTATION_STATUS: '/customer-dashboard/quotation-status',
    GET_QUOTATION_TOTAL_AMOUNT: '/customer-dashboard/quotation-total-amount',

    GET_DEFECT_COUNT: '/customer-dashboard/defect',
    GET_DEFECT_LIST: '/defects',

    GET_QUOTES_CHART_BY_AMOUNT: '/customer-dashboard/quotation-amount-monthly',
  },
  TECHNICIAN: {
    GET_TECHNICIANS: '/user/technician',
    GET_TECHNICIAN_BY_ID: '/user/technician/{id}',
    GET_UPDATE_TECHNICIANS: '/user/technician/{id}',
    CREATE_TECHNICIANS: '/user/technician',
    // Tags list api
    GET_TAGS_LIST: '/technician/tags',
    // Skills list api
    GET_SKILLS_LIST: '/technician/skills',
    // Zones list api
    GET_ZONES_LIST: '/technician_zones',
    // Service Area list api
    GET_SERVICE_AREAS: '/technician/service-areas',
    GET_TECHNICIAN_NAMES: '/technician_names',
    GET_TECHNICIAN_PERFORMANCE_DATA: '/technician_dashboard',
  },
  CATEGORY: {
    GET_CATEGORY_DROPDOWN_LIST: '/technician/category',
  },
  SKILLS: {
    GET_SKILLS_DROPDOWN_LIST: '/technician/skills',
  },
  ZONES: {
    GET_ZONES_DROPDOWN_LIST: '/zone_local_government',
    CREATE_ZONE: '/new_zone_local_government',
    UPDATE_ZONE: '/new_zone_local_government/{zone_uuid}',
    GET_ZONE: '/new_zone_local_government/{zone_uuid}',
    GET_ZONES_LIST: '/new_zone_local_government',
  },
  SERVICE_AREAS: {
    GET_SERVICE_AREAS_DROPDOWN_LIST: '/technician/service-areas',
  },
  ACCOUNT_MANAGER: {
    GET_ACCOUNT_MANAGER_DROPDOWN_LIST: '/user/office-staff',
    GET_ACCOUNT_MANAGER_COMMON_DROPDOWN_LIST: 'account_managers',
  },
  OFFICE_STAFF: {
    GET_OFFICE_STAFFS: '/user/office-staff',
    GET_OFFICE_STAFF_BY_ID: '/user/office-staff/{id}',
    GET_UPDATE_OFFICE_STAFFS: '/user/office-staff/{id}',
    CREATE_OFFICE_STAFFS: '/user/office-staff',
  },
  OFFICE_MANAGEMENT: {
    GET_OFFICE_MANAGEMENT: '/user/office-staff-management',
    GET_OFFICE_MANAGEMENT_BY_ID: '/user/office-staff-management/{id}',
    GET_UPDATE_OFFICE_MANAGEMENT: '/user/office-staff-management/{id}',
    CREATE_OFFICE_MANAGEMENT: '/user/office-staff-management',
  },
  WORK_ORDER: {
    GET_WORK_ORDERS: '/work_orders',
    GET_WORK_ORDER: '/work_orders/{uuid}',
    CERATE_WORK_ORDER: '/work_orders',
    GET_WORK_ORDER_QUOTATIONS: '/work_order/{work_order_uuid}/quotation',
    GET_ASSET_LIST: '/work_orders/:workorder_uuid/tasks',
    GET_DEFECT_INFORMATION: '/work_order/{work_order_uuid}/catalog',
    GET_ASSET_INSPECTION_FORM: '/work_order/:work_order_uuid/inspection_form',
    GET_DEFECT_LIST: '/work_orders/:workorder_uuid/defects',
    UPDATE_DEFECT:
      '/work_orders/{work_orders_uuid}/asset/{asset_uuid}/defect/{defect_uuid}',
    GET_DEFECT_QUOTE: '/work_orders/:work_order_uuid/defect/:defect_uuid',
    GET_DEFECT_QUOTES_DETAIL: '/quotation/:quotation_uuid',
    ADD_DEFECT_QUOTE_PRODUCT: '/quotation/:quotation_uuid/items',
    PATCH_DEFECT_QUOTE_STATUS: '/quotation/:quotation_uuid',
    UPDATE_DEFECT_QUOTE_PRODUCT: '/quotation_items/:quotation_item_uuid',
    DELETE_DEFECT_QUOTE_PRODUCT: '/quotation_items/:quotation_item_uuid',
    CREATE_INVOICE: '/work_order/{work_order_uuid}/generate_invoice',
    ASSIGN_UNASSIGN_TECHNICIAN: '/work_orders/:work_order_uuid',
    GET_WORK_ORDER_PRECHECKLIST: '/work_orders/{work_order_uuid}/pre-checklist',
    CREATE_DEFECT_QUOTE: '/quotation',
  },
  SUPPLIER: {
    GET_SUPPLIERS: '/supplier',
    GET_SUPPLIER_BY_ID: '/supplier/{uuid}',
    UPDATE_SUPPLIER: '/supplier/{uuid}',
    CREATE_SUPPLIER: '/supplier',
  },
  GOOGLE: {
    AUTOCOMPLETE: '/location/utility',
    DETAIL: '/location/utility',
  },
  PRODUCT_CATALOGUE: {
    GET_PRODUCT_CATALOGUE_LIST: 'product_catalogue',
    GET_PRODUCT_CATALOGUE_DETAILS:
      '/product_catalogue/{product_catalogue_uuid}',
    GET_PRODUCT_CATALOGUE: '/product_catalogue',
    GET_PRODUCT_DETAILS: '/product_catalogue/{uuid}',
    UPDATE_PRODUCT_CATALOGUE: '/product_catalogue/{uuid}',
    CREATE_PRODUCT_CATALOGUE: '/product_catalogue',
    PRODUCT_ENHANCEMENT: '/product_enhancement',
  },
  SCHEDULE_BOARD: {
    ASSIGN_WORK_ORDER_TO_TECHNICIAN: '/workorder/{uuid}/schedule',
    CREATE_SCHEDULE_BOARD: '/schedule_board',
  },
  PURCHASE_ORDER: {
    GET_PURCHASE_ORDER_LIST: '/purchase_order',
    CREATE_PURCHASE_ORDER: '/purchase_order',
    CREATE_PURCHASE_ORDER_PRODUCT: '/purchase_order/{purchase_order}/items',
    CREATE_PURCHASE_ORDER_INVOICE_BILL: '/purchase_order_bill',
    UPDATE_PURCHASE_ORDER_PRODUCT:
      '/purchase_order/{purchase_order}/items/{purchase_order_item_uuid}',
    UPDATE_PURCHASE_ORDER: '/purchase_order/{purchase_order_uuid}',
    GET_PURCHASE_ORDER_INVOICE_BILL_LIST:
      '/purchase_order/{purchase_order_uuid}/items',
    GET_PURCHASE_ORDER_INVOICE_BILL:
      '/purchase_order_bill/{purchase_order_bill_uuid}',
  },
  BILLING_CONTRACT: {
    GET_BILLING_CONTRACT_LIST: '/bill_contract',
    CREATE_BILLING_CONTRACT_LIST: '/bill_contract',
    GET_BILLING_CONTRACT_BY_ID: '/bill_contract/:billing_contract_uuid',
    UPDATE_BILLING_CONTRACT_BY_ID: '/bill_contract/:contract_uuid',
    UPDATE_BILLING_CONTRACT_DETAILS: '/bill_contract/:contract_uuid/details',
  },
  STANDARDS: {
    GET_STANDARDS_DROPDOWN_LIST: '/standards',
  },
  ROUTINE: {
    GET_ROUTINE_LIST: '/routine',
    GET_ROUTINE_STANDARD_FREQUENCY_LIST: '/routine_standard_frequency',
    CREATE_UPDATE_ROUTINE: '/routine',
    GET_PROPERTY_ROUTINE: '/routine-property',
  },
  FREQUENCY: {
    GET_FREQUENCY_LIST: '/frequency',
  },
  DOCUMENTS: {
    GET_DOCUMENTS: '/bulk_upload',
    GET_SAMPLE_TEMPLATE: '/bulk_upload/template/:code',
    DOWNLOAD_IMPORTED_FILE: '/bulk_upload/:documentId/document',
  },
  USER_MANAGEMENT: {
    GET_USER_LIST: '/users',
  },

  INTEGRATIONS: {
    ACCOUNT_PARTNER_CONFIGURATION: '/account_partner_config',
    XERO_INVOICE: '/Xero-invoice',
    VERIFY_ACCOUNT_PARTNER_CONFIGURATION: '/account_partner_config/verify',
    HEALTH_ACCOUNT_PARTNER_CONFIGURATION:
      '/account_partner_config/health_check',
  },

  ROUTINE_SERVICE_FREQUENCIES: {
    GET_ROUTINE_SERVICE_FREQUENCIES: '/frequency',
    GET_ROUTINE_SERVICE_FREQUENCIES_BY_ID: 'frequency/{uuid}',
    GET_UPDATE_ROUTINE_SERVICE_FREQUENCIES: '/frequency/{uuid}',
    CREATE_ROUTINE_SERVICE_FREQUENCIES: '/frequency',
  },

  INSPECTION_FORM: {
    GET_INSPECTION_FORM_LIST: '/inspection-form',
  },
  ASSETS: {
    EQUIPMENT_CLASS: '/equipment_class/{equipment_type_id}',
    CATEGORY_LEVELS: '/category_levels',
  },
  CITY: {
    CREATE_CITY: '/locations',
    UPDATE_CITY: '/locations/{id}',
    CITY_LIST: '/locations',
  },
  CONTACT: {
    GET_CONTACTS: '/contacts',
    GET_CONTACT_BY_ID: '/contacts/{uuid}',
    UPDATE_CONTACT: '/contacts/{uuid}',
    CREATE_CONTACT: '/contacts',
  },
};
