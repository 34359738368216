import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Document,
  Image,
  Page,
  pdf,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { DarkLogo } from '../../assets/images';
import {
  DARK_GREEN,
  DESCRIPTION_COLOR,
  TABLE_BG_COLOR,
} from '../../constants/Colors';
import {
  ASSETS_STATUS_LABEL,
  CRITICAL_STATUS,
  DATE_FORMAT,
  DEFECT_STATUS,
  NON_CONFORMANCE_STATUS,
  NON_CRITICAL_STATUS,
  OPEN_STATUS,
  RECOMMENDATION_STATUS,
  RESOLVED,
} from '../../constants/Constants';
import { formatAddress } from '../../utils';

// Constants & Fallbacks
const FALLBACK_TEXT = 'N/A';
const DEFAULT_REPORT_NAME = 'ACTIVITY-REPORT';

// PDF Styles (no change here)
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 30,
    fontSize: 11,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerRow: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerCell: {
    display: 'flex',
    flexDirection: 'row',
    lineHeight: 1.5,
    alignItems: 'center',
    gap: '10px',
  },
  headerCellTitle: {
    minWidth: '100px',
    fontSize: '15px',
  },
  headerCellAnswer: {
    color: DESCRIPTION_COLOR,
  },
  propertyDetails: {
    color: 'white',
  },
  section: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 10,
  },
  tableCellRight: {
    padding: '16px',
    border: '1px solid #EBEBEB',
    textAlign: 'right',
  },
  table: {
    marginTop: '24p',
    width: 'auto',
    // marginVertical: 10,
    // border: '1px solid #ececec',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: '1px solid #ccc',
  },
  tableRowHeader: {
    backgroundColor: TABLE_BG_COLOR,
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: 11,
    border: '1px solid #ececec',
    height: '100%',
    flex: 1,
    padding: '8px',
  },
  tableCell: {
    flex: 1,
    fontSize: 11,
    padding: '8px',
    border: '1px solid #ececec',
  },
  logo: {
    height: 68,
    objectFit: 'contain',
    maxWidth: 150,
  },
  image: {
    height: 68,
    objectFit: 'contain',
    maxWidth: 150,
  },
  bold: {
    fontWeight: 'bold',
  },
  footer: {
    marginTop: 20,
    lineHeight: 1.5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  address: {
    lineHeight: 1.5,
    color: '#646464',
  },
  title: {
    marginBottom: 4,
    fontWeight: 'bold',
  },
  pass: {
    color: '#95C020',
  },
  fail: {
    color: '#C54036',
  },
  non_conformance: {
    color: '#7A4E8C',
  },
  recommendation: {
    color: '#FD7E14',
  },
  open: {
    color: '#59C3C3',
  },
  in_progress: {
    color: '#2196F3',
  },
  no_check: {
    color: '#59366B',
  },
  normal: {
    fontWeight: 'normal',
  },
});

const DownloadPDF = ({ workOrder = {} || [], imgSrc = [], company = {} }) => {
  const { t } = useTranslation();

  const getImageSource = ({ document_uuid, isSignatureImage = false }) => {
    const imageSource = imgSrc?.find((item) => {
      const key = Object.keys(item)[0];

      return key === document_uuid?.toString();
    });

    if (imageSource) {
      const uri = imageSource[document_uuid];

      return (
        <Image
          src={{ uri, method: 'GET' }}
          style={[
            isSignatureImage ? styles.image : styles.logo,
            isSignatureImage && { height: '30px' },
          ]}
        />
      );
    }
  };

  const formatStatus = ({ value, textAlign }) => {
    const mergedStyle = { textAlign, fontWeight: 'bold' };

    if (value === ASSETS_STATUS_LABEL.pass) {
      return (
        <Text style={[styles.tableCell, styles.pass, mergedStyle]}>
          {t('common.status.pass')}
        </Text>
      );
    } else if (value === ASSETS_STATUS_LABEL.fail) {
      return (
        <Text style={[styles.tableCell, styles.fail, mergedStyle]}>
          {t('common.status.fail')}
        </Text>
      );
    } else if (value === CRITICAL_STATUS) {
      return (
        <Text style={[styles.tableCell, styles.fail]}>
          {t('common.status.critical')}
        </Text>
      );
    } else if (value === NON_CONFORMANCE_STATUS) {
      return (
        <Text style={[styles.tableCell, styles.non_conformance, mergedStyle]}>
          {t('common.status.non_conformance')}
        </Text>
      );
    } else if (value === NON_CRITICAL_STATUS) {
      return (
        <Text style={[styles.tableCell, styles.pass, mergedStyle]}>
          {t('common.status.non_critical')}
        </Text>
      );
    } else if (value === RECOMMENDATION_STATUS) {
      return (
        <Text style={[styles.tableCell, styles.recommendation, mergedStyle]}>
          {t('common.status.recommendation')}
        </Text>
      );
    } else if (value === OPEN_STATUS) {
      return (
        <Text style={[styles.tableCell, styles.open, mergedStyle]}>
          {t('common.status.open')}
        </Text>
      );
    } else if (value === RESOLVED) {
      return (
        <Text style={[styles.tableCell, styles.pass, mergedStyle]}>
          {t('common.status.resolved')}
        </Text>
      );
    } else if (value === ASSETS_STATUS_LABEL.noCheck) {
      return (
        <Text style={[styles.tableCell, styles.no_check, mergedStyle]}>
          {t('common.status.no_check')}
        </Text>
      );
    } else {
      return <Text style={[styles.tableCell, mergedStyle]}>{value}</Text>;
    }
  };

  return (
    <Document>
      <Page wrap style={styles.page}>
        {/* Header Section */}
        <View style={styles.headerContainer}>
          <View style={styles.header}>
            {/* Customer Details */}
            <View style={styles.headerRow}>
              <View style={styles.headerCell}>
                <Text style={[styles.bold, styles.headerCellTitle]}>
                  {t('attributes.customer.customer')} {t('attributes.name')}:
                </Text>
                <Text style={[styles.headerCellAnswer, { fontSize: '15px' }]}>
                  {Array.isArray(workOrder) && workOrder.length > 0
                    ? workOrder[0]?.property?.customer
                    : workOrder?.customer?.customer_name || '-'}
                </Text>
              </View>
            </View>
          </View>
          {/* Logo */}
          <Image src={company?.logo_url ?? DarkLogo} style={styles.image} />
        </View>

        <View style={styles.table}>
          <View style={{ backgroundColor: DARK_GREEN, padding: '10' }}>
            <Text
              style={[
                styles.propertyDetails,
                { width: '280px', paddingBottom: '5px' },
                styles.bold,
              ]}
            >
              {Array.isArray(workOrder) && workOrder.length > 0
                ? workOrder[0]?.property?.property_name
                : workOrder?.property?.property_name || '-'}
            </Text>
            <Text style={[styles.propertyDetails, { width: '280px' }]}>
              {Array.isArray(workOrder) && workOrder.length > 0
                ? [
                    workOrder[0]?.property?.address,
                    workOrder[0]?.property?.address_line_2,
                    workOrder[0]?.property?.state,
                    workOrder[0]?.property?.city || '',
                    workOrder[0]?.property?.zip_code,
                  ]
                    .filter(Boolean)
                    .join(', ')
                : [
                    formatAddress(
                      workOrder?.property?.address,
                      workOrder?.property?.address_line_2,
                      workOrder?.property?.state,
                      workOrder?.property?.city || '',
                      workOrder?.property?.zip_code
                    ),
                  ]
                    .filter(Boolean)
                    .join(', ') || '-'}
            </Text>
          </View>
          <View>
            <View style={[styles.tableRow, styles.tableRowHeader]}>
              <Text style={[styles.tableHeader, { minWidth: '230px' }]}>
                {t('attributes.work_order.work_orders').toUpperCase()}
              </Text>
              <Text style={styles.tableHeader}>
                {t('attributes.work_order.work_order_ID').toUpperCase()}
              </Text>
              <Text style={styles.tableHeader}>
                {t('attributes.work_order.asset_count').toUpperCase()}
              </Text>
            </View>
            {Array.isArray(workOrder) ? (
              workOrder?.map((item, index) => (
                <React.Fragment index={index}>
                  <View style={styles.tableRow}>
                    <View
                      style={[
                        styles.tableCell,
                        { minWidth: '230px', gap: '8px' },
                      ]}
                    >
                      <Text>{item?.work_order_name || '-'}</Text>
                    </View>
                    <Text
                      style={[styles.tableCell, { textAlign: 'center' }]}
                    >{`W-${item?.wo_id}`}</Text>

                    <Text style={[styles.tableCell, { textAlign: 'center' }]}>
                      {item?.asset_count}
                    </Text>
                  </View>
                </React.Fragment>
              ))
            ) : (
              <>
                <View style={styles.tableRow}>
                  <View
                    style={[
                      styles.tableCell,
                      { minWidth: '230px', gap: '8px' },
                    ]}
                  >
                    <Text>{workOrder?.work_order_name}</Text>
                  </View>
                  <Text
                    style={[styles.tableCell, { textAlign: 'center' }]}
                  >{`W-${workOrder?.wo_id}`}</Text>

                  <Text style={[styles.tableCell, { textAlign: 'center' }]}>
                    {workOrder?.asset_count}
                  </Text>
                </View>
              </>
            )}

            {Array.isArray(workOrder) && workOrder.length > 0
              ? workOrder?.map((item, index) => (
                  <View key={index}>
                    {/* Table Section */}
                    <View style={styles.table}>
                      <View style={[styles.tableRow, styles.tableRowHeader]}>
                        <View
                          style={{
                            minWidth: '180px',
                            gap: '8px',
                            flex: 1,
                            fontSize: 11,
                            padding: '8px',
                          }}
                        >
                          <Text style={styles.bold}>{`W-${item?.wo_id}`}</Text>
                          <Text>{item?.work_order_name || '-'}</Text>
                        </View>
                        <View
                          style={{
                            gap: '8px',
                            flex: 1,
                            fontSize: 11,
                            padding: '8px',
                          }}
                        >
                          <Text style={styles.bold}>
                            {t('common.completed_date')}:{' '}
                            {moment(item.updated_at).format(DATE_FORMAT) ||
                              FALLBACK_TEXT}
                          </Text>
                        </View>
                      </View>
                      {item?.work_order_tasks?.map((item, index) => (
                        <View style={styles.tableRow} key={index}>
                          <View
                            style={[
                              styles.tableCell,
                              { minWidth: '250px', gap: '8px' },
                            ]}
                          >
                            <Text
                              style={styles.bold}
                            >{`${index + 1} - ${item?.asset_detail?.label}`}</Text>
                            <Text
                              style={styles.headerCellAnswer}
                            >{`${t('attributes.location')}: ${item?.asset_detail?.location || '-'}`}</Text>
                          </View>

                          {formatStatus({
                            value: item?.asset_detail?.status?.display_name,
                            textAlign: 'center',
                          }) || '-'}
                        </View>
                      ))}

                      {item?.assets_defect?.map((item, index) => (
                        <View style={styles.tableRow} key={`asset-${index}`}>
                          <View
                            style={[
                              styles.tableCell,
                              { minWidth: '250px', gap: '8px' },
                            ]}
                          >
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              <View
                                style={[styles.bold, { minWidth: '250px' }]}
                              >
                                <Text>
                                  {t('attributes.work_order.defect_severity')}
                                </Text>
                                <Text style={styles.headerCellAnswer}>
                                  {formatStatus({
                                    value: item?.severity,
                                    textAlign: 'left',
                                  }) || '-'}
                                </Text>
                              </View>
                              <View style={styles.bold}>
                                <Text>{t('attributes.work_order.status')}</Text>
                                <Text style={styles.headerCellAnswer}>
                                  {formatStatus({
                                    value:
                                      item?.latest_status === RESOLVED
                                        ? 'resolved'
                                        : 'open',
                                    textAlign: 'left',
                                  }) || FALLBACK_TEXT}
                                </Text>
                              </View>
                            </View>

                            <Text style={[styles.bold, { paddingTop: '10px' }]}>
                              {t('attributes.work_order.defect_description') ||
                                '-'}
                            </Text>
                            <Text style={styles.headerCellAnswer}>
                              {item?.defect_description || '-'}
                            </Text>

                            <Text
                              break
                              style={[styles.bold, { paddingTop: '10px' }]}
                            >
                              {t('attributes.work_order.photos')}
                            </Text>

                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '8px',
                                flexWrap: 'wrap',
                              }}
                            >
                              {item?.documents?.map(
                                (doc) =>
                                  doc?.document_type === DEFECT_STATUS && (
                                    <View key={doc?.document_uuid}>
                                      {getImageSource({
                                        document_uuid: doc?.document_uuid,
                                      })}
                                    </View>
                                  )
                              )}
                            </View>
                          </View>
                        </View>
                      ))}
                    </View>

                    {/* Footer */}
                    <View style={[styles.footer, { padding: '10' }]}>
                      <View style={styles.bold}>
                        <Text>
                          {t('attributes.technician.technician_name')}:{' '}
                          <Text
                            style={[styles.normal, styles.headerCellAnswer]}
                          >
                            {item?.technician?.technician_name || FALLBACK_TEXT}
                          </Text>
                        </Text>
                        <Text>
                          {t('attributes.work_order.performed_date')}:{' '}
                          {Array.isArray(item?.work_order_pre_check_list) &&
                            item.work_order_pre_check_list.length > 0 &&
                            item.work_order_pre_check_list[0] && (
                              <Text
                                style={[styles.headerCellAnswer, styles.normal]}
                              >
                                {moment(
                                  item.work_order_pre_check_list[0]
                                    ?.signature_time
                                ).format(DATE_FORMAT) || FALLBACK_TEXT}
                              </Text>
                            )}
                        </Text>
                      </View>
                      <View style={styles.bold}>
                        <Text>
                          {t('attributes.work_order.technician_signature')}:
                        </Text>
                        {item?.work_order_pre_check_list[0] &&
                          getImageSource({
                            document_uuid:
                              item?.work_order_pre_check_list[0]
                                ?.technician_signature_document_uuid,
                            isSignatureImage: true,
                          })}
                      </View>
                    </View>
                  </View>
                ))
              : !isEmpty(workOrder) && (
                  <>
                    {/* Table Section */}
                    <View style={styles.table}>
                      <View style={[styles.tableRow, styles.tableRowHeader]}>
                        <View
                          style={{
                            minWidth: '180px',
                            gap: '8px',
                            flex: 1,
                            fontSize: 11,
                            padding: '8px',
                          }}
                        >
                          <Text
                            style={styles.bold}
                          >{`W-${workOrder?.wo_id}`}</Text>
                          <Text>{workOrder?.work_order_name}</Text>
                        </View>
                        <View
                          style={{
                            gap: '8px',
                            flex: 1,
                            fontSize: 11,
                            padding: '8px',
                          }}
                        >
                          <Text style={styles.bold}>
                            {t('common.completed_date')}:{' '}
                            {moment(workOrder.updated_at).format(DATE_FORMAT) ||
                              FALLBACK_TEXT}
                          </Text>
                        </View>
                      </View>
                      <>
                        {workOrder?.work_order_tasks?.map((item, index) => (
                          <View style={styles.tableRow} key={index}>
                            <View
                              style={[
                                styles.tableCell,
                                { minWidth: '250px', gap: '8px' },
                              ]}
                            >
                              <Text
                                style={styles.bold}
                              >{`${index + 1} - ${item?.asset_detail?.label}`}</Text>
                              <Text
                                style={styles.headerCellAnswer}
                              >{`${t('attributes.location')}: ${item?.asset_detail?.location || '-'}`}</Text>
                            </View>

                            {formatStatus({
                              value: item?.asset_detail?.status?.display_name,
                              textAlign: 'center',
                            }) || '-'}
                          </View>
                        ))}

                        {workOrder?.assets_defect?.map((item, index) => (
                          <View style={styles.tableRow} key={index}>
                            <View
                              style={[
                                styles.tableCell,
                                { minWidth: '250px', gap: '8px' },
                              ]}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <View
                                  style={[styles.bold, { minWidth: '250px' }]}
                                >
                                  <Text>
                                    {t('attributes.work_order.defect_severity')}
                                  </Text>
                                  <Text style={styles.headerCellAnswer}>
                                    {formatStatus({
                                      value: item?.severity,
                                      textAlign: 'left',
                                    }) || '-'}
                                  </Text>
                                </View>
                                <View style={styles.bold}>
                                  <Text>
                                    {t('attributes.work_order.status')}
                                  </Text>
                                  <Text style={styles.headerCellAnswer}>
                                    {formatStatus({
                                      value:
                                        item?.latest_status === RESOLVED
                                          ? 'resolved'
                                          : 'open',
                                      textAlign: 'left',
                                    })}
                                  </Text>
                                </View>
                              </View>

                              <Text
                                style={[styles.bold, { paddingTop: '10px' }]}
                              >
                                {t('attributes.work_order.defect_description')}
                              </Text>
                              <Text style={styles.headerCellAnswer}>
                                {item?.defect_description || '-'}
                              </Text>

                              <Text
                                break
                                style={[styles.bold, { paddingTop: '10px' }]}
                              >
                                {t('attributes.work_order.photos')}
                              </Text>

                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: '8px',
                                  flexWrap: 'wrap',
                                }}
                              >
                                {item?.documents?.map(
                                  (doc) =>
                                    doc?.document_type === DEFECT_STATUS && (
                                      <View key={doc?.document_uuid}>
                                        {getImageSource({
                                          document_uuid: doc?.document_uuid,
                                        })}
                                      </View>
                                    )
                                )}
                              </View>
                            </View>
                          </View>
                        ))}
                      </>
                    </View>

                    {/* Footer */}
                    <View style={[styles.footer, { padding: '10' }]}>
                      <View>
                        <Text style={styles.bold}>
                          {t('attributes.technician.technician_name')}:{' '}
                          <Text
                            style={[styles.normal, styles.headerCellAnswer]}
                          >
                            {workOrder?.technician?.technician_name ||
                              FALLBACK_TEXT}
                          </Text>
                        </Text>
                        <Text style={styles.bold}>
                          {t('attributes.work_order.performed_date')}:{' '}
                          {workOrder?.work_order_pre_check_list?.[0] && (
                            <Text
                              style={[styles.headerCellAnswer, styles.normal]}
                            >
                              {moment(
                                workOrder?.work_order_pre_check_list[0]
                                  ?.signature_time
                              ).format(DATE_FORMAT) || FALLBACK_TEXT}
                            </Text>
                          )}
                        </Text>
                      </View>
                      <View>
                        <Text style={styles.bold}>
                          {t('attributes.work_order.technician_signature')}:
                        </Text>
                        {workOrder?.work_order_pre_check_list?.[0] &&
                          getImageSource({
                            document_uuid:
                              workOrder?.work_order_pre_check_list[0]
                                ?.technician_signature_document_uuid,
                            isSignatureImage: true,
                          })}
                      </View>
                    </View>
                  </>
                )}
          </View>
        </View>
      </Page>
    </Document>
  );
};

const generatePDF = async (
  workOrder,
  imgSrc,
  setIsDownloadingRectificationPDF,
  company
) => {
  try {
    setIsDownloadingRectificationPDF(true);
    const blob = await pdf(
      <DownloadPDF workOrder={workOrder} imgSrc={imgSrc} company={company} />
    ).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.download = `${moment().format(DATE_FORMAT)}-${DEFAULT_REPORT_NAME}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error generating PDF:', error);
  } finally {
    setIsDownloadingRectificationPDF(false);
  }
};

export default generatePDF;
