import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { DATE_FORMAT_API } from '../../constants/Constants';
import { formattedDate } from '../../utils';
import { PURCHASE_ORDER } from '../actionPrefix';
import { axiosInstanceTechnician } from '../axios';

export const getPurchaseOrders = createAsyncThunk(
  PURCHASE_ORDER.GET_PURCHASE_ORDER_LIST,
  async (
    {
      page,
      search,
      size,
      limit,
      work_order_uuid,
      company_uuid,
      supplier_uuid,
      status,
      order,
      orderBy,
      list_column_names,
      createdAtDateFrom,
      createdAtDateTo,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);
    if (work_order_uuid) params.append('work_order_uuid', work_order_uuid);
    if (company_uuid) params.append('company_uuid', company_uuid);
    if (supplier_uuid) params.append('supplier_uuid', supplier_uuid);
    if (status) params.append('status', status);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (list_column_names)
      params.append('list_column_names', list_column_names);
    if (createdAtDateFrom)
      params.append(
        'creation_date__gte',
        formattedDate(createdAtDateFrom, DATE_FORMAT_API)
      );
    if (createdAtDateTo)
      params.append(
        'creation_date__lte',
        formattedDate(createdAtDateTo, DATE_FORMAT_API)
      );

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.PURCHASE_ORDER.GET_PURCHASE_ORDER_LIST}?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createPurchaseOrder = createAsyncThunk(
  PURCHASE_ORDER.CREATE_PURCHASE_ORDER,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        APIs.PURCHASE_ORDER.CREATE_PURCHASE_ORDER,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createPurchaseOrderProduct = createAsyncThunk(
  PURCHASE_ORDER.CREATE_PURCHASE_ORDER_PRODUCT,
  async ({ purchase_order_id, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        APIs.PURCHASE_ORDER.CREATE_PURCHASE_ORDER_PRODUCT.replace(
          '{purchase_order}',
          purchase_order_id
        ),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePurchaseOrderProduct = createAsyncThunk(
  PURCHASE_ORDER.UPDATE_PURCHASE_ORDER_PRODUCT,
  async (
    { purchase_order_id, purchase_order_item_uuid, data },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstanceTechnician.put(
        APIs.PURCHASE_ORDER.UPDATE_PURCHASE_ORDER_PRODUCT.replace(
          '{purchase_order}',
          purchase_order_id
        ).replace('{purchase_order_item_uuid}', purchase_order_item_uuid),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deletePurchaseOrderProduct = createAsyncThunk(
  PURCHASE_ORDER.DELETE_PURCHASE_ORDER_PRODUCT,
  async (
    { purchase_order_id, purchase_order_item_uuid },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstanceTechnician.delete(
        APIs.PURCHASE_ORDER.UPDATE_PURCHASE_ORDER_PRODUCT.replace(
          '{purchase_order}',
          purchase_order_id
        ).replace('{purchase_order_item_uuid}', purchase_order_item_uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePurchaseOrder = createAsyncThunk(
  PURCHASE_ORDER.UPDATE_PURCHASE_ORDER,
  async ({ purchase_order_id, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.put(
        APIs.PURCHASE_ORDER.UPDATE_PURCHASE_ORDER.replace(
          '{purchase_order_uuid}',
          purchase_order_id
        ),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addPurchaseOrderInvoiceBill = createAsyncThunk(
  PURCHASE_ORDER.CREATE_PURCHASE_ORDER_INVOICE_BILL,
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        APIs.PURCHASE_ORDER.CREATE_PURCHASE_ORDER_INVOICE_BILL,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPurchaseOrderInvoiceBillList = createAsyncThunk(
  PURCHASE_ORDER.GET_PURCHASE_ORDER_INVOICE_BILL_LIST,
  async ({ purchase_order_uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.get(
        APIs.PURCHASE_ORDER.GET_PURCHASE_ORDER_INVOICE_BILL_LIST.replace(
          '{purchase_order_uuid}',
          purchase_order_uuid
        )
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPurchaseOrderInvoiceBill = createAsyncThunk(
  PURCHASE_ORDER.GET_PURCHASE_ORDER_INVOICE_BILL,
  async ({ purchase_order_bill_uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.get(
        APIs.PURCHASE_ORDER.GET_PURCHASE_ORDER_INVOICE_BILL.replace(
          '{purchase_order_bill_uuid}',
          purchase_order_bill_uuid
        )
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
