import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Box, Divider } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';

import { DIVIDER_COLOR } from '../../constants/Colors';
import { ROUTE_NAVIGATE_CONST } from '../../constants/Constants';
import { ROUTENAME } from '../../constants/RoutesConstants';
import { getTechnicianList } from '../../store/technician/api';
import { getRedirectURL, loggedInUserRole } from '../../utils';
import { PerformanceIcon } from '../CommonComponents/ActionComponent';
import { CustomMonthPicker } from '../CommonComponents/CustomDatePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import { RefreshDashboardButton } from '../CommonComponents/FilterButton';
import MainWrapper from '../CommonComponents/MainWrapper';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import CustomSearch from '../CommonComponents/Search';

const TechnicianPerformance = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { parentMonth, setParentMonth } = useOutletContext();

  const role = loggedInUserRole();

  const [isDataLoading, setIsDataLoading] = useState(true);

  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [total, setTotal] = useState(0);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('technician_name');

  const [technician, setTechnician] = useState(null);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'technician_name',
        headerName: t('attributes.technician.technician_name'),
        flex: 1,
        sortable: true,
      },
      {
        field: 'technician_email',
        headerName: t('attributes.email'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'total_work_order_assigned',
        headerName: t('attributes.work_order.work_order'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'work_order_completed_on_time',
        headerName: t('attributes.work_order.work_order_completed'),
        flex: 1,
        sortable: false,
      },

      {
        field: 'edit',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <PerformanceIcon
            onClick={() => {
              navigate(`${ROUTENAME.CHARTS}?id=${row?.uuid}`);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  const rows = technician?.map((t) => ({
    ...t,
    id: t?.uuid,
    total_work_order_assigned: t?.total_work_order_assigned || 0,
    work_order_completed_on_time: t?.work_order_completed_on_time || 0,
  }));

  const getAllTechnician = useCallback(() => {
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);

    dispatch(
      getTechnicianList({
        size: perPageData,
        page: currentPage,
        search: searchText,
        order: order,
        orderBy: orderBy,
        list_column_names: visibleFieldsString,
        workOrderYear: moment(parentMonth).year(),
        workOrderMonth: moment(parentMonth).month() + 1,
      })
    )
      .then((res) => {
        setTechnician(res?.payload?.data);
        setTotal(res?.payload?.pagination?.total_items);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [
    perPageData,
    currentPage,
    order,
    orderBy,
    searchText,
    columns,
    columnVisibilityModel,
    parentMonth,
  ]);

  // Reset pagination on page size, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllTechnician, 500), [
    getAllTechnician,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const handleDefaultStep = () => {
    navigate(getRedirectURL()[role][ROUTE_NAVIGATE_CONST.REPORTING]);
  };

  return (
    <>
      <Box sx={{ backgroundColor: DIVIDER_COLOR }}>
        <MainWrapper
          defaultPadding="0px 0px 16px 0px"
          title={t('attributes.reports.reporting')}
          variant="body1"
          isStep={true}
          step={t('attributes.reports.technician_performance')}
          handleDefaultStep={handleDefaultStep}
        />
      </Box>
      <Box sx={{ height: 'calc(100vh - 120px)' }}>
        <Box sx={{ padding: '16px', position: 'relative' }}>
          <CustomMonthPicker
            name="selected_month"
            value={parentMonth}
            setValue={setParentMonth}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            columnGap: '8px',
            padding: '16px',
          }}
        >
          <RefreshDashboardButton
            label={t('common.refresh')}
            onClick={getAllTechnician}
          />
          <CustomSearch
            value={searchText}
            onChange={(e) => setSearchText(e?.target?.value)}
          />
        </Box>

        <Box sx={{ padding: '16px' }}>
          <CustomGridTable
            columns={columns}
            rows={rows}
            total={total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPageData={perPageData}
            setPerPageData={setPerPageData}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
            noData={<NoRecordFound />}
            isLoading={isDataLoading}
            checkboxSelection={false}
            filterHeight={270}
          />
        </Box>
      </Box>
    </>
  );
};

export default TechnicianPerformance;
