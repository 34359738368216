import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { format } from 'd3-format';
import { uniqueId } from 'lodash';

import { GLOBAL_ADMIN } from '../../../constants/Roles';
import { getUserRole } from '../../../hoc/Permission';
import { NoData } from '../PieChart/PieChart';

const CustomBarChart = ({
  data,
  xLabels,
  colors,
  onChartClick = () => {},
  isFullLabel = false,
  isYAxis = true,
  isAmount = false,
  isLegend = true,
  isRedirect = true,
}) => {
  const navigate = useNavigate();

  return (
    <>
      {getUserRole() !== GLOBAL_ADMIN && isLegend && data?.length > 0 && (
        <Box
          display="flex"
          columnGap={0.5}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginTop: '8px',
          }}
        >
          {xLabels?.map((label, index) => (
            <Box
              key={uniqueId(label)}
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={0.5}
            >
              <Box width={12} height={12} bgcolor={colors[index]} />
              <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                {label}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
      <Box
        sx={{
          height: `${data?.length > 0 ? 'calc(100% - 8px)' : '100%'}`,
          width: '100%',
        }}
      >
        <BarChart
          xAxis={[
            {
              scaleType: 'band',
              data: xLabels?.map((label) =>
                isFullLabel
                  ? label
                  : label?.length > 9
                    ? `${label?.slice(0, 6)}...`
                    : label
              ),
              categoryGapRatio: 0.3,
              barGapRatio: -1,
              colorMap: {
                type: 'ordinal',
                colors: colors,
              },
              tickPlacement: 'middle',
            },
          ]}
          margin={{ top: 16 }}
          series={[{ data: data }]}
          leftAxis={!isYAxis ? null : { min: 0 }}
          onItemClick={(e, data) => {
            if (isRedirect) {
              const redirectTo = onChartClick(data?.dataIndex);

              if (redirectTo) {
                navigate(redirectTo);
              }
            } else {
              onChartClick(data);
            }
          }}
          yAxis={[
            {
              valueFormatter: (val) => (isAmount ? format('.0s')(val) : val),
            },
          ]}
          slots={{
            noDataOverlay: NoData,
          }}
          tooltip={{ trigger: data?.length > 0 ? 'axis' : 'none' }}
        />
      </Box>
    </>
  );
};

export default CustomBarChart;
