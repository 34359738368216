import { createSlice } from '@reduxjs/toolkit';

import { TAB_STATUS } from '../../constants/Constants';
import {
  createTechnician,
  getTechnicianById,
  getTechnicianList,
  getTechnicianNamesList,
  getTechnicianPerformanceData,
  updateTechnician,
} from './api';

const initialTechnicianNamesData = {
  isLoading: true,
  error: false,
  data: false,
};

const initialCreateData = {
  isLoading: false,
  error: false,
  createdTechnician: false,
};

const initialEditData = {
  isLoading: false,
  error: false,
  editTechnician: false,
};

const initialGetData = {
  isLoading: true,
  error: null,
  technicianActiveList: [],
  technicianInactiveList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const initialPerformanceData = {
  isLoading: true,
  error: null,
  data: {
    average_work_orders_perform_per_day: 0,
    average_defect_quote_per_day: 0,
    average_hours_per_day: 0,
    total_work_hours_per_month: 0,
    defects_raised: 0,
    work_order_completed_within_time_duration: 0,
    total_work_orders: 0,
    total_work_order: null,
    total_cost: 0,
    productivity: 0,
    // Charts
    work_order_completed_per_day: null,
    defect_quotes_issued_daily: null,
    daily_asset_totals: null,
    daily_defect_count: null,
  },
};

const typeMatchHandler = (type, state, data) => {
  switch (type) {
    // case 'total_work_orders':
    //   return {
    //     ...state.performance?.data,
    //     total_work_orders: data?.[0]?.total_work_orders,
    //   };

    case 'total_work_order':
      return {
        ...state.performance?.data,
        total_work_order: data?.[0]?.total_work_order, // Pie chart data
      };

    case 'defects_raised':
      return {
        ...state.performance?.data,
        defects_raised: data?.[0]?.defects_raised,
      };

    case 'average_work_orders_perform_per_day':
      return {
        ...state.performance?.data,
        average_work_orders_perform_per_day:
          data?.[0]?.average_work_orders_perform_per_day,
      };

    case 'average_defect_quote_per_day':
      return {
        ...state.performance?.data,
        average_defect_quote_per_day: data?.[0]?.average_defect_quote_per_day,
      };

    case 'average_hours_per_day':
      return {
        ...state.performance?.data,
        average_hours_per_day: data?.[0]?.average_hours_per_day,
      };

    case 'total_work_hours_per_month':
      return {
        ...state.performance?.data,
        total_work_hours_per_month:
          data?.[0]?.total_work_hours_per_month?.total_work_hours,
      };

    case 'work_order_completed_within_time_duration':
      return {
        ...state.performance?.data,
        work_order_completed_within_time_duration:
          data?.[0]?.work_order_completed_within_time_duration
            ?.work_order_completed_within_time_duration,
        total_work_orders:
          data?.[0]?.work_order_completed_within_time_duration
            ?.total_work_orders,
      };

    case 'work_order_completed_per_day':
      return {
        ...state.performance?.data,
        work_order_completed_per_day: data?.[0]?.work_order_completed_per_day, // Work Order chart
      };

    case 'defect_quotes_issued_daily':
      return {
        ...state.performance?.data,
        defect_quotes_issued_daily: data?.[0]?.defect_quotes_issued_daily, // Defect quote chart
      };

    case 'daily_asset_totals':
      return {
        ...state.performance?.data,
        daily_asset_totals: data?.[0]?.daily_asset_totals, // Assets chart
      };

    case 'daily_defect_count':
      return {
        ...state.performance?.data,
        daily_defect_count: data?.[0]?.daily_defect_count, // Defect chart
      };

    case 'productivity':
      return {
        ...state.performance?.data,
        productivity: `${Math.round(data?.[0]?.productivity?.productivity_percentage)}%`,
      };

    case 'total_cost':
      return {
        ...state.performance?.data,
        total_cost: data?.[0]?.total_cost?.[0],
      };

    default:
      return {
        ...state.performance?.data,
      };
  }
};

const technicianSlice = createSlice({
  name: 'technician',
  initialState: {
    get: { ...initialGetData },
    create: {
      ...initialCreateData,
    },
    edit: {
      ...initialEditData,
    },
    performance: { ...initialPerformanceData },
    technicianNamesDropdownList: { ...initialTechnicianNamesData },
  },
  reducers: {
    resetCreate(state) {
      state.create = { ...initialCreateData };
    },
    resetEdit(state) {
      state.edit = { ...initialEditData };
    },
    resetPagination(state) {
      state.get = { ...initialGetData };
    },
    resetTechnicianDropdownList(state) {
      state.technicianNamesDropdownList = { ...initialTechnicianNamesData };
    },
  },
  extraReducers: (builder) => {
    builder
      // Technician List
      .addCase(getTechnicianList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getTechnicianList.fulfilled, (state, action) => {
        const { pagination, data, active } = action.payload;

        state.get.isLoading = false;

        if (active === TAB_STATUS.active) {
          state.get.technicianActiveList = data;
        } else {
          state.get.technicianInactiveList = data;
        }
        state.get.total = pagination.total_items;
        state.get.totalCount = pagination.total_count;
      })
      .addCase(getTechnicianList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })
      // Create Technician
      .addCase(createTechnician.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(createTechnician.fulfilled, (state, action) => {
        state.create.error = false;
        state.create.isLoading = false;
        state.create.createdTechnician = action.payload.data[0];
      })
      .addCase(createTechnician.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Update Technician
      .addCase(updateTechnician.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(updateTechnician.fulfilled, (state, action) => {
        state.create.error = false;
        state.create.isLoading = false;
        state.create.createdTechnician = action.payload.data[0];
      })
      .addCase(updateTechnician.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Edit Technician
      .addCase(getTechnicianById.pending, (state) => {
        state.edit.isLoading = true;
      })
      .addCase(getTechnicianById.fulfilled, (state, action) => {
        state.edit.isLoading = false;
        state.edit.editTechnician = action.payload.data[0];
      })
      .addCase(getTechnicianById.rejected, (state, action) => {
        state.edit.isLoading = false;
        state.edit.error = action.payload;
      })

      // Get Technician Names List
      .addCase(getTechnicianNamesList.pending, (state) => {
        state.technicianNamesDropdownList.isLoading = true;
      })
      .addCase(getTechnicianNamesList.fulfilled, (state, action) => {
        state.technicianNamesDropdownList.isLoading = false;
        state.technicianNamesDropdownList.data = action.payload;
      })
      .addCase(getTechnicianNamesList.rejected, (state, action) => {
        state.technicianNamesDropdownList.isLoading = false;
        state.technicianNamesDropdownList.error = action.error.message;
      })
      // Get Technician Performance Data
      .addCase(getTechnicianPerformanceData.pending, (state) => {
        state.performance.isLoading = true;
      })
      .addCase(getTechnicianPerformanceData.fulfilled, (state, action) => {
        const type = action?.meta?.arg?.type;

        const matchedType = typeMatchHandler(
          type,
          state,
          action?.payload?.data
        );

        state.performance.isLoading = false;
        state.performance.data = matchedType;
      })
      .addCase(getTechnicianPerformanceData.rejected, (state, action) => {
        state.performance.isLoading = false;
        state.performance.error = action.error.message;
      });
  },
});

export const {
  resetCreate,
  resetEdit,
  resetPagination,
  resetTechnicianDropdownList,
} = technicianSlice.actions;

export default technicianSlice.reducer;
