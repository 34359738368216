import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const getDropdownListHook = ({
  reducerName = '',
  dropdownListName = '',
  labelName = '',
  valueName = '',
  loading = 'isLoading',
  customNameForDropdown = null,
  fetchDataFrom = 'data',
  concatLabelName = '',
  ...rest
}) => {
  // Get the category dropdown list from Redux store
  const stateDropdownList = useSelector((state) => {
    const dropdownList = state?.[reducerName]?.[dropdownListName];

    return (
      dropdownList?.data?.[fetchDataFrom] ||
      dropdownList?.[fetchDataFrom] ||
      dropdownList ||
      []
    );
  });

  // Get the loading state from Redux store
  const isLoading = useSelector(
    (state) => state?.[reducerName]?.[dropdownListName]?.[loading]
  );

  // Memoize the transformation of category data
  const data = useMemo(() => {
    if (!Array.isArray(stateDropdownList) || stateDropdownList?.length === 0) {
      return [];
    } else {
      return stateDropdownList?.map((item) => {
        const concatLabel = concatLabelName ? `, ${item[concatLabelName]}` : '';

        return {
          label: `${item[labelName]}${concatLabel}`,
          value: item[valueName],
          ...(rest
            ? Object.entries(rest).reduce((acc, [key, value]) => {
                acc[key] = item[value];

                return acc;
              }, {})
            : {}),
        };
      });
    }
  }, [stateDropdownList]);

  const customName = customNameForDropdown || reducerName;

  // Return both the loading status and the transformed data
  return {
    [`${customName}DropdownLoading`]: isLoading,
    [`${customName}DropdownData`]: data,
  };
};

export default getDropdownListHook;
