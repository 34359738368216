import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../assets/commonStyled';
import { WARNING } from '../../constants/Colors';
import { FILE_UPLOAD_SIZES } from '../../constants/Constants';
import { Validation } from '../../constants/FieldValidationMsg';
import { uploadDocument } from '../../store/CommonAPI';
import { resetDocumentData } from '../../store/CommonReducer';
import { getFloorPlanById } from '../../store/floorPlan/api';
import { resetFloorPlanEdit } from '../../store/floorPlan/reducer';
import CustomFileUploadButton from '../CommonComponents/CustomFileUploadButton';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';
import { ConfirmationModal } from '../CommonComponents/Modal';
import UploadedDocumentView from '../CommonComponents/UploadedDocumentView';

const FloorPlanForm = ({ id, reset, control, trigger, watch, setValue }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading, data: editFloorPlan } = useSelector(
    (state) => state.floorPlan.edit
  );
  const { isDocumentLoading, uploadedSuccessfully, documentData } = useSelector(
    (state) => state.common
  );

  const [documentError, setdocumentError] = useState('');

  const [isUploadingOther, setIsUploadingOther] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState({
    name: '',
    size: '',
  });
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const handleFileChange = (type) => (event, onChange) => {
    const file = event.target.files[0];
    const isValidFile = file && file.type === 'image/png';

    if (file?.size > FILE_UPLOAD_SIZES.DOCUMENT) {
      setdocumentError(t('message.customer.uploadDocumentMaxSizeError'));

      return;
    }

    if (isValidFile) {
      onChange(file);
      const fileSize = (file.size / 1024).toFixed(0) + ' KB';

      setIsUploadingOther(true);
      setUploadedDocuments({
        name: file?.name,
        size: fileSize,
      });
      setdocumentError('');

      dispatch(
        uploadDocument({ document_type: type, uploaded_by: 1, file: file })
      );
    } else {
      setdocumentError('Please upload a PNG file format.');
    }
  };

  useEffect(() => {
    if (!isDocumentLoading && uploadedSuccessfully) {
      setIsUploadingOther(false);
      setValue('document_uuid', {
        document_uuid: documentData?.uuid,
        document_type: documentData?.document_type,
        document_name: documentData?.document_name,
        document_size: uploadedDocuments?.size,
      });
    }
  }, [isDocumentLoading, uploadedSuccessfully]);

  const handleDeleteDocument = () => {
    setUploadedDocuments({
      name: '',
      size: '',
    });
    setValue('document_uuid', null);
    const input = document.querySelector(`input[name=document_uuid]`);

    if (input) {
      input.value = ''; // Reset the file input
    }

    setdocumentError('');
  };

  useEffect(() => () => dispatch(resetDocumentData()), []);

  useEffect(() => {
    if (id) {
      dispatch(getFloorPlanById(id));
    }

    return () => {
      dispatch(resetFloorPlanEdit());
    };
  }, []);

  useEffect(() => {
    if (!isLoading && editFloorPlan && id) {
      reset({
        label: editFloorPlan?.label,
        floor_no: editFloorPlan?.floor_no,
        document_uuid: { document_name: editFloorPlan?.document_uuid },
      });
    }
  }, [isLoading, editFloorPlan]);

  const clearHandler = (name) => setValue(name, '');

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <>
      <FormWrapper>
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1">
              {t('attributes.property.floorPlan')}
            </Typography>
          </Box>
          <Divider />
          <FormFieldWrapper>
            <Controller
              name="label"
              control={control}
              rules={{
                required: `${t('attributes.property.levelName')} ${Validation.general.required}`,
                maxLength: {
                  value: 80,
                  message: Validation.property.levelName,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.property.levelName')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('label');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
            <Controller
              name="floor_no"
              control={control}
              rules={{
                required: `${t('attributes.property.levelNum')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.property.levelNum')}
                  fullWidth
                  type="number"
                  value={value}
                  onChange={(e, newValue) => {
                    onChange(e?.target?.value);
                    trigger('floor_no');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
          </FormFieldWrapper>
        </CustomCard>
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1">
              {t('common.attach_documnet')}
              <span style={{ color: WARNING }}>*</span>
            </Typography>
          </Box>
          <Divider />
          <FormFieldWrapper>
            <Controller
              name="document_uuid"
              control={control}
              rules={{
                required: Validation.general.fileUpload,
              }}
              render={({
                field: { onChange, name, value },
                fieldState: { error },
              }) => (
                <>
                  <CustomFileUploadButton
                    name={name}
                    onFileChange={(event) => {
                      handleFileChange('other')(event, onChange);
                      trigger('document_uuid');
                    }}
                    multiple={false}
                    buttonText={t('attributes.uploadPNGFileBtnText')}
                    accept="image/png"
                    error={!!documentError || error}
                    errorMessage={documentError || error?.message}
                  />
                  {((!isEmpty(value) && value?.document_name) ||
                    uploadedDocuments?.name !== '') && (
                    <UploadedDocumentView
                      fileName={
                        isUploadingOther
                          ? uploadedDocuments?.name
                          : value?.document_name || ''
                      }
                      fileSize={
                        isUploadingOther
                          ? uploadDocument?.size
                          : value?.document_size
                      }
                      isUploading={isUploadingOther}
                      onDelete={() => setOpenConfirmationModal(true)}
                    />
                  )}
                </>
              )}
            />
          </FormFieldWrapper>
        </CustomCard>
      </FormWrapper>
      {openConfirmationModal && (
        <ConfirmationModal
          title={`${t('attributes.delete')} ${t('attributes.document')}`}
          description={t('common.delete_document_confirmation')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            handleDeleteDocument('other');
            setOpenConfirmationModal(false);
          }}
        />
      )}
    </>
  );
};

export default FloorPlanForm;
