import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Card, Divider, Typography } from '@mui/material';
import moment from 'moment';

import {
  DIVIDER_COLOR,
  PRIMARY,
  PURPLE,
  RED,
  YELLOW,
} from '../../constants/Colors';
import {
  LABELS,
  ROUTE_NAVIGATE_CONST,
  TECHNICIAN_PERFORMANCE_KEY,
  WORK_ORDER_TYPE_ADHOC,
  WORK_ORDER_TYPE_REPAIR,
  WORK_ORDER_TYPE_ROUTINE,
} from '../../constants/Constants';
import useDetailPageValidation from '../../hooks/useDetailPageValidation';
import {
  getTechnicianById,
  getTechnicianPerformanceData,
} from '../../store/technician/api';
import { resetEdit } from '../../store/technician/reducer';
import { getRedirectURL, loggedInUserRole } from '../../utils';
import CustomBarChart from '../Chart/BarChart/CustomBarChart';
import PieChart from '../Chart/PieChart/PieChartWithLegendBelow';
import StackedBarChart from '../Chart/StackedBarChart/StackedBarChart';
import { CustomMonthPicker } from '../CommonComponents/CustomDatePicker';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import DashboardCard from '../CommonComponents/DashboardCard';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import CommonDrawerData from './CommonDrawerData';

const TechnicianPerformanceCharts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const technicianId = searchParams.get('id');

  const { parentMonth } = useOutletContext();

  const role = loggedInUserRole();

  const [month, setMonth] = useState(parentMonth || moment());
  const [xAxisLabels, setXAxisLabels] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  // Chart Data
  const [pieChartData, setPieChartData] = useState([]);

  const [defectQuotesData, setDefectQuotesData] = useState(null);
  const [defectsData, setDefectsData] = useState(null);
  const [assetsData, setAssetsData] = useState(null);
  const [workOrderData, setWorkOrderData] = useState(null);

  // Sidebar
  const [isOpen, setIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({
    title: '',
    selectedDate: null,
    type: '',
  });

  const { editTechnician, isLoading, error } = useSelector(
    (state) => state.technician.edit
  );

  useDetailPageValidation({
    is_error: true,
    error: error,
    is_loading: isLoading,
  });

  const { data, isLoading: chartDataLoading } = useSelector(
    (state) => state.technician.performance
  );

  const defaultBredcrum = [
    {
      title: t('attributes.reports.reporting'),
      onClick: () =>
        navigate(getRedirectURL()[role][ROUTE_NAVIGATE_CONST.REPORTING]),
    },
    {
      title: t('attributes.reports.technician_performance'),
      onClick: () =>
        navigate(
          getRedirectURL()[role][
            ROUTE_NAVIGATE_CONST.TECHNICIAN_PERFORMANCE_LIST
          ]
        ),
    },
    {
      title: !isLoading && editTechnician?.technician_name,
      isLastStep: true,
    },
  ];

  const technicianPerformanceCard = [
    {
      title: t(
        'attributes.technician.dashboard.average_work_order_performance_per_day'
      ),
      value: data?.average_work_orders_perform_per_day
        ? Math.round(data?.average_work_orders_perform_per_day)
        : 0,
    },
    {
      title: t('attributes.technician.dashboard.average_defect_quote_per_day'),
      value: data?.average_defect_quote_per_day
        ? Math.round(data?.average_defect_quote_per_day)
        : 0,
    },
    {
      title: t('attributes.technician.dashboard.average_hours_per_day'),
      value: data?.average_hours_per_day
        ? Math.round(data?.average_hours_per_day)
        : 0,
    },
    {
      title: t('attributes.technician.dashboard.total_work_hours'),
      value: data?.total_work_hours_per_month
        ? Math.round(data?.total_work_hours_per_month)
        : 0,
    },
    {
      title: t('attributes.technician.dashboard.defect_raised'),
      value: data?.defects_raised ? Math.round(data?.defects_raised) : 0,
    },
    {
      title: t(
        'attributes.technician.dashboard.work_order_completed_within_time_duration'
      ),
      value: `${data?.work_order_completed_within_time_duration}/${data?.total_work_orders}`,
    },
    {
      title: t('attributes.technician.dashboard.total_cost'),
      value: data?.total_cost ? Math.floor(data?.total_cost) : 0,
    },
    {
      title: t('attributes.technician.dashboard.productivity'),
      value: data?.productivity,
    },
  ];

  const colors = [PRIMARY, YELLOW, RED];

  const chartsData = [
    {
      title: t('attributes.technician.dashboard.work_orders_completed_per_day'),
      data: workOrderData,
      colors: colors,
      xLabels: xAxisLabels,
      isStackedChart: true,
      type: 'work_orders',
    },
    {
      title: t('attributes.technician.dashboard.defect_quotes_issued_per_day'),
      data: defectQuotesData,
      colors: [RED],
      xLabels: xAxisLabels,
      type: 'defect_quotes',
    },
    // It may comes in future
    // {
    //   title: t('attributes.technician.dashboard.asset_serviced_per_day'),
    //   data: assetsData,
    //   colors: [TURQUOISE],
    //   xLabels: xAxisLabels,
    //   type: 'assets',
    // },
    {
      title: t('attributes.technician.dashboard.defect_raised_per_day'),
      data: defectsData,
      colors: [PURPLE],
      xLabels: xAxisLabels,
      type: 'defects',
    },
  ];

  // API calls
  useEffect(() => {
    let getAllData = false;

    setDataLoading(true);

    for (let index = 0; index < TECHNICIAN_PERFORMANCE_KEY.length; index++) {
      dispatch(
        getTechnicianPerformanceData({
          start_date: moment(month).startOf('month'),
          end_date: moment(month).endOf('month'),
          technician_uuid: technicianId,
          type: TECHNICIAN_PERFORMANCE_KEY[index],
        })
      );
      if (index + 1 === TECHNICIAN_PERFORMANCE_KEY.length) {
        getAllData = true;
      }
    }

    if (getAllData) {
      setDataLoading(false);
    }
  }, [month]);

  // X Axis Labels
  useEffect(() => {
    const totalDaysInMonth = moment(month).daysInMonth();
    const labels = [];
    // Chart data
    // WO start
    const repairCounts = [];
    const routineCounts = [];
    const adhocCounts = [];
    // WO end

    const defectQuoteCounts = [];
    const defectCounts = [];
    const assetsCounts = [];

    // Piechart Data
    const pieData = [
      {
        label: LABELS.ROUTINE_SERVICE,
        value:
          data?.total_work_order?.find(
            (r) => r?.work_order_type === WORK_ORDER_TYPE_ROUTINE
          )?.count || 0,
        color: PRIMARY,
      },
      {
        label: LABELS.REPAIR,
        value:
          data?.total_work_order?.find(
            (r) => r?.work_order_type === WORK_ORDER_TYPE_REPAIR
          )?.count || 0,
        color: YELLOW,
      },
      {
        label: LABELS.AD_HOC,
        value:
          data?.total_work_order?.find(
            (r) => r?.work_order_type === WORK_ORDER_TYPE_ADHOC
          )?.count || 0,
        color: RED,
      },
    ];

    for (let i = 0; i < totalDaysInMonth; i++) {
      const currentdate = moment(month).startOf('month').add(i, 'days');

      labels.push(`${currentdate.format('D')}\n${currentdate.format('ddd')}`);

      // Work Order Completed
      if (data?.work_order_completed_per_day?.length > 0) {
        // Get data for same date
        const matchedData = data?.work_order_completed_per_day?.filter((d) =>
          moment(d?.completion_date, 'YYYY-MM-DD')?.isSame(currentdate, 'day')
        );

        const routine =
          matchedData?.find(
            (m) => m?.work_order_type === WORK_ORDER_TYPE_ROUTINE
          )?.work_order_count || 0;

        const repair =
          matchedData?.find(
            (m) => m?.work_order_type === WORK_ORDER_TYPE_REPAIR
          )?.work_order_count || 0;

        const ad_hoc =
          matchedData?.find((m) => m?.work_order_type === WORK_ORDER_TYPE_ADHOC)
            ?.work_order_count || 0;

        routineCounts.push(routine);
        repairCounts.push(repair);
        adhocCounts.push(ad_hoc);
      }

      // Defect Quotes
      if (data?.defect_quotes_issued_daily?.length > 0) {
        const matchedData = data?.defect_quotes_issued_daily?.find((d) =>
          moment(d?.quote_date, 'YYYY-MM-DD')?.isSame(currentdate, 'day')
        );

        defectQuoteCounts?.push(matchedData?.quote_count || 0);
      }
      // Defects
      if (data?.daily_defect_count?.length > 0) {
        const matchedData = data?.daily_defect_count?.find((d) =>
          moment(d?.date, 'YYYY-MM-DD')?.isSame(currentdate, 'day')
        );

        defectCounts?.push(matchedData?.defect_count || 0);
      }
      // Assets
      if (data?.daily_asset_totals?.length > 0) {
        const matchedData = data?.daily_asset_totals?.find((d) =>
          moment(d?.date, 'YYYY-MM-DD')?.isSame(currentdate, 'day')
        );

        assetsCounts?.push(matchedData?.total_assets || 0);
      }
    }

    const chartData = [
      {
        data: routineCounts,
        label: LABELS.ROUTINE_SERVICE,
        id: WORK_ORDER_TYPE_ROUTINE,
        stack: 'total',
      },
      {
        data: repairCounts,
        label: LABELS.REPAIR,
        id: WORK_ORDER_TYPE_REPAIR,
        stack: 'total',
      },
      {
        data: adhocCounts,
        label: LABELS.AD_HOC,
        id: WORK_ORDER_TYPE_ADHOC,
        stack: 'total',
      },
    ];

    setXAxisLabels(labels);
    setWorkOrderData(chartData);
    setDefectQuotesData(defectQuoteCounts);
    setDefectsData(defectCounts);
    setAssetsData(assetsCounts);
    setPieChartData(pieData);
  }, [month, data]);

  // Technician Detail
  useEffect(() => {
    if (technicianId) {
      dispatch(getTechnicianById(technicianId));
    }
  }, [technicianId]);

  // Reset Reducer
  useEffect(
    () => () => {
      dispatch(resetEdit());
    },
    []
  );

  return dataLoading || chartDataLoading ? (
    <CustomCircularLoader />
  ) : (
    <>
      <Box
        sx={{
          backgroundColor: DIVIDER_COLOR,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 0 16px 0',
        }}
      >
        <Box
          sx={{
            backgroundColor: DIVIDER_COLOR,
            display: 'flex',
            alignItems: 'center',
            columnGap: '8px',
          }}
        >
          {defaultBredcrum?.map((br, index) => {
            if (br?.isLastStep) {
              return (
                <Typography variant={'h2'} key={index}>
                  {br?.title}
                </Typography>
              );
            }

            return (
              <React.Fragment key={index}>
                <Typography
                  variant="h2"
                  onClick={br?.onClick}
                  sx={{ cursor: 'pointer', color: PRIMARY }}
                >
                  {br?.title}
                </Typography>
                <ArrowForwardIosIcon sx={{ height: '12px', width: '12px' }} />
              </React.Fragment>
            );
          })}
        </Box>
        <CustomMonthPicker
          name="selected_month"
          value={month}
          setValue={setMonth}
        />
      </Box>
      <Box
        sx={{
          bgcolor: DIVIDER_COLOR,
          display: 'flex',
          flexDirection: 'column',
          rowGap: '16px',
          paddingBottom: '16px',
        }}
      >
        {/* Card Data */}
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            width: '100%',
          }}
        >
          <Card sx={{ flex: '1', maxWidth: '100%', minWidth: '280px' }}>
            <Box sx={{ padding: '8px' }}>
              <Typography variant="body1">
                {t('attributes.technician.dashboard.total_work_order')}
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ height: '350px' }}>
              <PieChart data={pieChartData} />
            </Box>
          </Card>

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '16px',
            }}
          >
            {technicianPerformanceCard?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  flex: '0 1 calc(33.333% - 16px)',
                  minWidth: '280px',
                }}
              >
                <DashboardCard
                  title={item.title}
                  value={item.value}
                  isLoading={false}
                />
              </Box>
            ))}
          </Box>
        </Box>
        {/* Charts */}
        {chartsData?.map((ch, index) => (
          <Card key={index}>
            <Box sx={{ padding: '8px' }}>
              <Typography variant="body1">{ch.title}</Typography>
            </Box>
            <Divider />
            <Box sx={{ height: '350px' }}>
              {ch?.isStackedChart ? (
                <StackedBarChart
                  data={ch?.data}
                  colors={ch?.colors}
                  xLabels={ch?.xLabels}
                  onChartClick={(data) => {
                    setSelectedData({
                      title: ch?.title,
                      selectedDate: month.date(data?.dataIndex + 1),
                      type: ch?.type,
                    });
                    setIsOpen(true);
                  }}
                />
              ) : (
                <CustomBarChart
                  data={ch?.data}
                  xLabels={ch?.xLabels}
                  colors={ch?.colors}
                  isLegend={false}
                  isRedirect={false}
                  onChartClick={(data) => {
                    setSelectedData({
                      title: ch?.title,
                      selectedDate: month.date(data?.dataIndex + 1),
                      type: ch?.type,
                    });
                    setIsOpen(true);
                  }}
                />
              )}
            </Box>
          </Card>
        ))}
      </Box>

      {isOpen && (
        <SwipeableDrawer
          open={open}
          title={selectedData?.title}
          onClose={() => setIsOpen(false)}
          width="1400px"
        >
          <CommonDrawerData
            date={selectedData?.selectedDate}
            type={selectedData?.type}
            technician_uuid={technicianId}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default TechnicianPerformanceCharts;
