import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ROUTE_NAVIGATE_CONST } from '../constants/Constants';
import { GLOBAL_ADMIN } from '../constants/Roles';
import { ROUTENAME } from '../constants/RoutesConstants';
import {
  getFormattedCompanyName,
  getRedirectURL,
  loggedInUserRole,
} from '../utils';

const useCompanyNameURLValidationHook = ({ isAuthenticated = true }) => {
  const { company_name } = useParams();
  const defaultRoute =
    getRedirectURL()?.[loggedInUserRole()]?.[ROUTE_NAVIGATE_CONST.DASHBOARD];
  const navigate = useNavigate();

  useEffect(() => {
    let updatedPath = location.pathname;

    if (
      company_name !== getFormattedCompanyName() &&
      loggedInUserRole() !== GLOBAL_ADMIN &&
      isAuthenticated
    ) {
      // Construct the new correct URL dynamically
      updatedPath = location.pathname.replace(
        company_name,
        getFormattedCompanyName()
      );
    }
    if (
      updatedPath === `/${getFormattedCompanyName()}` &&
      loggedInUserRole() !== GLOBAL_ADMIN &&
      isAuthenticated
    ) {
      updatedPath = defaultRoute;
    }

    if (
      updatedPath !== location.pathname &&
      loggedInUserRole() !== GLOBAL_ADMIN &&
      isAuthenticated
    ) {
      navigate(updatedPath, { replace: true });
    }

    if (
      company_name &&
      loggedInUserRole() === GLOBAL_ADMIN &&
      isAuthenticated
    ) {
      navigate(`/${ROUTENAME.NOT_FOUND}`);
    }
  }, [
    company_name,
    getFormattedCompanyName(),
    location.pathname,
    navigate,
    isAuthenticated,
  ]);
};

export default useCompanyNameURLValidationHook;
