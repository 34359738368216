import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import EastOutlinedIcon from '@mui/icons-material/EastOutlined';

import {
  BusinessCentralIntegration,
  GoogleIndoorMap,
  GoogleMap,
  SSOLogo,
  XeroLogo,
} from '../../assets/images';
import { DIVIDER_COLOR } from '../../constants/Colors';
import { GLOBAL_ADMIN, SUPER_ADMIN } from '../../constants/Roles';
import { ROUTENAME } from '../../constants/RoutesConstants';
import { getUserRole } from '../../hoc/Permission';
import { getAccountPartnerConfiguration } from '../../store/integrations/api';
import { resetGetAccountPartnerConfiguration } from '../../store/integrations/reducer';
import Cards from '../CommonComponents/CustomCards';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';

const Integrations = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const loggedInUserRole = getUserRole();
  const dispatch = useDispatch();

  const [xeroStatus, setXeroStatus] = useState(false);
  const [dbcStatus, setDbcStatus] = useState(false);
  const [cardActive, setCardActive] = useState(false);

  useEffect(() => {
    if (loggedInUserRole === SUPER_ADMIN) {
      dispatch(getAccountPartnerConfiguration());
    }

    return () => {
      dispatch(resetGetAccountPartnerConfiguration());
    };
  }, []);

  const { isLoading, listData: getConfigurationData } = useSelector(
    (state) => state.accountConfiguration.get
  );

  useEffect(() => {
    if (!isLoading && getConfigurationData) {
      const defXeroStatus = getConfigurationData?.find(
        (config) => config.account_partner_type === 'XERO'
      )?.is_active;

      const defDbcStatus = getConfigurationData?.find(
        (config) => config.account_partner_type === 'DBC365'
      )?.is_active;

      if (!defXeroStatus && !defDbcStatus) {
        setXeroStatus(false);
        setDbcStatus(false);
      } else {
        setXeroStatus(!defXeroStatus);
        setDbcStatus(!defDbcStatus);
        setCardActive(true);
      }
    }
  }, [isLoading, getConfigurationData]);

  const cardData = {
    [GLOBAL_ADMIN]: [
      {
        onclick: () => {
          navigate(ROUTENAME.GLOBAL_ADMIN_GOOGLE_INDOOR_MAPS);
        },
        rightIcon: (
          <img
            src={GoogleIndoorMap}
            alt="Google Indoor Maps"
            height="35px"
            width="35px"
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.integrations.googleIndoorMaps'),
        description: t('attributes.integrations.googleIndoorMapsDescription'),
      },
      {
        onclick: () => {
          navigate(ROUTENAME.GLOBAL_ADMIN_SSO);
        },
        rightIcon: (
          <img src={SSOLogo} alt="SSO Logo" height="35px" width="35px" />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.integrations.ssoTitle'),
        description: t('attributes.integrations.ssoDescription'),
      },
      {
        onclick: () => {
          navigate(ROUTENAME.GLOBAL_ADMIN_GOOGLE_MAPS);
        },
        rightIcon: (
          <img src={GoogleMap} alt="Google Maps" height="35px" width="35px" />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.integrations.googleMaps'),
        description: t('attributes.integrations.googleMapsDescription'),
      },
    ],
    [SUPER_ADMIN]: [
      {
        onclick: () => {
          navigate(ROUTENAME.XERO_INTEGRATION);
        },
        rightIcon: (
          <img src={XeroLogo} alt="Xero Logo" height="35px" width="35px" />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.integrations.xero'),
        description: t('attributes.integrations.xeroDecription'),
        disabled: xeroStatus,
        cardActive: cardActive,
        inlineIconTitle: true,
      },
      {
        onclick: () => {
          navigate(ROUTENAME.BC_INTEGRATION);
        },
        rightIcon: (
          <img
            src={BusinessCentralIntegration}
            alt="Xero Logo"
            height="35px"
            width="35px"
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.integrations.bci'),
        description: t('attributes.integrations.bciDecription'),
        disabled: dbcStatus,
        cardActive: cardActive,
        inlineIconTitle: true,
      },
    ],
  };

  const DataManagementqWrapper = (
    <StyledMainWrapper
      title={t('attributes.integrations.integrations')}
      style={{ height: '100%', backgroundColor: DIVIDER_COLOR }}
    >
      <Cards
        cardData={cardData}
        role={loggedInUserRole}
        isLoading={isLoading}
        cardCount={SUPER_ADMIN ? 2 : 3}
      />
    </StyledMainWrapper>
  );

  return <>{DataManagementqWrapper}</>;
};

export default Integrations;
