import { useSelector } from 'react-redux';

import { navigate } from '../App';
import { ROUTENAME } from '../constants/RoutesConstants';
import useCompanyNameURLValidationHook from '../hooks/useCompanyNameURLValidationHook';

const AuthRoute = ({ children, type }) => {
  useCompanyNameURLValidationHook({ isAuthenticated: true });

  const { profileDetails } = useSelector((state) => state.common);

  const permissionRoute = profileDetails?.portals?.[0]?.permissions || {};

  let hasPermission = !profileDetails;

  if (profileDetails) {
    if (Array.isArray(type)) {
      hasPermission = type.some(
        (routeType) => permissionRoute?.[routeType]?.isAccessible
      );
    } else {
      hasPermission = permissionRoute?.[type]?.isAccessible;
    }
  }
  if (!hasPermission) {
    navigate(ROUTENAME.UNAUTHORIZED);
  }

  return hasPermission && children;
};

export default AuthRoute;
