import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Card } from '@mui/material';

import {
  DEFECTS,
  DEFECT_QUOTES,
  QUOTE_TYPES,
  WORK_ORDERS,
  WO_STATUS,
} from '../../constants/Constants';
import { getQuotesList } from '../../store/quotes/api';
import { getCustomerDefectList } from '../../store/users/customer/api';
import { getWorkOrderDropdownList } from '../../store/workOrder/api';
import CustomDatePicker from '../CommonComponents/CustomDatePicker';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import TableData from './TableData';

const CommonDrawerData = ({ technician_uuid, date, type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('');

  const { control, trigger, reset, watch } = useForm({
    defaultValue: { selectedDate: null },
  });

  const changedDate = watch('selectedDate');

  useEffect(() => {
    if (type === WORK_ORDERS) {
      setOrderBy('id');
    }
    if (type === DEFECTS) {
      setOrderBy('id');
    }
    if (type === DEFECT_QUOTES) {
      setOrderBy('work_order_id');
    }
  }, []);

  useEffect(() => {
    if (date) {
      reset({
        selectedDate: date,
      });
    }
  }, [date]);

  useEffect(() => {
    if (changedDate) {
      if (type === WORK_ORDERS) {
        setIsLoading(true);
        dispatch(
          getWorkOrderDropdownList({
            technician: technician_uuid,
            dueFromDate: changedDate,
            dueToDate: changedDate,
            order: order,
            orderBy: orderBy,
            latest_status__in: WO_STATUS.completed,
            limit: -1,
          })
        )
          .then((res) => setData(res?.payload?.data))
          .finally(() => setIsLoading(false));
      } else if (type === DEFECTS) {
        setIsLoading(true);

        dispatch(
          getCustomerDefectList({
            technician_uuid: technician_uuid,
            fromDate: changedDate,
            toDate: changedDate,
            order: order,
            orderBy: orderBy,
            limit: -1,
          })
        )
          .then((res) => setData(res?.payload?.data?.[0]))
          .finally(() => setIsLoading(false));
      } else if (type === DEFECT_QUOTES) {
        setIsLoading(true);
        dispatch(
          getQuotesList({
            technician_uuid: technician_uuid,
            fromCreatedAt: changedDate,
            toCreatedAt: changedDate,
            order: order,
            orderBy: orderBy,
            type: QUOTE_TYPES.defect,
            limit: -1,
          })
        )
          .then((res) => setData(res?.payload?.data))
          .finally(() => setIsLoading(false));
      }
    }
  }, [type, changedDate, order, orderBy]);

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <Card sx={{ padding: '16px', height: '100%' }}>
      <CustomDatePicker
        name="selectedDate"
        control={control}
        isRequired={true}
        label={t('attributes.date')}
        sx={{ width: '260px' }}
        trigger={trigger}
      />
      <TableData
        type={type}
        data={data}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
      />
    </Card>
  );
};

export default CommonDrawerData;
