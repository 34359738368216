import React, { useCallback, useEffect, useState } from 'react';
import { Views } from 'react-big-calendar';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SendIcon from '@mui/icons-material/Send';
import { Box } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';
import timezone from 'moment-timezone';

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  DARK_GREEN,
  DIVIDER_COLOR,
  ORANGE_YELLOW,
  PRIMARY,
  WARNING,
} from '../../constants/Colors';
import { APPROVED, SUBMITTED } from '../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  COLUMNS_TO_MAP,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
// eslint-disable-next-line
import { exportToCSV } from '../../constants/common';
import useBrowserBackButtonHandler from '../../hooks/useBrowserBackButtonHandler';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../store/CommonReducer';
import {
  approveOrRejectTimeSheet,
  createTimeSheet,
  deleteTimeSheet,
  downloadTimeSheetList,
  getTimeSheetCalenderTechnicianList,
  getTimeSheetList,
  updateTimeSheet,
} from '../../store/timesheet/api';
import {
  resetTechList,
  resetTimeSheetList,
} from '../../store/timesheet/reducer';
import { getZonesList } from '../../store/zones/api';
import { showHideTopbarLoader } from '../../utils';
import CustomButton from '../CommonComponents/CustomButton';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import StatusLabel from '../CommonComponents/StatusLabel';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import './../../assets/schedulerBoard.scss';
import TimeEntry from './TimeEntry';
import TimesheetCalendar from './TimesheetCalendar';
import TimesheetCalendarControls from './TimesheetCalendarControls';
import TimeSheetDownload from './TimesheetDownload';

export const defaultValues = {
  technician: '',
  work_order: '',
  type: '',
  date: null,
  start_time: null,
  end_time: null,
  description: '',
  // Todo: - Last 2 in dev plus test
  fullDayLeaveCheck: false,
  leaveDays: 0,
};

const filterHeight = 285;

const Timesheet = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Get the technicians list from Redux store
  const { data: calendarTechList } = useSelector(
    (state) => state.timeSheet.getTechList
  );
  // Get Create Timesheet state
  const { create, downloadList } = useSelector((state) => state.timeSheet);

  const [open, setOpen] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [openTimesheetDownload, setOpenTimesheetDownload] = useState(false);
  const [selectedTimeEntry, setSelectedTimeEntry] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [zonesDropdown, setZonesDropdown] = useState(null);
  const [selectedView, setSelectedView] = useState({
    label: 'Month',
    value: Views.MONTH,
  });
  const [date, setDate] = useState(moment().toDate());
  const [displayCalendarLoader, setDisplayCalendarLoader] = useState(true);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [downloadSelectedTechnician, setDownloadSelectedTechnician] = useState(
    []
  );
  const [doDownload, setDoDownload] = useState(false);
  const [hasFormValues, sethasFormValues] = useState(false);

  const [serverErrors, setServerErrors] = useState([]);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    trigger,
    getValues,
    setError,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: defaultValues,
  });

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  useBrowserBackButtonHandler({
    hasUnsavedChanges: hasFormValues,
    setOpenConfirmationModal,
    open,
    watchMethod: watch,
    sethasFormValues,
  });

  // Technician Start
  const getAllTechnician = useCallback(() => {
    dispatch(getTimeSheetCalenderTechnicianList(zonesDropdown?.value)).then(
      () => {
        setIsInitialLoading(false);
      }
    );
  }, [dispatch, zonesDropdown]);

  const technicianRefactoredList =
    calendarTechList.data?.map(({ uuid, display_name }) => ({
      uuid: uuid,
      displayName: display_name,
    })) || [];

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllTechnician, 500), [
    getAllTechnician,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    setSelectedTab(0);
    setSelectedTechnician(technicianRefactoredList?.[0]?.uuid || null);
  }, [calendarTechList]);

  // Technician End

  // Zones Start
  const getAllZones = useCallback(() => {
    dispatch(getZonesList({ limit: -1 }));
  }, [dispatch]);

  useEffect(() => {
    getAllZones();
  }, [getAllZones]);
  // Zones End

  // Form level error handling
  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors();
    }
  }, [serverErrors, handleServerErrors]);

  useEffect(() => {
    showHideTopbarLoader(create?.isLoading, dispatch);
    if (create?.isLoading !== null && !create?.isLoading) {
      if (create?.error) {
        if (create?.error.errorDetails) {
          setServerErrors(create?.error.errorDetails);
        }
      } else {
        if (isApproved) {
          dispatch(
            approveOrRejectTimeSheet([
              {
                uuid:
                  selectedTimeEntry?.id || create?.timesheet?.data?.[0]?.uuid,
                status: APPROVED,
                approved_remark: '',
                reject_remark: '',
              },
            ])
          ).then((res) => {
            dispatch(
              snackbarToggle({
                isOpen: true,
                isErrorMsg: false,
                msg: res?.payload?.message,
              })
            );
            setOpen(false);
            setIsApproved(false);
            dispatch(
              getTimeSheetList({
                page: 1,
                size: 1,
                limit: -1,
                technicianUUID: selectedTechnician,
              })
            );
            setSelectedTimeEntry(null);
          });
        } else {
          setOpen(false);
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: create?.timesheet?.message,
            })
          );
          dispatch(
            getTimeSheetList({
              page: 1,
              size: 1,
              limit: -1,
              technicianUUID: selectedTechnician,
            })
          );
          setSelectedTimeEntry(null);
        }
      }
    }
  }, [create?.error, create?.isLoading, create?.timeSheet]);

  useEffect(() => {
    if (doDownload) {
      dispatch(
        downloadTimeSheetList({
          technicianUUID: downloadSelectedTechnician.join(','),
          calendarStartDate: fromDate,
          calendarEndDate: toDate,
        })
      );
    }
  }, [doDownload]);

  const onDownload = (value) => {
    if (!fromDate || !toDate) {
      dispatch(
        snackbarToggle({
          isOpen: true,
          isErrorMsg: true,
          msg: t('message.timesheet.noTimerangeSelectMessage'),
        })
      );

      return;
    }

    if (downloadSelectedTechnician.length === 0) {
      dispatch(
        snackbarToggle({
          isOpen: true,
          isErrorMsg: true,
          msg: t('message.timesheet.noTechnicianSelectMessage'),
        })
      );

      return;
    }

    if (moment(toDate).diff(fromDate, 'days') > 30) {
      dispatch(
        snackbarToggle({
          isOpen: true,
          isErrorMsg: true,
          msg: t('message.timesheet.timerangeExcedMessage'),
        })
      );

      return;
    }

    if (downloadSelectedTechnician.length > 5) {
      dispatch(
        snackbarToggle({
          isOpen: true,
          isErrorMsg: true,
          msg: t('message.timesheet.technicianlistExcedMessage'),
        })
      );

      return;
    }

    setDownloadSelectedTechnician((prev) => prev.map((tech) => tech.uuid));
    setDoDownload(true);
  };

  if (!downloadList.isLoading && doDownload) {
    exportToCSV(
      downloadList.timeSheetList,
      COLUMNS_TO_EXPORT.TIMESHEET,
      EXPORT_FILE_NAMES.TIMESHEET,
      COLUMNS_TO_MAP.TIMESHEET
    );
    setFromDate(null);
    setToDate(null);
    setDownloadSelectedTechnician([]);
    setOpenTimesheetDownload(false);
    setDoDownload(false);
  }

  const onSubmit = (value) => {
    const dateOfTz = timezone.tz(value?.date, timezone.tz.guess());

    const startTime = moment(value?.start_time, ['h:mm A'])
      .format('HH:mm')
      ?.split(':');

    const endTime = moment(value?.end_time, ['h:mm A'])
      .format('HH:mm')
      ?.split(':');
    // Todo: THis 2 in dev and test
    const startHours = value.fullDayLeaveCheck ? '00' : startTime[0];
    const startMin = startTime[1];

    const endHours = value.fullDayLeaveCheck ? '00' : endTime[0];
    const endMin = endTime[1];

    // Todo: this one in dev and test
    const leaveDays = parseInt(value?.leaveDays);

    const bodyData = {
      work_order_uuid: value?.work_order?.value,
      technician_uuid: value?.technician?.value,
      type: value?.type?.value,
      task_description: value?.description,
      user_remark: '',
      start_date_time: dateOfTz
        .clone()
        ?.set({ hour: startHours, minute: startMin, second: 0, millisecond: 0 })
        .toISOString(),
      end_date_time: dateOfTz
        .clone()
        // Todo: this one in dev and test
        ?.add(leaveDays, 'day')
        ?.set({ hour: endHours, minute: endMin, second: 0, millisecond: 0 })
        .toISOString(),
      time_zone: timezone.tz.guess(),
      status: SUBMITTED,
      // Todo: This one in dev and test
      leave_days: value?.leaveDays,
    };

    if (selectedTimeEntry?.id) {
      dispatch(
        updateTimeSheet({
          timesheet_uuid: selectedTimeEntry?.id,
          data: { ...bodyData, status: value?.status },
        })
      );
    } else {
      dispatch(createTimeSheet(bodyData));
    }
  };

  const onApprove = (value) => {
    onSubmit(value);
    setIsApproved(true);
  };

  const onDelete = () => {
    setIsDeleteLoading(true);
    showHideTopbarLoader(true, dispatch);
    dispatch(deleteTimeSheet({ timesheet_uuid: selectedTimeEntry?.id }))
      .then((res) => {
        dispatch(
          getTimeSheetList({
            page: 1,
            size: 1,
            limit: -1,
            technicianUUID: selectedTechnician,
          })
        );
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: res?.payload?.message,
          })
        );

        setOpenDelete(false);
        setOpen(false);
        setSelectedTimeEntry(null);
      })
      .finally(() => {
        setIsDeleteLoading(false);
        showHideTopbarLoader(false, dispatch);
      });
  };

  // Reset everything on unmount start
  useEffect(
    () => () => {
      dispatch(resetTimeSheetList());
      dispatch(resetTechList());
      dispatch(resetTimeSheetList());
      setDoDownload(false);
      setDownloadSelectedTechnician([]);
    },
    []
  );
  // Reset everything on unmount end

  const renderTimesheet = (
    <Box
      className="timeSheet"
      sx={{ border: `1px solid ${DIVIDER_COLOR}`, borderRadius: '6px' }}
    >
      <TimesheetCalendarControls
        date={date}
        setDate={setDate}
        selectedView={selectedView}
        setSelectedView={setSelectedView}
        zonesDropdown={zonesDropdown}
        setZonesDropdown={setZonesDropdown}
        disableControls={technicianRefactoredList?.length === 0}
        setDisplayCalendarLoader={setDisplayCalendarLoader}
        selectedTechnician={selectedTechnician}
        setSelectedTechnician={setSelectedTechnician}
        displayCalendarLoader={displayCalendarLoader}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {technicianRefactoredList?.length ? (
        <TimesheetCalendar
          handleTimeSheetClick={(value) => {
            setSelectedTimeEntry(value);
            setOpen(true);
          }}
          date={date}
          onNavigate={setDate}
          selectedView={selectedView}
          displayCalendarLoader={displayCalendarLoader}
          setDisplayCalendarLoader={setDisplayCalendarLoader}
          selectedTechnician={selectedTechnician}
        />
      ) : (
        <NewNoDataPage
          icon={<EngineeringOutlinedIcon />}
          onlyTitle={true}
          customText={t('attributes.scheduleBoard.noTechForSelectedZone')}
          filterHeight={filterHeight}
        />
      )}
    </Box>
  );

  const TimeSheetWrapper = (
    <StyledMainWrapper
      title={t('attributes.timesheet.timesheet')}
      btn={
        <>
          <CustomButton
            text={`${t('attributes.add')}  ${t('attributes.timesheet.timeEntry')}`}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            color="secondary"
            onClick={() => setOpen(true)}
          />
          <CustomButton
            text={t('attributes.download')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<DownloadOutlinedIcon />}
            onClick={() => setOpenTimesheetDownload(true)}
          />
        </>
      }
    >
      {isInitialLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            minHeight: `calc(100vh - 301px)`,
            maxHeight: `calc(100vh - 301px)`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        <>
          <Box
            display={'flex'}
            justifyContent={'end'}
            // alignItems={'center'}
            height={35}
            padding={'8px 16px 0 0'}
            gap={1}
          >
            <StatusLabel
              label={t('common.status.draft')}
              color={ORANGE_YELLOW}
            />
            <StatusLabel label={t('common.status.submitted')} color={PRIMARY} />
            <StatusLabel
              label={t('common.status.approved')}
              color={DARK_GREEN}
            />
          </Box>
          <Box padding={1}>{renderTimesheet}</Box>
        </>
      )}
    </StyledMainWrapper>
  );

  return (
    <>
      {TimeSheetWrapper}
      {open && (
        <SwipeableDrawer
          open={open}
          title={
            selectedTimeEntry?.id &&
            selectedTimeEntry?.status.toLowerCase() === APPROVED
              ? `${t('attributes.view')} ${t('attributes.timesheet.timeEntry')}`
              : selectedTimeEntry?.id
                ? `${t('attributes.edit')} ${t('attributes.timesheet.timeEntry')}`
                : `${t('attributes.add')}  ${t('attributes.timesheet.timeEntry')}`
          }
          onClose={() => {
            if (selectedTimeEntry?.status.toLowerCase() !== APPROVED) {
              setOpenConfirmationModal(true);
            } else {
              setOpen(false);
              setSelectedTimeEntry(null);
            }
          }}
          footerButton={
            selectedTimeEntry?.status.toLowerCase() !== APPROVED && (
              <Box sx={{ display: 'flex', columnGap: '16px' }}>
                {selectedTimeEntry?.id && (
                  <CustomButton
                    text={t('attributes.delete')}
                    startIcon={<DeleteOutlineIcon />}
                    onClick={() => setOpenDelete(true)}
                    variant="outlined"
                    sx={{
                      color: WARNING,
                      border: `1px solid ${WARNING}`,
                      ':hover': {
                        border: `1px solid ${WARNING}`,
                        backgroundColor: 'white',
                      },
                    }}
                  />
                )}
                <CustomButton
                  text={
                    selectedTimeEntry?.id &&
                    selectedTimeEntry?.status.toLowerCase() === APPROVED
                      ? t('common.status.approved')
                      : t('common.approve')
                  }
                  startIcon={<CheckCircleOutlineIcon />}
                  onClick={handleSubmit(onApprove)}
                  disabled={
                    (selectedTimeEntry?.id &&
                      selectedTimeEntry?.status.toLowerCase() === APPROVED) ||
                    create?.isLoading ||
                    isApproved
                  }
                />
                <CustomButton
                  text={
                    selectedTimeEntry?.id
                      ? t('attributes.save')
                      : t('common.submit')
                  }
                  startIcon={
                    selectedTimeEntry?.id ? <SaveOutlinedIcon /> : <SendIcon />
                  }
                  onClick={handleSubmit(onSubmit)}
                  disabled={create?.isLoading || isApproved}
                />
              </Box>
            )
          }
        >
          <TimeEntry
            id={selectedTimeEntry?.id}
            control={control}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            getValues={getValues}
            setError={setError}
            watch={watch}
            isApproved={selectedTimeEntry?.status.toLowerCase() === APPROVED}
          />
        </SwipeableDrawer>
      )}
      {openTimesheetDownload && (
        <SwipeableDrawer
          open={openTimesheetDownload}
          title={`${t('attributes.download')}`}
          onClose={() => {
            setOpenTimesheetDownload(false);
            setDownloadSelectedTechnician([]);
            setFromDate(null);
            setToDate(null);
          }}
          footerButton={
            <Box sx={{ display: 'flex', columnGap: '16px' }}>
              <CustomButton
                text={'Download'}
                startIcon={<DownloadOutlinedIcon />}
                onClick={handleSubmit(onDownload)}
                disabled={doDownload}
              />
            </Box>
          }
        >
          <TimeSheetDownload
            technicianList={technicianRefactoredList}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            selectedTechnician={downloadSelectedTechnician}
            setSelectedTechnician={setDownloadSelectedTechnician}
          />
        </SwipeableDrawer>
      )}
      {openDelete && (
        <ConfirmationModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={t('attributes.timesheet.deleteTitle')}
          description={t('attributes.timesheet.deleteMsg')}
          onConfirm={onDelete}
          disabled={isDeleteLoading}
        />
      )}

      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setSelectedTimeEntry(null);
            setOpenConfirmationModal(false);
            sethasFormValues(false);

            if (window.history.state === null) {
              window.history.back();
            }
          }}
        />
      )}
    </>
  );
};

export default Timesheet;
