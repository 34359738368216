import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CloseOutlined } from '@mui/icons-material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {
  Backdrop,
  Box,
  Divider,
  Modal,
  Typography,
  styled,
} from '@mui/material';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../assets/commonStyled';
import { DIVIDER_COLOR, PRIMARY, SECONDARY } from '../../constants/Colors';
import { Validation } from '../../constants/FieldValidationMsg';
import { EMAIL_REGEX } from '../../constants/Regex';
// eslint-disable-next-line
import { validateAddress } from '../../constants/common';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getStatesHook from '../../hooks/getStatesHook';
import useAddressDetail from '../../hooks/useAddressDetail';
import useAutocompleteAddress from '../../hooks/useAutcompleteAddress';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { resetBillingCreate } from '../../store/billing/reducer';
import { clearTextfields } from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomTextField from '../CommonComponents/CustomTextField';

const modalStyle = {
  position: 'absolute',
  top: '8%',
  left: '50%',
  transform: 'translate(-50%, 0)',
  width: 800,
  bgcolor: DIVIDER_COLOR,
  borderRadius: '4px',
  overflow: 'hidden',
  Height: '700px',
};

const TitleStyled = styled(Box)(({ theme }) => ({
  backgroundColor: PRIMARY,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px',
  borderRadius: '4px 4px 0 0',
}));

const DescriptionStyled = styled(Box)(() => ({
  padding: '16px',
}));

const FooterButton = styled(Box)({
  backgroundColor: SECONDARY,
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '8px',
});

export const defaultValues = {
  customer: null,
  billing_name: '',
  abn_number: '',
  contact_name: '',
  email: '',
  phone: '',
  address: '',
  city: null,
  state: null,
  post_code: '',
};

const CreateCustomerBill = ({
  open,
  customer,
  setOpen,
  onConfirm = () => {},
  buttonDisabled,
}) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: defaultValues,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setValue('customer', customer?.value);
  }, [customer, setValue]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [searchAddress, setSearchAddress] = useState('');
  const [address2Blur, setAddress2Blur] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addressBlur, setAddressBlur] = useState(false);
  const [stateBlur, setStateBlur] = useState(false);
  const [cityBlur, setCityBlur] = useState(false);
  const [postcodeBlur, setPostcodeBlur] = useState(false);
  const address = watch('address');
  const state = watch('state');
  const city = watch('city');
  const postcode = watch('post_code');
  const addressLine2 = watch('address_line_2');
  const [serverErrors, setServerErrors] = useState([]);

  const billingAddressLabels = (label) => t(`attributes.technician.${label}`);
  const postCodeLength = 4;

  const clearHandler = (name) => setValue(name, '');

  const cityList = getCityByStateHook(state?.value);
  const stateList = getStatesHook();

  const {
    isLoading: isAutocompleteAddressLoading,
    data: autocompleteAddressData,
  } = useSelector((state) => state.googleAPIs.autocompleteAddress);

  const typedValue = watch('searchAddress');

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  useAutocompleteAddress(searchAddress);

  useAddressDetail({
    selectedAddress: typedValue,
    setValue: setValue,
    fieldName: {
      stateKey: 'state',
      cityKey: 'city',
      postCodeKey: 'post_code',
      address1: 'address',
      address2: 'address_line_2',
    },
  });

  useEffect(() => {
    if (open) {
      reset();
      setIsButtonDisabled(false);
    }
    setValue('customer', customer?.value);
  }, [open]);

  useEffect(
    () => () => {
      dispatch(resetBillingCreate());
    },
    []
  );

  const handleOnSubmit = (data) => {
    setIsButtonDisabled(true); // Disable the button when clicked
    onConfirm(data); // Call the passed onConfirm handler
  };

  useEffect(() => {
    validateAddress({
      address1: address,
      address2: addressLine2,
      city: city,
      state: state,
      postcode: postcode,
      fieldName: {
        addressValue: billingAddressLabels('contact_address'),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'post_code',
        postCodeValue: t('attributes.postCode'),
        address1Key: 'address',
        address1Value: t('attributes.addressLine1'),
        address2Key: 'address_line_2',
        address2Value: t('attributes.addressLine2'),
      },
      setServerErrors,
      clearErrors,
    });
  }, [addressBlur, stateBlur, cityBlur, postcodeBlur, address2Blur]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(handleOnSubmit)}
        sx={modalStyle}
      >
        <TitleStyled>
          <Typography variant="h1">
            {t('attributes.property.createNewCustomerBilling')}
          </Typography>
          <CloseOutlined
            onClick={() => setOpen(false)}
            style={{ cursor: 'pointer' }}
          />
        </TitleStyled>

        <DescriptionStyled>
          <FormWrapper>
            <CustomCard>
              <Box sx={{ padding: '16px' }}>
                <Typography variant="body1">
                  {t('attributes.billingCard.accountDetails')}
                </Typography>
              </Box>
              <Divider />
              <FormFieldWrapper>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '8px',
                  }}
                >
                  <Typography>Customer: </Typography>
                  <Typography variant="body2">{customer?.label}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    columnGap: '8px',
                  }}
                >
                  <Controller
                    name="billing_name"
                    control={control}
                    rules={{
                      required: `${t('attributes.billingCard.billName')} ${Validation.general.required}`,
                    }}
                    render={({
                      field: { onChange, value, name, onBlur },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={t('attributes.billingCard.billName')}
                        value={value}
                        sx={{ width: '100%' }}
                        onChange={(e) => {
                          onChange(e);
                          trigger('billing_name');
                          clearErrors('billing_name');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearTextfields(setValue, name)}
                        isRequired={true}
                        onBlur={() => {
                          onChange(value.trim());
                          onBlur();
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="abn_number"
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (value) {
                          return value.length == 11;
                        }

                        return true;
                      },
                      pattern: {
                        value: /^\d{11}$/,
                        message: Validation.general.abnNumber,
                      },
                    }}
                    render={({
                      field: { onChange, value, name, onBlur },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={t('attributes.billingCard.abn')}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('abn_number');
                          clearErrors('abn_number');
                        }}
                        sx={{ width: '100%' }}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearTextfields(setValue, name)}
                        isRequired={false}
                        onBlur={() => {
                          onChange(value.trim());
                          onBlur();
                        }}
                      />
                    )}
                  />
                </Box>
              </FormFieldWrapper>
            </CustomCard>
            <CustomCard>
              <Box sx={{ padding: '16px' }}>
                <Typography variant="body1">
                  {t('attributes.billingCard.contactDetails')}
                </Typography>
              </Box>
              <Divider />
              <FormFieldWrapper>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    columnGap: '8px',
                  }}
                >
                  <Controller
                    name="contact_name"
                    control={control}
                    rules={{
                      required: `${t('attributes.supplier.contact_name')} ${Validation.general.required}`,
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={t('attributes.supplier.contact_name')}
                        fullWidth
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('contact_name');
                          clearErrors('contact_name');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearTextfields(setValue, name)}
                        isRequired={true}
                      />
                    )}
                  />

                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: `${t('attributes.billingCard.contact_email')} ${Validation.general.required}`,
                      maxLength: {
                        value: 254,
                        message: Validation.general.email,
                      },
                      pattern: {
                        value: EMAIL_REGEX,
                        message: Validation.general.validEmail,
                      },
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={t('attributes.billingCard.contact_email')}
                        fullWidth
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('email');
                          clearErrors('email');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearHandler(name)}
                      />
                    )}
                  />
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^\d{1,50}$/,
                        message: Validation.general.contactPhone,
                      },
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={t('attributes.supplier.contact_phone')}
                        fullWidth
                        value={value}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ''
                          );

                          onChange(numericValue);
                          trigger('phone');
                          clearErrors('phone');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearTextfields(setValue, name)}
                        isRequired={false}
                      />
                    )}
                  />
                </Box>
                <Controller
                  name="searchAddress"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      label={t('common.searchAddress')}
                      options={autocompleteAddressData || []}
                      value={value}
                      isRequired={false}
                      isLoadingData={isAutocompleteAddressLoading}
                      onChange={(e, newValue) => {
                        if (newValue || newValue === null) {
                          onChange(newValue);
                        }
                        setSearchAddress(e.target?.value);
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="address"
                  control={control}
                  rules={{
                    required: `${t('attributes.addressLine1')} ${Validation.general.required}`,
                    maxLength: {
                      value: 300,
                      message: Validation.billingCard.address,
                    },
                  }}
                  render={({
                    field: { onChange, value, name, onBlur },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.addressLine1')}
                      fullWidth
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('address');
                      }}
                      multiline
                      rows={2}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearTextfields(setValue, name)}
                      isRequired={true}
                      setBlur={setAddressBlur}
                      onBlur={() => {
                        onChange(value.trim());
                        onBlur();
                      }}
                    />
                  )}
                />
                <Controller
                  name={'address_line_2'}
                  control={control}
                  rules={{
                    maxLength: {
                      value: 300,
                      message: Validation.general.address2,
                    },
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.addressLine2')}
                      fullWidth
                      value={value || ''}
                      onChange={(e) => {
                        onChange(e);
                        trigger('address_line_2');
                      }}
                      isRequired={false}
                      multiline
                      rows={2}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearTextfields(setValue, name)}
                      setBlur={setAddress2Blur}
                    />
                  )}
                />

                <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
                  <Controller
                    name="state"
                    control={control}
                    rules={{
                      required: `${t('common.state')} ${Validation.general.required}`,
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        label={t('common.state')}
                        options={stateList}
                        value={value}
                        onChange={(e, newValue) => {
                          if (newValue || newValue === null) {
                            onChange(newValue);
                          }
                          trigger('state');
                          setValue('city', null);
                          clearErrors('city');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearTextfields(setValue, name)}
                        isRequired={true}
                        setBlur={setStateBlur}
                      />
                    )}
                  />
                  <Controller
                    name="city"
                    control={control}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        label={t('common.city')}
                        options={cityList}
                        value={value}
                        onChange={(e, newValue) => {
                          if (newValue || newValue === null) {
                            onChange(newValue);
                          }
                          trigger('city');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearTextfields(setValue, name)}
                        isRequired={false}
                        setBlur={setCityBlur}
                        ListboxProps={{
                          style: {
                            maxHeight: '272px',
                          },
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="post_code"
                    control={control}
                    rules={{
                      required: `${t('attributes.postCode')} ${Validation.general.required}`,
                      validate: (value) => {
                        if (value && !/^\d+$/.test(value)) {
                          return Validation.general.numericValue;
                        }

                        return true;
                      },
                      minLength: {
                        value: postCodeLength,
                        message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                      },
                      maxLength: {
                        value: postCodeLength,
                        message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                      },
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={t('attributes.postCode')}
                        fullWidth
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('post_code');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearTextfields(setValue, name)}
                        isRequired={true}
                        setBlur={setPostcodeBlur}
                      />
                    )}
                  />
                </Box>
              </FormFieldWrapper>
            </CustomCard>
          </FormWrapper>
        </DescriptionStyled>

        <FooterButton>
          <CustomButton
            startIcon={<AddCircleOutlineOutlinedIcon />}
            text={isButtonDisabled ? t('common.loading') : t('attributes.add')}
            type="submit"
            disabled={isButtonDisabled}
          />
        </FooterButton>
      </Box>
    </Modal>
  );
};

export default CreateCustomerBill;
