import { useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';

const useNavigationBlocker = ({ hasFormValues, setConfirmationModalOpen }) => {
  const [nextLocation, setNextLocation] = useState(null);
  const [forceNavigate, setForceNavigate] = useState(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (forceNavigate) return false;

    if (hasFormValues && currentLocation.pathname !== nextLocation.pathname) {
      setNextLocation(nextLocation);
      setConfirmationModalOpen(true);

      return true;
    }

    return false;
  });

  useEffect(() => {
    if (!hasFormValues) {
      setNextLocation(null);
    }
  }, [hasFormValues]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasFormValues) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasFormValues]);

  const proceedNavigation = (force = false) => {
    if (force) setForceNavigate(true); // Use this parameter to force navigation
    if (nextLocation) {
      blocker.proceed();
      setNextLocation(null);
    }
  };

  return { proceedNavigation, setForceNavigate, setNextLocation };
};

export default useNavigationBlocker;
