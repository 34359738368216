import React, { useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Box, Divider, Typography } from '@mui/material';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../assets/commonStyled';
import { Validation } from '../../constants/FieldValidationMsg';
import { SUPER_ADMIN } from '../../constants/Roles';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import { getBill } from '../../store/billing/api';
import { resetBillingCreate } from '../../store/billing/reducer';
import { getContactDropdownList } from '../../store/contacts/api';
import { resetCreate } from '../../store/contacts/reducer';
import { getPropertyCustomerList } from '../../store/property/api';
import { resetPropertyCustomerList } from '../../store/property/reducer';
import { getCustomerById } from '../../store/users/customer/api';
import { resetEdit } from '../../store/users/customer/reducer';
import {
  clearTextfields,
  findLabelByValue,
  loggedInUserRole,
  showHideTopbarLoader,
} from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CreateContactModal from '../CommonComponents/CreateContactModal';
import CustomButton from '../CommonComponents/CustomButton';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';

const BillingForm = ({
  id,
  control,
  watch,
  trigger,
  setValue,
  clearErrors,
  setServerErrors,
  reset,
  getValues = () => {},
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    propertyDropdownLoading: customerDropdownLoading,
    propertyDropdownData: customerDropdownData,
  } = getDropdownListHook({
    reducerName: 'property',
    dropdownListName: 'propertyCustomerList',
    labelName: 'customer_name',
    valueName: 'uuid',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [open, setOpen] = useState(false);

  const { isLoading: customerLoading, editCustomer } = useSelector(
    (state) => state.customer.edit
  );

  const { isLoading: contactLoading, contact } = useSelector(
    (state) => state.contact.edit
  );

  const { createdContact } = useSelector((state) => state.contact.create);
  const {
    isLoading: createCustomerLoading,
    createdCustomer,
    error,
  } = useSelector((state) => state.customer.create);

  const { contactDropdownLoading, contactDropdownData } = getDropdownListHook({
    reducerName: 'contact',
    dropdownListName: 'getDropdown',
    labelName: 'name',
    valueName: 'uuid',
    address: 'address',
    address_line_2: 'address_line_2',
    email: 'email',
    city: 'city',
    state: 'state',
    postcode: 'postcode',
  });

  const customerValue = useWatch({ control, name: 'customer' });

  useEffect(() => {
    if (createdCustomer && !id) {
      reset({
        customer: {
          label: createdCustomer?.data[0]?.customer_name,
          value: createdCustomer?.data[0]?.uuid,
        },
        abn_number: createdCustomer?.data[0]?.customer_abn_number || '',
        customer_contact: findLabelByValue(
          contactDropdownData,
          createdCustomer?.data[0]?.contact_uuid
        ),
      });
    }
  }, [createdCustomer]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      dispatch(getBill({ uuid: id }))
        .then((res) => {
          const data = res?.payload?.data?.[0];

          if (isFirstRender) {
            reset({
              customer: {
                label: data?.customer_name,
                value: data?.customer_uuid,
              },
              billing_name: data?.billing_name,
              abn_number: data?.abn_number || '',
              customer_contact: findLabelByValue(
                contactDropdownData,
                data?.contact_uuid
              ),
            });
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [id]);

  useEffect(() => {
    if (customerValue?.value && !isFirstRender) {
      dispatch(getCustomerById(customerValue.value));
    }
  }, [customerValue?.value]);

  useEffect(() => {
    showHideTopbarLoader(contactLoading, dispatch);
    if (!customerLoading) {
      if (contact) {
        const values = getValues();

        reset({
          ...values,
          contact_name: contact?.name || '',
          email: contact?.email || '',
          phone: contact?.contact_phone || '',
          address: contact?.address || '',
          address_line_2: contact?.address_line_2 || '',
          city: {
            label: contact?.city || '',
            value: contact?.city || '',
          },
          state: {
            label: contact?.state || '',
            value: contact?.state || '',
          },
          post_code: contact?.postcode || '',
        });
      }
    }
  }, [contactLoading, contact]);

  // useEffect(() => {
  //   showHideTopbarLoader(customerLoading, dispatch);
  //   if (!customerLoading) {
  //     if (editCustomer) {
  //       editCustomer?.contact_uuid &&
  //         dispatch(editContact(editCustomer?.contact_uuid));
  //       reset({
  //         customer: {
  //           label: editCustomer?.customer_name || '',
  //           value: editCustomer?.uuid || '',
  //         },
  //         abn_number: editCustomer?.customer_abn_number || '',
  //       });
  //     }
  //   }
  // }, [customerLoading, editCustomer]);

  useEffect(() => {
    if (createdContact && contactDropdownData.length > 0) {
      const result = contactDropdownData?.filter(
        (item) => item.value === createdContact?.data[0]?.uuid
      )[0];

      setValue(
        'customer_contact',
        result
          ? {
              ...result,
            }
          : null
      );
    }
  }, [createdContact, contactDropdownData]);

  useEffect(() => {
    dispatch(getPropertyCustomerList({}));
    dispatch(getContactDropdownList());

    return () => reset({});
  }, []);

  useEffect(
    () => () => {
      dispatch(resetEdit());
      dispatch(resetBillingCreate());
      dispatch(resetPropertyCustomerList());
      dispatch(resetCreate());
      reset();
    },
    []
  );

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <>
      <FormWrapper>
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1">
              {t('attributes.billingCard.accountDetails')}
            </Typography>
          </Box>
          <Divider />
          <FormFieldWrapper>
            <Controller
              name="customer"
              control={control}
              rules={{
                required: `${t('attributes.customer.customer')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={t('attributes.customer.customer')}
                  options={customerDropdownData}
                  value={value}
                  onChange={(e, newValue) => {
                    if (newValue || newValue === null) {
                      onChange(newValue);
                      setIsFirstRender(false);
                    }
                    clearErrors('customer');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  isLoadingData={customerDropdownLoading}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={true}
                />
              )}
            />
            <Controller
              name="billing_name"
              control={control}
              rules={{
                required: `${t('attributes.billingCard.billName')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name, onBlur },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.billingCard.billName')}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('billing_name');
                    clearErrors('billing_name');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={true}
                  onBlur={() => {
                    onChange(value.trim());
                    onBlur();
                  }}
                />
              )}
            />
            <Controller
              name="abn_number"
              control={control}
              rules={{
                validate: (value) => {
                  if (value) {
                    return value.length == 11;
                  }

                  return true;
                },
                pattern: {
                  value: /^\d{11}$/,
                  message: Validation.general.abnNumber,
                },
              }}
              render={({
                field: { onChange, value, name, onBlur },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.billingCard.abn')}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('abn_number');
                    clearErrors('abn_number');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                  onBlur={() => {
                    onChange(value.trim());
                    onBlur();
                  }}
                />
              )}
            />
          </FormFieldWrapper>
        </CustomCard>
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1">
              {t('attributes.billingCard.contactDetails')}
            </Typography>
          </Box>
          <Divider />
          <FormFieldWrapper>
            <Controller
              name="customer_contact"
              control={control}
              rules={{
                required: `${t('attributes.customer.customerContact')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={`${t('attributes.customer.customerContact')}`}
                  options={contactDropdownData}
                  value={value}
                  onChange={(e, newValue) => {
                    if (newValue || newValue === null) {
                      onChange(newValue);
                    }
                    trigger('customer_contact');
                  }}
                  isLoadingData={contactDropdownLoading}
                  helperText={error ? error.message : ''}
                  error={error}
                />
              )}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '-16px 0px',
              }}
            >
              <CustomButton
                startIcon={<AddCircleOutlineOutlinedIcon />}
                text={t('attributes.add')}
                style={{ textDecoration: 'underline' }}
                variant="text"
                onClick={() => setOpen(true)}
              />
            </Box>
            {/* <Controller
              name="contact_name"
              control={control}
              rules={{
                required: `${t('attributes.supplier.contact_name')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.supplier.contact_name')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('contact_name');
                    clearErrors('contact_name');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={true}
                />
              )}
            />

            <Controller
              name="email"
              control={control}
              rules={{
                required: `${t('attributes.billingCard.contact_email')} ${Validation.general.required}`,
                maxLength: {
                  value: 254,
                  message: Validation.general.email,
                },
                pattern: {
                  value: EMAIL_REGEX,
                  message: Validation.general.validEmail,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.billingCard.contact_email')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('email');
                    clearErrors('email');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              rules={{
                pattern: {
                  value: /^\d{0,50}$/,
                  message: Validation.general.contactPhone,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.supplier.contact_phone')}
                  fullWidth
                  value={value}
                  isRequired={false}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '');

                    onChange(numericValue);
                    trigger('phone');
                    clearErrors('phone');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                />
              )}
            />
            <Controller
              name="searchAddress"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={t('common.searchAddress')}
                  options={autocompleteAddressData || []}
                  value={value}
                  isRequired={false}
                  isLoadingData={isAutocompleteAddressLoading}
                  onChange={(e, newValue) => {
                    if (newValue || newValue === null) {
                      onChange(newValue);
                    }
                    setSearchAddress(e.target?.value);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                />
              )}
            />
            <Controller
              name="address"
              control={control}
              rules={{
                required: `${t('attributes.addressLine1')} ${Validation.general.required}`,
                maxLength: {
                  value: 100,
                  message: Validation.billingCard.address,
                },
              }}
              render={({
                field: { onChange, value, name, onBlur },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.addressLine1')}
                  fullWidth
                  value={value || ''}
                  onChange={(e) => {
                    onChange(e);
                    trigger('address');
                  }}
                  multiline
                  rows={2}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={true}
                  setBlur={setAddressBlur}
                  onBlur={() => {
                    onChange(value.trim());
                    onBlur();
                  }}
                />
              )}
            />
            <Controller
              name={'address_line_2'}
              control={control}
              rules={{
                maxLength: {
                  value: 50,
                  message: Validation.billingCard.address_line_2,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.addressLine2')}
                  fullWidth
                  value={value || ''}
                  onChange={(e) => {
                    onChange(e);
                    trigger('address_line_2');
                  }}
                  isRequired={false}
                  multiline
                  rows={2}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  setBlur={setAddress2Blur}
                />
              )}
            />

            <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
              <Controller
                name="state"
                control={control}
                rules={{
                  required: `${t('common.state')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('common.state')}
                    options={stateList}
                    value={value}
                    onChange={(e, newValue) => {
                      if (newValue || newValue === null) {
                        onChange(newValue);
                      }
                      trigger('state');
                      setValue('city', null);
                      clearErrors('city');
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    onClear={() => clearTextfields(setValue, name)}
                    isRequired={true}
                    setBlur={setStateBlur}
                  />
                )}
              />
              <Controller
                name="city"
                control={control}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('common.city')}
                    options={cityList}
                    value={value}
                    isRequired={false}
                    onChange={(e, newValue) => {
                      if (newValue || newValue === null) {
                        onChange(newValue);
                      }
                      trigger('city');
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    onClear={() => clearTextfields(setValue, name)}
                    setBlur={setCityBlur}
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '-16px 0px',
              }}
            >
              <CustomButton
                startIcon={<AddCircleOutlineOutlinedIcon />}
                text={t('attributes.add')}
                style={{ textDecoration: 'underline' }}
                variant="text"
                onClick={() => setOpen(true)}
              />
            </Box>
            <Controller
              name="post_code"
              control={control}
              rules={{
                required: `${t('attributes.postCode')} ${Validation.general.required}`,
                validate: (value) => {
                  if (value && !/^\d+$/.test(value)) {
                    return Validation.general.numericValue;
                  }

                  return true;
                },
                minLength: {
                  value: postCodeLength,
                  message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                },
                maxLength: {
                  value: postCodeLength,
                  message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.postCode')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('post_code');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={true}
                  setBlur={setPostcodeBlur}
                />
              )}
            /> */}
          </FormFieldWrapper>
        </CustomCard>
      </FormWrapper>
      {loggedInUserRole() === SUPER_ADMIN && (
        <CreateContactModal
          open={open}
          setOpen={setOpen}
          state={watch('state')}
          setServerErrors={setServerErrors}
        />
      )}
    </>
  );
};

export default BillingForm;
