import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { uniqueId } from 'lodash';
import moment from 'moment';

import { DIVIDER_COLOR, STATUS_COLORS } from '../../constants/Colors';
import {
  DASHBOARD,
  DIFFERED,
  MONTHS_DROPDOWN,
  OVERDUE,
  PAID,
  PENDING,
  ROUTE_NAVIGATE_CONST,
  SIX_MONTHS,
} from '../../constants/Constants';
import { ROUTENAME } from '../../constants/RoutesConstants';
import {
  getInvoiceByAmount,
  getInvoiceByAmountChart,
  getInvoiceByStatus,
  getInvoiceByStatusChart,
} from '../../store/invoices/api';
import {
  formatPriceWithDecimalValue,
  getRedirectURL,
  getXAxisLabel,
  invoiceStatusChartFilterCondition,
  loggedInUserRole,
} from '../../utils';
import CustomLineChart from '../Chart/LineChart/CustomLineChart';
import StackedBarChart from '../Chart/StackedBarChart/StackedBarChart';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import DashboardCard from '../CommonComponents/DashboardCard';
import ChartCard from '../Dashboard/ChartCard';

const defaultRangeData = {
  invoiceStatusRange: MONTHS_DROPDOWN[0],
  invoiceAmountRange: MONTHS_DROPDOWN[0],
};

const InvoiceDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = loggedInUserRole();

  const { dashboardFilter, refresh } = useOutletContext();

  const quoteInvoiceCardData = [
    {
      title: `${t('common.status.pending')} ${t('attributes.invoice.invoice')}`,
      status: PENDING,
      label: t('common.status.pending'),
      click: true,
      value: 0,
      order: 1,
    },
    {
      title: `${t('attributes.invoice.paid')} ${t('attributes.invoice.invoice')}`,
      status: PAID,
      click: true,
      value: 0,
      order: 2,
      label: t('attributes.invoice.paid'),
    },
    {
      title: `${t('common.status.overdue')} ${t('attributes.invoice.invoice')}`,
      status: OVERDUE,
      value: 0,
      click: true,
      order: 1,
      label: t('attributes.invoice.overdue'),
    },
    {
      title: `${t('common.status.differed')} ${t('attributes.invoice.invoice')}`,
      status: DIFFERED,
      value: 0,
      click: true,
      order: 2,
      label: t('attributes.invoice.differed'),
    },
  ];

  const quoteAmountCardData = [
    {
      title: `${t('attributes.total')} ${t('attributes.amount')} ${t('attributes.invoice.due')}`,
      value: 0,
      status: PENDING,
      click: false,
      order: 5,
    },
    {
      title: `${t('attributes.total')} ${t('attributes.amount')} ${t('attributes.invoice.paid')}`,
      value: 0,
      click: false,
      status: PAID,
      order: 6,
    },
    {
      title: `${t('attributes.total')} ${t('attributes.amount')} ${t('attributes.invoice.overdue')}`,
      value: 0,
      status: OVERDUE,
      click: false,
      order: 4,
    },
    {
      title: `${t('attributes.total')} ${t('attributes.amount')} ${t('attributes.invoice.differed')}`,
      value: 0,
      click: false,
      status: DIFFERED,
      order: 7,
    },
  ];

  const [ranges, setRanges] = useState(defaultRangeData);
  const [isLoading, setIsLoading] = useState(false);

  const [chartLoading, setChartLoading] = useState({
    status: false,
    amount: false,
  });

  const [xAxisLabel, setXaxisLabel] = useState({
    status: SIX_MONTHS,
    amount: SIX_MONTHS,
  });

  const [amountData, setAmountData] = useState([]);
  const [statusData, setStatusData] = useState([]);

  const [cardData, setCardData] = useState({
    status: quoteInvoiceCardData,
    amount: quoteAmountCardData,
  });

  const quoteCardData = [...cardData.status, ...cardData.amount];

  const colors = [
    STATUS_COLORS.DIFFERED,
    STATUS_COLORS.PAID,
    STATUS_COLORS.OVERDUE,
    STATUS_COLORS.PENDING,
  ];

  const handleCardClick = (status) => {
    if (status !== DASHBOARD) {
      const formattedStatus = status.toLowerCase().replaceAll(' ', '-');

      navigate(
        getRedirectURL()[role][ROUTE_NAVIGATE_CONST.INVOICES_STATUS]?.replace(
          ROUTENAME.INVOICES_STATUS,
          formattedStatus
        )
      );
    } else {
      navigate(getRedirectURL()[role][ROUTE_NAVIGATE_CONST.INVOICES]);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const reqData = {
      start_date: dashboardFilter?.invoice_date?.startDate,
      end_date: dashboardFilter?.invoice_date?.endDate,
      propertyUUID: dashboardFilter?.property?.value,
    };

    dispatch(getInvoiceByAmount(reqData)).then((res) => {
      const latestAmountData = quoteAmountCardData?.map((qi) => {
        const matchedData = res?.payload?.data?.find(
          (s) => s.payment_status === qi.status
        );

        return {
          ...qi,
          value: Math.round(matchedData?.total) || 0,
        };
      });

      setCardData((prev) => ({ ...prev, amount: latestAmountData }));
    });

    dispatch(getInvoiceByStatus(reqData)).then((res) => {
      const latestStatusData = quoteInvoiceCardData?.map((qi) => {
        const matchedData = res?.payload?.data?.find(
          (s) => s.invoice_status === qi.status
        );

        return { ...qi, value: matchedData?.count || 0 };
      });

      setCardData((prev) => ({ ...prev, status: latestStatusData }));
      setIsLoading(false);
    });
  }, [dashboardFilter, refresh]);

  // Status Chart
  useEffect(() => {
    setXaxisLabel((pre) => ({
      ...pre,
      status: getXAxisLabel(ranges.invoiceStatusRange?.value, true),
    }));
    setChartLoading((pre) => ({ ...pre, status: true }));

    dispatch(
      getInvoiceByStatusChart({
        start_date: moment().subtract(
          ranges.invoiceStatusRange?.value,
          'months'
        ),
        end_date: moment(),
      })
    )
      .then((res) => {
        const currentMonth = moment().month() + 1; // Convert to 1-based month
        const currentYear = moment().year();

        const lastMonths = [...Array(ranges.invoiceStatusRange?.value)].map(
          (_, index) => {
            const month = ((currentMonth - index - 1 + 12) % 12) + 1; // 1-based month
            const year =
              currentYear + Math.floor((currentMonth - index - 1) / 12);

            return { month, year };
          }
        );

        const statusData = (status) =>
          lastMonths
            ?.map(
              ({ month, year }) =>
                res?.payload?.data?.find(
                  (d) =>
                    d.month === month &&
                    d.year === year &&
                    invoiceStatusChartFilterCondition[status](d)
                )?.total || 0
            )
            ?.reverse();

        const finalData = [
          {
            data: statusData(PENDING),
            label: t('common.status.pending'),
            id: PENDING,
            stack: 'total',
          },
          {
            data: statusData(PAID),
            label: t('common.status.paid'),
            id: PAID,
            stack: 'total',
          },
          {
            data: statusData(OVERDUE),
            label: t('common.status.overdue'),
            id: OVERDUE,
            stack: 'total',
          },
          {
            data: statusData(DIFFERED),
            label: t('common.status.differed'),
            id: DIFFERED,
            stack: 'total',
          },
        ];

        setStatusData(finalData);
      })
      .finally(() => setChartLoading((pre) => ({ ...pre, status: false })));
  }, [ranges.invoiceStatusRange, refresh]);

  // Invoice Amount Chart
  useEffect(() => {
    setXaxisLabel((pre) => ({
      ...pre,
      amount: getXAxisLabel(ranges.invoiceAmountRange?.value, true),
    }));
    setChartLoading((pre) => ({ ...pre, amount: true }));
    dispatch(
      getInvoiceByAmountChart({
        start_date: moment().subtract(
          ranges.invoiceAmountRange?.value,
          'months'
        ),
        end_date: moment(),
      })
    )
      .then((res) => {
        const currentMonth = moment().month() + 1;
        const currentYear = moment().year();

        const lastMonths = [...Array(ranges.invoiceAmountRange?.value)].map(
          (_, index) => {
            const month = ((currentMonth - index - 1 + 12) % 12) + 1;
            const year =
              currentYear + Math.floor((currentMonth - index - 1) / 12);

            return { month, year };
          }
        );

        const amountData = lastMonths.map(
          ({ month, year }) =>
            res?.payload?.data?.find(
              (d) => d.month === month && d.year === year
            )?.total || 0
        );

        setAmountData(amountData.reverse());
      })
      .finally(() => setChartLoading((pre) => ({ ...pre, amount: false })));
  }, [ranges?.invoiceAmountRange, refresh]);

  return (
    <Box sx={{ bgcolor: DIVIDER_COLOR }}>
      <Box sx={{ width: '100%', padding: '16px 0 30px 0px' }}>
        <Grid container spacing={2}>
          {quoteCardData?.map((item) => (
            <Grid xs={12} sm={6} lg={3} key={uniqueId('dashboardCard')}>
              <DashboardCard
                title={item.title}
                value={formatPriceWithDecimalValue(item.value)}
                click={item.click}
                onClick={() => handleCardClick(item.label)}
                isLoading={isLoading}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ display: 'flex', gap: '16px' }}>
        {/* Invoice Status Chart */}
        <Box sx={{ flex: 1 }}>
          <ChartCard
            title={`${t('attributes.invoice.invoice')} ${t('attributes.invoice.byStatus')}`}
            options={MONTHS_DROPDOWN}
            selectedType={ranges.invoiceStatusRange}
            isEditableChart={false}
            setSelectedType={(id, val) =>
              setRanges((prev) => ({
                ...prev,
                invoiceStatusRange: val,
              }))
            }
            height="257px"
            isSettingMode={true}
          >
            {chartLoading?.status ? (
              <CustomCircularLoader />
            ) : (
              <StackedBarChart
                colors={colors}
                data={statusData}
                xLabels={xAxisLabel?.status}
                legendPosition={{ vertical: 'top', horizontal: 'center' }}
                isAmount={true}
              />
            )}
          </ChartCard>
        </Box>

        {/* Invoice Amount Chart */}
        <Box sx={{ flex: 1 }}>
          <ChartCard
            title={`${t('attributes.invoice.invoice')} ${t('attributes.amount')}`}
            options={MONTHS_DROPDOWN}
            selectedType={ranges.invoiceAmountRange}
            isEditableChart={false}
            setSelectedType={(id, val) =>
              setRanges((prev) => ({
                ...prev,
                invoiceAmountRange: val,
              }))
            }
            height="257px"
            isSettingMode={true}
          >
            {chartLoading?.amount ? (
              <CustomCircularLoader />
            ) : (
              <CustomLineChart
                xAxisLabels={xAxisLabel?.amount}
                chartData={amountData}
                height={257}
                isAmount={true}
              />
            )}
          </ChartCard>
        </Box>
      </Box>
    </Box>
  );
};

export default InvoiceDashboard;
