import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

// MUI
import { useMsal } from '@azure/msal-react';
import { Logout } from '@mui/icons-material';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { googleLogout } from '@react-oauth/google';

import { PRIMARY } from '../../constants/Colors';
import { LOGIN_TYPE } from '../../constants/Constants';
import { SUPER_ADMIN } from '../../constants/Roles';
import { ROUTENAME } from '../../constants/RoutesConstants';
import {
  DRAWER_WIDTH,
  HEADER_HEIGHT,
  ICON_DRAWER_WIDTH,
} from '../../constants/Typography';
import { getDocument } from '../../store/CommonAPI';
import { extractInitials, loggedInUserRole } from '../../utils';

const ToolbarStyled = styled(Toolbar)(() => ({
  '&.MuiToolbar-root': {
    minHeight: HEADER_HEIGHT,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const Header = ({
  isNavbarOpen,
  setIsNavbarOpen,
  logoutConfirmation,
  setOpenLogoutModal,
}) => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [profilePic, setProfilePic] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getProfilePic = () =>
    profilePic ? (
      <Avatar
        src={profilePic}
        sx={{
          width: 36,
          height: 36,
          bgcolor: PRIMARY,
          ml: '8px',
          '&.MuiAvatar-root': { fontWeight: 600, fontSize: '14px' },
        }}
        onClick={handleClick}
      />
    ) : (
      <Avatar
        sx={{
          width: 36,
          height: 36,
          bgcolor: PRIMARY,
          ml: '8px',
          '&.MuiAvatar-root': { fontWeight: 600, fontSize: '14px' },
        }}
        onClick={handleClick}
      >
        {extractInitials(profileDetails?.name || profileDetails?.customer_name)}
      </Avatar>
    );

  // Logout handler
  const handleLogout = async () => {
    const type = localStorage.getItem('loginType');

    if (type == LOGIN_TYPE['microsoft']) {
      instance.logoutPopup({
        postLogoutRedirectUri: ROUTENAME.DEFAULT_ROUTE,
      });
    }
    if (type == LOGIN_TYPE['google']) {
      googleLogout();
    }
    localStorage.clear();
    navigate(ROUTENAME.DEFAULT_ROUTE);
  };

  useEffect(() => {
    if (logoutConfirmation) {
      handleLogout();
    }
  }, [logoutConfirmation]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { profileDetails, isAuthLoading } = useSelector(
    (state) => state.common
  );

  useEffect(() => {
    if (!isAuthLoading && profileDetails?.profile_pic_document_uuid) {
      dispatch(
        getDocument({ doc_uuid: profileDetails?.profile_pic_document_uuid })
      ).then((res) => setProfilePic(res?.payload?.data?.[0]?.presigned_url));
    } else if (!isAuthLoading && !profileDetails?.profile_pic_document_uuid) {
      setProfilePic(null);
    }
  }, [profileDetails, isAuthLoading]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: {
            sm: `calc(100% - ${isNavbarOpen ? DRAWER_WIDTH : ICON_DRAWER_WIDTH}px)`,
          },
          ml: { sm: `${isNavbarOpen ? DRAWER_WIDTH : ICON_DRAWER_WIDTH}px` },
          background: 'white',
          borderRadius: 0,
          boxShadow: 0,
        }}
      >
        <ToolbarStyled>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={() => setIsNavbarOpen(!isNavbarOpen)}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              color: 'black',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <IconButton onClick={() => navigate(ROUTENAME.HELP_AND_SUPPORT)}>
              <HelpOutlineOutlinedIcon
                sx={{ color: 'black', fontSize: '24px !important' }}
              />
            </IconButton>
            <IconButton onClick={() => navigate(ROUTENAME.BELL_NOTIFICATION)}>
              <NotificationsOutlinedIcon
                sx={{ color: 'black', fontSize: '24px !important' }}
              />
            </IconButton>
            <IconButton onClick={() => navigate(ROUTENAME.SYSTEM_SETTING)}>
              <SettingsOutlinedIcon
                sx={{ color: 'black', fontSize: '24px !important' }}
              />
            </IconButton>
            <Tooltip title={profileDetails?.role_title || '-'} arrow>
              {getProfilePic()}
            </Tooltip>
          </Box>
        </ToolbarStyled>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              padding: '16px 16px 8px 16px',
              '& .MuiMenuItem-root': {
                padding: '8px 0',
                height: 'auto',
                columnGap: '8px',
              },
              '& .MuiDivider-root': {
                margin: 0,
              },
              mt: 1.5,
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <Box
            sx={{
              display: 'flex',
              columnGap: '16px',
            }}
          >
            {getProfilePic()}
            <Box>
              <Typography variant="body1">
                {profileDetails?.name || profileDetails?.customer_name}
              </Typography>
              <Typography variant="body2">{profileDetails?.email}</Typography>
            </Box>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(ROUTENAME.PROFILE);
          }}
        >
          <AccountBoxOutlinedIcon />
          <Typography variant="body2">{t('common.profile')}</Typography>
        </MenuItem>
        <Divider />
        {loggedInUserRole() === SUPER_ADMIN && (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(ROUTENAME.COMPANY_PROFILE);
            }}
          >
            <CorporateFareIcon />
            <Typography variant="body2">
              {t('common.companyInformation')}
            </Typography>
          </MenuItem>
        )}
        {loggedInUserRole() === SUPER_ADMIN && <Divider />}
        <MenuItem
          onClick={() => {
            setOpenLogoutModal(true);
          }}
        >
          <Logout />
          <Typography variant="body2">{t('common.logout')}</Typography>
        </MenuItem>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography>
            {t('label.version')} {profileDetails?.app_version || ''}
          </Typography>
        </Box>
      </Menu>
    </>
  );
};

export default Header;
