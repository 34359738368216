import { useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider, Typography } from '@mui/material';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../../assets/commonStyled';
import { Validation } from '../../../constants/FieldValidationMsg';
import getDropdownListHook from '../../../hooks/getDropdownListHook';
import {
  getEquipmentClassDropdown,
  getEquipmentTypesDropdown,
} from '../../../store/equipmentTypes/api';
import {
  resetCategoryLevels1DropdownList,
  resetEquipmentClassDropdownList,
} from '../../../store/equipmentTypes/reducer';
import { clearTextfields } from '../../../utils';
import Autocomplete from '../../CommonComponents/AutoComplete';
import CommonCheckbox from '../../CommonComponents/CustomCheckbox';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import CustomTextField from '../../CommonComponents/CustomTextField';

const AssetVariantForm = ({ id, reset, control, trigger, setValue }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading: listLoading, data: assetVariantsListTypeData } = useSelector(
    (state) => state.property.assetVariantsList
  );

  // Get equipment class list
  const { equipmentClassDropdownLoading, equipmentClassDropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'equipmentClassDropdownList',
      customNameForDropdown: 'equipmentClass',
      labelName: 'name',
      valueName: 'id',
    });

  // Get equipment type list
  const { equipmentTypesDropdownLoading, equipmentTypesDropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'equipmentTypesDropdownList',
      labelName: 'display_name',
      valueName: 'code',
      id: 'id',
      isPressure: 'is_pressure',
    });

  const selectedEquipmentType = useWatch({
    control,
    name: 'assetGroup',
    defaultValue: '',
  });

  useEffect(() => {
    dispatch(getEquipmentTypesDropdown());

    return () => {
      reset({});
    };
  }, []);

  useEffect(() => {
    if (selectedEquipmentType?.label) {
      dispatch(
        getEquipmentClassDropdown({
          equipment_type_id: selectedEquipmentType?.id,
          status: true,
        })
      );
    }
  }, [selectedEquipmentType]);

  useEffect(() => {
    if (!listLoading && assetVariantsListTypeData && id) {
      const selectedAssetVariant = assetVariantsListTypeData?.find(
        (assetVariant) => assetVariant?.uuid === id
      );

      reset({
        assetGroup:
          selectedAssetVariant?.equipment_class.equipment_type.display_name,
        assetType: selectedAssetVariant?.equipment_class.name,
        name: selectedAssetVariant?.name,
        is_active: selectedAssetVariant?.is_active,
      });
    }
  }, [listLoading, assetVariantsListTypeData]);

  const clearHandler = (name) => setValue(name, '');

  return listLoading ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">
            {`${t('attributes.configuration.assetVariant.title')} ${t('common.details')}`}
          </Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          {
            <>
              <Controller
                name="assetGroup"
                control={control}
                rules={{
                  required: `${t('attributes.property.assetGroup')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('attributes.property.assetGroup')}
                    options={equipmentTypesDropdownData}
                    value={value}
                    onChange={(e, newValue) => {
                      if (newValue || newValue === null) {
                        onChange(newValue);
                      }
                      trigger('assetGroup');
                      dispatch(resetEquipmentClassDropdownList());
                      setValue('assetType', null);
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    isLoadingData={equipmentTypesDropdownLoading}
                    onClear={() => clearTextfields(setValue, name)}
                    isRequired={true}
                    disabledDropdown={id}
                  />
                )}
              />
              <Controller
                name="assetType"
                control={control}
                rules={{
                  required: `${t('attributes.property.assetType')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('attributes.property.assetType')}
                    options={equipmentClassDropdownData}
                    value={value}
                    onChange={(e, newValue) => {
                      if (newValue || newValue === null) {
                        onChange(newValue);
                      }
                      trigger('assetType');
                      dispatch(resetCategoryLevels1DropdownList());
                    }}
                    isLoadingData={equipmentClassDropdownLoading}
                    error={error}
                    helperText={error ? error.message : ''}
                    disabledDropdown={id}
                  />
                )}
              />
            </>
          }
          <Controller
            name="name"
            control={control}
            rules={{
              required: `${t('attributes.configuration.assetVariant.title')} ${t('attributes.name')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={`${t('attributes.configuration.assetVariant.title')} ${t('attributes.name')}`}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('name');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
      {id && (
        <CustomCard>
          <Box sx={{ px: '16px' }}>
            <CommonCheckbox
              control={control}
              name="is_active"
              label={t('common.active')}
              isRequired={false}
              showMessage={true} // Enable message display
              messageOnChecked={t(
                'label.assetVariant.assetVariantActiveCheckbox'
              )}
              messageOnUnchecked={t(
                'label.assetVariant.assetVariantInactiveCheckbox'
              )}
            />
          </Box>
        </CustomCard>
      )}
    </FormWrapper>
  );
};

export default AssetVariantForm;
