import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from '@react-pdf/renderer';
import moment from 'moment';

import { DarkLogo } from '../../../../assets/images';
import {
  BOX_GRAY,
  DIVIDER_COLOR,
  PRIMARY,
  SECONDARY,
} from '../../../../constants/Colors';
import {
  DATE_FORMAT,
  TAX_NAME,
  TAX_VALUE,
  TEMPLATE_SET,
} from '../../../../constants/Constants';
import {
  calculateTaxPercentage,
  calculateTotal,
  formatAddress,
  formattedDate,
  templateExtractData,
} from '../../../../utils';

// Styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 30,
    fontSize: 11,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 20,
  },
  section: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    width: '100%',
    gap: '10px',
  },
  sectionBox: {
    minWidth: '260px',
    padding: '16px',
    border: `1px solid ${DIVIDER_COLOR}`,
    borderRadius: '4px',
    backgroundColor: BOX_GRAY,
    gap: '8px',
  },
  sectionContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '8px',
  },
  table: {
    display: 'table',
    width: '100%',
    borderCollapse: 'collapse',
    paddingTop: '16px',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableHeaderRow: {
    flexDirection: 'row',
    backgroundColor: PRIMARY,
    color: SECONDARY,
  },
  tableCell: {
    padding: 8,
    border: '1px solid #EBEBEB',
    textAlign: 'left',
  },
  tableCellWithoutBorder: {
    padding: 8,
    textAlign: 'left',
  },
  tableCellCenter: {
    textAlign: 'center',
  },
  tableCellRight: {
    textAlign: 'right',
  },
  bold: {
    fontWeight: 'bold',
  },
  logo: {
    height: 68,
    objectFit: 'contain',
    maxWidth: 150,
  },
  footer: {
    marginTop: 20,
  },
  title: {
    marginBottom: 4,
    fontWeight: 'bold',
  },
  address: {
    color: '#646464',
  },
  termsAndConditions: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
});

const DownloadPreviewInvoice = ({
  module,
  previewData,
  isFromServiceQuotes,
}) => {
  const { t } = useTranslation();
  const {
    companyData,
    isLoading,
    moduleDetail,
    propertyData,
    subTotal,
    taxAmount,
  } = templateExtractData(module, previewData);

  // const tax = subTotal * 0.0625;
  const total = subTotal;
  const totalTax = taxAmount || 0;

  const TotalCountRowsUI = (
    <>
      <View style={styles.tableRow}>
        <Text
          style={[
            styles.tableCellWithoutBorder,
            { width: '80%', textAlign: 'right' },
            styles.bold,
          ]}
        >
          {t('common.capital.subtotal')}
        </Text>
        <Text
          style={[
            styles.tableCellWithoutBorder,
            { width: '20%' },
            styles.tableCellRight,
          ]}
        >
          {calculateTotal({ total: total, type: 'subtotal' })}
        </Text>
      </View>
      <View style={styles.tableRow}>
        <View style={{ width: '50%' }}></View>
        <View style={{ width: '10%' }}></View>
        <Text
          style={[
            styles.tableCellWithoutBorder,
            {
              width: '20%',
              textAlign: 'right',
              borderBottom: '1px solid #EBEBEB',
            },
            styles.bold,
          ]}
        >
          {module === TEMPLATE_SET?.INVOICE
            ? `${TAX_NAME} ${
                total && totalTax ? calculateTaxPercentage(total, totalTax) : 0
              }%`
            : companyData?.tax_type && companyData?.tax_value
              ? `${companyData.tax_type} ${companyData.tax_value}%`
              : `${TAX_NAME} ${TAX_VALUE}%`}
        </Text>
        <Text
          style={[
            styles.tableCellWithoutBorder,
            { width: '20%', borderBottom: '1px solid #EBEBEB' },
            styles.tableCellRight,
          ]}
        >
          {module === TEMPLATE_SET?.INVOICE
            ? totalTax
            : calculateTotal({
                total: total,
                type: 'gst',
                taxPercentage: companyData?.tax_value,
              })}
        </Text>
      </View>
      <View style={styles.tableRow}>
        <Text
          style={[
            styles.tableCellWithoutBorder,
            { width: '80%', textAlign: 'right' },
            styles.bold,
          ]}
        >
          {t('common.total')}
        </Text>
        <Text
          style={[
            styles.tableCellWithoutBorder,
            { width: '20%' },
            styles.tableCellRight,
            styles.bold,
          ]}
        >
          {module === TEMPLATE_SET?.INVOICE
            ? calculateTotal({
                total: total,
                taxAmount: totalTax,
                type: 'total',
              })
            : calculateTotal({
                total: total,
                type: 'total',
                taxPercentage: companyData?.tax_value,
              })}
        </Text>
      </View>
    </>
  );

  return (
    <Document>
      <Page style={styles.page}>
        <View>
          {/* Header */}
          <View style={styles.header}>
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  gap: '8px',
                }}
              >
                <Text style={[styles.bold, { minWidth: '80px' }]}>
                  {module === TEMPLATE_SET.INVOICE
                    ? t('attributes.invoice.invoice_number')
                    : module === TEMPLATE_SET.DEFECT_QUOTES
                      ? t('attributes.work_order.quote')
                      : t('attributes.work_order.purchase_order')}
                  :
                </Text>
                <Text>
                  {module === TEMPLATE_SET.INVOICE
                    ? moduleDetail?.account_partner_invoice_number
                    : module === TEMPLATE_SET.DEFECT_QUOTES
                      ? `Q-${moduleDetail?.quotation_id}`
                      : moduleDetail?.poid}
                </Text>
              </View>
              <View
                style={[
                  {
                    flexDirection: 'row',
                    gap: '8px',
                  },
                ]}
              >
                <Text style={[styles.bold, { minWidth: '80px' }]}>
                  {module === TEMPLATE_SET.INVOICE
                    ? t('attributes.invoice.issued_date')
                    : module === TEMPLATE_SET.DEFECT_QUOTES
                      ? t('attributes.work_order.quote_date')
                      : t('attributes.purchase_order.purchase_order_date')}
                  :
                </Text>
                <Text>
                  {module === TEMPLATE_SET.INVOICE
                    ? formattedDate(moduleDetail?.created_at)
                    : module === TEMPLATE_SET.DEFECT_QUOTES
                      ? formattedDate(moduleDetail?.quote_date)
                      : formattedDate(moduleDetail?.created_at)}
                </Text>
              </View>
              <View
                style={[
                  {
                    flexDirection: 'row',
                    gap: '8px',
                  },
                ]}
              >
                <Text style={[styles.bold, { minWidth: '80px' }]}>
                  {module === TEMPLATE_SET.INVOICE
                    ? `${t('attributes.invoice.invoice_due')}:`
                    : module === TEMPLATE_SET.DEFECT_QUOTES &&
                      `${t('attributes.work_order.quote_due')}:`}
                </Text>
                <Text>
                  {module === TEMPLATE_SET.INVOICE
                    ? formattedDate(moduleDetail?.due_date)
                    : module === TEMPLATE_SET.DEFECT_QUOTES &&
                      formattedDate(moduleDetail?.due_date)}
                </Text>
              </View>
              {module === TEMPLATE_SET.DEFECT_QUOTES && (
                <View
                  style={{
                    flexDirection: 'row',
                    gap: '8px',
                  }}
                >
                  <Text style={[styles.bold, { minWidth: '80px' }]}>
                    {t('attributes.work_order.ref')} #:
                  </Text>
                  <Text>-</Text>
                </View>
              )}
            </View>

            <Image
              src={companyData?.logo_url || DarkLogo}
              style={styles.logo}
            />
          </View>

          {/* Invoice Metadata */}
          <View style={styles.section}>
            <View style={styles.sectionBox}>
              <View style={styles.sectionContent}>
                <Text style={[styles.bold, { minWidth: '80px' }]}>
                  {module === TEMPLATE_SET.INVOICE
                    ? t('attributes.invoice.invoice_by')
                    : module === TEMPLATE_SET?.DEFECT_QUOTES
                      ? t('attributes.work_order.quote_by')
                      : t('attributes.purchase_order.purchase_order_from')}
                </Text>
                <Text style={[styles.address, { maxWidth: '100px' }]}>
                  {companyData?.company_name}
                </Text>
              </View>

              <View style={styles.sectionContent}>
                <Text
                  style={[
                    styles.bold,
                    {
                      minWidth:
                        module === TEMPLATE_SET.PURCHASE_ORDER
                          ? '106px'
                          : '80px',
                    },
                  ]}
                >
                  {t('attributes.address')}
                </Text>
                <Text style={[styles.address, { maxWidth: '100px' }]}>
                  {companyData?.address}
                  {'\n'}
                  {formatAddress(
                    companyData?.city?.trim() || '',
                    companyData?.state,
                    companyData?.zip_code
                  )}
                </Text>
              </View>
            </View>

            <View style={styles.sectionBox}>
              <View style={styles.sectionContent}>
                <Text style={[styles.bold, { minWidth: '80px' }]}>
                  {module === TEMPLATE_SET.INVOICE
                    ? t('attributes.invoice.invoice_to')
                    : module === TEMPLATE_SET?.DEFECT_QUOTES
                      ? t('attributes.work_order.quote_to')
                      : t('attributes.purchase_order.purchase_order_to')}
                </Text>
                <Text style={[styles.address, { maxWidth: '100px' }]}>
                  {module === TEMPLATE_SET.INVOICE
                    ? moduleDetail?.contact_name
                    : module === TEMPLATE_SET?.DEFECT_QUOTES
                      ? propertyData?.customer
                      : moduleDetail?.supplier?.name}
                </Text>
              </View>
              <View style={styles.sectionContent}>
                <Text
                  style={[
                    styles.bold,
                    {
                      minWidth:
                        module === TEMPLATE_SET.PURCHASE_ORDER
                          ? '92px'
                          : '80px',
                    },
                  ]}
                >
                  {t('attributes.address')}
                </Text>
                <Text style={[styles.address, { maxWidth: '100px' }]}>
                  {module === TEMPLATE_SET?.PURCHASE_ORDER
                    ? moduleDetail?.supplier?.address
                    : moduleDetail?.address}

                  {[
                    module === TEMPLATE_SET?.PURCHASE_ORDER &&
                      formatAddress(moduleDetail?.supplier?.city || ''),

                    (module === TEMPLATE_SET?.PURCHASE_ORDER &&
                      moduleDetail?.supplier?.state) ||
                      null,

                    (module === TEMPLATE_SET?.PURCHASE_ORDER &&
                      moduleDetail?.supplier?.postcode) ||
                      null,
                  ]
                    .filter(Boolean) // Remove empty/null values
                    .join(', ')}
                </Text>
              </View>
            </View>
          </View>

          {/* Property Information */}
          <View style={styles.sectionBox}>
            <View style={{ flexDirection: 'row' }}>
              <Text
                style={[
                  styles.bold,
                  {
                    ...(module === TEMPLATE_SET.INVOICE
                      ? { width: '120px' }
                      : { maxWidth: '120px' }),
                    textAlign: 'left',
                    paddingRight: 10,
                  },
                ]}
              >
                {module === TEMPLATE_SET.INVOICE ||
                module === TEMPLATE_SET.DEFECT_QUOTES
                  ? t('attributes.property.propertyName')
                  : t('attributes.purchase_order.delivery_instruction')}
                :
              </Text>
              <Text style={styles.address}>
                {module === TEMPLATE_SET.INVOICE
                  ? moduleDetail?.property?.property_name
                  : module === TEMPLATE_SET.DEFECT_QUOTES
                    ? propertyData?.property_name
                    : (moduleDetail?.delivery_instruction ?? '-')}
              </Text>
            </View>
            {module === TEMPLATE_SET.INVOICE && (
              <View style={{ flexDirection: 'row' }}>
                <Text
                  style={[
                    styles.bold,
                    {
                      width: '120px',
                      textAlign: 'left',
                      paddingRight: 10,
                    },
                  ]}
                >
                  {t('attributes.invoice.work_order_name')}:
                </Text>
                <Text style={styles.address}>
                  {moduleDetail?.work_order_data?.work_order_name ?? '-'}
                </Text>
              </View>
            )}
          </View>

          {/* Table Section */}
          {module === TEMPLATE_SET.INVOICE && (
            <View style={styles.table}>
              <View style={styles.tableHeaderRow}>
                <Text
                  style={[
                    styles.tableCell,
                    { width: '50%', borderRight: '0px' },
                    styles.bold,
                  ]}
                >
                  {t('attributes.productCatalogue.product')?.toUpperCase()}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: '10%', borderRight: '0px', borderLeft: '0px' },
                    styles.tableCellCenter,
                    styles.bold,
                  ]}
                >
                  {t('common.qty')}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: '20%', borderLeft: '0px', borderRight: '0px' },
                    styles.tableCellRight,
                    styles.bold,
                  ]}
                >
                  {t('common.unit_price')}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: '20%', borderLeft: '0px' },
                    styles.tableCellRight,
                    styles.bold,
                  ]}
                >
                  {t('common.amount')}
                </Text>
              </View>
              {moduleDetail?.items.map((item, index) => (
                <View
                  style={[
                    styles.tableRow,
                    { backgroundColor: index & 1 && BOX_GRAY },
                  ]}
                  key={index}
                >
                  <Text style={[styles.tableCell, { width: '50%' }]}>
                    <Text style={styles.bold}>
                      {item?.product_name}
                      {'\n'}
                    </Text>
                    <Text style={styles.bold}>
                      {item?.meta_data?.defect_title || ''}
                      {'\n'}
                    </Text>
                    <Text>{item?.meta_data?.defect_description || ''}</Text>
                  </Text>

                  <Text
                    style={[
                      styles.tableCell,
                      { width: '10%' },
                      styles.tableCellCenter,

                      styles.bold,
                    ]}
                  >
                    {item.qty}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      { width: '20%' },
                      styles.tableCellRight,
                    ]}
                  >
                    {item?.price?.toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      { width: '20%' },
                      styles.tableCellRight,
                    ]}
                  >
                    {(item.qty * item?.price)?.toFixed(2)}
                  </Text>
                </View>
              ))}
              {TotalCountRowsUI}
            </View>
          )}
          {module === TEMPLATE_SET.DEFECT_QUOTES && (
            <View style={styles.table}>
              <View style={styles.tableHeaderRow}>
                <Text
                  style={[
                    styles.tableCell,
                    { width: '50%', borderRight: '0px' },
                    styles.bold,
                  ]}
                >
                  {t('common.desciption')}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: '10%', borderRight: '0px', borderLeft: '0px' },
                    styles.tableCellCenter,
                    styles.bold,
                  ]}
                >
                  {t('common.qty')}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: '20%', borderRight: '0px', borderLeft: '0px' },
                    styles.tableCellRight,
                    styles.bold,
                  ]}
                >
                  {t('common.unit_price')}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: '20%', borderLeft: '0px' },
                    styles.tableCellRight,
                    styles.bold,
                  ]}
                >
                  {t('common.amount')}
                </Text>
              </View>
              {moduleDetail?.items.map((item, index) => (
                <View
                  style={[
                    styles.tableRow,
                    { backgroundColor: index & 1 && BOX_GRAY },
                  ]}
                  key={index}
                >
                  <Text style={[styles.tableCell, { width: '50%' }]}>
                    <Text style={styles.bold}>
                      {item.product_catalogue.product_name}
                    </Text>
                    <Text>
                      {item?.asset_details?.label &&
                        `\n${item?.asset_details?.label}`}
                    </Text>
                    <Text style={styles.bold}>
                      {item?.asset_defects?.defect_title &&
                        `\n${item?.asset_defects?.defect_title}`}
                    </Text>
                    <Text>
                      {item.asset_defects?.defect_description &&
                        `\n${item.asset_defects?.defect_description}`}
                    </Text>
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      { width: '10%' },
                      styles.tableCellCenter,
                      styles.bold,
                    ]}
                  >
                    {item.qty}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      { width: '20%' },
                      styles.tableCellRight,
                    ]}
                  >
                    {item.sell_price?.toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      { width: '20%' },
                      styles.tableCellRight,
                    ]}
                  >
                    {(item.qty * item.sell_price)?.toFixed(2)}
                  </Text>
                </View>
              ))}
              {TotalCountRowsUI}
            </View>
          )}
          {module === TEMPLATE_SET.PURCHASE_ORDER && (
            <View style={styles.table}>
              <View style={styles.tableHeaderRow}>
                <Text
                  style={[
                    styles.tableCell,
                    { width: '50%', borderRight: '0px' },
                    styles.bold,
                  ]}
                >
                  {t('common.desciption')}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: '10%', borderRight: '0px', borderLeft: '0px' },
                    styles.tableCellCenter,
                    styles.bold,
                  ]}
                >
                  {t('common.qty')}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: '20%', borderRight: '0px', borderLeft: '0px' },
                    styles.tableCellRight,
                    styles.bold,
                  ]}
                >
                  {t('common.unit_price')}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: '20%', borderLeft: '0px' },
                    styles.tableCellRight,
                    styles.bold,
                  ]}
                >
                  {t('common.amount')}
                </Text>
              </View>
              {moduleDetail?.items.map((item, index) => (
                <View
                  style={[
                    styles.tableRow,
                    { backgroundColor: index & 1 && BOX_GRAY },
                  ]}
                  key={index}
                >
                  <Text style={[styles.tableCell, { width: '50%' }]}>
                    <Text style={styles.bold}>
                      {item.product_catalogue?.product_name}
                    </Text>
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      { width: '10%' },
                      styles.tableCellCenter,
                      styles.bold,
                    ]}
                  >
                    {item.qty}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      { width: '20%' },
                      styles.tableCellRight,
                    ]}
                  >
                    {Number(item.price)?.toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      { width: '20%' },
                      styles.tableCellRight,
                    ]}
                  >
                    {(item.qty * Number(item.price))?.toFixed(2)}
                  </Text>
                </View>
              ))}
              {TotalCountRowsUI}
            </View>
          )}
        </View>
        {module === TEMPLATE_SET.DEFECT_QUOTES && (
          <View style={styles.footer}>
            <Text style={styles.termsAndConditions}>
              {t('common.termsAndCondition')}
            </Text>
            <Text>{t('common.serviceQuotePaymentDescription')}</Text>
            {/* {isFromServiceQuotes ? (
              <Text>{t('common.serviceQuotePaymentDescription')}</Text>
            ) : (
              <>
                <Text>{t('common.paymentDecription1')}</Text>
                <Text>{`${t('common.paymentDecription2')} ${companyData?.company_name}`}</Text>
              </>
            )} */}
          </View>
        )}
      </Page>
    </Document>
  );
};

// Generate PDF function
export const generatePDF = async (module, previewData) => {
  const {
    companyData,
    isLoading,
    moduleDetail,
    propertyData,
    subTotal,
    isFromServiceQuotes,
  } = templateExtractData(module, previewData);
  const blob = await pdf(
    <DownloadPreviewInvoice
      module={module}
      previewData={previewData}
      isFromServiceQuotes={isFromServiceQuotes}
    />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.download = `${moment().format(DATE_FORMAT)}_${module === TEMPLATE_SET?.DEFECT_QUOTES ? `Q-${moduleDetail?.quotation_id}` : module === TEMPLATE_SET?.PURCHASE_ORDER ? moduleDetail?.poid : ''}${module === TEMPLATE_SET?.INVOICE ? moduleDetail?.account_partner_invoice_number : ''}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
