import { useTranslation } from 'react-i18next';

import { CategoryOutlined } from '@mui/icons-material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

import AUTOMATIC_FIRE_SPRINKLER_SYSTEMS_SVG from '../assets/icons/Automatic Fire Sprinkler Systems.svg';
import DELIVERY_LAY_FLAT_HOSE_SVG from '../assets/icons/Delivery Lay Flat Hose.svg';
import EMERGENCY_PLANNING_IN_FACILITIES_SVG from '../assets/icons/Emergency Planning in Facilities.svg';
import FIRE_AND_SMOKE_CONTROL_FEATURES_OF_MECHANICAL_SERVICES_SVG from '../assets/icons/Fire and Smoke Control Features of Mechanical Services.svg';
import FIRE_BLANKETS_SVG from '../assets/icons/Fire Blankets.svg';
import FIRE_DETECTION_AND_ALARM_SYSTEMS_SVG from '../assets/icons/Fire Detection and Alarm Systems.svg';
import FIRE_HOSE_REELS_SVG from '../assets/icons/Fire Hose Reels.svg';
import FIRE_HYDRANT_SYSTEMS_SVG from '../assets/icons/Fire Hydrant Systems.svg';
import FIRE_PUMPSETS_SVG from '../assets/icons/Fire Pumpsets.svg';
import PASSIVE_FIRE_AND_SMOKE_SYSTEMS_SVG from '../assets/icons/Passive Fire and Smoke Systems.svg';
import PORTABLE_WHEELED_FIRE_EXTINGUISHERS_SVG from '../assets/icons/Portable and Wheeled Fire Extinguishers.svg';
import SPECIAL_HAZARD_SYSTEMS_SVG from '../assets/icons/Special Hazard Systems.svg';
import WATERS_STORAGE_TANKS_FOR_FIRE_PROTECTION_SYSTEMS_SVG from '../assets/icons/Water Storage Tanks for Fire Protection Systems.svg';
import { ROUTENAME } from '../constants/RoutesConstants';
import {
  DARK_GRAY,
  DRAFT,
  GREEN,
  INVITE_PENDING,
  PRIMARY,
  TEAL_BLUE,
  WARNING,
} from './Colors';
import {
  CUSTOMER,
  GLOBAL_ADMIN,
  OFFICE_MANAGEMENT,
  OFFICE_STAFF,
  SUPER_ADMIN,
  TECHNICIAN,
} from './Roles';

export const LOCATION = process.env.REACT_APP_LOCATION;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const GOOGLE_PLACE_KEY = process.env.REACT_APP_GOOGLE_PLACES_KEY;
export const TAX_NAME = process.env.REACT_APP_TAX_NAME;
export const TAX_VALUE = process.env.REACT_APP_TAX_VALUE;
export const VAPI_PUBLIC_KEY = process.env.REACT_APP_VAPI_PUBLIC_KEY;
export const VAPI_ASSISTANT_ID = process.env.REACT_APP_VAPI_ASSISTANT_ID;
export const ORA_AI_CHATBOT_LINK = process.env.REACT_APP_ORA_AI_CHATBOT;

export const ADDRESS_REGION_CODE = 'AU';
export const ADDRESS_COUNTRY = 'Australia';

export const COUNTRIES = {
  AUSTRALIA: 'AUS',
  UAE: 'UAE',
};

export const TAB_STATUS = {
  active: 'active',
  inactive: 'inactive',
};

export const getUserStatusList = () => {
  const { t } = useTranslation();

  return [
    { label: t('common.active'), value: true },
    { label: t('common.inactive'), value: false },
  ];
};

export const getRolesList = () => {
  const { t } = useTranslation();

  return [
    { label: t('roles.superAdmin'), value: SUPER_ADMIN },
    { label: t('roles.customer'), value: CUSTOMER },
    { label: t('roles.officeStaff'), value: OFFICE_STAFF },
    { label: t('roles.technician'), value: TECHNICIAN },
    { label: t('roles.officeManagement'), value: OFFICE_MANAGEMENT },
  ];
};

export const setUserManagermentDrawerTitle = (role, t) => {
  const titles = [
    {
      key: SUPER_ADMIN,
      value: `${t('attributes.edit')} ${t('roles.superAdmin')}`,
    },
    {
      key: CUSTOMER,
      value: `${t('attributes.edit')} ${t('roles.customer')}`,
    },
    {
      key: OFFICE_STAFF,
      value: `${t('attributes.edit')} ${t('roles.officeStaff')}`,
    },
    {
      key: TECHNICIAN,
      value: `${t('attributes.edit')} ${t('roles.technician')}`,
    },
    {
      key: OFFICE_MANAGEMENT,
      value: `${t('attributes.edit')} ${t('roles.officeManagement')}`,
    },
  ];

  const matchedTitle = titles.find((title) => title.key === role);

  return matchedTitle ? matchedTitle.value : null;
};

export const filterRoles = (role, t) => {
  const titles = [
    {
      key: SUPER_ADMIN,
      value: t('roles.superAdmin'),
    },
    {
      key: CUSTOMER,
      value: t('roles.customer'),
    },
    {
      key: OFFICE_STAFF,
      value: t('roles.officeStaff'),
    },
    {
      key: TECHNICIAN,
      value: t('roles.technician'),
    },
    {
      key: OFFICE_MANAGEMENT,
      value: t('roles.officeManagement'),
    },
  ];

  const matchedTitle = titles.find((title) => title.key === role);

  return matchedTitle ? matchedTitle.value : null;
};

// Common Status Start
export const PENDING = 'pending';
export const APPROVED = 'approved';
export const OPEN = 'open';
export const SUBMITTED = 'submitted';
export const DRAFT_STATUS = 'draft';
export const CREATED_STATUS = 'created';
export const WO_CREATED_APPROVED = 'wo_created_and_approved';
export const INVITATION_PENDING = 'invitation_pending';
export const PAID = 'paid';
export const DIFFERED = 'differed';
export const DELIVERED = 'delivered';
export const WO_CREATED = 'wo_created';
export const OVERDUE = 'overdue';
export const INVITED_STATUS = 'invited';
export const RE_VISIT = 'revisit';
export const IN_REVIEW = 'in_review';
export const WORK_ORDER_TYPE_ROUTINE = 'routine';
export const WORK_ORDER_TYPE_ADHOC = 'ad_hoc';
export const WORK_ORDER_TYPE_REPAIR = 'repair';
export const WORK_ORDER_TYPE_MAINTENANCE = 'maintenance';
export const PERFORM_ROUTINE_SERVICE = 'perform_routine_service';
export const TRAVEL_TO_PROPERTY = 'travel_to_property';
export const OTHER = 'other';
export const LEAVE = 'leave';
export const EQUIPMENT_STATUS = 'equipment';
export const MATERIAL_STATUS = 'material';
export const EXPENSES_STATUS = 'expenses';
export const EXPENSE_STATUS = 'expense';
export const LABOUR_STATUS = 'labour';
export const AWAITING_FOR_CUSTOMER_CONFIRMATION_STATUS =
  'awaiting_for_customer_confirmation';
export const CONFIRMED_STATUS = 'confirmed';
export const IN_PROGRESS_STATUS = 'in_progress';
export const COMPLETED_STATUS = 'completed';
export const DEFECT_STATUS = 'defect';
export const SERVICE_STATUS = 'service';
export const CRITICAL_STATUS = 'critical';
export const NON_CRITICAL_STATUS = 'non_critical';
export const RECOMMENDATION_STATUS = 'recommendation';
export const NON_CONFORMANCE_STATUS = 'non_conformance';
export const STATUS = 'status';
export const SEVERITY = 'severity';
export const PROPERTIES = 'properties';
export const ASSETS = 'assets';
export const WORK_ORDERS = 'work_orders';
export const DEFECTS = 'defects';
export const DEFECT_QUOTES = 'defect_quotes';
export const LATEST_STATUS = 'latest_status';
export const SCHEDULE_STATUS = 'schedule_status';
export const REJECTED_STATUS = 'rejected';
export const SUBMIT_TO_CUSTOMER_STATUS = 'submit_to_customer';
export const TOTAL_AMOUNT_STATUS = 'total_amount';
export const DELETED_STATUS = 'deleted';
export const OPEN_STATUS = 'open';
export const CLOSED_STATUS = 'closed';
export const ROUTINE_CONTRACT_STATUS = 'routine_contract';
export const CUSTOMER_STATUS = 'customer';
export const PROPERTY_STATUS = 'property';
export const TECHNICIAN_STATUS = 'technician';
export const ASSET_STATUS = 'asset';
export const SUPPLIER_STATUS = 'supplier';
export const PRODUCT_CATALOGUE_STATUS = 'product_catalogue';
export const UPLOADED_STATUS = 'uploaded';
export const PROCESSING_STATUS = 'processing';
export const INSERTED_STATUS = 'inserted';
export const FAILED_STATUS = 'failed';
export const INSERTED_WITH_ERRORS_STATUS = 'inserted_with_errors';
export const COMPLIANCE_STATUS = 'compliant';
export const NON_COMPLIANCE_STATUS = 'non_compliant';
export const PERIODIC = 'amortized';
export const DO_AND_CHARGE = 'do_and_charge';
export const NOT_APPLICABLE = 'N/A';
export const PASS_STATUS = 'pass';
export const FAIL_STATUS = 'fail';
export const NO_CHECK_STATUS = 'no_check';
export const RESOLVED = 'resolved';
export const FIRE_HOSE_REELS = 'fire_hose_reels';
export const FIRE_HYDRANT_SYSTEMS = 'fire_hydrant_systems';
export const AUTOMATIC_FIRE_SPRINKLER_SYSTEMS =
  'automatic_fire_sprinkler_systems';
export const DELIVERY_LAY_FLAT_HOSE = 'delivery_lay_flat_hose';
export const WATERS_STORAGE_TANKS_FOR_FIRE_PROTECTION_SYSTEMS =
  'waters_storage_tanks_for_fire_protection_systems';
export const EMERGENCY_PLANNING_IN_FACILITIES =
  'emergency_planning_in_facilities';
export const FIRE_AND_SMOKE_CONTROL_FEATURES_OF_MECHANICAL_SERVICES =
  'fire_and_smoke_control_features_of_mechanicial_services';
export const FIRE_DETECTION_AND_ALARM_SYSTEMS =
  'fire_detection_and_alarm_systems';
export const FIRE_PUMPSETS = 'fire_pumpsets';
export const PASSIVE_FIRE_AND_SMOKE_SYSTEMS = 'passive_fire_and_smoke_systems';
export const FIRE_BLANKETS = 'fire_blankets';
export const PORTABLE_WHEELED_FIRE_EXTINGUISHERS =
  'portable_wheeled_fire_extinguishers';
export const SPECIAL_HAZARD_SYSTEMS = 'special_hazard_systems';
export const ASSET_NO_CHECK = 'Asset No Check';
export const ASSET_MODIFIED = 'Asset Modified';
export const DEFECT_RESOLVED = 'Defect Resolved';
export const DEFECT_RAISED = 'Defect Raised';
export const ASSET_FAILED = 'Asset Failed';
export const ASSET_PASSED = 'Asset Passed';
export const ASSET_ADDED = 'Asset Added';
export const CONTRACT = 'contract';
export const NON_CONTRACT = 'non_contract';
export const ACTIVE = 'active';
export const IN_ACTIVE = 'inactive';
export const XERO = 'XERO';
export const DBC365 = 'DBC365';
export const SOMETHING_WENT_WRONG_IMAGE = 'something-went-wrong-image';

export const OCSP = 'OCSP';
export const DP_DN = 'DP/DN';

export const FIXED_WIDTH = 107;
export const FIXED_HEIGHT = 40;

// Common Status End
export const LABELS = {
  COMPLIANCE_STATUS: 'Compliant',
  NON_COMPLIANCE_STATUS: 'Non-Compliant',
  OPEN: 'Open',
  CLOSED: 'Closed',
  DRAFT: 'Draft',
  INVITED: 'Invited',
  INVITATION_PENDING: 'Invitation Pending',
  SUBMITTED: 'Submitted',
  IN_REVIEW: 'In Review',
  RE_VISIT: 'Re-Visit',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  OVERDUE: 'Overdue',
  NA: 'N/A',
  WO_CREATED: 'WO Created',
  REPAIR: 'Repair',
  AD_HOC: 'Ad-hoc',
  ROUTINE_SERVICE: 'Routine Service',
  ACTIVE: 'Active',
  IN_ACTIVE: 'Inactive',
  DIFFERED: 'Differed',
};

export const CUSTOMER_DEFECT_QUOTE_LIST = [
  {
    label: LABELS.DRAFT,
    value: DRAFT_STATUS,
  },
  {
    label: LABELS.SUBMITTED,
    value: SUBMIT_TO_CUSTOMER_STATUS,
  },
  {
    label: LABELS.WO_CREATED,
    value: WO_CREATED,
  },
  {
    label: LABELS.IN_REVIEW,
    value: CREATED_STATUS,
  },
  {
    label: LABELS.APPROVED,
    value: APPROVED,
  },
  {
    label: LABELS.REJECTED,
    value: REJECTED_STATUS,
  },
  {
    label: LABELS.OVERDUE,
    value: OVERDUE,
  },
  {
    label: LABELS.DIFFERED,
    value: DIFFERED,
  },
];

export const CUSTOMER_COMPLIANCE_STATUS = [
  {
    label: LABELS.COMPLIANCE_STATUS,
    value: COMPLIANCE_STATUS,
  },
  {
    label: LABELS.NON_COMPLIANCE_STATUS,
    value: NON_COMPLIANCE_STATUS,
  },
  {
    label: LABELS.NA,
    value: NOT_APPLICABLE,
  },
];

export const INVITATION_STATUS = {
  draft: DRAFT_STATUS,
  invited: INVITED_STATUS,
  invitation_pending: INVITATION_PENDING,
  submitted: SUBMITTED,
  approved: APPROVED,
};

export const INVITATION_STATUS_DISPLAY = {
  draft: LABELS.DRAFT,
  invited: LABELS.INVITED,
  invitation_pending: LABELS.INVITATION_PENDING,
  submitted: LABELS.SUBMITTED,
  approved: LABELS.APPROVED,
};

export const STATUS_COLOR_MAP = {
  draft: DRAFT,
  invited: PRIMARY,
  invitation_pending: INVITE_PENDING,
};

export const SIX_MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
export const TWELEVE_MONTHS = [
  ...SIX_MONTHS,
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const MONTHS_DROPDOWN = [
  { label: 'Six Months', value: 6 },
  { label: 'Twelve Months', value: 12 },
];
//  CHART_TYPE
export const PIE_CHART = 'pie_chart';
export const BAR_CHART = 'bar_chart';
export const LINE_CHART = 'line_chart';
export const SCATTER_CHART = 'scatter_chart';
export const SPARKLINE_CHART = 'sparkline_chart';
export const GAUGE_CHART = 'gauge_chart';

// Date format
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_RANGE_FORMAT = 'dd/MM/yyyy';
export const DATE_TIME_RANGE_FORMAT = 'dd/MM/yyyy hh:mm aa';
export const DATE_TIME_RANGE_FORMAT_DEFAULT = 'DD/MM/YYYY hh:mm A';
export const DATE_TIME_RANGE_FORMAT_PLACEHOLDER = 'DD/MM/YYYY HH:MM A';
export const DATE_FORMAT_API = 'YYYY-MM-DD';
export const DATE_FORMAT_API_WITH_TIME = 'YYYY-MM-DDTHH:mm';
export const DATE_CONVERT_UTC_FORMAT_API = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const DATE_RANGE_FORMAT_API = 'YYYY-MM-DDTHH:mm:ss';
export const SCHEDULE_BOARD_DAY = 'MMMM DD, yyyy';
export const SCHEDULE_BOARD_WEEK_START = 'MMMM DD';
export const SCHEDULE_BOARD_WEEK_END = 'DD, YYYY';
export const SCHEDULE_BOARD_MONTH = 'MMMM, yyyy';
export const SCHEDULE_BOARD_WO_UTC_LOCAL = 'MM/DD/YYYY hh:mm A';

// Font size
export const FONT_SIZE = '14px';
// SSO
export const LOGIN_TYPE = {
  microsoft: 'microsoft',
  google: 'google',
};

export const TIME_SHEET_TYPE = [
  {
    label: 'Perform Routine Service',
    value: PERFORM_ROUTINE_SERVICE,
  },

  {
    label: 'Travel To Property',
    value: TRAVEL_TO_PROPERTY,
  },
  {
    label: 'Leave',
    value: LEAVE,
  },
  {
    label: 'Other',
    value: OTHER,
  },
];

export const CONFIRMATIVE_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const TECHNICIAN_PERFORMANCE_KEY = [
  // 'total_work_orders',
  'total_work_order',
  'defects_raised',
  'average_work_orders_perform_per_day',
  'average_defect_quote_per_day',
  'average_hours_per_day',
  'total_work_hours_per_month',
  'work_order_completed_within_time_duration',
  'work_order_completed_per_day',
  'defect_quotes_issued_daily',
  'daily_asset_totals',
  'daily_defect_count',
  'productivity',
  'total_cost',
];

// Dashboard Constants Start
export const DASHBOARD = 'dashboard';

export const CHART_CONST = {
  PROPERTIES: 'properties',
  QUOTES: 'quotes',
  SERVICE: 'service_quotes',
  DEFECTS: 'defects',
  PURCHASE_ORDER: 'purchase_order',
  FIRE_COMPANY: 'fire_companies',
  FIRE_COMPANY_STATUS: 'fire_companies_status',
  CUSTOMER_PROPERTIES: 'customer_properties',
  USERS: 'users',
  INVOICES: 'invoices',
  PROPERTY_COMPLIANCE: 'property_compliance',
  USER_MANAGEMENT: 'user-management',
  WORK_ORDERS: 'work_orders',
  SCHEDULE_BOARD: 'schedule_board',
};

export const ROUTE_NAVIGATE_CONST = {
  PROPERTIES: 'sidebar_properties',
  TEMPLATE: 'template',
  TEMPLATES: 'templates',
  CONTROL_PANEL: 'control_panel',
  REPORTING: 'reporting',
  TECHNICIAN_PERFORMANCE_LIST: 'technician_performance_list',
  TECHNICIAN_PERFORMANCE_CHARTS: 'technician_performance_charts',
  INVOICE_EDIT: 'invoice_edit',
  INVOICE: 'sidebar_invoice',
  PROPERTY_ASSET: 'assets',
  PROPERTY_ASSET_DETAIL: 'asset-detail',
  PROPERTY_FLOOR_PLAN: 'floor-plan',
  PROPERTY_ROUTINE: 'routine',
  PROPERTY_CONTRACT: 'contract',
  PROPERTY_SERVICE_QUOTE: 'service-quote',
  PROPERTY_INVOICE: 'property-invoices',
  PROPERTY_COMING_SOON: 'coming-soon',
  WORK_ORDER_DETAIL: 'work-order-detail',
  INTEGRATION: 'integration',
  SUPPLIER: 'supplier',
  DASHBOARD: 'dashboard',
  CUSTOMER: 'sidebar_customer',
  CUSTOMER_BILLING: 'customer_billing',
  BILLING_CONTRACTS: 'billing_contracts',
  DATA_MANAGEMENT: 'data_management',
  PRODUCT_CATALOGUE: 'product_catalogue',
  TECHNICIAN_LIST: 'technician_list',
  GLOBAL_ADMIN_INTEGRATIONS: 'global_admin_integrations',
  QUOTES: 'quotes',
  QUOTES_STATUS: 'quotes_status',
  INVOICES: 'invoices',
  INVOICES_STATUS: 'invoices_status',
  DEFECTS: 'defects',
  DEFECTS_STATUS: 'defects_status',
  SERVICE_QUOTES: 'service_quotes',
  NOT_FOUND_404: '404_not_found',
  PROPERTY_DETAIL: 'property_detail',
  CONFIGURATION: 'configuration',
  CONTACT: 'contact',
  CONTACT_DETAIL: 'contact_detail',
};

export const DASHBOARD_CHART_LABEL_TYPE = {
  PERCENTAGE: 'percentage',
  VALUE: 'value',
};

export const CUSTOMER_DASHBOARD_DEFECT_VERTICAL_CHART = [
  { label: 'Severity', value: SEVERITY },
  { label: 'Status', value: STATUS },
];

export const CUSTOMER_DASHBOARD_DEFECT_HORIZONTAL_CHART = [
  { label: 'Equipment Type', value: ASSETS },
  { label: 'Properties', value: PROPERTIES },
];

export const StaticChartData = [
  {
    id: 1,
    name: 'defects',
    order: 1,
    display_name: 'Defects',
    description: 'Critical, Non-Critical,Non-Conformance',
    is_active: true,
    isRadius: true,
    default_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    selected_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    supported_types: [
      {
        id: 5,
        name: 'pie_chart',
        display_name: 'Pie Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      pie_chart: {
        data: [
          { label: 'Group A', value: 40, color: '#F06548' },
          { label: 'Group B', value: 30, color: '#95C020' },
          { label: 'Group C', value: 30, color: '#299CDB' },
        ],
      },
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Critical',
            id: CRITICAL_STATUS,
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Non-Critical',
            id: 'nonCritical',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Non-Conformance',
            id: 'nonConformance',
            stack: 'total',
          },
        ],
        colors: ['#F06548', '#95C020', '#299CDB'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
    },
  },
  {
    id: 2,
    name: 'quotes',
    order: 2,
    display_name: 'Quotes',
    description:
      'Open, Pending Approval, Assigned,In Progress, Delayed, Overdue',
    is_active: true,
    isRadius: true,
    default_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    selected_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    supported_types: [
      {
        id: 5,
        name: 'pie_chart',
        display_name: 'Pie Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      pie_chart: {
        data: [
          { label: 'Open', value: 25, color: '#95C020' },
          { label: 'Pending Approval', value: 18, color: '#405189' },
          { label: 'Assigned', value: 17, color: '#299CDB' },
          { label: 'In Progress', value: 9, color: '#F7B84B' },
          { label: 'Delayed', value: 9, color: '#F06548' },
          { label: 'Overdue', value: 22, color: '#A85BBB' },
        ],
      },
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Open',
            id: OPEN_STATUS,
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Pending Approval',
            id: 'pendingApproval',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Assigned',
            id: 'assigned',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'In Progress',
            id: 'inProgress',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Delayed',
            id: 'delayed',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Overdue',
            id: OVERDUE,
            stack: 'total',
          },
        ],
        colors: [
          '#95C020',
          '#405189',
          '#299CDB',
          '#F7B84B',
          '#F06548',
          '#A85BBB',
        ],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
    },
  },
  {
    id: 3,
    name: 'invoices',
    order: 3,
    display_name: 'Invoices',
    description: 'Not Sent, In Progress, Pending Payments, Dispute, Overdue',
    is_active: true,
    default_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    selected_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    supported_types: [
      {
        id: 5,
        name: 'pie_chart',
        display_name: 'Pie Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      pie_chart: {
        data: [
          { label: 'Not Sent', value: 14, color: '#95C020' },
          { label: 'In Progress', value: 3, color: '#F7B84B' },
          { label: 'Pending Payments', value: 1, color: '#F06548' },
          { label: 'Dispute', value: 1, color: '#299CDB' },
          { label: 'Overdue', value: 1, color: '#A85BBB' },
        ],
      },
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Not Sent',
            id: 'notSent',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'In Progress',
            id: 'inProgress',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Pending Payments',
            id: 'pendingPayments',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Dispute',
            id: 'dispute',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Overdue',
            id: OVERDUE,
            stack: 'total',
          },
        ],
        colors: ['#95C020', '#F7B84B', '#F06548', '#299CDB', '#A85BBB'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
    },
  },
  {
    id: 4,
    name: 'routine_services',
    display_name: 'Routine Services',
    description: 'Unassigned, In Progress, Overdue, Blocked',
    is_active: true,
    order: 4,
    default_type: {
      id: 4,
      name: 'bar_chart',
      display_name: 'Bar Chart',
    },
    selected_type: {
      id: 4,
      name: 'bar_chart',
      display_name: 'Bar Chart',
    },
    supported_types: [
      {
        id: 9,
        name: 'line_chart',
        display_name: 'Line Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Unassigned',
            id: 'unAssigned',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'In Progress',
            id: 'inProgressId',
            stack: 'total',
          },
          {
            data: [10, 17, 20, 25, 5, 15],
            label: 'Overdue',
            id: 'overDue',
            stack: 'total',
          },
          {
            data: [15, 23, 15, 60, 35, 5],
            label: 'Blocked',
            id: 'blocked',
            stack: 'total',
          },
        ],
        colors: ['#95C020', '#F7B84B', '#A85BBB', '#F06548'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
      line_chart: {
        data: [
          {
            data: [2, 5.5, 2, 8.5, 1.5, 5],
            label: 'Unassigned',
            color: '#95C020',
          },
          {
            data: [3, 5, 2, 8, 1.5, 5],
            label: 'In Progress',
            color: '#F7B84B',
          },
          {
            data: [7, 5, 2, 5, 1, 5],
            label: 'Overdue',
            color: '#A85BBB',
          },
          {
            data: [2, 1, 2, 8, 1, 5],
            label: 'Blocked',
            color: '#F06548',
          },
        ],
        labels: [1, 2, 3, 4, 5, 6],
      },
    },
  },
  {
    id: 5,
    name: 'service_request',
    display_name: 'Service Request',
    description: 'Unassigned, In Progress, Overdue, Blocked',
    is_active: true,
    order: 5,
    default_type: {
      id: 4,
      name: 'bar_chart',
      display_name: 'Bar Chart',
    },
    selected_type: {
      id: 4,
      name: 'bar_chart',
      display_name: 'Bar Chart',
    },
    supported_types: [
      {
        id: 9,
        name: 'line_chart',
        display_name: 'Line Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Unassigned',
            id: 'unAssigned',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'In Progress',
            id: 'inProgressId',
            stack: 'total',
          },
          {
            data: [10, 17, 20, 25, 5, 15],
            label: 'Overdue',
            id: 'overDue',
            stack: 'total',
          },
          {
            data: [15, 23, 15, 60, 35, 5],
            label: 'Blocked',
            id: 'blocked',
            stack: 'total',
          },
        ],
        colors: ['#95C020', '#F7B84B', '#A85BBB', '#F06548'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
      line_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45], // Unassigned
            label: 'Unassigned',
          },
          {
            data: [15, 12, 20, 25, 10, 20], // In Progress
            label: 'In Progress',
          },
          {
            data: [10, 17, 20, 25, 5, 15], // Overdue
            label: 'Overdue',
          },
          {
            data: [15, 23, 15, 60, 35, 5], // Blocked
            label: 'Blocked',
          },
        ],
        labels: [1, 2, 3, 4, 5, 6],
      },
    },
  },
  {
    id: 6,
    name: 'properties',
    order: 6,
    display_name: 'Properties',
    description:
      'Created, Deactivated, Reactivated,Contracts Expired, Contracts Expiring Soon',
    is_active: true,
    default_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    selected_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    supported_types: [
      {
        id: 5,
        name: 'pie_chart',
        display_name: 'Pie Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      pie_chart: {
        data: [
          { label: 'Created', value: 14, color: '#95C020' },
          { label: 'Deactivated', value: 3, color: '#405189' },
          { label: 'Reactivated', value: 1, color: '#299CDB' },
          { label: 'Contracts Expired', value: 1, color: '#F06548' },
          { label: 'Contracts Expiring Soon', value: 1, color: '#F7B84B' },
        ],
      },
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Created',
            id: CREATED_STATUS,
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Deactivated',
            id: 'deactivated',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Reactivated',
            id: 'reactivated',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Contracts Expired',
            id: 'contractsExpired',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Contracts Expiring Soon',
            id: 'contractsExpiringSoon',
            stack: 'total',
          },
        ],
        colors: ['#95C020', '#405189', '#299CDB', '#F06548', '#F7B84B'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
    },
  },
];

export const GlobalAdminChartStatic = [
  {
    id: 1,
    order: 1,
    name: 'fire_companies_status',
    display_name: 'Active vs Inactive Company by Month',
    is_active: true,
    isRadius: true,
    default_type: {
      id: 1,
      name: 'line_chart',
      display_name: 'Line Chart',
    },
    selected_type: {
      id: 1,
      name: 'line_chart',
      display_name: 'Line Chart',
    },
    supported_types: [
      {
        id: 1,
        name: 'line_chart',
        display_name: 'Line Chart',
      },
    ],
    widget_data: {},
  },
  {
    id: 2,
    name: 'fire_companies',
    order: 2,
    display_name: 'Fire Companies by Region',
    description:
      'New South Wales, Victoria, Queensland Western Australia, South Australia, Tasmania Northern Territory',
    is_active: true,
    isRadius: true,
    default_type: {
      id: 1,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    selected_type: {
      id: 1,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    supported_types: [
      {
        id: 1,
        name: 'pie_chart',
        display_name: 'Pie Chart',
      },
      {
        id: 2,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      pie_chart: {
        data: [
          { label: 'Group A', value: 40, color: '#F06548' },
          { label: 'Group B', value: 30, color: '#95C020' },
          { label: 'Group C', value: 30, color: '#299CDB' },
        ],
      },
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Critical',
            id: CRITICAL_STATUS,
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Non-Critical',
            id: 'nonCritical',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Non-Conformance',
            id: 'nonConformance',
            stack: 'total',
          },
        ],
        colors: ['#F06548', '#95C020', '#299CDB'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
    },
  },
  {
    id: 3,
    name: 'customer_properties',
    order: 3,
    display_name: 'Properties by Region',
    description:
      'New South Wales, Victoria, Queensland Western Australia, South Australia, Tasmania Northern Territory',
    is_active: true,
    isRadius: true,
    default_type: {
      id: 1,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    selected_type: {
      id: 1,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    supported_types: [
      {
        id: 1,
        name: 'pie_chart',
        display_name: 'Pie Chart',
      },
      {
        id: 2,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      pie_chart: {
        data: [
          { label: 'Open', value: 25, color: '#95C020' },
          { label: 'Pending Approval', value: 18, color: '#405189' },
          { label: 'Assigned', value: 17, color: '#299CDB' },
          { label: 'In Progress', value: 9, color: '#F7B84B' },
          { label: 'Delayed', value: 9, color: '#F06548' },
          { label: 'Overdue', value: 22, color: '#A85BBB' },
        ],
      },
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Open',
            id: OPEN_STATUS,
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Pending Approval',
            id: 'pendingApproval',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Assigned',
            id: 'assigned',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'In Progress',
            id: 'inProgress',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Delayed',
            id: 'delayed',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Overdue',
            id: OVERDUE,
            stack: 'total',
          },
        ],
        colors: [
          '#95C020',
          '#405189',
          '#299CDB',
          '#F7B84B',
          '#F06548',
          '#A85BBB',
        ],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
    },
  },
  {
    id: 4,
    name: 'users',
    order: 4,
    display_name: 'Active users of STAM by Region',
    description:
      'New South Wales, Victoria, Queensland Western Australia, South Australia, Tasmania Northern Territory',
    is_active: true,
    isRadius: true,
    default_type: {
      id: 1,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    selected_type: {
      id: 1,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    supported_types: [
      {
        id: 1,
        name: 'pie_chart',
        display_name: 'Pie Chart',
      },
      {
        id: 2,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      pie_chart: {
        data: [
          { label: 'Not Sent', value: 14, color: '#95C020' },
          { label: 'In Progress', value: 3, color: '#F7B84B' },
          { label: 'Pending Payments', value: 1, color: '#F06548' },
          { label: 'Dispute', value: 1, color: '#299CDB' },
          { label: 'Overdue', value: 1, color: '#A85BBB' },
        ],
      },
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Not Sent',
            id: 'notSent',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'In Progress',
            id: 'inProgress',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Pending Payments',
            id: 'pendingPayments',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Dispute',
            id: 'dispute',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Overdue',
            id: OVERDUE,
            stack: 'total',
          },
        ],
        colors: ['#95C020', '#F7B84B', '#F06548', '#299CDB', '#A85BBB'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
    },
  },
];

// Dashboard Constants End

export const PRODUCT_TYPES_LABEL = {
  EQUIPMENT: EQUIPMENT_STATUS,
  MATERIAL: MATERIAL_STATUS,
  EXPENSES: EXPENSES_STATUS,
  EXPENSE: EXPENSE_STATUS,
  LABOUR: LABOUR_STATUS,
};

export const PRODUCT_TYPES = [
  { label: 'Material', value: MATERIAL_STATUS },
  { label: 'Equipment', value: EQUIPMENT_STATUS },
  { label: 'Expense', value: EXPENSE_STATUS },
  { label: 'Labour', value: LABOUR_STATUS },
];

export const UNIT_OF_MEASURES = [
  { label: 'Each', value: 'each' },
  { label: 'Length', value: 'length' },
  { label: 'Weight', value: 'weight' },
];

export const PROPERTY_DETAIL_STEP = {
  assetDetails: 'Assets',
  billingContracts: 'Contracts',
  invoice: 'Invoice',
  floorPlan: 'Floor Plan',
  serviceQuote: 'Service Quote',
};

export const WORK_ORDER_STEPS = {
  defect_quotes: 'Defects Quote',
  purchase_orders: 'Purchase Orders',
  invoice: 'Invoice',
  service_quotes: 'Service Quote',
};

// OTHER CHART

export const SCHEDULE_BOARD_CALENDAR_VIEW = {
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
};

export const WO_STATUS = {
  created: CREATED_STATUS,
  pending: PENDING,
  awaitingForCustomerConfirmation: AWAITING_FOR_CUSTOMER_CONFIRMATION_STATUS,
  confirmed: CONFIRMED_STATUS,
  inProgress: IN_PROGRESS_STATUS,
  submitted: SUBMITTED,
  completed: COMPLETED_STATUS,
  re_visit: RE_VISIT,
  in_review: IN_REVIEW,
};

export const QUOTE_TYPES = {
  defect: DEFECT_STATUS,
  service: SERVICE_STATUS,
};

export const WORK_ORDER_TYPE_LIST = [
  {
    label: 'Routine Service',
    value: WORK_ORDER_TYPE_ROUTINE,
  },
  {
    label: 'Maintenance',
    value: WORK_ORDER_TYPE_MAINTENANCE,
  },
  {
    label: 'Ad-Hoc',
    value: WORK_ORDER_TYPE_ADHOC,
  },
  {
    label: 'Repair',
    value: WORK_ORDER_TYPE_REPAIR,
  },
];

export const DEFECT_SEVERITY = [
  {
    label: 'Critical',
    value: CRITICAL_STATUS,
  },
  {
    label: 'Non-Critical',
    value: NON_CRITICAL_STATUS,
  },
  {
    label: 'Non-Conformance',
    value: NON_CONFORMANCE_STATUS,
  },
  {
    label: 'Recommendation',
    value: RECOMMENDATION_STATUS,
  },
];

export const CUSTOMER_DEFECT_STATUS = [
  {
    label: 'Open',
    value: OPEN_STATUS,
  },
  {
    label: 'Closed',
    value: RESOLVED,
  },
];

// Work Order Status Lable Start
export const CREATED = 'Created';
export const SCHEDULED = 'Scheduled';
export const APPOINTMENT_CONFIRMED = 'Appointment Confirmed';
export const IN_PROGRESS = 'In-Progress';
export const COMPLETED = 'Completed';
// Work Order Status Lable End

// Quote Type Label Start
export const DEFECT = 'Defect';

// Defect Status Lable Start
export const DEFECT_SEVERITY_LABEL = {
  nonCritical: 'Non-Critical',
  nonConformance: 'Non-Conformance',
  critical: 'Critical',
};
// Defect Status Lable End

// Assets Status Lable Start
export const ASSETS_STATUS_LABEL = {
  fail: 'Fail',
  pass: 'Pass',
  noCheck: 'No Check',
};

export const ASSETS_STATUS_REPORT_LABEL = {
  [FAIL_STATUS]: 'FAIL',
  [PASS_STATUS]: 'PASS',
  [NO_CHECK_STATUS]: 'NO CHECK',
};
// Assets Status Lable End

// Work Order Status Filter ENUMS Start
export const STATUS_LIST = [
  {
    label: CREATED,
    value: WO_STATUS.created,
    key: LATEST_STATUS,
    filter: {
      latest_status: WO_STATUS.created,
      schedule_status: WO_STATUS.pending,
    },
  },
  {
    label: SCHEDULED,
    value: WO_STATUS.awaitingForCustomerConfirmation,
    key: SCHEDULE_STATUS,
    filter: {
      latest_status: WO_STATUS.created,
      schedule_status: WO_STATUS.awaitingForCustomerConfirmation,
    },
  },
  {
    label: APPOINTMENT_CONFIRMED,
    value: WO_STATUS.confirmed,
    key: SCHEDULE_STATUS,
    filter: {
      latest_status: WO_STATUS.created,
      schedule_status: WO_STATUS.confirmed,
    },
  },
  {
    label: IN_PROGRESS,
    value: WO_STATUS.inProgress,
    key: LATEST_STATUS,
    value2: WO_STATUS.awaitingForCustomerConfirmation,
    key2: SCHEDULE_STATUS,
    filter: {
      latest_status: WO_STATUS.inProgress,
    },
  },
  {
    label: LABELS.IN_REVIEW,
    value: WO_STATUS.in_review,
    key: LATEST_STATUS,
    filter: {
      latest_status: WO_STATUS.in_review,
    },
  },
  {
    label: LABELS.RE_VISIT,
    value: WO_STATUS.re_visit,
    key: LATEST_STATUS,
    filter: {
      latest_status: WO_STATUS.re_visit,
    },
  },
  {
    label: COMPLETED,
    value: WO_STATUS.completed,
    key: LATEST_STATUS,
    filter: {
      latest_status: WO_STATUS.completed,
    },
  },
];
// Work Order Status Filter ENUMS End

// Quote Types ENUM
export const QUOTE_TYPE_LIST = [
  {
    label: DEFECT,
    value: QUOTE_TYPES.defect,
  },
];

// Function to get labels from STATUS_LIST based on status keys
export const getStatusLabels = (item) => {
  const latestStatusLabel = STATUS_LIST.find(
    (status) =>
      status.key === LATEST_STATUS && status.value === item.latest_status
  );

  let scheduleStatusLabel = null;
  let combineStatusLabel = null;

  if (
    latestStatusLabel?.value !== WO_STATUS.completed &&
    latestStatusLabel?.value !== WO_STATUS.inProgress &&
    latestStatusLabel?.value !== WO_STATUS.in_review &&
    latestStatusLabel?.value !== WO_STATUS.re_visit
  ) {
    scheduleStatusLabel = STATUS_LIST.find(
      (status) =>
        status.key === SCHEDULE_STATUS && status.value === item.schedule_status
    )?.label;

    combineStatusLabel = STATUS_LIST.find(
      (status) =>
        status.key === LATEST_STATUS &&
        status.key2 === SCHEDULE_STATUS &&
        status.value === item.latest_status &&
        status.value2 === item.schedule_status
    )?.label;
  }

  return combineStatusLabel
    ? combineStatusLabel
    : scheduleStatusLabel
      ? scheduleStatusLabel
      : latestStatusLabel?.label;
};

// Common Error Message
export const COMMON_ERROR_MESSAGE = {
  default: 'Something went wrong on our end. Please try again later.',
};

export const FILE_UPLOAD_SIZES = {
  DOCUMENT: 50_000_000,
  IMAGE: 15_000_000,
};

export const PURCHASE_ORDER_STATUS = {
  DELETED: DELETED_STATUS,
  SUBMITTED: SUBMITTED,
  DRAFT: DRAFT_STATUS,
  CREATED: CREATED_STATUS,
  PAID: PAID,
  DELIVERED: DELIVERED,
};

export const CUSTOMER_QUOTATION_STATUS = {
  WO_CREATED_APPROVED: WO_CREATED_APPROVED,
  APPROVED: APPROVED,
  REJECTED: REJECTED_STATUS,
  PENDING: SUBMIT_TO_CUSTOMER_STATUS,
  OVERDUE: OVERDUE,
  TOTAL_AMOUNT: TOTAL_AMOUNT_STATUS,
  DIFFERED: DIFFERED,
};

export const CUSTOMER_QUOTATION_CHART_LABEL = {
  submit_to_customer: 'Pending',
  // approved: 'Approved',
  wo_created_and_approved: 'Approved',
  rejected: 'Reject',
  overdue: 'Overdue',
  differed: 'Differed',
};

// Random Color
export const COUNTRY_COLOR = [
  '#FF5733', // Vibrant Orange
  '#33FF57', // Lime Green
  '#5733FF', // Indigo
  '#FF33A1', // Pink
  '#33FFF3', // Cyan
  '#FFD133', // Golden Yellow
  '#8E44AD', // Purple
  '#34495E', // Navy Gray
  '#2ECC71', // Emerald Green
  '#E74C3C', // Bright Red
  '#FF7F50', // Coral
  '#6495ED', // Cornflower Blue
  '#DC143C', // Crimson
  '#00CED1', // Dark Turquoise
  '#FFD700', // Gold
  '#ADFF2F', // Green Yellow
  '#FF69B4', // Hot Pink
  '#4B0082', // Indigo
  '#20B2AA', // Light Sea Green
  '#9370DB', // Medium Purple
];

// Chart Colors Start
export const CHART_COLOR = {
  // Properties
  setup: '#95C020',
  hold: '#299CDB',
  active: '#F06548',
  inactive: '#F7B84B',
  compliant: PRIMARY,
  non_compliant: WARNING,
  not_applicable: '#454545',
  // Defects
  critical: '#C70000',
  non_critical: '#95C020',
  non_conformance: '#59366B',
  wo_created: DARK_GRAY,
  recommendation: '#FD7E14',

  // Quotation
  approved: '#95C020',
  rejected: '#C54036',
  submitted: '#95C020',
  submit_to_customer: '#FD7E14',
  'in progress': '#007BFF',
  paid: '#FF33A1',
  overdue: '#e466ed',
  differed: '#FFA500',

  // Purchase Order
  draft: '#299CDB',
  created: '#F06548',
};
// Chart Colors End

export const certificationNumberDropdown = [
  { value: OCSP, label: OCSP },
  { value: DP_DN, label: DP_DN },
];

// Colors
export const STATUS_BASED_COLOR = {
  [RE_VISIT]: TEAL_BLUE,
  [COMPLETED_STATUS]: PRIMARY,
  [IN_REVIEW]: GREEN,
  [WO_CREATED]: DARK_GRAY,
};

export const DOCUMENT_IMPORT_STATUS_LIST = [
  { value: UPLOADED_STATUS, label: 'Uploaded' },
  { value: PROCESSING_STATUS, label: 'Processing' },
  { value: INSERTED_STATUS, label: 'Inserted' },
  { value: FAILED_STATUS, label: 'Failed' },
  { value: INSERTED_WITH_ERRORS_STATUS, label: 'Inserted with Errors' },
];

export const DOCUMENT_TEMPLATE_TYPES = [
  { value: CUSTOMER_STATUS, label: 'Customer' },
  { value: PROPERTY_STATUS, label: 'Property' },
  { value: TECHNICIAN_STATUS, label: 'Technician' },
  { value: ASSET_STATUS, label: 'Asset' },
  { value: SUPPLIER_STATUS, label: 'Supplier' },
  { value: PRODUCT_CATALOGUE_STATUS, label: 'Product catalogue' },
];

export const INVOICE_STATUS_LIST = [
  { value: DRAFT_STATUS, label: 'Draft' },
  { value: CREATED_STATUS, label: 'Created' },
  { value: SUBMITTED, label: 'Submitted' },
  { value: PAID, label: 'Paid' },
  { value: DIFFERED, label: 'Differed' },
  { value: OVERDUE, label: 'Overdue' },
];

export const PURCHASE_ORDER_STATUS_DROPDOWN = [
  { value: DRAFT_STATUS, label: 'Draft' },
  { value: CREATED_STATUS, label: 'Created' },
  { value: SUBMITTED, label: 'Submitted' },
  // { value: DELETED_STATUS, label: 'Deleted' },
  { value: DELIVERED, label: 'Delivered' },
  { value: PAID, label: 'Paid' },
];

export const CURRENCY = {
  USD: 'USD',
  AUD: 'AUD',
};

export const INVOICE_FREQUENCY_ENUM = {
  MONTHLY: 'M',
  YEARLY: 'Y',
};

export const FREQUENCY_TYPE_LIST = [
  { value: 'W', label: 'Weekly' },
  { value: 'M', label: 'Month' },
  { value: 'Y', label: 'Yearly' },
];

export const TOLERANCE_TYPE_LIST = [
  { value: 'D', label: 'Day' },
  { value: 'W', label: 'Weekly' },
  { value: 'M', label: 'Month' },
  { value: 'Y', label: 'Yearly' },
];

export const ROUTINE_TYPES = {
  STANDARD: 'standard',
  CUSTOM: 'custom',
};

export const WORK_ORDER_TYPE = [
  { value: WORK_ORDER_TYPE_ADHOC, label: 'Ad-Hoc' },
  { value: WORK_ORDER_TYPE_ROUTINE, label: 'Routine Service' },
  { value: WORK_ORDER_TYPE_REPAIR, label: 'Repair' },
];

export const INTEGRATION_TYPES = {
  VERIFY: 'verify',
  SAVE: 'save',
};

export const INSPECTION_FORM_RADIO = {
  DEFAULT: '0',
  RED: '1',
  GREY: '2',
};

export const RADIO_DEFAULT = '0';
export const RADIO_RED = '1';
export const RADIO_GREY = '2';

export const INSPECTION_FORM = {
  RADIO_GROUP: 'radiogroup',
  COMMENT: 'comment',
  COMMENT_CAPITAL: 'Comment',
  RESULT: 'Result',
  PANEL: 'panel',
};

export const redirectURL = {
  [SUPER_ADMIN]: {
    [CHART_CONST.PROPERTIES]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
    [CHART_CONST.INVOICES]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}`,
    [CHART_CONST.QUOTES]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.DEFECTS_QUOTES}`,
    [CHART_CONST.WORK_ORDERS]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}`,
    [CHART_CONST.PURCHASE_ORDER]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.PURCHASE_ORDER}`,
    [CHART_CONST.PROPERTY_COMPLIANCE]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
    [CHART_CONST.DEFECTS]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.DEFECTS}`,
    [ROUTE_NAVIGATE_CONST.PROPERTIES]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
    [ROUTE_NAVIGATE_CONST.TEMPLATES]: `/${ROUTENAME.SETTINGS}/${ROUTENAME.TEMPLATES}`,
    [ROUTE_NAVIGATE_CONST.TEMPLATES]: `/${ROUTENAME.SETTINGS}/${ROUTENAME.TEMPLATES}`,
    [ROUTE_NAVIGATE_CONST.REPORTING]: `/${ROUTENAME.REPORTS}/${ROUTENAME.SUPER_ADMIN_REPORTING}`,
    [ROUTE_NAVIGATE_CONST.TECHNICIAN_PERFORMANCE_LIST]: `/${ROUTENAME.REPORTS}/${ROUTENAME.SUPER_ADMIN_REPORTING}/${ROUTENAME.TECHNICIAN_PERFORMANCE}`,
    [ROUTE_NAVIGATE_CONST.TECHNICIAN_PERFORMANCE_CHARTS]: `/${ROUTENAME.REPORTS}/${ROUTENAME.SUPER_ADMIN_REPORTING}/${ROUTENAME.TECHNICIAN_PERFORMANCE}/${ROUTENAME.CHARTS}`,
    [ROUTE_NAVIGATE_CONST.INVOICE_EDIT]: `/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}/${ROUTENAME.INVOICE_DETAIL}`,
    [ROUTE_NAVIGATE_CONST.INVOICE]: `/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_ASSET]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.ASSETS}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_FLOOR_PLAN]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_FLOOR_PLAN}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_ROUTINE]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_ROUTINE}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_CONTRACT]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_CONTRACT}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_SERVICE_QUOTE]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_SERVICE_QUOTE}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_INVOICE]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_INVOICES}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_COMING_SOON]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_COMING_SOON}`,
    [ROUTE_NAVIGATE_CONST.WORK_ORDER_DETAIL]: `/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}/${ROUTENAME.WORK_ORDERS_VIEW}`,
    [ROUTE_NAVIGATE_CONST.INTEGRATION]: `/${ROUTENAME.SETTINGS}/${ROUTENAME.INTEGRATIONS}`,
    [ROUTE_NAVIGATE_CONST.SUPPLIER]: `/${ROUTENAME.MASTER}/${ROUTENAME.SUPPLIERS}`,
    [ROUTE_NAVIGATE_CONST.DASHBOARD]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.DASHBOARD}`,
    [ROUTE_NAVIGATE_CONST.CUSTOMER]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.CUSTOMER}`,
    [ROUTE_NAVIGATE_CONST.CUSTOMER_BILLING]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.BILLING_INFORMATION}`,
    [ROUTE_NAVIGATE_CONST.SERVICE_QUOTES]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.QUOTES_SERVICES}`,
  },
  [GLOBAL_ADMIN]: {
    [CHART_CONST.FIRE_COMPANY]: `/${ROUTENAME.FIRE_COMPANY}/${ROUTENAME.COMPANIES}`,
    [CHART_CONST.USER_MANAGEMENT]: `/${ROUTENAME.USERS_MANAGEMENT}`,
    [CHART_CONST.USERS]: `/${ROUTENAME.USERS_MANAGEMENT}`,
    [CHART_CONST.WORK_ORDERS]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}`,
    [ROUTE_NAVIGATE_CONST.PROPERTIES]: `/${ROUTENAME.PROPERTIES}`,
    [CHART_CONST.CUSTOMER_PROPERTIES]: `/${ROUTENAME.PROPERTIES}`,
    [ROUTE_NAVIGATE_CONST.CONTROL_PANEL]: `/${ROUTENAME.CONTROL_PANEL}`,
    [ROUTE_NAVIGATE_CONST.TEMPLATE]: `/${ROUTENAME.CONTROL_PANEL}/${ROUTENAME.TEMPLATE}`,
    [ROUTE_NAVIGATE_CONST.WORK_ORDER_DETAIL]: `/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}/${ROUTENAME.WORK_ORDERS_VIEW}`,
    [ROUTE_NAVIGATE_CONST.CUSTOMER]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.CUSTOMER}`,
    [ROUTE_NAVIGATE_CONST.CUSTOMER_BILLING]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.BILLING_INFORMATION}`,
  },
  [OFFICE_STAFF]: {
    [CHART_CONST.PROPERTIES]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
    [CHART_CONST.INVOICES]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}`,
    [CHART_CONST.QUOTES]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.DEFECTS_QUOTES}`,
    [CHART_CONST.WORK_ORDERS]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}`,
    [CHART_CONST.PURCHASE_ORDER]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.PURCHASE_ORDER}`,
    [CHART_CONST.PROPERTY_COMPLIANCE]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
    [CHART_CONST.DEFECTS]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.DEFECTS}`,
    [ROUTE_NAVIGATE_CONST.PROPERTIES]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
    [ROUTE_NAVIGATE_CONST.TECHNICIAN_PERFORMANCE_LIST]: `/${ROUTENAME.REPORTS}/${ROUTENAME.SUPER_ADMIN_REPORTING}/${ROUTENAME.TECHNICIAN_PERFORMANCE}`,
    [ROUTE_NAVIGATE_CONST.TECHNICIAN_PERFORMANCE_CHARTS]: `/${ROUTENAME.REPORTS}/${ROUTENAME.SUPER_ADMIN_REPORTING}/${ROUTENAME.TECHNICIAN_PERFORMANCE}/${ROUTENAME.CHARTS}`,
    [ROUTE_NAVIGATE_CONST.INVOICE_EDIT]: `/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}/${ROUTENAME.INVOICE_DETAIL}`,
    [ROUTE_NAVIGATE_CONST.INVOICE]: `/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_FLOOR_PLAN]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_FLOOR_PLAN}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_ROUTINE]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_ROUTINE}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_CONTRACT]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_CONTRACT}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_SERVICE_QUOTE]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_SERVICE_QUOTE}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_INVOICE]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_INVOICES}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_COMING_SOON]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_COMING_SOON}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_ASSET]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.ASSETS}`,
    [ROUTE_NAVIGATE_CONST.WORK_ORDER_DETAIL]: `/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}/${ROUTENAME.WORK_ORDERS_VIEW}`,
    [ROUTE_NAVIGATE_CONST.SUPPLIER]: `/${ROUTENAME.MASTER}/${ROUTENAME.SUPPLIERS}`,
    [ROUTE_NAVIGATE_CONST.DASHBOARD]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.DASHBOARD}`,
    [ROUTE_NAVIGATE_CONST.CUSTOMER]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.CUSTOMER}`,
    [ROUTE_NAVIGATE_CONST.CUSTOMER_BILLING]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.BILLING_INFORMATION}`,
  },
  [OFFICE_MANAGEMENT]: {
    [CHART_CONST.PROPERTIES]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
    [CHART_CONST.INVOICES]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}`,
    [CHART_CONST.QUOTES]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.DEFECTS_QUOTES}`,
    [CHART_CONST.WORK_ORDERS]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}`,
    [CHART_CONST.PURCHASE_ORDER]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.PURCHASE_ORDER}`,
    [CHART_CONST.PROPERTY_COMPLIANCE]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
    [CHART_CONST.DEFECTS]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.COMMERCIAL}/${ROUTENAME.DEFECTS}`,
    [ROUTE_NAVIGATE_CONST.PROPERTIES]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`,
    [ROUTE_NAVIGATE_CONST.REPORTING]: `/${ROUTENAME.REPORTS}/${ROUTENAME.SUPER_ADMIN_REPORTING}`,
    [ROUTE_NAVIGATE_CONST.TECHNICIAN_PERFORMANCE_LIST]: `/${ROUTENAME.REPORTS}/${ROUTENAME.SUPER_ADMIN_REPORTING}/${ROUTENAME.TECHNICIAN_PERFORMANCE}`,
    [ROUTE_NAVIGATE_CONST.TECHNICIAN_PERFORMANCE_CHARTS]: `/${ROUTENAME.REPORTS}/${ROUTENAME.SUPER_ADMIN_REPORTING}/${ROUTENAME.TECHNICIAN_PERFORMANCE}/${ROUTENAME.CHARTS}`,
    [ROUTE_NAVIGATE_CONST.INVOICE_EDIT]: `/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}/${ROUTENAME.INVOICE_DETAIL}`,
    [ROUTE_NAVIGATE_CONST.INVOICE]: `/${ROUTENAME.COMMERCIAL}/${ROUTENAME.INVOICES}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_FLOOR_PLAN]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_FLOOR_PLAN}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_ROUTINE]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_ROUTINE}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_CONTRACT]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_CONTRACT}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_SERVICE_QUOTE]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_SERVICE_QUOTE}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_INVOICE]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_INVOICES}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_COMING_SOON]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_COMING_SOON}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_ASSET]: `/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.ASSETS}`,
    [ROUTE_NAVIGATE_CONST.WORK_ORDER_DETAIL]: `/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}/${ROUTENAME.WORK_ORDERS_VIEW}`,
    [ROUTE_NAVIGATE_CONST.SUPPLIER]: `/${ROUTENAME.MASTER}/${ROUTENAME.SUPPLIERS}`,
    [ROUTE_NAVIGATE_CONST.DASHBOARD]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.DASHBOARD}`,
    [ROUTE_NAVIGATE_CONST.CUSTOMER]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.CUSTOMER}`,
    [ROUTE_NAVIGATE_CONST.CUSTOMER_BILLING]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.BILLING_INFORMATION}`,
  },
  [CUSTOMER]: {
    [ROUTE_NAVIGATE_CONST.PROPERTIES]: `/${ROUTENAME.DASHBOARD}/${ROUTENAME.PROPERTIES}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_ASSET]: `/${ROUTENAME.DASHBOARD}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.ASSETS}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_FLOOR_PLAN]: `/${ROUTENAME.DASHBOARD}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_FLOOR_PLAN}`,
    [ROUTE_NAVIGATE_CONST.PROPERTY_COMING_SOON]: `/${ROUTENAME.DASHBOARD}/${ROUTENAME.PROPERTIES}/${ROUTENAME.PROPERTIES_DETAILS}/${ROUTENAME.SUPER_ADMIN_PROPERTIES_COMING_SOON}`,
    [ROUTE_NAVIGATE_CONST.DASHBOARD]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.DASHBOARD}/${ROUTENAME.PROPERTIES}`,
    [ROUTE_NAVIGATE_CONST.CUSTOMER]: `/${ROUTENAME.DEFAULT_ROUTE_COMPANY}/${ROUTENAME.CUSTOMERS}/${ROUTENAME.CUSTOMER}`,
  },
};

export const EQUIPMENT_ICONS = {
  [FIRE_HOSE_REELS]: FIRE_HOSE_REELS_SVG,
  [FIRE_HYDRANT_SYSTEMS]: FIRE_HYDRANT_SYSTEMS_SVG,
  [AUTOMATIC_FIRE_SPRINKLER_SYSTEMS]: AUTOMATIC_FIRE_SPRINKLER_SYSTEMS_SVG,
  [DELIVERY_LAY_FLAT_HOSE]: DELIVERY_LAY_FLAT_HOSE_SVG,
  [WATERS_STORAGE_TANKS_FOR_FIRE_PROTECTION_SYSTEMS]:
    WATERS_STORAGE_TANKS_FOR_FIRE_PROTECTION_SYSTEMS_SVG,
  [EMERGENCY_PLANNING_IN_FACILITIES]: EMERGENCY_PLANNING_IN_FACILITIES_SVG,
  [FIRE_AND_SMOKE_CONTROL_FEATURES_OF_MECHANICAL_SERVICES]:
    FIRE_AND_SMOKE_CONTROL_FEATURES_OF_MECHANICAL_SERVICES_SVG,
  [FIRE_PUMPSETS]: FIRE_PUMPSETS_SVG,
  [FIRE_BLANKETS]: FIRE_BLANKETS_SVG,
  [PASSIVE_FIRE_AND_SMOKE_SYSTEMS]: PASSIVE_FIRE_AND_SMOKE_SYSTEMS_SVG,
  [PORTABLE_WHEELED_FIRE_EXTINGUISHERS]:
    PORTABLE_WHEELED_FIRE_EXTINGUISHERS_SVG,
  [SPECIAL_HAZARD_SYSTEMS]: SPECIAL_HAZARD_SYSTEMS_SVG,
  [FIRE_DETECTION_AND_ALARM_SYSTEMS]: FIRE_DETECTION_AND_ALARM_SYSTEMS_SVG,
};

export const ASSET_HISTORY = {
  [ASSET_MODIFIED]: {
    icon: <CategoryOutlined />,
    bgColor: DARK_GRAY,
  },
  [DEFECT_RESOLVED]: {
    icon: <WarningAmberOutlinedIcon />,
    bgColor: PRIMARY,
  },
  [DEFECT_RAISED]: {
    icon: <WarningAmberOutlinedIcon />,
    bgColor: DARK_GRAY,
  },
  [ASSET_FAILED]: {
    icon: <CategoryOutlined />,
    bgColor: WARNING,
  },
  [ASSET_PASSED]: {
    icon: <CategoryOutlined />,
    bgColor: PRIMARY,
  },
  [ASSET_ADDED]: {
    icon: <CategoryOutlined />,
    bgColor: DARK_GRAY,
  },
  [ASSET_NO_CHECK]: {
    icon: <CategoryOutlined />,
    bgColor: DARK_GRAY,
  },
};

export const CUSTOMER_TYPE = [
  { label: 'Contract', value: 'contract' },
  { label: 'Non-Contract', value: 'non_contract' },
];

export const TEMPLATE_SET = {
  INVOICE: 'invoice',
  PURCHASE_ORDER: 'po',
  DEFECT_QUOTES: 'quote',
};

export const GST_PERCENTAGE = 10;
export const ASPECT_RATIO = 1;

export const INVOICE_FREQUENCY_NUM = {
  M: 12,
  Q: 4,
  '6M': 2,
  Y: 1,
};

export const STANDARD_COUNTRY = {
  INDIA: 'India',
  AUSTRALIA: 'Australia',
  QATAR: 'Qatar',
  SAUDI_ARABIA: 'Saudi Arabia',
  UAE: 'UAE',
  UK: 'UK',
  USA: 'USA',
};

export const STANDARD_CURRENCY_CODE = {
  [STANDARD_COUNTRY.INDIA]: 'INR',
  [STANDARD_COUNTRY.AUSTRALIA]: 'AUD',
  [STANDARD_COUNTRY.QATAR]: 'QAR',
  [STANDARD_COUNTRY.SAUDI_ARABIA]: 'SAR',
  [STANDARD_COUNTRY.UAE]: 'AED',
  [STANDARD_COUNTRY.UK]: 'GBP',
  [STANDARD_COUNTRY.USA]: 'USD',
};

export const MAX_FILE_SIZE_MB = 20;
