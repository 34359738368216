export const PRIMARY = '#95C020';
export const SECONDARY = '#FFFFFF';
export const TEXT_COLOR = '#1E1E1E';
export const DISABLE = '#cccccc';
export const DISABLED_BACKGROUND = 'rgba(235, 235, 235, 1)';
export const DIVIDER_COLOR = '#EBEBEB';
export const LIGHT_GRAY = '#EBEBEB33';
export const WARNING = '#C54036';
export const LIGHT_WARNING = '#C5403633';
export const WARNING_HOVER = '#aa3931';
export const NODATA_ICON_COLOR = '#0000008F';
export const INVITE_PENDING = '#FD7E14';
export const DRAFT = '#00000066';
export const YELLOW = '#F7B84B';
export const LAVENDER = '#A85BBB';
export const RED = '#F06548';
export const LIGHT_RED = '#d32f2f';
export const SKY = '#299CDB';
export const LIGHT_SKY = '#2196F333';
export const DARK_SKY = '#2196F3';
export const GOOGLE_MAP_MARKER_COLOR = ['95C020', '#59C3C3'];
export const ROW_HIGHLIGHTER_COLOR = '#95C02033';
export const DATA_AMANAGEMENT_ICON_COLOR = '#00000080';
export const ICON_DEFAULT_COLOR = '#707070';
export const RADIO_GREY_COLOR = 'rgba(118, 118, 118, 1)';
export const TABLE_BG_COLOR = '#e0e0e0';
export const DESCRIPTION_COLOR = '#5d5d5d';
export const TEXT_GRAY = '#00000099';
export const DARK_GRAY = '#454545';
export const TEAL_BLUE = '#5D949F';
export const BOX_GRAY = 'rgba(235, 235, 235, 0.4)';
export const PDF_HEADER_GREY = 'rgba(30, 30, 30, 0.4)';
export const PRIMARY_LIGHT = 'rgba(149, 192, 32, 0.2)';
export const LIGHT_GREEN = '#e1efbd';
export const LIGHT_GREEN_SHADOW = '#c8db9c';
export const GREEN_SHADOW = '#7CA316';

export const STATUS_COLORS = {
  PENDING: YELLOW,
  APPROVED: PRIMARY,
  IN_PROGRESS: '#59C3C3',
  REJECTED: WARNING,
  PAID: PRIMARY,
  DIFFERED: '#FFA500',
  OVERDUE: WARNING,
};
export const DARK_BLUE = '#405189';
export const TURQUOISE = '#59C3C3';
export const DARK_GREEN = '#395B50';
export const GREEN = '#41A857';
export const ORANGE_YELLOW = '#F0CC1C';
export const PURPLE = '#59366B';
export const SCROLLBAR_TRACK_BACKGROUND = '#ededed';
export const SCROLLBAR_TRACK_BOXSHADOW = 'inset 0 0 5px rgba(0, 0, 0, 0.4)';
export const SCROLLBAR_THUMB_BACKGROUND = '#999999';
