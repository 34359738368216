import { createSlice } from '@reduxjs/toolkit';

import {
  accountPartnerHealthCheck,
  getLocation,
  getMe,
  getWidgetDataByUser,
  getWidgetDataRole,
  updateCharts,
  uploadDocument,
} from './CommonAPI';

const initialWidgetData = {
  isLoading: false,
  error: null,
  data: [],
  userChartData: [],
};

const initialAccountHealthState = {
  stopFetching: false,
  data: false,
  error: false,
  isLoading: false,
};

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    snackbarOpen: false,
    snackbarMessage: '',
    isErrorMsg: false,
    stateCityList: {},
    isUnAuthorised: false,
    isAuthLoading: false,
    isDocumentLoading: false,
    uploadedSuccessfully: false,
    uploadError: null,
    profileDetails: null,
    silentTokenSuccess: {
      sessionExpired: false,
      isSsoSuccess: false,
    },
    widget: {
      ...initialWidgetData,
    },
    isTopBarLoader: false,
    accountConfigHealthCheck: { ...initialAccountHealthState },
  },
  reducers: {
    snackbarToggle: (state, action) => {
      state.snackbarOpen = action.payload.isOpen;
      state.snackbarMessage = action.payload.msg;
      state.isErrorMsg = action.payload.isErrorMsg;
    },
    isSsoSilentTokenAquired: (state, action) => {
      state.silentTokenSuccess = {
        sessionExpired: action.payload.sessionExpired,
        isSsoSuccess: action.payload.silentTokenSuccess,
      };
    },
    resetDocumentData(state) {
      state.documentData = null;
      state.isDocumentLoading = false;
      state.uploadedSuccessfully = false;
      state.uploadError = null;
    },
    setIsTopBarLoader(state, action) {
      state.isTopBarLoader = action.payload;
    },
    resetAccountConfigHealthCheck(state, action) {
      state.accountConfigHealthCheck = {
        ...initialAccountHealthState,
        stopFetching: action.payload?.stopFetching,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocation.fulfilled, (state, action) => {
        const stateCityList = action.payload.data;

        state.stateCityList = stateCityList[0];
      })
      .addCase(getMe.pending, (state) => {
        state.isAuthLoading = true;
        state.isUnAuthorised = false;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        const data = action?.payload;

        if (data?.portals?.some((item) => item.field_value === 'web')) {
          state.profileDetails = data;
          state.isAuthLoading = false;
          state.isAuthSuccess = true;
          state.silentTokenSuccess = {
            sessionExpired: false,
            isSsoSuccess: true,
          };
        } else {
          state.isAuthSuccess = false;
          state.isAuthLoading = false;
          state.snackbarOpen = true;
          state.snackbarMessage =
            'Sorry, you are not allowed to login to web portal, Please login to mobile app.';
          state.isErrorMsg = true;
          state.isUnAuthorised = true;
          state.silentTokenSuccess = {
            sessionExpired: false,
            isSsoSuccess: true,
          };
        }
      })
      .addCase(getMe.rejected, (state, action) => {
        state.isAuthSuccess = false;
        state.isAuthLoading = false;
        state.snackbarOpen = true;
        state.snackbarMessage = action?.error?.message;
        state.isErrorMsg = true;
        state.isUnAuthorised = true;
        state.silentTokenSuccess = {
          sessionExpired: true,
          isSsoSuccess: false,
        };
      })
      .addCase(uploadDocument.pending, (state) => {
        state.isDocumentLoading = true;
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        state.isDocumentLoading = false;
        state.uploadedSuccessfully = true;
        state.documentData = action.payload?.data[0];
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        state.uploadError = action.payload;
      })
      // GetWidget
      .addCase(getWidgetDataRole.pending, (state) => {
        state.widget.isLoading = true;
      })
      .addCase(getWidgetDataRole.fulfilled, (state, action) => {
        state.widget.isLoading = false;
        state.widget.data = action.payload.data;
      })
      .addCase(getWidgetDataRole.rejected, (state, action) => {
        state.widget.isLoading = false;
        state.widget.error = action.payload;
      })
      // GetWidget By User
      .addCase(getWidgetDataByUser.pending, (state) => {
        state.widget.isLoading = true;
      })
      .addCase(getWidgetDataByUser.fulfilled, (state, action) => {
        state.widget.isLoading = false;
        state.widget.userChartData = action.payload.data;
      })
      .addCase(getWidgetDataByUser.rejected, (state, action) => {
        state.widget.isLoading = false;
        state.widget.error = action.payload;
        state.widget.userChartData = [];
      })
      // UPDATE Widget
      .addCase(updateCharts.pending, (state) => {
        state.widget.isLoading = true;
      })
      .addCase(updateCharts.fulfilled, (state, action) => {
        state.widget.isLoading = false;
        state.widget.data = action.payload.data;
      })
      .addCase(updateCharts.rejected, (state, action) => {
        state.widget.isLoading = false;
        state.widget.error = action.payload;
      })
      .addCase(accountPartnerHealthCheck.pending, (state) => {
        state.accountConfigHealthCheck.isLoading = true;
      })
      .addCase(accountPartnerHealthCheck.fulfilled, (state, action) => {
        state.accountConfigHealthCheck.isLoading = false;
        state.accountConfigHealthCheck.data = action.payload.data;
        state.accountConfigHealthCheck.error = null;
      })
      .addCase(accountPartnerHealthCheck.rejected, (state, action) => {
        state.accountConfigHealthCheck.isLoading = false;
        state.accountConfigHealthCheck.error = action.payload;
      });
  },
});

export default commonSlice.reducer;
export const {
  snackbarToggle,
  resetDocumentData,
  isSsoSilentTokenAquired,
  setIsTopBarLoader,
  resetAccountConfigHealthCheck,
} = commonSlice.actions; // Export the action
