import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import EastOutlinedIcon from '@mui/icons-material/EastOutlined';

import {
  AccessProcedureLogo,
  AccessScheduleLogo,
  AssetVariantLogo,
  BuildingTypeLogo,
  CityLogo,
  SiteRequirementLogo,
  TaxImage,
  ZoneLogo,
} from '../../assets/images';
import { DIVIDER_COLOR } from '../../constants/Colors';
import { SUPER_ADMIN } from '../../constants/Roles';
import { ROUTENAME } from '../../constants/RoutesConstants';
import { getUserRole } from '../../hoc/Permission';
import Cards from '../CommonComponents/CustomCards';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';

const Configuration = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const cardData = {
    [SUPER_ADMIN]: [
      {
        onclick: () => {
          navigate(ROUTENAME.ZONE_CONFIGURATION);
        },
        rightIcon: (
          <img src={ZoneLogo} alt="Zone Logo" height="35px" width="35px" />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.configuration.zone.title'),
        description: t('attributes.configuration.zone.description'),
        inlineIconTitle: true,
      },
      {
        onclick: () => {
          navigate(ROUTENAME.TAX_CONFIGURATION);
        },
        rightIcon: (
          <img src={TaxImage} alt="Zone Logo" height="35px" width="35px" />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.configuration.tax.title'),
        description: t('attributes.configuration.tax.description'),
        inlineIconTitle: true,
      },
      {
        onclick: () => {
          navigate(ROUTENAME.BUILDING_TYPE_CONFIGURATION);
        },
        rightIcon: (
          <img
            src={BuildingTypeLogo}
            alt="Building Type"
            height="35px"
            width="35px"
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.configuration.buildingType.title'),
        description: t('attributes.configuration.buildingType.description'),
        inlineIconTitle: true,
      },
      {
        onclick: () => {
          navigate(ROUTENAME.CITY_CONFIGURATION);
        },
        rightIcon: <img src={CityLogo} alt="City" height="35px" width="35px" />,
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.configuration.city.title'),
        description: t('attributes.configuration.city.description'),
        inlineIconTitle: true,
      },
      {
        onclick: () => {
          navigate(ROUTENAME.ASSET_VARIANT_CONFIGURATION);
        },
        rightIcon: (
          <img
            src={AssetVariantLogo}
            alt="AssetVariant"
            height="35px"
            width="35px"
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.configuration.assetVariant.title'),
        description: t('attributes.configuration.assetVariant.description'),
        inlineIconTitle: true,
      },
      {
        onclick: () => {
          navigate(ROUTENAME.SITE_REQUIREMENT_CONFIGURATION);
        },
        rightIcon: (
          <img
            src={SiteRequirementLogo}
            alt="SiteRequirement"
            height="35px"
            width="35px"
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.configuration.siteRequirement.title'),
        description: t('attributes.configuration.siteRequirement.description'),
        inlineIconTitle: true,
      },
      {
        onclick: () => {
          navigate(ROUTENAME.ACCESS_SCHEDULE_CONFIGURATION);
        },
        rightIcon: (
          <img
            src={AccessScheduleLogo}
            alt="AccessSchedule"
            height="35px"
            width="35px"
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.configuration.accessSchedule.title'),
        description: t('attributes.configuration.accessSchedule.description'),
        inlineIconTitle: true,
      },
      {
        onclick: () => {
          navigate(ROUTENAME.ACCESS_PROCEDURE_CONFIGURATION);
        },
        rightIcon: (
          <img
            src={AccessProcedureLogo}
            alt="AccessProcedure"
            height="35px"
            width="35px"
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.configuration.accessProcedure.title'),
        description: t('attributes.configuration.accessProcedure.description'),
        inlineIconTitle: true,
      },
    ],
  };

  const DataManagementqWrapper = (
    <StyledMainWrapper
      title={t('attributes.configuration.title')}
      style={{ height: '100%', backgroundColor: DIVIDER_COLOR }}
    >
      <Cards cardData={cardData} role={getUserRole()} />
    </StyledMainWrapper>
  );

  return <>{DataManagementqWrapper}</>;
};

export default Configuration;
