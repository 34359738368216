import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Divider, Stack, styled, Typography } from '@mui/material';

import { DIVIDER_COLOR, SECONDARY, WARNING } from '../../constants/Colors';
import {
  DBC365,
  INTEGRATION_TYPES,
  ROUTE_NAVIGATE_CONST,
} from '../../constants/Constants';
import { Validation } from '../../constants/FieldValidationMsg';
import useNavigationBlocker from '../../hooks/useNavigationBlocker';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../store/CommonReducer';
import {
  addAccountPartnerConfiguration,
  getAccountPartnerConfiguration,
  verifyAccountPartnerConfiguration,
} from '../../store/integrations/api';
import {
  resetAddAccountPartnerConfiguration,
  resetGetAccountPartnerConfiguration,
  resetVerifyAccountPartnerConfiguration,
} from '../../store/integrations/reducer';
import {
  getRedirectURL,
  loggedInUserRole,
  showHideTopbarLoader,
} from '../../utils';
import CustomButton from '../CommonComponents/CustomButton';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';
import MainWrapper from '../CommonComponents/MainWrapper';
import { ConfirmationModal } from '../CommonComponents/Modal';

const StyledOrderedList = styled('ol')({
  listStyleType: 'decimal',
  paddingLeft: '16px',
  margin: 0,
});

const StyledListItem = styled('li')({
  marginBottom: '8px',
  fontWeight: 'normal',
});

const BusinessCentralIntegration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userRole = loggedInUserRole();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    clearErrors,
    setError,
  } = useForm();
  const { clientId, tenantId, clientSecret, account_partner_company_uuid } =
    watch();

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [unlinkFormData, setUnlinkFormData] = useState(null);
  const [serverErrors, setServerErrors] = useState([]);
  const { isLoading, data, error } = useSelector(
    (state) => state.accountConfiguration.add
  );

  const {
    isLoading: verifyLoading,
    data: verifyData,
    error: verifyError,
  } = useSelector((state) => state.accountConfiguration.verify);

  const { isLoading: getConfigurationDataLoading, data: getConfigurationData } =
    useSelector((state) => state.accountConfiguration.get);

  useEffect(() => {
    if (!getConfigurationDataLoading) {
      reset({
        clientId: getConfigurationData?.client_id,
        clientSecret: 'Hidden client secrent ...................',
        tenantId: getConfigurationData?.tenant_id,
        account_partner_company_uuid:
          getConfigurationData?.account_partner_company_uuid,
      });
    }

    if (!getConfigurationData) {
      reset({
        clientId: '',
        clientSecret: '',
        tenantId: '',
        account_partner_company_uuid: '',
      });
    }
  }, [getConfigurationData]);

  const [hasFormValues, setHasFormValues] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const formValues = watch();

  useEffect(() => {
    setHasFormValues(Object.values(formValues).some((value) => value));
  }, [formValues]);

  const { proceedNavigation } = useNavigationBlocker({
    hasFormValues,
    setConfirmationModalOpen,
  });

  useEffect(() => {
    dispatch(getAccountPartnerConfiguration({ account_partner_type: DBC365 }));

    return () => {
      dispatch(resetAddAccountPartnerConfiguration());
      dispatch(resetGetAccountPartnerConfiguration());
      dispatch(resetVerifyAccountPartnerConfiguration());
    };
  }, []);

  // Use the custom hook
  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  useEffect(() => {
    showHideTopbarLoader(isLoading, dispatch);

    if (!isLoading) {
      if (error) {
        if (error?.errorDetails?.[0]?.field) {
          setServerErrors(error.errorDetails);
        } else {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: error.message,
            })
          );
        }
      } else {
        if (data) {
          dispatch(
            getAccountPartnerConfiguration({ account_partner_type: DBC365 })
          );
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: data.message,
            })
          );
        }
      }
    }
  }, [isLoading, data, error]);

  useEffect(() => {
    showHideTopbarLoader(verifyLoading, dispatch);

    if (!verifyLoading) {
      if (verifyError) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: verifyError.message,
          })
        );
      }
      if (verifyData) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: verifyData.message,
          })
        );
      }
    }
  }, [verifyLoading, verifyData]);

  const onSubmit = (data) => (type, isActive) => {
    const requestData = {
      account_partner_type: DBC365,
      tenant_id: data.tenantId,
      account_partner_company_uuid: data?.account_partner_company_uuid,
      client_id: data.clientId,
      secret: data?.clientSecret?.includes('Hidden') ? null : data.clientSecret,
      is_active: isActive,
    };

    dispatch(resetAddAccountPartnerConfiguration());
    if (type === INTEGRATION_TYPES.VERIFY) {
      dispatch(verifyAccountPartnerConfiguration(requestData));
    } else {
      requestData.webhook_key = '';
      (requestData.webhook_key =
        data?.webhookKey == null || data?.webhookKey?.includes('Hidden')
          ? ''
          : data?.webhookKey),
        dispatch(addAccountPartnerConfiguration(requestData));
    }
  };

  const isUnChanged = () => {
    const isChanged =
      getConfigurationData?.client_id !== clientId ||
      getConfigurationData?.tenant_id !== tenantId ||
      getConfigurationData?.account_partner_company_uuid !==
        account_partner_company_uuid ||
      !clientSecret?.includes('Hidden');

    return isChanged;
  };

  const handleDefaultStep = () => {
    navigate(getRedirectURL()[userRole][ROUTE_NAVIGATE_CONST.INTEGRATION]);
  };

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('attributes.integrations.unlinkTitle')}
          description={t(
            'attributes.integrations.unlinkBusinessCentralDescription'
          )}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            onSubmit(unlinkFormData)(
              getConfigurationData || verifyData
                ? INTEGRATION_TYPES.SAVE
                : INTEGRATION_TYPES.VERIFY,
              false
            );
            setOpenConfirmationModal(false);
          }}
        />
      )}
      {confirmationModalOpen && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={confirmationModalOpen}
          setOpen={setConfirmationModalOpen}
          onConfirm={() => {
            setConfirmationModalOpen(false);
            setHasFormValues(false); // Reset form change detection
            proceedNavigation(); // Allow navigation
          }}
          onCancel={() => {
            setConfirmationModalOpen(false);
          }}
        />
      )}
      <Box sx={{ backgroundColor: DIVIDER_COLOR }}>
        <MainWrapper
          defaultPadding="0px 0px 16px 0px"
          title={t('attributes.integrations.integrations')}
          variant="body1"
          isStep={true}
          step={t('attributes.integrations.bci')}
          handleDefaultStep={handleDefaultStep}
        />
      </Box>
      <Box
        sx={{ backgroundColor: DIVIDER_COLOR, height: 'calc(100vh - 124px)' }}
      >
        {getConfigurationDataLoading ? (
          <Box
            sx={{
              backgroundColor: SECONDARY,
              borderRadius: '6px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                mt: 2,
                minHeight: `calc(100vh - 140px)`,
                maxHeight: `calc(100vh - 140px)`,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CustomCircularLoader />
            </Box>
          </Box>
        ) : (
          <Box sx={{ backgroundColor: SECONDARY, borderRadius: '6px' }}>
            {/* Client Details */}
            <Box sx={{ padding: '16px' }}>
              <Typography variant="body1" sx={{ paddingBottom: '8px' }}>
                {t('attributes.integrations.bciClientTitle')}
              </Typography>
              <StyledOrderedList>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint1')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint2')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint3')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint4')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint5')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint6')}
                  </Typography>
                </StyledListItem>
              </StyledOrderedList>
              <Stack spacing={2} direction="row" sx={{ paddingTop: '16px' }}>
                <Controller
                  name="clientId"
                  control={control}
                  rules={{
                    required: `${t('attributes.integrations.clientId')} ${Validation.general.required}`,
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.integrations.clientId')}
                      sx={{ width: '362px' }}
                      value={value}
                      onChange={(e) => onChange(e)}
                      helperText={error ? error.message : ''}
                      error={!!error}
                      onClear={() => setValue(name, '')}
                      isRequired={true}
                    />
                  )}
                />

                <Controller
                  name="tenantId"
                  control={control}
                  rules={{
                    required: `${t('attributes.integrations.tenantId')} ${Validation.general.required}`,
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.integrations.tenantId')}
                      sx={{ width: '362px' }}
                      value={value}
                      onChange={(e) => onChange(e)}
                      helperText={error ? error.message : ''}
                      error={!!error}
                      onClear={() => setValue(name, '')}
                      isRequired={true}
                    />
                  )}
                />
              </Stack>
            </Box>
            <Divider />
            {/* Tenant Details */}
            <Box sx={{ padding: '16px' }}>
              <Typography variant="body1" sx={{ paddingBottom: '8px' }}>
                {t('attributes.integrations.bciClientSecret')}
              </Typography>
              <StyledOrderedList start={7}>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint7')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint8')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint9')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint10')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint11')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint12')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint13')}
                  </Typography>
                </StyledListItem>
              </StyledOrderedList>
              <Box sx={{ paddingTop: '16px' }}>
                <Controller
                  name="clientSecret"
                  control={control}
                  rules={{
                    required: `${t('attributes.integrations.clientSecret')} ${Validation.general.required}`,
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.integrations.clientSecret')}
                      sx={{ width: '362px' }}
                      type={getConfigurationData ? 'password' : null}
                      value={value}
                      onChange={(e) => onChange(e)}
                      helperText={error ? error.message : ''}
                      error={!!error}
                      onClear={() => setValue(name, '')}
                      isRequired={true}
                    />
                  )}
                />
              </Box>
            </Box>
            <Divider />
            {/* Company Id */}
            <Box sx={{ padding: '16px' }}>
              <Typography variant="body1" sx={{ paddingBottom: '8px' }}>
                {t('attributes.integrations.bciCompany')}
              </Typography>
              <StyledOrderedList start={14}>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint14')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint15')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint16')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint17')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.bciPoint18')}
                  </Typography>
                </StyledListItem>
              </StyledOrderedList>
              <Box sx={{ paddingTop: '16px' }}>
                <Controller
                  name="account_partner_company_uuid"
                  control={control}
                  rules={{
                    required: `${t('attributes.integrations.companyId')} ${Validation.general.required}`,
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.integrations.companyId')}
                      sx={{ width: '362px' }}
                      type="text"
                      value={value}
                      onChange={(e) => onChange(e)}
                      helperText={error ? error.message : ''}
                      error={!!error}
                      onClear={() => setValue(name, '')}
                      isRequired={true}
                    />
                  )}
                />
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                padding: '16px',
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <ErrorOutlineOutlinedIcon
                sx={{ height: '16px', width: '16px' }}
              />
              <Typography variant="body1">
                {t('attributes.integrations.bciBottomNote')}
              </Typography>
            </Box>
            <Divider />
            <Stack direction="row" justifyContent="end" padding="16px" gap={2}>
              {getConfigurationData?.is_active && (
                <CustomButton
                  text={t('attributes.integrations.inactive')}
                  startIcon={<LinkOffIcon />}
                  disabled={isLoading}
                  sx={{
                    height: '52%',
                    backgroundColor: WARNING,
                    '&:hover': {
                      backgroundColor: WARNING,
                      boxShadow: 'none',
                    },
                  }}
                  onClick={handleSubmit((data) => {
                    setUnlinkFormData(data);
                    setOpenConfirmationModal(true);
                  })}
                />
              )}
              <CustomButton
                text={
                  getConfigurationData || verifyData
                    ? t('attributes.save')
                    : t('attributes.integrations.verify')
                }
                color="primary"
                sx={{ height: '52%' }}
                startIcon={<SaveIcon />}
                onClick={handleSubmit((data) => {
                  onSubmit(data)(
                    getConfigurationData || verifyData
                      ? INTEGRATION_TYPES.SAVE
                      : INTEGRATION_TYPES.VERIFY,
                    true
                  );
                })}
                disabled={isLoading || verifyLoading || !isUnChanged()}
              />
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
};

export default BusinessCentralIntegration;
