import { Box, styled, Typography } from '@mui/material';

// Internal
import Autocomplete from '../CommonComponents/AutoComplete';
import Search from '../CommonComponents/Search';

const FilterComponentStyled = styled(Box)(({ theme }) => ({
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const FilterSectionStyled = styled(Box)(({ theme, sx }) => ({
  display: 'flex',
  columnGap: '16px',
  justifyContent: 'space-between',
  alignItems: 'center',
  ...sx,
}));

export const FilterComponent = ({ children }) => (
  <FilterComponentStyled>{children}</FilterComponentStyled>
);

export const WorkOrderFilterComponent = ({
  searchText = '',
  onSearchChange = () => {},
  sx = {},
  filters = {},
  setFilters = () => {},
  zonesDropdownLoading = true,
  zonesDropdownData = [],
}) => (
  <FilterComponentStyled>
    <FilterSectionStyled sx={sx}>
      <Typography variant="body1">Work Orders</Typography>
      <Box sx={{ display: 'flex', columnGap: '16px', alignItems: 'center' }}>
        {/* <Autocomplete
          placeholder="Status"
          options={[]}
          onChange={(e, newVal) =>
            setFilters((prev) => ({ ...prev, property: newVal }))
          }
          isLoadingData={false}
          value={filters?.property}
          width="190px"
          height={'26px'}
        /> */}
        <Autocomplete
          placeholder="Zone"
          options={zonesDropdownData}
          onChange={(e, newVal) => {
            if (newVal || newVal === null) {
              setFilters((prev) => ({ ...prev, propertyZone: newVal }));
            }
          }}
          value={filters?.propertyZone}
          isLoadingData={zonesDropdownLoading}
          sx={{
            '& .MuiOutlinedInput-root': {
              height: '26px',
            },
          }}
        />
        <Search value={searchText} onChange={onSearchChange} />
      </Box>
    </FilterSectionStyled>
  </FilterComponentStyled>
);
