import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  Card,
  Divider,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from '@mui/material';
// Internal
import { isEmpty } from 'lodash';

import { theme } from '../../assets/theme';
import {
  SCROLLBAR_THUMB_BACKGROUND,
  SCROLLBAR_TRACK_BACKGROUND,
  SCROLLBAR_TRACK_BOXSHADOW,
  SECONDARY,
  WARNING,
  WARNING_HOVER,
} from '../../constants/Colors';
import {
  previewDocument,
  validateAddress,
  validImageTypes,
} from '../../constants/common';
import {
  DRAFT_STATUS,
  FILE_UPLOAD_SIZES,
  INVITATION_PENDING,
  INVITED_STATUS,
} from '../../constants/Constants';
import { Validation } from '../../constants/FieldValidationMsg';
import { EMAIL_REGEX } from '../../constants/Regex';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getStatesHook from '../../hooks/getStatesHook';
import useAddressDetail from '../../hooks/useAddressDetail';
import useAutocompleteAddress from '../../hooks/useAutcompleteAddress';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { uploadDocument } from '../../store/CommonAPI';
import { resetDocumentData, snackbarToggle } from '../../store/CommonReducer';
import {
  createCompany,
  editCompany,
  updateCompany,
} from '../../store/company/api';
import { resetCreate, resetEdit } from '../../store/company/reducer';
import {
  resetAddressDetail,
  resetAutocompleteAddress,
} from '../../store/google/reducer';
import {
  createSuperAdmin,
  getSuperAdminList,
  updateSuperAdmin,
} from '../../store/superAdmin/api';
import { resetCreate as resetAdminCreate } from '../../store/superAdmin/reducer';
import { formattedDate, showHideTopbarLoader } from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import ImageCropper from '../CommonComponents/CropImage/Modal';
import CustomButton from '../CommonComponents/CustomButton';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomFileUploadButton from '../CommonComponents/CustomFileUploadButton';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';
import { ConfirmationModal } from '../CommonComponents/Modal';
import UploadedDocumentView from '../CommonComponents/UploadedDocumentView';
import InviteTheFireCompany from './InviteCompany';
import SuperAdminForm from './SuperAdminForm';
import UploadLogo from './UploadLogo';

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const FormWrapper = styled(Box)(() => ({
  maxHeight: '84vh',
  overflowX: 'hidden',
  overflowY: 'auto',
  width: '100%',
  // scrollbarWidth: 'none',
  /* Scoped scrollbar styles for WebKit browsers */
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: SCROLLBAR_TRACK_BACKGROUND, // Fixed: Proper string format
    boxShadow: SCROLLBAR_TRACK_BOXSHADOW,
    borderRadius: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: SCROLLBAR_THUMB_BACKGROUND,
    borderRadius: '4px',
    width: '2px',
  },
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomCard = styled(Card)(() => ({
  boxShadow: 'none',
  overflow: 'initial',
}));

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: ownerState.active ? theme.palette.primary.main : 'white',
  color: ownerState.active ? 'white' : 'black',
  zIndex: 1,
  width: 26,
  height: 26,
  border: ownerState.active ? '' : '1px solid black',
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.completed && {
    backgroundColor: 'black',
    color: 'white',
  }),
}));

const LayoutContainer = styled(Box)(({ id }) => ({
  display: 'grid',
  gridTemplateColumns: id ? '1fr' : '1fr 2fr',
  height: '100%',
}));

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderColor: theme.palette.grey,
    borderWidth: 2,
    borderLeftStyle: 'dotted',
  },
}));

const stepIcons = [
  <PortraitOutlinedIcon key="icon-01" sx={{ height: '15px', width: '15px' }} />,
  <ContactPageOutlinedIcon
    key="icon-02"
    sx={{ height: '15px', width: '15px' }}
  />,
  <ForwardToInboxOutlinedIcon
    key="icon-03"
    sx={{ height: '15px', width: '15px' }}
  />,
];

const CompanyForm = ({ id, setServerError }) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    trigger,
    reset,
    watch,
    setValue,
    clearErrors,
    setError = () => ({}),
    setServerErrors,
  } = useFormContext();

  const { isLoading, company } = useSelector((state) => state.company.edit);
  const { isLoading: createLoading, createdCompany } = useSelector(
    (state) => state.company.create
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const [openCropper, setOpenCropper] = useState(false);

  function base64ToBlob(base64Data, contentType) {
    // Check if the string contains the data URL prefix and strip it
    const base64String = base64Data.split(',')[1]; // Removes 'data:image/png;base64,'

    try {
      const byteCharacters = atob(base64String);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    } catch (e) {
      console.error('Invalid base64 string:', e);

      return null;
    }
  }
  const updateAvatar = (imgSRC) => {
    // Convert Base64 to Blob
    const blob = base64ToBlob(imgSRC, 'image/png');

    if (!blob) return;

    const file = new File([blob], `logo_${Date.now()}.png`, {
      type: 'image/png',
    });

    setValue('logo_url', imgSRC); // Update the logo preview

    dispatch(
      uploadDocument({
        document_type: 'company_logo_document_uuid',
        uploaded_by: 1,
        file,
      })
    ).then((res) => {
      setValue('company_logo_document_uuid', res?.payload?.data?.[0]?.uuid, {
        shouldValidate: true,
      });
    });

    setOpenCropper(false); // Close the modal
  };

  const { isLoading: createSuperAdminLoading, createdSuperAdmin } = useSelector(
    (state) => state.superAdmin.create
  );

  const selectedState = watch('state');

  const stateList = getStatesHook();
  const cityList = getCityByStateHook(selectedState?.value);

  const { t } = useTranslation();
  const {
    isLoading: isAutocompleteAddressLoading,
    data: autocompleteAddressData,
  } = useSelector((state) => state.googleAPIs.autocompleteAddress);

  const { isDocumentLoading, uploadedSuccessfully, documentData } = useSelector(
    (state) => state.common
  );
  const [uploadedDocuments, setUploadedDocuments] = useState({
    name: '',
    size: '',
  });

  const typedValue = watch('searchAddress');

  const [searchAddress, setSearchAddress] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [isNextReady, setIsNextReady] = useState(true);
  const [addressBlur, setAddressBlur] = useState(false);
  const [address2Blur, setAddress2Blur] = useState(false);
  const [stateBlur, setStateBlur] = useState(false);
  const [cityBlur, setCityBlur] = useState(false);
  const [postcodeBlur, setPostcodeBlur] = useState(false);
  const [documentError, setdocumentError] = useState('');
  const [isUploadingOther, setIsUploadingOther] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [addressRecheck, setAddressRecheck] = useState(false);
  const [newCompanyId, setNewCompanyId] = useState(null);
  const [superAdminDetails, setSuperAdminDetails] = useState();
  const [newSuperAdminId, setNewSuperAdminId] = useState(null);

  useAutocompleteAddress(searchAddress);
  useAddressDetail({
    selectedAddress: typedValue,
    setValue: setValue,
    fieldName: {
      stateKey: 'state',
      cityKey: 'city',
      postCodeKey: 'zip_code',
      address1: 'address',
      address2: 'address_line_2',
    },
  });

  const labels = (label) => t(`label.globalAdmin.${label}`);

  useEffect(() => {
    if (id) {
      dispatch(editCompany(id));
      dispatch(
        getSuperAdminList({
          company_uuid: id,
          order: 'asc',
          orderBy: 'created_at',
        })
      ).then((res) => setSuperAdminDetails(res?.payload?.data?.[0]));
    }

    return () => {
      dispatch(resetDocumentData());
      dispatch(resetAutocompleteAddress());
      dispatch(resetAddressDetail());
      dispatch(resetEdit());
      dispatch(resetCreate());
      dispatch(resetAdminCreate());
      showHideTopbarLoader(false, dispatch);
    };
  }, []);

  useEffect(() => {
    if (!isLoading && company && id) {
      reset({
        uuid: company.uuid,
        company_name: company.company_name,
        abn: company.abn,
        address: company.address,
        address_line_2: company.address_line_2 || '',
        contact_name: company.contact_name,
        email: company.email,
        cell_phone: company.cell_phone,
        city: { label: company?.city || '', value: company.city || '' },
        state: { label: company.state, value: company.state },
        active: company.active,
        logo_url: company?.logo_url,
        company_logo: company?.logo_url,
        company_logo_document_uuid: company?.company_logo_document_uuid,
        agreementDocument: company.company_agreement_document_uuid,
        created_at: formattedDate(company.created_at),
        zip_code: company.zip_code,
        // agreementDocument:
        //   company?.documents?.length > 0 ? company.documents[0] : {},
        ...(superAdminDetails && {
          superadmin_uuid: superAdminDetails?.uuid,
          name: superAdminDetails?.name,
          superadmin_cell_phone: superAdminDetails?.cell_phone,
          superadmin_email: superAdminDetails?.email,
          superadmin_address: superAdminDetails?.address,
          supaeradmin_address_line_2: superAdminDetails?.address_line_2,
          superadmin_state: {
            label: superAdminDetails?.state,
            value: superAdminDetails?.state,
          },
          superadmin_city: {
            label: superAdminDetails?.city || '',
            value: superAdminDetails?.city || '',
          },
          superadmin_zip_code: superAdminDetails?.zip_code,
          superadmin_active: superAdminDetails?.active,
        }),
      });
    }
  }, [isLoading, company, superAdminDetails]);

  const clearHandler = (name) => setValue(name, '');

  const postCodeLength = 4;

  const companyAddress = watch('address');
  const companyState = watch('state');
  const companyCity = watch('city');
  const zipcode = watch('zip_code');
  const addressLine2 = watch('address_line_2');

  useEffect(() => {
    if (
      companyAddress &&
      companyState &&
      (companyCity || true) &&
      zipcode &&
      (addressLine2 || true)
    ) {
      setAddressBlur(true);
      setStateBlur(true);
      setCityBlur(true);
      setPostcodeBlur(true);
      setAddressRecheck(true);
      setAddress2Blur(true);
      trigger('address');
      trigger('state');
      trigger('city');
      trigger('zip_code');
      trigger('address_line_2');
    }
  }, [companyAddress, companyState, companyCity, zipcode, addressLine2]);

  useEffect(() => {
    validateAddress({
      address1: companyAddress,
      address2: addressLine2,
      city: companyCity,
      state: companyState,
      postcode: zipcode,
      clearErrors,
      addressRecheck,
      fieldName: {
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'zip_code',
        postCodeValue: t('attributes.postCode'),
        address1Key: 'address',
        address1Value: t('attributes.addressLine1'),
        address2Key: 'address_line_2',
        address2Value: t('attributes.addressLine2'),
      },
      setServerErrors,
    });
  }, [addressBlur, address2Blur, stateBlur, cityBlur, postcodeBlur]);

  useEffect(() => {
    if (!isDocumentLoading && uploadedSuccessfully) {
      setIsUploadingOther(false);
      const documentType = documentData?.document_type;
      const documentDetails = {
        document_uuid: documentData?.uuid,
        document_type: documentType,
        document_name: documentData?.document_name,
        document_size: uploadedDocuments?.size,
      };

      if (documentType === 'agreement') {
        setValue('agreementDocument', documentDetails);
      } else {
        setValue(documentType, documentDetails);
      }

      setAddressRecheck(false);
    }
  }, [isDocumentLoading, uploadedSuccessfully]);

  const steps = [
    t('attributes.company.company_details'),
    t('attributes.company.super_admin_details'),
    ...(company?.status === INVITED_STATUS
      ? []
      : [t('attributes.company.invite_the_customer')]),
  ];

  const CustomStepIcon = ({ icon, active, completed, className }) => (
    <CustomStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {stepIcons[icon - 1]}
    </CustomStepIconRoot>
  );
  const [serverSuperAdminErrors, setSuperAdminServerErrors] = useState([]);
  const { handleServerErrors } = useServerSideErrors(
    serverSuperAdminErrors,
    setError,
    clearErrors
  );

  useEffect(() => {
    if (serverSuperAdminErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverSuperAdminErrors, handleServerErrors]);

  useEffect(() => {
    const newId = id ? id : newCompanyId;

    showHideTopbarLoader(createLoading, dispatch);
    if (!createLoading && createdCompany) {
      if (activeStep === steps.length - 1 || activeStep === 0) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: !newId
              ? t('message.company.companyCreatedSuccessfully')
              : t('message.company.companyUpdatedSuccessfully'),
          })
        );
      }
    }
  }, [createLoading]);

  useEffect(() => {
    showHideTopbarLoader(createSuperAdminLoading, dispatch);
    if (!createSuperAdminLoading) {
      if (createdSuperAdmin && activeStep !== steps.length - 1) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: !superAdminDetails
              ? t('message.superAdmin.superAdminCreatedSuccessfully')
              : t('message.superAdmin.superAdminUpdatedSuccessfully'),
          })
        );
        setSuperAdminDetails(createdSuperAdmin);
        dispatch(resetAdminCreate());
      }
    }
  }, [createSuperAdminLoading]);

  const onSubmit = (submitType) => async (data) => {
    const hasError = await validateAddress({
      address1: companyAddress,
      address2: addressLine2,
      city: companyCity,
      state: companyState,
      postcode: zipcode,
      clearErrors,
      addressRecheck,
      fieldName: {
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'zip_code',
        postCodeValue: t('attributes.postCode'),
        address1Key: 'address',
        address1Value: t('attributes.addressLine1'),
        address2Key: 'address_line_2',
        address2Value: t('attributes.addressLine2'),
      },
      setServerErrors,
    });

    if (hasError) return;

    const isDocUploaded = data?.agreementDocument?.document_uuid;
    const isLogoUploaded = data?.company_logo_document_uuid?.document_uuid;
    let docs = [];

    if (!isEmpty(isDocUploaded)) {
      docs.push(isDocUploaded);
    }
    if (company?.documents?.length > 0) {
      const oldDocId = company?.documents?.document_uuid;

      if (oldDocId !== isDocUploaded?.document_uuid || isEmpty(isDocUploaded)) {
        docs.push({ ...company?.documents[0], is_deleted: true });
      }
    }

    const createdCompanyData = {
      company_name: data.company_name,
      address: data.address,
      address_line_2: data.address_line_2,
      city: data.city?.value,
      state: data.state?.value,
      zip_code: data.zip_code,
      contact_name: data.contact_name,
      email: data?.email,
      abn: data.abn,
      office_phone: data.cell_phone,
      company_agreement_document_uuid:
        isDocUploaded || data?.company_agreement_document_uuid,
      cell_phone: data.cell_phone,
      company_logo_document_uuid:
        isLogoUploaded || data?.company_logo_document_uuid,
      ...(data.active !== undefined && {
        active: data.active,
      }),
      status: submitType,
    };

    const superadminRequest = {
      name: data.name,
      email: data.superadmin_email,
      address: data.superadmin_address,
      address_line_2: data.superadmin_address_line_2,
      cell_phone: data.superadmin_cell_phone,
      city: data.superadmin_city?.value,
      state: data.superadmin_state?.value,
      zip_code: data.superadmin_zip_code,
      active: data.superadmin_active,
      status: submitType,
    };

    const pageDataMapping = {
      0: {
        companyData: createdCompanyData,
      },
      1: {
        companyData: createdCompanyData,
        superAdminData: superadminRequest,
      },
      2: {
        companyData: createdCompanyData,
        superAdminData: superadminRequest,
      },
    };

    const req = {
      ...pageDataMapping[activeStep],
    };

    if (!id) {
      if (!newCompanyId && !createdCompany) {
        dispatch(createCompany(req.companyData)).then((res) => {
          if (res.payload?.errorDetails) {
            dispatch(
              snackbarToggle({
                isOpen: true,
                isErrorMsg: true,
                msg: res.payload?.errorDetails?.[0]?.message,
              })
            );
          }
          setNewCompanyId(res.payload?.data?.[0]?.uuid);
          if (res.payload?.data && activeStep !== 0) {
            dispatch(
              createSuperAdmin({
                ...req.superAdminData,
                ...(res.payload?.data?.[0] && {
                  company_uuid: res.payload?.data?.[0]?.uuid,
                  company_name: res.payload?.data?.[0]?.company_name,
                }),
              })
            ).then((superAdminRes) => {
              setNewSuperAdminId(superAdminRes.payload?.data[0]?.uuid);
              if (superAdminRes.payload?.errorDetails) {
                setSuperAdminServerErrors(superAdminRes.payload?.errorDetails);
                dispatch(
                  snackbarToggle({
                    isOpen: true,
                    isErrorMsg: true,
                    msg: superAdminRes.payload?.errorDetails?.[0]?.message,
                  })
                );
              }
            });
          }
        });
      } else {
        if (createdCompany) {
          if (company) {
            dispatch(
              createSuperAdmin({
                ...req.superAdminData,
                ...(company && {
                  company_uuid: company?.uuid,
                  company_name: company?.company_name,
                }),
              })
            );
          }
        }
        dispatch(
          updateCompany({ uuid: newCompanyId, data: createdCompanyData })
        ).then((res) => {
          if (res.payload?.errorDetails) {
            dispatch(
              snackbarToggle({
                isOpen: true,
                isErrorMsg: true,
                msg: res.payload?.errorDetails?.[0]?.message,
              })
            );
          }
          if (res.payload?.data && activeStep !== 0) {
            setNewCompanyId(res.payload?.data[0]?.uuid);
            if (!newSuperAdminId) {
              dispatch(
                createSuperAdmin({
                  ...req.superAdminData,
                  ...(company
                    ? {
                        company_uuid: company?.uuid,
                        company_name: company?.company_name,
                      }
                    : {
                        company_uuid: res.payload?.data[0]?.uuid,
                        company_name: res.payload?.data[0]?.company_name,
                      }),
                })
              ).then((superAdminRes) => {
                setNewSuperAdminId(superAdminRes.payload?.data[0]?.uuid);
                if (superAdminRes.payload?.errorDetails) {
                  setSuperAdminServerErrors(
                    superAdminRes.payload?.errorDetails
                  );
                  dispatch(
                    snackbarToggle({
                      isOpen: true,
                      isErrorMsg: true,
                      msg: superAdminRes.payload?.errorDetails?.[0]?.message,
                    })
                  );
                }
              });
            } else {
              dispatch(
                updateSuperAdmin({
                  uuid: newSuperAdminId,
                  req: {
                    ...req.superAdminData,
                    ...(company
                      ? {
                          company_uuid: company?.uuid,
                          company_name: company?.company_name,
                        }
                      : {
                          company_uuid: res.payload?.data[0]?.uuid,
                          company_name: res.payload?.data[0]?.company_name,
                        }),
                  },
                })
              );
            }
          }
        });
      }
    } else {
      dispatch(updateCompany({ uuid: data?.uuid, data: req.companyData }));

      if (activeStep !== 0) {
        if (superAdminDetails) {
          dispatch(
            updateSuperAdmin({
              uuid: data?.superadmin_uuid,
              req: {
                ...req.superAdminData,
                ...(company && {
                  company_uuid: company?.uuid,
                  company_name: company?.company_name,
                }),
              },
            })
          );
        } else {
          dispatch(
            createSuperAdmin({
              ...req.superAdminData,
              ...(company && {
                company_uuid: company?.uuid,
                company_name: company?.company_name,
              }),
            })
          );
        }
      }
    }
  };

  const handleNext = async () => {
    setIsNextReady(false);
    const isValid = await trigger(); // Trigger validation for all fields in the current step

    let hasError = false;

    if (activeStep === 0 && companyAddress && companyState && zipcode) {
      hasError = await validateAddress({
        address: companyAddress,
        city: companyCity,
        state: companyState,
        postcode: zipcode,
        fieldName: {
          addressKey: 'address',
          addressValue: t('attributes.address'),
          stateKey: 'state',
          stateValue: t('common.state'),
          cityKey: 'city',
          cityValue: t('common.city'),
          postCodeKey: 'zip_code',
          postCodeValue: t('attributes.postCode'),
        },
        setServerErrors,
        clearErrors,
      });
      trigger();
    }

    if (hasError) {
      setIsNextReady(true);

      return;
    }

    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setIsNextReady(true);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [fileData, setFileData] = useState(null);
  const handleFileChange = (type) => (event, onChange) => {
    const file = event.target.files[0];

    setFileData(file);
    const isValidFile = file && file.type === 'application/pdf';

    if (file?.size > FILE_UPLOAD_SIZES.DOCUMENT) {
      setdocumentError(t('message.customer.uploadDocumentMaxSizeError'));

      return;
    }

    if (isValidFile) {
      setAddressRecheck(false);
      onChange(event);
      const fileSize = (file.size / 1024).toFixed(0) + ' KB';

      setIsUploadingOther(true);
      setUploadedDocuments({
        name: file?.name,
        size: fileSize,
      });
      setdocumentError('');

      dispatch(
        uploadDocument({ document_type: type, uploaded_by: 1, file: file })
      );
    } else {
      setdocumentError('Invalid file type. Please upload a PDF.');
    }
  };

  const handleDeleteDocument = (type) => {
    setAddressRecheck(false);
    setUploadedDocuments({
      name: '',
      size: '',
    });
    setValue(`${type}Document`, null);

    const input = document.querySelector(`input[name="${type}Document"]`);

    if (input) {
      input.value = ''; // Reset the file input
    }

    setdocumentError('');
  };

  const handleStepClick = async (index) => {
    const isValid = await trigger();

    if (isValid) {
      setActiveStep(index);
    }
  };

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <LayoutContainer>
      <Box
        sx={{
          position: 'block',
          width: '259px',
          padding: '16px',
          background: SECONDARY,
          marginRight: '16px',
          borderRadius: '4px',
          height: '84vh',
        }}
      >
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          connector={<CustomConnector />}
        >
          {steps.map((label, idx) => (
            <Step
              key={label}
              onClick={id ? () => handleStepClick(idx) : undefined}
              sx={{ cursor: id ? 'pointer' : 'default' }}
            >
              <StepLabel StepIconComponent={CustomStepIcon}>
                <Typography
                  variant={idx <= activeStep ? 'body2' : 'body2'}
                  sx={{
                    color:
                      idx === activeStep ? theme.palette.primary.main : 'black',
                  }}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <FormWrapper component="form" onSubmit={handleSubmit(onSubmit)}>
        {activeStep === 0 && (
          <>
            <CustomCard>
              <Box sx={{ padding: '16px' }}>
                <Typography variant="body1">{`${t('attributes.company.company')} ${t('attributes.company.details')}`}</Typography>
              </Box>
              <Divider />
              <FormFieldWrapper>
                <Controller
                  name="company_name"
                  control={control}
                  rules={{
                    required: `${t('attributes.company.company')} ${t('attributes.name')} ${Validation.general.required}`,
                    minLength: {
                      value: 2,
                      message: Validation.company.companyName,
                    },
                    maxLength: {
                      value: 255,
                      message: Validation.company.companyName,
                    },
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={`${t('attributes.company.company')} ${t('attributes.name')}`}
                      fullWidth
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('company_name');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearHandler(name)}
                      disabled={id ? true : false}
                    />
                  )}
                />
                <Controller
                  name="abn"
                  control={control}
                  rules={{
                    required: `${t('attributes.company.abnNumber')} ${Validation.general.required}`,
                    pattern: {
                      value: /^\d{11}$/,
                      message: Validation.general.abnNumber,
                    },
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.company.abnNumber')}
                      fullWidth
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('abn');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearHandler(name)}
                    />
                  )}
                />
                <Controller
                  name="searchAddress"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      label={t('common.searchAddress')}
                      options={autocompleteAddressData || []}
                      value={value}
                      isRequired={false}
                      isLoadingData={isAutocompleteAddressLoading}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                        setSearchAddress(e.target?.value);
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="address"
                  control={control}
                  rules={{
                    required: `${t('attributes.addressLine1')} ${Validation.general.required}`,
                    maxLength: {
                      value: 300,
                      message: Validation.general.address1,
                    },
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.addressLine1')}
                      fullWidth
                      value={value}
                      setBlur={setAddressBlur}
                      onChange={(e) => {
                        onChange(e);
                        trigger('address');
                      }}
                      multiline
                      rows={2}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearHandler(name)}
                    />
                  )}
                />
                <Controller
                  name={'address_line_2'}
                  control={control}
                  rules={{
                    maxLength: {
                      value: 300,
                      message: Validation.general.address2,
                    },
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.addressLine2')}
                      fullWidth
                      value={value || ''}
                      onChange={(e) => {
                        onChange(e);
                        trigger('address_line_2');
                      }}
                      isRequired={false}
                      multiline
                      rows={2}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearHandler(name)}
                      setBlur={setAddress2Blur}
                    />
                  )}
                />

                <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
                  <Controller
                    name="state"
                    control={control}
                    rules={{
                      required: `${t('common.state')} ${Validation.general.required}`,
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={stateList}
                        label={t('common.state')}
                        setBlur={setStateBlur}
                        onChange={(e, newValue) => {
                          onChange(newValue);
                          setValue('city', null);
                          clearErrors('city');
                        }}
                        value={value}
                        error={error}
                        helperText={error ? error.message : ''}
                        onClear={() => clearHandler(name)}
                      />
                    )}
                  />
                  <Controller
                    name="city"
                    control={control}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={cityList}
                        label={t('common.city')}
                        setBlur={setCityBlur}
                        onChange={(e, newValue) => {
                          onChange(newValue);
                          trigger('city');
                        }}
                        value={value}
                        error={error}
                        helperText={error ? error.message : ''}
                        onClear={() => clearHandler(name)}
                        disabledDropdown={!selectedState}
                        isRequired={false}
                      />
                    )}
                  />
                </Box>
                <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
                  <Controller
                    name="zip_code"
                    control={control}
                    rules={{
                      required: `${t('attributes.postCode')} ${Validation.general.required}`,
                      validate: (value) => {
                        if (value && !/^\d+$/.test(value)) {
                          return Validation.general.numericValue;
                        }

                        return true;
                      },
                      minLength: {
                        value: postCodeLength,
                        message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                      },
                      maxLength: {
                        value: postCodeLength,
                        message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                      },
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={t('attributes.postCode')}
                        fullWidth
                        value={value}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ''
                          );

                          onChange(numericValue);
                          trigger('zip_code');
                        }}
                        setBlur={setPostcodeBlur}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearHandler(name)}
                      />
                    )}
                  />
                </Box>
                <Typography variant="body1">
                  {t('attributes.preview')}
                  <span style={{ color: WARNING }}>*</span>
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    columnGap: '12px',
                    width: '100%',
                  }}
                >
                  <Controller
                    name="company_logo_document_uuid"
                    control={control}
                    rules={{
                      required: `${t('attributes.company.company')} ${t('attributes.company.logo')} ${Validation.general.required}`,
                    }}
                    render={({ field: { value }, fieldState: { error } }) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '16px',
                        }}
                      >
                        <UploadLogo
                          logoUrl={watch('logo_url')}
                          onClick={() =>
                            document
                              .getElementById('upload-button-file')
                              .click()
                          }
                        />
                        {!value && error && (
                          <Typography variant="body2" color={WARNING}>
                            {error.message}
                          </Typography>
                        )}
                      </Box>
                    )}
                  />
                  <Controller
                    name="company_logo_document_uuid"
                    control={control}
                    render={({ field: { value } }) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '16px',
                        }}
                      >
                        <input
                          accept="image/*"
                          style={{ display: 'none' }}
                          id="upload-button-file"
                          type="file"
                          onChange={(event) => {
                            const file = event.target.files[0];

                            if (!validImageTypes.includes(file.type)) {
                              setError('company_logo_document_uuid', {
                                type: 'custom',
                                message: t('common.invalidLogoType'),
                              });

                              return;
                            }

                            event.target.value = '';
                            if (file && file.size > FILE_UPLOAD_SIZES.IMAGE) {
                              setError('company_logo_document_uuid', {
                                type: 'custom',
                                message: t(
                                  'message.company.uploadLogoMaxSizeError'
                                ),
                              });

                              return;
                            }
                            setSelectedImage(URL.createObjectURL(file));
                            setOpenCropper(true);

                            // dispatch(
                            //   uploadDocument({
                            //     document_type: 'company_logo_document_uuid',
                            //     uploaded_by: 1,
                            //     file: file,
                            //   })
                            // );

                            if (file) {
                              const reader = new FileReader();

                              reader.onload = function (e) {
                                const dataUrl = e.target.result;

                                setValue('logo_url', dataUrl);
                              };

                              reader.readAsDataURL(file); // Read the file as a data URL
                            }
                          }}
                        />
                        <label
                          htmlFor={!value ? 'upload-button-file' : undefined}
                        >
                          <CustomButton
                            text={`${t('attributes.company.upload')} ${t('attributes.company.company')} ${t('attributes.company.logoCapital')}`}
                            startIcon={<UploadFileIcon />}
                            disabled={value ? true : false}
                            component="span"
                          />
                        </label>

                        {value && (
                          <CustomButton
                            text={t('attributes.delete')}
                            startIcon={<DeleteOutlineIcon />}
                            sx={{
                              width: '100px',
                              background: WARNING,
                              '&:hover': {
                                backgroundColor: WARNING_HOVER,
                              },
                            }}
                            onClick={() => {
                              setValue('company_logo_document_uuid', null, {
                                shouldValidate: true,
                              });
                              setValue('logo_url', null);
                            }}
                          />
                        )}
                      </Box>
                    )}
                  />
                </Box>

                <Box>
                  <Typography variant="body1">
                    {t('common.attach_agreement_documents')}
                  </Typography>
                  {/* <FormFieldWrapper> */}
                  <Box
                    sx={{
                      paddingTop: '16px',
                      rowGap: '16px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Controller
                      name="agreementDocument"
                      control={control}
                      render={({
                        field: { onChange, name, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <CustomFileUploadButton
                            name={name}
                            onFileChange={(event) => {
                              handleFileChange('agreement')(event, onChange);
                              trigger('agreementDocument');
                            }}
                            multiple={false}
                            buttonText="Click to upload or drag and drop the PDF file"
                            accept=".pdf"
                            error={!!documentError || error}
                            errorMessage={documentError || error?.message}
                          />
                          {((!isEmpty(value) && value?.document_uuid) ||
                            uploadedDocuments?.name) && (
                            <UploadedDocumentView
                              fileName={
                                isUploadingOther
                                  ? uploadedDocuments?.name
                                  : value?.document_name ||
                                    (value && `${value}.pdf`) ||
                                    ''
                              }
                              fileSize={
                                isUploadingOther
                                  ? uploadDocument?.size
                                  : value?.document_size
                              }
                              isUploading={isUploadingOther}
                              onclick={() => {
                                if (fileData) {
                                  // Handle preview for newly uploaded document
                                  previewDocument(
                                    t,
                                    dispatch,
                                    { file: fileData },
                                    true
                                  );
                                } else {
                                  // Handle preview for existing document
                                  previewDocument(
                                    t,
                                    dispatch,
                                    value?.document_uuid ?? value
                                  );
                                }
                              }}
                              onDelete={() => setOpenConfirmationModal(true)}
                            />
                          )}
                        </>
                      )}
                    />
                  </Box>
                  {/* </FormFieldWrapper> */}
                </Box>
              </FormFieldWrapper>
            </CustomCard>
            <CustomCard>
              <Box sx={{ padding: '16px' }}>
                <Typography variant="body1">{`${t('common.business_owner')} ${t('attributes.information')}`}</Typography>
              </Box>
              <Divider />
              <FormFieldWrapper>
                <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
                  <Controller
                    name="contact_name"
                    control={control}
                    rules={{
                      required: `${t('common.business_owner')} ${t('attributes.name')} ${Validation.general.required}`,
                      maxLength: {
                        value: 80,
                        message: Validation.general.contactName,
                      },
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={`${t('common.business_owner')} ${t('attributes.name')}`}
                        fullWidth
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('contact_name');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearHandler(name)}
                      />
                    )}
                  />
                  <Controller
                    name="cell_phone"
                    control={control}
                    rules={{
                      // required: `${t('attributes.company.cell_phone')} ${Validation.general.required}`,
                      pattern: {
                        value: /^\d{0,50}$/,
                        message: Validation.general.cellPhone,
                      },
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={t('attributes.company.cell_phone')}
                        fullWidth
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('cell_phone');
                        }}
                        isRequired={false}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearHandler(name)}
                      />
                    )}
                  />
                </Box>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: `${t('attributes.email')} ${Validation.general.required}`,
                    maxLength: {
                      value: 254,
                      message: Validation.general.email,
                    },
                    pattern: {
                      value: EMAIL_REGEX,
                      message: Validation.general.validEmail,
                    },
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.email')}
                      fullWidth
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('email');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearHandler(name)}
                    />
                  )}
                />
              </FormFieldWrapper>
            </CustomCard>

            {id && (
              <CustomCard>
                <Box sx={{ padding: '16px' }}>
                  <CommonCheckbox
                    control={control}
                    name="active"
                    label={t('common.active')}
                    isRequired={false}
                    showMessage={true} // Enable message display
                    messageOnChecked={labels('fireCompanyActiveCheckbox')}
                    messageOnUnchecked={labels('fireCompanyInactiveCheckbox')}
                  />
                </Box>
              </CustomCard>
            )}
          </>
        )}
        {activeStep === 1 && (
          <SuperAdminForm
            id={superAdminDetails?.id}
            clearErrors={clearErrors}
            reset={reset}
            control={control}
            trigger={trigger}
            watch={watch}
            setValue={setValue}
            setServerErrors={setServerError}
            setError={setError}
          />
        )}

        {activeStep === 2 && (
          <InviteTheFireCompany title={steps[2]} watch={watch} />
        )}
      </FormWrapper>

      <Box
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          height: '54px',
          width: '100%',
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center',
          backgroundColor: SECONDARY,
          padding: '16px',
          gap: 2,
        }}
      >
        {activeStep !== 0 && (
          <CustomButton
            startIcon={<KeyboardArrowLeftIcon />}
            text={t('attributes.previous')}
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
          />
        )}

        {!id && (
          <CustomButton
            startIcon={<SaveOutlinedIcon />}
            text={t('common.save_as_draft')}
            color="inherit"
            onClick={() =>
              handleSubmit(
                onSubmit(
                  activeStep === steps.length - 1
                    ? INVITATION_PENDING
                    : DRAFT_STATUS
                )
              )()
            }
            disabled={createLoading}
          />
        )}

        {activeStep === steps.length - 1 ? (
          <CustomButton
            text={
              createLoading
                ? t('common.loading')
                : company?.status !== INVITED_STATUS
                  ? t('common.send_the_invite')
                  : t('attributes.save')
            }
            startIcon={
              company?.status !== INVITED_STATUS ? (
                <MarkEmailReadOutlinedIcon />
              ) : (
                <SaveOutlinedIcon />
              )
            }
            onClick={() => handleSubmit(onSubmit(INVITED_STATUS))()}
            disabled={createLoading}
          />
        ) : (
          <CustomButton
            endIcon={<ChevronRightIcon />}
            text="Next"
            onClick={handleNext}
            disabled={!isNextReady}
          />
        )}
      </Box>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('common.delete_document')}
          description={t('common.delete_document_confirmation')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            handleDeleteDocument('agreement');
            setOpenConfirmationModal(false);
          }}
        />
      )}
      {openCropper && (
        <ImageCropper
          imgSrc={selectedImage}
          updateAvatar={updateAvatar}
          open={openCropper}
          onClose={() => {
            setOpenCropper(false);
          }}
          isForCompany={true}
        />
      )}
    </LayoutContainer>
  );
};

export default CompanyForm;
