import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { WarningAmberOutlined } from '@mui/icons-material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Box, Tooltip, Typography } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';

import { PRIMARY, RED, SECONDARY } from '../../../constants/Colors';
import { exportToCSV } from '../../../constants/common';
import {
  ACTIVE,
  APPROVED,
  CREATED_STATUS,
  CUSTOMER_DEFECT_QUOTE_LIST,
  CUSTOMER_QUOTATION_STATUS,
  DATE_FORMAT_API,
  DIFFERED,
  DRAFT_STATUS,
  PENDING,
  PROPERTY_DETAIL_STEP,
  QUOTE_TYPES,
  REJECTED_STATUS,
  ROUTE_NAVIGATE_CONST,
  SCHEDULE_BOARD_WO_UTC_LOCAL,
  SUBMIT_TO_CUSTOMER_STATUS,
  SUBMITTED,
  TEMPLATE_SET,
  WO_CREATED,
  WORK_ORDER_STEPS,
  WORK_ORDER_TYPE_ADHOC,
} from '../../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  EXPORT_FILE_NAMES,
} from '../../../constants/ExportConstant';
import { getUserRole } from '../../../hoc/Permission';
import getDropdownDataHook from '../../../hooks/getDropdownDataHook';
import usePreviewAndDownloadTemplate from '../../../hooks/usePreviewAndDownloadTemplate';
import { snackbarToggle } from '../../../store/CommonReducer';
import { editCompany } from '../../../store/company/api';
import { editContact } from '../../../store/contacts/api';
import { resetEdit } from '../../../store/contacts/reducer';
import { setPropertyServiceQuoteFilter } from '../../../store/filters/reducer';
import { getProperty, getPropertyList } from '../../../store/property/api';
import {
  getQuotesList,
  getQuotesStatusDropdownList,
} from '../../../store/quotes/api';
import { resetQuoteList } from '../../../store/quotes/reducer';
import { getCustomerList } from '../../../store/users/customer/api';
import {
  createDefectQuote,
  createWorkOrder,
  getDefectQuotesDetail,
  updateDefectQuotationStatus,
} from '../../../store/workOrder/api';
import {
  resetCreateDefectQuote,
  resetCreateWorkOrder,
  resetDefectQuotesDetail,
  resetUpdateQuotationStatus,
} from '../../../store/workOrder/reducer';
import {
  calculateTotal,
  checkIsFiltersApplied,
  formatAddress,
  formatPriceWithDecimalValue,
  formattedDate,
  getRedirectURL,
  showHideTopbarLoader,
} from '../../../utils';
import { Edit, Generate, View } from '../../CommonComponents/ActionComponent';
import Autocomplete from '../../CommonComponents/AutoComplete';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomDateRangePicker from '../../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../../CommonComponents/FilterComponent';
import NewNoDataPage from '../../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import { formatDefectQuotesStatus } from '../../WorkOrder/DefectQuotes';
import ServiceQuotesDetail from './ServiceQuotesDetail';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

const defaultFilters = {
  customer: null,
  property: null,
  status: null,
  due_date: null,
  quote_date: null,
};

const ServiceQuotes = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const outletProps = useOutletContext();
  const [searchParams] = useSearchParams();
  const queryStatus = searchParams.get('status');
  const quoteUUID = useParams()?.service_quote_uuid;
  const navigate = useNavigate();

  const {
    propertyId,
    nextStepTitle,
    setNextStepTitle,
    isFromServiceQuotes = true,
    isFromSideBar = false,
  } = { ...props, ...outletProps };

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [searchText, setSearchText] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [quote, setQuote] = useState('');
  const [open, setOpen] = useState(false);
  const [isPropertyLoading, setIsPropertyLoading] = useState(false);
  const [propertyData, setPropertyData] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedRows, setSelectedRows] = useState(null);
  const [customerOptions, setCustomerOptions] = useState(null);
  const [propertyDetailsList, setPropertyDetailsList] = useState([]);
  const [editServiceQuote, setEditServiceQuote] = useState(null);

  const { propertyList } = useSelector((state) => state.property);
  const { propertyServiceQuoteFilter } = useSelector((state) => state.filters);

  const { isLoading, quoteList, total, totalServiceCount } = useSelector(
    (state) => state.quotes.get
  );
  const { isLoading: createDefectQuoteLoading, data: createDefectQuoteData } =
    useSelector((state) => state.workOrder.createDefectQuote);

  const { data, isLoading: defectQuotesDetailLoading } = useSelector(
    (state) => state.workOrder.defectQuotesDetail
  );

  const { isLoading: isLoadingCompany, company } = useSelector(
    (state) => state.company.edit
  );

  const { isLoading: updateQuotiationLoading, data: updateQuotiationData } =
    useSelector((state) => state.workOrder.updateQuotationStatus);

  const { isLoading: contactLoading, contact } = useSelector(
    (state) => state.contact.edit
  );

  const { listTemplateLoading, generatePDF, PreviewTemplatePDF } =
    usePreviewAndDownloadTemplate({
      module: TEMPLATE_SET.DEFECT_QUOTES,
      data: {
        companyData: company,
        propertyData,
        isPropertyLoading: isPropertyLoading || isLoading || contactLoading,
        quoteData: data,
        isFromServiceQuotes: true,
        contact,
      },
    });

  const quotesPropertyList = getDropdownDataHook({
    data: propertyDetailsList,
    labelName: 'property_name',
    valueName: 'uuid',
  });

  const user_info = localStorage.getItem('user_info');
  const user = user_info ? JSON.parse(user_info) : null;

  const resetFilter = () => {
    setSearchText('');
    setFilters(defaultFilters);
  };

  useEffect(
    () => () => {
      dispatch(resetQuoteList());
      dispatch(resetDefectQuotesDetail());
      dispatch(resetUpdateQuotationStatus());
      dispatch(resetCreateDefectQuote());
      dispatch(resetCreateWorkOrder());
      dispatch(resetEdit());
    },
    []
  );

  const generateWorkOrder = (selected = {}) => {
    const bodyData = {
      customer_uuid: selected?.customer_uuid,
      property_uuid: selected?.property_uuid,
      due_date: moment().add(1, 'month').format(DATE_FORMAT_API),
      work_order_type: WORK_ORDER_TYPE_ADHOC,
      work_order_tasks: selected?.quotation_items?.map((item) => ({
        description:
          [item?.product_catalogue?.product_name, item?.comment]
            .filter(Boolean)
            .join(' - ') || '',
      })),
      parent_quotation_approved_uuid: selected?.uuid,
      work_order_name: `${selected?.id} - Adhoc`,
    };

    dispatch(createWorkOrder({ data: bodyData }))
      .then((res) =>
        dispatch(
          updateDefectQuotationStatus({
            work_order_id: null,
            quote_id: selected?.uuid,
            status: WO_CREATED,
            work_order_uuid: res?.payload?.data?.[0]?.uuid,
          })
        )
      )
      .then(() => {
        getAllQuoteList();
        setOpenPreview(false);
        dispatch(resetCreateWorkOrder());
      })
      .catch((error) => {
        console.error(
          'Error in creating work order or updating defect quotation:',
          error
        );
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: 'An error occurred. Please try again.',
          })
        );
      });
  };

  const findProperty = (property_uuid) => {
    const property = propertyDetailsList?.find(
      (prop) => prop.uuid === property_uuid
    );

    return property;
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'id',
        headerName: t('attributes.work_order.quote_id'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'woid',
        headerName: t('attributes.invoice.wo_id'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'status',
        headerName: t('attributes.work_order.status'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row?.status ? formatDefectQuotesStatus(row?.status, t) : '-',
      },
      {
        field: 'quote_date',
        headerName: t('attributes.work_order.quote_date'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'due_date',
        headerName: t('attributes.work_order.due_date'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
          if (!row?.due_date) {
            return '-';
          }

          const dueDate = moment(
            formattedDate(row?.due_date, SCHEDULE_BOARD_WO_UTC_LOCAL)
          );

          if (dueDate.isBefore(moment())) {
            return (
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography color={RED}>
                  {formattedDate(row?.due_date)}
                </Typography>
                <WarningAmberOutlined sx={{ color: `${RED}!important` }} />
              </Box>
            );
          }

          return formattedDate(row.due_date);
        },
      },
      {
        field: 'amount',
        headerName: t('common.total_amount'),
        flex: 1,
        sortable: false,
        renderHeader: () => (
          <Tooltip
            title={`${t('common.total_amount')} (${t('common.including')} ${company?.tax_type})`}
            arrow
          >
            <Typography
              variant="body1"
              textAlign="left"
              sx={{
                display: 'flex',
                justifyContent: 'start',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {t('common.total_amount')}
              <br />
              {`(${t('common.including')} ${company?.tax_type})`}
            </Typography>
          </Tooltip>
        ),
        renderCell: ({ row }) => (
          <Typography
            variant="body2"
            sx={{ display: 'flex', justifyContent: 'start' }}
          >
            {`${formatPriceWithDecimalValue(row.amount)}`}
          </Typography>
        ),
      },
      {
        field: 'edit',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <View
              onClick={() => {
                if (user.company_uuid && row.property_uuid) {
                  setIsPropertyLoading(true);
                  // dispatch(editCompany(user?.company_uuid));
                  dispatch(getProperty(row?.property_uuid))
                    .then((res) => setPropertyData(res.payload?.data[0]))
                    .finally(() => setIsPropertyLoading(false));
                }
                if (row?.id) {
                  setQuote(row);
                  dispatch(
                    getDefectQuotesDetail({
                      quote_id: row?.uuid,
                    })
                  );
                }
                if (row?.contact_uuid) {
                  dispatch(editContact(row?.contact_uuid));
                }
                setOpenPreview(true);
              }}
              disabled={row?.quotation_items?.length === 0}
            />
            {row?.status === APPROVED || row?.status === WO_CREATED ? (
              <Generate
                onClick={() => generateWorkOrder(row)}
                disabled={
                  row?.status === WO_CREATED ||
                  row?.property_details?.[0]?.property_status?.name !== ACTIVE
                }
              />
            ) : (
              [
                DRAFT_STATUS,
                CREATED_STATUS,
                REJECTED_STATUS,
                DIFFERED,
              ].includes(row?.status) && (
                <Edit
                  onClick={() => {
                    dispatch(resetUpdateQuotationStatus());
                    dispatch(resetDefectQuotesDetail());
                    if (row?.id) {
                      setQuote(row);
                      dispatch(resetCreateDefectQuote());
                      dispatch(
                        getDefectQuotesDetail({
                          quote_id: row?.uuid,
                        })
                      );
                      !isFromSideBar
                        ? setNextStepTitle({
                            title: WORK_ORDER_STEPS.service_quotes,
                            step: `Q-${row?.quotation_id}`,
                          })
                        : (navigate(
                            getRedirectURL()[getUserRole()][
                              ROUTE_NAVIGATE_CONST.SERVICE_QUOTES
                            ] +
                              '/' +
                              row?.uuid
                          ),
                          setEditServiceQuote({
                            title: WORK_ORDER_STEPS.service_quotes,
                            step: `${row?.id}`,
                          }));
                      setOpen(true);
                    }
                  }}
                  disabled={row?.status === SUBMITTED}
                />
              )
            )}
          </Box>
        ),
      },
    ];

    if (isFromSideBar) {
      baseColumns.splice(
        2,
        0,
        {
          field: 'customer',
          headerName: t('attributes.customer.customer'),
          flex: 1,
          sortable: true,
          renderCell: ({ row }) => row?.customer || '-',
        },
        {
          field: 'property_name',
          headerName: t('attributes.property.propertyName'),
          flex: 1,
          sortable: false,
        },
        {
          field: 'property_address',
          headerName: `${t('attributes.property.property')} ${t('attributes.address')}`,
          flex: 1,
          sortable: false,
        }
      );
    }

    return baseColumns;
  }, [company]);

  const getAllQuoteList = useCallback(() => {
    const status_to_pass = filters.status
      ?.map((item) =>
        item.value === PENDING ? SUBMIT_TO_CUSTOMER_STATUS : item.value
      )
      ?.toString();

    dispatch(
      getQuotesList({
        order: order,
        orderBy: orderBy === 'id' ? 'created_at' : orderBy,
        page: currentPage,
        size: perPageData,
        search: searchText,
        fromDueDate: filters.due_date?.startDate,
        toDueDate: filters.due_date?.endDate,
        status: status_to_pass,
        type: QUOTE_TYPES.service,
        propertyUUID: propertyId ? propertyId : filters.property?.value,
        fromQuoteDate: filters.quote_date?.startDate,
        toQuoteDate: filters.quote_date?.endDate,
        customer_uuid: filters.customer?.value,
        isFromSideBar: isFromSideBar ? true : false,
      })
    ).finally(() => {
      setIsInitialLoading(false);
    });
  }, [
    dispatch,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
    refresh,
    propertyId,
    isFromSideBar,
  ]);

  useEffect(() => {
    if (queryStatus) {
      const selected = CUSTOMER_DEFECT_QUOTE_LIST?.find(
        (d) => d.value === queryStatus
      );

      setFilters((prev) => ({ ...prev, status: [selected] }));
      setIsFilterComponentVisible(true);
    }
  }, [queryStatus]);

  useEffect(() => {
    setPropertyDetailsList(propertyList?.data?.data);
  }, [propertyList]);

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  const debouncedFetchData = useCallback(debounce(getAllQuoteList, 500), [
    getAllQuoteList,
  ]);

  useEffect(() => {
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    const req = {
      limit: -1,
      customerUuid: filters?.customer?.value,
    };

    dispatch(getPropertyList(req));
  }, [filters?.customer]);

  useEffect(() => {
    dispatch(getQuotesStatusDropdownList());
    dispatch(getCustomerList({ limit: -1 })).then((res) => {
      const options = res?.payload?.data?.map((c) => ({
        label: c?.customer_name,
        value: c?.uuid,
      }));

      setCustomerOptions(options || []);
    });
    dispatch(editCompany(user?.company_uuid));
  }, []);

  useEffect(() => {
    if (!updateQuotiationLoading) {
      if (updateQuotiationData?.data?.length > 0) {
        setOpenPreview(false);
        debouncedFetchData();
        updateQuotiationData?.data[0]?.status !== CREATED_STATUS &&
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg:
                updateQuotiationData?.data[0]?.status === WO_CREATED
                  ? t('attributes.work_order.work_order_created')
                  : t('attributes.work_order.quotationSuccessMessage'),
            })
          );
      }
    }
  }, [updateQuotiationData, updateQuotiationLoading]);

  useEffect(() => {
    showHideTopbarLoader(createDefectQuoteLoading, dispatch);
    if (!createDefectQuoteLoading) {
      if (createDefectQuoteData) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: t('attributes.service_quotes.serviceQuoteCreatedSuccessMsg'),
          })
        );
        debouncedFetchData();
      }
    }
  }, [createDefectQuoteLoading, createDefectQuoteData]);

  useEffect(() => {
    if (propertyServiceQuoteFilter && isFromServiceQuotes) {
      setFilters(propertyServiceQuoteFilter);
      setSearchText(propertyServiceQuoteFilter?.searchText);
      checkIsFiltersApplied(propertyServiceQuoteFilter) &&
        setIsFilterComponentVisible(true);
    }
  }, []);

  useEffect(() => {
    if ((filters || searchText) && isFromServiceQuotes) {
      dispatch(setPropertyServiceQuoteFilter({ ...filters, searchText }));
    }
  }, [filters, searchText]);

  const rows = quoteList?.map((item) => {
    const updatedItem = Object.keys(item).reduce((acc, key) => {
      acc[key] = item[key] === ('' || null) ? '-' : item[key];

      return acc;
    }, {});

    updatedItem.id = `Q-${
      item?.quotation_id < 10 ? '0' + item?.quotation_id : item?.quotation_id
    }`;
    updatedItem.uuid = item?.uuid;
    updatedItem.woid = item?.work_order?.woid || '-';
    updatedItem.property_uuid = item?.property_uuid;
    updatedItem.quote_date = formattedDate(item.quote_date) || '-';
    updatedItem.due_date = item.due_date;
    updatedItem.amount = calculateTotal({
      data: item,
      type: 'total',
      ...(company?.tax_value && {
        taxPercentage: company?.tax_value,
      }),
    });
    updatedItem.created_at = item.created_at;
    updatedItem.customer = item?.customer_details?.customer_name || '-';
    (updatedItem.contact_uuid = item?.customer_details?.contact_uuid),
      (updatedItem.property_name = findProperty(
        item?.property_uuid
      )?.property_name);
    updatedItem.property_address = formatAddress(
      findProperty(item?.property_uuid)?.address,
      findProperty(item?.property_uuid)?.address_line_2
    );

    return updatedItem;
  });

  const handleCreateDefectQuote = () => {
    dispatch(
      createDefectQuote({
        work_order_uuid: null,
        quotation_type: QUOTE_TYPES.service,
        property_uuid: propertyId,
      })
    );
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const noData = (
    <>
      <NewNoDataPage
        icon={<ReceiptLongOutlinedIcon />}
        title={t('attributes.service_quotes.service_quotes')}
        singularText={t('attributes.service_quotes.service_quote')}
        filterHeight={380}
        onlyTitle={true}
        customText={t('attributes.service_quote.no_service_quote')}
      />
    </>
  );

  const styledButtonDispalyer = (
    <Box sx={{ paddingLeft: '10px' }}>
      {isFromSideBar ? (
        <CustomButton
          text={t('attributes.export')}
          color="secondary"
          sx={{ height: '52%' }}
          startIcon={<OpenInNewIcon />}
          onClick={() => {
            if (!selectedRows || selectedRows?.length === 0) {
              dispatch(
                snackbarToggle({
                  isOpen: true,
                  isErrorMsg: true,
                  msg: t('message.common.noRecordExportMessage'),
                })
              );
              setSelectedRows(null);
            } else {
              exportToCSV(
                selectedRows,
                COLUMNS_TO_EXPORT.SUPER_ADMIN_SERVICE_QUOTES,
                EXPORT_FILE_NAMES.SUPER_ADMIN_SERVICE_QUOTES
              );
            }
          }}
        />
      ) : (
        <CustomButton
          text={t('common.create_new')}
          color="secondary"
          sx={{ height: '52%' }}
          startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={() => {
            handleCreateDefectQuote();
          }}
          disabled={isLoading || createDefectQuoteLoading}
        />
      )}
    </Box>
  );

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        isRefresh={true}
        searchText={searchText}
        isActive={isFilterComponentVisible}
        onRefreshFilter={() => setRefresh(!refresh)}
        onResetFilter={resetFilter}
        onSearchChange={(e) => setSearchText(e.target.value)}
        isResetButtonVisible={
          searchText ||
          filters.status?.length > 0 ||
          filters.quote_date?.startDate ||
          filters.quote_date?.endDate ||
          filters.due_date?.startDate ||
          filters.due_date?.endDate ||
          filters.customer ||
          filters.property
        }
        isFilterDisable={checkIsFiltersApplied(filters)}
        sx={{ mr: '16px', mt: '16px' }}
      />
      {isFilterComponentVisible && (
        <Box sx={{ padding: '0 16px 16px 16px' }}>
          <FilterComponent>
            {isFromSideBar && (
              <>
                <Autocomplete
                  placeholder={t('roles.customer')}
                  options={customerOptions}
                  width="190px"
                  value={filters?.customer}
                  onChange={(e, newVal) => {
                    if (newVal || newVal === null) {
                      setFilters((pre) => ({ ...pre, customer: newVal }));
                    }
                  }}
                />
                <Autocomplete
                  placeholder={t('attributes.property.property')}
                  options={quotesPropertyList}
                  width="190px"
                  value={filters?.property}
                  onChange={(e, newVal) => {
                    if (newVal || newVal === null) {
                      setFilters((pre) => ({ ...pre, property: newVal }));
                    }
                  }}
                />
              </>
            )}
            <Autocomplete
              multiple
              handleMultipleFilter={true}
              placeholder={t('attributes.property.status')}
              options={CUSTOMER_DEFECT_QUOTE_LIST}
              width="190px"
              value={filters?.status}
              onChange={(e, newVal) => {
                if (newVal || newVal === null) {
                  setFilters((pre) => ({ ...pre, status: newVal }));
                }
              }}
            />
            <CustomDateRangePicker
              placeholder={t('attributes.work_order.quote_date')}
              onOkClick={(val) => {
                setFilters((pre) => ({
                  ...pre,
                  quote_date: { startDate: val[0], endDate: val[1] },
                }));
              }}
              onClear={() => {
                setFilters((pre) => ({
                  ...pre,
                  quote_date: null,
                }));
              }}
              fromDate={filters.quote_date?.startDate}
              toDate={filters.quote_date?.endDate}
            />
            <CustomDateRangePicker
              placeholder={t('attributes.work_order.due_date')}
              onOkClick={(val) => {
                setFilters((pre) => ({
                  ...pre,
                  due_date: { startDate: val[0], endDate: val[1] },
                }));
              }}
              onClear={() => {
                setFilters((pre) => ({
                  ...pre,
                  due_date: null,
                }));
              }}
              fromDate={filters.due_date?.startDate}
              toDate={filters.due_date?.endDate}
            />
          </FilterComponent>
        </Box>
      )}

      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
        checkboxSelection={isFromSideBar ? true : false}
        onRowSelectionModelChange={handleSelectionChange}
        sx={{ px: '16px' }}
      />
    </>
  );

  const mainWrapper = (
    <StyledMainWrapper
      title={
        isFromSideBar ? t('attributes.service_quotes.service_quotes') : ' '
      }
      btn={styledButtonDispalyer}
      isSubDetails={true}
    >
      {!isLoading && totalServiceCount === 0 && noData}
      {isInitialLoading || updateQuotiationLoading || listTemplateLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            minHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
            maxHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isInitialLoading &&
        !updateQuotiationLoading &&
        !listTemplateLoading &&
        totalServiceCount > 0 &&
        renderedComponent
      )}
    </StyledMainWrapper>
  );

  const sendDefectQuotation = () => {
    dispatch(
      updateDefectQuotationStatus({
        work_order_id: null,
        quote_id: data?.uuid,
        status: SUBMIT_TO_CUSTOMER_STATUS,
      })
    );
  };

  return (
    <>
      {nextStepTitle?.title === PROPERTY_DETAIL_STEP.serviceQuote ? (
        <ServiceQuotesDetail isFromServiceQuotes={isFromServiceQuotes} />
      ) : quoteUUID ? (
        <Outlet
          context={{
            woId: quoteUUID,
            nextStepTitle: editServiceQuote,
            setRefresh: setRefresh,
          }}
        />
      ) : (
        mainWrapper
      )}

      {openPreview && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={openPreview}
          title={`Q-${quote?.quotation_id}` || '-'}
          onClose={() => {
            setOpenPreview(false);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.download')}
              color="inherit"
              disabled={
                isPropertyLoading ||
                isLoadingCompany ||
                defectQuotesDetailLoading
              }
              startIcon={<DownloadOutlinedIcon />}
              onClick={() => generatePDF()}
            />,
            quote?.status !== CUSTOMER_QUOTATION_STATUS.PENDING &&
              quote?.status !== DRAFT_STATUS &&
              quote?.status !== WO_CREATED &&
              quote?.status !== CUSTOMER_QUOTATION_STATUS.APPROVED &&
              quote?.status !== CUSTOMER_QUOTATION_STATUS.OVERDUE && (
                <CustomButton
                  text={t('attributes.service_quotes.send_service_quotes')}
                  disabled={
                    isPropertyLoading ||
                    isLoadingCompany ||
                    updateQuotiationLoading ||
                    quote?.status === CUSTOMER_QUOTATION_STATUS.APPROVED
                  }
                  startIcon={<ForwardToInboxOutlinedIcon />}
                  onClick={sendDefectQuotation}
                  sx={{
                    '&.Mui-disabled': {
                      backgroundColor: PRIMARY,
                      color: `${SECONDARY} !important`,
                      opacity: 0.5,
                    },
                  }}
                />
              ),
          ]}
          width={653}
        >
          {PreviewTemplatePDF}
        </SwipeableDrawer>
      )}
    </>
  );
};

export default ServiceQuotes;
