import React, { useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider, Typography } from '@mui/material';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../assets/commonStyled';
import {
  PRODUCT_TYPES,
  PRODUCT_TYPES_LABEL,
  TAB_STATUS,
} from '../../constants/Constants';
import { Validation } from '../../constants/FieldValidationMsg';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import useDidMount from '../../hooks/useDidMount';
import {
  getCategoryLevelsDropdown,
  getEquipmentClassDropdown,
} from '../../store/equipmentTypes/api';
import {
  resetCategoryLevels1DropdownList,
  resetCategoryLevels2DropdownList,
  resetCategoryLevels3DropdownList,
  resetEquipmentClassDropdownList,
} from '../../store/equipmentTypes/reducer';
import {
  getProductDetailsById,
  getProductEnhancement,
} from '../../store/productCatalogue/api';
import {
  resetProductDetails,
  resetProductEnhancementData,
} from '../../store/productCatalogue/reducer';
import { formatPriceWithDecimalValue } from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';
import { defaultValuesProductCatalogueForm } from './ProductCatalogue';

const UNIT_OF_MEASURE = [
  { label: 'Each', value: 'each' },
  { label: 'Length', value: 'length' },
  { label: 'Weight', value: 'weight' },
];

export const ProductCatalogueForm = ({
  id,
  control,
  reset,
  setValue,
  trigger,
  supplierList,
  equipmentTypeList,
  watch,
  setServerErrors = () => {},
}) => {
  const dispatch = useDispatch();
  const { isLoading, productDetails } = useSelector(
    (state) => state.productCatalogue.edit
  );

  // Get equipment class list
  const { equipmentClassDropdownLoading, equipmentClassDropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'equipmentClassDropdownList',
      customNameForDropdown: 'equipmentClass',
      labelName: 'name',
      valueName: 'id',
    });

  // Get category levels 1 list
  const { categoryLevels1DropdownLoading, categoryLevels1DropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'categoryLevels1DropdownList',
      customNameForDropdown: 'categoryLevels1',
      labelName: 'name',
      valueName: 'id',
      isOther: 'is_other',
    });

  // Get category levels 2 list
  const { categoryLevels2DropdownLoading, categoryLevels2DropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'categoryLevels2DropdownList',
      customNameForDropdown: 'categoryLevels2',
      labelName: 'name',
      valueName: 'id',
      isOther: 'is_other',
    });

  // Get category levels 3 list
  const { categoryLevels3DropdownLoading, categoryLevels3DropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'categoryLevels3DropdownList',
      customNameForDropdown: 'categoryLevels3',
      labelName: 'name',
      valueName: 'id',
      isOther: 'is_other',
    });

  // Equipment class errors
  const { error: equipmentClassDropdownError } = useSelector(
    (state) => state.equipmentTypes.equipmentClassDropdownList
  );

  // Category levels errors
  const { error: categoryLevels1DropdownError } = useSelector(
    (state) => state.equipmentTypes.categoryLevels1DropdownList
  );
  const { error: categoryLevels2DropdownError } = useSelector(
    (state) => state.equipmentTypes.categoryLevels2DropdownList
  );
  const { error: categoryLevels3DropdownError } = useSelector(
    (state) => state.equipmentTypes.categoryLevels3DropdownList
  );

  // Product Enhancement Data
  const { data: productEnhancementData } = useSelector(
    (state) => state.productCatalogue.productEnhancementData
  );

  const { t } = useTranslation();
  const clearHandler = (name) => setValue(name, '');
  const labels = (label) => t(`label.superAdmin.${label}`);

  const findLabelByValue = (list, value) => {
    const item = list.find((option) => option.value === value);

    return item ? { label: item.label, value: item.value, ...item } : null;
  };

  useEffect(() => {
    if (id) {
      dispatch(getProductDetailsById(id));
    }

    return () => {
      dispatch(resetEquipmentClassDropdownList());
      dispatch(resetCategoryLevels1DropdownList());
      dispatch(resetCategoryLevels2DropdownList());
      dispatch(resetCategoryLevels3DropdownList());
      dispatch(resetProductEnhancementData());
      dispatch(resetProductDetails());
      reset(defaultValuesProductCatalogueForm);
    };
  }, []);

  useEffect(() => {
    if (!isLoading && productDetails && id) {
      reset({
        uuid: productDetails?.uuid,
        product_name: productDetails?.product_name,
        type: findLabelByValue(PRODUCT_TYPES, productDetails?.type),
        ...(productDetails?.type !== PRODUCT_TYPES_LABEL.EQUIPMENT && {
          equipment_type:
            productDetails?.equipment_type?.map((et) =>
              findLabelByValue(equipmentTypeList, et)
            ) || [],
        }),
        ...(productDetails?.type === PRODUCT_TYPES_LABEL.EQUIPMENT && {
          equipment_type:
            productDetails?.equipment_type?.map((et) =>
              findLabelByValue(equipmentTypeList, et)
            )?.[0] || null,

          equipment_class: {
            label: productDetails?.equipment_class?.name,
            value: productDetails?.equipment_class?.id,
          },

          category_level1: {
            label: productDetails?.product?.name,
            value: productDetails?.product?.id,
          },

          ...(productDetails?.productDetails?.category2_rel && {
            category_level2: {
              label: productDetails?.productDetails?.category2_rel?.name,
              value: productDetails?.productDetails?.category2_rel?.id,
              isOther: productDetails?.productDetails?.category2_rel?.is_other,
            },
          }),
          ...(productDetails?.productDetails?.category3_rel && {
            category_level3: {
              label: productDetails?.productDetails?.category3_rel?.name,
              value: productDetails?.productDetails?.category3_rel?.id,
              isOther: productDetails?.productDetails?.category3_rel?.is_other,
            },
          }),
          ...(productDetails?.other_category_name && {
            category_name: productDetails?.other_category_name,
          }),
        }),
        description: productDetails?.description,
        sell_price: formatPriceWithDecimalValue(productDetails?.sell_price),
        code: productDetails?.code,
        supplier_uuid: findLabelByValue(
          supplierList,
          productDetails?.supplier_uuid
        ),
        um: findLabelByValue(UNIT_OF_MEASURE, productDetails?.um),
        cost: formatPriceWithDecimalValue(productDetails?.cost),
        estimated_time_hours: productDetails?.estimated_time_hours,
        active: productDetails?.status === TAB_STATUS.active,
      });
    }
  }, [isLoading, productDetails]);

  const selectedType = useWatch({
    control,
    name: 'type',
    defaultValue: '',
  });

  const selectedEquipmentType = useWatch({
    control,
    name: 'equipment_type',
    defaultValue: '',
  });

  const selectedEquipmentClass = useWatch({
    control,
    name: 'equipment_class',
    defaultValue: '',
  });

  const selectedCategoryLevel1 = useWatch({
    control,
    name: 'category_level1',
    defaultValue: '',
  });

  const selectedCategoryLevel2 = useWatch({
    control,
    name: 'category_level2',
    defaultValue: '',
  });

  const selectedCategoryLevel3 = useWatch({
    control,
    name: 'category_level3',
    defaultValue: '',
  });

  const categoryName = useWatch({
    control,
    name: 'category_name',
    defaultValue: '',
  });

  useEffect(() => {
    if (selectedEquipmentType?.label) {
      dispatch(
        getEquipmentClassDropdown({
          equipment_type_id: selectedEquipmentType?.id,
          status: true,
        })
      );
    }
  }, [selectedEquipmentType]);

  useEffect(() => {
    if (selectedEquipmentClass?.label) {
      const categoryLevels = [
        {
          ecid: selectedEquipmentClass?.value,
          parent_cid: null,
          current_category: 1,
        },
      ];

      categoryLevels.forEach((level) => {
        dispatch(getCategoryLevelsDropdown(level));
      });
    }
  }, [selectedEquipmentClass]);

  useEffect(() => {
    if (selectedEquipmentType?.label && categoryLevels1DropdownError) {
      dispatch(
        getProductEnhancement({
          equipment_types: selectedEquipmentType?.id,
          ecid: selectedEquipmentClass?.value || null,
          category1: selectedCategoryLevel1?.value || null,
          is_ecid_null: !selectedEquipmentClass?.label,
          is_category1_null: !selectedCategoryLevel1?.label,
        })
      );
    }
  }, [selectedEquipmentType, categoryLevels1DropdownError]);

  useEffect(() => {
    if (productEnhancementData?.data?.length && selectedEquipmentClass?.label) {
      setValue('product_name', productEnhancementData.data[0]?.display_name);
    }
  }, [productEnhancementData]);

  useEffect(() => {
    setServerErrors((prev) =>
      equipmentClassDropdownError?.status === 404
        ? [
            ...prev,
            {
              field: 'equipment_class',
              message: t(
                'attributes.purchase_order.pleaseChooseRightCombination'
              ),
            },
          ]
        : prev?.filter((error) => error?.field !== 'equipment_class')
    );
  }, [equipmentClassDropdownError]);

  const renderFormBasedOnType = (isRenderName = false) => {
    if (isRenderName) {
      switch (selectedType?.value) {
        case PRODUCT_TYPES_LABEL.MATERIAL:
        case PRODUCT_TYPES_LABEL.EXPENSE:
        case PRODUCT_TYPES_LABEL.LABOUR:
          return renderProductName();
      }
    } else {
      switch (selectedType?.value) {
        case PRODUCT_TYPES_LABEL.EQUIPMENT:
          return equipementForm;
        case PRODUCT_TYPES_LABEL.MATERIAL:
        case PRODUCT_TYPES_LABEL.EXPENSE:
          return materialExpenseForm;
        case PRODUCT_TYPES_LABEL.LABOUR:
          return labourForm;
      }
    }
  };

  useDidMount(() => {
    if (!selectedEquipmentClass?.label) {
      setValue('product_name', '');

      return;
    }

    const parts = [
      selectedEquipmentClass?.label || '',
      selectedCategoryLevel1?.label || '',
    ].filter(Boolean);

    setValue('product_name', parts.length ? parts.join(' - ') : '');
    trigger('product_name');
  }, [selectedEquipmentClass, selectedCategoryLevel1]);

  const renderProductName = (isDisable = false) => (
    <Controller
      name="product_name"
      control={control}
      rules={{
        required: `${t('attributes.name')} ${Validation.general.required}`,
      }}
      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
        <CustomTextField
          label={t('attributes.name')}
          fullWidth
          value={value || ''}
          onChange={(e) => {
            onChange(e);
            trigger('product_name');
          }}
          disabled={isDisable}
          helperText={error ? error.message : ''}
          error={error}
          onClear={() => clearHandler(name)}
        />
      )}
    />
  );

  const materialExpenseForm = (
    <>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.productCatalogue.product_details')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="equipment_type"
            control={control}
            rules={{
              required: `${t('attributes.property.assetGroup')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                multiple
                options={equipmentTypeList}
                label={t('attributes.property.assetGroup')}
                onChange={(e, newValue) => {
                  if (newValue || newValue === null) {
                    onChange(newValue);
                  }
                  trigger('equipment_type');
                }}
                value={value}
                error={error}
                helperText={error ? error.message : ''}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={{
              maxLength: {
                value: 300,
                message: Validation.general.description,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                isRequired={false}
                label={t('attributes.productCatalogue.description')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('description');
                }}
                multiline
                rows={2}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Typography variant="body2">
            {t('attributes.productCatalogue.product_detail_description_note')}
          </Typography>
          <Controller
            name="sell_price"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.default_sell_price')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.productCatalogue.default_sell_price')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('sell_price');
                }}
                onBlur={() => {
                  // Format value on blur
                  if (value) {
                    const formattedValue = parseFloat(value).toFixed(2);

                    onChange(formattedValue);
                    trigger('sell_price');
                  }
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.productCatalogue.supplier_details')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="supplier_uuid"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.supplier')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={supplierList}
                label={t('attributes.productCatalogue.supplier')}
                onChange={(e, newValue) => {
                  if (newValue || newValue === null) {
                    onChange(newValue);
                  }
                }}
                value={value}
                error={error}
                helperText={error ? error.message : ''}
              />
            )}
          />
          <Controller
            name="cost"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.cost_price')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.productCatalogue.cost_price')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('cost');
                }}
                onBlur={() => {
                  // Format value on blur
                  if (value) {
                    const formattedValue = parseFloat(value).toFixed(2);

                    onChange(formattedValue);
                    trigger('cost');
                  }
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Controller
            name="um"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.unit_of_measure')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={UNIT_OF_MEASURE}
                label={t('attributes.productCatalogue.unit_of_measure')}
                onChange={(e, newValue) => {
                  if (newValue || newValue === null) {
                    onChange(newValue);
                  }
                }}
                value={value}
                error={error}
                helperText={error ? error.message : ''}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.productCatalogue.services_details')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="estimated_time_hours"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.productCatalogue.estimate_time')}
                fullWidth
                value={value}
                isRequired={false}
                onChange={(e) => {
                  const inputValue = e.target.value;

                  if (/^\d*\.?\d*$/.test(inputValue)) {
                    // Allow only decimal input
                    onChange(inputValue); // Pass the valid value to the controller
                    trigger('estimated_time_hours');
                  }
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Typography variant="body2">
            {t('attributes.productCatalogue.esitmated_time_note')}
          </Typography>
        </FormFieldWrapper>
      </CustomCard>
    </>
  );

  const equipementForm = (
    <>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.productCatalogue.product_details')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="equipment_type"
            control={control}
            rules={{
              required: `${t('attributes.property.assetGroup')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={equipmentTypeList}
                label={t('attributes.property.assetGroup')}
                onChange={(e, newValue) => {
                  if (newValue || newValue === null) {
                    onChange(newValue);
                  }

                  trigger('equipment_type');
                  setTimeout(() => {
                    dispatch(resetEquipmentClassDropdownList());
                    dispatch(resetCategoryLevels1DropdownList());
                    setValue('equipment_class', null);
                    setValue('category_level1', null);
                    setValue('category_name', '');
                    setValue('product_name', '');
                  }, 10);
                }}
                value={value}
                error={error}
                helperText={error ? error.message : ''}
              />
            )}
          />
          <Controller
            name="equipment_class"
            control={control}
            rules={{
              required: `${t('attributes.property.assetType')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                label={t('attributes.property.assetType')}
                options={equipmentClassDropdownData}
                value={value}
                onChange={(e, newValue) => {
                  if (newValue || newValue === null) {
                    onChange(newValue);
                  }
                  trigger('equipment_class');
                  dispatch(resetCategoryLevels1DropdownList());
                  reset({
                    ...watch(),
                    category_level1: null,
                    category_name: '',
                    product_name: '',
                  });
                }}
                isLoadingData={equipmentClassDropdownLoading}
                error={error}
                helperText={error ? error.message : ''}
              />
            )}
          />
          {selectedEquipmentClass && categoryLevels1DropdownData?.length ? (
            <Controller
              name="category_level1"
              control={control}
              rules={{
                required: `${t('attributes.property.assetVariant')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={t('attributes.property.assetVariant')}
                  options={categoryLevels1DropdownData}
                  value={value}
                  onChange={(e, newValue) => {
                    if (newValue || newValue === null) {
                      onChange(newValue);
                    }
                    trigger('category_level1');
                    reset({
                      ...watch(),
                      category_name: '',
                      product_name: '',
                    });
                  }}
                  isLoadingData={categoryLevels1DropdownLoading}
                  error={error}
                  helperText={error ? error.message : ''}
                />
              )}
            />
          ) : null}
          {selectedCategoryLevel1?.isOther && (
            <Controller
              name="category_name"
              control={control}
              rules={{
                required: `${t('attributes.productCatalogue.asset')} ${t(`attributes.productCatalogue.categoryLevel${selectedCategoryLevel1?.isOther ? 1 : selectedCategoryLevel2?.isOther ? 2 : 3}`)} ${t('attributes.name')} ${Validation.general.required}`,
                maxLength: {
                  value: 80,
                  message: Validation.general.contactName,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={`${t('attributes.productCatalogue.asset')} ${t(`attributes.productCatalogue.categoryLevel${selectedCategoryLevel1?.isOther ? 1 : selectedCategoryLevel2?.isOther ? 2 : 3}`)} ${t('attributes.name')}`}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('category_name');
                    trigger('product_name');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
          )}
          {renderProductName(true)}
          <Controller
            name="description"
            control={control}
            rules={{
              maxLength: {
                value: 300,
                message: Validation.general.description,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                isRequired={false}
                label={t('attributes.productCatalogue.description')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('description');
                }}
                multiline
                rows={2}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Typography variant="body2">
            {t('attributes.productCatalogue.product_detail_description_note')}
          </Typography>
          <Controller
            name="sell_price"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.default_sell_price')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.productCatalogue.default_sell_price')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('sell_price');
                }}
                onBlur={() => {
                  // Format value on blur
                  if (value) {
                    const formattedValue = parseFloat(value).toFixed(2);

                    onChange(formattedValue);
                    trigger('sell_price');
                  }
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.productCatalogue.supplier_details')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="supplier_uuid"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.supplier')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={supplierList}
                label={t('attributes.productCatalogue.supplier')}
                onChange={(e, newValue) => {
                  if (newValue || newValue === null) {
                    onChange(newValue);
                  }
                }}
                value={value}
                error={error}
                helperText={error ? error.message : ''}
              />
            )}
          />
          <Controller
            name="cost"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.cost_price')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.productCatalogue.cost_price')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('cost');
                }}
                onBlur={() => {
                  // Format value on blur
                  if (value) {
                    const formattedValue = parseFloat(value).toFixed(2);

                    onChange(formattedValue);
                    trigger('cost');
                  }
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Controller
            name="um"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.unit_of_measure')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={UNIT_OF_MEASURE}
                label={t('attributes.productCatalogue.unit_of_measure')}
                onChange={(e, newValue) => {
                  if (newValue || newValue === null) {
                    onChange(newValue);
                  }
                }}
                value={value}
                error={error}
                helperText={error ? error.message : ''}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.productCatalogue.services_details')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="estimated_time_hours"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.productCatalogue.estimate_time')}
                fullWidth
                value={value}
                isRequired={false}
                onChange={(e) => {
                  onChange(e);
                  trigger('estimated_time_hours');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Typography variant="body2">
            {t('attributes.productCatalogue.esitmated_time_note')}
          </Typography>
        </FormFieldWrapper>
      </CustomCard>
    </>
  );

  const labourForm = (
    <CustomCard>
      <Box sx={{ padding: '16px' }}>
        <Typography variant="body1">{`${t('attributes.productCatalogue.labour_details')}`}</Typography>
      </Box>
      <Divider />
      <FormFieldWrapper>
        <Controller
          name="cost"
          control={control}
          rules={{
            required: `${t('attributes.productCatalogue.cost_price')} ${Validation.general.required}`,
          }}
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }) => (
            <CustomTextField
              label={t('attributes.productCatalogue.cost_price')}
              fullWidth
              value={value}
              onChange={(e) => {
                onChange(e);
                trigger('cost');
              }}
              helperText={error ? error.message : ''}
              error={error}
              onClear={() => clearHandler(name)}
            />
          )}
        />
        <Typography variant="body2">
          {t('attributes.productCatalogue.labour_details_cost_price_note')}
        </Typography>
        <Controller
          name="sell_price"
          control={control}
          rules={{
            required: `${t('attributes.productCatalogue.default_sell_price')} ${Validation.general.required}`,
          }}
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }) => (
            <CustomTextField
              label={t('attributes.productCatalogue.default_sell_price')}
              fullWidth
              value={value}
              onChange={(e) => {
                onChange(e);
                trigger('sell_price');
              }}
              helperText={error ? error.message : ''}
              error={error}
              onClear={() => clearHandler(name)}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          rules={{
            maxLength: {
              value: 300,
              message: Validation.general.description,
            },
          }}
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }) => (
            <CustomTextField
              label={t('attributes.productCatalogue.description')}
              fullWidth
              isRequired={false}
              value={value}
              onChange={(e) => {
                onChange(e);
                trigger('description');
              }}
              multiline
              rows={2}
              helperText={error ? error.message : ''}
              error={error}
              onClear={() => clearHandler(name)}
            />
          )}
        />
      </FormFieldWrapper>
    </CustomCard>
  );

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.productCatalogue.product_type')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="type"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.type')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={PRODUCT_TYPES}
                label={t('attributes.productCatalogue.type')}
                onChange={(e, newValue) => {
                  if (newValue || newValue === null) {
                    onChange(newValue);
                  }
                  dispatch(resetEquipmentClassDropdownList());
                  dispatch(resetCategoryLevels1DropdownList());
                  dispatch(resetCategoryLevels2DropdownList());
                  dispatch(resetCategoryLevels3DropdownList());

                  reset({
                    ...watch(),
                    sell_price: '',
                    cost: '',
                    description: '',
                    product_name: '',
                    equipment_type: '',
                    equipment_class: '',
                    category_level1: '',
                    category_level2: '',
                    category_level3: '',
                    category_name: '',
                  });
                  setTimeout(() => {
                    reset({
                      ...watch(),
                      sell_price: '',
                      cost: '',
                      description: '',
                      product_name: '',
                      equipment_type: null,
                      equipment_class: null,
                      category_level1: null,
                      category_level2: null,
                      category_level3: null,
                      category_name: '',
                      supplier_uuid: '',
                      um: '',
                      estimated_time_hours: '',
                    });
                  }, 0);
                }}
                value={value}
                error={error}
                helperText={error ? error.message : ''}
              />
            )}
          />
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography variant="body2">
              <b>{t('attributes.productCatalogue.materials')}</b>{' '}
              {t('attributes.productCatalogue.materials_detail_text')}
            </Typography>
            <Typography variant="body2">
              <b>{t('attributes.productCatalogue.equipment')}</b>{' '}
              {t('attributes.productCatalogue.equipment_details_text')}
            </Typography>
            <Typography variant="body2">
              <b>{t('attributes.productCatalogue.expenses')}</b>{' '}
              {t('attributes.productCatalogue.expenses_details_text')}
            </Typography>
            <Typography variant="body2">
              <b>{t('attributes.productCatalogue.labour')}</b>{' '}
              {t('attributes.productCatalogue.labour_details_text')}
            </Typography>
          </Box>
          {renderFormBasedOnType(true)}
        </FormFieldWrapper>
      </CustomCard>
      {renderFormBasedOnType()}
      {id && (
        <CustomCard>
          <Box sx={{ padding: '0 16px 0 16px' }}>
            <CommonCheckbox
              control={control}
              name="active"
              label={t('common.active')}
              isRequired={false}
              showMessage={true} // Enable message display
              messageOnChecked={labels('productActiveCheckbox')}
              messageOnUnchecked={labels('productInactiveCheckbox')}
            />
          </Box>
        </CustomCard>
      )}
    </FormWrapper>
  );
};
