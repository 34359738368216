import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Modal, styled, Typography } from '@mui/material';

import { DIVIDER_COLOR } from '../../constants/Colors';
import { Validation } from '../../constants/FieldValidationMsg';
import CustomButton from '../CommonComponents/CustomButton';
import CustomTextField from '../CommonComponents/CustomTextField';

const FooterButton = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '7px 16px 7px 16px',
  columnGap: '16px',
});

const TitleStyled = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px',
  borderRadius: '4px 4px 0 0',
  color: 'white',
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '4px',
};

const AssetVariantForm = ({ isOpen, onClose, onSubmit, disabled }) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      asset_variant_name: '',
    },
  });

  const handleAdd = (data) => {
    onSubmit(data, reset);
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Modal open={isOpen}>
      <Box sx={style}>
        <TitleStyled>
          <Typography variant="body2">
            {t('attributes.property.addAssetVariant')}
          </Typography>
          <CloseIcon
            onClick={!disabled ? handleClose : undefined}
            style={{ cursor: 'pointer' }}
          />
        </TitleStyled>
        <Box
          sx={{
            borderRadius: '4px',
            margin: '16px',
            border: `1px solid ${DIVIDER_COLOR}`,
          }}
        >
          <Typography sx={{ padding: '12px 16px' }} variant="body1">
            {t('attributes.property.assetVariantDetails')}
          </Typography>
          <Divider />
          <Box sx={{ padding: '16px' }}>
            <Controller
              name="asset_variant_name"
              control={control}
              rules={{
                required: `${t('attributes.property.assetVariantName')} ${Validation.general.required}`,
              }}
              render={({ field }) => (
                <CustomTextField
                  label={t('attributes.property.assetVariantName')}
                  fullWidth
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.asset_variant_name}
                  helperText={errors.asset_variant_name?.message}
                />
              )}
            />
          </Box>
        </Box>
        <Divider />
        <FooterButton>
          <CustomButton
            startIcon={<AddIcon />}
            text={t('attributes.add')}
            onClick={handleSubmit(handleAdd)}
            disabled={disabled}
          />
        </FooterButton>
      </Box>
    </Modal>
  );
};

export default AssetVariantForm;
