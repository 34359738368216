import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Box, Typography } from '@mui/material';
import { debounce } from 'lodash';

import { exportToCSV } from '../../../constants/common';
import {
  INVITATION_STATUS,
  INVITATION_STATUS_DISPLAY,
  INVITED_STATUS,
  ROUTE_NAVIGATE_CONST,
  STATUS_COLOR_MAP,
  TAB_STATUS,
} from '../../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  COLUMNS_TO_MAP,
  EXPORT_FILE_NAMES,
} from '../../../constants/ExportConstant';
import {
  OFFICE_MANAGEMENT,
  OFFICE_STAFF,
  SUPER_ADMIN,
} from '../../../constants/Roles';
import { SWIPEABLE_DRAWER_STEPPER_WIDTH } from '../../../constants/Typography';
import getCityByStateHook from '../../../hooks/getCityByStateHook';
import getDropdownListHook from '../../../hooks/getDropdownListHook';
import getStatesHook from '../../../hooks/getStatesHook';
import useBrowserBackButtonHandler from '../../../hooks/useBrowserBackButtonHandler';
import useServerSideErrors from '../../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../../store/CommonReducer';
import { resetDropdown } from '../../../store/company/reducer';
import { getUsersList } from '../../../store/userManagement/api';
import { getCustomerList } from '../../../store/users/customer/api';
import {
  resetCreate,
  resetPagination,
} from '../../../store/users/customer/reducer';
import {
  checkIsFiltersApplied,
  formattedDate,
  getRedirectURL,
  loggedInUserDetail,
  loggedInUserRole,
  showHideTopbarLoader,
} from '../../../utils';
import { Edit } from '../../CommonComponents/ActionComponent';
import Autocomplete from '../../CommonComponents/AutoComplete';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../../CommonComponents/FilterComponent';
import { ConfirmationModal } from '../../CommonComponents/Modal';
import NewNoDataPage from '../../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import TabView from '../../CommonComponents/TabView';
import CustomerForm from './CustomerForm';

const listColumnNames = {
  customer_name: 'customer_name',
  address: 'address',
  categories: 'categories',
  agreement_signed_date: 'agreement_signed_date',
  contact_name: 'contact_name',
  contact_email: 'contact_email',
  created_at: 'created_at',
  invitation_status: 'invitation_status',
  edit: 'edit',
};

//default filters
const defaultFilters = {
  city: null,
  state: null,
  accoutnManager: null,
};
const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

const Customer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const method = useForm();
  const { setError, clearErrors } = method;

  const { customerActiveList, customerInactiveList, total, totalCount } =
    useSelector((state) => state.customer.get);
  const {
    isLoading: createCustomerLoading,
    createdCustomer,
    error,
  } = useSelector((state) => state.customer.create);
  const {
    isLoading: updateCustomerLoading,
    updatedCustomer,
    error: updateCustomerError,
  } = useSelector((state) => state.customer.update);

  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [serverErrors, setServerErrors] = useState([]);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');

  const [open, setOpen] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [hasFormValues, sethasFormValues] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [activeTab, setActiveTab] = useState(0);
  const [customerId, setCustomerId] = useState('');
  const [formMode, setFormMode] = useState('create');

  const [filters, setFilters] = useState(defaultFilters);

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState(null);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [moveToBillingCard, setMoveToBillingCard] = useState(null);
  const isFirstRender = useRef(true);
  const cityList = getCityByStateHook(filters.state?.value);
  const stateList = getStatesHook();
  const {
    customerDropdownLoading: categoryDropdownLoading,
    customerDropdownData: categoryDropdownData,
  } = getDropdownListHook({
    reducerName: 'customer',
    dropdownListName: 'customerCategoryDropdownList',
    labelName: 'name',
    valueName: 'uuid',
  });

  const { userManagerDropdownLoading, userManagerDropdownData } =
    getDropdownListHook({
      reducerName: 'userManagement',
      dropdownListName: 'get',
      labelName: 'user_name',
      fetchDataFrom: 'usersList',
      customNameForDropdown: 'userManager',
      valueName: 'uuid',
    });

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  useBrowserBackButtonHandler({
    hasUnsavedChanges: hasFormValues,
    setOpenConfirmationModal,
    open,
    watchMethod: method.watch,
    sethasFormValues,
  });

  const navigate = useNavigate();

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'customer_name',
        headerName: t('attributes.customer.customer_name'),
        flex: 1,
        // renderCell: ({ row }) => (
        //   <Typography
        //     variant="body1"
        //     sx={{ textDecoration: 'underline', cursor: 'pointer' }}
        //     onClick={() => {
        //       navigate(
        //         getRedirectURL()[loggedInUserRole()][
        //           `${ROUTE_NAVIGATE_CONST.CONTACT}`
        //         ] + `/${row?.contact_uuid}`
        //       );
        //     }}
        //   >
        //     {row.customer_name}
        //   </Typography>
        // ),
      },
      // {
      //   field: 'address',
      //   headerName: t('attributes.customer.registered_address'),
      //   flex: 1.5,
      //   sortable: false,
      // },
      {
        field: 'agreement_signed_date',
        headerName: t('attributes.customer.agreement_signed_date'),
        flex: 1.3,
        sortable: false,
        valueFormatter: (params) => (params ? formattedDate(params) : ''),
      },
      {
        field: 'contact_name',
        headerName: t('attributes.customer.contact_name'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Typography
            variant="body1"
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              navigate(
                getRedirectURL()[loggedInUserRole()][
                  `${ROUTE_NAVIGATE_CONST.CONTACT}`
                ] + `/${row?.contact_uuid}`
              );
            }}
          >
            {row.contact_name}
          </Typography>
        ),
      },
      // {
      //   field: 'contact_email',
      //   headerName: t('attributes.customer.contact_email'),
      //   flex: 1.5,
      //   sortable: false,
      // },
      {
        field: 'created_at',
        headerName: t('attributes.customer.created_at'),
        flex: 1,
        valueFormatter: (params) => (params ? formattedDate(params) : ''),
      },
      {
        field: 'invitation_status',
        headerName: t('attributes.customer.invitation_status'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formattedStatus(row.invitation_status),
      },
      {
        field: 'edit',
        headerName: t('attributes.edit'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Edit
            onClick={() => {
              setCustomerId(row?.uuid);
              setOpen(true);
              setFormMode('edit');
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  const formattedStatus = (status) => {
    const statusKey = Object.keys(INVITATION_STATUS).find(
      (key) => INVITATION_STATUS[key] === status
    );
    const statusColor = STATUS_COLOR_MAP[statusKey];

    return statusColor ? handleStatus(status, statusColor) : null;
  };

  const handleStatus = (status, color) => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          bgcolor: color,
          height: '16px',
          width: '16px',
          borderRadius: '50%',
        }}
      />
      <Typography
        sx={{
          color: color,
          fontSize: '12px',
          weight: '600',
          marginLeft: '8px',
        }}
      >
        {INVITATION_STATUS_DISPLAY[status]}
      </Typography>
    </Box>
  );

  // Function to fetch data based on search, pagination and filter
  const getAllCustomer = useCallback(() => {
    setSelectedRows(null);
    const visibleFieldsString = [
      ...columns
        .filter((col) => columnVisibilityModel[col.field] !== false)
        .map((col) => col.field),
      'state',
      'city',
    ].join(',');

    setIsDataLoading(true);
    dispatch(
      getCustomerList({
        active: activeTab === 0 ? TAB_STATUS.active : TAB_STATUS.inactive,
        page_size: perPageData,
        page: currentPage,
        search: searchText,
        order: order,
        orderBy: orderBy,
        company: filters.company?.label,
        state: filters.state?.value,
        city: filters.city?.value,
        account_manager: filters?.accoutnManager?.value,
        list_column_names: visibleFieldsString,
      })
    ).finally(() => {
      setIsDataLoading(false);
      setIsInitialLoading(false);
    });
  }, [
    dispatch,
    activeTab,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
    refresh,
  ]);

  useEffect(() => {
    const defaultColumnVisibilityModel = listColumnNames;

    columns.forEach((column) => {
      defaultColumnVisibilityModel[column.field] = true;
    });
    setColumnVisibilityModel(defaultColumnVisibilityModel);
  }, [activeTab]);

  useEffect(
    () => () => {
      if (!isFirstRender.current) {
        !moveToBillingCard && dispatch(resetCreate());
        dispatch(resetPagination());
        dispatch(resetDropdown());
        showHideTopbarLoader(false, dispatch);
        isFirstRender.current = true;
      }
      isFirstRender.current = false;
    },
    [moveToBillingCard]
  );

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllCustomer, 500), [
    getAllCustomer,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    createdCustomer?.data && setCustomerId(createdCustomer.data[0]?.uuid);
  }, [createdCustomer]);

  useEffect(() => {
    dispatch(
      getUsersList({
        active: true,
        limit: -1,
        role: `${SUPER_ADMIN},${OFFICE_MANAGEMENT},${OFFICE_STAFF}`,
        company_uuid: loggedInUserDetail()?.company_uuid,
      })
    );
  }, []);

  useEffect(() => {
    showHideTopbarLoader(createCustomerLoading, dispatch);
    if (createCustomerLoading !== null && !createCustomerLoading) {
      if (error) {
        if (error.errorDetails) {
          setServerErrors(error.errorDetails);
        } else {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: error.message,
            })
          );
        }
      } else {
        if (createdCustomer) {
          const closeModel =
            createdCustomer?.data[0]?.invitation_status === INVITED_STATUS;

          if (!customerId) setActiveTab(0);
          closeModel && setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: createdCustomer.message,
            })
          );
          if (moveToBillingCard) {
            navigate(
              getRedirectURL()[loggedInUserRole()][
                ROUTE_NAVIGATE_CONST.CUSTOMER_BILLING
              ] + '?open=true'
            );
          }
        }
      }
    }
  }, [error, createCustomerLoading, moveToBillingCard]);

  useEffect(() => {
    showHideTopbarLoader(updateCustomerLoading, dispatch);
    if (updateCustomerLoading !== null && !updateCustomerLoading) {
      if (updateCustomerError) {
        if (updateCustomerError.errorDetails) {
          setServerErrors(updateCustomerError.errorDetails);
        } else {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: updateCustomerError.message,
            })
          );
        }
      } else {
        if (updatedCustomer) {
          const closeModel =
            updatedCustomer?.data[0]?.invitation_status === INVITED_STATUS;

          if (!customerId) setActiveTab(0);
          closeModel && setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: updatedCustomer.message,
            })
          );
        }
      }
    }
  }, [updateCustomerError, updateCustomerLoading]);

  const rows =
    activeTab === 0
      ? customerActiveList?.map((item) => {
          const updatedItem = {
            ...item,
            export_id: item.id,
            customer_name: item.customer_name,
            address: [
              item.address,
              item.address_line_2,
              item.city,
              item.state,
              item.postcode,
            ]
              .filter(Boolean) // Removes null, undefined, and empty strings
              .join(', '),
            categories: item.categories?.length
              ? item.categories.join(', ')
              : '-',
            agreement_signed_date: item.agreement_signed_date,
            contact_name: item?.contact_name || '-',
            created_at: item.created_at,
            invitation_status: INVITATION_STATUS[item.invitation_status],
            billing_contact_name: item?.billing?.billing_contact_name || '',
            billing_email_to: item?.billing?.email_to || '',
            account_manager_name:
              userManagerDropdownData.filter(
                (accountManager) =>
                  accountManager.value === item?.account_managers_uuid?.[0] ||
                  ''
              )?.[0]?.label || '',
          };

          return updatedItem;
        })
      : customerInactiveList?.map((item) => {
          const updatedItem = {
            ...item,
            export_id: item.id,
            customer_name: item.customer_name,
            address: [
              item.address,
              item.address_line_2,
              item.city,
              item.state,
              item.postcode,
            ]
              .filter(Boolean) // Removes null, undefined, and empty strings
              .join(', '),
            categories: item.categories?.length
              ? item.categories.join(', ')
              : '-',
            agreement_signed_date: item.agreement_signed_date,
            contact_name: item?.contact_name || '-',
            created_at: item.created_at,
            invitation_status: INVITATION_STATUS[item.invitation_status],
            billing_contact_name: item?.billing?.billing_contact_name || '',
            billing_email_to: item?.billing?.email_to || '',
            account_manager_name:
              userManagerDropdownData.filter(
                (accountManager) =>
                  accountManager.value === item?.account_managers_uuid?.[0] ||
                  ''
              )?.[0]?.label || '',
          };

          return updatedItem;
        });

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };
  const resetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  const onTabChange = () => {
    setIsDataLoading(true);
    setFilters(defaultFilters);
    setSearchText('');
    setIsFilterComponentVisible(false);
  };

  const noData = (
    <NewNoDataPage
      icon={<PersonOutlineOutlinedIcon />}
      title={t('attributes.customer.customers')}
      createBtnText={t('attributes.customer.add_customer')}
      singularText="customer"
      filterHeight={filterHeight(isFilterComponentVisible)}
    />
  );

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        searchText={searchText}
        isRefresh={true}
        onRefreshFilter={() => setRefresh(!refresh)}
        onSearchChange={(e) => {
          setSearchText(e.target.value);
        }}
        isResetButtonVisible={
          searchText ||
          filters.state?.value ||
          filters.city?.value ||
          filters.accoutnManager?.value
        }
        onResetFilter={resetFilter}
        isActive={isFilterComponentVisible}
        isFilterDisable={checkIsFiltersApplied(filters)}
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder={t('common.state')}
            options={stateList}
            value={filters?.state}
            onChange={(e, newVal) => {
              if (newVal || newVal === null) {
                setFilters((prev) => ({ ...prev, state: newVal, city: null }));
              }
            }}
            width="190px"
          />
          <Autocomplete
            placeholder={t('common.city')}
            options={cityList}
            value={filters?.city}
            onChange={(e, newVal) => {
              if (newVal || newVal === null) {
                setFilters((prev) => ({ ...prev, city: newVal }));
              }
            }}
            width="190px"
            disabledDropdown={!filters.state}
          />
          <Autocomplete
            placeholder={t('attributes.account_manager.account_manager')}
            options={userManagerDropdownData}
            value={filters?.accoutnManager}
            onChange={(e, newVal) => {
              if (newVal || newVal === null) {
                setFilters((prev) => ({ ...prev, accoutnManager: newVal }));
              }
            }}
            width="190px"
            isLoadingData={userManagerDropdownLoading}
          />
        </FilterComponent>
      )}
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        isLoading={isDataLoading}
        noData={<NoRecordFound />}
        onRowSelectionModelChange={handleSelectionChange}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  const mainWrapper = (
    <StyledMainWrapper
      title={t('attributes.customer.customers')}
      btn={
        <>
          <CustomButton
            text={t('attributes.customer.add_customer')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => {
              setOpen(true);
              setCustomerId('');
              setFormMode('create');
            }}
          />
          <CustomButton
            text={t('attributes.export')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<OpenInNewIcon />}
            onClick={() => {
              if (!selectedRows || selectedRows?.length === 0) {
                dispatch(
                  snackbarToggle({
                    isOpen: true,
                    isErrorMsg: true,
                    msg: t('message.common.noRecordExportMessage'),
                  })
                );
                setSelectedRows(null);
              } else {
                exportToCSV(
                  selectedRows,
                  COLUMNS_TO_EXPORT.CUSTOMER,
                  EXPORT_FILE_NAMES.CUSTOMER,
                  COLUMNS_TO_MAP.CUSTOMER
                );
              }
            }}
          />
        </>
      }
    >
      {!isDataLoading && totalCount === 0 && noData}
      {isInitialLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            minHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
            maxHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isInitialLoading &&
        totalCount > 0 && (
          <TabView
            tabs={[
              {
                label: t('common.active'),
                component: renderedComponent,
              },
              {
                label: t('common.inactive'),
                component: renderedComponent,
              },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onTabChange={onTabChange}
          />
        )
      )}
    </StyledMainWrapper>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
            sethasFormValues(false);

            if (window.history.state === null) {
              window.history.back();
            }
          }}
        />
      )}
      {mainWrapper}
      {open && (
        <SwipeableDrawer
          open={open}
          title={t(
            formMode === 'create'
              ? t('attributes.customer.add_customer')
              : `${t('attributes.edit')} ${t('attributes.customer.customer')}`
          )}
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          width={SWIPEABLE_DRAWER_STEPPER_WIDTH}
        >
          <FormProvider {...method}>
            <CustomerForm
              customerId={customerId}
              formMode={formMode}
              sethasFormValues={sethasFormValues}
              setServerErrors={setServerErrors}
              serverErrors={serverErrors}
              setMoveToBillingCard={setMoveToBillingCard}
            />
          </FormProvider>
        </SwipeableDrawer>
      )}
    </>
  );
};

export default Customer;
