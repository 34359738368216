import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Typography } from '@mui/material';

import { Page404 } from '../../assets/images';
import { ROUTE_NAVIGATE_CONST } from '../../constants/Constants';
import { getUserRole } from '../../hoc/Permission';
import { getRedirectURL } from '../../utils';
import CustomButton from '../CommonComponents/CustomButton';

const PageNotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userRole = getUserRole();
  const redirectPath =
    getRedirectURL()[userRole][ROUTE_NAVIGATE_CONST.DASHBOARD];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 300px',
        height: '100vh',
        background: 'white',
        textAlign: 'center',
      }}
    >
      <img
        src={Page404}
        alt="404 Page"
        style={{ height: '235px', width: '235px', objectFit: 'contain' }}
      />
      <Typography variant="h5">{t('errorPage.pageNotFoundTitle')}</Typography>
      <Typography variant="h1" fontWeight={400}>
        {t('errorPage.pageNotFoundDescription')}
      </Typography>
      <CustomButton
        variant="contained"
        color="primary"
        onClick={() => {
          navigate(redirectPath, { replace: true });
        }}
        startIcon={<KeyboardBackspaceIcon />}
      >
        {t('errorPage.goBack')}
      </CustomButton>
    </Box>
  );
};

export default PageNotFound;
