import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../constants/APIConstants';
import { DATE_RANGE_FORMAT_API } from '../../constants/Constants';
import { formatDateAPI } from '../../utils';
import { PROPERTIES } from '../actionPrefix';
import {
  axiosInstanceBilling,
  axiosInstanceCompany,
  axiosInstanceTechnician,
  axiosPropertyUrl,
} from '../axios';

export const getPropertyList = createAsyncThunk(
  PROPERTIES.GET_PROPERTIES,
  async (
    {
      page,
      search,
      size,
      status,
      tags,
      constructionClass,
      order,
      orderBy,
      fromDate,
      toDate,
      customerUuid,
      parentProperty,
      billingCard,
      accountManager,
      latest_compliance_status,
      list_column_names,
      state,
      city,
      limit,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Build the query string dynamically
    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (size) params.append('size', size);
    if (status) params.append('status_id', status);
    if (state) params.append('state', state);
    if (city) params.append('city', city);
    if (limit) params.append('limit', limit);
    if (customerUuid) params.append('customer_uuid', customerUuid);
    if (billingCard) params.append('billing_card_uuid', billingCard);
    if (parentProperty) params.append('parent_property_uuid', parentProperty);
    if (accountManager) params.append('account_manager_id', accountManager);
    if (latest_compliance_status)
      params.append('latest_compliance_status', latest_compliance_status);
    if (tags) params.append('tags_id', tags);
    if (constructionClass)
      params.append('construction_class_id', constructionClass);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy === 'pid' ? 'id' : orderBy);
    if (fromDate)
      params.append(
        'created_at__gte',
        formatDateAPI(moment(fromDate).format(DATE_RANGE_FORMAT_API))
      );
    if (toDate)
      params.append(
        'created_at__lte',
        formatDateAPI(moment(toDate).endOf('day').format(DATE_RANGE_FORMAT_API))
      );
    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.PROPERTIES.GET_PROPERTIES}?${params}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPropertyListForMap = createAsyncThunk(
  PROPERTIES.GET_PROPERTIES_MAP,
  async ({ page, size }, { rejectWithValue }) => {
    const params = new URLSearchParams();

    // Build the query string dynamically
    if (page) params.append('page', page);
    if (size) params.append('size', size);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.PROPERTIES.GET_PROPERTIES}?${params}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getProperty = createAsyncThunk(
  PROPERTIES.GET_PROPERTY,
  async (propertyUuid, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_PROPERTY.replace('{propertyUuid}', propertyUuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProperty = createAsyncThunk(
  PROPERTIES.UPDATE_PROPERTY,
  async ({ uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.put(
        APIs.PROPERTIES.UPDATE_PROPERTY.replace('{propertyUuid}', uuid),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createProperty = createAsyncThunk(
  PROPERTIES.CREATE_PROPERTY,
  async (preparedData, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.post(
        APIs.PROPERTIES.CREATE_PROPERTY,
        preparedData
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Below are property status apis

export const getPropertyStatusList = createAsyncThunk(
  PROPERTIES.GET_STATUS_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_STATUS_LIST
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Tags list api

export const getPropertyTagsList = createAsyncThunk(
  PROPERTIES.GET_TAG_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(APIs.PROPERTIES.GET_TAG_LIST);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Access schedule list api

export const getPropertyAccessScheduleList = createAsyncThunk(
  PROPERTIES.GET_ACCESS_SCHEDULE_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_ACCESS_SCHEDULE_LIST
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get access procedure list

export const getPropertyAccessProcedureList = createAsyncThunk(
  PROPERTIES.GET_ACCESS_PROCEDURE_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_ACCESS_PROCEDURE_LIST
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Building era list

export const getBuildingEraList = createAsyncThunk(
  PROPERTIES.GET_BUILDING_ERA_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_BUILDING_ERA_LIST
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get building class list

export const getBuildingClassList = createAsyncThunk(
  PROPERTIES.GET_BUILDING_CLASS_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_BUILDING_CLASS_LIST
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get construction class list
export const getConstructionClassList = createAsyncThunk(
  PROPERTIES.GET_CONSTRUCTION_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_CONSTRUCTION_LIST
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get building type list
export const getBuildingTypeList = createAsyncThunk(
  PROPERTIES.GET_BUILDING_TYPE_LIST,
  async (
    { page, search, size, order, orderBy, limit, active },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Build the query string dynamically
    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (active !== undefined || active !== null)
      params.append('is_active', active);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.PROPERTIES.GET_BUILDING_TYPE_LIST}?${params.toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// TODO: Not in use but will use it in future if required
// get building standard list
export const getBuildingStandardList = createAsyncThunk(
  PROPERTIES.GET_BUILDING_STANDARD_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_BUILDING_STANDARD_LIST
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get local government area list
export const getLocalGovernmentAreaList = createAsyncThunk(
  PROPERTIES.GET_LOCAL_GOVERNMENT_AREA_LIST,
  async (req, { rejectWithValue }) => {
    const request = {
      ...req,
      post_code: 'all',
      unique_zone: false,
      unique_local_government_area: true,
    };

    try {
      const response = await axiosPropertyUrl.post(
        APIs.PROPERTIES.GET_LOCAL_GOVERNMENT_AREA_LIST,
        request
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get local government area list
export const getParentPropertyList = createAsyncThunk(
  PROPERTIES.GET_PARENT_PROPERTY_LIST,
  async (
    { customerUuid, limit, active_billing_contract_uuid },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    if (limit) params.append('limit', limit);
    if (customerUuid) params.append('customer_uuid', customerUuid);
    if (active_billing_contract_uuid)
      params.append(
        'active_billing_contract_uuid',
        active_billing_contract_uuid
      );

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.PROPERTIES.GET_PARENT_PROPERTY_LIST}?${params}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get account manager list
export const getPropertyAccountManagerList = createAsyncThunk(
  PROPERTIES.GET_PROPERTY_ACCOUNT_MANAGER_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_PROPERTY_ACCOUNT_MANAGER_LIST
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get property customer list
export const getPropertyCustomerList = createAsyncThunk(
  PROPERTIES.GET_PROPERTY_CUSTOMER_LIST,
  async ({ type }, { rejectWithValue }) => {
    const params = new URLSearchParams();

    if (type) params.append('customer_type', type);

    params.append('size', -1);
    try {
      const response = await axiosInstanceCompany.get(
        `${APIs.PROPERTIES.GET_PROPERTY_CUSTOMER_LIST}?${params.toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get property billing card list for Add/Edit
export const getPropertyBillingCardList = createAsyncThunk(
  PROPERTIES.GET_PROPERTY_BILLING_CARD_LIST,
  async ({ limit, customer_uuid }, { rejectWithValue }) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (limit) params.append('limit', limit);
    if (customer_uuid) params.append('customer_uuid', customer_uuid);

    try {
      const response = await axiosInstanceBilling.get(
        `${APIs.BILLING_CARD.BILLING_CARDS}?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get property assets list
export const getPropertyAssetsList = createAsyncThunk(
  PROPERTIES.GET_PROPERTY_ASSETS_LIST,
  async (
    {
      page,
      search,
      size,
      status,
      order,
      orderBy,
      is_active,
      status_id,
      product_id,
      equipment_type_id,
      property_uuid,
      limit,
      equipment_class_id,
      list_column_names,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Build the query string dynamically
    if (page) params.append('page', page);
    if (size) params.append('size', size);
    if (status) params.append('status', status);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (search) params.append('search', search);
    if (is_active) params.append('is_active', is_active);
    if (status_id) params.append('status_id', status_id);
    if (product_id) params.append('product_id', product_id);
    if (equipment_type_id)
      params.append('equipment_type_id', equipment_type_id);
    if (equipment_class_id)
      params.append('equipment_class_id', equipment_class_id);
    if (limit) params.append('limit', limit);
    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.PROPERTIES.GET_PROPERTY_ASSETS_LIST.replace(
          '{property_uuid}',
          property_uuid
        )}?${params}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// create asset
export const createAsset = createAsyncThunk(
  PROPERTIES.CREATE_ASSET,
  async (preparedData, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.post(
        APIs.PROPERTIES.CREATE_ASSET,
        preparedData
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// update asset
export const updateAsset = createAsyncThunk(
  PROPERTIES.UPDATE_ASSET,
  async ({ assetId, data }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.put(
        APIs.PROPERTIES.UPDATE_ASSET_DETAILS.replace('{asset_id}', assetId),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get asset details
export const getAssetDetails = createAsyncThunk(
  PROPERTIES.GET_ASSET_DETAILS,
  async ({ assetId }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_ASSET_DETAILS.replace('{asset_id}', assetId)
      );

      const { data } = response;

      if (data?.data?.[0]?.product?.uuid) {
        const productResponse = await axiosPropertyUrl.get(
          `${APIs.PRODUCT_CATALOGUE.PRODUCT_ENHANCEMENT}?uuid=${data?.data?.[0]?.product?.uuid}`
        );

        return {
          data: [
            {
              ...data?.data?.[0],
              productDetails: productResponse?.data?.data?.[0] || {},
            },
          ],
        };
      } else {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get equipment list
export const getEquipmentTypes = createAsyncThunk(
  PROPERTIES.GET_EQUIPEMENT_TYPE,
  async ({ standards_id }, { rejectWithValue }) => {
    const params = new URLSearchParams();

    if (standards_id) params.append('standards_id', standards_id);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.PROPERTIES.GET_EQUIPEMENT_TYPE}?${params.toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get products
export const getProducts = createAsyncThunk(
  PROPERTIES.GET_PRODUCTS,
  async ({ equipment_type }, { rejectWithValue }) => {
    const params = new URLSearchParams();

    // Build the query string dynamically
    if (equipment_type) params.append('equipment_types', equipment_type);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.PROPERTIES.GET_PRODUCTS}?${params}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get asset status
export const getAssetStatus = createAsyncThunk(
  PROPERTIES.GET_ASSET_STATUS,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_ASSET_STATUS
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Routines
export const getRoutineList = createAsyncThunk(
  PROPERTIES.GET_PROPERTY_ROUTINE_LIST,
  async (
    { page, search, size, status, order, orderBy, list_column_names, limit },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Build the query string dynamically
    if (page) params.append('page', page);
    if (size) params.append('size', 100 || size);
    if (limit) params.append('limit', limit);
    if (status) params.append('status', status);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (search) params.append('search', search);
    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.PROPERTIES.GET_PROPERTY_ROUTINE_LIST}?${params}`
      );

      const activeBillingContractUuid =
        response.data.data[0]?.property?.contract_assignment_details
          ?.active_billing_contract_uuid;

      if (activeBillingContractUuid) {
        const billingContractResponse = await axiosInstanceBilling.get(
          APIs.BILLING_CONTRACT.GET_BILLING_CONTRACT_BY_ID.replace(
            ':billing_contract_uuid',
            activeBillingContractUuid
          )
        );

        return {
          ...response.data,
          billingContractDetails: billingContractResponse.data.data[0],
        };
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update Routines
export const updateRoutine = createAsyncThunk(
  PROPERTIES.UPDATE_ROUTINE,
  async ({ routineId, data }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.put(
        APIs.PROPERTIES.UPDATE_ROUTINE_DETAILS.replace(
          '{routine_id}',
          routineId
        ),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// Get Routines
export const getRoutineStandardFrequencyList = createAsyncThunk(
  PROPERTIES.GET_PROPERTY_ROUTINE_STANDARD_FREQUENCY,
  async (
    {
      page,
      search,
      size,
      status,
      order,
      orderBy,
      property_id,
      list_column_names,
      limit,
      type,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Build the query string dynamically
    if (page) params.append('page', page);
    if (size) params.append('size', 100 || size);
    if (limit) params.append('limit', limit);
    // if (status) params.append('status', status);
    // if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    // if (search) params.append('search', search);
    if (property_id) params.append('property_uuid', property_id);
    if (type) params.append('type', type);
    // if (list_column_names)
    //   params.append('list_column_names', list_column_names);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.PROPERTIES.GET_PROPERTY_ROUTINE_STANDARD_FREQUENCY}?${params.toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLocalGovernmentAndZone = createAsyncThunk(
  PROPERTIES.GET_LOCAL_GOVERNMENT_AND_ZONE,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.post(
        APIs.PROPERTIES.GET_LOCAL_GOVERNMENT_AND_ZONE,
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getInvoiceCount = createAsyncThunk(
  PROPERTIES.GET_INVOICE_COUNT,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.post(
        APIs.PROPERTIES.GET_INVOICE_COUNT,
        { status: '' }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getQuoteCount = createAsyncThunk(
  PROPERTIES.GET_QUOTE_COUNT,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        APIs.PROPERTIES.GET_QUOTE_COUNT,
        { status: '' }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDefectCount = createAsyncThunk(
  PROPERTIES.GET_DEFECT_COUNT,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        APIs.PROPERTIES.GET_DEFECT_COUNT,
        { status: '' }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAssetHistoryMessages = createAsyncThunk(
  PROPERTIES.GET_ASSET_HISTORY_MESSAGES,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_ASSET_HISTORY_MESSAGES
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAssetHistory = createAsyncThunk(
  PROPERTIES.GET_ASSET_HISTORY,
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_ASSET_HISTORY.replace('{uuid}', uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createWorkOrderFromAsset = createAsyncThunk(
  PROPERTIES.CREATE_WORK_ORDER_FROM_ASSET,
  async ({ asset_id }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.post(
        APIs.PROPERTIES.CREATE_WORK_ORDER.replace('{asset_id}', asset_id)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createBuildingType = createAsyncThunk(
  PROPERTIES.CREATE_BUILDING_TYPE_LIST,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.post(
        APIs.PROPERTIES.CREATE_BUILDING_TYPE,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBuildingType = createAsyncThunk(
  PROPERTIES.GET_BUILDING_TYPE,
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_BUILDING_TYPE.replace('{uuid}', uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateBuildingType = createAsyncThunk(
  PROPERTIES.UPDATE_BUILDING_TYPE,
  async ({ uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.patch(
        APIs.PROPERTIES.UPDATE_BUILDING_TYPE.replace('{uuid}', uuid),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addAssetVariant = createAsyncThunk(
  PROPERTIES.ADD_PRODUCT_VARIANT,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.post(
        APIs.PROPERTIES.CREATE_ASSET_VARIANT,
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAssetVariant = createAsyncThunk(
  PROPERTIES.UPDATE_PRODUCT_VARIANT,
  async ({ uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.patch(
        APIs.PROPERTIES.UPDATE_ASSET_VARIANT.replace('{uuid}', uuid),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAssetVariantsList = createAsyncThunk(
  PROPERTIES.GET_PRODUCT_VARIANTS,
  async (
    { page, search, size, order, orderBy, active, limit },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (active !== undefined || active !== null)
      params.append('is_active', active);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.PROPERTIES.GET_PRODUCT_VARIANTS}?${params}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addSiteRequirement = createAsyncThunk(
  PROPERTIES.ADD_SITE_REQUIREMENT,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.post(
        APIs.PROPERTIES.CREATE_SITE_REQUIREMENT,
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSiteRequirement = createAsyncThunk(
  PROPERTIES.UPDATE_SITE_REQUIREMENT,
  async ({ uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.patch(
        APIs.PROPERTIES.UPDATE_SITE_REQUIREMENT.replace('{uuid}', uuid),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSiteRequirementsList = createAsyncThunk(
  PROPERTIES.GET_SITE_REQUIREMENTS,
  async (
    { page, search, size, order, orderBy, active, limit },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (active !== undefined && active !== null) {
      params.append('is_active', active);
    }

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.PROPERTIES.GET_SITE_REQUIREMENTS}?${params}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSiteRequirement = createAsyncThunk(
  PROPERTIES.GET_SITE_REQUIREMENT,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.UPDATE_SITE_REQUIREMENT.replace('{uuid}', uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addAccessProcedure = createAsyncThunk(
  PROPERTIES.ADD_ACCESS_PROCEDURE,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.post(
        APIs.PROPERTIES.CREATE_ACCESS_PROCEDURE,
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAccessProcedure = createAsyncThunk(
  PROPERTIES.UPDATE_ACCESS_PROCEDURE,
  async ({ uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.patch(
        APIs.PROPERTIES.UPDATE_ACCESS_PROCEDURE.replace('{uuid}', uuid),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAccessProceduresList = createAsyncThunk(
  PROPERTIES.GET_ACCESS_PROCEDURES,
  async (
    { page, search, size, order, orderBy, active, limit },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (active !== undefined || active !== null)
      params.append('is_active', active);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.PROPERTIES.GET_ACCESS_PROCEDURES}?${params}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAccessProcedure = createAsyncThunk(
  PROPERTIES.GET_ACCESS_PROCEDURE,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_ACCESS_PROCEDURE.replace('{uuid}', uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addAccessSchedule = createAsyncThunk(
  PROPERTIES.ADD_ACCESS_SCHEDULE,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.post(
        APIs.PROPERTIES.CREATE_ACCESS_SCHEDULE,
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAccessSchedule = createAsyncThunk(
  PROPERTIES.UPDATE_ACCESS_SCHEDULE,
  async ({ uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.patch(
        APIs.PROPERTIES.UPDATE_ACCESS_SCHEDULE.replace('{uuid}', uuid),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAccessSchedule = createAsyncThunk(
  PROPERTIES.GET_ACCESS_SCHEDULE,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_ACCESS_SCHEDULE.replace('{uuid}', uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAccessScheduleList = createAsyncThunk(
  PROPERTIES.GET_ACCESS_SCHEDULE_LISTS,
  async (
    { page, search, size, order, orderBy, active, limit },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (active !== undefined || active !== null)
      params.append('is_active', active);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.PROPERTIES.GET_ACCESS_SCHEDULE_LISTS}?${params}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
