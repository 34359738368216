import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Typography } from '@mui/material';

import { CustomCard, FormWrapper } from '../../../assets/commonStyled';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';

const ZoneView = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: 'local_government_areas_name',
      headerName: t('attributes.configuration.zone.lganame'),
      flex: 3,
      renderHeader: (params) => (
        <Typography style={{ paddingLeft: '16px' }}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <span style={{ paddingLeft: '16px' }}>{params.value}</span>
      ),
      sortable: false,
    },
    {
      field: 'post_code',
      headerName: t('attributes.configuration.zone.postCode'),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!isLoading) {
      setRows(data);
    }
  }, [isLoading]);

  const displayRows = rows
    .filter((row) => row.is_active !== false)
    .map((row, index) => ({
      ...row,
      id: index,
    }));

  return (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">
            {t('attributes.configuration.zone.lgaDetails')}
          </Typography>
        </Box>
        <Divider />
        <CustomGridTable
          columns={columns}
          rows={displayRows}
          total={displayRows.length}
          checkboxSelection={false}
          paginationRequired={false}
          disableColumnMenu={true}
          noData={
            <NoRecordFound
              isShowIcon={false}
              text={t('attributes.configuration.zone.noData')}
              bold={false}
            />
          }
          isLoading={isLoading}
          isTableView={true}
          filterHeight={140}
        />
      </CustomCard>
    </FormWrapper>
  );
};

export default ZoneView;
