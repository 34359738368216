import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import KeyboardVoiceRoundedIcon from '@mui/icons-material/KeyboardVoiceRounded';
import { Box, Chip, Typography } from '@mui/material';
import Vapi from '@vapi-ai/web';

import { SupportImage, VoiceGIF } from '../../assets/images';
import {
  GREEN_SHADOW,
  LIGHT_GREEN,
  LIGHT_GREEN_SHADOW,
  PRIMARY,
} from '../../constants/Colors';
import { VAPI_ASSISTANT_ID, VAPI_PUBLIC_KEY } from '../../constants/Constants';
import { showHideTopbarLoader } from '../../utils';
import CustomButton from '../CommonComponents/CustomButton';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';

const Support = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [hoverConnected, setHoverConnected] = useState(false);

  const vapiClient = useMemo(
    () =>
      new Vapi(VAPI_PUBLIC_KEY, undefined, {
        alwaysIncludeMicInPermissionPrompt: true,
      }),
    []
  );

  const statusText = useMemo(() => {
    if (!connected && !connecting)
      return t('attributes.support.start_voice_assistant');
    if (connecting) return t('attributes.support.waiting_text');
    if (connected && hoverConnected)
      return t('attributes.support.click_to_disconnect');
    if (connected && !hoverConnected) return t('attributes.support.just_talk');
  }, [connected, connecting, hoverConnected]);

  useEffect(() => {
    vapiClient.on('call-start', () => {
      setConnecting(false);
      setConnected(true);
      showHideTopbarLoader(false, dispatch);
    });

    vapiClient.on('call-end', () => {
      setConnected(false);
    });

    return () => {
      showHideTopbarLoader(false, dispatch);
      vapiClient.on('call-start', () => {
        vapiClient.stop();
      });
      vapiClient.stop();
    };
  }, []);

  const handleAssitanceClick = () => {
    if (!vapiClient) return;

    if (connected) {
      vapiClient.stop();
    } else {
      setConnecting(true);
      showHideTopbarLoader(true, dispatch);
      vapiClient.start(VAPI_ASSISTANT_ID);
    }
  };

  return (
    <StyledMainWrapper title={t('attributes.support.support')}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            gap: '40px',
            p: 10,
          }}
        >
          <Typography variant="h1" sx={{ fontSize: '46px' }}>
            {t('attributes.support.assitant_title')}
          </Typography>
          <img
            src={SupportImage}
            alt=""
            style={{ height: '418px', width: '350px' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '18px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CustomButton
            sx={{
              p: 1,
              borderRadius: '100%',
              height: '80px',
              width: '80px',
              background: `linear-gradient(315deg, ${PRIMARY}, ${LIGHT_GREEN})`,
              transition: 'background 0.3s ease-in-out',
              overflow: 'hidden',
              boxShadow: `0px 0px 10px ${PRIMARY}`,
              '&:hover': {
                background: `linear-gradient(315deg, ${GREEN_SHADOW}, ${LIGHT_GREEN_SHADOW})`,
                boxShadow: `0px 0px 20px ${PRIMARY}`,
              },
            }}
            disabled={connecting}
            onClick={handleAssitanceClick}
            onMouseEnter={() => setHoverConnected(connected ? true : false)}
            onMouseLeave={() => setHoverConnected(false)}
          >
            {connected ? (
              <img
                src={VoiceGIF}
                alt=""
                style={{
                  maxWidth: '230%',
                }}
              />
            ) : (
              <KeyboardVoiceRoundedIcon
                sx={{ height: '60px', width: '60px', color: 'white' }}
              />
            )}
          </CustomButton>
          <Chip label={<Typography variant="body1">{statusText}</Typography>} />
        </Box>
      </Box>
    </StyledMainWrapper>
  );
};

export default Support;
