import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Box, Divider, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../../assets/commonStyled';
import { previewDocument, validateAddress } from '../../../constants/common';
import { FILE_UPLOAD_SIZES } from '../../../constants/Constants';
import { Validation } from '../../../constants/FieldValidationMsg';
import { SUPER_ADMIN } from '../../../constants/Roles';
import getCityByStateHook from '../../../hooks/getCityByStateHook';
import getStatesHook from '../../../hooks/getStatesHook';
import useAddressDetail from '../../../hooks/useAddressDetail';
import useAutocompleteAddress from '../../../hooks/useAutcompleteAddress';
import { uploadDocument } from '../../../store/CommonAPI';
import { resetDocumentData } from '../../../store/CommonReducer';
import { getTechnicianById } from '../../../store/technician/api';
import { resetEdit } from '../../../store/technician/reducer';
import {
  clearTextfields,
  formattedDate,
  loggedInUserRole,
} from '../../../utils';
import Autocomplete from '../../CommonComponents/AutoComplete';
import CreateCityModal from '../../CommonComponents/CreateCityModal';
import CustomButton from '../../CommonComponents/CustomButton';
import CommonCheckbox from '../../CommonComponents/CustomCheckbox';
import CustomFileUploadButton from '../../CommonComponents/CustomFileUploadButton';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import CustomTextField from '../../CommonComponents/CustomTextField';
import { ConfirmationModal } from '../../CommonComponents/Modal';
import UploadedDocumentView from '../../CommonComponents/UploadedDocumentView';

const TechnicianForm = ({
  control,
  setValue,
  getValues,
  sethasFormValues,
  clearErrors,
  isEdit,
  isSuperAdmin = true,
  categoryList,
  isCategoryListLoading,
  zonesList,
  isZonesListLoading,
  skillsList,
  isSkillsListLoading,
  serviceAreaList,
  isServiceListLoading,
  watch,
  trigger,
  reset,
  id,
  setServerErrors,
}) => {
  const { t } = useTranslation();

  const selectedState = watch('state');
  const stateList = getStatesHook();
  const cityList = getCityByStateHook(selectedState?.value);
  const dispatch = useDispatch();
  const { isLoading, editTechnician } = useSelector(
    (state) => state.technician.edit
  );

  const { isDocumentLoading, uploadedSuccessfully, documentData } = useSelector(
    (state) => state.common
  );
  const [uploadedDocuments, setUploadedDocuments] = useState({
    name: '',
    size: '',
  });
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const {
    isLoading: isAutocompleteAddressLoading,
    data: autocompleteAddressData,
  } = useSelector((state) => state.googleAPIs.autocompleteAddress);
  const typedValue = watch('searchAddress');
  const [searchAddress, setSearchAddress] = useState('');

  useAutocompleteAddress(searchAddress);
  useAddressDetail({
    selectedAddress: typedValue,
    setValue: setValue,
    fieldName: {
      stateKey: 'state',
      cityKey: 'city',
      postCodeKey: 'postCode',
      address1: 'address',
      address2: 'address_line_2',
    },
  });

  const [documentError, setdocumentError] = useState('');

  const [isUploadingOther, setIsUploadingOther] = useState(false);
  const postCodeLength = 4;

  const [fileData, setFileData] = useState(null);

  const handleFileChange = (type) => (event, onChange) => {
    const file = event.target.files[0];

    setFileData(file);
    const isValidFile = file && file.type === 'application/pdf';

    if (file?.size > FILE_UPLOAD_SIZES.DOCUMENT) {
      setdocumentError(t('message.customer.uploadDocumentMaxSizeError'));

      return;
    }

    if (isValidFile) {
      onChange(event);
      const fileSize = (file.size / 1024).toFixed(0) + ' KB';

      setIsUploadingOther(true);
      setUploadedDocuments({
        name: file?.name,
        size: fileSize,
      });
      setdocumentError('');

      dispatch(
        uploadDocument({ document_type: type, uploaded_by: 1, file: file })
      );
    } else {
      setdocumentError('Invalid file type. Please upload a PDF.');
    }
  };

  useEffect(() => {
    if (!isDocumentLoading && uploadedSuccessfully) {
      setIsUploadingOther(false);
      setValue('otherDocument', {
        document_uuid: documentData?.uuid,
        document_type: documentData?.document_type,
        document_name: documentData?.document_name,
        document_size: uploadedDocuments?.size,
      });
    }
  }, [isDocumentLoading, uploadedSuccessfully]);

  const handleDeleteDocument = (type) => {
    setUploadedDocuments({
      name: '',
      size: '',
    });
    setValue(`${type}Document`, null);

    const input = document.querySelector(`input[name="${type}Document"]`);

    if (input) {
      input.value = ''; // Reset the file input
    }

    setdocumentError('');
  };

  const findLabelByValue = (list, value) => {
    const item = list.find((option) => option.value === value);

    return item ? { label: item.label, value: item.value } : null;
  };

  sethasFormValues(Object.values(watch()).some((value) => value));

  useEffect(() => {
    if (id) {
      dispatch(getTechnicianById(id));
    }

    return () => {
      dispatch(resetEdit());
    };
  }, []);

  useEffect(() => () => dispatch(resetDocumentData()), []);

  useEffect(() => {
    if (!isLoading && editTechnician && id) {
      reset({
        id: editTechnician.id,
        primaryContact: editTechnician.technician_name,
        technician_email: editTechnician.technician_email,
        category: findLabelByValue(categoryList, editTechnician.categories[0]),
        apfs_number: editTechnician.apfs_number,
        isApfs_certified: editTechnician.apfs_number,
        skill:
          editTechnician.skills?.map((val) =>
            findLabelByValue(skillsList, val)
          ) || [],
        serviceArea:
          editTechnician.service_areas?.map((val) =>
            findLabelByValue(serviceAreaList, val)
          ) || [],
        zones:
          editTechnician.zones?.map((val) =>
            findLabelByValue(zonesList, val)
          ) || [],
        notes: editTechnician?.notes || null,
        contactPhone: editTechnician.primary_contact_details?.contact_phone,
        wantToAddAddress: editTechnician.primary_contact_details?.address
          ? true
          : false,
        address: editTechnician.primary_contact_details?.address,
        address_line_2:
          editTechnician.primary_contact_details.address_line_2 || '',
        state: editTechnician.primary_contact_details?.state
          ? {
              label: editTechnician.primary_contact_details?.state || '',
              value: editTechnician.primary_contact_details?.state || '',
            }
          : null,
        city: editTechnician.primary_contact_details?.city
          ? {
              label: editTechnician.primary_contact_details?.city || '',
              value: editTechnician.primary_contact_details?.city || '',
            }
          : null,
        postCode: editTechnician.primary_contact_details?.postcode,
        default_cost_rate: editTechnician.rates?.default_cost_rate,
        otherDocument:
          editTechnician?.documents?.length > 0
            ? editTechnician.documents[0]
            : {},
        active: editTechnician.status === 'active',
        created_at: formattedDate(editTechnician.created_at),
      });
    }
  }, [isLoading, editTechnician]);

  const labels = (label) =>
    t(`label.${isSuperAdmin ? 'superAdmin' : 'globalAdmin'}.${label}`);

  const technicianLabels = (label) => t(`attributes.technician.${label}`);

  const [addressBlur, setAddressBlur] = useState(false);
  const [address2Blur, setAddress2Blur] = useState(false);
  const [stateBlur, setStateBlur] = useState(false);
  const [cityBlur, setCityBlur] = useState(false);
  const [postcodeBlur, setPostcodeBlur] = useState(false);
  const [addressRecheck, setAddressRecheck] = useState(false);
  const address = watch('address');
  const addressLine2 = watch('address_line_2');
  const state = watch('state');
  const city = watch('city');
  const postcode = watch('postCode');
  const isApfsCertified = watch('isApfs_certified');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (
      state &&
      (city || true) &&
      address &&
      postcode &&
      (addressLine2 || true)
    ) {
      setAddressBlur(true);
      setStateBlur(true);
      setCityBlur(true);
      setPostcodeBlur(true);
      setAddressRecheck(true);
      setAddress2Blur(true);
    }
  }, [state, city, address, postcode, addressLine2]);

  useEffect(() => {
    validateAddress({
      address1: address,
      address2: addressLine2,
      city: city,
      state: state,
      postcode: postcode,
      clearErrors,
      addressRecheck,
      fieldName: {
        addressKey: 'address',
        addressValue: technicianLabels('contact_address'),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'postCode',
        postCodeValue: t('attributes.postCode'),
        address1Key: 'address',
        address1Value: t('attributes.addressLine1'),
        address2Key: 'address_line_2',
        address2Value: t('attributes.addressLine2'),
      },
      setServerErrors,
    });
  }, [addressBlur, address2Blur, stateBlur, cityBlur, postcodeBlur]);

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <>
      <FormWrapper>
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1">General Details</Typography>
          </Box>
          <Divider />
          <FormFieldWrapper>
            <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
              <Controller
                name="primaryContact"
                control={control}
                rules={{
                  required: 'Primary contact is required',
                  maxLength: {
                    value: 80,
                    message: 'Primary contact must have maximum 80 characters',
                  },
                }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    label="Primary Contact"
                    fullWidth
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      trigger('primaryContact');
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    onClear={() => clearTextfields(setValue, name)}
                    onBlur={() => {
                      onChange(value.trim());
                      onBlur();
                    }}
                  />
                )}
              />
            </Box>
            <Controller
              name="technician_email"
              control={control}
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Please enter a valid email address',
                },
                maxLength: {
                  value: 254,
                  message: 'Email must have maximum 254 characters',
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Email"
                  fullWidth
                  value={value}
                  disabled={id ? true : false}
                  onChange={(e) => {
                    onChange(e);
                    trigger('technician_email');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                />
              )}
            />
            <Controller
              name="category"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label="Category"
                  options={categoryList}
                  value={value}
                  onChange={(e, newValue) => {
                    if (newValue || newValue === null) {
                      onChange(newValue);
                    }
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  isLoadingData={isCategoryListLoading}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
            <Controller
              name="skill"
              control={control}
              rules={{
                required: 'Skills are required',
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  options={skillsList}
                  fullWidth
                  multiple={true}
                  label={t('attributes.technician.skills')}
                  onChange={(e, newValue) => {
                    if (newValue || newValue === null) {
                      onChange(newValue);
                    }
                    trigger('skill');
                  }}
                  value={value}
                  helperText={error ? error.message : ''}
                  error={error}
                  isLoadingData={isSkillsListLoading}
                  onClear={() => clearTextfields(setValue, name)}
                />
              )}
            />
            <Controller
              name="serviceArea"
              control={control}
              rules={{
                required: 'Service Area is required',
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  options={serviceAreaList}
                  fullWidth
                  multiple={true}
                  label="Service Areas"
                  onChange={(e, newValue) => {
                    if (newValue || newValue === null) {
                      onChange(newValue);
                    }
                    trigger('serviceArea');
                  }}
                  value={value}
                  helperText={error ? error.message : ''}
                  isLoadingData={isServiceListLoading}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                />
              )}
            />
            <Controller
              name="zones"
              control={control}
              rules={{
                required: ` ${t('attributes.technician.zones')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  options={zonesList}
                  fullWidth
                  multiple={true}
                  label="Zones"
                  onChange={(e, newValue) => {
                    if (newValue || newValue === null) {
                      onChange(newValue);
                    }
                    trigger('zones');
                  }}
                  value={value}
                  helperText={error ? error.message : ''}
                  isLoadingData={isZonesListLoading}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                />
              )}
            />
            <Controller
              name="notes"
              control={control}
              rules={{
                maxLength: {
                  value: 65535,
                  message: Validation.general.notes,
                },
              }}
              render={({
                field: { onChange, value, name, onBlur },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Notes"
                  multiline
                  rows={3}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('notes');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                  onBlur={() => {
                    onChange(value?.trim());
                    onBlur();
                  }}
                />
              )}
            />
          </FormFieldWrapper>
        </CustomCard>
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1">Contact Details</Typography>
          </Box>
          <Divider />
          <FormFieldWrapper>
            <Controller
              name="contactPhone"
              control={control}
              rules={{
                // required: `Contact phone ${Validation.general.required}`,
                pattern: {
                  value: /^\d{0,50}$/,
                  message: Validation.general.contactPhone,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Contact Phone"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '');

                    onChange(numericValue);
                    trigger('contactPhone');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
            <CommonCheckbox
              control={control}
              name="wantToAddAddress"
              label={t('attributes.technician.wtaa')}
              isRequired={false}
            />
            {getValues('wantToAddAddress') && (
              <>
                <Controller
                  name="searchAddress"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      label={t('common.searchAddress')}
                      options={autocompleteAddressData || []}
                      value={value}
                      isRequired={false}
                      isLoadingData={isAutocompleteAddressLoading}
                      onChange={(e, newValue) => {
                        if (newValue || newValue === null) {
                          onChange(newValue);
                        }
                        setSearchAddress(e.target?.value);
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="address"
                  control={control}
                  rules={{
                    required: `${t('attributes.addressLine1')} ${Validation.general.required}`,
                    maxLength: {
                      value: 300,
                      message: Validation.general.address1,
                    },
                  }}
                  render={({
                    field: { onChange, value, name, onBlur },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.addressLine1')}
                      fullWidth
                      value={value || ''}
                      onChange={(e) => {
                        onChange(e);
                        trigger('address');
                      }}
                      multiline
                      rows={2}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearTextfields(setValue, name)}
                      isRequired={true}
                      setBlur={setAddressBlur}
                      onBlur={() => {
                        onChange(value.trim());
                        onBlur();
                      }}
                    />
                  )}
                />
                <Controller
                  name={'address_line_2'}
                  control={control}
                  rules={{
                    maxLength: {
                      value: 300,
                      message: Validation.general.address2,
                    },
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.addressLine2')}
                      fullWidth
                      value={value || ''}
                      onChange={(e) => {
                        onChange(e);
                        trigger('address_line_2');
                      }}
                      isRequired={false}
                      multiline
                      rows={2}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearTextfields(setValue, name)}
                      setBlur={setAddress2Blur}
                    />
                  )}
                />

                <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
                  <Controller
                    name="state"
                    control={control}
                    rules={{
                      required: `${t('common.state')} ${Validation.general.required}`,
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        label={t('common.state')}
                        options={stateList}
                        value={value}
                        onChange={(e, newValue) => {
                          if (newValue || newValue === null) {
                            onChange(newValue);
                          }
                          trigger('state');
                          setValue('city', null);
                          clearErrors('city');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearTextfields(setValue, name)}
                        isRequired={true}
                        setBlur={setStateBlur}
                      />
                    )}
                  />
                  <Controller
                    name="city"
                    control={control}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        label={t('common.city')}
                        options={cityList}
                        value={value}
                        onChange={(e, newValue) => {
                          if (newValue || newValue === null) {
                            onChange(newValue);
                          }
                          trigger('city');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearTextfields(setValue, name)}
                        isRequired={false}
                        setBlur={setCityBlur}
                      />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '-16px 0px',
                  }}
                >
                  <CustomButton
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    text={t('attributes.add')}
                    style={{ textDecoration: 'underline' }}
                    variant="text"
                    onClick={() => setOpen(true)}
                  />
                </Box>
                <Controller
                  name="postCode"
                  control={control}
                  rules={{
                    required: `${t('attributes.postCode')} ${Validation.general.required}`,
                    validate: (value) => {
                      if (value && !/^\d+$/.test(value)) {
                        return Validation.general.numericValue;
                      }

                      return true;
                    },
                    minLength: {
                      value: postCodeLength,
                      message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                    },
                    maxLength: {
                      value: postCodeLength,
                      message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                    },
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.postCode')}
                      fullWidth
                      value={value || ''}
                      onChange={(e) => {
                        onChange(e);
                        trigger('postCode');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearTextfields(setValue, name)}
                      isRequired={true}
                      setBlur={setPostcodeBlur}
                    />
                  )}
                />
              </>
            )}
          </FormFieldWrapper>
        </CustomCard>
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1">
              {t('attributes.technician.hourly_rate')}
            </Typography>
          </Box>
          <Divider />
          <FormFieldWrapper>
            <Controller
              name="default_cost_rate"
              control={control}
              rules={{
                required: `${t('attributes.technician.hourly_rate')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.technician.hourly_rate_aud')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    if (/^[0-9]*\.?[0-9]*$/.test(inputValue)) {
                      onChange(inputValue);
                      trigger('default_cost_rate');
                    }
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={true}
                />
              )}
            />
          </FormFieldWrapper>
        </CustomCard>
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1">
              {t('attributes.technician.apfs_details')}
            </Typography>
          </Box>
          <Divider />
          <FormFieldWrapper>
            <CommonCheckbox
              control={control}
              name="isApfs_certified"
              label={t('attributes.technician.apfs_certificate')}
              isRequired={false}
              onCheckboxChange={(val) => {
                if (!val) {
                  setValue('apfs_number', '');
                }
              }}
            />
            {isApfsCertified && (
              <Controller
                name="apfs_number"
                control={control}
                rules={{
                  required: `APFS Number ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    label={t('attributes.technician.apfs_number')}
                    fullWidth
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                      trigger('apfs_number');
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    onClear={() => clearTextfields(setValue, name)}
                    isRequired={true}
                  />
                )}
              />
            )}
          </FormFieldWrapper>
        </CustomCard>
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1">Attach Document</Typography>
          </Box>
          <Divider />
          <FormFieldWrapper>
            <Controller
              name="otherDocument"
              control={control}
              render={({
                field: { onChange, name, value },
                fieldState: { error },
              }) => (
                <>
                  <CustomFileUploadButton
                    name={name}
                    onFileChange={(event) => {
                      handleFileChange('other')(event, onChange);
                      // onChange(event);
                      trigger('otherDocument');
                    }}
                    multiple={false}
                    buttonText="Click to upload or drag and drop the PDF file"
                    accept=".pdf"
                    error={!!documentError || error}
                    errorMessage={documentError || error?.message}
                  />
                  {((!isEmpty(value) && value?.document_uuid) ||
                    uploadedDocuments?.name !== '') && (
                    <UploadedDocumentView
                      fileName={
                        isUploadingOther
                          ? uploadedDocuments?.name
                          : value?.document_name || ''
                      }
                      fileSize={
                        isUploadingOther
                          ? uploadDocument?.size
                          : value?.document_size
                      }
                      isUploading={isUploadingOther}
                      onclick={() => {
                        if (fileData) {
                          // Handle preview for newly uploaded document
                          previewDocument(
                            t,
                            dispatch,
                            { file: fileData },
                            true
                          );
                        } else {
                          // Handle preview for existing document
                          previewDocument(
                            t,
                            dispatch,
                            value?.document_uuid,
                            false
                          );
                        }
                      }}
                      onDelete={() => setOpenConfirmationModal(true)}
                    />
                  )}
                </>
              )}
            />
          </FormFieldWrapper>
        </CustomCard>
        {id && (
          <CustomCard>
            <Box sx={{ padding: '0 16px 0 16px' }}>
              <CommonCheckbox
                control={control}
                name="active"
                label="Active"
                isRequired={false}
                showMessage={true} // Enable message display
                messageOnChecked={labels('technicianActiveCheckbox')}
                messageOnUnchecked={labels('technicianInactiveCheckbox')}
              />
            </Box>
          </CustomCard>
        )}
      </FormWrapper>
      {openConfirmationModal && (
        <ConfirmationModal
          title="Delete Document"
          description="Are you sure you want to delete this document?"
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            handleDeleteDocument('other');
            setOpenConfirmationModal(false);
          }}
        />
      )}
      {loggedInUserRole() === SUPER_ADMIN && (
        <CreateCityModal
          open={open}
          setOpen={setOpen}
          state={watch('state')}
          setServerErrors={setServerErrors}
        />
      )}
    </>
  );
};

export default TechnicianForm;
