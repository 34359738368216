import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box } from '@mui/material';
import debounce from 'lodash.debounce';

import { exportToCSV, validateAddress } from '../../constants/common';
import {
  COLUMNS_TO_EXPORT,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getStatesHook from '../../hooks/getStatesHook';
import useBrowserBackButtonHandler from '../../hooks/useBrowserBackButtonHandler';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../store/CommonReducer';
import {
  createContact,
  getContactList,
  updateContact,
} from '../../store/contacts/api';
import {
  resetCreate,
  resetEdit,
  resetPagination,
} from '../../store/contacts/reducer';
import {
  checkIsFiltersApplied,
  loggedInUserRole,
  showHideTopbarLoader,
} from '../../utils';
import { Edit, View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import TabView from '../CommonComponents/TabView';
import ContactForm from './ContactForm';

export const defaultValues = {
  name: '',
  email: '',
  contact_phone: '',
  address: '',
  address_line_2: '',
  city: null,
  state: null,
  active: null,
  postcode: '',
};

const filterHeight = (isFilterOpen) => (isFilterOpen ? 359 : 290);

const Contacts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const role = loggedInUserRole();

  const { contact_uuid } = useParams();
  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: t('attributes.name'),
        field: 'name',
        sortable: false,
        flex: 1,
      },
      {
        headerName: t('attributes.email'),
        field: 'email',
        flex: 1,
        sortable: false,
      },
      {
        headerName: t('attributes.masterContact.contact_phone'),
        field: 'contact_phone',
        flex: 1,
        sortable: false,
      },
      {
        headerName: t('attributes.address'),
        field: 'address',
        sortable: false,
        flex: 1,
      },
      { headerName: t('common.city'), field: 'city', sortable: false, flex: 1 },
      {
        headerName: t('common.state'),
        field: 'state',
        sortable: false,
        flex: 1,
      },
      {
        headerName: t('attributes.postCode'),
        field: 'postcode',
        sortable: false,
        flex: 1,
      },
      {
        field: 'edit',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <View
              onClick={() => {
                navigate(`${row?.uuid}`);
              }}
            />
            <Edit
              onClick={() => {
                setOpen(true);
                setContactId(row.uuid);
              }}
            />
          </Box>
        ),
      },
    ];

    return baseColumns;
  }, []);

  // Redux state
  const {
    contactActiveList,
    contactInactiveList,
    size,
    page,
    total,
    totalCount,
  } = useSelector((state) => state.contact.get);
  const {
    isLoading: createLoading,
    createdContact,
    error,
  } = useSelector((state) => state.contact.create);

  // Form state and methods from react-hook-form
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    watch,
    trigger,
  } = useForm({
    defaultValues: defaultValues,
  });

  // Local state
  const [currentPage, setCurrentPage] = useState(page);
  const [perPageData, setPerPageData] = useState(size);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({ state: null });
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [activeTab, setActiveTab] = useState(0);
  const [contactId, setContactId] = useState('');
  const [serverErrors, setServerErrors] = useState([]);
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [open, setOpen] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [hasFormValues, sethasFormValues] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedRows, setSelectedRows] = useState(null);
  // Custom hook
  const cityList = getCityByStateHook(filters.state?.value);
  const stateList = getStatesHook();
  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );
  const navigate = useNavigate();

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  useBrowserBackButtonHandler({
    hasUnsavedChanges: hasFormValues,
    setOpenConfirmationModal,
    open,
    watchMethod: watch,
    sethasFormValues,
  });

  // Function to fetch data based on search, pagination, and filter
  const getContact = useCallback(() => {
    setSelectedRows(null);
    // Set loading state based on active tab
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);
    dispatch(
      getContactList({
        page: currentPage,
        size: perPageData,
        search: searchText,
        active: activeTab === 0,
        country: filters.country?.value,
        state: filters.state?.value,
        city: filters.city?.value,
        orderBy: orderBy,
        order: order,
        list_column_names: visibleFieldsString,
      })
    ).finally(() => {
      setIsDataLoading(false);
      setIsInitialLoading(false);
    });
  }, [
    dispatch,
    currentPage,
    perPageData,
    searchText,
    activeTab,
    filters,
    order,
    orderBy,
    columnVisibilityModel,
    refresh,
  ]);

  useEffect(
    () => () => {
      dispatch(resetCreate());
      dispatch(resetEdit());
      dispatch(resetPagination());
    },
    []
  );

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getContact, 500), [
    getContact,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    showHideTopbarLoader(createLoading, dispatch);
    if (createLoading !== null && !createLoading) {
      if (error) {
        if (error.errorDetails) {
          setServerErrors(error.errorDetails);
        }
        if (error.message) {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: error.message,
            })
          );
        }
      } else {
        if (createdContact) {
          setOpen(false);
          if (!contactId) setActiveTab(0);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: createdContact?.message,
            })
          );
        }
      }
      dispatch(resetCreate());
    }
  }, [error, createLoading]);

  const rows =
    activeTab === 0
      ? contactActiveList?.map((cl) => ({
          ...cl,
          uuid: cl.uuid,
          name: cl.name,
          created_at: cl.created_at,
          address: [
            cl?.address,
            cl?.address_line_2,
            cl?.city,
            cl?.state,
            cl?.postcode,
          ]
            .filter(Boolean)
            .join(', '),
        }))
      : contactInactiveList?.map((cl) => ({
          ...cl,
          uuid: cl.uuid,
          name: cl.name,
          created_at: cl.created_at,
          address: [
            cl?.address,
            cl?.address_line_2,
            cl?.city,
            cl?.state,
            cl?.postcode,
          ]
            .filter(Boolean)
            .join(', '),
        }));

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const onSubmit = async (data) => {
    const hasError = await validateAddress({
      clearErrors,
      address: data.address,
      city: data.city,
      state: data.state,
      postcode: data.postcode,
      address1: data.address,
      address2: data.address_line_2,
      fieldName: {
        addressKey: 'address',
        addressValue: t('attributes.address'),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'postcode',
        postCodeValue: t('attributes.postCode'),
        address1Key: 'address',
        address1Value: t('attributes.addressLine1'),
        address2Key: 'address_line_2',
        address2Value: t('attributes.addressLine2'),
      },
      setServerErrors: setServerErrors,
    });

    if (hasError) return;

    const createdContactData = {
      name: data.name,
      email: data.email,
      contact_phone: data.contact_phone,
      address: data.address,
      address_line_2: data.address_line_2 || '',
      city: data.city?.value,
      state: data.state.value,
      postcode: data.postcode,
      is_active: contactId ? data.active : true,
    };

    if (!contactId) {
      dispatch(createContact(createdContactData));
    } else {
      dispatch(updateContact({ uuid: data?.uuid, data: createdContactData }));
    }
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const resetFilter = () => {
    setFilters({ state: null });
    setSearchText('');
  };

  const onTabChange = () => {
    setIsDataLoading(true);
    setFilters({ state: null });
    setSearchText('');
    setIsFilterComponentVisible(false);
  };

  const noData = (
    <NewNoDataPage
      icon={<PermContactCalendarOutlinedIcon />}
      title={t('attributes.masterContact.contacts')}
      singularText={t('attributes.masterContact.contact')}
      filterHeight={filterHeight(isFilterComponentVisible)}
      createBtnText={`${t('attributes.add')} ${t('attributes.masterContact.contact')}`}
    />
  );
  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        onRefreshFilter={() => setRefresh(!refresh)}
        isRefresh={true}
        searchText={searchText}
        isActive={isFilterComponentVisible}
        onResetFilter={resetFilter}
        onSearchChange={(e) => setSearchText(e.target.value)}
        isResetButtonVisible={searchText || filters.state}
        isFilterDisable={checkIsFiltersApplied({
          ...filters,
        })}
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder={t('common.state')}
            options={stateList}
            width="190px"
            value={filters?.state}
            onChange={(e, newVal) => {
              if (newVal || newVal === null) {
                setFilters({ state: newVal });
              }
            }}
          />
        </FilterComponent>
      )}
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        onRowSelectionModelChange={handleSelectionChange}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
            sethasFormValues(false);

            if (window.history.state === null) {
              window.history.back();
            }
          }}
        />
      )}
      {contact_uuid ? (
        <Outlet />
      ) : (
        <StyledMainWrapper
          title={t('attributes.masterContact.contacts')}
          btn={
            <>
              <CustomButton
                text={`${t('attributes.add')} ${t('attributes.masterContact.contact')}`}
                color="secondary"
                sx={{ height: '52%' }}
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() => {
                  setOpen(true);
                  setContactId('');
                  reset(defaultValues);
                }}
              />
              <CustomButton
                text={t('attributes.export')}
                color="secondary"
                sx={{ height: '52%' }}
                startIcon={<OpenInNewIcon />}
                onClick={() => {
                  if (!selectedRows || selectedRows?.length === 0) {
                    dispatch(
                      snackbarToggle({
                        isOpen: true,
                        isErrorMsg: true,
                        msg: t('message.common.noRecordExportMessage'),
                      })
                    );
                    setSelectedRows(null);
                  } else {
                    exportToCSV(
                      selectedRows,
                      COLUMNS_TO_EXPORT.CONTACTS,
                      EXPORT_FILE_NAMES.CONTACTS
                    );
                  }
                }}
              />
            </>
          }
        >
          {!isDataLoading && totalCount === 0 && noData}
          {isInitialLoading ? (
            <Box
              sx={{
                width: '100%',
                mt: 2,
                minHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
                maxHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CustomCircularLoader />
            </Box>
          ) : (
            !isInitialLoading &&
            totalCount > 0 && (
              <TabView
                tabs={[
                  {
                    label: t('common.active'),
                    component: renderedComponent,
                  },
                  {
                    label: t('common.inactive'),
                    component: renderedComponent,
                  },
                ]}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                onTabChange={onTabChange}
              />
            )
          )}
        </StyledMainWrapper>
      )}

      {open && (
        <SwipeableDrawer
          open={open}
          title={
            !contactId
              ? `${t('attributes.add')} ${t('attributes.masterContact.contact')}`
              : `${t('attributes.edit')} ${t('attributes.masterContact.contact')}`
          }
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          footerButton={
            <CustomButton
              text={
                createLoading
                  ? t('common.loading')
                  : !contactId
                    ? t('attributes.add')
                    : t('attributes.save')
              }
              startIcon={!contactId ? <AddIcon /> : <SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={createLoading}
            />
          }
        >
          <ContactForm
            id={contactId}
            control={control}
            reset={reset}
            setValue={setValue}
            watch={watch}
            trigger={trigger}
            clearErrors={clearErrors}
            setServerErrors={setServerErrors}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default Contacts;
