import React, { useRef, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';

import { Button, Stack } from '@mui/material';

import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';

const ASPECT_RATIO = 1;
const scale = 1;
const rotate = 0;

const reduceImageSize = (img, maxWidth = 800, maxHeight = 800) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  let { width, height } = img;

  if (width > maxWidth || height > maxHeight) {
    const ratio = Math.min(maxWidth / width, maxHeight / height);

    width *= ratio;
    height *= ratio;
  }

  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(img, 0, 0, width, height);

  return canvas.toDataURL('image/jpeg');
};

const ImageCropper = ({
  onClose,
  updateAvatar,
  imgSrc,
  isForCompany = false,
}) => {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);

  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);

  const onImageLoad = (e) => {
    const img = e.currentTarget;
    const reducedImgSrc = reduceImageSize(img);

    img.src = reducedImgSrc;

    setCrop(
      (prevCrop) =>
        prevCrop ||
        centerCrop(
          makeAspectCrop(
            {
              unit: '%',
              width: 90,
            },
            1,
            img.width,
            img.height
          ),
          img.width,
          img.height
        )
    );
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop]
  );

  return (
    <Stack px={3}>
      {imgSrc && (
        <Stack display="flex" flexDirection="column" alignItems="center">
          <ReactCrop
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={ASPECT_RATIO}
            minWidth={400}
            minHeight={100}
            {...(isForCompany ? {} : { circularCrop: true })}
          >
            <img
              ref={imgRef}
              src={imgSrc}
              alt="Upload"
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            justifyContent="center"
            py={2}
          >
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                const dataUrl = previewCanvasRef.current.toDataURL();

                updateAvatar(dataUrl);
                onClose();
              }}
            >
              Crop Image
            </Button>
            <Button
              type="button"
              variant="contained"
              color="error"
              onClick={onClose}
            >
              Close
            </Button>
          </Stack>
        </Stack>
      )}

      {crop && (
        <canvas
          ref={previewCanvasRef}
          className="mt-4"
          style={{
            display: 'none',
            border: '1px solid black',
            objectFit: 'contain',
            width: 800,
            height: 800,
          }}
        />
      )}
    </Stack>
  );
};

export default ImageCropper;
