import { createSlice } from '@reduxjs/toolkit';

import {
  addPurchaseOrderInvoiceBill,
  createPurchaseOrder,
  createPurchaseOrderProduct,
  deletePurchaseOrderProduct,
  getPurchaseOrderInvoiceBill,
  getPurchaseOrderInvoiceBillList,
  getPurchaseOrders,
  updatePurchaseOrder,
  updatePurchaseOrderProduct,
} from './api';

const initialGetData = {
  isLoading: true,
  error: null,
  purchaseOrdersList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const initialCreateData = {
  isLoading: false,
  error: false,
  createdPurchaseOrder: false,
};

const initialCreateProductData = {
  isLoading: false,
  error: false,
  createdPurchaseOrderProduct: false,
};

const initialPurchaseOrderDetailData = {
  isLoading: false,
  error: null,
  purchaseOrderDetail: [],
};

const initialUpdatePurchaseOrderProductData = {
  isLoading: false,
  error: null,
  updatePurchaseOrderProductData: [],
};

const initialDeletePurchaseOrderProductData = {
  isLoading: false,
  error: null,
  deletePurchaseOrderProductData: [],
};

const initialPurchaseOrderInvoiceBillData = {
  isLoading: false,
  error: null,
  purchaseOrderInvoiceBillData: [],
};

const initialPurchaseOrderInvoiceBillListData = {
  isLoading: false,
  error: null,
  purchaseOrdersInvoiceBillList: [],
};

const initialPurchaseOrderInvoiceBillDetail = {
  isLoading: false,
  error: null,
  purchaseOrdersInvoiceBillDetail: [],
};

const purchaseOrderSlice = createSlice({
  name: 'purchaseOrders',
  initialState: {
    get: { ...initialGetData },
    create: {
      ...initialCreateData,
    },
    updatePurchaseOrder: { ...initialPurchaseOrderDetailData },
    createProduct: {
      ...initialCreateProductData,
    },
    updatePurchaseOrderProduct: { ...initialUpdatePurchaseOrderProductData },
    deletePurchaseOrderProduct: { ...initialDeletePurchaseOrderProductData },
    addNewPurchaseOrderInvoiceBill: { ...initialPurchaseOrderInvoiceBillData },
    getPurchaseOrderInvoiceBillList: {
      ...initialPurchaseOrderInvoiceBillListData,
    },
    getPurchaseOrderInvoiceBillDetail: {
      ...initialPurchaseOrderInvoiceBillDetail,
    },
  },
  reducers: {
    resetPagination(state) {
      state.get = { ...initialGetData };
    },
    resetCreatePurchaseOrder(state) {
      state.create = { ...initialCreateData };
    },
    resetUpdatePurchaseOrder(state) {
      state.updatePurchaseOrder = { ...initialPurchaseOrderDetailData };
    },
    resetCreatePurchaseOrderProduct(state) {
      state.createProduct = { ...initialCreateProductData };
    },
    resetPurchaseOrderInvoiceBillList(state) {
      state.getPurchaseOrderInvoiceBillList = {
        ...initialPurchaseOrderInvoiceBillListData,
      };
    },
    resetAddPurchaseOrderInvoiceBill(state) {
      state.addNewPurchaseOrderInvoiceBill = {
        ...initialPurchaseOrderInvoiceBillData,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Purchase order List
      .addCase(getPurchaseOrders.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getPurchaseOrders.fulfilled, (state, action) => {
        state.get.isLoading = false;

        state.get.purchaseOrdersList = action.payload.data;

        state.get.total = action.payload.pagination?.total_items;
        state.get.totalCount = action.payload.pagination?.total_count;
        state.get.totalPurchaseOrderCount =
          action.payload.pagination?.type_count;
      })
      .addCase(getPurchaseOrders.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })

      // Create purchase order
      .addCase(createPurchaseOrder.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(createPurchaseOrder.fulfilled, (state, action) => {
        state.create.isLoading = false;
        state.create.error = false;
        state.create.createdPurchaseOrder = action.payload.data[0];
      })
      .addCase(createPurchaseOrder.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })

      //  Purchase order Detail
      .addCase(updatePurchaseOrder.pending, (state) => {
        state.updatePurchaseOrder.isLoading = true;
      })
      .addCase(updatePurchaseOrder.fulfilled, (state, action) => {
        state.updatePurchaseOrder.isLoading = false;
        state.updatePurchaseOrder.purchaseOrderDetail = action.payload;
      })
      .addCase(updatePurchaseOrder.rejected, (state, action) => {
        state.updatePurchaseOrder.isLoading = false;
        state.updatePurchaseOrder.error = action.payload;
      })

      // Create purchase order
      .addCase(createPurchaseOrderProduct.pending, (state) => {
        state.createProduct.isLoading = true;
      })
      .addCase(createPurchaseOrderProduct.fulfilled, (state, action) => {
        state.createProduct.isLoading = false;
        state.createProduct.error = false;
        state.createProduct.createdPurchaseOrderProduct =
          action.payload.data[0];
      })
      .addCase(createPurchaseOrderProduct.rejected, (state, action) => {
        state.createProduct.isLoading = false;
        state.createProduct.error = action.payload;
      })

      //  Purchase order Detail
      .addCase(updatePurchaseOrderProduct.pending, (state) => {
        state.updatePurchaseOrderProduct.isLoading = true;
      })
      .addCase(updatePurchaseOrderProduct.fulfilled, (state, action) => {
        state.updatePurchaseOrderProduct.isLoading = false;
        state.updatePurchaseOrderProduct.updatePurchaseOrderProductData =
          action.payload;
      })
      .addCase(updatePurchaseOrderProduct.rejected, (state, action) => {
        state.updatePurchaseOrderProduct.isLoading = false;
        state.updatePurchaseOrderProduct.error = action.payload;
      })

      //  Purchase order Detail
      .addCase(deletePurchaseOrderProduct.pending, (state) => {
        state.deletePurchaseOrderProduct.isLoading = true;
      })
      .addCase(deletePurchaseOrderProduct.fulfilled, (state, action) => {
        state.deletePurchaseOrderProduct.isLoading = false;
        state.deletePurchaseOrderProduct.deletePurchaseOrderProductData =
          action.payload;
      })
      .addCase(deletePurchaseOrderProduct.rejected, (state, action) => {
        state.deletePurchaseOrderProduct.isLoading = false;
        state.deletePurchaseOrderProduct.error = action.payload;
      })

      // add purchase order invoice bill
      .addCase(addPurchaseOrderInvoiceBill.pending, (state) => {
        state.addNewPurchaseOrderInvoiceBill.isLoading = true;
      })
      .addCase(addPurchaseOrderInvoiceBill.fulfilled, (state, action) => {
        state.addNewPurchaseOrderInvoiceBill.isLoading = false;
        state.addNewPurchaseOrderInvoiceBill.error = false;
        state.addNewPurchaseOrderInvoiceBill.purchaseOrderInvoiceBillData =
          action.payload;
      })
      .addCase(addPurchaseOrderInvoiceBill.rejected, (state, action) => {
        state.addNewPurchaseOrderInvoiceBill.isLoading = false;
        state.addNewPurchaseOrderInvoiceBill.error = action.payload;
      })

      // Purchase order invoice bill List
      .addCase(getPurchaseOrderInvoiceBillList.pending, (state) => {
        state.getPurchaseOrderInvoiceBillList.isLoading = true;
      })
      .addCase(getPurchaseOrderInvoiceBillList.fulfilled, (state, action) => {
        state.getPurchaseOrderInvoiceBillList.isLoading = false;

        state.getPurchaseOrderInvoiceBillList.purchaseOrdersInvoiceBillList =
          action.payload.data;
      })
      .addCase(getPurchaseOrderInvoiceBillList.rejected, (state, action) => {
        state.getPurchaseOrderInvoiceBillList.isLoading = false;
        state.getPurchaseOrderInvoiceBillList.error = action.payload;
      })

      // Purchase order invoice bill detail
      .addCase(getPurchaseOrderInvoiceBill.pending, (state) => {
        state.getPurchaseOrderInvoiceBillDetail.isLoading = true;
      })
      .addCase(getPurchaseOrderInvoiceBill.fulfilled, (state, action) => {
        state.getPurchaseOrderInvoiceBillDetail.isLoading = false;

        state.getPurchaseOrderInvoiceBillDetail.purchaseOrdersInvoiceBillDetail =
          action.payload.data[0];
      })
      .addCase(getPurchaseOrderInvoiceBill.rejected, (state, action) => {
        state.getPurchaseOrderInvoiceBillDetail.isLoading = false;
        state.getPurchaseOrderInvoiceBillDetail.error = action.payload;
      });
  },
});

export const {
  resetPagination,
  resetCreatePurchaseOrder,
  resetUpdatePurchaseOrder,
  resetCreatePurchaseOrderProduct,
  resetPurchaseOrderInvoiceBillList,
  resetAddPurchaseOrderInvoiceBill,
} = purchaseOrderSlice.actions;

export default purchaseOrderSlice.reducer;
