import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { DIVIDER_COLOR, SECONDARY, TEXT_GRAY } from '../../constants/Colors';
import {
  COMPLETED_STATUS,
  ROUTE_NAVIGATE_CONST,
  WORK_ORDER_TYPE_ADHOC,
  WORK_ORDER_TYPE_REPAIR,
} from '../../constants/Constants';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import useNavigationBlocker from '../../hooks/useNavigationBlocker';
import { getDocument } from '../../store/CommonAPI';
import { snackbarToggle } from '../../store/CommonReducer';
import { editCompany } from '../../store/company/api';
import {
  getParentPropertyList,
  getPropertyAssetsList,
  getPropertyCustomerList,
} from '../../store/property/api';
import { resetPropertyCustomerList } from '../../store/property/reducer';
import { getBillingContractList } from '../../store/users/billingContract/api';
import { getWorkOrder, getWorkOrderList } from '../../store/workOrder/api';
import {
  resetPagination,
  resetWorkOrderData,
} from '../../store/workOrder/reducer';
import {
  clearTextfields,
  getRedirectURL,
  loggedInUserDetail,
  loggedInUserRole,
} from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomTextField from '../CommonComponents/CustomTextField';
import MainWrapper from '../CommonComponents/MainWrapper';
import { ConfirmationModal } from '../CommonComponents/Modal';
import generatePDF from './ActivityReportPDF';

const defaultDateRange = {
  From: null,
  To: null,
};

const ActivityReport = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { control, handleSubmit, setValue, watch, trigger } = useForm();

  const workOrderIdValue = watch('workOrderId');
  const customerIdValue = watch('customer');
  const contractValue = watch('contract');
  const propertyValue = watch('property');

  const [contractUUIDDisabled, setContractUUIDDisabled] = useState(true);
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [disableWorkOrder, setDisableWorkOrder] = useState(false);
  const [isDownloadingRectificationPDF, setIsDownloadingRectificationPDF] =
    useState(false);
  const [company, setCompany] = useState();

  const {
    propertyDropdownLoading: customerDropdownLoading,
    propertyDropdownData: customerDropdownData,
  } = getDropdownListHook({
    reducerName: 'property',
    dropdownListName: 'propertyCustomerList',
    labelName: 'customer_name',
    valueName: 'uuid',
  });

  const [hasFormValues, setHasFormValues] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const formValues = watch();

  useEffect(() => {
    setHasFormValues(Object.values(formValues).some((value) => value));
  }, [formValues]);

  const { proceedNavigation } = useNavigationBlocker({
    hasFormValues,
    setConfirmationModalOpen,
  });

  const { contractDropdownLoading, contractDropdownData } = getDropdownListHook(
    {
      reducerName: 'billingContract',
      dropdownListName: 'getList',
      customNameForDropdown: 'contract',
      fetchDataFrom: 'billingContractActiveList',
      labelName: 'name',
      valueName: 'uuid',
    }
  );

  const { parentPropertyDropdownLoading, parentPropertyDropdownData } =
    getDropdownListHook({
      reducerName: 'property',
      dropdownListName: 'parentPropertyList',
      customNameForDropdown: 'parentProperty',
      labelName: 'property_name',
      valueName: 'uuid',
    });

  const { isLoading: isLoadingCompany, company: companyData } = useSelector(
    (state) => state.company.edit
  );

  const role = loggedInUserRole();
  const user = loggedInUserDetail();

  useEffect(() => {
    if (companyData) {
      setCompany(companyData);
    }
  }, [companyData]);

  useEffect(() => {
    dispatch(getPropertyCustomerList({}));
    if (user?.company_uuid) {
      dispatch(editCompany(user?.company_uuid));
    }

    return () => {
      dispatch(resetPagination());
      dispatch(resetWorkOrderData());
      dispatch(resetPropertyCustomerList());
    };
  }, []);

  useEffect(() => {
    setContractUUIDDisabled(!customerIdValue);
    if (customerIdValue?.value) {
      dispatch(
        getBillingContractList({
          limit: -1,
          customerUUID: customerIdValue?.value,
          active: true,
        })
      );
    }
  }, [customerIdValue]);

  useEffect(() => {
    if ((customerIdValue?.value, contractValue?.value)) {
      dispatch(
        getParentPropertyList({
          limit: -1,
          customerUuid: customerIdValue?.value,
          active_billing_contract_uuid: contractValue?.value,
        })
      );
    }
  }, [customerIdValue, contractValue]);

  useEffect(() => {
    const allFieldsFilled =
      customerIdValue &&
      contractValue &&
      propertyValue &&
      dateRange.From &&
      dateRange.To;

    if (allFieldsFilled) {
      if (!disableWorkOrder) {
        setDisableWorkOrder(true); // Disable work order ID
        setValue('workOrderId', null); // Clear work order ID if all fields are filled
      }
    } else if (workOrderIdValue) {
      if (
        customerIdValue ||
        contractValue ||
        propertyValue ||
        dateRange.From ||
        dateRange.To
      ) {
        setValue('customer', null);
        setValue('contract', null);
        setValue('property', null);
        setDateRange({ From: null, To: null }); // Reset date range
      }
    } else {
      if (disableWorkOrder) {
        setDisableWorkOrder(false); // Enable work order ID when other fields are incomplete
      }
    }
  }, [
    workOrderIdValue,
    customerIdValue,
    contractValue,
    propertyValue,
    dateRange.From,
    dateRange.To,
  ]);

  const handleDefaultStep = () => {
    navigate(getRedirectURL()[role][ROUTE_NAVIGATE_CONST.REPORTING]);
  };

  const handleGenerate = async (data) => {
    try {
      let workOrderResponse = null;

      // Case 1: If `workOrderId` is provided
      if (data.workOrderId) {
        dispatch(resetPagination());

        workOrderResponse = await dispatch(
          getWorkOrder({ id: data.workOrderId })
        ).unwrap();

        const workOrderData = workOrderResponse?.data[0];

        if (
          workOrderData &&
          (workOrderData.work_order_type === WORK_ORDER_TYPE_ADHOC ||
            workOrderData.work_order_type === WORK_ORDER_TYPE_REPAIR ||
            workOrderData.latest_status !== COMPLETED_STATUS)
        ) {
          setIsDownloadingRectificationPDF(false);

          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: t('attributes.work_order.wo_is_not_compatible'),
            })
          );

          throw new Error(t('attributes.work_order.wo_is_not_compatible'));
        }

        const response = workOrderData;
        const propertyUUID = response?.property_uuid;

        if (!propertyUUID) {
          throw new Error('Property UUID not found for the Work Order!');
        }

        // Fetch property assets list for the given work order
        const propertyAssetsListResponse = await dispatch(
          getPropertyAssetsList({
            property_uuid: propertyUUID,
            limit: -1,
          })
        ).unwrap();

        return {
          workOrderData: workOrderResponse,
          propertyAssetsData: propertyAssetsListResponse,
        };
      }

      // Case 2: When `customerIdValue`, `contractValue`, and other conditions are met
      if (
        customerIdValue &&
        contractValue &&
        propertyValue &&
        dateRange.From &&
        dateRange.To
      ) {
        dispatch(resetWorkOrderData());

        // Fetch property assets list
        const propertyAssetsListResponse = await dispatch(
          getPropertyAssetsList({
            property_uuid: propertyValue?.value,
            limit: -1,
          })
        ).unwrap();

        const workOrderListResponse = await dispatch(
          getWorkOrderList({
            property: propertyValue?.value,
            createdAtFrom: dateRange.From,
            createdAtTo: dateRange.To,
            limit: -1,
          })
        ).unwrap();

        if (!workOrderListResponse?.data?.length) {
          setIsDownloadingRectificationPDF(false);

          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: t('attributes.work_order.no_work_orders_found'),
            })
          );

          throw new Error(t('attributes.work_order.no_work_orders_found'));
        }

        const fillteredWorkOrderListResponse =
          workOrderListResponse?.data?.filter(
            (item) =>
              item.work_order_type !== WORK_ORDER_TYPE_ADHOC &&
              item.work_order_type !== WORK_ORDER_TYPE_REPAIR &&
              item.latest_status === COMPLETED_STATUS
          );

        if (!fillteredWorkOrderListResponse.length) {
          setIsDownloadingRectificationPDF(false);

          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: t('attributes.work_order.wo_is_not_compatible'),
            })
          );

          throw new Error(t('attributes.work_order.wo_is_not_compatible'));
        }

        return {
          workOrderListData: { data: fillteredWorkOrderListResponse },
          propertyAssetsData: propertyAssetsListResponse,
        };
      }

      const handleError = (errorMessage) => {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg:
              errorMessage ||
              t('attributes.reports.activity_report_error_message'),
          })
        );
        setIsDownloadingRectificationPDF(false);
        throw new Error(errorMessage || 'Validation failed');
      };

      handleError(t('attributes.reports.activity_report_error_message'));
    } catch (error) {
      console.error('Error in handleGenerate:', error);
      dispatch(
        snackbarToggle({
          isOpen: true,
          isErrorMsg: true,
          msg: error.message,
        })
      );
      throw error;
    }
  };

  const processWorkOrderData = ({ assetsList, workOrder, workOrderList }) => {
    const mapAssetsDefects = (list) =>
      list?.map((task) => ({
        ...task,
        asset_detail:
          assetsList?.find((asset) => asset.uuid === task.asset_uuid) || null, // If no matching asset is found, set to null
      }));

    if (!isEmpty(workOrder) && workOrderIdValue) {
      return {
        ...workOrder,
        work_order_tasks: mapAssetsDefects(workOrder.work_order_tasks),
      };
    }

    return (
      !workOrderIdValue &&
      workOrderList?.map((workOrderItem) => ({
        ...workOrderItem,
        work_order_tasks: mapAssetsDefects(workOrderItem.work_order_tasks),
      }))
    );
  };

  const getImagesByUUID = ({ uuid, imagesSrc }) => {
    if (uuid) {
      return dispatch(getDocument({ doc_uuid: uuid })).then((res) => {
        imagesSrc.push({
          [uuid]: res?.payload?.data?.[0]?.presigned_url,
        });
      });
    }

    return Promise.resolve(); // Return a resolved promise if no UUID
  };

  const handleDownloading = async (data) => {
    setIsDownloadingRectificationPDF(true);

    try {
      // Await the response from handleGenerate
      const generateData = await handleGenerate(data);

      // Process work order data using the returned data
      const processData = processWorkOrderData({
        assetsList: generateData?.propertyAssetsData?.data,
        workOrder: generateData?.workOrderData?.data[0],
        workOrderList: generateData?.workOrderListData?.data,
      });

      let imagesSrc = [];

      const fetchAllDocumentURLs = (dataArray) => {
        const promises = [];

        const processDefects = (defects) => {
          if (defects?.assets_defect?.length > 0) {
            defects.assets_defect.forEach((defect) => {
              if (defect?.documents?.length > 0) {
                defect.documents.forEach((doc) => {
                  if (doc?.document_uuid) {
                    promises.push(
                      getImagesByUUID({ uuid: doc.document_uuid, imagesSrc })
                    );
                  }
                });
              }
            });
          }

          const technicianSignatureUUID =
            defects?.work_order_pre_check_list?.[0]
              ?.technician_signature_document_uuid;

          if (technicianSignatureUUID) {
            promises.push(
              getImagesByUUID({
                uuid: technicianSignatureUUID,
                imagesSrc,
              })
            );
          }
        };

        if (Array.isArray(dataArray)) {
          dataArray.forEach((item) => {
            if (item) {
              processDefects(item);
            }
          });
        } else if (dataArray) {
          processDefects(dataArray);
        }

        return promises;
      };

      const documentFetchPromises = fetchAllDocumentURLs(processData);

      await Promise.all(documentFetchPromises);

      generatePDF(
        processData,
        imagesSrc,
        setIsDownloadingRectificationPDF,
        company
      );
    } catch (error) {
      console.error('Error in handleDownloading:', error);
    } finally {
      setIsDownloadingRectificationPDF(false);
    }
  };

  return (
    <>
      <Box sx={{ backgroundColor: DIVIDER_COLOR }}>
        <MainWrapper
          defaultPadding="0px 0px 16px 0px"
          title={t('attributes.reports.reporting')}
          variant="body1"
          isStep={true}
          step={t('attributes.reports.activity_report')}
          handleDefaultStep={handleDefaultStep}
        />
      </Box>
      {confirmationModalOpen && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={confirmationModalOpen}
          setOpen={setConfirmationModalOpen}
          onConfirm={() => {
            setConfirmationModalOpen(false);
            setHasFormValues(false); // Reset form change detection
            proceedNavigation(); // Allow navigation
          }}
          onCancel={() => {
            setConfirmationModalOpen(false);
          }}
        />
      )}
      <Box
        sx={{ backgroundColor: DIVIDER_COLOR, height: 'calc(100vh - 120px)' }}
      >
        <Box
          sx={{
            backgroundColor: SECONDARY,
            borderRadius: '6px',
          }}
        >
          <Box sx={{ padding: '16px' }}>
            <Controller
              name="workOrderId"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.work_order.work_order_ID')}
                  sx={{ width: '362px' }}
                  value={value}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '');

                    onChange(numericValue);
                    trigger('workOrderId');
                  }}
                  helperText={error ? error.message : ''}
                  error={!!error}
                  onClear={() => setValue(name, '')}
                  disabled={disableWorkOrder}
                  displayStartAdornment={true}
                  startAdornmentValue="W-"
                  isRequired={false}
                />
              )}
            />
            <Box pt="15px">
              <Divider>
                <Typography variant="body2" sx={{ color: TEXT_GRAY }}>
                  {t('common.or')}
                </Typography>
              </Divider>
            </Box>
            <Grid container spacing={2} sx={{ paddingTop: '16px' }}>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="customer"
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      label={t('attributes.customer.customer')}
                      options={customerDropdownData}
                      value={value}
                      onChange={(e, newValue) => {
                        if (newValue || newValue === null) {
                          onChange(newValue);
                        }
                        trigger('customer');
                        setValue('contract', null);
                        setValue('property', null);
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                      isLoadingData={customerDropdownLoading}
                      onClear={() => clearTextfields(setValue, name)}
                      disabled={workOrderIdValue}
                      isRequired={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="contract"
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      label={t('attributes.property.contract')}
                      options={contractDropdownData}
                      value={value}
                      onChange={(e, newValue) => {
                        if (newValue || newValue === null) {
                          onChange(newValue);
                        }
                        setValue('property', null);
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                      isLoadingData={contractDropdownLoading}
                      onClear={() => clearTextfields(setValue, name)}
                      disabledDropdown={contractUUIDDisabled}
                      isRequired={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="property"
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      label={t('attributes.property.property')}
                      options={parentPropertyDropdownData}
                      value={value}
                      onChange={(e, newValue) => {
                        if (newValue || newValue === null) {
                          onChange(newValue);
                        }
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                      isLoadingData={parentPropertyDropdownLoading}
                      onClear={() => clearTextfields(setValue, name)}
                      disabledDropdown={!contractValue}
                      isRequired={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomDateRangePicker
                  placeholder={t('common.date_range')}
                  onOkClick={(val) => {
                    setDateRange((prev) => ({
                      ...prev,
                      From: val[0],
                      To: val[1],
                    }));
                  }}
                  onClear={() => {
                    setDateRange((prev) => ({
                      ...prev,
                      From: null,
                      To: null,
                    }));
                  }}
                  fromDate={dateRange?.From}
                  toDate={dateRange?.To}
                  disabled={workOrderIdValue}
                  maxRangeMonths={1}
                  dynamicWidth
                />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Stack direction="row" justifyContent="end" px="16px" py="8px">
            <CustomButton
              text={
                isDownloadingRectificationPDF
                  ? t('common.downloading')
                  : t('common.generate')
              }
              color="primary"
              sx={{ height: '52%' }}
              startIcon={<AddOutlinedIcon />}
              onClick={handleSubmit(handleDownloading)}
              disabled={isDownloadingRectificationPDF}
            />
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default ActivityReport;
