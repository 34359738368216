import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { ROUTINE } from '../actionPrefix';
import { axiosPropertyUrl } from '../axios';

// Get Routine List API
export const getRoutineStandardFrequencyList = createAsyncThunk(
  ROUTINE.GET_ROUTINE_STANDARD_FREQUENCY_LIST,
  async (
    { page, size, limit, standardUUID, property_uuid },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    if (page) params.append('page', page);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);
    if (standardUUID) params.append('standard_id', standardUUID);
    if (property_uuid) params.append('property_uuid', property_uuid);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.ROUTINE.GET_ROUTINE_STANDARD_FREQUENCY_LIST}?${params?.toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Routine List API
export const getRoutineList = createAsyncThunk(
  ROUTINE.GET_ROUTINE_LIST,
  async ({ page, size, limit, propertyUUID, type }, { rejectWithValue }) => {
    const params = new URLSearchParams();

    if (page) params.append('page', page);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);
    if (type) params.append('type', type);
    if (propertyUUID) params.append('property_uuid', propertyUUID);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.ROUTINE.GET_ROUTINE_LIST}?${params?.toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createUpdateRoutine = createAsyncThunk(
  ROUTINE.CREATE_UPDATE_ROUTINE,
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.post(
        APIs.ROUTINE.CREATE_UPDATE_ROUTINE,
        data
      );

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

// Get Property Routine
export const getPropertyRoutine = createAsyncThunk(
  ROUTINE.GET_PROPERTY_ROUTINE,
  async (
    {
      page,
      size,
      limit,
      standardUUID,
      property_uuid,
      type,
      search,
      is_asset,
      standards,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    if (page) params.append('page', page);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);
    if (standardUUID) params.append('standard_id', standardUUID);
    if (property_uuid) params.append('property_uuid', property_uuid);
    if (type) params.append('type', type);
    if (search) params.append('search', search);
    if (is_asset) params.append('is_asset', is_asset);
    if (standards) params.append('standards', standards);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.ROUTINE.GET_PROPERTY_ROUTINE}?${params?.toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
