import { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { Box, Card, Checkbox, Grid, styled, Typography } from '@mui/material';
import moment from 'moment';

import { FlexEnd, FlexStart, SpaceBetween } from '../../assets/commonStyled';
import { DIVIDER_COLOR, SECONDARY } from '../../constants/Colors';
import {
  CHART_CONST,
  DASHBOARD_CHART_LABEL_TYPE,
  MONTHS_DROPDOWN,
  redirectURL,
  TAB_STATUS,
} from '../../constants/Constants';
import { GLOBAL_ADMIN } from '../../constants/Roles';
import {
  getWidgetDataByUser,
  getWidgetDataRole,
  updateCharts,
} from '../../store/CommonAPI';
import {
  getActiveInactiveCompany,
  getFireCompaniesChartData,
  getGlobalAdminDashboardCount,
  getPropertiesChartData,
  getUsersChartData,
} from '../../store/globalAdmin/api';
import {
  displayChart,
  getMergedArray,
  getWidgetChartData,
  searchFromArray,
} from '../../utils';
import CustomButton from '../CommonComponents/CustomButton';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import { RefreshDashboardButton } from '../CommonComponents/FilterButton';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import CustomSearch from '../CommonComponents/Search';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import ChartCard from './ChartCard';
import DraggableChart from './DraggableChart';
import DroppableArea from './DroppableArea';

const CardWrapper = styled(Card)(({ theme, clickable }) => ({
  padding: '16px',
  width: '100%',
  ...(clickable && { cursor: 'pointer' }),
}));

const GlobalAdminDashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [layout, setLayout] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [search, setSearch] = useState('');

  const [ranges, setRanges] = useState(MONTHS_DROPDOWN[0]);

  const [activeCharts, setActiveCharts] = useState([]);
  const [fileredValue, setFilteredValue] = useState(activeCharts);
  const [refresh, setRefresh] = useState(false);

  const [chartData, setChartData] = useState({});

  const [carData, setCardData] = useState([
    {
      id: TAB_STATUS.active,
      status: TAB_STATUS.active,
      title: `${t('common.active')} ${t('attributes.company.company')}`,
      redirectModule: CHART_CONST.FIRE_COMPANY,
      clickable: true,
      count: 0,
    },
    {
      id: TAB_STATUS.inactive,
      status: TAB_STATUS.inactive,
      title: `${t('common.inactive')} ${t('attributes.company.company')}`,
      redirectModule: CHART_CONST.FIRE_COMPANY,
      clickable: true,
      count: 0,
    },
    {
      id: 'users',
      status: TAB_STATUS.active,
      title: t('common.total_users_of_stam'),
      clickable: true,
      redirectModule: CHART_CONST.USER_MANAGEMENT,
      count: 0,
    },
  ]);

  const [isManageDashboard, setIsManageDashboard] = useState(false);
  const navigate = useNavigate();

  const { profileDetails, widget } = useSelector((state) => state.common);

  useEffect(() => {
    if (profileDetails?.id) {
      dispatch(getWidgetDataRole(profileDetails?.role_name)).finally(() =>
        setIsLoading(false)
      );
      dispatch(getWidgetDataByUser(profileDetails?.uuid)).finally(() =>
        setIsLoading(false)
      );
    }
  }, [dispatch, refresh]);

  useEffect(() => {
    dispatch(
      getActiveInactiveCompany({
        start_date: moment().subtract(ranges.value, 'months'),
        end_date: moment(),
      })
    ).then((res) =>
      setChartData((prev) => ({
        ...prev,
        [CHART_CONST.FIRE_COMPANY_STATUS]: res?.payload?.data,
      }))
    );
  }, [ranges, refresh, dispatch]);

  useEffect(() => {
    dispatch(getPropertiesChartData()).then((res) =>
      setChartData((prev) => ({
        ...prev,
        [CHART_CONST.CUSTOMER_PROPERTIES]: res?.payload?.data,
      }))
    );
    dispatch(getFireCompaniesChartData()).then((res) =>
      setChartData((prev) => ({
        ...prev,
        [CHART_CONST.FIRE_COMPANY]: res?.payload?.data,
      }))
    );
    dispatch(getUsersChartData()).then((res) => {
      setChartData((prev) => ({
        ...prev,
        [CHART_CONST.USERS]: res?.payload?.data,
      }));
      const totalActiveUsers = res?.payload?.data?.reduce(
        (total = 0, current) => current?.count + total,
        0
      );

      setCardData((prev) =>
        prev?.map((p) => {
          if (p.id === 'users') {
            return { ...p, count: totalActiveUsers };
          }

          return p;
        })
      );
    });

    dispatch(getGlobalAdminDashboardCount()).then((res) => {
      let activeCount = 0;
      let inActiveCount = 0;

      res?.payload?.data?.forEach((d) => {
        d?.active ? (activeCount = +d?.count) : (inActiveCount = +d?.count);
      });
      setCardData((prev) =>
        prev?.map((p) => {
          if (p?.id === TAB_STATUS.active) {
            return { ...p, count: activeCount };
          }
          if (p?.id === TAB_STATUS.inactive) {
            return { ...p, count: inActiveCount };
          }

          return p;
        })
      );
    });
  }, [dispatch, refresh]);

  useEffect(() => {
    if (widget?.data?.length) {
      const mergedArray = widget?.userChartData?.length
        ? getMergedArray(widget?.data, widget?.userChartData)
        : widget?.data;

      // Initial layout with names
      const latestLayout = mergedArray
        ?.map((chart, index) => {
          const displayData = {};

          chart?.supported_types?.forEach((el) => {
            displayData[el?.name] = getWidgetChartData(
              chart?.name,
              el?.name,
              chartData[chart?.name],
              ranges?.value
            );
          });

          return {
            ...chart,
            chart_name: chart?.name,
            name: chart?.display_name,
            type: chart?.selected_type
              ? chart?.selected_type?.name
              : chart?.default_type?.name,
            selected_type: chart?.selected_type
              ? {
                  ...chart?.selected_type,
                  label: chart?.selected_type?.display_name,
                  value: chart?.selected_type?.name,
                }
              : {
                  ...chart?.default_type,
                  label: chart?.default_type?.display_name,
                  value: chart?.default_type?.name,
                },
            supported_types: chart?.supported_types?.map((st) => ({
              ...st,
              label: st.display_name,
              value: st?.name,
            })),
            order: chart?.order || index + 1,
            widget_data: displayData,
            label_type: DASHBOARD_CHART_LABEL_TYPE.VALUE,
          };
        })
        .sort((a, b) => a.order - b.order);

      setLayout(latestLayout?.filter((c) => c?.is_active));
      setActiveCharts(latestLayout);
    }
  }, [
    widget?.data,
    widget?.userChartData,
    isLoading,
    widget?.isLoading,
    chartData,
    refresh,
    ranges,
  ]);

  const restoreDefault = () => {
    const defaultSettings = layout?.map((chart) => ({
      ...chart,
      chart_name: chart?.name,
      name: chart?.display_name,
      type: chart?.default_type?.name,
      selected_type: {
        ...chart?.default_type,
        label: chart?.default_type?.display_name,
        value: chart?.default_type?.name,
      },
      supported_types: chart?.supported_types?.map((st) => ({
        ...st,
        label: st.display_name,
        value: st?.name,
      })),
      is_active: true,
    }));

    setLayout(defaultSettings?.filter((c) => c?.is_active));
    setActiveCharts(defaultSettings);
  };

  const handleDrop = useCallback((draggedChartId, targetAreaId) => {
    setLayout((prevLayout) => {
      const newLayout = prevLayout?.map((pl) => {
        if (pl.id === draggedChartId || pl?.order === targetAreaId) {
          if (pl.id === draggedChartId) {
            return { ...pl, order: targetAreaId };
          }
          const oldItemPosition = prevLayout?.find(
            (pl) => pl?.id === draggedChartId
          )?.order;

          return { ...pl, order: oldItemPosition };
        }

        return pl;
      });

      return newLayout.sort((a, b) => a.order - b.order);
    });
  }, []);

  const onChartRemove = (id) => {
    const updatedActiveCharts = activeCharts?.map((c) => {
      if (c?.id === id) {
        return { ...c, is_active: false };
      }

      return c;
    });

    setLayout(updatedActiveCharts);
    setActiveCharts(updatedActiveCharts);
  };

  const chartTypeHandler = (id, val) => {
    const updatedLayout = layout.map((chart) => {
      if (chart.id === id) {
        let updatedChartData = {
          ...chart,
          type: val.value,
          selected_type: val,
          data: chart?.widget_data[val.value]?.data,
          colors: chart?.widget_data[val.value]?.colors,
          xLabel: chart?.widget_data[val.value]?.labels,
        };

        return updatedChartData;
      }

      return chart;
    });

    setLayout(updatedLayout);
  };

  const displayChartHandler = (id, isChecked) => {
    const updatedCharts = activeCharts?.map((c) => {
      if (c?.id === id) {
        return { ...c, is_active: isChecked };
      }

      return c;
    });

    setActiveCharts(updatedCharts);
  };

  const updateChartDataHandler = () => {
    const updatedData = {
      widgets: layout?.map((l, index) => ({
        widget_id: l?.id,
        type_id: l?.selected_type?.id,
        order: l?.order || index + 1,
        is_active: l?.is_active,
      })),
    };

    dispatch(
      updateCharts({ uuid: profileDetails?.uuid, data: updatedData })
    ).then((res) => dispatch(getWidgetDataByUser(profileDetails?.uuid)));
  };

  useEffect(() => {
    setFilteredValue(activeCharts);
  }, [activeCharts, refresh]);

  const onCloseSidebar = () => {
    setSearch('');
    setFilteredValue(activeCharts);
    setOpen(false);
  };

  return (
    <>
      <SpaceBetween sx={{ flexWrap: 'wrap', rowGap: '8px' }}>
        <Typography variant="body1">
          {t('common.welcome')}, {profileDetails?.name || '-'}
        </Typography>
        <FlexEnd>
          <RefreshDashboardButton onClick={() => setRefresh(!refresh)} />
          {!isManageDashboard && (
            <CustomButton
              startIcon={<WidgetsOutlinedIcon />}
              text={t('common.manage_dashboard')}
              onClick={() => setIsManageDashboard(true)}
            />
          )}
        </FlexEnd>
      </SpaceBetween>
      {isManageDashboard && (
        <SpaceBetween
          sx={{
            background: SECONDARY,
            padding: '12px',
            marginTop: '16px',
            borderRadius: '6px',
            flexWrap: 'wrap',
            rowGap: '8px',
          }}
        >
          <FlexStart>
            <CustomButton
              startIcon={<AddchartOutlinedIcon />}
              text={t('common.add_widget')}
              variant="text"
              onClick={() => setOpen(true)}
              color="inherit"
              sx={{
                bgcolor: SECONDARY,
              }}
            />
            <CustomButton
              startIcon={<SaveOutlinedIcon />}
              text={t('attributes.save')}
              variant="text"
              onClick={updateChartDataHandler}
              color="inherit"
              sx={{
                bgcolor: SECONDARY,
              }}
            />
            <CustomButton
              startIcon={<RestoreOutlinedIcon />}
              text={t('common.restore_dashboard')}
              variant="text"
              onClick={restoreDefault}
              color="inherit"
              sx={{
                bgcolor: SECONDARY,
              }}
            />
          </FlexStart>
          <CustomButton
            startIcon={<CancelOutlinedIcon />}
            text={t('attributes.close')}
            variant="outlined"
            color="inherit"
            size="small"
            sx={{
              bgcolor: SECONDARY,
              height: '26px',
            }}
            onClick={() => setIsManageDashboard(false)}
          />
        </SpaceBetween>
      )}

      {isLoading || widget?.isLoading ? (
        <CustomCircularLoader />
      ) : !widget?.isLoading && !isLoading && layout?.length == 0 ? (
        <NewNoDataPage
          icon={<DashboardOutlinedIcon />}
          title={t('attributes.dashboard')}
          singularText="chart"
          filterHeight={isManageDashboard ? 422 : 350}
          createBtnText={t('common.add_widget')}
        />
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              marginTop: '16px',
              width: '100%',
              columnGap: '16px',
            }}
          >
            {carData?.map((cd, index) => (
              <CardWrapper
                key={index}
                clickable={cd.clickable}
                onClick={() =>
                  cd.clickable &&
                  navigate(
                    `${redirectURL[GLOBAL_ADMIN][cd?.redirectModule]}?status=${cd?.status}`
                  )
                }
              >
                <Typography variant="body1">{cd?.title}</Typography>
                <Typography
                  variant="common600"
                  fontSize={21}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '16px',
                  }}
                >
                  {cd?.count}
                </Typography>
              </CardWrapper>
            ))}
          </Box>

          <DndProvider backend={HTML5Backend}>
            <Box style={{ marginTop: '16px' }}>
              <Grid container spacing={2}>
                {/* Dynamically generated droppable areas */}
                {layout
                  ?.filter((l) => l?.is_active)
                  .map((item, index) => (
                    <Grid item xs={12} lg={6} xl={4} key={`area${index + 1}`}>
                      <DroppableArea id={index + 1} onDrop={handleDrop}>
                        {isManageDashboard ? (
                          <DraggableChart id={item.id}>
                            <ChartCard
                              title={item.name}
                              id={item.id}
                              options={item?.supported_types}
                              isSettingMode={isManageDashboard}
                              selectedType={item?.selected_type}
                              setSelectedType={(id, val) =>
                                chartTypeHandler(id, val)
                              }
                              onChartRemove={(id) => onChartRemove(id)}
                            >
                              {displayChart(item)}
                            </ChartCard>
                          </DraggableChart>
                        ) : (
                          <ChartCard
                            title={item.name}
                            id={item.id}
                            isSettingMode={isManageDashboard}
                            monthOptions={
                              item?.chart_name ===
                                CHART_CONST.FIRE_COMPANY_STATUS &&
                              MONTHS_DROPDOWN
                            }
                            selectedMonth={ranges}
                            setSelectedMonth={(e, newVal) => setRanges(newVal)}
                          >
                            {displayChart(item)}
                          </ChartCard>
                        )}
                      </DroppableArea>
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </DndProvider>
        </>
      )}

      <SwipeableDrawer
        open={open}
        title={t('common.add_widgets')}
        onClose={onCloseSidebar}
        footerButton={
          <CustomButton
            text="Add"
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => {
              onCloseSidebar();
              setLayout(activeCharts);
            }}
          />
        }
        width="300px"
        fontWeight={600}
        bgColor={SECONDARY}
      >
        <CustomSearch
          width="100%"
          value={search}
          onChange={(e) => {
            setSearch(e?.target?.value);
            const filterResult = searchFromArray(
              e?.target?.value,
              activeCharts
            );

            setFilteredValue(filterResult);
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '8px',
            marginTop: '8px',
          }}
        >
          {fileredValue?.map((chart, index) => (
            <Card
              key={index}
              sx={{
                padding: '8px',
                borderRadius: '4px',
                border: `1px solid ${DIVIDER_COLOR}`,
                boxShadow: 'none',
                display: 'flex',
                columnGap: '16px',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <Checkbox
                  checked={chart?.is_active}
                  onChange={(e) =>
                    displayChartHandler(chart?.id, e.target.checked)
                  }
                  color="primary"
                  heigth="16px"
                  sx={{ padding: '2px 8px', height: 'auto' }}
                />
                <Typography variant="body1">{chart?.display_name}</Typography>
              </Box>

              <Typography
                variant="body2"
                sx={{ marginLeft: '14%', marginTop: '8px' }}
              >
                {chart?.description}
              </Typography>
            </Card>
          ))}
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default GlobalAdminDashboard;
