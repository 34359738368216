import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Divider, Typography } from '@mui/material';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../assets/commonStyled';
import { validateAddress } from '../../constants/common';
import { Validation } from '../../constants/FieldValidationMsg';
import { EMAIL_REGEX } from '../../constants/Regex';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getStatesHook from '../../hooks/getStatesHook';
import useAddressDetail from '../../hooks/useAddressDetail';
import useAutocompleteAddress from '../../hooks/useAutcompleteAddress';
import Autocomplete from '../CommonComponents/AutoComplete';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomTextField from '../CommonComponents/CustomTextField';

const SuperAdminForm = ({
  id,
  clearErrors,
  control,
  trigger,
  watch,
  setValue,
  setServerErrors,
  setError,
}) => {
  const { t } = useTranslation();

  const {
    isLoading: isAutocompleteAddressLoading,
    data: autocompleteAddressData,
  } = useSelector((state) => state.googleAPIs.autocompleteAddress);

  const selectedState = watch('superadmin_state');
  const typedValue = watch('superadmin_searchAddress');
  const stateList = getStatesHook();
  const cityList = getCityByStateHook(selectedState?.value);
  const [searchAddress, setSearchAddress] = useState('');

  useAutocompleteAddress(searchAddress);
  useAddressDetail({
    selectedAddress: typedValue,
    setValue: setValue,
    fieldName: {
      address1: 'superadmin_address',
      stateKey: 'superadmin_state',
      cityKey: 'superadmin_city',
      postCodeKey: 'superadmin_zip_code',
      address2: 'superadmin_address_line_2',
    },
  });

  const clearHandler = (name) => setValue(name, '');

  const postCodeLength = 4;

  const [addressBlur, setAddressBlur] = useState(false);
  const [address2Blur, setAddress2Blur] = useState(false);
  const [stateBlur, setStateBlur] = useState(false);
  const [cityBlur, setCityBlur] = useState(false);
  const [postcodeBlur, setPostcodeBlur] = useState(false);
  const [addressRecheck, setAddressRecheck] = useState(true);
  const address = watch('superadmin_address');
  const state = watch('superadmin_state');
  const city = watch('superadmin_city');
  const postcode = watch('superadmin_zip_code');
  const addressLine2 = watch('superadmin_address_line_2');

  useEffect(() => {
    if (
      address &&
      state &&
      (city || true) &&
      postcode &&
      (addressLine2 || true)
    ) {
      setAddressBlur(true);
      setStateBlur(true);
      setCityBlur(true);
      setPostcodeBlur(true);
      setAddressRecheck(true);
      setAddress2Blur(true);
      trigger('superadmin_address');
      trigger('superadmin_state');
      trigger('superadmin_city');
      trigger('superadmin_zip_code');

      trigger('superadmin_address_line_2');
    }
  }, [address, state, city, postcode, address2Blur]);

  useEffect(() => {
    validateAddress({
      address1: address,
      address2: addressLine2,
      state: state,
      city: city,
      setError,
      postcode: postcode,
      clearErrors,
      addressRecheck,
      fieldName: {
        stateKey: 'superadmin_state',
        stateValue: t('common.state'),
        cityKey: 'superadmin_city',
        cityValue: t('common.city'),
        postCodeKey: 'superadmin_zip_code',
        postCodeValue: t('attributes.postCode'),
        address1Key: 'superadmin_address',
        address1Value: t('attributes.addressLine1'),
        address2Key: 'superadmin_address_line_2',
        address2Value: t('attributes.addressLine2'),
      },
      setServerErrors,
    });
  }, [addressBlur, address2Blur, stateBlur, cityBlur, postcodeBlur]);

  return (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">
            {`${t('roles.superAdmin')} ${t('attributes.information')}`}
          </Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: `${t('attributes.name')} ${Validation.general.required}`,
                maxLength: {
                  value: 80,
                  message: Validation.general.contactName,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.name')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('name');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
            <Controller
              name={'superadmin_cell_phone'}
              control={control}
              rules={{
                // required: `${t('attributes.company.cell_phone')} ${Validation.general.required}`,
                pattern: {
                  value: /^\d{0,50}$/,
                  message: Validation.general.cellPhone,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.company.cell_phone')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '');

                    onChange(numericValue);
                    trigger('superadmin_cell_phone');
                  }}
                  isRequired={false}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
          </Box>
          <Controller
            name={'superadmin_email'}
            control={control}
            rules={{
              required: `${t('attributes.email')} ${Validation.general.required}`,
              maxLength: {
                value: 254,
                message: Validation.general.email,
              },
              pattern: {
                value: EMAIL_REGEX,
                message: Validation.general.validEmail,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.email')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('superadmin_email');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
                disabled={id}
              />
            )}
          />
          <Controller
            name={'superadmin_searchAddress'}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                label={t('common.searchAddress')}
                options={autocompleteAddressData || []}
                value={value}
                isRequired={false}
                isLoadingData={isAutocompleteAddressLoading}
                onChange={(e, newValue) => {
                  onChange(newValue);
                  setSearchAddress(e.target?.value);
                }}
                helperText={error ? error.message : ''}
                error={error}
              />
            )}
          />
          <Controller
            name={'superadmin_address'}
            control={control}
            rules={{
              required: `${t('attributes.addressLine1')} ${Validation.general.required}`,
              maxLength: {
                value: 300,
                message: Validation.general.address1,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.addressLine1')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('superadmin_address');
                }}
                multiline
                rows={2}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
                setBlur={setAddressBlur}
              />
            )}
          />
          <Controller
            name={'superadmin_address_line_2'}
            control={control}
            rules={{
              maxLength: {
                value: 300,
                message: Validation.general.address2,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.addressLine2')}
                fullWidth
                value={value || ''}
                onChange={(e) => {
                  onChange(e);
                  trigger('superadmin_address_line_2');
                }}
                isRequired={false}
                multiline
                rows={2}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
                setBlur={setAddress2Blur}
              />
            )}
          />

          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name={'superadmin_state'}
              control={control}
              rules={{
                required: `${t('common.state')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={t('common.state')}
                  options={stateList}
                  value={value}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    clearErrors('superadmin_city');
                    setValue('superadmin_city', null);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  setBlur={setStateBlur}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
            <Controller
              name={'superadmin_city'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={t('common.city')}
                  options={cityList}
                  value={value}
                  onChange={(e, newValue) => onChange(newValue)}
                  helperText={error ? error.message : ''}
                  error={error}
                  disabledDropdown={!selectedState}
                  setBlur={setCityBlur}
                  isRequired={false}
                />
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name={'superadmin_zip_code'}
              control={control}
              rules={{
                required: `${t('attributes.postCode')} ${Validation.general.required}`,
                validate: (value) => {
                  if (value && !/^\d+$/.test(value)) {
                    return Validation.general.numericValue;
                  }

                  return true;
                },
                minLength: {
                  value: postCodeLength,
                  message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                },
                maxLength: {
                  value: postCodeLength,
                  message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.postCode')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '');

                    onChange(numericValue);
                    trigger('superadmin_zip_code');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                  setBlur={setPostcodeBlur}
                />
              )}
            />
          </Box>
        </FormFieldWrapper>
      </CustomCard>
      {id && (
        <CustomCard>
          <Box sx={{ px: '16px' }}>
            <CommonCheckbox
              control={control}
              name="superadmin_active"
              label={t('common.active')}
              isRequired={false}
              showMessage={true} // Enable message display
              messageOnChecked={t('label.superAdmin.superAdminActiveCheckbox')}
              messageOnUnchecked={t(
                'label.superAdmin.superAdminInactiveCheckbox'
              )}
            />
          </Box>
        </CustomCard>
      )}
    </FormWrapper>
  );
};

export default SuperAdminForm;
