import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CloseOutlined } from '@mui/icons-material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {
  Backdrop,
  Box,
  Divider,
  Modal,
  Typography,
  styled,
} from '@mui/material';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../assets/commonStyled';
import { DIVIDER_COLOR, PRIMARY, SECONDARY } from '../../constants/Colors';
import { Validation } from '../../constants/FieldValidationMsg';
// eslint-disable-next-line
import { createCity, getCityList } from '../../store/city/api';
import { resetCreateCity } from '../../store/city/reducer';
import { getLocation } from '../../store/CommonAPI';
import { snackbarToggle } from '../../store/CommonReducer';
import { clearTextfields, showHideTopbarLoader } from '../../utils';
import Autocomplete from './AutoComplete';
import CustomButton from './CustomButton';
import CustomTextField from './CustomTextField';

const modalStyle = {
  position: 'absolute',
  top: '12%',
  left: '50%',
  transform: 'translate(-50%, 0)',
  width: 800,
  bgcolor: DIVIDER_COLOR,
  borderRadius: '4px',
  overflow: 'hidden',
  Height: '700px',
};

const TitleStyled = styled(Box)(({ theme }) => ({
  backgroundColor: PRIMARY,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px',
  borderRadius: '4px 4px 0 0',
}));

const DescriptionStyled = styled(Box)(() => ({
  padding: '16px',
}));

const FooterButton = styled(Box)({
  backgroundColor: SECONDARY,
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '8px',
});

export const defaultValues = {
  name: '',
  parent_id: null,
};

const CreateCityModal = ({
  open,
  state,
  setOpen,
  onConfirm = () => {},
  setServerErrors,
  buttonDisabled,
}) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    clearErrors,
  } = useForm({
    defaultValues: defaultValues,
  });

  const dispatch = useDispatch();
  const {
    loading: createLoading,
    data: createCityData,
    error,
  } = useSelector((state) => state.city.createCity);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { loading, data } = useSelector((state) => state.city.getCityList);

  const handleOnSubmit = (data, event) => {
    event?.stopPropagation();
    setIsButtonDisabled(true);

    const request = {
      name: data?.name,
      type: 'city',
      parent_id: data?.parent_id?.value,
    };

    dispatch(createCity({ data: request }));
  };

  useEffect(() => {
    dispatch(getCityList({ include_ids: true, active: false }));

    return () => {
      dispatch(resetCreateCity());
      reset({
        name: '',
        parent_id: null,
      });
    };
  }, []);

  const stateList =
    data?.states?.map((state) => ({
      label: state?.name,
      value: state?.id,
    })) || [];

  const resetValues = () =>
    reset({
      parent_id: null,
      name: '',
    });

  useEffect(() => {
    if (state && stateList) {
      setValue(
        'parent_id',
        stateList?.filter((val) => val.label === state.label)?.[0]
      );
    } else {
      setValue('parent_id', null);
    }
  }, [state, stateList]);

  useEffect(() => {
    showHideTopbarLoader(createLoading, dispatch);
    if (createLoading !== null && !createLoading) {
      if (error) {
        if (error.errorDetails) {
          setServerErrors(error.errorDetails);
        } else {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: error?.message,
            })
          );
        }
      } else {
        if (createCityData) {
          setOpen(false);
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: createCityData?.message,
            })
          );
          dispatch(getLocation());
        }
      }
      dispatch(resetCreateCity());
      resetValues();
      setIsButtonDisabled(false);
    }
  }, [error, createLoading]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={open}
      onClose={() => {
        setOpen(false);
        resetValues();
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
    >
      <Box
        component="form"
        id="createCityForm"
        onSubmit={handleSubmit(handleOnSubmit)}
        sx={modalStyle}
      >
        <TitleStyled>
          <Typography variant="h1">{t('attributes.createNewCity')}</Typography>
          <CloseOutlined
            onClick={() => setOpen(false)}
            style={{ cursor: 'pointer' }}
          />
        </TitleStyled>
        <DescriptionStyled>
          <FormWrapper>
            <CustomCard>
              <Box sx={{ padding: '16px' }}>
                <Typography variant="body1">
                  {t('attributes.billingCard.contactDetails')}
                </Typography>
              </Box>
              <Divider />
              <FormFieldWrapper>
                <Controller
                  name="parent_id"
                  control={control}
                  rules={{
                    required: `${t('common.state')} ${Validation.general.required}`,
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      label={t('common.state')}
                      options={stateList}
                      value={value || null}
                      onChange={(e, newValue) => {
                        if (newValue || newValue === null) {
                          onChange(newValue);
                        }
                        trigger('parent_id');
                      }}
                      helperText={error ? error.message : ''}
                      isLoadingData={loading}
                      error={error}
                      onClear={() => clearTextfields(setValue, name)}
                      isRequired={true}
                      disablePortal={false}
                    />
                  )}
                />
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: `${t('common.city')} ${Validation.general.required}`,
                  }}
                  render={({
                    field: { onChange, value, name, onBlur },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('common.city')}
                      value={value || ''}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearTextfields(setValue, name)}
                      isRequired={true}
                      ListboxProps={{
                        style: {
                          maxHeight: '272px',
                        },
                      }}
                      onBlur={() => {
                        onChange(value.trim());
                        onBlur();
                      }}
                    />
                  )}
                />
              </FormFieldWrapper>
            </CustomCard>
          </FormWrapper>
        </DescriptionStyled>

        <FooterButton>
          <CustomButton
            startIcon={<AddCircleOutlineOutlinedIcon />}
            text={isButtonDisabled ? t('common.loading') : t('attributes.add')}
            type="submit"
            form="createCityForm"
            disabled={isButtonDisabled}
          />
        </FooterButton>
      </Box>
    </Modal>
  );
};

export default CreateCityModal;
