import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import GridOffOutlinedIcon from '@mui/icons-material/GridOffOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box, Divider, Typography } from '@mui/material';

import { DIVIDER_COLOR, SECONDARY } from '../../../constants/Colors';
import {
  CREATED_STATUS,
  CUSTOMER_QUOTATION_STATUS,
  DRAFT_STATUS,
  SUBMITTED,
  SUBMIT_TO_CUSTOMER_STATUS,
  TAX_NAME,
  TAX_VALUE,
  TEMPLATE_SET,
} from '../../../constants/Constants';
import { SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH } from '../../../constants/Typography';
import useBrowserBackButtonHandler from '../../../hooks/useBrowserBackButtonHandler';
import useDetailPageValidation from '../../../hooks/useDetailPageValidation';
import usePreviewAndDownloadTemplate from '../../../hooks/usePreviewAndDownloadTemplate';
import { snackbarToggle } from '../../../store/CommonReducer';
import { editCompany } from '../../../store/company/api';
import { editContact } from '../../../store/contacts/api';
import { resetEdit } from '../../../store/contacts/reducer';
import { getProperty } from '../../../store/property/api';
import {
  deleteDefectQuotationProduct,
  getDefectQuotesDetail,
  postAddDefectQuoteProduct,
  updateDefectQuotationProduct,
  updateDefectQuotationStatus,
} from '../../../store/workOrder/api';
import {
  resetAddDefectProductData,
  resetDefectQuotesDetail,
  resetUpdateQuotationItems,
  resetUpdateQuotationStatus,
  resetdeleteQuotationItems,
} from '../../../store/workOrder/reducer';
import { calculateTotal } from '../../../utils';
import { Delete, Edit } from '../../CommonComponents/ActionComponent';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import { ConfirmationModal } from '../../CommonComponents/Modal';
import NewNoDataPage from '../../CommonComponents/NoDataPage/NewNoDataPage';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import AddProduct from '../../WorkOrder/AddDefectQuotesProduct';
import { formatDefectQuotesStatus } from '../../WorkOrder/DefectQuotes';
import EditDefectQuotesProduct from '../../WorkOrder/EditDefectQuotesProduct';

// Styles
const headerStyle = {
  padding: '7px',
  border: '1px solid #EBEBEB',
  textAlign: 'left',
  backgroundColor: '#ebebeb19',
};

const veryNarrowStyle = {
  width: '60px',
};

const narrowStyle = {
  width: '80px',
};

const cellStyle = {
  padding: '7px',
  border: '1px solid #EBEBEB',
  textAlign: 'left',
};

const cellStyleRight = {
  padding: '7px',
  border: '1px solid #EBEBEB',
  textAlign: 'right',
};

const defaultValues = {
  defect_title: null,
  type: null,
  search: '',
};

const ServiceQuotesDetail = ({
  isFromServiceQuotes,
  setRefresh = () => {},
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user_info = localStorage.getItem('user_info');
  const user = user_info ? JSON.parse(user_info) : null;

  const { isLoading, data, error } = useSelector(
    (state) => state.workOrder.defectQuotesDetail
  );

  const {
    isLoading: addedDefectQuoteProductLoading,
    data: addedDefectQuoteProduct,
  } = useSelector((state) => state.workOrder.addDefectQuoteProduct);

  const {
    isLoading: updatedQuotiationProductLoading,
    data: updatedQuotiationProduct,
  } = useSelector((state) => state.workOrder.updateQuotationItems);

  const {
    isLoading: deleteQuotiationProductLoading,
    data: deleteQuotiationProduct,
  } = useSelector((state) => state.workOrder.deleteQuotationItems);

  const { isLoading: isLoadingCompany, company } = useSelector(
    (state) => state.company.edit
  );

  const { isLoading: updateQuotiationLoading, data: updateQuotiationData } =
    useSelector((state) => state.workOrder.updateQuotationStatus);

  const { isLoading: contactLoading, contact } = useSelector(
    (state) => state.contact.edit
  );

  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [selectedProductsError, setSelectedProductsError] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [isPropertyLoading, setIsPropertyLoading] = useState(false);
  const [propertyData, setPropertyData] = useState([]);
  const [hasFormValues, sethasFormValues] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [editProductDetail, setEditProductDetail] = useState(null);
  const [editProductFormOpen, setEditProductFormOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteProductDetail, setDeleteProductDetail] = useState(null);

  const { handleSubmit, control, watch, reset, setValue, trigger } = useForm({
    defaultValues: defaultValues,
  });

  useBrowserBackButtonHandler({
    hasUnsavedChanges: hasFormValues,
    setOpenConfirmationModal,
    open,
    watchMethod: watch,
    sethasFormValues,
  });

  useDetailPageValidation({
    is_error: true,
    error: error,
    is_loading: isLoading,
  });

  const { listTemplateLoading, generatePDF, PreviewTemplatePDF } =
    usePreviewAndDownloadTemplate({
      module: TEMPLATE_SET.DEFECT_QUOTES,
      data: {
        companyData: company,
        propertyData,
        isPropertyLoading: isPropertyLoading || isLoading || contactLoading,
        quoteData: data,
        isFromServiceQuotes: true,
        contact,
      },
    });

  const updateDefectQuoteDetail = () => {
    if (data?.uuid) {
      dispatch(
        getDefectQuotesDetail({
          quote_id: data?.uuid,
        })
      );
    }
  };

  useEffect(
    () => () => {
      dispatch(resetDefectQuotesDetail());
      dispatch(resetUpdateQuotationStatus());
      dispatch(resetAddDefectProductData());
      dispatch(resetUpdateQuotationItems());
      dispatch(resetdeleteQuotationItems());
      dispatch(resetEdit());
    },
    []
  );

  useEffect(() => {
    if (!addedDefectQuoteProductLoading) {
      if (Object.keys(addedDefectQuoteProduct).length > 0) {
        setEditProductFormOpen(false);
        setDisableSave(false);
        setRefresh((prev) => !prev);
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: t(
              'attributes.service_quotes.serviceQuoteAddProductSuccessMsg'
            ),
          })
        );
        setOpenAddProduct(false);
        updateDefectQuoteDetail();
      }
    }
  }, [addedDefectQuoteProductLoading, addedDefectQuoteProduct]);

  useEffect(() => {
    if (
      updatedQuotiationProductLoading !== null &&
      !updatedQuotiationProductLoading
    ) {
      if (updatedQuotiationProduct?.data?.length > 0) {
        setEditProductFormOpen(false);
        setDisableSave(false);
        setRefresh((prev) => !prev);

        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: t(
              'attributes.service_quotes.serviceQuoteUpdateProductSuccessMsg'
            ),
          })
        );
        updateDefectQuoteDetail();
      }
    }
  }, [updatedQuotiationProductLoading, updatedQuotiationProduct]);

  useEffect(() => {
    if (!deleteQuotiationProductLoading) {
      if (deleteQuotiationProduct) {
        setRefresh((prev) => !prev);
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: deleteQuotiationProduct.message,
          })
        );
        updateDefectQuoteDetail();
      }
    }
  }, [deleteQuotiationProductLoading, deleteQuotiationProduct]);

  useEffect(() => {
    if (!updateQuotiationLoading) {
      if (updateQuotiationData?.data?.length > 0) {
        updateDefectQuoteDetail();
        setOpenPreview(false);
        setRefresh((prev) => !prev);
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg:
              updateQuotiationData?.data[0]?.status !== SUBMITTED
                ? t('attributes.service_quotes.serviceQuoteUpdatedSuccessMsg')
                : t('attributes.work_order.quotationSuccessMessage'),
          })
        );
      }
    }
  }, [updateQuotiationData, updateQuotiationLoading]);

  const sendDefectQuotation = (status) => {
    dispatch(
      updateDefectQuotationStatus({
        work_order_id: null,
        quote_id: data?.uuid,
        status: status,
      })
    );
  };

  const defect_quotes_header_options = useMemo(() => {
    // List of statuses that should hide certain buttons
    const statusToHideButtons = [
      CUSTOMER_QUOTATION_STATUS.PENDING,
      CUSTOMER_QUOTATION_STATUS.APPROVED,
      CUSTOMER_QUOTATION_STATUS.OVERDUE,
    ];

    return [
      // Add Product button, shown only if the status is not in the list
      ...(statusToHideButtons.includes(data?.status)
        ? []
        : [
            {
              label: `${t('attributes.add')} ${t(
                'attributes.productCatalogue.product'
              )}`,
              onClick: () => {
                setOpenAddProduct(true);
                setSelectedProductsError(false);
                setDisableSave(false);
                reset(defaultValues);
              },
              icon: <ControlPointIcon />,
            },
          ]),
    ];
  }, [user, data]);

  const onSubmit = (formData) => {
    setDisableSave(true);
    if (!formData.selectedProducts || formData.selectedProducts.length === 0) {
      // Set an error for the selectedProducts field
      setSelectedProductsError(
        `${t('common.select_at_least_one_product')} ${t('attributes.service_quotes.to_add_in_serviceQuote_line_items')}`
      );
      setDisableSave(false);

      return; // Prevent form submission
    }
    formData.selectedProducts.forEach((product) => {
      const createProductRequest = {
        product_catalogue_uuid: product.uuid,
        qty: product.qty,
        cost: product.cost,
        sell_price: product.sell_price,
      };

      dispatch(
        postAddDefectQuoteProduct({
          work_order_uuid: null,
          quotation_uuid: data?.uuid,
          data: createProductRequest,
        })
      );
    });
  };

  const onSubmitEditProduct = async (formData) => {
    setDisableSave(true);
    setEditProductDetail(null);
    const createData = {
      qty: formData.qty,
      sell_price: formData.sell_price,
      ...(formData.description && { comment: formData.description }),
    };

    dispatch(
      updateDefectQuotationProduct({
        quotation_item_uuid: formData?.uuid,
        data: createData,
      })
    );
  };

  const columns = [
    {
      field: 'product',
      headerName: t('attributes.purchase_order.product'),
      flex: 1, // 40% width
      sortable: false,
    },
    {
      field: 'description',
      headerName: t('attributes.description'),
      flex: 2, // 10% width
      sortable: false,
    },
    {
      field: 'qty',
      headerName: t('attributes.quantity'),
      flex: 'auto',
      align: 'center',
      sortable: false,
    },
    {
      field: 'sell_price',
      headerName: t('attributes.productCatalogue.sell_price'),
      flex: 'auto',
      align: 'center',
      sortable: false,
    },
    {
      field: 'total',
      headerName: t('attributes.total'),
      flex: 'auto',
      align: 'center',
      sortable: false,
    },
    ...(data?.status !== CUSTOMER_QUOTATION_STATUS.PENDING &&
    data?.status !== CUSTOMER_QUOTATION_STATUS.APPROVED &&
    data?.status !== CUSTOMER_QUOTATION_STATUS.OVERDUE
      ? [
          {
            field: 'actions',
            headerName: t('attributes.actions'),
            flex: 'auto',
            sortable: false,
            renderCell: ({ row }) => (
              <Box sx={{ display: 'flex', gap: '8px' }}>
                <Edit
                  onClick={() => {
                    setEditProductDetail(row);
                    setEditProductFormOpen(true);
                  }}
                />
                <Delete
                  onClick={() => {
                    setDeleteConfirmation(true);
                    setDeleteProductDetail({
                      quotation_item_uuid: row?.id,
                    });
                  }}
                />
              </Box>
            ),
          },
        ]
      : []),
  ];

  const rows = data?.quotation_items?.map((item) => ({
    id: item.uuid,
    uuid: item.uuid,
    product_catalogue: item.product_catalogue,
    product: item?.product_catalogue?.product_name,
    description: item?.comment,
    qty: item?.qty,
    sell_price: item?.sell_price?.toFixed(2),
    total: (item?.qty * item?.sell_price).toFixed(2),
  }));

  const noData = (
    <>
      <NewNoDataPage
        icon={<GridOffOutlinedIcon />}
        title={t('attributes.productCatalogue.products')}
        createBtnText={t('attributes.purchase_order.add_product')}
        singularText={t('attributes.purchase_order.product')}
        filterHeight={380}
        showTitle={false}
      />
    </>
  );

  return (
    <>
      {deleteConfirmation && (
        <ConfirmationModal
          title={t('deleteConfirmationModal.title')}
          description={t('deleteConfirmationModal.description')}
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onConfirm={() => {
            dispatch(
              deleteDefectQuotationProduct({
                quotation_item_uuid: deleteProductDetail?.quotation_item_uuid,
              })
            );
            setDeleteConfirmation(false);
          }}
        />
      )}
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpenAddProduct(false);
            setEditProductDetail(null);
            setEditProductFormOpen(false);
            setOpenConfirmationModal(false);
            sethasFormValues(false);
          }}
        />
      )}
      {(isLoading === null ? true : isLoading) || updateQuotiationLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            display: 'flex',
            paddingTop: '200px',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        (isLoading === null ? true : !isLoading) && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: '12px 16px',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {defect_quotes_header_options.map((val, index) => (
                  <CustomButton
                    key={index}
                    text={val.label}
                    color="secondary"
                    sx={{ height: '52%' }}
                    startIcon={val.icon}
                    onClick={() => {
                      if (val.onClick) {
                        val.onClick();
                      }
                    }}
                  />
                ))}
              </Box>
              <Box>
                <Typography>
                  {formatDefectQuotesStatus(data?.status, t)}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ padding: '16px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: 'calc(100vh - 270px)',
                }}
              >
                {data?.quotation_items?.length === 0 ? (
                  noData
                ) : (
                  <Box
                    sx={{
                      border: `1px solid ${DIVIDER_COLOR}`,
                      borderRadius: '6px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <CustomGridTable
                      columns={columns}
                      rows={rows}
                      total={rows?.length}
                      checkboxSelection={false}
                      paginationRequired={false}
                      filterHeight={438}
                      disableColumnMenu={true}
                      isLoading={false}
                      isTableView={true}
                    />

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        py: 2,
                        pr: 1,
                        gap: 1,
                        border: `1px solid ${DIVIDER_COLOR}`,
                      }}
                    >
                      <Typography variant="body1">
                        {t('common.capital.subtotal')}:
                      </Typography>
                      <Typography variant="body1">
                        {calculateTotal({ data: data, type: 'subtotal' })}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        py: 2,
                        pr: 1,
                        gap: 1,
                        border: `1px solid ${DIVIDER_COLOR}`,
                      }}
                    >
                      <Typography variant="body1">
                        {company?.tax_type && company?.tax_value
                          ? `${company.tax_type} ${company.tax_value}%`
                          : `${TAX_NAME} ${TAX_VALUE}%`}
                        :
                      </Typography>
                      <Typography variant="body1">
                        {calculateTotal({
                          data: data,
                          type: 'gst',
                          ...(company?.tax_value && {
                            taxPercentage: company?.tax_value,
                          }),
                        })}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        py: 2,
                        pr: 1,
                        gap: 1,
                        backgroundColor: 'rgba(235, 235, 235, 0.2)',
                        border: `1px solid ${DIVIDER_COLOR}`,
                      }}
                    >
                      <Typography variant="body1">
                        {t('common.total')}:
                      </Typography>
                      <Typography variant="body1">
                        {calculateTotal({
                          data: data,
                          type: 'total',
                          ...(company?.tax_value && {
                            taxPercentage: company?.tax_value,
                          }),
                        })}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            {data?.quotation_items?.length > 0 && (
              <>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    padding: '8px 16px 8px 16px',
                  }}
                >
                  {
                    <Box sx={{ display: 'flex', gap: '8px' }}>
                      <CustomButton
                        text={t('attributes.preview')}
                        startIcon={<RemoveRedEyeOutlinedIcon />}
                        color="inherit"
                        onClick={() => {
                          setOpenPreview(true);
                          if (user.company_uuid && data.property_uuid) {
                            setIsPropertyLoading(true);
                            dispatch(editCompany(user?.company_uuid));
                            dispatch(getProperty(data?.property_uuid))
                              .then((res) =>
                                setPropertyData(res.payload?.data[0])
                              )
                              .finally(() => setIsPropertyLoading(false));
                            dispatch(
                              editContact(data?.customer_details?.contact_uuid)
                            );
                          }
                        }}
                      />
                      {data?.status === DRAFT_STATUS && (
                        <CustomButton
                          text={t('attributes.create')}
                          startIcon={<AddIcon />}
                          disabled={updateQuotiationLoading}
                          onClick={() => sendDefectQuotation(CREATED_STATUS)}
                        />
                      )}
                      {data?.status !== DRAFT_STATUS &&
                        data.status !== CUSTOMER_QUOTATION_STATUS.PENDING &&
                        data.status !== CUSTOMER_QUOTATION_STATUS.APPROVED &&
                        data?.status !== CUSTOMER_QUOTATION_STATUS.OVERDUE && (
                          <CustomButton
                            text={`${t('common.send')} ${t('attributes.service_quotes.service_quote')}`}
                            startIcon={<ForwardToInboxOutlinedIcon />}
                            disabled={updateQuotiationLoading}
                            onClick={() =>
                              sendDefectQuotation(SUBMIT_TO_CUSTOMER_STATUS)
                            }
                          />
                        )}
                    </Box>
                  }
                </Box>
              </>
            )}
          </>
        )
      )}
      {openAddProduct && (
        <SwipeableDrawer
          open={openAddProduct}
          title="Add Product"
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setOpenAddProduct(false);
          }}
          footerButton={
            <CustomButton
              text={t('attributes.service_quotes.add_to_serviceQuote')}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={disableSave}
            />
          }
          width={SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH + 20}
        >
          <AddProduct
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            selectedProductsError={selectedProductsError}
            setProductError={setSelectedProductsError}
            isFromServiceQuote={true}
          />
        </SwipeableDrawer>
      )}
      {editProductDetail && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={editProductFormOpen}
          title={`${editProductDetail?.product_catalogue?.product_name}`}
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setEditProductFormOpen(false) && setEditProductDetail(null);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.save')}
              disabled={disableSave}
              startIcon={<SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmitEditProduct)}
            />,
          ]}
          width={653}
        >
          <EditDefectQuotesProduct
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            data={editProductDetail}
            isFromServiceQuotes={isFromServiceQuotes}
          />
        </SwipeableDrawer>
      )}
      {openPreview && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={openPreview}
          title={`Q-${data?.quotation_id}`}
          onClose={() => {
            setOpenPreview(false);
            setSelectedProductsError(false);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.download')}
              color="inherit"
              disabled={
                isPropertyLoading || isLoadingCompany || listTemplateLoading
              }
              startIcon={<DownloadOutlinedIcon />}
              onClick={() => {
                generatePDF();
              }}
            />,
            data.status !== CUSTOMER_QUOTATION_STATUS.PENDING &&
              data.status !== CUSTOMER_QUOTATION_STATUS.APPROVED &&
              data.status !== DRAFT_STATUS &&
              data?.status !== CUSTOMER_QUOTATION_STATUS.OVERDUE && (
                <CustomButton
                  text={t('attributes.service_quotes.send_service_quotes')}
                  disabled={
                    isPropertyLoading ||
                    isLoadingCompany ||
                    updateQuotiationLoading
                  }
                  startIcon={<ForwardToInboxOutlinedIcon />}
                  onClick={() => sendDefectQuotation(SUBMIT_TO_CUSTOMER_STATUS)}
                />
              ),
          ]}
          width={653}
        >
          {PreviewTemplatePDF}
        </SwipeableDrawer>
      )}
    </>
  );
};

export default ServiceQuotesDetail;
